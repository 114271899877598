export const initializeWootric = (userJustAccepted = false): void => {
  const useAcceptedPerformanceCookies =
    accompany.currentUserAcceptedPerformanceCookies || userJustAccepted;

  const wootricActive = accompany.wootric.active;

  if (!wootricActive || !useAcceptedPerformanceCookies) {
    return;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://cdn.wootric.com/wootric-sdk.js';
  script.nonce = accompany.nonce;

  document.head.appendChild(script);

  if (accompany.wootric.wootric_data) {
    // Wootric Testing flag. This should be commented out in Prod
    // @see https://docs.wootric.com/javascript/#step-1--add-the-code
    // window.wootric_survey_immediately = true;

    window.wootricSettings = accompany.wootric.wootric_data;

    script.addEventListener('load', function () {
      if (window.wootric) {
        window.wootric('run');
      }
    });
  }
};
