import React from 'react';

import { Button } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import WhatIsAlignModal from 'app/components/features/WhatIsAlignModal';

export class WhatIsAlignModalDemo extends React.Component {
  @observable isOpen = false;
  @action onToggle = () => (this.isOpen = !this.isOpen);

  render() {
    return (
      <div>
        <Button type="primary" onClick={this.onToggle}>
          Show What is Align Modal
        </Button>

        <WhatIsAlignModal isOpen={this.isOpen} onClose={this.onToggle} />
      </div>
    );
  }
}

export default observer(WhatIsAlignModalDemo);
