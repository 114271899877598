import { observable } from 'mobx';
import moment, { Moment } from 'moment';

import Model, { ModelJson } from 'app/models/Model';
import { PulseForTeamStore } from 'app/stores';

import MemberModel from './MemberModel';
import { ModelItem } from './ModelItem';
import { ModelList } from './ModelList';
import PulseActivityModel from './PulseActivityModel';
import PulseReminderModel from './PulseReminderModel';
import PulseStatementModel from './PulseStatementModel';
import TeamModel from './TeamModel';

export interface PulseForTeamMeta {
  parent?: {
    id: number;
    type: string;
  };
}

export class PulseForTeamModel extends Model {
  static _store: PulseForTeamStore;

  @observable id: number;
  @observable name: string;
  @observable type: string;
  @observable membership?: string;
  @observable owner_id: number;
  @observable owner_type: string;
  @observable disabled_at?: string;
  @observable disabled?: boolean;
  @observable has_report_data: boolean;
  @observable started_at?: Moment;
  @observable token_member? = new ModelItem<MemberModel>(MemberModel);

  @observable owner = new ModelItem<MemberModel>(TeamModel);
  @observable team = new ModelItem<TeamModel>(TeamModel);
  @observable participants_pool = new ModelList<MemberModel>(MemberModel);
  @observable participants = new ModelList<MemberModel>(MemberModel);
  @observable statements = new ModelList<PulseStatementModel>(PulseStatementModel);
  @observable reminders = new ModelList<PulseReminderModel>(PulseReminderModel);
  @observable recent_activities = new ModelList<PulseActivityModel>(PulseActivityModel);
  @observable user_responded?: boolean;

  deserialize_started_at(started_at) {
    if (!started_at) {
      return;
    }

    this.started_at = moment(started_at);
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PulseForTeamModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PulseForTeamModel;
  }

  static get(id) {
    return this._get(id) as PulseForTeamModel;
  }

  get reminder(): PulseReminderModel {
    return this.reminders.items?.[0];
  }
}

export default PulseForTeamModel;
