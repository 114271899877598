export const DiscussionGuideTestDriveWarningModalSeen = 'discussion_guide_test_drive_warning_seen';

export const PERSPECTIVE_LENS_ENERGY_SEEN = 'perspective_lens_energy_seen';
export const PERSPECTIVE_LENS_PROCESSING_SEEN = 'perspective_lens_processing_seen';
export const PERSPECTIVE_LENS_DECISIONS_SEEN = 'perspective_lens_decisions_seen';
export const PERSPECTIVE_LENS_STRUCTURE_SEEN = 'perspective_lens_structure_seen';

export const TEAM_DASHBOARD_GUIDE_CARD_SEEN = 'team_dashboard_guide_card_seen';
export const STATEMENTS_COLOR_TYPE_TOOLTIP_SEEN = 'statements_color_type_tooltip_seen';

export const REFLECT_360_WELCOME_CARD_SEEN = 'reflect_360_welcome_card_seen';
