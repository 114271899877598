import { observable } from 'mobx';

import { ModelList } from 'app/models/ModelList';
import TeamPerspectiveResultStore from 'app/stores/TeamPerspectiveResultStore';

import MemberAvatarModel from './MemberAvatarModel';
import Model, { ModelJson } from './Model';
import PerspectiveDimensionModel from './PerspectiveDimensionModel';
import PerspectiveMemberModel from './PerspectiveMemberModel';

export interface LensScoreMember {
  id: number;
  name: string;
  raw_score: string;
  binned_score: number;
  avatar: MemberAvatarModel;
}

export interface LensScoreWithMembers {
  title: string;
  left_label: string;
  right_label: string;
  left_tooltip: string;
  right_tooltip: string;
  members: { [memberId: number]: LensScoreMember };
}

export class TeamPerspectiveResultModel extends Model {
  static _store: TeamPerspectiveResultStore;

  @observable id: string;
  @observable dimensions = new ModelList<PerspectiveDimensionModel>(PerspectiveDimensionModel);
  @observable members?: { [id: string]: PerspectiveMemberModel };
  @observable absentees?: { [id: string]: PerspectiveMemberModel };
  @observable lensesScores?: { [dimension: string]: { [lens: string]: LensScoreWithMembers } };
  @observable canManage?: boolean;
  @observable isManagerCoachDisabled?: boolean;

  deserialize_members(members) {
    const mapping = {} as { [id: number]: PerspectiveMemberModel };
    for (const memberId in members) {
      mapping[memberId] = PerspectiveMemberModel.fromJson(members[memberId]);
    }
    this.members = mapping;
  }

  deserialize_absentees(absentees) {
    const mapping = {} as { [id: number]: PerspectiveMemberModel };
    for (const absenteeId in absentees) {
      mapping[absenteeId] = PerspectiveMemberModel.fromJson(absentees[absenteeId]);
    }
    this.absentees = mapping;
  }

  deserialize_dimensions(dimensions) {
    const allDimensions = dimensions.map((dimension) => {
      return PerspectiveDimensionModel.fromJson(dimension);
    });

    this.dimensions.appendItems(allDimensions);
  }

  static getEmptyTeamStackItems() {
    const empty = {
      spreadType: null,
      leftLabel: '',
      rightLabel: '',
      items: [],
      description: '',
      differences: [],
      diffIntro: '',
      risks: '',
      tips: [],
      lenses: [],
    };

    return {
      energy: empty,
      processing: empty,
      decisions: empty,
      structure: empty,
    };
  }

  getTeamStackItems() {
    const data = TeamPerspectiveResultModel.getEmptyTeamStackItems();

    this.dimensions.items.forEach(
      ({
        spreadType,
        dimensionDisplayName,
        memberScores,
        descriptionHtml,
        differences,
        risks,
        tips,
        leftLabel,
        rightLabel,
        leftToolTip,
        rightToolTip,
      }) => {
        data[dimensionDisplayName] = {
          name: dimensionDisplayName,
          spreadType,
          differences,
          description: descriptionHtml,
          leftLabel,
          rightLabel,
          leftToolTip,
          rightToolTip,
          diffIntro: '',
          items: memberScores.map(({ memberId, binnedScore }) => {
            return {
              score: binnedScore,
              avatar: this.members[memberId].avatar,
              name: this.members[memberId].name,
            };
          }),
          lensesScores: this.lensesScores?.[dimensionDisplayName] ?? {},
          risks,
          tips,
        };
      }
    );

    return data;
  }

  get completedMembers() {
    return Object.values(this.members).filter(({ letters }) => !!letters);
  }

  get nonCompletedMembers() {
    return Object.values(this.members).filter(({ letters }) => !letters);
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamPerspectiveResultModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeamPerspectiveResultModel;
  }

  static get(id) {
    return this._get(id) as TeamPerspectiveResultModel;
  }
}
