import React from 'react';

import { Col, Row } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import HelpCard from './HelpCard';
import InviteEmailPreview from './InviteEmailPreview';
import InviteModal from './InviteModal';
import SelectedPeers from './SelectedPeers';

export interface InvitePeersStepProps extends RouteComponentProps {}

export const InvitePeersStep: React.FC<InvitePeersStepProps> = () => (
  <>
    <Row gutter={24}>
      <Col span={12}>
        <HelpCard />
      </Col>
      <Col span={12}>
        <SelectedPeers />
      </Col>
    </Row>

    <InviteModal />
    <InviteEmailPreview />
  </>
);

export default withRouter(InvitePeersStep);
