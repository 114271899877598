import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { PulseTemplateGroupStore } from 'app/stores';

import { ModelList } from './ModelList';
import PulseTemplateModel from './PulseTemplateModel';

export class PulseTemplateGroupModel extends Model {
  static _store: PulseTemplateGroupStore;

  @observable id: string;
  @observable name: string;
  @observable templates = new ModelList<PulseTemplateModel>(PulseTemplateModel);

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PulseTemplateGroupModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PulseTemplateGroupModel;
  }

  static get(id) {
    return this._get(id) as PulseTemplateGroupModel;
  }
}

export default PulseTemplateGroupModel;
