import React from 'react';

import { Tag } from 'antd';

import './Reflect360GeneralFeedback.scss';

import EnhancedCard from 'app/components/ui/EnhancedCard';

export interface Reflect360GeneralFeedbackProps {
  comments: string[];
}

export const Reflect360GeneralFeedback: React.FC<Reflect360GeneralFeedbackProps> = ({
  comments,
}) => {
  const [isCardCollapsed, setIsCardCollapsed] = React.useState(false);

  return (
    <EnhancedCard
      heading={`General feedback (${comments?.length})`}
      className="general-feedback-card"
      headerClassName="card-header"
      headerTag={<Tag color="green">New</Tag>}
      onCollapseStateChange={setIsCardCollapsed}
      isCollapsed={isCardCollapsed}
      collapsible
    >
      <div className="card-body">
        {comments.map((comment, i) => (
          <p className="feedback-comment" key={i}>
            {comment}
          </p>
        ))}
      </div>
    </EnhancedCard>
  );
};

export default Reflect360GeneralFeedback;
