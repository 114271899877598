import React from 'react';

import { useDragLayer, XYCoord } from 'react-dnd';

import './ExerciseStatementCardCustomDragLayer.scss';

const getStyles = (currentOffset: XYCoord): any => {
  if (!currentOffset) {
    return { display: 'none' };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return { transform };
};

export const ExerciseStatementCardCustomDragLayer: React.FC = () => {
  const { item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!item) {
    return null;
  }

  return (
    <div className="exercise-statement-card-custom-drag-layer">
      <div className="exercise-statement-card" style={getStyles(currentOffset)}>
        <div className="header"></div>
        <div className="body">{item.title}</div>
      </div>
    </div>
  );
};

export default ExerciseStatementCardCustomDragLayer;
