import { find } from 'lodash';
import type { IObservableArray } from 'mobx';
import { action, computed, observable } from 'mobx';
import moment from 'moment';

import { DiscussionGuidePage } from 'app/constants/discussionGuideConstants';
import { DiscussionGuideState } from 'app/constants/discussionGuideConstants';
import AlignReportModel from 'app/models/AlignReportModel';
import ShiftModel from 'app/models/ShiftModel';
import type { StatementNotesModel } from 'app/models/StatementNotesModel';

import allModels from './allModels';
import PulseForTeamModel from './PulseForTeamModel';

export interface DiscussionGuideProps {
  id?: number;
  team_id: number;
  exercise_id: number;
  state: string;
  alignReport: AlignReportModel;
  pulse?: PulseForTeamModel;
  insights: string;
  statement_notes?: Array<any>;
  last_page?: string;
  guide_type: string;
  recommended_statements_count: number;
  completed_at?: string;
  is_test_drive?: boolean;
}

export class DiscussionGuideModel extends ShiftModel<DiscussionGuideProps> {
  @observable id: number;
  @observable team_id: number;
  @observable exercise_id: number;
  @observable state: string;
  @observable insights: string;
  @observable completedAt?: moment.Moment = null;
  @observable pulse?: PulseForTeamModel;
  @observable alignReport: AlignReportModel;
  @observable statement_notes?: Array<any>;
  @observable lastPage: string;
  @observable guide_type: string;
  @observable commitments: string;

  is_test_drive: boolean;

  // Keep track of statements that have been prioritized vs left behind
  @observable prioritized: IObservableArray<StatementNotesModel> = observable([]);
  @observable statementsToDiagnose: StatementNotesModel[] = [];
  @observable statementsToDiscuss: StatementNotesModel[] = [];
  @observable statementsToCelebrate: StatementNotesModel[] = [];

  constructor({
    insights,
    state,
    last_page,
    statement_notes,
    completed_at,
    pulse,
    ...rest
  }: DiscussionGuideProps) {
    super(rest as any);

    this.insights = insights;
    this.lastPage = last_page || DiscussionGuidePage.START;
    this.state = state || DiscussionGuideState.PREVIEW;
    this.completedAt = completed_at ? moment(completed_at) : null;

    let notes = [];
    if (statement_notes) {
      notes = statement_notes.map((note) => ({ ...note, statementID: note.align_statement_id }));
    }
    this.statement_notes = notes;

    this.buildStatements(notes);

    if (pulse) {
      this.pulse = pulse;

      if (!(pulse instanceof PulseForTeamModel)) {
        this.pulse = PulseForTeamModel.fromJson(pulse);
      }
    }
  }

  buildStatements(statementNotes) {
    if (!this.alignReport) {
      return;
    }

    const alignStatements = this.alignReport.statements;

    // populate prioritized field
    if (statementNotes) {
      for (const note of statementNotes) {
        const alignStatement = find(alignStatements, { statementID: note.statementID });

        this.prioritized.push(
          new allModels.StatementNotesModel({
            ...note,
            alignStatement,
            guide: this,
          })
        );
      }
    }

    // Assign non-prioritized statements to their respective buckets
    const buckets = ['statementsToCelebrate', 'statementsToDiscuss', 'statementsToDiagnose'];
    buckets.forEach((bucket) => (this[bucket] = []));

    for (const statement of alignStatements) {
      const bucket = buckets[statement.bucket];
      this[bucket].push(
        new allModels.StatementNotesModel({
          alignStatement: statement,
          order: 0,
          statementID: statement.statementID,
          guide: this,
        })
      );
    }
  }

  @action setCommitments = (commitments: string): void => {
    this.commitments = commitments;
  };

  @computed
  get hasStatementNotes() {
    return !!this.statement_notes?.length;
  }

  get defaultRerunDate() {
    const closedAt = this.alignReport.closedAt;
    if (closedAt && closedAt.isValid()) {
      return closedAt.clone().add(6, 'month');
    } else {
      return moment().add(6, 'month');
    }
  }

  @action
  public complete() {
    this.completedAt = moment();
    this.state = DiscussionGuideState.COMPLETED;
  }

  @computed
  public get isCompleted() {
    return this.state === DiscussionGuideState.COMPLETED;
  }

  public get completedAtFormatted() {
    return this.completedAt.format('MMMM D, YYYY');
  }

  @computed
  public get isPreview() {
    return this.state === DiscussionGuideState.PREVIEW;
  }

  @computed
  public get isStarted() {
    return this.state === DiscussionGuideState.STARTED;
  }

  @action
  setInsights(insights) {
    this.insights = insights;
  }

  @action
  setGuideType(type: string) {
    this.guide_type = type;
  }

  @computed get isNotEmpty() {
    return !!this.insights || !!this.prioritized.length || !!this.completedAt;
  }

  @computed get toJS() {
    return {
      commitments: this.commitments,
      completedAt: this.completedAt ? this.completedAt.toISOString() : null,
      pulseId: this.pulse?.id,
      insights: this.insights,
      statementNotes: this.prioritized.map((note) => note.toJS),
      state: this.state,
    };
  }
}

export default DiscussionGuideModel;
