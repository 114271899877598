/*
 |------------------------------------------------------------------------------
 | Simple Markdown Wrapper Component
 |------------------------------------------------------------------------------
 |
 | A wrapper component that enforces a wrapper class so we can enforce default
 | styling make rendered markdown.
 |
 */

import React from 'react';

import cx from 'classnames';
import ReactMarkdown from 'react-markdown';

import './Markdown.scss';

export interface MarkdownProps {
  source: string;
  className?: string;
}

export const Markdown: React.FC<MarkdownProps> = ({ source, className }) => (
  <ReactMarkdown className={cx('markdown-wrapper', className)} linkTarget="_blank">
    {source}
  </ReactMarkdown>
);

export default Markdown;
