import { observable } from 'mobx';

import { PulseReportStore } from 'app/stores';

import Model, { ModelJson } from './Model';

export interface Response {
  created_at: string;
  id: number;
  statements: ResponseStatement[];
  type: string;
  updated_at: string;
}

export interface ResponseStatement {
  created_at: string;
  id: number | string;
  order: number;
  pulse_activities_id: number;
  pulse_statement_id: number;
  response_values: ResponseValue[];
  statement_type: string;
  text: string;
  type?: string;
}

export interface ResponseValue {
  value: number | string;
  count: number;
  metric_type: string;
}

export interface ReportOverview {
  created_at: string;
  id: number;
  response_values: ResponseValue[];
  total: number;
}

export class PulseReportModel extends Model {
  static _store: PulseReportStore;

  @observable aggregate_responses: Response[];
  @observable overview: ReportOverview[];
  @observable statements: ResponseStatement[];

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PulseReportModel;
  }
}

export default PulseReportModel;
