import MbtiDataModel from 'app/models/MbtiDataModel';

import Store from './Store';

export class MbtiDataStore extends Store<MbtiDataModel> {
  constructor() {
    super();
    MbtiDataModel._store = this;
  }
}

export default MbtiDataStore;
