import { GREEN, ORANGE, RED, YELLOW } from './colors';

export const IMPROVE = 'IMPROVE';
export const DISCUSS = 'DISCUSS';
export const CELEBRATE = 'CELEBRATE';

export enum ExerciseClassification {
  IMPROVE = 'IMPROVE',
  CELEBRATE = 'CELEBRATE',
  DISCUSS = 'DISCUSS',
}

export const CLASS_COLOR_MAP = {
  [IMPROVE]: RED,
  [DISCUSS]: YELLOW,
  [CELEBRATE]: GREEN,
};

export const SCATTER_PLOT_TAB_COLORS = {
  [IMPROVE]: '#FFEFBC',
  [DISCUSS]: '#FFEFBC',
  [CELEBRATE]: '#D0F4F4',
};

export const SCATTER_PLOT_TITLE_COLORS = {
  [IMPROVE]: ORANGE,
  [DISCUSS]: YELLOW,
  [CELEBRATE]: GREEN,
};

export const STATEMENT_SECTION_COLORS = {
  [RED]: 'red',
  [YELLOW]: 'yellow',
  [GREEN]: 'green',
};

export const DEFAULT_SECTIONS = [IMPROVE, DISCUSS, CELEBRATE];
export const DEFAULT_MY360_SECTIONS = [CELEBRATE, DISCUSS, IMPROVE];

export const DIG_DEEPER_CARD_CONTENT = {
  1: {
    statement: 'We focus on the most important things',
    secondary_copy: 'Focus and prioritization are critical to modern teams. ',
    cta: 'Learn how to priortize. ',
  },
  2: {
    statement: 'We are efficient in our meetings',
    secondary_copy: 'People are spending more time than ever in meetings. ',
    cta: 'Make the most of this time. ',
  },
  3: {
    statement: 'We hold ourselves to high standards',
    secondary_copy: 'A culture of high standards empowers people. ',
    cta: 'Explore why this is important. ',
  },
  4: {
    statement: 'We have clear roles',
    secondary_copy: 'Clarifying roles can increase efficiency. ',
    cta: 'Learn how to optimize this. ',
  },
  5: {
    statement: "We admit if we are wrong or don't know",
    secondary_copy: 'Making mistakes are important for innovation and growth. ',
    cta: 'Learn to embrace imperfection. ',
  },
  6: {
    statement: 'We genuinely care about each other',
    secondary_copy: 'Teams that care about each other perform better. ',
    cta: 'Cultivate a culture of caring. ',
  },
  7: {
    statement: 'We deliver on our commitments',
    secondary_copy: 'High-performing teams make public commitments. ',
    cta: 'Learn how to make commitments. ',
  },
  8: {
    statement: "We don't let resentments build up",
    secondary_copy: 'Resentment can create lost time, energy and focus. ',
    cta: 'Become more conflict savvy. ',
  },
  9: {
    statement: 'We make decisions quickly',
    secondary_copy: 'High performing teams can make decisions quickly. ',
    cta: 'Learn how to get there. ',
  },
  10: {
    statement: 'We have fun together',
    secondary_copy: 'Happier workplaces are more productive. ',
    cta: 'Foster a culture of connection. ',
  },
  11: {
    statement: 'We actively surface & address problems',
    secondary_copy: 'Great teams actively seek out problems. ',
    cta: 'Tips for honest conversations. ',
  },
  12: {
    statement: "We don't let our egos get in the way",
    secondary_copy: 'The best teams have players who put the team first. ',
    cta: 'Learn how to manage ego. ',
  },
  13: {
    statement: 'We communicate well',
    secondary_copy: 'Communication is almost always a work in progress. ',
    cta: 'Learn how to enhance this. ',
  },
  14: {
    statement: 'We feel safe with one another',
    secondary_copy: 'Feeling safe is the biggest factor in successful teams. ',
    cta: 'Get to know each other better. ',
  },
  15: {
    statement: 'We give honest feedback to each other',
    secondary_copy: 'Feedback is an important factor for change and growth. ',
    cta: 'Learn how to give feedback. ',
  },
  16: {
    statement: 'We let good ideas come from anywhere',
    secondary_copy: 'Diverse perspectives can generate innovative solutions. ',
    cta: 'Learn how to be more curious. ',
  },
};
