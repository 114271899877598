import { MemberAvatarModel } from 'app/models';

import Store from './Store';

export class MemberAvatarStore extends Store<MemberAvatarModel> {
  constructor() {
    super();
    MemberAvatarModel._store = this;
  }
}

export default MemberAvatarStore;
