import React from 'react';

import './CrucialConversationsOnboardingModal.scss';

import CarouselModalComponent from 'app/components/ui/CarouselModalComponent';
import { CarouselModalComponentTags } from 'app/components/ui/CarouselModalComponent/CarouselModalComponent';

export interface CrucialConversationsOnboardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete?: () => void;
  completeDisabled?: boolean;
  disableInfoText?: string;
  dataTrackPrefix?: string;
  tags?: CarouselModalComponentTags;
}

const SLIDES = [
  {
    header:
      'This is a quick activity to help teams find ways to better support one another during these stressful times.',
    iconSrc: '/images/align/crucial-conversations-modal-slide-1.svg',
    iconStyle: {
      marginTop: '1.5rem',
      marginBottom: '2.5rem',
    },
  },
  {
    header: 'It starts with an anonymous exercise so you can share how you are really feeling.',
    iconSrc: '/images/align/crucial-conversations-modal-slide-2.svg',
    iconTopMargin: '1.75rem',
    iconStyle: {
      marginTop: '1.75rem',
      marginBottom: '1.25rem',
    },
  },
  {
    header:
      "When the team is done, you'll get a custom diagnostic and have a supportive conversation together.",
    iconSrc: '/images/align/crucial-conversations-modal-slide-3.svg',
    iconTopMargin: '1.75rem',
    iconStyle: {
      marginTop: '1.75rem',
      marginBottom: '1.25rem',
    },
  },
];

export const CrucialConversationsOnboardingModal: React.FC<
  CrucialConversationsOnboardingModalProps
> = ({ isOpen, onComplete, onClose, dataTrackPrefix, tags }) => (
  <CarouselModalComponent
    title="Let's cope with stress together"
    isOpen={isOpen}
    onClose={onClose}
    onComplete={onComplete}
    slides={SLIDES}
    className="welcome-to-align-modal crucial-conversations-onboarding-modal"
    dataTrackPrefix={dataTrackPrefix}
    tags={tags}
  />
);

export default CrucialConversationsOnboardingModal;
