import { computed, observable } from 'mobx';

import { TeamGameStatus } from 'app/constants';
import Model, { ModelJson } from 'app/models/Model';
import TeamGameStore from 'app/stores/TeamGameStore';

import { ModelList } from './ModelList';
import TeamGameParticipantModel, { TeamGameAnswerModel } from './TeamGameParticipantModel';

export class TeamGameQuestionModel {
  id: number;
  question: string;
  answers: TeamGameAnswerModel[];
}

export class TeamGameModel extends Model {
  static _store: TeamGameStore;

  @observable id: number;
  @observable name: string;
  @observable type: string;
  @observable status: TeamGameStatus;
  @observable invite_link: string;
  @observable created_by: number;
  @observable created_at: string;
  @observable team_id: number;
  @observable participants = new ModelList<TeamGameParticipantModel>(TeamGameParticipantModel);
  @observable questions: TeamGameQuestionModel[];
  @observable round: number;

  @computed
  get profiles(): TeamGameParticipantModel[] {
    return this.participants.items.filter((participant) => participant.isCompleted);
  }

  @computed
  get membersWithAnswers(): TeamGameParticipantModel[] {
    return this.participants.items.filter((participant) => participant.answers?.length > 0);
  }

  @computed
  get isPending(): boolean {
    return this.status === TeamGameStatus.Pending;
  }

  @computed
  get isStarted(): boolean {
    return this.status === TeamGameStatus.Started;
  }

  @computed
  get isFinished(): boolean {
    return this.status === TeamGameStatus.Finished;
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamGameModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeamGameModel;
  }

  static get(id) {
    return this._get(id) as TeamGameModel;
  }
}

export default TeamGameModel;
