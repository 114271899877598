import * as React from 'react';

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import RichTextEditor from 'react-rte';

import './MarkdownEditor.scss';

import DocumentFragmentRenderer from 'app/components/ui/DocumentFragmentRenderer';

export interface MarkdownEditorProps {
  content: string;
  maxLength?: number;
  onChange: (string) => void;
}

export class MarkdownEditor extends React.Component<MarkdownEditorProps> {
  @observable value = RichTextEditor.createEmptyValue();

  @observable fragment?: DocumentFragment;
  @action setFragment = (fragment: DocumentFragment) => (this.fragment = fragment);

  uniqKey: string;

  constructor(props) {
    super(props);

    this.uniqKey = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 10);

    const content = props.maxLength ? props.content.substr(0, props.maxLength) : props.content;
    this.value = RichTextEditor.createValueFromString(content || '', 'markdown');
  }

  componentDidMount() {
    this.updatePreview();
  }

  updatePreview() {
    const html = this.value.toString('html');
    const frag = document.createDocumentFragment();
    const div = document.createElement('div');
    div.innerHTML = html;
    frag.appendChild(div);
    this.setFragment(frag);
  }

  get previewRenderID() {
    return `markdown-editor-react-render-${this.uniqKey}`;
  }

  render() {
    return (
      <div className="markdown-editor-react">
        <RichTextEditor
          value={this.value}
          editorClassName="markdown-editor-react-inner"
          onChange={(value) => {
            this.value = value;
            this.props.onChange(this.value.toString('markdown'));
            this.updatePreview();
          }}
        />
        <div id={this.previewRenderID} className="markdown-editor-react-hidden-preview">
          {this.fragment && <DocumentFragmentRenderer fragment={this.fragment} />}
        </div>
        {this.props.maxLength && (
          <div className="chars-limit">
            Characters remaining: {this.value.toString('markdown').length}/{this.props.maxLength}
          </div>
        )}
      </div>
    );
  }
}

export default observer(MarkdownEditor);
