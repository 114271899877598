import React from 'react';

import { assign } from 'lodash';
import { Provider } from 'mobx-react';
import ReactDOM from 'react-dom';

import AppLayout from 'app/components/features/AppLayout/AdminAppLayout';
import { WelcomeToSHIFTModalContainer } from 'app/components/features/WelcomeToSHIFTModal/WelcomeToSHIFTModal';
import RegisterFullScreen from 'app/pages/auth/Register/RegisterFullScreen/RegisterFullScreen';
import { InviteManagersModal } from 'app/pages/dashboard/Organization/InviteManagersModal/InviteManagersModal';
import { createStores } from 'app/stores';

import EmailEditorContainer from './EmailEditorContainer/EmailEditorContainer';
import ExampleContainer from './ExampleContainer';
import HowHabitWorksModalContainer from './HowHabitWorksModalContainer';
import TeamAdminControls from './TeamAdminControlsContainer';

/**
 * The Registry maps component names to their container classes
 */
const registry = {
  example: ExampleContainer,

  EmailEditor: EmailEditorContainer,
  InviteManagersModal,
  RegisterFullScreen,
  TeamAdminControls,
  HowHabitWorksModalContainer,
  AppLayout,

  WelcomeToSHIFTIntroModal: WelcomeToSHIFTModalContainer,
};

/**
 * Loops through unmounted components and will mount React Components
 * where ever they have the `data-react-component` property.
 */
const mountComponents = () => {
  const rootStore = createStores();

  // Find all divs with the `data-react-component` property
  const reactElements = document.querySelectorAll('div[data-react-component]');

  for (let i = 0; i < reactElements.length; i++) {
    const elm = reactElements[i];
    const dataset = elm['dataset'];

    // If we've already mounted, don't mount again
    if (dataset.reactComponentMounted) {
      return;
    }

    // Determine the component key and get it from the registry
    const componentKey = dataset.reactComponent;
    const RegistryComponent = registry[componentKey];

    // If it doesn't exist in the registry, skip
    if (!RegistryComponent) {
      break;
    }

    // Basic parsing of props
    // <div
    //   data-react-component="example"
    //   data-props="key1:value,key2:value"
    // ></div>
    let props = {};
    if (dataset.props) {
      props = assign(
        dataset.props
          .split(',')
          .map((prop) => {
            const [key, value] = prop.split(':');
            return { [key]: value };
          })
          .reduce((acc, prop) => assign(acc, prop), {})
      );
    }

    // Mount the react component
    ReactDOM.render(
      <Provider {...rootStore}>
        <RegistryComponent {...props} />
      </Provider>,
      elm
    );

    // Mark the component div as mounted
    dataset.reactComponentMounted = true;
  }
};

/**
 * Containers are React components that can be mounted directly
 * (in HTML and outside of react). They are entry points for the React
 * sub application.
 *
 * To mount a container create a div with data-react-component set to the name
 * of the component you want to mount
 *
 * Example: <div data-react-component="example"></div>
 */
export default class Containers {
  static mount(): void {
    mountComponents();
  }
}

document.addEventListener('mount-components', () => {
  mountComponents();
});
