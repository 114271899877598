import React, { lazy } from 'react';

import { orderBy } from 'lodash';

import { CreateHabitsIcon } from 'app/components/ui/icons';
import PageTitle from 'app/components/ui/PageTitle';

import Reflect360GeneralFeedback from '../components/Reflect360GeneralFeedback';
import Reflect360WelcomeCard from '../components/Reflect360WelcomeCard';
import My360ReportUIStore from '../My360ReportUIStore';

const My360StatementsBreakdown = lazy(() => import('../components/My360StatementsBreakdown'));

interface FullAnalysisContainerProps {
  uiStore: My360ReportUIStore;
}

const FullAnalysisContainer: React.FC<FullAnalysisContainerProps> = ({ uiStore }) => {
  const { report, isLoading } = uiStore;
  if (isLoading || !report) {
    return null;
  }

  let statements = Object.keys(report.comments).reduce((acc, key) => {
    acc.push(report.comments[key]);
    return acc;
  }, []);

  statements = orderBy(statements, ['statement_id'], ['desc']);
  const generalComments =
    statements.find((statement) => statement.statement_id === null)?.comments || [];

  return (
    <div className="container">
      <Reflect360WelcomeCard firstName={report.exercise.member?.firstName} />

      {!uiStore.showingFeedbackAndGuidance && (
        <PageTitle title="Digest your Reflect 360 results" icon={<CreateHabitsIcon />} />
      )}
      <My360StatementsBreakdown report={report} uiStore={uiStore} />

      {generalComments.length > 0 && !uiStore.showingFeedbackAndGuidance && (
        <>
          <PageTitle
            title="Helpful feedback from what others are saying:"
            icon={<CreateHabitsIcon />}
          />
          <Reflect360GeneralFeedback comments={generalComments} />
        </>
      )}
    </div>
  );
};

export default FullAnalysisContainer;
