import { observable } from 'mobx';

import { MemberModel, Subscription } from 'app/models/MemberModel';
import Model, { ModelJson } from 'app/models/Model';
import { SubscriptionOrderItemStore } from 'app/stores/SubscriptionOrderItemStore';

import SubscriptionItemModel from './SubscriptionItemModel';

export class SubscriptionOrderItemModel extends Model {
  static _store: SubscriptionOrderItemStore;

  readonly id: number;
  @observable subscription_item_id: number;
  @observable subscription_order_id: number;
  @observable member_id: number;
  @observable member: Partial<MemberModel>;
  @observable price: string;
  @observable subscription_member?: Subscription;
  @observable subscription_item: SubscriptionItemModel;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as SubscriptionOrderItemModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as SubscriptionOrderItemModel;
  }

  static get(id) {
    return this._get(id) as SubscriptionOrderItemModel;
  }
}

export default SubscriptionOrderItemModel;
