import { observable } from 'mobx';

import PulseActivityStore from 'app/stores/PulseActivityStore';

import Model, { ModelJson } from './Model';

export interface ActivityCounts {
  responses: number;
  participants: number;
  average: number;
}

export interface ActivityResponse {
  id: number;
  member_id: number;
  value: string;
  pulse_statement_id: number;
  pulse_activities_id: number;
}

export interface PulseRecentActivity {
  pulse_activities_id: number;
  created_at: string;
  updated_at: string;
  counts: ActivityCounts;
  responses: ActivityResponse[];
}

export class PulseActivityModel extends Model {
  static _store: PulseActivityStore;

  @observable id: number;
  @observable activities: PulseRecentActivity[];

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PulseActivityModel;
  }
}

export default PulseActivityModel;
