import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';

import { TeamCardChecklistItemCtaModel } from './TeamCardChecklistItemCtaModel';

export class TeamCardChecklistItemModel extends Model {
  readonly id: string;

  @observable title: string;
  @observable completed: boolean;
  @observable cta: TeamCardChecklistItemCtaModel;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamCardChecklistItemModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeamCardChecklistItemModel;
  }

  static get(id) {
    return this._get(id) as TeamCardChecklistItemModel;
  }
}

export default TeamCardChecklistItemModel;
