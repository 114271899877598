import React from 'react';

import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import cx from 'classnames';

import './SimpleModal.scss';

export const SimpleModal: React.FC<ModalProps> = ({ className, children, ...rest }) => {
  return (
    <Modal className={cx('simple-modal', className)} footer={null} {...rest}>
      {children}
    </Modal>
  );
};

export default SimpleModal;
