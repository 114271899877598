import { action, observable, ObservableMap } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { MenuModel, ModelItem, ModelList, OrganizationModel, TeamModel } from 'app/models';

import Store from './Store';

export class MenuStore extends Store<MenuModel> {
  private lastLoadedMenu: ModelItem<MenuModel> | undefined;
  @observable public menus = new ModelList<MenuModel>(MenuModel);

  @observable public menuItemsTracker = new ObservableMap();

  @observable isLoadingMenu = false;
  @action private setIsLoadingMenu = (status: boolean) => (this.isLoadingMenu = status);

  constructor() {
    super();
    MenuModel._store = this;
  }

  @action
  public async loadMenu(name: string, orgId?: number, teamId?: number): Promise<void> {
    if (this.isLoadingMenu && this.lastLoadedMenu?.item?.id === name) {
      // Cancel the previous request if it's still loading so that we don't load a menu twice
      this.lastLoadedMenu?.cancelPreviousRequest();
    }

    this.setIsLoadingMenu(true);

    // Get menu model
    const url = ServerRouteHelper.api.menus.menu(name, orgId, teamId);
    const menu = new ModelItem<MenuModel>(MenuModel);
    this.lastLoadedMenu = menu;

    // Keep track of individual menu item loading status
    this.menuItemsTracker.set(name, menu);

    await menu.load(url);

    // Add menu to menu list
    if (menu.item) {
      this.menus.appendUniqueItems([menu.item]);
    }

    this.setIsLoadingMenu(false);
  }

  public getMenu(name: string): MenuModel {
    return this.menus.getItem(name);
  }

  async getActiveOrgIdAndTeam(
    orgId?: number,
    teamId?: number
  ): Promise<{
    active_organization: OrganizationModel;
    active_team: TeamModel;
  }> {
    const url = ServerRouteHelper.api.menus.activeModels(orgId, teamId);
    const config = { url };

    const response = await this.apiService.newGet(config);

    return response?.data;
  }
}

export default MenuStore;
