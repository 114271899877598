import { observable } from 'mobx';

import ShiftModel from './ShiftModel';

export interface OrganizationPivotModelProps {
  created_at?: Date;
  member_id?: number;
  organization_id?: number;
  role?: string;
}

export class OrganizationPivotModel extends ShiftModel<OrganizationPivotModelProps> {
  @observable created_at?: Date;
  @observable member_id?: number;
  @observable organization_id?: number;
  @observable role?: string;
}

export default OrganizationPivotModel;
