import { computed, observable } from 'mobx';

import TeamGroupStore from 'app/stores/TeamGroupStore';

import Model, { ModelJson } from './Model';
import TeamModel from './TeamModel';

/**
 * Define the properties that are passed in to create TeamGroups
 */
export interface TeamGroupProps {
  id: number;
  name: string;
  membersCount: number;
  teamsCount: number;
  teams: TeamModel[];
  exerciseTypes: { [id: number]: string };
}

/**
 * TeamGroupModel is used to represent TeamGroups
 */
export class TeamGroupModel extends Model {
  static _store: TeamGroupStore;

  readonly id: number;
  @observable name: string;
  @observable organization_id: number;
  @observable team_group_division_id?: number;
  @observable dependent_group_id?: number;

  @observable membersCount: number;
  @observable teamsCount: number;
  @observable participantsCount: number;

  @observable teams: TeamModel[];
  @observable exerciseTypes: { [id: number]: string };
  @observable child_required: boolean;

  get key(): string {
    const { id, name } = this;
    const key = name.toLowerCase().split(' ').join('_').replace(/\W/g, '');
    return key + '_' + id;
  }

  @computed
  get isOverall(): boolean {
    return this.name === 'Overall';
  }

  @computed
  get isSelectAll(): boolean {
    return this.name === 'Select all';
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamGroupModel;
  }
}

export default TeamGroupModel;
