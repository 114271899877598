import * as React from 'react';

interface DocumentFragmentRendererProps {
  fragment: DocumentFragment;
}
const DocumentFragmentRenderer: React.StatelessComponent<DocumentFragmentRendererProps> = ({
  fragment,
}) => {
  const rootElement = document.createElement('div');
  rootElement.appendChild(fragment);

  return <div dangerouslySetInnerHTML={{ __html: rootElement.innerHTML }} />;
};

export default DocumentFragmentRenderer;
