import React from 'react';

import { RightCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cx from 'classnames';

interface SubmitButtonProps {
  label?: string;
  disabled?: boolean;
  hideForDesktop?: boolean;
  className?: string;
  onClick: () => void;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  label,
  disabled,
  hideForDesktop,
  className,
  onClick,
}) => {
  const btn = (
    <button
      id="align-submit-button"
      onClick={onClick}
      className={cx('btn btn-primary submit-btn', className, {
        'align-tablet': hideForDesktop,
        'submit-btn-disabled': disabled,
      })}
      disabled={disabled}
    >
      <RightCircleFilled /> &nbsp; {label ?? 'Submit'}
    </button>
  );

  if (!disabled) {
    return btn;
  }

  return (
    <Tooltip placement="top" title="Drag all the cards onto the grid to finish the exercise.">
      <span>{btn}</span>
    </Tooltip>
  );
};
