import { observable } from 'mobx';

import MemberModel from 'app/models/MemberModel';
import Model, { ModelJson } from 'app/models/Model';
import EntityBlockStore from 'app/stores/EntityBlockStore';

export class EntityBlockModel extends Model {
  static _store: EntityBlockStore;

  readonly id: number;
  @observable type: string;
  @observable member: MemberModel;
  @observable value: string;
  @observable created_at: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as EntityBlockModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as EntityBlockModel;
  }

  static get(id) {
    return this._get(id) as EntityBlockModel;
  }
}

export default EntityBlockModel;
