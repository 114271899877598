import React, { ReactElement, ReactNode } from 'react';

import { LoadingOutlined, RightCircleFilled } from '@ant-design/icons';
import { notification } from 'antd';
import cx from 'classnames';
import { mapValues } from 'lodash';
import { action, computed, observable, when } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import URI from 'urijs';

import './ExerciseSubmitContent.scss';

import PolicyAcceptance from 'app/components/features/PolicyAcceptance';
import TextField from 'app/components/ui/TextField';
import {
  AutoMagicLinkOrigin,
  STORE_ALIGN,
  STORE_AUTH,
  STORE_EXERCISE_ANSWERS,
  STORE_MEMBER,
  STORE_MEMBER_CONFIRMATION,
} from 'app/constants';
import { preventDefault, ServerRouteHelper } from 'app/helpers';
import { MemberModel, ShiftError } from 'app/models';
import { AutoMagicLinkService } from 'app/services/AutoMagicLinkService';
import {
  AlignStore,
  AuthStore,
  ExerciseAnswersStore,
  MemberConfirmationStore,
  MemberStore,
} from 'app/stores';

import ExerciseContext from '../ExerciseContext';
import ExerciseUIStore from '../ExerciseUIStore';

export interface ExerciseSubmitContentProps {
  token: string;
  history: any;
  toggleSubmitModal: () => void;
  showSubmitModal: boolean;
  nextStepUrl?: string;
  alignStore?: AlignStore;
  isCustomForm?: boolean;
  exerciseAnswersStore?: ExerciseAnswersStore;
  memberStore?: MemberStore;
  memberConfirmationStore?: MemberConfirmationStore;
  authStore?: AuthStore;
  uiStore?: ExerciseUIStore;
  onSurveyMember?: (member: Partial<MemberModel>) => void;
  animateStickies?: boolean;
  onSubmitAnswersCallback?: () => void;
}

export class ExerciseSubmitContent extends React.Component<ExerciseSubmitContentProps> {
  static contextType = ExerciseContext;
  context!: React.ContextType<typeof ExerciseContext>;

  @observable private name;
  @action private setName = (name) => (this.name = name);

  @observable private email;
  @action private setEmail = (email) => (this.email = email);

  @observable private errors = { name: null, email: null };
  @action setErrors = (errors) => (this.errors = errors);

  @observable private isSubmittingAnswers = false;
  @action private toggleIsSubmittingAnswers = () =>
    (this.isSubmittingAnswers = !this.isSubmittingAnswers);

  @observable private isAnswersSubmitted = false;
  @action private toggleIsAnswersSubmitted = () =>
    (this.isAnswersSubmitted = !this.isAnswersSubmitted);

  @observable hasAcceptedPolicy = false;
  @action setHasAcceptedPolicy = (hasAgreed: boolean) => (this.hasAcceptedPolicy = hasAgreed);

  constructor(props: ExerciseSubmitContentProps) {
    super(props);

    when(
      () => !!this.uiStore && this.uiStore.surveyMember && !!props.onSurveyMember,
      () => props.onSurveyMember(this.uiStore?.surveyMember)
    );

    when(
      () => !!this.exercise,
      () => this.setHasAcceptedPolicy(this.exercise.currentUserHasAcceptedPolicy)
    );
  }

  get uiStore(): ExerciseUIStore {
    return this.context?.uiStore;
  }

  get token() {
    return this.props.token;
  }

  get exercise() {
    return this.props.alignStore.exercise.item;
  }

  get team() {
    return this.exercise?.team?.item;
  }

  get exerciseAnswers() {
    return this.props.exerciseAnswersStore.exerciseAnswers;
  }

  get isLoading() {
    return this.props.alignStore.exercise.loading;
  }

  get exerciseType() {
    return this.exercise?.exercise_type;
  }
  /**
   * Validate guest users primary domain email state.
   *
   * @return boolean
   */
  checkEmailStatus = async (): Promise<boolean> => {
    // Logged in members status is assumed to be valid
    if (!this.isGuest) {
      return false;
    }

    // Validate email domain
    const { authStore } = this.props;
    const response = await authStore.checkEmailPrimaryStatus(this.email);

    const email_status = response?.email_status;

    if (email_status !== false) {
      return false;
    }

    const { user_domain, primary_domain } = response.data;
    const statusMessage = `Using your "${user_domain}" email address is no longer allowed. Please use your "${primary_domain}" email.`;
    this.setErrors({ name: null, email: statusMessage });

    // Toggle off and return early
    this.toggleIsSubmittingAnswers();
    return true;
  };

  @computed
  get postQuestionnaireUrl(): string {
    if (!this.props.alignStore.exercise.item.post_questionnaire) {
      return null;
    }

    // If we have post_questionnaire, append member_id
    const url = new URI(this.props.alignStore.exercise.item.post_questionnaire);
    url.search((data) => {
      data.member_id = this.uiStore.surveyMember.id;
    });
    return url.toString();
  }

  handleFormSubmit = async (): Promise<void> => {
    // Toggle on
    this.toggleIsSubmittingAnswers();

    // Validate email domain
    const handled = await this.checkEmailStatus();

    if (handled) {
      return;
    }

    // Save member details;
    this.handlePolicyAcceptance();
  };

  handlePolicyAcceptance = async (): Promise<void> => {
    const { memberConfirmationStore } = this.props;

    await memberConfirmationStore.guestConfirm(
      this.name ?? this.currentMember.name,
      this.email ?? this.currentMember.email,
      this.currentMember?.id,
      this.token,
      this.team ? { team_id: this.team.id, team_code: this.team.code } : null,
      this.exercise.id
    );

    this.postAcceptPolicy();
  };

  postAcceptPolicy = async (): Promise<void> => {
    // Save
    const submitResposne = await this.handleSubmitAnswers();

    // Toggle off
    this.toggleIsSubmittingAnswers();

    if (!submitResposne) {
      return;
    }

    if (this.postQuestionnaireUrl) {
      window.location.href = this.postQuestionnaireUrl;
      return;
    }

    if (this.props.animateStickies) {
      this.props.onSubmitAnswersCallback();
      return;
    }

    this.props.history.push(this.props.nextStepUrl);
  };

  handleSubmitAnswers = async (): Promise<boolean> => {
    this.setErrors({ name: null, email: null });

    const member = {
      name: this.name ?? this.currentMember.name,
      email: this.email ?? this.currentMember.email,
    };

    try {
      const response = await this.props.uiStore.saveExerciseAndSurveyAnswers(member);

      if (response?.member) {
        // If member has no last_login_method,
        // keep track of it for the auto magiclink flow
        AutoMagicLinkService.flagMember(response.member, AutoMagicLinkOrigin.Align);

        // Toggle done
        this.toggleIsAnswersSubmitted();
        return true;
      }
    } catch (error) {
      if (error instanceof ShiftError) {
        this.setErrors(mapValues(error.errors, (field) => field[0]));
        document.getElementById('member-create-submit-btn').focus();
      }
    }

    notification.error({
      message: 'Error',
      description: 'Something went wrong please try again',
      placement: 'bottomRight',
      duration: 8,
    });

    return false;
  };

  @action
  handleSignout = (): void => {
    this.exercise.exercise_member = null;

    const url = `${ServerRouteHelper.auth.logout()}?redirect=${window.location.pathname}`;
    window.location.href = url;
  };

  setStartedAt = () => {
    if (!this.exerciseAnswers.started_at) {
      this.exerciseAnswers.update({ started_at: moment().unix() });
    }
  };

  @computed
  get currentMember(): MemberModel {
    return this.exercise?.exercise_member?.item || this.props.memberStore.currentMember.item;
  }

  @computed
  get isGuest(): boolean {
    return !this.isLoading && !this.currentMember;
  }

  @computed
  get isSubmitDisabled(): boolean {
    if (this.isSubmittingAnswers) {
      return true;
    }

    if (this.props.isCustomForm && !this.hasAcceptedPolicy) {
      return true;
    }

    return false;
  }

  @computed
  get guestMemberForm(): ReactNode {
    if (!this.isGuest) {
      return null;
    }

    const { name, email } = this.errors;

    return (
      <div className="member-form d-flex justify-content-between">
        <div className="form-group">
          <TextField
            inputId="name"
            type="text"
            placeHolder="What’s your name?"
            value={this.name}
            onValueChange={this.setName}
            error={name}
            required
          />
        </div>
        <div className="form-group">
          <TextField
            inputId="email"
            type="email"
            placeHolder="What’s your work email?"
            value={this.email}
            onValueChange={this.setEmail}
            error={email}
            required
          />
        </div>
      </div>
    );
  }

  @computed
  get policyAcceptanceField(): ReactNode {
    return <PolicyAcceptance onAcceptPolicy={this.setHasAcceptedPolicy} />;
  }

  @computed
  get actions(): ReactElement {
    return (
      <div className="actions">
        <button
          className={cx('btn btn-primary next-button', {
            'custom-form-next-btn': this.props.isCustomForm,
          })}
          id="member-create-submit-btn"
          data-track="align-submit-assessment__submit"
          disabled={this.isSubmitDisabled}
        >
          {this.props.isCustomForm ? 'Next' : 'Submit'}
          {this.props.isCustomForm && !this.isSubmittingAnswers && <RightCircleFilled />}
          {this.isSubmittingAnswers && <LoadingOutlined />}
        </button>
      </div>
    );
  }

  @computed
  get renderCustomForm(): ReactElement {
    const { name, email } = this.errors;
    if (!this.isGuest) {
      return (
        <div>
          <div className="existing-member-details">
            Submitting as <strong>{this.currentMember?.firstName}</strong>
            {!this.exercise.currentUserHasAcceptedPolicy && this.policyAcceptanceField}
          </div>

          <p>
            Not you?&nbsp;
            <a href="#" onClick={this.handleSignout}>
              Click here to sign out
            </a>
            &nbsp; and you can submit under your own details. Your answers will not be lost!
          </p>
        </div>
      );
    }

    return (
      <div>
        <div className="member-form">
          <div className="form-group">
            <TextField
              inputId="name"
              type="text"
              placeHolder="Your name"
              label="Your name:"
              value={this.name}
              onValueChange={this.setName}
              error={name}
              required
            />
          </div>
          <div className="form-group">
            <TextField
              inputId="email"
              type="email"
              label="Your work email:"
              placeHolder="Your work email address"
              value={this.email}
              onValueChange={this.setEmail}
              error={email}
              required
            />
          </div>
          {this.policyAcceptanceField}
        </div>
      </div>
    );
  }

  @computed
  get renderForm(): ReactNode {
    if (this.props.isCustomForm) {
      return this.renderCustomForm;
    }

    return (
      <div>
        {this.isGuest && (
          <>
            <p>To submit your team assessment, just confirm your details.</p>
            {this.guestMemberForm}
          </>
        )}

        {!this.isGuest && (
          <div>
            <p>
              Submitting as <strong>{this.currentMember?.firstName}</strong>
            </p>
            <p>
              Not you?&nbsp;
              <a href="#" onClick={this.handleSignout}>
                Click here to sign out
              </a>
              &nbsp; and you can submit under your own details. Your answers will not be lost!
            </p>
          </div>
        )}
      </div>
    );
  }

  @computed
  get content(): ReactElement {
    return (
      <div>
        <div className="exercise-step-two-banner-content">
          <form onSubmit={preventDefault(this.handleFormSubmit)}>
            {this.renderForm}
            <div className="division" />
            {this.actions}
          </form>
        </div>
      </div>
    );
  }

  render(): ReactElement {
    return <div>{this.content}</div>;
  }
}

export default inject(
  STORE_ALIGN,
  STORE_AUTH,
  STORE_EXERCISE_ANSWERS,
  STORE_MEMBER,
  STORE_MEMBER_CONFIRMATION
)(observer(ExerciseSubmitContent));
