import React from 'react';

import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { observer } from 'mobx-react';

import './CommitToImproveCommitment.scss';

import { preventDefault } from 'app/helpers';
import { ExerciseReportModel } from 'app/models';
import ExerciseCommitmentModel, {
  ExerciseCommitmentCategory,
} from 'app/models/ExerciseCommitmentModel';
import ExerciseCommitmentStore from 'app/stores/ExerciseCommitmentStore';

interface CommitToImproveCommitmentEmptyProps {
  index: number;
  selectStatementLabel?: string;
  noPreview?: boolean;
  onClickSelectStatement: () => void;
  changeTitle: string;
  challengeTitle: string;
}

const CommitToImproveCommitmentEmpty: React.FC<CommitToImproveCommitmentEmptyProps> = ({
  index,
  selectStatementLabel,
  noPreview,
  onClickSelectStatement,
  changeTitle,
  challengeTitle,
}) => {
  const noBottomMargin = index != 0;
  const label = selectStatementLabel ?? 'Select and reflect on a statement';

  return (
    <>
      <div
        className={cx('commit-to-improve-select-statement-button', { 'mb-0': noBottomMargin })}
        onClick={onClickSelectStatement}
      >
        <PlusCircleOutlined className="add-icon" />
        <span>{label}</span>
      </div>
      {!noPreview && (
        <div className="preview-container">
          <div className="preview-tile">
            <p className="preview-title">{changeTitle}</p>
            <p className="preview-content">Select a statement above to reflect on this</p>
          </div>
          <div className="divider" />
          <div className="preview-tile">
            <p className="preview-title">{challengeTitle}</p>
            <p className="preview-content"> Select a statement above to reflect on this</p>
          </div>
        </div>
      )}
    </>
  );
};

interface CommitToImproveCommitmentProps {
  exerciseCommitmentStore: ExerciseCommitmentStore;
  report: ExerciseReportModel;
  category: ExerciseCommitmentCategory;
  index: number;
  selectStatementLabel?: string;
  noPreview?: boolean;
  onClickSelectStatement: () => void;
  onClickEditCommitment: (commitment: ExerciseCommitmentModel) => void;
}

const CommitToImproveCommitment: React.FC<CommitToImproveCommitmentProps> = ({
  exerciseCommitmentStore,
  report,
  category,
  index,
  selectStatementLabel,
  noPreview,
  onClickSelectStatement,
  onClickEditCommitment,
}) => {
  const commitment = exerciseCommitmentStore.commitmentsForExerciseAndCategory(
    report.exercise.id,
    category
  )[index];

  const changeTitle =
    category === ExerciseCommitmentCategory.improve
      ? 'What would it be like to make this change?'
      : 'Why was this unexpected?';
  const challengeTitle =
    category === ExerciseCommitmentCategory.improve
      ? "What's holding me back?"
      : 'What will you do differently now?';

  if (!commitment) {
    return (
      <CommitToImproveCommitmentEmpty
        index={index}
        {...{
          selectStatementLabel,
          noPreview,
          onClickSelectStatement,
          changeTitle,
          challengeTitle,
        }}
      />
    );
  }

  const statement = report.statementsById[commitment.align_statement_id];

  const hasContent =
    commitment.category === ExerciseCommitmentCategory.improve ||
    commitment.category === ExerciseCommitmentCategory.summary;

  const noTitleMargin =
    hasContent || (commitment.category === ExerciseCommitmentCategory.celebrate && index === 1);

  return (
    <div key={commitment.id} className="commit-to-improve-commitment">
      <div
        className={cx('d-flex commitment-header-container', {
          'mb-0': noTitleMargin,
          'has-content': hasContent,
        })}
      >
        <div className="d-flex flex-fill">
          <span>{statement.text}</span>
        </div>
        <div>
          <a
            className="edit"
            href="#"
            onClick={preventDefault(() => onClickEditCommitment(commitment))}
          >
            <EditOutlined className="edit-icon" /> Edit
          </a>
        </div>
      </div>

      {hasContent && (
        <div className="d-flex commitment-content-container">
          <div className="flex-fill commitment-content-section">
            <h5>{changeTitle}</h5>
            <p>{commitment.commitments.change}</p>
          </div>

          <div className="flex-fill commitment-content-section">
            <h5>{challengeTitle}</h5>
            <p>{commitment.commitments.challenge}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(CommitToImproveCommitment);
