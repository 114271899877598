import ExerciseStatusModel from 'app/models/ExerciseStatusModel';

import Store from './Store';

export class ExerciseStatusStore extends Store<ExerciseStatusModel> {
  constructor() {
    super();
    ExerciseStatusModel._store = this;
  }
}

export default ExerciseStatusStore;
