import React from 'react';

import PointerChart from './PointerChart/PointerChart';
import TimeSeriesChart from './TimeSeriesChart/TimeSeriesChart';

function LineChart({ data, glyphData, width, displayTimeSeries }) {
  if (displayTimeSeries) {
    return <TimeSeriesChart data={data} width={width} />;
  }

  return <PointerChart data={data} glyphData={glyphData} width={width} />;
}

export default LineChart;
