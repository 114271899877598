import React from 'react';

import CarouselModalComponent from 'app/components/ui/CarouselModalComponent';

export interface WhatIsAlignModalProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete?: () => void;
  completeDisabled?: boolean;
  disableInfoText?: string;
}

const SLIDES = [
  {
    header: 'The Align exercise helps you diagnose your team’s areas of focus',
    content:
      'It offers a research backed approach, helping you uncover the issues that matter most to your team.',
    iconSrc: '/images/align/what-is-align-slide-image.svg',
  },
  {
    header: 'The exercise takes about two minutes to complete.',
    content:
      'It’s a drag-and-drop tool designed to help you discover what’s holding your team back. You’ll be asked to rank 16 statements to evaluate what is important to your team and if you are doing them well.',
    iconSrc: '/images/align/what-is-align-slide-image.svg',
  },
  {
    header: 'After completing the exercise, we’ll uncover your team’s areas of focus.',
    content:
      'We provide a report with personal and team insights. These areas of focus help identify where the team agrees or sees things differently.',
    iconSrc: '/images/align/what-is-align-slide-image.svg',
    iconTopMargin: '1.75rem',
  },
];

export const WhatIsAlignModal: React.StatelessComponent<WhatIsAlignModalProps> = ({
  isOpen,
  onComplete,
  onClose,
}) => (
  <CarouselModalComponent
    title="What is the Align exercise?"
    isOpen={isOpen}
    onClose={onClose}
    onComplete={onComplete}
    completeLabel="Got it"
    slides={SLIDES}
  />
);

export default WhatIsAlignModal;
