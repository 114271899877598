import React, { ReactElement } from 'react';

import { Button } from 'antd';

import './DiscoverHabitBuilder.scss';

import { ServerRouteHelper } from 'app/helpers';

export const DiscoverHabitBuilder = ({ reportMemberId, isTestDrive }): ReactElement => {
  return (
    <div className="discover-habit-builder">
      <div className="discover-habit-builder-content">
        <h4>We're big believers in practicing</h4>
        <p>
          Discover a low-risk way to work on an area that needs improvement and get feedback on how
          you're doing.
        </p>
      </div>
      <Button
        type="primary"
        size="middle"
        disabled={isTestDrive}
        href={ServerRouteHelper.dashboard.myself.personalHabitsNew('add')}
      >
        Discover Habit Builder
      </Button>
    </div>
  );
};

export default DiscoverHabitBuilder;
