import { TeamPerspectiveResultModel } from 'app/models';

import Store from './Store';

export class TeamPerspectiveResultStore extends Store<TeamPerspectiveResultModel> {
  constructor() {
    super();
    TeamPerspectiveResultModel._store = this;
  }
}

export default TeamPerspectiveResultStore;
