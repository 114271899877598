import ShiftModel from './ShiftModel';

export interface DiscussionGuideTypeModelProps {
  id: string;
  icon: string;
  name: string;
  description: string;
  duration: number;
}

export class DiscussionGuideTypeModel extends ShiftModel<DiscussionGuideTypeModelProps> {
  id: string;
  icon: string;
  name: string;
  description: string;
  duration: number;
}

export default DiscussionGuideTypeModel;
