import React from 'react';

import cx from 'classnames';

import './Spinner.scss';

interface SpinnerProps {
  isLoading: boolean;
  isPageSpinner?: boolean;
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ isLoading, isPageSpinner, className }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <i
      className={cx('fas fa-spinner fa-spin spinner', className, {
        'page-spinner': isPageSpinner,
      })}
    />
  );
};

export default Spinner;
