/**
 * autoTag tells `wrapTags` to automatically generate the tag for
 * a particular instance. This should cover 99% of the use case and
 * can be used so long as you don't want to define a custom tag
 */
export const autoTag = '*';

/**
 * `nestedTag` tells the TagHelper test to search for a tag that is being
 * passed as an object. This is useful if your reusable component accepts a list of
 * multiple tags.
 * @param object
 */
export const nestedTag = <T>(object: T): T => {
  (object as any).__nested = true;
  return object;
};

/**
 * `wrapTags` transforms tags marked as `autoTag` into key paths that are human
 * readable strings. The key that is generated will be the same as the js object
 * key path. So TagHelper.Auth.Login.SubmitButton will have the string
 * Auth.LoginPage.SubmitButton
 * @param object - The object that contains the tags you want to transform
 * @param keys - Optional key prefix (used internally during recursion)
 */
export const wrapTags = <T>(object: T, keys: string[] = []): T => {
  if (typeof object === 'object') {
    for (const key in object) {
      object[key] = wrapTags(object[key], [...keys, key]);
    }

    return object;
  }

  if ((object as any) === autoTag) {
    return keys.join('.') as any;
  }

  return object;
};
