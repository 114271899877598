import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import MemberRatingStore from 'app/stores/MemberRatingStore';

export enum MemberRatingTypes {
  PairPerspectiveLens = 'pair_perspective_lens',
  AlignPersonalResults = 'align_personal_results',
}

export enum MemberRatingKeys {
  SpotOn = 'spot_on',
  MostlyYes = 'mostly_yes',
  HitAndMiss = 'hit_and_miss',
  NotReally = 'not_really',
  PersonalResultsHelpful = 'personal_results_helpful',
}

export const MemberRatingKeysLabels = {
  [MemberRatingKeys.SpotOn]: 'This describes us well!',
  [MemberRatingKeys.MostlyYes]: "Let's talk about this!",
  [MemberRatingKeys.HitAndMiss]: 'I wonder if this is really us?',
  [MemberRatingKeys.NotReally]: "I don't think this describes me",
};

export class MemberRatingModel extends Model {
  public static _store: MemberRatingStore;

  @observable public id: number;
  @observable public member_id: number;
  @observable public type: MemberRatingTypes;
  @observable public rating_key: string;
  @observable public rating: string;
  @observable public created_at: string;
  @observable public updated_at: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as MemberRatingModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as MemberRatingModel;
  }

  static get(id) {
    return this._get(id) as MemberRatingModel;
  }
}

export default MemberRatingModel;
