import React, { Component, ReactNode } from 'react';

import { computed } from 'mobx';
import moment from 'moment';

import { SessionStorageHelper } from 'app/helpers';
import ThirdPartyService from 'app/services/ThirdPartyService';

const LAZY_LOAD_REFRESH_KEY = 'lazy_load_refresh_counter';
const LAZY_LOAD_LAST_UPDATED_KEY = 'lazy_load_refresh_last_updated';
const RETRY_ATTEMPTS = 5;
const COUNTER_RESET_THRESHOLD_HOURS = 1;

class LazyLoadErrorBoundary extends Component<
  { children: ReactNode },
  { giveUp: boolean; online: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { giveUp: false, online: true };
  }

  static getDerivedStateFromError(error) {
    return {
      giveUp: LazyLoadErrorBoundary.currentCounter >= RETRY_ATTEMPTS,
      online: window.navigator.onLine,
    };
  }

  componentDidCatch(error, errorInfo): void {
    if (!this.state.online) {
      return;
    }

    // Keep updating counter and reloading until we hit max attempts
    if (LazyLoadErrorBoundary.currentCounter < RETRY_ATTEMPTS) {
      LazyLoadErrorBoundary.updateCounter();
      window.location.reload();
      return;
    }

    ThirdPartyService.sentry.withScope((scope) => {
      scope.setExtra('error', error);
      scope.setExtra('errorInfo', errorInfo);

      ThirdPartyService.sentry.captureException(
        new Error(`LazyLoadErrorBoundary exhausted reload threshold: ${error.message}`)
      );
    });

    // Once we hit max attempts, if the last update of counter was an hour ago,
    // allow resetting counter so at most we have this refresh cycle once per hour.
    if (LazyLoadErrorBoundary.shouldResetCounter()) {
      LazyLoadErrorBoundary.resetCounter();
      window.location.reload();
    }
  }

  static get currentCounter(): number {
    return SessionStorageHelper.get(LAZY_LOAD_REFRESH_KEY) || 0;
  }

  static updateCounter(): void {
    SessionStorageHelper.set(LAZY_LOAD_REFRESH_KEY, this.currentCounter + 1);
    SessionStorageHelper.set(LAZY_LOAD_LAST_UPDATED_KEY, moment().unix());
  }

  static shouldResetCounter(): boolean {
    // If the last update was more than an hour, allow resetting of counter
    const lastUpdated = moment.unix(SessionStorageHelper.get(LAZY_LOAD_LAST_UPDATED_KEY));
    return moment().diff(lastUpdated, 'hours') > COUNTER_RESET_THRESHOLD_HOURS;
  }

  static resetCounter(): void {
    SessionStorageHelper.set(LAZY_LOAD_REFRESH_KEY, 0);
  }

  @computed
  get offlineMessage(): ReactNode {
    return (
      <div className="p-5">
        <h6>Sorry, you seem to be offline.</h6>
        <p>
          We can't load the site because you're currently offline, please check your internet
          connection and refresh this page.
        </p>
      </div>
    );
  }

  @computed
  get giveUpMessage(): ReactNode {
    return (
      <div className="p-5">
        <h6>
          Sorry, something seems to have gone wrong. To continue, please try refreshing this page.
        </h6>
        <p>
          <strong>
            If refreshing doesn't work, please try clearing your browser's cache through the
            following steps:
          </strong>
        </p>
        <ul>
          <li>
            Press the <kbd>Ctrl</kbd> (or <kbd>Cmd</kbd> on Mac) and <kbd>F5</kbd> keys together.
            <ul>
              <li>If you have an Fn key on your keyboard:</li>
              <ul>
                <li>
                  Press and hold the <kbd>Fn</kbd> key, then press the <kbd>F5</kbd> key.
                </li>
              </ul>
            </ul>
          </li>
          <li>If that doesn't help, you can try a hard refresh:</li>
          <ul>
            <li>
              For most browsers: <kbd>Ctrl</kbd> (or <kbd>Cmd</kbd>) + <kbd>Shift</kbd> +
              <kbd>R</kbd>
            </li>
            <li>
              Apple Safari: Hold down the <kbd>Shift</kbd> key and click the Reload toolbar button.
            </li>
          </ul>
          <li>If the issue persists, consider clearing your browser's cache:</li>
          <ul>
            <li>
              Chrome: <kbd>Ctrl</kbd> (or <kbd>Cmd</kbd>) + <kbd>Shift</kbd> + <kbd>Delete</kbd>,
              then select "Cached images and files" and click "Clear data".
            </li>
            <li>
              Firefox: <kbd>Ctrl</kbd> (or <kbd>Cmd</kbd>) + <kbd>Shift</kbd> + <kbd>Delete</kbd>,
              Check "Cache" then select "Everything" from the dropdown and click "Clear Now".
            </li>
          </ul>
        </ul>
      </div>
    );
  }

  render(): ReactNode {
    if (!this.state.online) {
      return this.offlineMessage;
    }

    if (this.state.giveUp) {
      return this.giveUpMessage;
    }

    return this.props.children;
  }
}

export default LazyLoadErrorBoundary;
