import React from 'react';

import { CoffeeOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { ButtonType } from 'antd/lib/button';
import cx from 'classnames';
import { chunk } from 'lodash';
import { action, computed, observable, when } from 'mobx';
import { inject, observer } from 'mobx-react';
import numeral from 'numeral';

import './CommitToImproveSolidifyReflections.scss';

import EnhancedCard from 'app/components/ui/EnhancedCard';
import { ENTITY_STATES, STORE_EXERCISE_COMMITMENT } from 'app/constants';
import { EntityStateAssociationType } from 'app/models/EntityStateModel';
import ExerciseCommitmentModel, {
  ExerciseCommitmentCategory,
  ExerciseCommitmentContentKey,
} from 'app/models/ExerciseCommitmentModel';
import { ExerciseActivityActions } from 'app/stores';
import ExerciseCommitmentStore from 'app/stores/ExerciseCommitmentStore';

import CommitToImproveModal from '../../components/CommitToImproveModal';
import { CommitToImproveModalStep } from '../../components/CommitToImproveModal/CommitToImproveModal';
import My360ReportUIStore from '../../My360ReportUIStore';
import CommitToImproveCommitment from './CommitToImproveCommitment';

interface CommitToImproveContainerProps {
  uiStore: My360ReportUIStore;
  exerciseCommitmentStore?: ExerciseCommitmentStore;
}

class CommitToImproveSolidifyReflections extends React.Component<CommitToImproveContainerProps> {
  @observable expectedResultAnswer: number;
  @action setExpectedResultAnswer = (expectedResultAnswer) =>
    (this.expectedResultAnswer = expectedResultAnswer);

  @observable howYouFeelAnswers: number[] = [];
  @action setHowYouFeelAnswers = (howYouFeelAnswers) =>
    (this.howYouFeelAnswers = howYouFeelAnswers);

  constructor(props) {
    super(props);
    this.init();
  }

  async init() {
    const { memberEntityStates } = this.props.uiStore.entityStateStore;

    when(
      () => memberEntityStates.loaded,
      () => {
        this.restoreSavedExpectedResultAnswer();
        this.restoreSavedHowYouFeelAnswers();
      }
    );
  }

  restoreSavedExpectedResultAnswer = () => {
    const { memberEntityStates } = this.props.uiStore.entityStateStore;
    const expectedResultState = memberEntityStates.items.find(
      ({ type }) => type === ENTITY_STATES.MY360_EXPECTED_RESULT
    );

    if (!expectedResultState) {
      return;
    }

    this.setExpectedResultAnswer(numeral(expectedResultState.meta?.value).value());
  };

  restoreSavedHowYouFeelAnswers = () => {
    const { memberEntityStates } = this.props.uiStore.entityStateStore;
    const expectedResultState = memberEntityStates.items.find(
      ({ type }) => type === ENTITY_STATES.MY360_HOW_YOU_FEEL
    );

    if (!expectedResultState) {
      return;
    }

    const answers = (expectedResultState.meta?.value ?? []).map((value) => {
      return numeral(value).value();
    });

    this.setHowYouFeelAnswers(answers);
  };

  @computed
  get explainerCard() {
    return (
      <div className="explainer">
        <p>
          A few reflection questions will help you translate your report into action steps. Walk
          through the questions below and we'll suggest practices you can get feedback on.
        </p>
      </div>
    );
  }

  @computed
  get expectedResults() {
    const labels = [
      'Much better than expected',
      'Slightly better than expected',
      'Pretty much what I expected',
      'Slightly worse than expected',
      'Much worse than expected',
    ];

    return (
      <div className="reflection-section expected-results">
        <h3 className="title-text">
          <strong>Were your results what you had expected?</strong> Select the one that matches
          best.
        </h3>
        {labels.map((label, i) => {
          const value = i + 1;
          const isActive = value === this.expectedResultAnswer;

          return (
            <Button
              key={i}
              className={cx({ active: isActive })}
              onClick={() => this.handleExpectedResult(value)}
            >
              {label}
            </Button>
          );
        })}
      </div>
    );
  }

  handleExpectedResult = async (value: number) => {
    const { token, exercise, exerciseReportStore, entityStateStore } = this.props.uiStore;

    const meta = { value: this.expectedResultAnswer };

    this.setExpectedResultAnswer(value);

    await Promise.all([
      exerciseReportStore.recordExerciseActivity(
        token,
        exercise.id,
        ExerciseActivityActions.My360AnsweredExpectedResult,
        meta
      ),

      // Unfortunately we still need to record on
      // entityState to remember what the user's answer was
      entityStateStore.upsertMemberOwnedEntityState(
        EntityStateAssociationType.Exercise,
        exercise.id,
        {
          type: ENTITY_STATES.MY360_EXPECTED_RESULT,
          meta,
        },
        token
      ),
    ]);
  };

  @computed
  get howYouFeel() {
    const labels = chunk(
      [
        'Relieved',
        'Happy',
        'Excited',
        'Curious',
        'Hopeful',
        'Hopeless',
        'Worried',
        'Confused',
        'Sad',
        'Angry',
      ],
      5
    );

    return (
      <div className="reflection-section how-you-feel">
        <h3 className="title-text">
          <strong>How do you feel?</strong> Select all that apply.
        </h3>
        <div>
          {labels.map((group, i) => (
            <div key={i} className={`${i === 0 ? 'left' : 'right'}-pane`}>
              {group.map((label, j) => {
                // When:
                //   i is 0 = left column,
                //   i is 1 = right column
                // Left column:
                //   1+(0*5), 2+(0*5) ... yields 1-5 values
                // Right column:
                //   1+(1*5), 2+(1*5) ... yields 6-10 values
                const value = j + 1 + i * 5;
                const isActive = this.howYouFeelAnswers.includes(value);

                return (
                  <Button
                    key={value}
                    className={cx({ active: isActive })}
                    onClick={(event) => this.handleHowYouFeel(value)}
                  >
                    {label}
                  </Button>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  }

  @action
  handleHowYouFeel = async (value: number) => {
    const { token, exercise, exerciseReportStore, entityStateStore } = this.props.uiStore;

    const exists = this.howYouFeelAnswers.includes(value);

    if (exists) {
      this.setHowYouFeelAnswers(this.howYouFeelAnswers.filter((val) => val !== value));
    } else {
      this.howYouFeelAnswers.push(value);
    }

    const meta = { value: this.howYouFeelAnswers };

    await Promise.all([
      exerciseReportStore.recordExerciseActivity(
        token,
        exercise.id,
        ExerciseActivityActions.My360AnsweredHowYouFeel,
        meta
      ),

      entityStateStore.upsertMemberOwnedEntityState(
        EntityStateAssociationType.Exercise,
        exercise.id,
        {
          type: ENTITY_STATES.MY360_HOW_YOU_FEEL,
          meta,
        },
        token
      ),
    ]);
  };

  @computed
  get mostProud() {
    const { uiStore, exerciseCommitmentStore } = this.props;
    const category = ExerciseCommitmentCategory.celebrate;

    return (
      <div className="reflection-section">
        <h3 className="title-text">What made you most proud?</h3>

        <CommitToImproveCommitment
          exerciseCommitmentStore={exerciseCommitmentStore}
          report={uiStore.report}
          category={category}
          index={0}
          selectStatementLabel="Pin a statement"
          noPreview
          onClickEditCommitment={this.handleLaunchModalForExistingCommitment}
          onClickSelectStatement={() => this.handleLaunchModalForNewCommitment(category)}
        />
      </div>
    );
  }

  @computed
  get yourStrengthsWeakness() {
    const { uiStore, exerciseCommitmentStore } = this.props;
    const category = ExerciseCommitmentCategory.summary;

    return (
      <div className="reflection-section">
        <h3 className="title-text">What was an unexpected strength or weakness?</h3>

        <CommitToImproveCommitment
          exerciseCommitmentStore={exerciseCommitmentStore}
          report={uiStore.report}
          category={category}
          index={0}
          onClickEditCommitment={this.handleLaunchModalForExistingCommitment}
          onClickSelectStatement={() => this.handleLaunchModalForNewCommitment(category)}
        />
      </div>
    );
  }

  @computed
  get yourPriority() {
    const { uiStore, exerciseCommitmentStore } = this.props;
    const category = ExerciseCommitmentCategory.improve;

    return (
      <div className="reflection-section">
        <h3 className="title-text">
          What is your top priority to focus on improving going forward?
        </h3>

        <CommitToImproveCommitment
          exerciseCommitmentStore={exerciseCommitmentStore}
          report={uiStore.report}
          category={category}
          index={0}
          onClickEditCommitment={this.handleLaunchModalForExistingCommitment}
          onClickSelectStatement={() => this.handleLaunchModalForNewCommitment(category)}
        />
      </div>
    );
  }

  handleLaunchModalForNewCommitment = (category: ExerciseCommitmentCategory) => {
    const { uiStore } = this.props;

    const model = new ExerciseCommitmentModel(null);
    model.category = category;
    model.exercise_id = uiStore.exercise.id;

    uiStore.setCommitToImproveModalStep(CommitToImproveModalStep.SELECT_STATEMENT);
    uiStore.setCommitToImproveModalExerciseCommitment(model);
    uiStore.setShowCommitToImproveModal(true);
  };

  handleLaunchModalForExistingCommitment = (commitment: ExerciseCommitmentModel) => {
    const { uiStore } = this.props;

    const step =
      commitment.category === ExerciseCommitmentCategory.celebrate
        ? CommitToImproveModalStep.SELECT_STATEMENT
        : CommitToImproveModalStep.WRITE_CHALLENGE;

    uiStore.setCommitToImproveModalStep(step);
    uiStore.setCommitToImproveModalExerciseCommitment(commitment);
    uiStore.setShowCommitToImproveModal(true);
  };

  handleToggleModal = () => {
    const { uiStore } = this.props;

    this.checkCanToggleModal(() => {
      uiStore.setShowCommitToImproveModal(!uiStore.showCommitToImproveModal);
    });
  };

  checkCanToggleModal = (callback: () => void) => {
    const { uiStore } = this.props;

    const content =
      uiStore.commitToImproveModalExerciseCommitment.commitments.challenge ||
      uiStore.commitToImproveModalExerciseCommitment.commitments.change;

    const id = uiStore.commitToImproveModalExerciseCommitment.id;

    if (!content || id) {
      callback();
      return;
    }

    const message =
      'Are you sure you want to exit? You will loose all saved answers associated with this statement.';

    Modal.confirm({
      content: message,
      okText: 'Yes',
      cancelText: 'No',
      onOk: callback,
      zIndex: 1050,
    });
  };

  checkCanGoBack = (contentKey: ExerciseCommitmentContentKey, callback: () => void) => {
    const { uiStore } = this.props;

    const hasContent =
      (uiStore.commitToImproveModalExerciseCommitment.commitments[contentKey] || '').length > 0;

    if (!hasContent) {
      callback();
      return;
    }

    const message =
      'Are you sure you want to select a new statement? You will lose all saved answers associated with this statement.';

    Modal.confirm({
      content: message,
      okText: 'Yes',
      cancelText: 'No',
      onOk: callback,
      zIndex: 1050,
    });
  };

  handleSubmit = async () => {
    const { uiStore, exerciseCommitmentStore } = this.props;

    uiStore.setCommitToImproveIsSubmitting(true);

    const promise = uiStore.commitToImproveModalExerciseCommitment.id
      ? exerciseCommitmentStore.updateExerciseCommitment(
          uiStore.commitToImproveModalExerciseCommitment,
          uiStore.token
        )
      : exerciseCommitmentStore.createExerciseCommitment(
          uiStore.commitToImproveModalExerciseCommitment,
          uiStore.token
        );

    await Promise.all([
      uiStore.exerciseReportStore.recordExerciseActivity(
        uiStore.token,
        uiStore.exercise.id,
        uiStore.commitToImproveModalExerciseAction,
        uiStore.commitToImproveModalExerciseCommitment
      ),

      promise,
    ]);

    uiStore.setShowCommitToImproveModal(false);
    uiStore.setCommitToImproveIsSubmitting(false);
  };

  render() {
    const { uiStore } = this.props;

    const {
      exerciseReportStore,
      showCommitToImproveModal,
      commitToImproveModalExerciseCommitment,
      commitToImproveModalStep,
    } = uiStore;

    const reflectionSampleButton = {
      label: 'Show me a Reflection sample',
      onClick: this.props.uiStore.toggleSampleReflectionsDrawer,
      type: 'ghost' as ButtonType,
      className: 'reflect-sample-btn',
    };

    return (
      <div className="solidify-your-reflections-container">
        <EnhancedCard
          heading="Reflect on your own"
          headerIcon={<CoffeeOutlined className="reflect-header-icon" />}
          headerButton={reflectionSampleButton}
        >
          <div className="enhanced-card-body">
            {this.explainerCard}
            {this.expectedResults}
            {this.howYouFeel}
            {this.mostProud}
            {this.yourStrengthsWeakness}
            {this.yourPriority}
          </div>
        </EnhancedCard>

        {exerciseReportStore.report && (
          <CommitToImproveModal
            isSubmitting={uiStore.commitToImproveIsSubmitting}
            isOpen={showCommitToImproveModal}
            report={exerciseReportStore.report}
            exerciseCommitment={commitToImproveModalExerciseCommitment}
            step={commitToImproveModalStep}
            onToggle={this.handleToggleModal}
            onChangeStep={uiStore.setCommitToImproveModalStep}
            onSubmit={this.handleSubmit}
            checkCanGoBack={this.checkCanGoBack}
            isTestDrive={uiStore.isTestDrive}
          />
        )}
      </div>
    );
  }
}

export default inject(STORE_EXERCISE_COMMITMENT)(observer(CommitToImproveSolidifyReflections));
