import React, { Component, ReactElement } from 'react';

import { Menu } from 'antd';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './NavMenuList.module.scss';

import { AppLayoutUiStore, MenuItemUiStore } from 'app/components/features/AppLayout/stores';
import FakeBox from 'app/components/ui/FakeBox';
import { STORE_APP_LAYOUT_UI, STORE_MENU } from 'app/constants';
import { MenuModel } from 'app/models';
import { MenuStore } from 'app/stores';

export interface NavMenuListProps {
  menuStore?: MenuStore;
  appLayoutUiStore?: AppLayoutUiStore;

  menu?: MenuModel;
}

export class NavMenuList extends Component<NavMenuListProps> {
  protected menuItemUiStore: MenuItemUiStore;

  constructor(props: NavMenuListProps) {
    super(props);
    this.menuItemUiStore = new MenuItemUiStore(styles);
  }

  @computed
  get isLoading(): boolean {
    return this.props.menuStore.isLoadingMenu;
  }

  handleSidebarNavMenuOffClick = (): void => {
    this.props.appLayoutUiStore.toggleSidebarNavMenu();
  };

  @computed
  get hasNoMenu(): boolean {
    return !this.props.menu || this.props.menu.menu_items.length === 0;
  }

  @computed
  get currentKey(): string {
    const item = this.props.appLayoutUiStore.currentMenuItem();
    return `menu-link-${item?.id}`;
  }

  render(): ReactElement {
    if (this.isLoading) {
      return (
        <>
          <FakeBox className={styles.navMenuFakeLoadingItem} />
          <FakeBox className={styles.navMenuFakeLoadingItem} />
          <FakeBox className={styles.navMenuFakeLoadingItem} />
        </>
      );
    }

    if (this.hasNoMenu) {
      return null;
    }

    // Warning: [antd: Menu] `children` will be removed in next major version. Please use `items` instead.
    // we need to rewrite our usage of `Menu` so that we pass items using `items` attribute instead of the current method
    return (
      <Menu
        mode="vertical"
        className={styles.navMenuList}
        triggerSubMenuAction="click"
        onOpenChange={this.handleSidebarNavMenuOffClick}
        selectedKeys={[this.currentKey]}
      >
        {this.props.menu.menu_items.map((item, index) =>
          this.menuItemUiStore.makeItem(item, index)
        )}
      </Menu>
    );
  }
}

export default inject(STORE_APP_LAYOUT_UI, STORE_MENU)(observer(NavMenuList));
