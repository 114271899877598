import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import AnalyticsFiltersStore from 'app/stores/AnalyticsFiltersStore';

interface SegmentFilterOption {
  id: string;
  name: string;
  depends_on?: string;
}

export class AnalyticsFilterModel extends Model {
  static _store: AnalyticsFiltersStore;

  @observable id: string;
  @observable name: string;
  @observable options: SegmentFilterOption[];
  @observable depends_on?: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as AnalyticsFilterModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as AnalyticsFilterModel;
  }

  static get(id) {
    return this._get(id) as AnalyticsFilterModel;
  }
}

export default AnalyticsFilterModel;
