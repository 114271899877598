import { computed, observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { OrganizationReportRollupStore } from 'app/stores';

import ExerciseTypeModel from './ExerciseTypeModel';

export class OrganizationReportRollupTeamGroupModel extends Model {
  static _store: OrganizationReportRollupStore;

  @observable id: number;
  @observable name: string;
  @observable exercise_types: Partial<ExerciseTypeModel>[];
  @observable dependent_team_groups?: OrganizationReportRollupTeamGroupModel[];
  @observable dependent_group_id?: number;
  @observable is_overall?: boolean;

  deserialize_dependent_team_groups(teamGroups) {
    this.dependent_team_groups = teamGroups.map((teamGroup) =>
      OrganizationReportRollupTeamGroupModel.fromJson(teamGroup)
    );
  }

  // Return child team groups with exercise types
  @computed
  get validTeamGroups() {
    return this.dependent_team_groups?.filter((teamGroup) => {
      return teamGroup.exercise_types?.length > 0;
    });
  }

  static fromJson(json: ModelJson): OrganizationReportRollupTeamGroupModel {
    return this._fromJson(json) as OrganizationReportRollupTeamGroupModel;
  }

  static getOrNew(id: number | string): OrganizationReportRollupTeamGroupModel {
    return this._getOrNew(id) as OrganizationReportRollupTeamGroupModel;
  }

  static get(id: number | string): OrganizationReportRollupTeamGroupModel {
    return this._get(id) as OrganizationReportRollupTeamGroupModel;
  }

  @computed
  get isOverall(): boolean {
    return !!this.is_overall;
  }

  @computed
  get isSelectAll(): boolean {
    return this.name === 'Select all';
  }
}

export default OrganizationReportRollupTeamGroupModel;
