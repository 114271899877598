import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { PulseTemplateGroupModel } from 'app/models';
import { ModelList } from 'app/models/ModelList';
import Store from 'app/stores/Store';

export class PulseTemplateGroupStore extends Store<PulseTemplateGroupModel> {
  @observable templateGroups = new ModelList<PulseTemplateGroupModel>(PulseTemplateGroupModel);

  constructor() {
    super();
    PulseTemplateGroupModel._store = this;
  }

  loadTemplateGroups = (memberId: number): Promise<any> => {
    return this.templateGroups.load(ServerRouteHelper.api.pulses.templateGroups.member(memberId));
  };

  loadTeamTemplateGroups = (teamId: number, templateSourceTypeWithId?: string): Promise<any> => {
    return this.templateGroups.load(
      ServerRouteHelper.api.pulses.templateGroups.team(teamId, templateSourceTypeWithId)
    );
  };
}

export default PulseTemplateGroupStore;
