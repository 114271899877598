import React from 'react';

import { Carousel } from 'antd';
import cx from 'classnames';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';

import './CarouselModalComponent.scss';

import AntModal from 'app/components/ui/AntModal';

interface Slide {
  title?: string;
  header: string;
  headerStyle?: Record<string, unknown>;
  content?: string;
  contentStyle?: Record<string, unknown>;
  iconSrc: string;
  iconStyle?: Record<string, unknown>;
}

export interface CarouselModalComponentProps {
  title: string;
  slides: Slide[];
  isOpen: boolean;
  onClose: () => void;
  onComplete?: () => void;
  completeLabel?: string;
  previousLabel?: string;
  nextLabel?: string;
  completeDisabled?: boolean;
  dataTrackPrefix?: string;
  className?: string;
  tags?: CarouselModalComponentTags;
}

export interface CarouselModalComponentTags {
  nextButton: string;
  closeButton: string;
  previousButton: string;
}

const SWITCH_DELAY = 500;
const FIRST_SLIDE_INDEX = 0;

export class CarouselModalComponent extends React.Component<CarouselModalComponentProps> {
  @observable private isSwitching = false;
  @action private setSwitching = (isSwitching) => (this.isSwitching = isSwitching);

  @observable private slideIndex = FIRST_SLIDE_INDEX;
  @action private setSlideIndex = (index) => (this.slideIndex = index);

  @observable private carousel;
  @action private setCarousel = (carousel) => (this.carousel = carousel);

  COMPLETE_LABEL = this.props.completeLabel ?? 'Get Started';
  NEXT_LABEL = this.props.nextLabel ?? 'Next';
  PREVIOUS_LABEL = this.props.previousLabel ?? 'Back';

  @computed
  get isLastSlide() {
    return this.slideIndex === this.props.slides.length - 1;
  }

  @computed
  get isFirstSlide() {
    return this.slideIndex === 0;
  }

  private handleToggle = () => {
    this.setSlideIndex(FIRST_SLIDE_INDEX);
    this.props.onClose();
  };

  private renderSlide = (
    { header, headerStyle, content, contentStyle, iconSrc, iconStyle }: Slide,
    id
  ) => (
    <div key={id}>
      <span className="base-carousel-modal-body">
        <img style={iconStyle} src={iconSrc} />
        <div className="base-carousel-content-header" style={headerStyle}>
          {header}
        </div>
        <div className="base-carousel-content" style={contentStyle}>
          {content}
        </div>
      </span>
    </div>
  );

  private onNextButtonClick = () => {
    this.switchSlides('next');
  };

  private onPrevButtonClick = () => {
    this.switchSlides('previous');
  };

  private switchSlides = (switchType) => {
    // don't allow next button clicks in quick succession
    if (this.isSwitching) {
      return;
    }

    // lock & switch
    this.setSwitching(true);
    if (switchType === 'next') {
      this.carousel.next();
      this.setSlideIndex((this.slideIndex + 1) % (this.props.slides.length - 1 + 1));
    } else if (switchType === 'previous') {
      this.carousel.prev();
      this.setSlideIndex((this.slideIndex - 1) % (this.props.slides.length - 1 + 1));
    }

    // add delay before unlocking so that user can't switch slides faster than carousel can handle
    setTimeout(() => this.setSwitching(false), SWITCH_DELAY);
  };

  private get primaryAction() {
    const onModalClick = this.isLastSlide
      ? this.props.onComplete ?? this.handleToggle
      : this.onNextButtonClick;

    return {
      label: this.isLastSlide ? this.COMPLETE_LABEL : this.NEXT_LABEL,
      disabled: this.isSwitching ?? (this.isLastSlide && this.props.completeDisabled),
      onClick: onModalClick,
      id: 'primary-action',
      'data-tag': this.props.tags?.nextButton,
      'data-tag-context': `slide-${this.slideIndex}`,
      'data-track':
        this.props.dataTrackPrefix && `${this.props.dataTrackPrefix}-next-${this.slideIndex}-btn`,
    };
  }

  private get secondaryAction() {
    const onBackButtonClick = this.onPrevButtonClick;
    if (this.isFirstSlide) {
      return null;
    }

    return {
      label: this.PREVIOUS_LABEL,
      onClick: onBackButtonClick,
      id: 'secondary-action',
      'data-tag': this.props.tags?.previousButton,
      'data-tag-context': `slide-${this.slideIndex}`,
      'data-track':
        this.props.dataTrackPrefix && `${this.props.dataTrackPrefix}-next-${this.slideIndex}-btn`,
    };
  }

  @computed
  get title() {
    if (this.props.slides[this.slideIndex]?.title) {
      return this.props.slides[this.slideIndex].title;
    }

    return this.props.title;
  }

  public render() {
    return (
      <AntModal
        isOpen={this.props.isOpen}
        title={this.title}
        onToggle={this.handleToggle}
        className={cx('base-carousel-modal', this.props.className)}
        primaryAction={this.primaryAction}
        secondaryAction={this.secondaryAction}
        closeButtonTag={this.props.tags?.closeButton}
      >
        <Carousel
          afterChange={(currentIndex) => this.setSlideIndex(currentIndex)}
          ref={(carousel) => this.setCarousel(carousel)}
        >
          {this.props.slides.map(this.renderSlide)}
        </Carousel>
      </AntModal>
    );
  }
}

export default observer(CarouselModalComponent);
