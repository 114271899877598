import { notification } from 'antd';
import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem, ModelList, PagingMetaModel } from 'app/models';
import ProgramModel, { ProgramInitiative, ProgramStatus } from 'app/models/ProgramModel';

import Store from './Store';

export class ProgramStore extends Store<ProgramModel> {
  @observable isSaving = false;
  @action setIsSaving = (isSaving: boolean) => {
    this.isSaving = isSaving;
  };

  @observable programs = new ModelList<ProgramModel>(ProgramModel);
  @observable pageMeta: PagingMetaModel;
  @observable program = new ModelItem<ProgramModel>(ProgramModel);

  constructor() {
    super();
    ProgramModel._store = this;
  }

  @action
  createProgram = async (orgId: number, program: ProgramModel, duplicate = false) => {
    const url = ServerRouteHelper.api.onboarding.onBoardingLinks.create(orgId, program.type);
    const links = program.links.slice();
    const data = {
      name: duplicate ? `${program.name} (Copy)` : program.name,
      title: program.title,
      cta_text: program.cta_text,
      description: program.description,
      initiative: ProgramInitiative.PROGRAM,
      webinar_date: program.webinar_date,
      status: ProgramStatus.ACTIVE,
      links: links.filter((item) => item && item.title !== '' && item.url !== ''),
      exercise_type_id: program.exercise_type_id,
    };

    const config = {
      url,
      throwError: true,
      data: data,
    };

    this.setIsSaving(true);

    try {
      const response = await this.apiService.newPost(config);

      const program = ProgramModel.fromJson(response.data);
      this.programs.appendItem(program);

      notification.success({
        message: 'Success',
        description: 'Program created successfully',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Unable to create program',
      });
      throw error;
    } finally {
      this.setIsSaving(false);
    }
  };

  @action
  deleteProgram = async (program: ProgramModel): Promise<void> => {
    const url = ServerRouteHelper.api.onboarding.onBoardingLinks.delete(program.id);

    const config = {
      url,
      throwError: true,
    };

    this.setIsSaving(true);

    try {
      await this.apiService.newDelete(config);
      this.programs.deleteItem(program);

      notification.success({
        message: 'Success',
        description: 'Program deleted successfully',
      });
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Unable to delete program',
      });
    } finally {
      this.setIsSaving(false);
    }
  };

  async loadPrograms(
    orgId: number,
    searchText: string = null,
    pageNumber: number = null
  ): Promise<void> {
    const url = ServerRouteHelper.api.organizations.programs(orgId);

    const params = {};
    if (pageNumber) {
      params['page'] = pageNumber;
    }

    if (searchText) {
      params['search'] = searchText;
    }

    try {
      await this.programs.load(url, params, {
        onResponse: (response) => {
          this.pageMeta = new PagingMetaModel(response.meta);
        },
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Unable to fetch programs',
      });
    }
  }

  async getProgramById(id: number) {
    const url = ServerRouteHelper.api.onboarding.onBoardingLinks.show(id);
    await this.program.load(url);
  }

  updateProgram = async (program: ProgramModel): Promise<void> => {
    const url = ServerRouteHelper.api.onboarding.onBoardingLinks.update(program.id);
    const links = program.links.slice();

    const config = {
      url,
      throwError: true,
      data: {
        name: program.name,
        title: program.title,
        cta_text: program.cta_text,
        description: program.description,
        initiative: ProgramInitiative.PROGRAM,
        webinar_date: program.webinar_date,
        links: links.filter((item) => item && item.title !== '' && item.url !== ''),
        status: program.status || ProgramStatus.INACTIVE,
      },
    };

    this.setIsSaving(true);

    try {
      const response = await this.apiService.newPatch(config);
      program.updateFromJson(response.data);

      notification.success({
        message: 'Success',
        description: 'Program updated successfully',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Unable to update program',
      });
    } finally {
      this.setIsSaving(false);
    }
  };
}

export default ProgramStore;
