import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem } from 'app/models';
import { CsvDataModel } from 'app/models/CsvDataModel';
import Store from 'app/stores/Store';

export class CsvUploadStore extends Store<CsvDataModel> {
  @observable csvData = new ModelItem<CsvDataModel>(CsvDataModel);

  constructor() {
    super();
    CsvDataModel._store = this;
  }

  @action
  async parseCsv(data) {
    const url = ServerRouteHelper.api.parseCsv();

    if (this.sharedLinkToken) {
      data.append('token', this.sharedLinkToken);
    }

    const config = {
      url,
      headers: {
        ContentType: 'multipart/form-data',
      },
      data,
    };

    try {
      const response = await this.apiService.newPost(config);
      this.csvData.setItem(response.data);
    } catch (error) {
      this.csvData.setError(error);
    }
  }
}
export default CsvUploadStore;
