import { GREEN, RED, YELLOW } from './colors';
import { CELEBRATE, DISCUSS, IMPROVE } from './exerciseClassifications';

export const exerciseReportSections = {
  [IMPROVE]: {
    title: 'Areas to prioritize',
    color: RED,
    tooltip:
      'These were rated as the most important statements that are being done the least well.',
    description: 'Areas where you can likely have the biggest impact',
  },
  [DISCUSS]: {
    title: 'Areas to understand',
    color: YELLOW,
    tooltip:
      'While these statements weren’t rated as low, they are of medium importance and do show room for improvement.',
    description: 'Areas where you should be curious about what is happening',
  },
  [CELEBRATE]: {
    title: 'Areas to celebrate',
    color: GREEN,
    tooltip: 'These were rated as important statements that are being done well. Nice work!',
    description: 'Areas where the team is thriving',
    // positive: true,
  },

  teamFoundation: {
    title: 'Team Foundations',
    tooltip:
      'Statements about the underlying relationships, trust and acceptance needed for a healthy team.',
  },
  teamExcellence: {
    title: 'Team Excellence',
    tooltip: 'Statements about results orientation, accountability and and ambition of the team.',
  },
  teamEffectiveness: {
    title: 'Team Effectiveness',
    tooltip:
      'Statements about the organization, process and ability to deliver efficiently day to day.',
  },
  teamOpenness: {
    title: 'Team Openness',
    tooltip:
      "Statements about the ability to communicate truths and share what is on everyone's mind.",
  },
  generic: {
    title: 'Statements',
    tooltip: '',
  },
};
