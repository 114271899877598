import { action, observable } from 'mobx';

import Store from 'app/stores/Store';

export interface ModelJson {
  id: number | string;
  [key: string]: any;
}

export abstract class Model {
  protected omittedKeys: string[];

  @observable deleting = false;
  @observable deleted = false;
  @observable updating = false;
  @observable updated = false;
  @observable selected = false;

  @action delete() {
    this.deleted = true;
  }

  @action setIsSelected(selected) {
    this.selected = selected;
  }

  @action
  public setDeleting(value) {
    this.deleting = value;
  }

  @action
  public setUpdating(value) {
    this.updating = value;
  }

  toggleSelected() {
    this.setIsSelected(!this.selected);
  }

  constructor(readonly id: any) {}

  static getStore(): Store<Model> {
    const store = (this as any)._store;
    if (!store) {
      console.error(`_store not defined in ${this}
            Please define _store and assign 'this' to it in owner store's constructor`);
    }
    return store;
  }

  static _fromJson(json: ModelJson): Model {
    if (!json) {
      return null;
    }
    const id = json['id'];

    const entity = this._getOrNewWithJSON(id, json);

    entity.afterInitialize();

    return entity;
  }

  static fromJson(json: ModelJson): Model {
    console.error(
      `fromJson should be overridden in ${this}. Implementation should call this._fromJson and return after typecasting`
    );
    return null;
  }

  static _getOrNew(id): Model {
    let entity = this.getStore().get(id);

    if (!entity) {
      entity = new (this as any)(id);
      this.getStore().push(entity);
    }
    return entity;
  }

  /**
   * Originally _getOrNew() did not handle the json. This caused issues when we would push an entity to the store
   * before setting it's attribute values. Observers of the stores `entities` would be notified of the push to the store
   * but wouldn't get notified when the object itself updated. This method ensures that the json is set before the object
   * is ever pushed to the store. This means `entities` will only ever contained initialized objects and won't get objects
   * that are stuck in a half set state.
   * @param id
   * @param json
   */

  static _getOrNewWithJSON(id, json: ModelJson): Model {
    let entity = this.getStore().get(id);
    const exists = !!entity;

    entity = entity || new (this as any)(id);

    entity.updateFromJson(json);

    !exists && this.getStore().push(entity);

    return entity;
  }

  static _get(id): Model {
    return this.getStore().get(id);
  }

  afterInitialize() {}

  @action
  updateFromJson(json: Record<string, any>) {
    // temporarily disabling this, until epic#41654 is done

    // if (!!window.Sentry && !this.id && !json.id) {
    //   ThirdPartyService.sentry.withScope((scope) => {
    //     scope.setExtra('objectDataKeys', Object.keys(json));
    //     ThirdPartyService.sentry.captureMessage('Json object received without id');
    //   });
    // }

    const store = (this.constructor as any)._store;
    const idUpdateNeeded = json?.id !== this.id && store && store.get(this.id);

    if (idUpdateNeeded) {
      store.entities.delete(this.id);
    }

    for (const k in json) {
      if (this.omittedKeys && this.omittedKeys.indexOf(k) !== -1) {
        continue;
      }
      const deserializer = this.getDeserializer(k);
      if (deserializer) {
        json[k] && deserializer.bind(this)(json[k]);
      } else {
        if (this[k] && this[k].deserialize) {
          this[k].deserialize(json[k]);
        } else {
          this[k] = json[k];
        }
      }
    }

    if (idUpdateNeeded) {
      store.entities.set(this.id, this);
    }
  }

  private getDeserializer(prop: string) {
    const _methodName = `deserialize_${prop}`;
    return this[_methodName];
  }
}

export default Model;
