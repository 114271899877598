import { TeamGameParticipantModel } from 'app/models';
import Store from 'app/stores/Store';

export class TeamGameParticipantStore extends Store<TeamGameParticipantModel> {
  constructor() {
    super();
    TeamGameParticipantModel._store = this;
  }
}
export default TeamGameParticipantStore;
