import { AutoMagicLinkEmailKey, AutoMagicLinkOrigin, AutoMagicLinkOriginKey } from 'app/constants';
import { MemberModel } from 'app/models';

/**
 * Service to flag member who take align, reflect360 and perspectivce
 * that are eligible for auto magic link redirect when they get to login page.
 */
export class AutoMagicLinkService {
  static flagMember = (member: Partial<MemberModel>, origin: AutoMagicLinkOrigin): void => {
    // Only flag when no last_login_method
    if (!!member.last_login_method) {
      return;
    }

    localStorage.setItem(AutoMagicLinkEmailKey, member.email);
    localStorage.setItem(AutoMagicLinkOriginKey, origin);
  };

  static checkFlag = (): { email: string; origin: string } => {
    const email = localStorage.getItem(AutoMagicLinkEmailKey);
    const origin = localStorage.getItem(AutoMagicLinkOriginKey);

    if (email && origin) {
      // After reading, clear localstorage
      localStorage.removeItem(AutoMagicLinkEmailKey);
      localStorage.removeItem(AutoMagicLinkOriginKey);
    }

    return { email, origin };
  };
}
