import React, { useEffect } from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import './ExerciseStatementCard.scss';

import { STICKIES_ANIMATION_LOAD_TIME } from 'app/pages/align/Exercise/Steps/StepOne/StepOne';

const XXL = 55;
const EXTRA_LARGE_SIZE = 50;
const LARGE_SIZE = 35;
const MAX_WORD_LENGTH = 10;

const STANDARD_FONT_SIZE = 0.75;
const EXTRA_LARGE_FONT_SIZE = 0.6;
const XXL_FONT_SIZE = 0.55;
const MED_FONT_SIZE = 0.7;

export interface ExerciseStatementCardProps {
  id: string | number;
  title: string;
  explanation?: string;
  placed?: boolean;
  left?: number;
  top?: number;
  scaledX?: number;
  scaledY?: number;
  mobileScale?: boolean;
  className?: string;
  foreground?: boolean;
  onClick?: (id: string | number) => void;
  postCardAnimationAction?: (id: string | number) => void;
  blockDragging?: boolean;
  startAnimation?: boolean;
  cardIndex?: number;
}

const getStyles = (top: number, left: number): any => {
  if (top || left) {
    return { top, left, position: 'absolute' };
  }

  return {};
};

export const ExerciseStatementCard: React.FC<ExerciseStatementCardProps> = ({
  title,
  left,
  top,
  id,
  placed,
  mobileScale,
  className,
  foreground,
  onClick,
  blockDragging,
  startAnimation,
  cardIndex,
  postCardAnimationAction,
}) => {
  const [{ isDragging }, drag, preview] = useDrag({
    item: { id, left, top, type: 'box', title },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [isPlayingAnimation, setIsPlayingAnimation] = React.useState(false);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    if (startAnimation) {
      // decide the delay time to display new stickies based on current card index
      const fadeInDelay = cardIndex * STICKIES_ANIMATION_LOAD_TIME;
      const timer = setTimeout(() => {
        setIsPlayingAnimation(true);
        postCardAnimationAction && postCardAnimationAction(id);
      }, fadeInDelay);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [startAnimation, postCardAnimationAction, cardIndex, id]);

  const getFontSize = (title) => {
    const textLength = title.length;

    if (textLength >= XXL) {
      return `${XXL_FONT_SIZE}rem`;
    }

    if (textLength >= EXTRA_LARGE_SIZE) {
      return `${EXTRA_LARGE_FONT_SIZE}rem`;
    }

    if (textLength >= LARGE_SIZE || longestWordLength(title) > MAX_WORD_LENGTH) {
      return `${MED_FONT_SIZE}rem`;
    }

    return `${STANDARD_FONT_SIZE}rem`;
  };

  const longestWordLength = (sentence) => {
    return sentence.split(' ').reduce((maxLengthWord, currentWord) => {
      if (currentWord.length > maxLengthWord.length) {
        return currentWord;
      } else {
        return maxLengthWord;
      }
    }, '').length;
  };

  return (
    <div
      className={cx('exercise-statement-card', className, {
        placed: placed || isDragging,
        isDragging,
        mobileScale,
        foreground,
      })}
      ref={blockDragging ? null : drag}
      style={getStyles(top, left)}
      onClick={() => onClick?.(id)}
    >
      {!isPlayingAnimation && (
        <div className="body" style={{ fontSize: getFontSize(title) }}>
          {title}
        </div>
      )}

      {isPlayingAnimation && (
        <div className="body stickies-animation">
          <CheckCircleOutlined className="check-icon" />
        </div>
      )}
    </div>
  );
};

export default ExerciseStatementCard;
