import React from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, MenuProps } from 'antd';
import { sortBy } from 'lodash';
import { observer } from 'mobx-react';

import { MemberModel, OrganizationModel } from 'app/models';

interface MenuLinkProps {
  styles: any;
  activeOrg?: OrganizationModel;
  activeMember: MemberModel;
  handleGoToOrg: (org: OrganizationModel) => void;
}

const UserActiveOrgComponent: React.FC<MenuLinkProps> = ({
  styles,
  activeOrg,
  activeMember,
  handleGoToOrg,
}) => {
  const orgCount = activeMember.organizations.items.length;

  if (orgCount <= 0) {
    return null;
  }

  if (orgCount === 1) {
    return (
      <Menu.Item key="single-org">
        <a className={styles.orgNavLink} href="#" onClick={() => handleGoToOrg(activeOrg)}>
          {activeOrg.name}
        </a>
      </Menu.Item>
    );
  }

  // Sort Org names alphabetically
  const organizations = sortBy(activeMember.organizations.items, ['name']);

  const items: MenuProps['items'] = [];
  organizations.forEach((org, i) => {
    items.push({
      label: (
        <a className="dropdown-item" href="#" onClick={() => handleGoToOrg(org)}>
          {org.name}
        </a>
      ),
      key: org.id,
    });
  });

  return (
    <Menu.Item key="org-dropdown">
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button data-testid="displayed-org" data-nav-org aria-haspopup="true" aria-expanded="false">
          {activeOrg ? activeOrg.name : 'Organization'}
          <DownOutlined />
        </Button>
      </Dropdown>
    </Menu.Item>
  );
};

export default observer(UserActiveOrgComponent);
