import { computed } from 'mobx';

import { GREEN, NAV_BLUE, RED, YELLOW } from 'app/constants';

import ExerciseThemeSectionModel from './ExerciseThemeSectionModel';
import ShiftModel from './ShiftModel';

const THEME_COLORS = [RED, YELLOW, GREEN, NAV_BLUE];

export interface ExerciseThemeModelProps {
  id: number;
  title: string;
  exercise_type_id: number;
  sections: ExerciseThemeSectionModel[];
  created_at: string;
  updated_at: string;
}

export class ExerciseThemeModel extends ShiftModel<ExerciseThemeModelProps> {
  id: number;
  title: string;
  exercise_type_id: number;
  sections: ExerciseThemeSectionModel[];
  created_at: string;
  updated_at: string;

  constructor(props: ExerciseThemeModelProps) {
    super(props);
    this.sections = props.sections.map(
      (section, i) => new ExerciseThemeSectionModel(section, THEME_COLORS[i])
    );
  }

  @computed
  get orderedSectionStatementIds(): number[] {
    return this.sections.reduce((acc, section) => {
      section.align_statement_ids.forEach((id) => acc.push(id));
      return acc;
    }, []);
  }
}

export default ExerciseThemeModel;
