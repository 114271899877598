import { action, computed, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { MemberModel, OrganizationModel } from 'app/models';
import Model from 'app/models/Model';
import Store from 'app/stores/Store';

export const NavigationStoreAttributes = {
  coachedMember: 'coachedMember',
  member: 'member',
  displayedOrg: 'displayedOrg',
  showInviteButton: 'showInviteButton',
  myTeams: 'myTeams',
  isImpersonator: 'isImpersonator',
  isManager: 'isManager',
  isMyColleaguesTabEnabled: 'isMyColleaguesTabEnabled',
  isMarketingHomepageTabDisabled: 'isMarketingHomepageTabDisabled',
};

export class NavigationStore extends Store<Model> {
  @observable mainNavData = observable.map();

  @observable isLoadingMainNavigation = false;
  @action private setIsLoadingMainNavigation = (status) => (this.isLoadingMainNavigation = status);

  @observable organization: OrganizationModel = null;
  @action setOrganization = (organization: OrganizationModel): void => {
    this.organization = organization;
  };

  /**
   * Load menu data from API.
   *
   * @param number coachedMemberId = null
   */
  async loadMainNavData(coachedMemberId = null): Promise<void> {
    // Dont reload data if not required
    if (this.hasData && coachedMemberId === null) {
      return;
    }

    // Set loading switch
    this.setIsLoadingMainNavigation(true);

    // If null reset to undefined for ServerRouteHelper
    coachedMemberId = coachedMemberId ?? undefined;

    // Call back-end
    try {
      const url = ServerRouteHelper.api.navigation.main(coachedMemberId);
      const response = await this.apiService.get(url, undefined, undefined, undefined, false);

      // Populate our map
      for (const keyName in response) {
        const data = response[keyName];
        this.setData(keyName, data);
      }
    } catch (e) {
      // Intentionally suppress ShiftError exceptions
    } finally {
      // clear switch
      this.setIsLoadingMainNavigation(false);
    }
  }

  get navOrgRole(): string {
    return this.mainNavData.get('navRailOrg').pivot.role;
  }

  get isLoading(): boolean {
    return this.isLoadingMainNavigation;
  }

  @computed
  get hasData(): boolean {
    return this.mainNavData.size > 0;
  }

  @action
  clearData(): void {
    this.mainNavData.clear();
  }

  @action
  setData(name: string, data): void {
    // Always delete first if it already exists
    if (this.mainNavData.has(name)) {
      this.mainNavData.delete(name);
    }

    this.mainNavData.set(name, data);
  }

  getData(name: string): any {
    return this.mainNavData.get(name) ?? null;
  }

  parseOrganization = (): OrganizationModel => {
    const org = OrganizationModel.fromJson(this.getData(NavigationStoreAttributes.displayedOrg));
    this.setOrganization(org);
    return this.organization;
  };

  parseMember = (): MemberModel => {
    return MemberModel.fromJson(this.getData(NavigationStoreAttributes.member));
  };
}

export default NavigationStore;
