import { observable } from 'mobx';

import { CookiePrefOptions } from 'app/constants';
import { CookiePrefStore } from 'app/stores';

import Model, { ModelJson } from './Model';

export class CookiePrefModel extends Model {
  static _store: CookiePrefStore;

  @observable id: number;
  @observable pref: CookiePrefOptions;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as CookiePrefModel;
  }
}

export default CookiePrefModel;
