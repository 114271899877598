import { PerspectiveMemberModel } from 'app/models';

import Store from './Store';

export class PerspectiveMemberStore extends Store<PerspectiveMemberModel> {
  constructor() {
    super();
    PerspectiveMemberModel._store = this;
  }
}

export default PerspectiveMemberStore;
