import * as React from 'react';

import cx from 'classnames';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';

import './styles.scss';

export interface InputWrapperProps {
  id?: string;
  validators?: ((value: any) => string | null)[];
  label?: string;
  error?: string;
  hint?: string;
  className?: string;
  children?: (onBlur: () => void, onFocus: () => void) => void;
  value: any;
  onFocus?: () => void;
}

@observer
export class InputWrapper extends React.Component<InputWrapperProps> {
  @observable isFocused: boolean;
  @observable validationError: string;

  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  @action
  handleBlur() {
    this.isFocused = false;
    this.validate();
  }

  @action
  handleFocus() {
    const { onFocus } = this.props;
    this.isFocused = true;
    onFocus && onFocus();
  }

  validate() {
    const { validators } = this.props;
    (validators || []).find((validator) => {
      const error = validator(this.props.value);
      if (error !== this.validationError) {
        this.setValidationError(error);
      }
      return error;
    });
  }

  @action
  setValidationError(error: string) {
    this.validationError = error;
  }

  @computed
  get error() {
    return this.props.error || this.validationError;
  }

  @computed
  get showError() {
    return !this.isFocused && this.error;
  }

  render() {
    const { id, label, hint, className } = this.props;
    return (
      <div
        id={id}
        className={cx(
          'd-flex',
          'flex-column',
          'input-wrapper',
          { error: this.showError },
          { focus: this.isFocused },
          className
        )}
      >
        {label && <label className="mb-1 label">{label}</label>}

        {this.props.children(this.handleBlur, this.handleFocus)}

        {this.showError && <span className="error-msg mt-1">{this.error}</span>}

        {hint && <span className="hint mt-1">{hint}</span>}
      </div>
    );
  }
}
