import React, { useMemo } from 'react';

import { AxisBottom } from '@visx/axis';
import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';

import { GRAY_600 } from 'app/constants';

import Bar from './Bar';

// accessors
const x = (d) => d.label;
const y = (d) => d.value;

type Data = {
  label: string;
  color: string;
  value: string;
  isMock: boolean;
};

const N_A_VALUE = 'N/A';

export interface BarChartProps {
  data: Data[];
  width: number;
  height: number;
  padding?: number;
  hideZero?: boolean;
  showLabel?: boolean;
  showGradient?: boolean;
  maxValue?: number;
}

export const BarChart: React.FC<BarChartProps> = ({
  data,
  width,
  height,
  hideZero,
  padding,
  showLabel,
  showGradient,
  maxValue,
}) => {
  // bounds
  const xMax = width - 120;
  const yMax = 80;

  // scales
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: data.map(x),
        padding: padding ?? 0.4,
      }),
    [xMax, data, padding]
  );

  const yScale = useMemo(() => {
    const maxDomain = maxValue ?? Math.max(...data.map(y));
    return scaleLinear<number>({
      range: [yMax, 0],
      round: true,
      domain: [0, maxDomain],
    });
  }, [yMax, maxValue, data]);

  const getLabelFormat = (value: string, index: number): string => {
    if (value === N_A_VALUE) {
      return data[index].label;
    }

    if (!showLabel) {
      return '●';
    }

    const item = data[index];
    if (item.isMock) {
      return '-';
    }

    return data[index].label;
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <svg width={width} height={height}>
        <Group top={25} left={55}>
          {data.map((d, i) => {
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(y(d)) ?? 0);
            const barX = xScale(x(d));
            const barY = yMax - barHeight;
            const color = d.color;

            return (
              <React.Fragment key={`bar-${i}`}>
                {showGradient && <LinearGradient id={'gradient'} from="#3D59FA" to="#B8C9FF" />}
                <Bar
                  key={`bar-${i}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={showGradient ? 'url(#gradient)' : color}
                  value={y(d)}
                />
              </React.Fragment>
            );
          })}

          <AxisBottom
            hideTicks
            hideZero={hideZero}
            scale={xScale}
            top={yMax}
            stroke={GRAY_600}
            strokeDasharray="2"
            strokeWidth={0.5}
            tickFormat={(value, index) => getLabelFormat(value, index)}
            tickLabelProps={(value, index) => ({
              fill: showLabel ? 'black' : data[index].color,
              fontSize: 11,
              textAnchor: 'middle',
              verticalAnchor: 'end',
            })}
            label=""
          />
        </Group>
      </svg>
    </div>
  );
};

export default BarChart;
