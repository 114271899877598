import { observable } from 'mobx';

import { PerspectiveDimension } from 'app/constants';
import { ModelList } from 'app/models/ModelList';
import MemberPerspectiveResultStore from 'app/stores/MemberPerspectiveResultStore';

import lensReportStrings from '../../perspective/lens-report-strings.json';
import lensTooltips from '../../perspective/lens-tooltips.json';
import mbtiReportMapping from '../../perspective/mbti-report-strings.json';
import MbtiDataModel from './MbtiDataModel';
import Model, { ModelJson } from './Model';
import PerspectiveDimensionModel from './PerspectiveDimensionModel';
import PerspectiveMemberModel from './PerspectiveMemberModel';

export interface UnderStressElement {
  key: string;
  statement: string;
}

export class MemberPerspectiveResultModel extends Model {
  static _store: MemberPerspectiveResultStore;

  @observable id: number;
  @observable letters?: string;
  @observable typeCards?: string[];
  @observable dimensions = new ModelList<PerspectiveDimensionModel>(PerspectiveDimensionModel);
  @observable mbtiData: MbtiDataModel;
  @observable underStress?: {
    strategies: UnderStressElement[];
    reactions: UnderStressElement[];
  };
  @observable created_at?: string;
  @observable updated_at?: string;

  // Current member who this result is for
  @observable member?: PerspectiveMemberModel;

  deserialize_member(member) {
    this.member = PerspectiveMemberModel.fromJson(member);
  }

  deserialize_letters(letters) {
    this.letters = letters || '';
  }

  getOverall() {
    return this.dimensions?.items.map(
      ({
        dimension,
        binnedScore,
        descriptionHtml,
        dimensionDisplayName,
        leftLabel,
        rightLabel,
        leftToolTip,
        rightToolTip,
      }) => {
        return {
          dimension,
          dimensionDisplayName,
          leftLabel,
          rightLabel,
          leftToolTip,
          rightToolTip,
          value: binnedScore,
          description: descriptionHtml,
        };
      }
    );
  }

  getOthers() {
    const data = {
      [PerspectiveDimension.Energy]: {
        items: [],
        descriptions: [],
      },
      [PerspectiveDimension.Processing]: {
        items: [],
        descriptions: [],
      },
      [PerspectiveDimension.Decisions]: {
        items: [],
        descriptions: [],
      },
      [PerspectiveDimension.Structure]: {
        items: [],
        descriptions: [],
      },
    };

    this.dimensions.items.forEach(({ dimensionDisplayName, lenses, binnedScore }) => {
      data[dimensionDisplayName].items = lenses.items.map((lens) => {
        const lensLabels = lensReportStrings.cards[lens.lens];
        const description = lensLabels[`card${lens.binnedLensScore}`];
        data[dimensionDisplayName].descriptions.push(description);

        const leftTooltip = lensTooltips?.[dimensionDisplayName]?.[lensLabels.leftLabel];
        const rightTooltip = lensTooltips?.[dimensionDisplayName]?.[lensLabels.rightLabel];

        return {
          lens: lens.lens,
          binnedScore,
          dimensionDisplayName,
          leftLabel: lensLabels.leftLabel,
          leftTooltip,
          rightLabel: lensLabels.rightLabel,
          rightTooltip,
          value: lens.binnedScore,
          description,
        };
      });
    });

    return data;
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as MemberPerspectiveResultModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as MemberPerspectiveResultModel;
  }

  static get(id) {
    return this._get(id) as MemberPerspectiveResultModel;
  }
}

export enum PerspectiveDimensionIndex {
  EI = 'EI',
  SN = 'SN',
  TF = 'TF',
  JP = 'JP',
}

export namespace PerspectiveUtils {
  export const getDimensionProperties = (index: PerspectiveDimensionIndex, dimensionData) => {
    return dimensionData[index];
  };

  export const getDimensionIndexLabel = (index: PerspectiveDimensionIndex) => {
    return mbtiReportMapping.dimensionCard[index].title;
  };
}

export default MemberPerspectiveResultModel;
