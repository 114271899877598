import { History, Location } from 'history';
import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ExerciseReportModel, ExerciseStatementModel } from 'app/models';

import ShiftStore, { ShiftStoreRequestOptionMethods, ShiftStoreResponse } from './ShiftStore';

export enum ExerciseReportItem {
  PerspectiveInsights = 'perspectiveInsights',
}

export enum ExerciseReportStoreKeys {
  ExerciseReport = 'EXERCISE_REPORT',
  TestDriveReport = 'TEST_DRIVE_REPORT',
  StatementPercentiles = 'STATEMENT_PERCENTILES',
  Subscribe = 'SUBSCRIBE',
  ThankRespondents = 'THANK_RESPONDENTS',
  PersonalInsights = 'PERSONAL_INSIGHTS',
}

/**
 * Supported report view page actions.
 */
export enum ExerciseActivityActions {
  // align report tabs
  AlignViewTeamReport = 'view_align_team_report',
  AlignViewCoachReport = 'view_align_coach_report',
  AlignViewPersonalReport = 'view_align_personal_report',

  // my360 report tabs
  My360ViewHighlightsReport = 'my360_view_highlights_report',
  My360ViewFullAnalysisReport = 'my360_view_full_analysis_report',
  My360ViewReflectionReport = 'my360_view_reflection_report',
  My360ViewCommitmentsReport = 'my360_view_commitments_report',

  // my360 fulfilment report questions
  My360AnsweredExpectedResult = 'my360_answered_expected_result',
  My360AnsweredHowYouFeel = 'my360_answered_how_you_feel',
  My360AnsweredMostProud = 'my360_answered_most_proud',
  My360AnsweredUnexpectedStrengthWeakness = 'my360_answered_unexpected_stregth_weakness',
  My360AnsweredFocusOnImproving = 'my360_answered_focus_on_improving',
  My360SignedupForCoach = 'my360_signed_up_for_coach',
}

export interface PersonalInsightsLookupKey {
  statement: {
    id: number;
    text: string;
  };
  key: string;
}

export interface PersonalInsight {
  key: string;
  statement_id: number;
  statement?: ExerciseStatementModel;
  header: string;
  description: string;
  buttonCta: string;
}

export class ExerciseReportStore extends ShiftStore {
  @observable report: ExerciseReportModel;
  @action setReport = (report) => (this.report = report);

  @observable testDriveReport: ExerciseReportModel;
  @action setTestDriveReport = (report) => (this.testDriveReport = report);

  inflightOrLoadedReportToken?: string;
  inflightOrLoadedReportQueryParams?: string;
  inflightOrLoadedTestDriveType?: string;

  async getReportWithToken(
    token: string,
    allAnswers = false,
    liveInsights = false,
    exclude: ExerciseReportItem[] = []
  ): Promise<void> {
    // Build query params
    const queryParams = { params: null };

    if (allAnswers) {
      queryParams.params = { allAnswers };
    }

    if (liveInsights) {
      queryParams.params = queryParams.params ?? {};
      queryParams.params['live-insights'] = true;
    }

    if (exclude?.length > 0) {
      queryParams.params = queryParams.params ?? {};
      queryParams.params['exclude'] = exclude.join(',');
    }

    const newQueryParams = JSON.stringify(queryParams.params);
    const tokenLoaded = this.inflightOrLoadedReportToken === token;

    // Only do request on backgound if cached query params
    // is longer, because this means we have a partially loaded data
    // and we just want to override it with a complete one.
    const background =
      tokenLoaded && this.inflightOrLoadedReportQueryParams?.length > newQueryParams.length;

    // Prevent multiple request for same token
    if (tokenLoaded && !background) {
      return;
    }

    // Cache the token
    this.inflightOrLoadedReportToken = token;
    this.inflightOrLoadedReportQueryParams = newQueryParams;

    // If this is background request,
    // create unique id so it always goes through
    let key = ExerciseReportStoreKeys.ExerciseReport as string;
    if (background) {
      key = `${key}_${newQueryParams}`;
    }

    // Finally make actual request
    const params = {
      key,
      id: token,
      url: ServerRouteHelper.api.exerciseReports.show(token),
      onSuccess: (response: ShiftStoreResponse) => {
        this.setReport(new ExerciseReportModel(response.data, this, token));
      },
      errorMessage: 'Unable to load exercise report. Please Try Again.',
    };

    if (queryParams.params) {
      params['queryParams'] = queryParams;
    }

    await this.request(params);
  }

  async getTestDriveReportWithType(type: string, testDriveType: string) {
    if (this.inflightOrLoadedTestDriveType === testDriveType) {
      return;
    }

    this.inflightOrLoadedTestDriveType = testDriveType;

    const params = {
      key: ExerciseReportStoreKeys.TestDriveReport,
      id: testDriveType,
      url: ServerRouteHelper.api.exerciseReports.testDriveShow(type, testDriveType),
      onSuccess: (response: ShiftStoreResponse) => {
        const report = new ExerciseReportModel(response.data, this, testDriveType);
        report.loadTestDriveAnswers();
        this.setTestDriveReport(report);
      },
      errorMessage: 'Unable to load exercise report. Please Try Again.',
      queryParams: { params: { allAnswers: true } },
    };

    await this.request(params);
  }

  async adminGetStatementPercentilesForReport(token: string, report: ExerciseReportModel) {
    await this.request({
      key: ExerciseReportStoreKeys.StatementPercentiles,
      id: token,
      url: ServerRouteHelper.api.admin.exerciseReports.statementPercentiles(token),
      onSuccess: (response: ShiftStoreResponse) => {
        report.setStatementPercentiles(response.data.statements);
      },
      errorMessage: 'Unable to load exercise report. Please Try Again.',
    });
  }

  async subscribe(token: string) {
    await this.request({
      key: ExerciseReportStoreKeys.Subscribe,
      id: token,
      method: ShiftStoreRequestOptionMethods.POST,
      url: ServerRouteHelper.api.exerciseReports.subscribe(token),
      errorMessage: 'Unable to subscribe. Please Try Again.',
    });
  }

  async thankRespondents(token: string, selected_members: number[], message: string) {
    await this.request({
      key: ExerciseReportStoreKeys.ThankRespondents,
      id: token,
      method: ShiftStoreRequestOptionMethods.POST,
      params: {
        selected_members,
        message,
      },
      url: ServerRouteHelper.api.exerciseReports.thankRespondents(token),
      errorMessage: 'Unable to thank respondents. Please Try Again.',
    });
  }

  /**
   * Record `exerise_activities` table view report actions.
   *
   * @param token
   * @param exerciseId
   * @param action
   */
  async recordExerciseActivity(
    token: string,
    exerciseId: number,
    action: string,
    meta?: Record<string, any>
  ): Promise<void> {
    await this.request({
      key: 'exercise_activity',
      params: {
        action,
        token,
        meta,
      },
      method: ShiftStoreRequestOptionMethods.POST,
      url: ServerRouteHelper.api.exercises.activity(exerciseId),
      errorMessage: 'Failed to record exercise activity.',
    });
  }

  /**
   * Record and listen for exercise report views and record them as exercise activities.
   *
   * @param exercise
   * @param match
   * @param history
   */
  listenForExerciseActivityViews = (
    token: string,
    exerciseId: number,
    history: History,
    suffixMapper: (suffix: string) => ExerciseActivityActions
  ): void => {
    // record first time load
    this.sendExerciseViewActivity(token, history.location, exerciseId, suffixMapper);

    // listen for router path changes
    history.listen((location): void => {
      this.sendExerciseViewActivity(token, location, exerciseId, suffixMapper);
    });
  };

  /**
   * Record a exercise view event.
   *
   * @param match
   * @param pathname
   * @param exercise
   */
  sendExerciseViewActivity = (
    token: string,
    location: Location<any>,
    exerciseId: number,
    suffixMapper: (suffix: string) => ExerciseActivityActions
  ): void => {
    const suffix = location.pathname.split('/').pop();
    const reportAction = suffixMapper(suffix);

    if (!reportAction) {
      return;
    }

    this.recordExerciseActivity(token, exerciseId, reportAction);
  };
}

export default ExerciseReportStore;
