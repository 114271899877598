import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import AdminStatsModel from 'app/models/AdminStatsModel';
import { ModelList } from 'app/models/ModelList';
import Store from 'app/stores/Store';

export class AdminStatsStore extends Store<AdminStatsModel> {
  @observable public stats = new ModelList<AdminStatsModel>(AdminStatsModel);

  constructor() {
    super();
    AdminStatsModel._store = this;
  }

  @action
  public async getInsigthStats() {
    const url = ServerRouteHelper.api.admin.stats.insights();

    await this.stats.load(url);
  }
}

export default AdminStatsStore;
