import Model from 'app/models/Model';

// This model will go away once all models starts using new model structure.
export abstract class BaseModel extends Model {
  static _fromJson(json: Record<string, any>): BaseModel {
    const entity = new (this as any)();
    entity.updateFromJson(json);
    return entity;
  }

  static _get(id: number): BaseModel {
    return null;
  }
}

export default BaseModel;
