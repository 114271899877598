import { PerspectiveTeammateDiffModel } from 'app/models';

import Store from './Store';

export class PerspectiveTeammateDiffStore extends Store<PerspectiveTeammateDiffModel> {
  constructor() {
    super();
    PerspectiveTeammateDiffModel._store = this;
  }
}

export default PerspectiveTeammateDiffStore;
