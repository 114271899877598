import { PulseActivityModel } from 'app/models';
import Store from 'app/stores/Store';

export class PulseActivityStore extends Store<PulseActivityModel> {
  constructor() {
    super();
    PulseActivityModel._store = this;
  }
}

export default PulseActivityStore;
