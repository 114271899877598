import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { MemberRatingModel } from 'app/models';
import Store from 'app/stores/Store';

export class MemberRatingStore extends Store<MemberRatingModel> {
  @observable public memberRating: MemberRatingModel[] = [];
  @action setRatings = (ratings) => (this.memberRating = ratings);

  constructor() {
    super();
    MemberRatingModel._store = this;
  }

  @action
  public async loadMemberRating(memberId: number, ratingKey: string, type: string): Promise<void> {
    const url = ServerRouteHelper.api.memberRating.list(memberId, ratingKey, type);

    const response = await this.apiService.get(
      url,
      { token: this.sharedLinkToken },
      this.defaultHeaders
    );

    this.setRatings(response?.data);
  }

  @action
  public async makeMemberRating(memberId: number, rating): Promise<void> {
    const url = ServerRouteHelper.api.memberRating.rate(memberId);

    await this.apiService.post(url, rating);
  }

  public getRating = (key: string, id: number): MemberRatingModel => {
    return this.memberRating?.find((rating) => rating.rating_key === `${id}:${key}`);
  };
}

export default MemberRatingStore;
