import React, { ReactNode } from 'react';

import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { PulseStatementModel } from 'app/models';
import PulseHabitsContext from 'app/pages/dashboard/Pulse/Context/PulseHabitsContext';

import QuickAddHabitsCard from './QuickAddHabitsCard';
import SelectHabitsCard from './SelectHabitsCard';

export interface RouteParams {
  step: string;
  teamId?: string;
}

export interface CreateHabitsStepProps extends RouteComponentProps<RouteParams> {}

export class CreateHabitsStep extends React.Component<CreateHabitsStepProps> {
  static contextType = PulseHabitsContext;

  componentDidMount(): void {
    // Set initial empty slots for habits
    this.context.uiStore.createEmptySlots();
  }

  private get habits(): PulseStatementModel[] {
    return this.context.uiStore.habits;
  }

  private handleCustomHabit = (text: string): void => {
    this.context.uiStore.appendNewHabit(text);
  };

  private handleDeleteHabit = (habit: PulseStatementModel): void => {
    this.context.uiStore.deleteHabit(habit);
  };

  public render(): ReactNode {
    return (
      <Row className="create-habits-step" gutter={24}>
        <Col span={12}>
          <QuickAddHabitsCard />
        </Col>

        <Col span={12}>
          <SelectHabitsCard
            habits={this.habits}
            onCustomHabit={this.handleCustomHabit}
            onDeleteHabit={this.handleDeleteHabit}
          />
        </Col>
      </Row>
    );
  }
}

export default withRouter(observer(CreateHabitsStep));
