import React, { ReactNode } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Col, Radio, Row, Select, TimePicker } from 'antd';
import { range } from 'lodash';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './ConfigureStep.module.scss';

import EnhancedCard from 'app/components/ui/EnhancedCard';
import { Clickable } from 'app/components/ui/EnhancedCard/CardFooter/CardFooter';
import Markdown from 'app/components/ui/Markdown';
import { Cadence, ReminderType } from 'app/constants';
import PulseReminderModel from 'app/models/PulseReminderModel';
import PersonalHabitsUIStore, {
  CADENCE_TYPES,
} from 'app/pages/dashboard/Myself/MyHabit/PersonalHabits/PersonalHabitsUIStore';
import PulseHabitsContext from 'app/pages/dashboard/Pulse/Context/PulseHabitsContext';
import TeamHabitsUIStore from 'app/pages/dashboard/Team/TeamHabits/TeamHabitsUIStore';
import { ReminderOptions, ReminderTimezone } from 'app/stores';

const { Option } = Select;

export interface RouteParams {
  step: string;
  teamId?: string;
}

export interface ConfigureStepProps extends RouteComponentProps<RouteParams> {}

export class ConfigureStep extends React.Component<ConfigureStepProps> {
  static contextType = PulseHabitsContext;

  get uiStore(): PersonalHabitsUIStore | TeamHabitsUIStore {
    return this.context.uiStore;
  }

  @computed
  get isLoading(): boolean {
    return !this.reminders || !this.reminder || this.isCadenceLoading;
  }

  get isCadenceLoading(): boolean {
    return this.uiStore.isCadenceLoading;
  }

  get reminders(): PulseReminderModel[] {
    return this.uiStore?.pulseReminderStore.reminders.items;
  }

  get reminder(): PulseReminderModel {
    return this.uiStore?.pulseReminderStore?.activeReminder;
  }

  get cadenceText(): string {
    return this.uiStore?.cadenceText;
  }

  get days(): string[] {
    return this.options && this.options.days;
  }

  get timezones(): ReminderTimezone[] {
    return this.options && this.options.timezones;
  }

  get options(): ReminderOptions {
    return this.uiStore.pulseReminderStore.options;
  }

  @computed
  get showCustomForm(): boolean {
    return this.reminder.option_key === ReminderType.Custom;
  }

  @computed
  get customFormFields(): ReactNode {
    return (
      <>
        <div className={styles.inlineSection}>
          <label className={styles.sectionLabel}>Repeat every:</label>
          <Select
            value={this.reminder?.interval}
            onChange={(value) => this.handleIntervalChange(value)}
          >
            {range(1, 11).map((value) => (
              <Option value={value} key={value}>
                {value}
              </Option>
            ))}
          </Select>
          <Select defaultValue="week" disabled>
            <Option value="week" key="week">
              Week
            </Option>
          </Select>
        </div>

        <div className={styles.inlineSection}>
          <label className={styles.sectionLabel}>Repeat on:</label>
          <Radio.Group
            onChange={(event) => this.handleRepeatOnChange(event.target.value)}
            value={this.reminder.day_of_week}
          >
            {this.days.map((day, i) => (
              <Radio value={day} key={i}>
                {day.substring(0, 1)}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </>
    );
  }

  @action
  handleRepeatOnChange = (day_of_week: string): void => {
    // Recompute what the default reminder is
    this.reminder.day_of_week = day_of_week;
    this.reminder.cadence = Cadence.Custom;

    this.uiStore.updateActiveDefaultReminder(this.reminder);
  };

  @action
  handleTimezoneChange = (timezone: string): void => {
    // Recompute what the default reminder is
    this.reminder.timezone = timezone;
    this.uiStore.updateActiveDefaultReminder(this.reminder);
  };

  @action
  handleIntervalChange = (interval: number): void => {
    // Recompute what the default reminder is
    this.reminder.interval = interval;
    this.reminder.cadence = Cadence.Custom;

    this.uiStore.updateActiveDefaultReminder(this.reminder);
  };

  @action
  handleTimeChange = (timeString): void => {
    const timeOfDay = moment(timeString, 'h:mm a').format('HH:mm:ss');
    this.reminder.time_of_day = timeOfDay;

    this.uiStore.updateActiveDefaultReminder(this.reminder);
  };

  @action
  handleCadenceTypeChange = (value: ReminderType): void => {
    const defaultReminder = this.reminders.find((reminder) => reminder.option_key === value);

    // Preserve email content
    defaultReminder.email_content = this.reminder.email_content;

    this.uiStore.updateActiveDefaultReminder(defaultReminder);
  };

  get formFieldsOptions(): any {
    return CADENCE_TYPES;
  }

  @computed
  get formFields(): ReactNode {
    return (
      <>
        <div className={styles.section}>
          <label className={styles.sectionLabel}>Repeat every:</label>
          <Radio.Group
            options={this.formFieldsOptions}
            value={this.reminder.option_key}
            optionType="button"
            buttonStyle="solid"
            onChange={(event) => this.handleCadenceTypeChange(event.target.value)}
          />
        </div>

        {this.showCustomForm && this.customFormFields}

        <div className={styles.section}>
          <label className={styles.sectionLabel}>Time of day:</label>

          <div className={styles.timeOfDayContainer}>
            <TimePicker
              allowClear={false}
              onChange={this.handleTimeChange}
              defaultValue={moment(this.reminder.time_of_day, 'HH:mm:ss')}
              value={moment(this.reminder.time_of_day, 'HH:mm:ss')}
              format="h:mm a"
              showNow={false}
              suffixIcon={<DownOutlined />}
            />

            <Select
              value={this.reminder.timezone}
              showSearch
              onChange={(value) => this.handleTimezoneChange(value)}
            >
              {this.timezones?.map((timezone, i) => (
                <Option value={timezone.value} key={i}>
                  {timezone.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </>
    );
  }

  @computed
  private get primaryButton(): Clickable {
    const { isSaving } = this.uiStore;

    if (this.context.uiStore.shortVersion) {
      return null;
    }

    return {
      label: isSaving ? 'Saving...' : 'Next: Review Habits',
      disabled: isSaving,
      onClick: () => this.handleNext(),
    };
  }

  @computed
  private get secondaryButton(): Clickable {
    const { isSaving } = this.uiStore;

    return {
      label: 'Back',
      disabled: isSaving,
      onClick: () => this.handlePrev(),
    };
  }

  @action
  handleNext = async (): Promise<void> => {
    await this.uiStore.saveReminder();

    const url = this.uiStore.getStepUrl('review');
    this.props.history.push(url);
  };

  handlePrev = (): void => {
    const url = this.uiStore.getStepUrl('invite');
    this.props.history.push(url);
  };

  public render(): ReactNode {
    if (this.isLoading) {
      return null;
    }

    return (
      <Row gutter={24}>
        <Col span={16}>
          <EnhancedCard
            className={styles.enhancedCard}
            heading="Let's define the cadence of your habits check-ins"
            subHeading={this.uiStore.configureSubHeading()}
          >
            {this.formFields}
          </EnhancedCard>
        </Col>

        <Col span={8}>
          <EnhancedCard
            className={styles.cadenceSummaryCard}
            heading="Your cadence summary"
            footerPrimaryButton={this.primaryButton}
            footerSecondaryButton={this.secondaryButton}
          >
            <div className="card-content-container">
              <Markdown source={this.cadenceText} />
            </div>
          </EnhancedCard>
        </Col>
      </Row>
    );
  }
}

export default withRouter(observer(ConfigureStep));
