import type { IObservableArray } from 'mobx';
import { action, computed, observable } from 'mobx';

import { Model } from 'app/models/Model';
import { ModelContainer } from 'app/models/ModelContainer';

export class ModelList<T extends Model, F = unknown> extends ModelContainer<T, F> {
  @observable protected _items: IObservableArray<T>;

  constructor(protected modelClass: typeof Model) {
    super(modelClass);
    this._items = observable([]);
  }

  @computed
  get hasError() {
    return !!this.error;
  }

  @computed
  get items() {
    return this._items.filter((item) => !item.deleted);
  }

  @action
  setItems(items: T[]) {
    this._items.clear();
    this.appendItems(items);
  }

  @action
  appendItems(items: T[]) {
    this._items.push(...items);
  }

  @action
  appendItem(item: T) {
    this._items.push(item);
  }

  @action
  prependItem(item: T) {
    this._items.unshift(item);
  }

  @action
  deleteItem(item: T): void {
    const index = this._items.findIndex(({ id }) => id === item.id);
    if (index !== -1) {
      this._items.splice(index, 1);
    }
  }

  @action
  deleteItemById(id: string | number): void {
    const item = this.getItem(id);
    if (item) {
      this.deleteItem(item);
    }
  }

  @action
  appendUniqueItems(items: T[]): void {
    items.forEach((item) => {
      if (!this.hasItem(item.id)) {
        this.appendItem(this.modelClass._fromJson(item) as T);
        return;
      }

      const existingItem = this.getItem(item.id);
      existingItem.updateFromJson(item);
    });
  }

  @action
  public deserialize(items: any[], append = false) {
    if (!items) {
      this.loaded = true;
      return;
    }

    const models = items.map((item) => this.modelClass._fromJson(item) as T);

    if (append) {
      this.appendUniqueItems(models);
    } else {
      this.setItems(models);
    }

    this.loaded = true;
  }

  public getItem(id: string | number): T {
    return this._items.find((item) => item.id === id);
  }

  public hasItem(id: string | number): boolean {
    return !!this.getItem(id);
  }

  public itemIsLoaded(id: string): boolean {
    return this.hasItem(id);
  }
}
