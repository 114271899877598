import React, { Component, ReactNode } from 'react';

import { observer } from 'mobx-react';

import styles from './HabitCard.module.scss';

import PulseCircleGraph from 'app/components/ui/PulseCircleGraph';
import { PulseForMemberModel, PulseForTeamModel, PulseRecentActivity } from 'app/models';

interface HabitTrackerCardProps {
  habit: PulseForTeamModel | PulseForMemberModel;
}

export class HabitTrackerCard extends Component<HabitTrackerCardProps> {
  get habit(): PulseForTeamModel | PulseForMemberModel {
    return this.props.habit;
  }

  getActivities(statementId): PulseRecentActivity[] {
    return this.habit.recent_activities.items.find((activity) => activity.id === statementId)
      ?.activities;
  }

  pulseCircleGraph(activities: PulseRecentActivity[]): ReactNode {
    return <PulseCircleGraph activities={activities} />;
  }

  render(): ReactNode {
    return (
      <div className={styles.trackingHabitsContainer}>
        <h6>Tracking habits</h6>

        {this.habit.statements.items.map((statement) => {
          const statementId = statement.id;
          const activities = this.getActivities(statementId);

          return (
            <div key={statementId} className={styles.statement}>
              <div className={styles.statementText}>{statement.text}</div>
              <div className={styles.waterfall}>{this.pulseCircleGraph(activities)}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default observer(HabitTrackerCard);
