import React, { ReactNode } from 'react';

import { Button, Divider, Form, Input, Select, Switch } from 'antd';
import cx from 'classnames';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import styles from '../PulseTemplates.module.scss';

import { STORE_ADMIN_PULSE_TEMPLATES } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import AdminPulseTemplateStore from 'app/stores/AdminPulseTemplateStore';

export interface Params {
  templateId: string;
}

export interface CreatePulseTemplateProps extends RouteComponentProps<Params> {
  adminPulseTemplateStore?: AdminPulseTemplateStore;
}

export class CreatePulseTemplate extends React.Component<CreatePulseTemplateProps> {
  @observable private templateName: string;
  @action setTemplateName = (name) => (this.templateName = name);

  @observable private templateScope: string;
  @action setTemplateScope = (scope) => (this.templateScope = scope);

  @observable private templateIsDefault: boolean;
  @action setIsDefault = (isDefault) => (this.templateIsDefault = isDefault);

  constructor(props: CreatePulseTemplateProps) {
    super(props);
  }

  createTemplate = async () => {
    const template = {
      name: this.templateName,
      is_default: this.templateIsDefault,
      scope: this.templateScope,
    } as any;
    const { adminPulseTemplateStore } = this.props;

    adminPulseTemplateStore?.currentTemplate.setItem(template);
    await adminPulseTemplateStore?.createTemplate();

    this.props.history.push(
      ServerRouteHelper.admin.pulseTemplates.edit(adminPulseTemplateStore?.currentTemplate?.item.id)
    );
  };

  public render(): ReactNode {
    return (
      <div className={cx(styles.templateForm, 'container')}>
        <h4>Create Pulse Template</h4>

        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal">
          <Form.Item label="Template Title">
            <Input
              value={this.templateName}
              onChange={(e) => this.setTemplateName(e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Type">
            <Select value={this.templateScope} onChange={(value) => this.setTemplateScope(value)}>
              <Select.Option value="member_pulse">Personal</Select.Option>
              <Select.Option value="team_pulse">Team</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Is Default ? ">
            <Switch
              checked={this.templateIsDefault}
              onChange={(checked) => this.setIsDefault(checked)}
            />
          </Form.Item>
        </Form>
        <Button type="primary" onClick={this.createTemplate}>
          Save
        </Button>
        <Divider />
      </div>
    );
  }
}

export default inject(STORE_ADMIN_PULSE_TEMPLATES)(observer(CreatePulseTemplate));
