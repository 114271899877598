import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { MemberItem, ModelList, PagingMetaModel } from 'app/models';
import { SubscriptionMemberModel } from 'app/models/SubscriptionMemberModel';

import Store from './Store';

export class SubscriptionMemberStore extends Store<SubscriptionMemberModel> {
  constructor() {
    super();
    SubscriptionMemberModel._store = this;
  }

  @observable pageMeta: PagingMetaModel;

  @observable teamMembersWithoutSubscription = new ModelList<SubscriptionMemberModel>(
    SubscriptionMemberModel
  );

  @observable memberLicenseNeeded = false;
  @action setMemberLicenseNeeded = (needed: boolean): void => {
    this.memberLicenseNeeded = needed;
  };

  @observable isLoadingMoreTeamMembersWithoutSubscription = false;
  @action setIsLoadingMoreTeamMembersWithoutSubscription = (isLoading: boolean) => {
    this.isLoadingMoreTeamMembersWithoutSubscription = isLoading;
  };

  @observable isAskingMyManagerForSubscription = false;
  @action setIsAskingMyManagerForSubscription = (status: boolean): void => {
    this.isAskingMyManagerForSubscription = status;
  };

  async loadTeamMembersWithoutSubscription(orgId: number): Promise<void> {
    const url = ServerRouteHelper.api.subscriptions.member.teamMembersWithoutSubscription(orgId);
    return this.teamMembersWithoutSubscription.load(url);
  }

  askMyManagerForSubscription(orgId: number, manager: MemberItem): Promise<void> {
    this.setIsAskingMyManagerForSubscription(true);

    const url = ServerRouteHelper.api.subscriptions.member.askMyManagerForSubscription(orgId);

    const config = {
      url: url,
      data: {
        manager: manager,
      },
      showGenericError: true,
      throwError: true,
    };

    try {
      return this.apiService.newPost(config);
    } finally {
      this.setIsAskingMyManagerForSubscription(false);
    }
  }

  async loadMemberSubscriptionStatus(orgId: number): Promise<void> {
    const url = ServerRouteHelper.api.subscriptions.member.subscriptionStatus(orgId);
    const response = await this.apiService.get(url);

    if (response) {
      this.setMemberLicenseNeeded(response.is_license_needed);
    }
  }
}

export default SubscriptionMemberStore;
