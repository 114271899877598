import { observable } from 'mobx';
import moment, { Moment } from 'moment';

import {
  Cadence,
  DATE_TIME_FORMAT,
  DEFAULT_TIME,
  DEFAULT_TIMEZONE,
  ReminderType,
} from 'app/constants';
import Model, { ModelJson } from 'app/models/Model';
import { PulseReminderStore } from 'app/stores';

export class PulseReminderModel extends Model {
  static _store: PulseReminderStore;

  @observable id: number;
  @observable cadence: Cadence;
  @observable interval: number;
  @observable day_of_week: string;
  @observable summary: string;
  @observable start_date: Moment;
  @observable time_of_day?: string;
  @observable timezone = DEFAULT_TIMEZONE;
  @observable last_sent_date?: Moment;
  @observable next_send_date?: Moment;
  @observable option_key: ReminderType;
  @observable email_content: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PulseReminderModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PulseReminderModel;
  }

  static get(id) {
    return this._get(id) as PulseReminderModel;
  }

  deserialize_start_date(start_date) {
    const date = start_date || new Date();
    this.start_date = moment(date, DATE_TIME_FORMAT.ISO_LOCAL_DATE);
  }

  deserialize_last_sent_date(last_sent_date) {
    const date = last_sent_date || new Date();
    this.last_sent_date = moment(date, DATE_TIME_FORMAT.ISO_LOCAL_DATE_TIME);
  }

  deserialize_timeOfDay(time_of_day) {
    const date = time_of_day || DEFAULT_TIME;
    this.time_of_day = date;
  }

  deserialize_timezone(timezone) {
    this.timezone = timezone || DEFAULT_TIMEZONE;
  }
}

export default PulseReminderModel;
