import { action, observable } from 'mobx';

import ServerRouteHelper from 'app/helpers/ServerRouteHelper';
import { Model } from 'app/models';
import PerspectiveManagerInsightsModel from 'app/models/PerspectiveManagerInsightsModels';
import Store from 'app/stores/Store';

export class PerspectiveManagerInsightsStore extends Store<Model> {
  constructor() {
    super();
    PerspectiveManagerInsightsModel._store = this;
  }

  @observable managerInsights: PerspectiveManagerInsightsModel;
  @action setManagerInsights = (managerInsights) => (this.managerInsights = managerInsights);

  @observable isLoadingManagerInsights: boolean;
  @action setisLoadingManagerInsights = (status) => (this.isLoadingManagerInsights = status);

  async loadManagerInsights(memberId: number, teamId: number) {
    const url = ServerRouteHelper.api.perspectives.managerInsights(memberId, teamId);
    const params = { token: this.sharedLinkToken };

    this.setisLoadingManagerInsights(true);
    const response = await this.apiService.get(url, params, this.defaultHeaders);
    this.setisLoadingManagerInsights(false);

    this.setManagerInsights(PerspectiveManagerInsightsModel.fromJson(response));
  }
}

export default PerspectiveManagerInsightsStore;
