import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem, ModelList } from 'app/models';
import AdminOrgContentItemModel from 'app/models/AdminOrgContentItemModel';
import AdminOrgContentModel from 'app/models/AdminOrgContentModel';
import Store from 'app/stores/Store';

export class AdminOrgContentStore extends Store<AdminOrgContentModel> {
  @observable public orgs = new ModelList<AdminOrgContentModel>(AdminOrgContentModel);

  @observable public activeOrg = new ModelItem<AdminOrgContentModel>(AdminOrgContentModel);
  @action public setActiveOrg = (org: AdminOrgContentModel): void => {
    this.activeOrg.setItem(org);
  };
  public unsetActive = (): void => {
    this.setActiveOrg(new AdminOrgContentModel(null));
  };

  @observable public defaultItems = new ModelList<AdminOrgContentItemModel>(
    AdminOrgContentItemModel
  );

  @observable public contentItems = new ModelList<AdminOrgContentItemModel>(
    AdminOrgContentItemModel
  );

  @observable public addErrors: string[] = [];
  @action setAddErrors = (errors: string[]): void => {
    this.addErrors = errors;
  };

  @observable public contentErrors: string[] = [];
  @action setContentErrors = (errors: string[]): void => {
    this.contentErrors = errors;
  };

  constructor() {
    super();

    AdminOrgContentModel._store = this;
    AdminOrgContentItemModel._store = this;
  }

  @action
  public async getOrgsWithOverrides(): Promise<void> {
    const url = ServerRouteHelper.api.admin.orgContent.orgs();

    await this.orgs.load(url, null, { forceRefresh: true });
  }

  @action
  public async getOrg(OrgId: number): Promise<void> {
    // Reset to empty org model first
    this.unsetActive();

    try {
      // Update active org
      const url = ServerRouteHelper.api.admin.orgContent.org(OrgId);
      this.activeOrg.load(url);
    } catch (errors) {
      // Suppress error, probably a not found error
    }
  }

  @action
  public async getOrgItems(orgId: number): Promise<void> {
    const url = ServerRouteHelper.api.admin.orgContent.orgItems(orgId);

    await this.contentItems.load(url, {}, { forceRefresh: true });
  }

  public async getItemList(): Promise<void> {
    const url = ServerRouteHelper.api.admin.orgContent.items();
    await this.defaultItems.load(url);
  }

  public async addNewDefault(
    org: AdminOrgContentModel,
    item: AdminOrgContentItemModel
  ): Promise<void> {
    // reset/clear errors
    this.setAddErrors([]);

    const url = ServerRouteHelper.api.admin.orgContent.orgItems(org.id);
    const payload = { key: item.key, arguments: item.arguments };

    try {
      const response = await this.apiService.newPost({
        data: payload,
        url,
        throwError: true,
      });

      const newItem = AdminOrgContentItemModel.fromJson(response.data);
      this.contentItems.appendItem(newItem);
    } catch (errors) {
      // Fallback to generic errors for exceptions
      if (!errors.errors?.content && !errors.errors?.key) {
        this.setAddErrors([errors.message]);
        return;
      }

      // Handle form validation errors
      const validationError = errors.errors.key || errors.errors.content;
      this.setAddErrors(validationError);
    }
  }

  public async updateItem(
    org: AdminOrgContentModel,
    item: AdminOrgContentItemModel
  ): Promise<void> {
    // reset/clear errors
    this.setContentErrors([]);

    const url = ServerRouteHelper.api.admin.orgContent.saveOrgItem(org.id, item.key);

    try {
      const resp = await this.apiService.newPatch({
        data: { content: item.content },
        url,
        throwError: true,
      });

      item.updateFromJson({ content: resp.data.content });
    } catch (errors) {
      // Fallback to generic errors for exceptions
      if (!errors.errors?.content) {
        this.setContentErrors([errors.message]);
        return;
      }

      // Handle form validation errors
      this.setContentErrors(errors.errors.content);
    }
  }

  public async deleteItem(
    org: AdminOrgContentModel,
    item: AdminOrgContentItemModel
  ): Promise<void> {
    // reset/clear errors
    this.setContentErrors([]);

    const url = ServerRouteHelper.api.admin.orgContent.deleteOrgItem(org.id, item.key);

    try {
      await this.apiService.delete(url);
      const updateItems = this.contentItems.items.filter((current) => current.key != item.key);
      this.contentItems.setItems(updateItems);
    } catch (errors) {
      // Fallback to generic errors for exceptions
      if (!errors.errors?.content) {
        this.setContentErrors([errors.message]);
        return;
      }

      // Handle form validation errors
      this.setContentErrors(errors.errors.content);
    }
  }
}

export default AdminOrgContentStore;
