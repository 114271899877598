import React, { CSSProperties, ReactNode } from 'react';

import { Card as AntCard } from 'antd';
import cx from 'classnames';

import './Card.scss';

import { preventDefault } from 'app/helpers';

import CardTitle from './CardTitle';

export interface CardProps {
  id?: string;
  icon?: ReactNode;
  title?: ReactNode;
  subTitle?: ReactNode;
  extra?: ReactNode;
  content?: string;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  cover?: ReactNode;
  onClick?: () => void;
  onDismiss?: () => void;
  onMouseEnter?: () => void;
}

export const Card: React.StatelessComponent<CardProps> = ({
  id,
  icon,
  title,
  subTitle,
  extra,
  content,
  className,
  style,
  children,
  cover,
  onClick,
  onMouseEnter,
}) => {
  const withTitle = !!icon || !!title || !!subTitle;

  return (
    <AntCard
      id={id}
      data-testid="card"
      className={cx('card', className, { clickable: !!onClick })}
      style={style}
      extra={extra}
      title={withTitle && <CardTitle icon={icon} title={title} subTitle={subTitle} />}
      onClick={onClick && preventDefault(onClick)}
      onMouseEnter={onMouseEnter}
      cover={cover}
    >
      {content ?? children}
    </AntCard>
  );
};

export default React.memo(Card);
