import { ScrollDirection } from 'app/components/features/ReportNavigation/TabbedReportNavigation';

interface ShrinkingHeaderServiceProps {
  setScrollDirection: (scrollDirection: ScrollDirection) => void;
  setLastScroll: (lastScroll: number) => number;
  lastScroll: number;
}

export default class ShrinkingHeaderService {
  private setScrollDirection: (scrollDirection: ScrollDirection) => void;
  private setLastScroll: (lastScroll: number) => number;
  private lastScroll: number;

  constructor(props: ShrinkingHeaderServiceProps) {
    this.setScrollDirection = props.setScrollDirection;
    this.setLastScroll = props.setLastScroll;
    this.lastScroll = props.lastScroll;
  }

  handleScrollForHeader = (): void => {
    const currentScroll = window.scrollY;
    const distanceY = currentScroll || document.documentElement.scrollTop;
    const headerEl = document.getElementById('tabs-container');
    const shrinkOn = 100;
    // Difference between shrinkOn and UnShrinkOn added to fix vibrating navbar
    const unShrinkOn = 45;

    if (currentScroll <= 0) {
      this.setScrollDirection(null);
      this.setLastScroll(0);
      return;
    }

    let direction = null;

    if (this.lastScroll !== 0) {
      direction = currentScroll > this.lastScroll ? ScrollDirection.Down : ScrollDirection.Up;
    }

    this.setScrollDirection(direction);

    if (!headerEl) {
      this.setLastScroll(currentScroll);
      return;
    }

    if (distanceY > shrinkOn) {
      headerEl.classList.add('shrinked');
    }

    if (distanceY < unShrinkOn) {
      headerEl.classList.remove('shrinked');
    }

    this.setLastScroll(currentScroll);
  };
}
