import React, { Component, ReactElement, ReactNode } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { autorun, computed, when } from 'mobx';
import { inject, observer, Provider } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';

import styles from './AppLayout.module.scss';

import { AppLayoutUiStore, ModalsUiStore } from 'app/components/features/AppLayout/stores';
import { ModalConfigItem } from 'app/components/features/AppLayout/stores/ModalsUiStore';
import MemberLicenseBanner from 'app/components/features/MemberLicenseBanner';
import { STORE_BREAKPOINT, STORE_MENU, STORE_ORGANIZATION, STORE_TEAM } from 'app/constants';
import { BreakpointStore, MenuStore, OrganizationStore } from 'app/stores';

import NavMenu from './NavMenu';
import TopPageMenu from './TopPageMenu';

export interface AppLayoutProps extends RouteComponentProps {
  className?: string;

  breakpointStore?: BreakpointStore;
  menuStore?: MenuStore;
  organizationStore?: OrganizationStore;
}

/**
 * Primary Dashboard App Layout. A 2 column layout with a fixed left side menu
 * and content area on the left.
 */
export class AppLayout extends Component<AppLayoutProps> {
  appLayoutUiStore: AppLayoutUiStore;
  modalsUiStore: ModalsUiStore;

  constructor(props: AppLayoutProps) {
    super(props);
    this.init();
  }

  protected init = async (): Promise<void> => {
    this.appLayoutUiStore = new AppLayoutUiStore({
      menuStore: this.props.menuStore,
      organizationStore: this.props.organizationStore,
    });
    this.modalsUiStore = new ModalsUiStore();

    // React to route changes
    autorun(() => {
      this.appLayoutUiStore.setPath(this.path);

      // Show sidebar on large screens when navigating to a new page
      if (!this.isMediumOrSmallScreen) {
        this.appLayoutUiStore.setShowSidebar(true);
      }

      // Hide sidebar on medium and small screens when navigating to a new page
      if (this.isMediumOrSmallScreen) {
        this.appLayoutUiStore.setShowSidebar(false);
      }
    });

    // Handle event listeners for responsive clicks
    autorun(() => {
      if (this.appLayoutUiStore.showSidebar && this.isMediumOrSmallScreen) {
        document.addEventListener('click', this.handleOffClick);
      }
    });

    // Show sidebar on large screens by explicitly setting it as the default is to not show it
    when(
      () => !this.appLayoutUiStore.showSidebar && !this.isMediumOrSmallScreen,
      () => this.appLayoutUiStore.setShowSidebar(true)
    );
  };

  componentWillUnmount(): void {
    document.removeEventListener('click', this.handleOffClick);
  }

  componentDidMount(): void {
    this.appLayoutUiStore.loadActiveOrgAndTeam();
  }

  get modals(): ModalConfigItem[] {
    return this.modalsUiStore.modals;
  }

  @computed
  get memberLicenserBanner(): ReactNode {
    if (!this.appLayoutUiStore.activeOrganization?.id) {
      return null;
    }

    return <MemberLicenseBanner organizationId={this.appLayoutUiStore.activeOrganization.id} />;
  }

  @computed
  get path(): string {
    return this.props.history.location.pathname;
  }

  @computed
  get isMediumOrSmallScreen(): boolean {
    return this.props.breakpointStore.isSmallScreen || this.props.breakpointStore.isMediumScreen;
  }

  // Off-click close handler for the sidebar
  handleOffClick = (e: MouseEvent): void => {
    // Get the clicked element
    const clickedElement = e.target as HTMLElement;
    const appLayout = clickedElement.getAttribute('class')?.includes('app-layout');

    // Check if the click event originated from the app layout excluding the sidebar
    if (appLayout) {
      e.stopPropagation();
      this.appLayoutUiStore.toggleSidebar();

      // Remove event listener after closing the sidebar
      document.removeEventListener('click', this.handleOffClick);
    }
  };

  // Add a getter to get an expand and collapse icon for the sidebar when on small or medium screens and withTopNav is false
  @computed
  get collapseIcon(): ReactNode {
    if (this.appLayoutUiStore.showSidebar && this.isMediumOrSmallScreen) {
      return (
        <CloseOutlined
          className={cx(styles.closeIcon, {
            [styles.showSidebarMenuNav]: this.appLayoutUiStore.sidebarNavMenuOpen,
          })}
          onClick={this.appLayoutUiStore.toggleSidebar}
        />
      );
    }

    return null;
  }

  render(): ReactElement {
    const menuState = this.appLayoutUiStore.showSidebar ? 'showSidebar' : 'hideSidebar';
    const children = this.props.children;

    return (
      <Provider appLayoutUiStore={this.appLayoutUiStore} modalsUiStore={this.modalsUiStore}>
        <div
          className={cx('app-layout', this.props.className, styles.appLayout, styles[menuState])}
        >
          <aside className={cx(styles.sideBar)}>
            {this.collapseIcon}
            <NavMenu />
          </aside>
          <section className={styles.content}>
            {this.memberLicenserBanner}
            <TopPageMenu />
            {children}
            {this.modals.map((modal) => (
              <React.Fragment key={modal.key}>{this.modalsUiStore.makeModal(modal)}</React.Fragment>
            ))}
          </section>
        </div>
      </Provider>
    );
  }
}

export default withRouter(
  inject(STORE_BREAKPOINT, STORE_MENU, STORE_TEAM, STORE_ORGANIZATION)(observer(AppLayout))
);
