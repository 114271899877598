import React from 'react';

import './MobileCardListDemo.scss';

import CustomDndProvider from 'app/components/features/CustomDndProvider';
import MobileCardList from 'app/pages/align/Exercise/MobileCardList';

export const MobileCardListDemo: React.StatelessComponent = () => {
  const title = 'We focus on the most important things';
  const explanation =
    'We recognize and reward perfomance against business objectives and how they are accomplished';

  const statements = [];

  for (let i = 0; i < 16; i++) {
    statements[i] = {
      id: String(i),
      title: `${title} - ${i}`,
      placed: i % 5 === 0,
      explanation: explanation,
    };
  }

  return (
    <div className="mobile-card-list-demo">
      <h4>Mobile Exercise Statement Card List Demo</h4>
      <CustomDndProvider>
        <div className="mobile-card-list-container">
          <MobileCardList
            title={<p>Mobile view 1</p>}
            statements={statements}
            onDragArrowDoubleClick={() => alert('Arrow double-clicked!')}
          />

          <MobileCardList
            title={<p>Mobile view 2</p>}
            statements={statements}
            onDragArrowDoubleClick={() => alert('Arrow double-clicked!')}
          />
        </div>
      </CustomDndProvider>
    </div>
  );
};

export default MobileCardListDemo;
