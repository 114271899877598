import PerspectiveQuestionModel from 'app/models/PerspectiveQuestionModel';

import Store from './Store';

export class PerspectiveQuestionStore extends Store<PerspectiveQuestionModel> {
  constructor() {
    super();
    PerspectiveQuestionModel._store = this;
  }
}

export default PerspectiveQuestionStore;
