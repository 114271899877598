import React from 'react';

import { Button } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import AlignImportanceModal from 'app/components/features/AlignImportanceModal';

export class AlignImportanceModalDemo extends React.Component {
  @observable isOpen = false;
  @action onToggle = () => (this.isOpen = !this.isOpen);

  render() {
    return (
      <div>
        <Button type="primary" onClick={this.onToggle}>
          Show Align Importance Modal
        </Button>

        <AlignImportanceModal isOpen={this.isOpen} onToggle={this.onToggle} />
      </div>
    );
  }
}

export default observer(AlignImportanceModalDemo);
