import React from 'react';

import './CardGridView.scss';

import {
  ExerciseStatementCard,
  ExerciseStatementCardProps,
} from 'app/components/features/ExerciseStatementCard/ExerciseStatementCard';

export interface CardGridViewProps {
  statements: ExerciseStatementCardProps[];
}

const getProps = ({ id, title, explanation, placed }: ExerciseStatementCardProps) => ({
  id,
  title,
  explanation,
  placed,
  key: id,
});

export const CardGridView: React.FC<CardGridViewProps> = ({ statements }) => (
  <div className="card-grid">
    {statements.map((statement) => (
      <ExerciseStatementCard {...getProps(statement)} />
    ))}
  </div>
);

export default CardGridView;
