import React from 'react';

import './LoginContainer.scss';
import './LoginContainer.scss';

import { Container } from 'app/pages/auth/Container';

interface LoginContainerProps {
  className?: string;
}

const LoginContainer: React.FC<LoginContainerProps> = ({ className, children }) => (
  <Container className={className}>
    <div className="acc-registration_row d-md-flex">
      <div className="acc-registration_col acc-registration_form">{children}</div>
    </div>
  </Container>
);

export default LoginContainer;
