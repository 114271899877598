import React from 'react';

import { Button } from 'antd';

import Styles from './SubmissionPlaceholder.module.scss';

export const SubmissionPlaceholder: React.FC = () => {
  return (
    <div className={Styles.container}>
      <h1>Sending response...</h1>

      <Button disabled className="stickies-animation-btn" data-testid="stickies-animation-load-btn">
        ... Loading
      </Button>
    </div>
  );
};
