export const DATE_TIME_FORMAT = {
  // Standard Formats.
  ISO_LOCAL_DATE: 'YYYY-MM-DD',
  ISO_LOCAL_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  ISO_LOCAL_TIME: 'HH:mm:ss',

  // Custom Formats.
  TIME_12_HOUR: 'h:mma',
  DAY_OF_WEEK: 'dddd',
  DAY_DATE_YEAR: 'MMM Do, YYYY',
};

export const enum Weekday {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export const enum Cadence {
  Custom = 'custom',
  Weekly = 'weekly',
  Monthly = 'monthly',
  MonthlyThirdFriday = 'monthly_third_friday',
}

export const enum ReminderType {
  Custom = 'custom',
  Weekly = 'weekly',
  Monthly = 'monthly',
  MonthlyThirdFriday = 'monthly_third_friday',
}

export const DEFAULT_TIMEZONE = 'America/New_York';
export const DEFAULT_TIME = '17:00:00';

export const WeekNumberSuffixes = {
  [1]: '1st',
  [2]: '2nd',
  [3]: '3rd',
  [4]: '4th',
  [5]: '5th',
};
