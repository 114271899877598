import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem } from 'app/models';
import IntelligenceActionResponseModel from 'app/models/IntelligenceActionResponseModel';
import { LOAD_METHOD } from 'app/models/ModelContainer';
import Store from 'app/stores/Store';

export class IntelligenceActionStore extends Store<IntelligenceActionResponseModel> {
  @observable sendActionResponse = new ModelItem<IntelligenceActionResponseModel>(
    IntelligenceActionResponseModel
  );

  constructor() {
    super();
    IntelligenceActionResponseModel._store = this;
  }

  public async sendAction(
    threadType: string,
    strategy: string,
    memberId: number,
    params?: { [key: string]: any }
  ): Promise<void> {
    const url = ServerRouteHelper.api.intelligence.actions.send(threadType, strategy, memberId);

    await this.sendActionResponse.load(url, params, {
      method: LOAD_METHOD.POST,
      forceRefresh: true,
      throwError: true,
    });
  }
}

export default IntelligenceActionStore;
