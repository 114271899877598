import { computed, observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import SubscriptionItemStore from 'app/stores/SubscriptionItemStore';

import { ModelList } from './ModelList';
import SubscriptionItemProviderModel from './SubscriptionItemProviderModel';

export class SubscriptionItemModel extends Model {
  static _store: SubscriptionItemStore;

  readonly id: number;
  @observable name: string;
  @observable currency: number;
  @observable price: string;
  @observable providers = new ModelList<SubscriptionItemProviderModel>(
    SubscriptionItemProviderModel
  );

  @computed
  get value(): string {
    return `${this.currency} ${this.price}`;
  }

  @computed
  get display(): string {
    return `${this.name} @ ${this.value}`;
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as SubscriptionItemModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as SubscriptionItemModel;
  }

  static get(id) {
    return this._get(id) as SubscriptionItemModel;
  }
}

export default SubscriptionItemModel;
