import { ceil, max, mean, orderBy, uniq } from 'lodash';
import { action, computed } from 'mobx';
import moment from 'moment';

import {
  PulseActivityModel,
  PulseReportModel,
  Response,
  ResponseStatement,
  ResponseValue,
} from 'app/models';
import { PulseForMemberStore, PulseForTeamStore } from 'app/stores';
import PulseReportStore from 'app/stores/PulseReportStore';

const OWNER_TYPE_TEAMS = 'teams';

const NUMBER_OF_ACTIVITY_DATES = 5;

export const MIN_TICKS_TO_SHOW_IN_TIME_SERIES = 9;

interface PulseReportUIStoreProps {
  ownerId: number;
  pulseId: number;
  ownerType: string;
  pulseForMemberStore: PulseForMemberStore;
  pulseForTeamStore: PulseForTeamStore;
  personalPulseReportStore: PulseReportStore;
  pulseToken?: string;
}

export default class PulseReportUIStore {
  ownerId?: number;
  pulseId?: number;
  ownerType: string;

  pulseForMemberStore: PulseForMemberStore;
  pulseForTeamStore: PulseForTeamStore;
  personalPulseReportStore: PulseReportStore;
  pulseToken?: string;

  constructor(props: PulseReportUIStoreProps) {
    this.init(props);
  }

  @action
  private init = (props: PulseReportUIStoreProps) => {
    this.ownerId = props.ownerId;
    this.ownerType = props.ownerType;

    this.pulseForMemberStore = props.pulseForMemberStore;
    this.pulseForTeamStore = props.pulseForTeamStore;
    this.personalPulseReportStore = props.personalPulseReportStore;

    if (props.pulseToken) {
      this.pulseStore.loadPulseByToken(props.pulseToken);
      this.personalPulseReportStore.loadReportByToken(props.pulseToken);
      return;
    }

    this.pulseStore.loadPulse(props.ownerId, props.pulseId);
    this.personalPulseReportStore?.loadReportData(props.ownerId, props.pulseId, props.ownerType);
  };

  @computed
  get pulseStore() {
    return this.ownerType == OWNER_TYPE_TEAMS ? this.pulseForTeamStore : this.pulseForMemberStore;
  }

  public get isLoading(): boolean {
    return this.pulseStore.pulse.loading;
  }

  get pulse() {
    return this.pulseStore.pulse.item;
  }

  get reportData(): PulseReportModel {
    return this.personalPulseReportStore.personalPulseReportData.item;
  }

  get statements(): ResponseStatement[] {
    return this.reportData?.statements ?? [];
  }

  get activities(): PulseActivityModel[] {
    return this.pulse?.recent_activities?.items ?? [];
  }

  @computed
  get responseDates(): string[] {
    const sortedResponses = orderBy(this.reportData?.aggregate_responses, ['created_at'], ['desc']);
    return uniq(sortedResponses.map((response) => response.created_at));
  }

  isSameDate(date, otherDate) {
    return moment(date).format('YYYY-DD-MM') === moment(otherDate).format('YYYY-DD-MM');
  }

  getResponsesByActivity(date: string): Response {
    return this.reportData.aggregate_responses.filter((response) =>
      this.isSameDate(date, response.created_at)
    )[0];
  }

  getMaxResponsesByActivity(date: string): number {
    const response = this.getResponsesByActivity(date);

    const allCounts = response.statements.reduce((acc, statement) => {
      const statementCounts = statement.response_values.map(({ count }) => count);
      return [...acc, ...statementCounts];
    }, []);

    return max(allCounts);
  }

  getResponsesByActivityAndStatement(date: string, statementId: number): ResponseValue[] {
    const response = this.getResponsesByActivity(date);
    const statement = response?.statements.filter(
      (statement) => statement.pulse_statement_id === statementId
    )[0];

    return statement?.response_values;
  }

  getOverallGraphByStatement(statementId: number): ResponseValue[] {
    const graphData = [];
    this.responseDates?.forEach((date) => {
      let numbers = [];
      const responseValues = this.getResponsesByActivityAndStatement(date, statementId);

      responseValues?.forEach((value) => {
        if (value.count > 0 && value.value && value.value !== 'N/A') {
          numbers = numbers.concat(Array(value.count).fill(value.value));
        }
      });

      const numbersMean = numbers.length > 0 ? mean(numbers) : 0;

      graphData.push({
        x: date,
        label: moment(date).format('MMM DD'),
        y: ceil(numbersMean) + 3, // +3 , to normalize negative values on a graph.
      });
    });

    if (graphData.length < NUMBER_OF_ACTIVITY_DATES) {
      Array.from(Array(NUMBER_OF_ACTIVITY_DATES - graphData.length).keys()).forEach((i) => {
        graphData.push({
          label: '-',
          x: i,
          y: null,
        });
      });
    }

    if (graphData?.length < MIN_TICKS_TO_SHOW_IN_TIME_SERIES) {
      graphData.push({
        x: ' ',
        label: ' ',
        y: null,
      } as any); // Giving space for glyph
    }

    if (graphData && graphData?.length > MIN_TICKS_TO_SHOW_IN_TIME_SERIES) {
      const oldestDate = graphData[graphData.length - 1].x;

      const date = moment(oldestDate).subtract(10, 'days').format('YYYY-MM-DD');
      graphData.push({
        label: ' ',
        x: date + 'T00:00:00Z',
        y: null,
      } as any); // Giving space for glyph in case of time series;
    }
    return graphData.reverse();
  }

  getGlyphData(): ResponseValue[] {
    const dataGlyph = [];
    Array.from(Array(7).keys()).forEach((i) => {
      return dataGlyph.push({
        x: ' ',
        y: i,
      });
    });

    return dataGlyph;
  }

  @computed
  get responseRatesBarData() {
    const graphData = [];

    this.reportData?.overview?.forEach((activity) => {
      graphData.push({
        label: moment(activity.created_at).format('MMM DD'),
        value: activity.total,
        isMock: false,
      });
    });

    if (graphData.length < 5) {
      const emptyDataPoints = Array.from(Array(5 - graphData.length).keys());
      emptyDataPoints.forEach((i) => {
        graphData.push({
          label: i,
          value: 0,
          isMock: true,
        });
      });
    }

    return graphData.reverse();
  }
}
