import * as React from 'react';

/**
 * Define the input props
 */
interface MithrilComponentProps {
  componentID: string;
  passProps?: any;
}

/**
 * Allows a Mithril Component be drawn from React
 */
export default class MithrilComponent extends React.Component<MithrilComponentProps> {
  key: string;
  containerID: string;

  constructor(props) {
    super(props);

    const randomString = Math.random().toString(36).substring(8);

    const { componentID } = props;

    this.key = `${randomString}_${componentID}`;
    this.containerID = `${this.key}_container`;
  }

  handleComponentRef(ref) {
    const { componentID } = this.props;
    const callback = accompany.MithrilCallbacks[componentID];

    if (callback && ref) {
      try {
        callback(ref);
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    const { key, containerID } = this;
    const { passProps } = this.props;

    return (
      <div className="logical_container" key={key}>
        <div
          ref={(ref) => this.handleComponentRef(ref)}
          className="logical_container"
          id={containerID}
          {...passProps}
        ></div>
      </div>
    );
  }
}
