import React from 'react';

import { preventDefault } from 'app/helpers';
import { ExerciseTypeModel } from 'app/models';

export interface AllExerciseTypeListProps {
  allExerciseTypes: ExerciseTypeModel[];
  selectedExerciseTypes: { [id: number]: boolean };
  onSave: () => void;
  onSelected: (exerciseType: ExerciseTypeModel) => void;
  isSaving: boolean;
}

export const AllExerciseTypeList: React.StatelessComponent<AllExerciseTypeListProps> = ({
  allExerciseTypes,
  selectedExerciseTypes,
  onSave,
  onSelected,
  isSaving,
}) => (
  <div>
    <h4>Exercise Types</h4>
    <form method="post">
      <div style={{ columns: 'auto 15rem' }}>
        {allExerciseTypes.map((exerciseType, i) => (
          <div key={`exercise-type-${i}`} className="form-check">
            <label>
              <input
                className="form-check-input"
                type="checkbox"
                value={exerciseType.id}
                checked={!!selectedExerciseTypes[exerciseType.id]}
                onChange={() => onSelected(exerciseType)}
              />
              {exerciseType.name}
            </label>
          </div>
        ))}
      </div>
      <div className="mt-2 text-right">
        <button
          type="submit"
          className="btn acc-btn-caps btn-primary"
          onClick={preventDefault(onSave)}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </form>
  </div>
);

export default AllExerciseTypeList;
