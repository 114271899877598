import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { TeamCardChecklistStore } from 'app/stores';

import { ChecklistResourceModel } from './ChecklistResourceModel';
import { TeamCardChecklistItemModel } from './TeamCardChecklistItemModel';

export class TeamCardChecklistModel extends Model {
  static _store: TeamCardChecklistStore;

  readonly id: number;
  @observable type: string;
  @observable title: string;
  @observable next_steps_text: string;
  @observable caption: string;
  @observable completed: boolean;
  @observable progress: number;
  @observable items: TeamCardChecklistItemModel[];
  @observable program_title?: string;
  @observable resources: ChecklistResourceModel[];

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamCardChecklistModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeamCardChecklistModel;
  }

  static get(id) {
    return this._get(id) as TeamCardChecklistModel;
  }
}

export default TeamCardChecklistModel;
