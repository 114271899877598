import { observable } from 'mobx';

import type { ExerciseTypeModel } from './ExerciseTypeModel';
import PlainModel from './PlainModel';
import ScoreModel from './ScoreModel';

export const EXERCISE_ANSWER_LABEL_YOU = 'YOU';

export interface ExerciseAnswerProps {
  created_at?: string;
  opened_at?: string;
  finished_at?: string;

  align_statement_id: number;

  member_id: number;
  member_role?: string;
  member?: {
    name?: string;
  };

  coordinate_x: number;
  coordinate_y: number;
}

export class ExerciseAnswerModel extends PlainModel {
  readonly id: number;
  readonly align_statement_id: number;

  @observable created_at?: string;
  @observable opened_at?: string;
  @observable finished_at?: string;

  @observable member_id: number;
  @observable member_name?: string;
  @observable member_role?: string;
  @observable exerciseType?: ExerciseTypeModel;

  @observable score: ScoreModel;

  @observable coordinate_x: number;
  @observable coordinate_y: number;

  constructor(props: ExerciseAnswerProps, exerciseType?: ExerciseTypeModel) {
    const { coordinate_x: x, coordinate_y: y } = props;

    super(props);

    this.member_name = props.member?.name;
    this.exerciseType = exerciseType;
    this.score = new ScoreModel({ x, y }, this.exerciseType);
  }
}
