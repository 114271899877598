import { observable } from 'mobx';

import MenuItemModel from 'app/models/MenuItemModel';
import Model, { ModelJson } from 'app/models/Model';
import { MenuStore } from 'app/stores';

export class MenuModel extends Model {
  public static _store: MenuStore;

  @observable id: string;
  @observable menu_items: MenuItemModel[];
  @observable active_team_id?: number;
  @observable active_organization_id?: number;

  deserialize_menuItems(children): void {
    this.menu_items = children.map((items) => MenuItemModel.fromJson(items));
  }

  static fromJson(json: ModelJson): MenuModel {
    return this._fromJson(json) as MenuModel;
  }

  static getOrNew(id): MenuModel {
    return this._getOrNew(id) as MenuModel;
  }

  static get(id): MenuModel {
    return this._get(id) as MenuModel;
  }
}

export default MenuModel;
