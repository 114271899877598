import { computed } from 'mobx';
import moment from 'moment';

import { GREEN, RED, YELLOW } from 'app/constants/colors';
import { AlignHelper } from 'app/helpers';

export enum ScoreBucket {
  Celebrate = 0,
  Discuss = 1,
  Diagnose = 2,
}

export interface AlignAnswer {
  x: number;
  y: number;
}

export interface AlignStatementScore {
  statementID: number;
  text: string;
  score: number;
  answers: AlignAnswer[];
  scoreRescaled: number;
  bucket: ScoreBucket;
  color?: string;
  alignReport?: AlignReportModel;
  habitSuggestions?: string[];
}

export interface AlignLabels {
  top: string;
  right: string;
  bottom: string;
  left: string;
}

export class AlignReportModel {
  scoreCutoffs: number[];
  labels: AlignLabels;
  statements: AlignStatementScore[];
  exerciseTypeCode: string;
  closedAt: moment.Moment;

  constructor(data) {
    this.closedAt = moment(data.closedAt);
    this.scoreCutoffs = data.scoreCutoffs;
    this.exerciseTypeCode = data.exerciseType.code;

    const { top, right, left, bottom } = data.exerciseType;
    this.labels = { top, right, left, bottom };

    const scoreColors = [GREEN, YELLOW, RED];
    const scoreToColor = AlignHelper.algoColor(this.scoreCutoffs);
    const rescaleScore = AlignHelper.algoRescale(this.scoreCutoffs);

    this.statements = data.statements.map((item) => {
      const answers = item.answers.map((a) => ({
        x: a.coordinate_x,
        y: a.coordinate_y,
      }));
      const scoreRescaled = rescaleScore(item.score);
      const color = scoreToColor(item.score);
      const bucket = scoreColors.indexOf(color);

      // filter out empty string suggestions
      const habitSuggestions = item.habitSuggestions.filter((text) => !!text);

      return {
        ...item,
        answers,
        scoreRescaled,
        bucket,
        color,
        habitSuggestions,
        alignReport: this,
      };
    });
  }

  @computed get statementsToCelebrate() {
    return this.statements.filter((s) => s.bucket === ScoreBucket.Celebrate);
  }

  @computed get statementsToDiscuss() {
    return this.statements.filter((s) => s.bucket === ScoreBucket.Discuss);
  }

  @computed get statementsToDiagnose() {
    return this.statements.filter((s) => s.bucket === ScoreBucket.Diagnose);
  }
}

export default AlignReportModel;
