import PerspectivePairScoreDiffModel from 'app/models/PerspectivePairScoreDiffModel';

import Store from './Store';

export class PerspectivePairScoreDiffStore extends Store<PerspectivePairScoreDiffModel> {
  constructor() {
    super();
    PerspectivePairScoreDiffModel._store = this;
  }
}

export default PerspectivePairScoreDiffStore;
