import React, { useState } from 'react';

import { EyeInvisibleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import './ExerciseStatementCardList.scss';

import { ExerciseCardListView } from 'app/constants';
import { ExerciseStatement } from 'app/models';

import AllStatementsModal from '../AllStatementsModal';
import CardCarouselView from '../CardCarouselView';
import CardGridView from '../CardGridView';
import { SubmitButton } from '../CardListActions/SubmitButton';
import { TestDriveButton } from '../CardListActions/TestDriveButton';

export interface ExerciseCustomStatementCardListProps {
  view?: ExerciseCardListView;
  statements: ExerciseStatement[];
  onSubmit: () => void;
  onDragArrowDoubleClick: () => void;
  renderTestDriveView?: boolean;
  exerciseIs360?: boolean;
  onTipsClick: () => void;
  submitButtonText?: string;
  teamName?: string;
}

export const ExerciseCustomStatementCardList: React.FC<ExerciseCustomStatementCardListProps> = ({
  view = ExerciseCardListView.REGULAR,
  statements,
  onSubmit,
  onDragArrowDoubleClick,
  renderTestDriveView,
  exerciseIs360,
  onTipsClick,
  submitButtonText,
  teamName,
}) => {
  const [arrowClickCount, setArrowClickCount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);
  const handleArrowClick = () => {
    if (arrowClickCount === 0) {
      setArrowClickCount(arrowClickCount + 1);
      return;
    }
    setArrowClickCount(0);
    onDragArrowDoubleClick();
  };

  const cardTitle = (): React.ReactNode => {
    return (
      <div className="card-title">
        <div className="right-section">
          <p className="header">Help your team improve</p>
          <p className="sub-header" id="double-click-auto-drag" onClick={handleArrowClick}>
            Drag the cards to rate how your team, {teamName} is doing.
          </p>
        </div>
        <Button
          className="ant-btn ant-btn-primary ant-btn-background-ghost action-btn"
          onClick={onTipsClick}
          id="tips-btn"
        >
          Help
        </Button>
      </div>
    );
  };

  const renderCards = () => {
    if (view === ExerciseCardListView.REGULAR) {
      return <CardGridView statements={statements} />;
    }

    // ExerciseCardListView.DETAILED
    return (
      <CardCarouselView
        statements={statements}
        onSeeAllStatements={toggleModal}
        onSubmit={onSubmit}
        renderTestDriveView={renderTestDriveView}
        render360View={exerciseIs360}
      />
    );
  };

  const isSubmitDisabled = statements.filter((statement) => !statement.placed).length > 0;

  const isOneStatementPlaced = statements.filter((statement) => statement.placed).length > 0;

  const buttonDisabled = () => !renderTestDriveView && isSubmitDisabled;

  const submitButton = () => {
    if (renderTestDriveView) {
      return <TestDriveButton onClick={onSubmit} disabled={buttonDisabled()} />;
    }

    return (
      <SubmitButton
        label={submitButtonText ?? 'Next'}
        className="statement-cardlist-custom-submit-btn"
        disabled={buttonDisabled()}
        onClick={onSubmit}
      />
    );
  };

  return (
    <div className="exercise-statement-card-list">
      <div className="main">
        {cardTitle()}
        {renderCards()}

        {!isOneStatementPlaced && (
          <img className="drag-arrow" src="/images/align/align-exercise-drag-arrow.svg" />
        )}

        <p className="responses-are-anonymous-text">
          <EyeInvisibleOutlined /> &nbsp; Your responses are anonymous
        </p>

        {submitButton()}

        <AllStatementsModal statements={statements} isOpen={showModal} onToggle={toggleModal} />
      </div>
    </div>
  );
};

export default ExerciseCustomStatementCardList;
