import React, { Component, ReactNode } from 'react';

import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';

import styles from './SelectedPeers.module.scss';

import EnhancedCard from 'app/components/ui/EnhancedCard';
import { Clickable } from 'app/components/ui/EnhancedCard/CardFooter/CardFooter';
import MemberAvatar from 'app/components/ui/MemberAvatar';
import { MemberItem } from 'app/models';
import PulseHabitsContext from 'app/pages/dashboard/Pulse/Context/PulseHabitsContext';

interface SelectedPeersProps extends RouteComponentProps {}

export class SelectedPeers extends Component<SelectedPeersProps> {
  static contextType = PulseHabitsContext;

  @computed
  private get selectedMembers(): MemberItem[] {
    return this.context.uiStore.participants.filter((member) => member.isSelected);
  }

  private get isTestDrive(): boolean {
    return this.context.uiStore.isTestDrive;
  }

  @computed
  private get nextButtonStatus(): boolean {
    if (this.isTestDrive) {
      return false;
    }

    return this.context.uiStore.isSaving || !this.selectedMembers.length;
  }

  @computed
  private get nextButton(): Clickable {
    const { isSaving } = this.context.uiStore;
    const hasSelectedPeers = this.context.uiStore.hasSelectedPeers;

    return {
      label: isSaving ? 'Saving...' : 'Next: configure cadence',
      disabled: this.nextButtonStatus,
      className: !hasSelectedPeers ? 'btn-outline' : null,
      onClick: this.handleNext,
    };
  }

  @computed
  private get backButton(): Clickable {
    return {
      label: 'Back',
      disabled: this.context.uiStore.isSaving,
      onClick: () => this.handlePrev(),
    };
  }

  @computed
  private get previewButton(): Clickable {
    return {
      label: 'Preview Invite',
      onClick: this.context.uiStore.toggleEmailModal,
    };
  }

  @computed
  private get prevUrl(): string {
    return this.context.uiStore.getStepUrl('add');
  }

  @computed
  private get nextUrl(): string {
    return this.context.uiStore.getStepUrl('configure');
  }

  handleNext = async (): Promise<void> => {
    const response = await this.context.uiStore.updateParticipants();

    if (response || this.isTestDrive) {
      this.props.history.push(this.nextUrl);
    }
  };

  handlePrev = (): void => {
    this.props.history.push(this.prevUrl);
  };

  public render(): ReactNode {
    return (
      <EnhancedCard
        heading="Your selected colleagues"
        footerPrimaryButton={this.nextButton}
        footerSecondaryButton={this.backButton}
        footerActionButton={this.previewButton}
      >
        <div className="card-content-container">
          <ul className={styles.selectedMembers}>
            {!this.selectedMembers.length && <i>Please select participants for this habit</i>}
            {this.selectedMembers.map((member) => (
              <li key={member.id}>
                <MemberAvatar {...member.avatar} /> {member.name}
              </li>
            ))}
          </ul>
        </div>
      </EnhancedCard>
    );
  }
}

export default withRouter(observer(SelectedPeers));
