import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { DiscussionSpaceRaisedHandStore } from 'app/stores';

import MemberModel from './MemberModel';

export class DiscussionSpaceRaisedHandModel extends Model {
  public static _store: DiscussionSpaceRaisedHandStore;

  @observable public id: number;
  @observable public discussion_space_id: number;
  @observable public member: MemberModel;
  @observable public active: boolean;
  @observable public date: string;

  public uuid: string;

  deserialize_member(member: any) {
    if (member instanceof MemberModel) {
      this.member = member;
      return;
    }

    this.member = MemberModel.fromJson(member);
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as DiscussionSpaceRaisedHandModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as DiscussionSpaceRaisedHandModel;
  }

  static get(id) {
    return this._get(id) as DiscussionSpaceRaisedHandModel;
  }
}

export default DiscussionSpaceRaisedHandModel;
