import React, { Component, ReactNode } from 'react';

import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';

import './WelcomeToMy360Modal.scss';

import { MultiStepModal, StepNavigation } from 'app/components/ui/AntModal';
import { MultiColumnLayout } from 'app/components/ui/ModalLayouts';
import { ClientDataHelper } from 'app/helpers';
import { AlignModel, ExerciseStatementModel } from 'app/models';

export interface WelcomeToMy360ModalProps {
  isOpen: boolean;
  firstName?: string;
  initialStep?: number;
  statements: string[];
  exercise: AlignModel;
  onClose: () => void;
}

export class WelcomeToMy360Modal extends Component<WelcomeToMy360ModalProps> {
  @observable messages = [];
  @action setMessages = (messages) => (this.messages = messages);

  constructor(props: WelcomeToMy360ModalProps) {
    super(props);
    this.init();
  }

  init = async (): Promise<void> => {
    const messages = await ClientDataHelper.get('welcome_messages');
    this.setMessages(messages ?? []);
  };

  renderStepContent = (content): string => {
    return content
      .replace('[first_name]', this.props.firstName)
      .replace('[statement_count]', this.exerciseStatements.length);
  };

  @computed
  get exerciseStatements(): ExerciseStatementModel[] {
    return this.props.exercise.exercise_type?.item.align_statements;
  }

  @computed
  get stepOne(): ReactNode {
    const message = this.messages.find(({ id }) => id === 'step_one');
    const title = this.renderStepContent(message.title);
    const content = this.renderStepContent(message.content);

    return (
      <div className="step-one-content">
        <p className="title">{title}</p>
        <img src="/images/feedback.svg" />
        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    );
  }

  @computed
  get stepTwo(): ReactNode {
    const message = this.messages.find(({ id }) => id === 'step_two');
    const title = this.renderStepContent(message.title);
    const content = this.renderStepContent(message.content);

    return (
      <div className="my360-onboarding-modal-step-two">
        <p className="title">{title}</p>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <span>
          <img src="/images/modals/my360-modal-img.svg" />
        </span>
      </div>
    );
  }

  @computed
  get stepThree(): ReactNode {
    const { statements } = this.props;

    return (
      <div className="welcome-to-my360-modal-step-normal">
        <p className="title">HOW IT WORKS</p>
        <p>On the left is a series of cards containing behaviours. For example:</p>
        <div className="cards-container">
          {statements.map((statement, i) => (
            <div key={i} className="exercise-statement-card">
              <div className="body">{statement}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  @computed
  get stepFour(): ReactNode {
    const { firstName, statements } = this.props;

    return (
      <div className="welcome-to-my360-modal-step-normal">
        <p className="title">HOW IT WORKS</p>
        <p>
          <strong>Drag each card onto the grid on the right based on:</strong>
        </p>
        <p>1. how important that statement is to you, and</p>
        <p>2. how well you feel it’s going for {firstName ? firstName : 'you'}</p>
        <div className="cards-container">
          <div className="exercise-statement-card">
            <div className="body">{statements[0]}</div>
          </div>
          <span>
            <img src="/images/align/welcome-modal/step-three-drag.svg" />
          </span>
        </div>
      </div>
    );
  }

  @computed
  get stepFive(): ReactNode {
    const { firstName } = this.props;

    return (
      <MultiColumnLayout
        title="THIS EXERCISE IS:"
        className="my360-final-step"
        columns={[
          <div className="column">
            <img src="/images/align/welcome-modal/dashboard.svg" />
            <strong>Quick</strong>
            <small>It typically takes less than 10 minutes to complete.</small>
          </div>,
          <div className="column">
            <img src="/images/align/welcome-modal/users.svg" />
            <strong>Anonymous</strong>
            <small>You're free to be 100% honest. In fact it's encouraged!</small>
          </div>,
          <div className="column">
            <img src="/images/align/welcome-modal/snow.svg" />
            <strong>Relaxed</strong>
            <small>It's about helping {firstName} grow, not a formal evaluation.</small>
          </div>,
        ]}
      />
    );
  }

  @computed
  get steps() {
    return [
      {
        content: this.stepOne,
        primaryAction: { label: 'Next', onClick: StepNavigation.nextStep },
      },
      {
        content: this.stepTwo,
        secondaryAction: { label: 'How it works', onClick: StepNavigation.nextStep },
        primaryAction: { label: 'Start exercise', onClick: StepNavigation.firstStepAndClose },
      },
      {
        content: this.stepThree,
        primaryAction: { label: 'Next', onClick: StepNavigation.nextStep },
      },
      {
        content: this.stepFour,
        primaryAction: { label: 'Next', onClick: StepNavigation.nextStep },
      },
      {
        content: this.stepFive,
        primaryAction: { label: 'Go to exercise', onClick: StepNavigation.firstStepAndClose },
      },
    ];
  }

  render(): ReactNode {
    const { isOpen, initialStep, onClose } = this.props;

    if (this.messages.length === 0) {
      return null;
    }

    return (
      <MultiStepModal
        className="welcome-to-my360-modal"
        isOpen={isOpen}
        steps={this.steps}
        initialStep={initialStep}
        onToggle={onClose}
        footerClassName="welcome-to-my360-footer"
      />
    );
  }
}

export default observer(WelcomeToMy360Modal);
