import { computed } from 'mobx';

import Model from './BaseModel';

export class HtmlArraySegmentModel extends Model {
  id: string;
  heading: string;
  html: string[];
  single_entry: false;

  /**
   * Handle html based on if its a single entry or not.
   */
  @computed
  get singleEntryHtml(): string {
    return this.single_entry ? this.fullHtml : this.contentHtml;
  }

  /**
   * All the HTML including the heading on the first line.
   */
  @computed
  get fullHtml(): string {
    return this.html.join('');
  }

  /**
   * Html without the heading first line.
   */
  @computed
  get contentHtml(): string {
    return this.html.filter((item, index) => index !== 0).join('');
  }

  /**
   * Plain text version of `contentHtml` where HTML tags have been stripped.
   */
  @computed
  get contentPlainText(): string {
    const temporalDiv = document.createElement('div');
    temporalDiv.innerHTML = this.contentHtml;

    // Get the text property of the element (browser support)
    return temporalDiv.textContent || temporalDiv.innerText || '';
  }

  static fromJson(json): HtmlArraySegmentModel {
    return this._fromJson(json) as HtmlArraySegmentModel;
  }
}

export default HtmlArraySegmentModel;
