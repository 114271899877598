import { action } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { OrganizationModel } from 'app/models/OrganizationModel';
import { RoleModel } from 'app/models/RoleModel';

import Store from './Store';

export class RoleStore extends Store<RoleModel> {
  constructor() {
    super();
    RoleModel._store = this;
  }

  @action
  async loadRoles(orgId: number, forceRefresh?: boolean) {
    const organization = OrganizationModel.getOrNew(orgId);
    await organization.roles.load(
      ServerRouteHelper.api.organizations.roles(organization.id),
      null,
      { dataKey: 'roles', forceRefresh }
    );
  }

  @action
  async removeRole(orgId: number, roleId: number): Promise<void> {
    const role = RoleModel.get(roleId);
    if (!role || role.deleting) {
      return;
    }
    role.deleting = true;
    await this.apiService.delete(ServerRouteHelper.api.organizations.role(orgId, roleId));
    role.deleted = true;
    role.deleting = false;
  }

  @action
  async addRole(orgId: number, name: string): Promise<RoleModel> {
    const response = await this.apiService.post(ServerRouteHelper.api.organizations.roles(orgId), {
      name,
    });

    // adding a role, effects other roles as well because org may be switched to custom roles. So we need to refresh.
    this.loadRoles(orgId, true);
    return RoleModel.fromJson(response.data);
  }
}

export default RoleStore;
