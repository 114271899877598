import { computed, observable } from 'mobx';

import { CLASS_COLOR_MAP } from 'app/constants';
import { AlignHelper } from 'app/helpers';

import ExerciseTypeModel from './ExerciseTypeModel';
import PlainModel from './PlainModel';

/**
 * Define the properties that are passed in to create Scores
 */
interface ScoreProps {
  id?: number;
  align_statement_template_id?: number;

  x: number;
  y: number;
}

/**
 * ScoreModel is used to represent Scores
 */
export class ScoreModel extends PlainModel {
  readonly id: number;
  @observable align_statement_template_id: number;

  @observable x: number;
  @observable y: number;

  exerciseType?: ExerciseTypeModel;

  constructor(props: ScoreProps, exerciseType?: ExerciseTypeModel) {
    // Ensure we only initialize the values that we need
    const { id, align_statement_template_id, x, y } = props;
    super({ id, align_statement_template_id, x, y });

    this.exerciseType = exerciseType;
  }

  @computed get exerciseScore() {
    return AlignHelper.alignScore(this.x, this.y);
  }

  @computed get colorClassification() {
    return CLASS_COLOR_MAP[this.exerciseType.classifyScore(this.exerciseScore)];
  }

  @computed get scaledExerciseScore() {
    return this.exerciseType.scaleScore(this.exerciseScore);
  }

  @computed get coordinates() {
    return [this.x, this.y];
  }
}

export default ScoreModel;
