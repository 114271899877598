import React, { MouseEvent, ReactElement } from 'react';

import { Tooltip } from 'antd';
import cx from 'classnames';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import FlipMove from 'react-flip-move';

import './AlignFocusAreaSection.scss';

import { ScoreBarRow } from 'app/components/features/ScoreBarRow/ScoreBarRow';
import { STATEMENT_SECTION_COLORS, STORE_BREAKPOINT } from 'app/constants';
import { ExerciseReportModel, ScatterPlotPoint, SortOption } from 'app/models';
import { BreakpointStore } from 'app/stores';

const DURATION_IN_MS = 500;
const STAGGER_DURATION_BY_IN_MS = 30;
/*
If you are animating 3 elements with a duration of 200 and a staggerDurationBy of 30:
The first element will take 200ms to transition.
The second element will take 230ms to transition.
The third element will take 260ms to transition.
*/
interface AlignFocusAreaSectionProps {
  header: string;
  color: string;
  tooltip?: string;
  showTooltip?: boolean;
  onTooltipSeen?: () => void;
  isNonClickableRows?: boolean;
  showArrow?: boolean;
  statements: {
    id: number;
    statement: string;
    score: number;
    scoreLabel?: string;
    scoreClasses?: string;
    scoreMeta?: { tooltip?: string };
    benchmark?: number;
    percentiles?: Array<{ value: number; label: string }>;
    showArrow?: boolean;
    selected?: boolean;
    themedActive?: boolean;
    statementInsightText?: string;
    color?: string;
    sectionColor?: string;
    otherBenchmarks?: any[];
    benchmarkColor?: string;
    allPoints?: ScatterPlotPoint[];
  }[];
  report?: ExerciseReportModel;
  hideStatementIcons?: boolean;
  hideStatementBenchmark?: boolean;
  hideSectionHeaders?: boolean;
  showGetInsightsButton?: boolean;
  showScatterPlotButton?: boolean;
  onScatterPlotBtnClick?: (id: number) => void;
  isCompassionateConversation?: boolean;
  onStatementClick?: (event: MouseEvent, statementID: number) => void;
  onGetInsightClick?: (event: MouseEvent, statementID: number) => void;
  scatterplotStatementId?: number;
  secondaryStatementButtonRenderer?: (statement: any) => ReactElement;
  breakpointStore?: BreakpointStore;
}

export class AlignFocusAreaSection extends React.Component<AlignFocusAreaSectionProps> {
  statementButton(statement: any) {
    if (this.props.secondaryStatementButtonRenderer) {
      return this.props.secondaryStatementButtonRenderer(statement);
    }

    return null;
  }

  @computed
  get isMobile() {
    const { isMobileDevice } = this.props.breakpointStore;
    return isMobileDevice;
  }

  @computed
  get statements(): ReactElement {
    const {
      statements,
      onStatementClick,
      onGetInsightClick,
      scatterplotStatementId,
      showTooltip,
      showArrow,
      onTooltipSeen,
      onScatterPlotBtnClick,
      hideStatementIcons,
      hideStatementBenchmark,
      showScatterPlotButton,
      showGetInsightsButton,
      isCompassionateConversation,
      report,
    } = this.props;

    let sectionColor = STATEMENT_SECTION_COLORS[this.props.color];

    if (
      report?.sortBy === SortOption.HighestToLowest ||
      report?.sortBy === SortOption.LowestToHighest
    ) {
      // Setting color to default when sortoption is set to sort by highest to lowest or lowest to highest.
      sectionColor = undefined;
    }

    const handleGetInsightClick = (event, statementId) => {
      return onGetInsightClick
        ? onGetInsightClick(event, statementId)
        : onStatementClick(event, statementId);
    };

    return (
      <div className="statements">
        <FlipMove
          staggerDurationBy={STAGGER_DURATION_BY_IN_MS}
          duration={DURATION_IN_MS}
          enterAnimation="accordionVertical"
          leaveAnimation="accordionVertical"
        >
          {statements.map((statement, i) => {
            const color = statement.sectionColor
              ? STATEMENT_SECTION_COLORS[statement.sectionColor]
              : STATEMENT_SECTION_COLORS[statement.color];

            return (
              <div key={`statement-${statement.id}`}>
                <ScoreBarRow
                  label={statement.statement}
                  circleValue={statement.score}
                  circleLabel={statement.scoreLabel}
                  circleColor={statement.color}
                  circleClassName={statement.scoreClasses}
                  rowColor={sectionColor || color}
                  diamondValue={!hideStatementBenchmark ? statement.benchmark : undefined}
                  iconClass="fa-chart-scatter statement-icon"
                  showTooltip={showTooltip}
                  showArrow={showArrow || statement.showArrow}
                  showExpandIcon
                  pins={statement.percentiles}
                  otherBenchmarks={statement.otherBenchmarks}
                  onClick={(event) => onStatementClick?.(event, statement.id)}
                  onInsightButtonClick={(event) => handleGetInsightClick(event, statement.id)}
                  onScatterPlotBtnClick={() => onScatterPlotBtnClick(statement.id)}
                  handleTooltipSeen={onTooltipSeen}
                  hideStatementIcons={hideStatementIcons}
                  isStatementScatterPlotActive={scatterplotStatementId === statement.id}
                  secondaryStatementButton={this.statementButton(statement)}
                  allPoints={statement.allPoints}
                  showAxes={!isCompassionateConversation}
                  selected={statement.selected}
                  nonClickable={this.props.isNonClickableRows}
                  themed={!isCompassionateConversation}
                  themedActive={statement.themedActive}
                  statementInsightText={statement?.statementInsightText}
                  showGetInsightsButton={showGetInsightsButton}
                  showScatterPlotButton={showScatterPlotButton}
                  highlightScatterPlotButton={this.props.report?.singleStatementInsights.has(
                    statement.id
                  )}
                  scatterPlotPlacement="right"
                  scatterPlotClassName="right-offset"
                  enableAdjustOverFlow={this.isMobile ? true : false}
                />
              </div>
            );
          })}
        </FlipMove>
      </div>
    );
  }

  @computed
  get sectionHeader(): ReactElement {
    const { header, color, tooltip } = this.props;
    return (
      <div className="mt-0 mb-2 row no-gutters">
        <div className="order-1 d-flex align-items-center col-md-5 order-md-0 header">
          {color && <i className="far fa-circle header-icon" style={{ color }} />}
          <span className="my-0 d-inline-block header-text">{header}</span>
          {tooltip && (
            <Tooltip placement="right" title={tooltip}>
              <div>
                <i className="p-1 ml-1 fal fa-question-circle header-tip-icon" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }

  render(): ReactElement {
    const { hideSectionHeaders } = this.props;

    return (
      <div className="align-focus-area-section">
        <div className={cx('flex-fill', { 'mb-4': !hideSectionHeaders })}>
          {!hideSectionHeaders && this.sectionHeader}
          {this.statements}
        </div>
      </div>
    );
  }
}

export default inject(STORE_BREAKPOINT)(observer(AlignFocusAreaSection));
