import React, { ReactNode } from 'react';

import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import cx from 'classnames';

import styles from './Header.module.scss';

interface HeaderProps {
  largeTitled?: boolean;
  balancedHeader?: boolean;
  title?: ReactNode;
  loading?: boolean;
  dataTrackPrefix?: string;
  closeButtonTag?: string;
  onBack?: () => void;
  onToggle: () => void;
}

export default function Header({
  largeTitled,
  balancedHeader,
  title,
  loading,
  dataTrackPrefix,
  closeButtonTag,
  onBack,
  onToggle,
}: HeaderProps): JSX.Element {
  return (
    <div
      className={cx(styles.header, {
        [styles.largeTitled]: largeTitled,
        [styles.balancedHeader]: balancedHeader,
      })}
    >
      {onBack && (
        <button className={styles.backButton} onClick={onBack}>
          <LeftOutlined />
          <span>Back</span>
        </button>
      )}

      {title && (
        <Skeleton loading={loading} paragraph={false}>
          <span>{title}</span>
        </Skeleton>
      )}

      {onToggle && (
        <button
          className="ant-modal-close"
          data-testid="modal-close-btn"
          data-track={dataTrackPrefix && `${dataTrackPrefix}-close-btn`}
          data-tag={closeButtonTag}
          onClick={onToggle}
        >
          <span className="ant-modal-close-x">
            <CloseOutlined />
          </span>
        </button>
      )}
    </div>
  );
}
