import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { DiscussionSpaceVoteStore } from 'app/stores';

export enum DiscussionSpaceVoteTypes {
  Statement = 'statement',
  Note = 'note',
}

export class DiscussionSpaceVoteModel extends Model {
  public static _store: DiscussionSpaceVoteStore;

  @observable public id: number;
  @observable public entity_id: number;
  @observable public entity_type: string;
  @observable public member_id: number;
  @observable public discussion_space_id: number;

  static fromJson(json: ModelJson): DiscussionSpaceVoteModel {
    return this._fromJson(json) as DiscussionSpaceVoteModel;
  }

  static getOrNew(id: number): DiscussionSpaceVoteModel {
    return this._getOrNew(id) as DiscussionSpaceVoteModel;
  }

  static get(id: number): DiscussionSpaceVoteModel {
    return this._get(id) as DiscussionSpaceVoteModel;
  }
}

export default DiscussionSpaceVoteModel;
