import React, { MouseEvent, ReactElement } from 'react';

import {
  DotChartOutlined,
  InfoCircleFilled,
  RightOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import cx from 'classnames';

import './ScoreBarRow.scss';

import ScatterPlot from 'app/components/features/ScatterPlot';
import ScoreBar from 'app/components/features/ScoreBar';
import MouseAwarePopOver from 'app/components/thirdparty/MouseAwarePopOver';
import { GREEN } from 'app/constants';
import { ENTER_KEY_CODE } from 'app/constants/keyMapping';
import { stopPropagation } from 'app/helpers';
import { ScatterPlotPoint } from 'app/models';

export interface ScoreBarRowProps {
  onClick?: React.MouseEventHandler;
  label: string;

  circleValue?: number;
  circleColor?: string;
  circleClassName?: string;
  circleLabel?: string;
  circleMeta?: { tooltip?: string };

  rowColor?: string;
  diamondValue?: number;
  nonClickable?: boolean;
  showAxes?: boolean;

  iconClass?: string;
  showExpandIcon?: boolean;
  showTooltip?: boolean;
  handleTooltipSeen?: () => void;
  className?: string;
  rowWidthClass?: string;
  showArrow?: boolean;
  pins?: { value: number; label: string }[];
  otherBenchmarks?: { value: number; marker: ReactElement; tooltip?: string }[];
  hideStatementIcons?: boolean;
  secondaryStatementButton?: ReactElement;
  allPoints?: ScatterPlotPoint[];
  showScatterPlotButton?: boolean;
  showGetInsightsButton?: boolean;
  selected?: boolean;
  // Themed is a styling props
  themed?: boolean;
  statementInsightText?: string;
  themedActive?: boolean;
  highlightScatterPlotButton?: boolean;
  scatterPlotPlacement?: TooltipPlacement;
  scatterPlotClassName?: string;
  enableAdjustOverFlow?: boolean;
  isStatementScatterPlotActive?: boolean;
  onInsightButtonClick?: (event: MouseEvent) => void;
  onScatterPlotBtnClick?: () => void;
}

export const ScoreBarRow: React.StatelessComponent<ScoreBarRowProps> = ({
  label,
  circleValue,
  circleColor,
  circleClassName,
  circleLabel,
  circleMeta,
  rowColor,
  diamondValue,
  nonClickable,
  onClick,
  iconClass,
  className,
  rowWidthClass,
  showArrow,
  showAxes,
  showTooltip,
  handleTooltipSeen,
  showExpandIcon,
  otherBenchmarks,
  pins,
  hideStatementIcons,
  secondaryStatementButton,
  allPoints,
  selected,
  themed,
  themedActive,
  statementInsightText,
  showScatterPlotButton,
  showGetInsightsButton,
  onInsightButtonClick,
  onScatterPlotBtnClick,
  highlightScatterPlotButton,
  isStatementScatterPlotActive,
  scatterPlotPlacement,
  scatterPlotClassName,
  enableAdjustOverFlow,
}) => {
  // For accessibility.
  const onKeyDown = (event) => {
    if (onClick && event.keyCode === ENTER_KEY_CODE) {
      onClick(event);
    }
  };

  const labels = {
    top: 'Relatively more important to me',
    bottom: 'Relatively less important to me',
    left: "Our team doesn't do well",
    right: 'Our team does well',
  };

  const emptyLabels = {
    top: '',
    bottom: '',
    left: '',
    right: '',
  };

  const popover = (
    <Popover
      placement="topRight"
      content={
        <div className="expand-icon-tool-tip-container">
          <div className="content">
            <InfoCircleFilled className="icon" />
            <div className="text">
              <strong>Tip:</strong> Click the individual statements to learn more
            </div>
          </div>
          <div className="button-container">
            <Button
              type="primary"
              className="button-styling"
              onClick={stopPropagation(handleTooltipSeen)}
            >
              Got it
            </Button>
          </div>
        </div>
      }
    >
      <ZoomInOutlined />
    </Popover>
  );

  const isGreenStatement = circleColor === GREEN;

  const viewTipsBtn = !isGreenStatement && (
    <Button className="btn btn-outline view-tips-btn" onClick={onInsightButtonClick}>
      View Tips
    </Button>
  );

  const expandIcon = showTooltip ? popover : viewTipsBtn;

  const defaultIcon = iconClass ? <i className={`fal ${iconClass}`} /> : null;

  // The scatter plot
  const statementScatterPlot = (
    <div className="scatter-plot-container-popover">
      <ScatterPlot
        labels={showAxes ? labels : emptyLabels}
        points={allPoints}
        footer={
          statementInsightText && (
            <div className="insight-heading">
              <div className="d-flex">
                <img src={'/images/coach-img.png'} />
                <p>{statementInsightText}</p>
              </div>
              <Button shape="circle" className="btn-right">
                <RightOutlined />
              </Button>
            </div>
          )
        }
      />
    </div>
  );

  const chartBtn = (
    <Button
      type="primary"
      shape="circle"
      className={cx({
        'statement-with-insight': statementInsightText,
        active: isStatementScatterPlotActive,
      })}
      onClick={onScatterPlotBtnClick}
    >
      <DotChartOutlined
        className={cx({
          'statement-with-insight': statementInsightText,
          active: isStatementScatterPlotActive,
        })}
      />
    </Button>
  );

  const scatterPlotBtn = showScatterPlotButton && allPoints && (
    <div className="chart-button">
      {!onScatterPlotBtnClick ? (
        <MouseAwarePopOver
          content={statementScatterPlot}
          title={label}
          trigger="click"
          placement={scatterPlotPlacement ?? 'top'}
          overlayClassName={scatterPlotClassName ?? 'ant-popover'}
          autoAdjustOverflow={enableAdjustOverFlow ?? true}
        >
          <Tooltip placement="topLeft" title="View scatterplot">
            {chartBtn}
          </Tooltip>
        </MouseAwarePopOver>
      ) : (
        chartBtn
      )}
    </div>
  );

  const showActions = !hideStatementIcons || scatterPlotBtn || showGetInsightsButton;

  const row = (
    <div
      className={cx('row align-items-center score-bar-row', {
        rowColor,
        themed,
        'selected-active': selected,
        'themed-active': themedActive,
        'highlighted-chart-icon': highlightScatterPlotButton,
        'cursor-pointer': !nonClickable,
      })}
      tabIndex={0}
    >
      <span className="col-md-6">{label}</span>
      <div className={'col-md-6 d-flex align-items-center no-gutters'}>
        <ScoreBar
          className={cx(rowWidthClass || 'w-100', { tipless: !showExpandIcon })}
          circleColor={circleColor}
          circleValue={circleValue}
          circleClassName={circleClassName}
          circleLabel={circleLabel}
          circleTooltip={circleMeta?.tooltip}
          diamondValue={diamondValue}
          pins={pins}
          showArrow={showArrow}
          otherBenchmarks={otherBenchmarks}
        />

        {/* Action buttons */}
        {showActions && (
          <div className="action">
            {!hideStatementIcons && (
              <div className="icons-container">
                {secondaryStatementButton && (
                  <div className="secondary-statement-button">{secondaryStatementButton}</div>
                )}
                <div className="expand-icon-container">
                  {showExpandIcon ? expandIcon : defaultIcon}
                </div>
              </div>
            )}

            {/* Show scatterplot trigger button */}
            {scatterPlotBtn}

            {/* Show UCC trigger buton */}
            {showGetInsightsButton && (
              <Tooltip placement="topLeft" title="Get insights">
                <Button type="primary" shape="circle" onClick={onInsightButtonClick}>
                  <RightOutlined />
                </Button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={cx('score-bar-row-container', className)}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {row}
    </div>
  );
};

export default ScoreBarRow;
