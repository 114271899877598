import { action, computed, observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { DiscussionSpaceAgendaStore } from 'app/stores';

import DiscussionSpaceNoteModel from './DiscussionSpaceNoteModel';

export enum DiscussionSpaceAgendaType {
  Static = 'static',
  Generic = 'generic',
  StatementsSelect = 'statements_select',
  StatementsReflect = 'statements_reflect',
}

export enum DiscussionSpaceAgendaTag {
  Intro = 'intro',
  Share = 'share',
  Prioritize = 'prioritize',
  Explore = 'explore',
  FinalThoughts = 'final_thoughts',
}

export class DiscussionSpaceAgendaModel extends Model {
  public static _store: DiscussionSpaceAgendaStore;

  @observable public id: number;
  @observable public tag: string;
  @observable public discussion_space_id: number;
  @observable public title: string;
  @observable public content: string;
  @observable public type: DiscussionSpaceAgendaType;
  @observable public timer_duration?: number;
  @observable public order: number;
  @observable public sort_by:
    | Record<string, string>
    | Record<number, { notes: string; actions: string }>;
  @observable public notes?: DiscussionSpaceNoteModel[];

  @observable timeElapsed = 0;
  @action setTimeElapsed = (timeElapsed: number) => (this.timeElapsed = timeElapsed);

  public uuid: string;

  deserialize_notes(notes: any[]) {
    this.notes = notes.map((note) => {
      return DiscussionSpaceNoteModel.fromJson(note);
    });
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as DiscussionSpaceAgendaModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as DiscussionSpaceAgendaModel;
  }

  static get(id) {
    return this._get(id) as DiscussionSpaceAgendaModel;
  }

  @action
  static appendNote(note: DiscussionSpaceNoteModel): void {
    const agenda = DiscussionSpaceAgendaModel.getOrNew(note.agenda_id);
    DiscussionSpaceAgendaModel.filterGroupNotes(note);
    agenda.notes.push(note);
    agenda.sortNotes();
  }

  @action
  static removeNote(note: DiscussionSpaceNoteModel): void {
    const agenda = DiscussionSpaceAgendaModel.getOrNew(note.agenda_id);
    agenda.deleteNote(note);
  }

  @action
  static updateNote(note: DiscussionSpaceNoteModel) {
    // If note is a group, delete its notes from main list
    if (note.isGroup) {
      this.filterGroupNotes(note);
      return;
    }

    // Delete from main list and any groups
    const agenda = DiscussionSpaceAgendaModel.get(note.agenda_id);
    agenda.deleteNote(note);

    // If it has a group, push to that group
    if (note.group_id) {
      const group = DiscussionSpaceNoteModel.get(note.group_id);
      group.notes.push(note);
      group.sortNotes();
      return;
    }

    // If no group, push to main list
    agenda.notes.push(note);
    agenda.sortNotes();
  }

  @action
  static filterGroupNotes(note: DiscussionSpaceNoteModel) {
    if (!note.isGroup) {
      return;
    }

    // If note is a group, remove lingering notes that belongs to
    // the group because they should be rendered within the group now
    const agenda = DiscussionSpaceAgendaModel.getOrNew(note.agenda_id);
    const groupNoteIds = note.notes.map((groupNote) => groupNote.id);
    agenda.notes = agenda.notes.filter(({ id }) => !groupNoteIds.includes(id));
  }

  @computed
  get isGeneric(): boolean {
    return this.type === DiscussionSpaceAgendaType.Generic;
  }

  @computed
  get isSelect(): boolean {
    return this.type === DiscussionSpaceAgendaType.StatementsSelect;
  }

  @computed
  get isReflect(): boolean {
    return this.type === DiscussionSpaceAgendaType.StatementsReflect;
  }

  @computed
  get isFinalThoughts(): boolean {
    return (
      this.type === DiscussionSpaceAgendaType.Generic &&
      this.tag === DiscussionSpaceAgendaTag.FinalThoughts
    );
  }

  @computed
  get flatNotes() {
    return this.notes.reduce((acc, note) => {
      const notes = note.isNote ? [note] : note.notes;
      acc = [...acc, ...notes];
      return acc;
    }, []);
  }

  @computed
  get notesIndex() {
    return this.notes.reduce((acc, note, i) => {
      acc[note.id] = i;
      return acc;
    }, {});
  }

  @action
  sortNotes() {
    this.notes = this.notes.sort((a, b) => {
      return a.order - b.order;
    });
  }

  @action
  reorderNotes(orderMap) {
    this.notes = this.notes.map((note) => {
      if (orderMap[note.id] !== undefined) {
        note.order = orderMap[note.id];
      }

      if (note.isGroup) {
        note.notes = note.notes.map((groupNote) => {
          if (orderMap[groupNote.id] !== undefined) {
            groupNote.order = orderMap[groupNote.id];
          }
          return groupNote;
        });

        note.sortNotes();
      }

      return note;
    });

    this.sortNotes();
  }

  @action
  deleteNote(note: DiscussionSpaceNoteModel) {
    // First delete from main list
    const index = this.notes.findIndex(({ id }) => id === note.id);
    if (index !== -1) {
      this.notes.splice(index, 1);
    }

    // Then from any groups
    this.notes.forEach((agendaNote) => {
      if (agendaNote.isGroup) {
        agendaNote.deleteNote(note);
      }
    });
  }
}

export default DiscussionSpaceAgendaModel;
