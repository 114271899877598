import React, { ReactNode } from 'react';

import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface MouseAwarePopOverState {
  placement: TooltipPlacement;
}

class MouseAwarePopOver extends React.Component<PopoverProps, MouseAwarePopOverState> {
  wrapperRef: React.RefObject<any>;

  constructor(props: PopoverProps) {
    super(props);

    this.wrapperRef = React.createRef();
    this.state = { placement: 'top' };

    window.addEventListener('scroll', this.updatePlacement);
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.updatePlacement);
  }

  updatePlacement = (): void => {
    const topOffset = this.wrapperRef.current.getBoundingClientRect().top;
    const placement = topOffset > 350 ? 'top' : 'bottom';

    this.setState({ placement });
  };

  render(): ReactNode {
    const { placement } = this.state;

    return (
      <div ref={this.wrapperRef} onMouseMove={this.updatePlacement}>
        <Popover placement={placement} {...this.props} />
      </div>
    );
  }
}

export default MouseAwarePopOver;
