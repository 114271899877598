export const ExerciseStatusHumanKeys = {
  invite_align: 'Invited',
  remind_align: 'Reminder sent',
  completed_align: 'Completed',
  invite_not_sent: 'Invite not sent',
};

export const ExerciseStatusKeys = {
  invite_align: 'invite_align',
  remind_align: 'remind_align',
  completed_align: 'completed_align',
};
