export enum ManageAlignTab {
  EXERCISE = 'exercise',
  INSIGHTS = 'insights',
  DISCUSSION = 'discussion',
  HABITS = 'habits',
  ADMIN = 'shift_admin',
}

export enum ManageAlignTabStatus {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  LOADING = 'Loading',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export const ManageAlignTabLabels = {
  [ManageAlignTab.EXERCISE]: 'Exercise',
  [ManageAlignTab.INSIGHTS]: 'Insights Report',
  [ManageAlignTab.DISCUSSION]: 'Discussion',
  [ManageAlignTab.HABITS]: 'Team Habits',
  [ManageAlignTab.ADMIN]: 'Valence Admin',
};
