import DiscussionGuideTypeModel from './DiscussionGuideTypeModel';
import ShiftModel from './ShiftModel';

export interface DiscussionGuideStepModelProps {
  id: string;
  name: string;
  description: string;
  duration: { [type: string]: number };
}

export class DiscussionGuideStepModel extends ShiftModel<DiscussionGuideStepModelProps> {
  public id: string;
  public title: string;
  public description: string;
  public duration: { [type: string]: number };

  public getDuration(type?: DiscussionGuideTypeModel) {
    return type ? this.duration[type.id] : 0;
  }
}

export default DiscussionGuideStepModel;
