import * as React from 'react';

import { partial } from 'lodash';
import numeral from 'numeral';

import { ExerciseTypeModel } from 'app/models';

export interface ExerciseTypeSelectProps {
  availableExerciseTypes: Array<ExerciseTypeModel>;
  onAdd: React.MouseEventHandler;
  onSave: React.MouseEventHandler;
  isSaving: boolean;
}

export const ExerciseTypeSelect: React.StatelessComponent<ExerciseTypeSelectProps> = ({
  availableExerciseTypes,
  onAdd,
  onSave,
  isSaving,
}) => {
  const newExerciseTypeRef = React.useRef(null);
  const handleAdd = (event) => {
    event.preventDefault();
    const exerciseTypeId = numeral(newExerciseTypeRef.current.value).value();
    // @ts-ignore
    partial(onAdd, exerciseTypeId)();
  };

  return (
    <div className="form-group">
      <select ref={newExerciseTypeRef} className="mr-2">
        {availableExerciseTypes.map((exerciseType, i) => (
          <option key={`exercise-type-${i}`} value={exerciseType.id}>
            {exerciseType.name}
          </option>
        ))}
      </select>
      <button className="btn btn-sm btn-secondary" onClick={handleAdd}>
        Add
      </button>

      <div className="text-right">
        <button className="btn btn-primary text-uppercase" onClick={onSave} disabled={isSaving}>
          {isSaving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default ExerciseTypeSelect;
