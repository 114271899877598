import { action, observable } from 'mobx';
import URI from 'urijs';

import ApiRoutes from 'app/helpers/serverRoutes/ApiRoutes';
import { ApiService } from 'app/services/ApiService';

export class LoadNavigation {
  private static instance = new LoadNavigation();
  @observable previousLocation: string;
  @action setPreviousLocation = (location: string) => {
    this.previousLocation = location;
  };

  public static getInstance(): LoadNavigation {
    return this.instance;
  }

  // helper method for tracking navigation by sending navigation url's to back end
  logNavigationUrl = async () => {
    if (window.location.href === this.previousLocation) {
      return;
    }

    this.setPreviousLocation(window.location.href);
    const token = URI.parseQuery(window.location.search).token;
    const url = ApiRoutes.tracking.navigation(token);
    const apiService = ApiService.getInstance();

    apiService.post(url, {
      url: window.location.href,
    });
  };
}
