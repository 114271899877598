import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { MetabaseStore } from 'app/stores/MetabaseStore';

export interface MetabaseProps {
  id: string;
  dashboard_url: string;
}

export class MetabaseModel extends Model {
  static _store: MetabaseStore;

  public readonly id: string;
  @observable public dashboard_url: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as MetabaseModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as MetabaseModel;
  }

  static get(id) {
    return this._get(id) as MetabaseModel;
  }
}

export default MetabaseModel;
