import React, { useState } from 'react';

import { LeftCircleOutlined, RightCircleOutlined, RightOutlined } from '@ant-design/icons';

import './CardCarouselView.scss';

import {
  ExerciseStatementCard,
  ExerciseStatementCardProps,
} from 'app/components/features/ExerciseStatementCard/ExerciseStatementCard';

import { Submit360Button } from './../CardListActions/Submit360Button';
import { SubmitButton } from './../CardListActions/SubmitButton';
import { TestDriveButton } from './../CardListActions/TestDriveButton';

export interface CardCarouselViewProps {
  statements: ExerciseStatementCardProps[];
  onSeeAllStatements: () => void;
  onSubmit?: () => void;
  renderTestDriveView?: boolean;
  render360View?: boolean;
}

export const CardCarouselView: React.StatelessComponent<CardCarouselViewProps> = ({
  statements,
  onSeeAllStatements,
  onSubmit,
  renderTestDriveView,
  render360View,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const unplacedStatements = statements.filter((statement) => !statement.placed);

  const maxIndex = unplacedStatements.length - 1;
  const safeIndex = maxIndex > selectedIndex ? selectedIndex : maxIndex;

  const renderProgress = () => {
    if (unplacedStatements.length === 0) {
      return null;
    }

    return <p className="card-carousel-view-progress">{unplacedStatements.length} cards left</p>;
  };

  const renderSlide = (card) => (
    <div key={card.id} className="slide-content">
      <ExerciseStatementCard
        id={card.id}
        title={card.title}
        explanation={card.explanation}
        placed={card.placed}
      />
      {card.explanation && <p className="explanation">{card.explanation}</p>}
    </div>
  );

  const submitButton = () => {
    const props = {
      onClick: onSubmit,
      hideForDesktop: true,
    };

    if (render360View) {
      return <Submit360Button {...props} />;
    }
    if (renderTestDriveView) {
      return <TestDriveButton {...props} />;
    }
    return <SubmitButton {...props} />;
  };

  const renderCarousel = () => {
    if (unplacedStatements.length === 0) {
      return (
        <div className="placeholder">
          <span>Good job!</span>
          <span className="text-muted">
            <small>All statements placed</small>
          </span>
          {submitButton()}
        </div>
      );
    }

    return renderSlide(unplacedStatements[safeIndex]);
  };

  const nextSlide = () => {
    if (safeIndex === maxIndex) {
      setSelectedIndex(0);
      return;
    }

    setSelectedIndex(safeIndex + 1);
  };

  const prevSlide = () => {
    if (safeIndex === 0) {
      setSelectedIndex(maxIndex);
      return;
    }

    setSelectedIndex(safeIndex - 1);
  };

  return (
    <div className="card-carousel-view">
      {renderProgress()}

      <div className="main-area">
        {unplacedStatements.length > 1 && (
          <LeftCircleOutlined className="slide-btn left" onClick={prevSlide} />
        )}

        <div className="exercise-carousel">{renderCarousel()}</div>

        {unplacedStatements.length > 1 && (
          <RightCircleOutlined className="slide-btn right" onClick={nextSlide} />
        )}
      </div>

      {renderProgress()}

      <button onClick={onSeeAllStatements} className="btn btn-link all-statements-btn">
        See all statements <RightOutlined />
      </button>
    </div>
  );
};

export default CardCarouselView;
