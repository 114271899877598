import React from 'react';

import './ScatterPlotsPerPage.scss';

import { ExerciseReportModel } from 'app/models';

import SummaryPrintView from '../SummaryPrintView';

export interface ScatterPlotsPerPageProps {
  report: ExerciseReportModel;
}

export const ScatterPlotsPerPage: React.StatelessComponent<ScatterPlotsPerPageProps> = ({
  report,
}) => (
  <SummaryPrintView
    className="scatter-plots-per-page"
    report={report}
    scatterPlotsPerPage={1}
    scatterPlotLabelPadding={48}
    scatterPlotSize={800}
  />
);

export default ScatterPlotsPerPage;
