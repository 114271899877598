/**
 * Component for getting data from the window.accompanyData store
 * Note: Eventually, this will be replaced with APIClientData
 */
export default class ClientDataHelper {
  /**
   * Gets an object from the accompanyData store
   * @param key - The key of the object you're trying to retrieve
   * @param fallback - The default value if that key is not defined
   *
   * Note: Even though this method doesn't need to be async, it is to
   * force us to write Async compatible components
   */
  static async get(key: string, fallback?: any) {
    if (window['accompanyData']) {
      return accompanyData[key] !== undefined ? accompanyData[key] : fallback;
    }
  }

  /**
   * For when we dont want to use async/await.
   *
   * @param {string} key
   * @param {any}    fallback
   */
  static getSync(key: string, fallback?: any) {
    if (window['accompanyData']) {
      return accompanyData[key] !== undefined ? accompanyData[key] : fallback;
    }
  }

  /**
   * Get current organization from accompanyData using the two possible data structures
   */
  static async getOrganization() {
    if (!window['accompanyData']) {
      return null;
    }

    // Find the full organization instance based on the "displayedOrg" for the current member.
    const displayedOrg = accompanyData.mainNav?.displayedOrg;
    const foundOrg = accompanyData.mainNav?.member?.organizations.find(
      (org: any) => org.id === displayedOrg?.id
    );
    const fallback = foundOrg ?? displayedOrg;

    return accompanyData['organization'] ?? fallback;
  }
}
