import React from 'react';

import { DndProvider } from 'react-dnd';
import MultiBackend, { Preview } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';

import { ExerciseStatementCardSimple } from 'app/components/features/ExerciseStatementCard';

const CustomPreview = ({ item, style }) => (
  <ExerciseStatementCardSimple title={item.title} style={style} />
);

export const CustomDndProvider: React.FC = ({ children }) => (
  <DndProvider backend={MultiBackend} options={HTML5toTouch}>
    {children}
    <Preview>{CustomPreview}</Preview>
  </DndProvider>
);

export default CustomDndProvider;
