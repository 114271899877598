import React from 'react';

import { UnregisterCallback } from 'history';
import { Provider } from 'mobx-react';
import { Router, withRouter } from 'react-router-dom';

import PageNotFound from 'app/components/ui/PageNotFound';
import { createStores } from 'app/stores';

import LazyLoadErrorBoundary from './components/features/LazyLoadErrorBoundary';
import Route, { history } from './customRoute';
import { LoadNavigation } from './helpers/LogNavigation';
import printRoutes from './pages/routesPrint';
import webRoutes from './pages/routesWeb';

const isProd = !!window?.['accompany']?.isProduction;

const LazyWrapper = () => {
  let unregisterLog: UnregisterCallback = null;
  let unregisterCapture: UnregisterCallback = null;

  const RouteListener = withRouter(({ history, children }) => {
    const loadNavigation = LoadNavigation.getInstance();
    loadNavigation.logNavigationUrl();

    if (unregisterLog === null) {
      unregisterLog = history.listen(loadNavigation.logNavigationUrl);
    }

    // Prevent errors when leaving the app
    if (window?.posthog) {
      const capture = () => window.posthog.capture('$pageview');

      if (unregisterCapture === null) {
        unregisterCapture = history.listen(capture);
      }
    }

    return <>{children}</>;
  });

  const rootRouter = (
    <Router history={history}>
      <RouteListener>
        <Route
          render={({ location }) =>
            location.pathname.indexOf('/print') == 0 ? printRoutes : webRoutes
          }
        />
        <Route path="/errors/not_found" component={PageNotFound} />
      </RouteListener>
    </Router>
  );

  // Disable error boundary when not on prod,
  // it interferes with development and its very annoying!
  if (!isProd) {
    return rootRouter;
  }

  return <LazyLoadErrorBoundary>{rootRouter}</LazyLoadErrorBoundary>;
};

export default (
  <Provider {...createStores()}>
    <LazyWrapper />
  </Provider>
);
