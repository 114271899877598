import HtmlArraySegmentModel from 'app/models/HtmlArraySegmentModel';

import PlainModel from './PlainModel';

export class AlignGuidanceModel extends PlainModel {
  statementID: number;
  segments = [];

  constructor(props) {
    super();
    this.statementID = props.statementID;
    this.segments = props.segments;

    if (props.html) {
      this.deserialize_html(props.html);
    }
  }

  deserialize_html(html: any[]): void {
    this.segments = html.map((item) => HtmlArraySegmentModel.fromJson(item));
  }
}

export default AlignGuidanceModel;
