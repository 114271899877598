import { computed, observable } from 'mobx';

import TeamGroupDivisionStore from 'app/stores/TeamGroupDivisionStore';

import Model, { ModelJson } from './Model';
import TeamGroupModel from './TeamGroupModel';

export interface TeamGroupDivisionDependency {
  division_id: number;
  team_group_ids: number[];
}

export interface TeamGroupDivisionOptions {
  label?: string;
  depends_on?: TeamGroupDivisionDependency[][];
  always_shown?: number[];
  none_label?: string;
  hidden?: boolean;
  required?: boolean;
}

export class TeamGroupDivisionModel extends Model {
  static _store: TeamGroupDivisionStore;

  readonly id: number;
  @observable name: string;
  @observable organization_id: number;
  @observable options?: TeamGroupDivisionOptions;
  @observable order?: number;

  // Outer array is for "or" conditions,
  // the inner one is for "and" conditions.
  @observable groups?: TeamGroupModel[];

  deserialize_groups(groups) {
    this.groups = groups.map((group) => TeamGroupModel.fromJson(group));
  }

  @computed
  get displayName(): string {
    return this.options?.label ?? this.name;
  }

  @computed
  get parentTeamGroups(): TeamGroupModel[] {
    return this.groups.filter(({ dependent_group_id }) => !dependent_group_id);
  }

  @computed
  get childTeamGroups(): TeamGroupModel[] {
    return this.groups.filter(({ dependent_group_id }) => !!dependent_group_id);
  }

  @computed
  get dependencies(): TeamGroupDivisionDependency[][] {
    return this.options?.depends_on ?? [];
  }

  get noneLabel(): string {
    return this.options?.none_label;
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamGroupDivisionModel;
  }
}

export default TeamGroupDivisionModel;
