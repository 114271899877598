import React from 'react';

import cx from 'classnames';
import { Button, ModalFooter } from 'reactstrap';

import { ModalAction } from '../Modal';

const ActionButton: React.FC<ModalAction> = ({ label, ...rest }) => {
  return <Button {...rest}>{label}</Button>;
};

export interface FooterProps {
  content?: any;
  primaryAction?: ModalAction;
  secondaryAction?: ModalAction;
  footerClassName?: string;
}

const defaultProps = [
  { className: 'secondary-action', color: 'link' },
  { className: 'primary-action', color: 'primary' },
];

export const Footer: React.FC<FooterProps> = ({
  footerClassName,
  content,
  secondaryAction,
  primaryAction,
}) => {
  [secondaryAction, primaryAction].forEach((action, i) => {
    if (action) {
      action.className = cx(action.className, defaultProps[i].className);
      action.color = action.color || defaultProps[i].color;
    }
  });

  return (
    <ModalFooter className={footerClassName}>
      {!!content && <div className="modal-footer-content">{content}</div>}
      <div className="modal-actions">
        {secondaryAction && <ActionButton {...secondaryAction} />}
        {primaryAction && <ActionButton {...primaryAction} />}
      </div>
    </ModalFooter>
  );
};

export default Footer;
