import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { DiscussionPromptStore } from 'app/stores';

import DiscussionPromptAnswerModel from './DiscussionPromptAnswerModel';
import { ModelList } from './ModelList';

export class DiscussionPromptModel extends Model {
  public static _store: DiscussionPromptStore;

  @observable public id: number;
  @observable public align_statement_id: number;
  @observable public order: number;
  @observable public red_prompt: string;
  @observable public yellow_prompt: string;
  @observable public green_prompt: string;
  @observable public answers = new ModelList<DiscussionPromptAnswerModel>(
    DiscussionPromptAnswerModel
  );

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as DiscussionPromptModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as DiscussionPromptModel;
  }

  static get(id) {
    return this._get(id) as DiscussionPromptModel;
  }

  static getPrompts() {
    return this._store.prompts.items;
  }
}

export default DiscussionPromptModel;
