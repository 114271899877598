import { notification } from 'antd';
import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ExerciseTypeModel, ModelItem, ModelList, My360Model, PagingMetaModel } from 'app/models';
import { My360Participant } from 'app/models/My360Model';
import My360sStatsModel from 'app/models/My360sStatsModel';
import ThirdPartyService from 'app/services/ThirdPartyService';
import AlignStore from 'app/stores/AlignStore';

export class My360Store extends AlignStore {
  @observable my360s: My360Model[];
  @action setMy360s = (my360s: My360Model[]): void => {
    this.my360s = my360s;
  };

  @observable my360sStats: My360sStatsModel;
  @action setMy360sStats = (my360sStats: My360sStatsModel): void => {
    this.my360sStats = my360sStats;
  };

  @observable isLoadingMy360s: boolean;
  @action private setIsLoadingMy360s = (status) => (this.isLoadingMy360s = status);

  @observable isReopeningExercise = false;
  @action setIsReopeningExercise = (status: boolean): void => {
    this.isReopeningExercise = status;
  };

  @observable isCreatingMy360 = false;
  @action setIsCreatingMy360 = (status: boolean): void => {
    this.isCreatingMy360 = status;
  };

  /*TODO: This should be on its own store/model:
      My360HighlightModel
      My360HighlightStore
  */
  @observable my360Highlights = [];
  @action setMy360Highlights = (highlights): void => {
    this.my360Highlights = highlights;
  };

  @observable isLoadingMy360Highlights: boolean;
  @action private setIsLoadingMy360Highlights = (status) =>
    (this.isLoadingMy360Highlights = status);

  @observable public currentMy360 = new ModelItem<My360Model>(My360Model);

  @observable my360Templates = new ModelList<ExerciseTypeModel>(ExerciseTypeModel);

  @observable colleague360s = new ModelList<My360Model>(My360Model);
  @observable colleague360sPageMeta: PagingMetaModel;

  @observable isLoadingManager360ReportFilters = false;
  @action setIsLoadingManager360ReportFilters = (status: boolean): void => {
    this.isLoadingManager360ReportFilters = status;
  };

  constructor() {
    super();
    My360Model._store = this;
  }

  public async loadTemplates(memberId: number = null): Promise<void> {
    const url = ServerRouteHelper.api.my360s.templates(memberId);
    await this.my360Templates.load(url, undefined, {
      onResponse: (templates) => this.my360Templates.deserialize(templates),
    });
  }

  // Proxy to my360Templates so maintain backwards compatibility
  get templates(): ExerciseTypeModel[] {
    return this.my360Templates.items;
  }

  public async loadMy360(exerciseId: number, forceRefresh = false) {
    const url = ServerRouteHelper.api.my360s.show(exerciseId);
    return this.currentMy360.load(url, null, { forceRefresh });
  }

  public async createNewMy360(
    typeId: number,
    memberId: number = null
  ): Promise<ModelItem<My360Model>> {
    this.setIsCreatingMy360(true);
    const url = ServerRouteHelper.api.my360s.new(typeId, memberId);

    const config = {
      url,
      data: {},
      throwError: true,
      showGenericError: true,
    };

    try {
      const result = await this.apiService.newPost(config);
      this.currentMy360.setItem(My360Model.fromJson(result));
      return this.currentMy360;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
      notification.error({
        message: 'Error',
        description: 'Something went wrong while creating a new 360.',
        placement: 'bottomRight',
        duration: 4,
      });
    } finally {
      this.setIsCreatingMy360(false);
    }
  }

  createManager360(exerciseTypeId: number, teamId: number, memberIds: number[]): Promise<void> {
    const url = ServerRouteHelper.api.manager360.createExercises();

    const config = {
      url,
      data: {
        exercise_type_id: exerciseTypeId,
        team_id: teamId,
        member_ids: memberIds,
      },
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  public async getMy360s(memberId?: number, withStats = false) {
    this.setIsLoadingMy360s(true);
    const url = ServerRouteHelper.api.my360s.memberMy360s(memberId, { withStats });
    try {
      const response = await this.apiService.get(url);
      if (response?.my360s) {
        this.setMy360s(response.my360s.map((my360) => My360Model.fromJson(my360)));
      }

      if (response?.stats) {
        this.setMy360sStats(My360sStatsModel.fromJson(response.stats));
      }
    } catch (e) {
      throw e;
    } finally {
      this.setIsLoadingMy360s(false);
    }
  }

  public async getMy360Highlights(exerciseTypeId: number, statementIds) {
    this.setIsLoadingMy360Highlights(true);
    const url = ServerRouteHelper.api.my360s.highlights(exerciseTypeId);
    try {
      const response = await this.apiService.post(url, statementIds);
      this.setMy360Highlights(response);
    } catch (err) {
      if (err.status !== 404) {
        ThirdPartyService.sentry.captureException(err);
      }
    } finally {
      this.setIsLoadingMy360Highlights(false);
    }
  }

  public async fetchPersonalInviteLinks(
    exerciseId: number,
    members: Partial<My360Participant>[]
  ): Promise<Record<string, string>> {
    const url = ServerRouteHelper.api.my360s.personalInviteLinks(exerciseId);
    const config = {
      url,
      data: { members },
      throwError: true,
    };

    try {
      const response = await this.apiService.newPost(config);

      if (!response) {
        return {};
      }

      return response.data;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
      notification.error({
        message: 'Error',
        description: 'Something went wrong while generating personal invite links.',
      });
    }
  }

  public async inviteMembers(exerciseId, selected_members, message) {
    const url = ServerRouteHelper.api.my360s.inviteMembers(exerciseId);

    const config = {
      url,
      data: { selected_members, message },
      throwError: true,
    };

    await this.apiService.newPost(config);
  }

  public async remindMembers(exerciseId, selected_members, message) {
    await this.apiService.post(ServerRouteHelper.api.my360s.remindMembers(exerciseId), {
      selected_members,
      message,
    });
  }

  public async deleteMy360(exerciseId) {
    const exercise = My360Model.getOrNew(exerciseId);
    if (!exercise) {
      return;
    }

    exercise.setDeleting(true);
    try {
      await this.apiService.delete(ServerRouteHelper.api.exercises.delete(exerciseId));
      exercise.delete();
    } finally {
      exercise.setDeleting(false);
    }
  }

  public async closeMy360(exerciseId: number) {
    const url = ServerRouteHelper.api.exercises.close(exerciseId);
    const exercise = My360Model.getOrNew(exerciseId);

    try {
      const result = await this.apiService.post(url);
      exercise.updateFromJson(result.data);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    }

    return exercise;
  }

  public async reopenMy360(exerciseId: number) {
    this.setIsReopeningExercise(true);
    const exercise = My360Model.getOrNew(exerciseId);

    try {
      await this.apiService.newGet({
        url: ServerRouteHelper.api.admin.exercises.reOpenExercise(exerciseId),
        throwError: true,
      });
      exercise.updateFromJson({ is_closed: false, closed_at: null, closed_by: null });

      notification.success({
        message: 'Exercise has been reopened',
        placement: 'bottomRight',
      });
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);

      notification.error({
        message: 'Failed to reopen exercise',
        description: 'Something went wrong while trying to reopen this exercise.',
        placement: 'bottomRight',
        duration: 8,
      });
    } finally {
      this.setIsReopeningExercise(false);
    }
  }

  public saveInsights(exerciseId: number, content: string) {
    const params = { shift_insights: content };
    const url = ServerRouteHelper.api.admin.exercises.shiftInsights(exerciseId);
    const config = {
      url,
      data: params,
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  async getColleague360s(page?: number): Promise<void> {
    const params = page ? { page } : undefined;
    const url = ServerRouteHelper.api.my360s.colleagues(params);

    this.colleague360s.load(url, undefined, {
      onResponse: (response) => {
        this.colleague360sPageMeta = new PagingMetaModel(response.meta);
      },
    });
  }

  async getManager360ReportFilters(token: string): Promise<any> {
    try {
      this.setIsLoadingManager360ReportFilters(true);

      const config = {
        url: ServerRouteHelper.api.my360s.manager360ReportFilters(token),
        showGenericError: true,
      };

      const resp = await this.apiService.newGet(config);

      return resp.data;
    } finally {
      this.setIsLoadingManager360ReportFilters(false);
    }
  }
}

export default My360Store;
