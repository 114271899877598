import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { TeamGroupReportStore } from 'app/stores';

import ExerciseTypeModel from './ExerciseTypeModel';
import { ModelItem } from './ModelItem';

export interface BenchmarkScore {
  align_statement_template_id: number;
  score: number;
  x: number;
  y: number;
}

export interface GroupScore {
  align_statement_template_id: number;
  score: number;
  x: number;
  y: number;
}

export class TeamGroupReportModel extends Model {
  public static _store: TeamGroupReportStore;

  @observable public benchmark_scores: BenchmarkScore[];
  @observable public group_scores: { [index: number]: GroupScore };
  @observable exercise_type = new ModelItem<ExerciseTypeModel>(ExerciseTypeModel);

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamGroupReportModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeamGroupReportModel;
  }

  static get(id) {
    return this._get(id) as TeamGroupReportModel;
  }
}

export default TeamGroupReportModel;
