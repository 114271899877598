import React from 'react';

import { AllExerciseTypeList } from 'app/components/features/OrgAdminControls';
import { ExerciseTypeModel, OrganizationModel } from 'app/models';

export interface ExerciseTypesEditProps {
  org: OrganizationModel;
  inheritedExerciseTypes: ExerciseTypeModel[];
  allExerciseTypes: ExerciseTypeModel[];
  selectedExerciseTypes: { [id: number]: boolean };
  isSavingExerciseTypes: boolean;
  onSelectedExerciseType: (exerciseType: ExerciseTypeModel) => void;
  onSaveSelectedExerciseTypes: () => void;
}

export const ExerciseTypesEdit: React.StatelessComponent<ExerciseTypesEditProps> = ({
  org,
  inheritedExerciseTypes,
  allExerciseTypes,
  selectedExerciseTypes,
  isSavingExerciseTypes,
  onSelectedExerciseType,
  onSaveSelectedExerciseTypes,
}) => (
  <div className="acc-card admin-controls">
    <h2>Enabled exercise types</h2>
    {org && inheritedExerciseTypes.length > 0 && (
      <div>
        <h4>Inherited from {org.name}</h4>
        <div className="mb-3">
          {inheritedExerciseTypes.map((exerciseType, i) => (
            <div key={`exercise-type-${i}`}>
              {exerciseType.name}
              {exerciseType.type === '360' && <span className="badge badge-primary ml-1">360</span>}
            </div>
          ))}
        </div>
      </div>
    )}

    <AllExerciseTypeList
      allExerciseTypes={allExerciseTypes}
      selectedExerciseTypes={selectedExerciseTypes}
      onSave={onSaveSelectedExerciseTypes}
      onSelected={onSelectedExerciseType}
      isSaving={isSavingExerciseTypes}
    />
  </div>
);

export default ExerciseTypesEdit;
