import React from 'react';

import cx from 'classnames';

import './TextField.scss';

import { InputWrapper, InputWrapperProps } from 'app/components/ui/InputWrapper/InputWrapper';

export interface TextFieldProps {
  inputId?: string;
  placeHolder?: string;
  value: any;
  type?: string;
  name?: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  dataTestId?: string;
  onValueChange: (value: any) => void;
}

export const TextField: React.StatelessComponent<TextFieldProps & InputWrapperProps> = ({
  inputId,
  placeHolder,
  value,
  type,
  name,
  required,
  autoFocus,
  disabled,
  readOnly,
  onValueChange,
  dataTestId,
  className,
  ...restOfProps
}) => (
  <InputWrapper {...restOfProps} className={cx(className, `${name}-input`)} value={value}>
    {(onBlur, onFocus) => (
      <input
        id={inputId}
        className={cx('form-control ', 'form-control--tall', 'text-input')}
        disabled={disabled}
        type={type || 'text'}
        name={name}
        onChange={(e) => onValueChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeHolder}
        value={value || ''}
        autoFocus={autoFocus}
        required={required}
        readOnly={readOnly}
        data-testid={dataTestId}
      />
    )}
  </InputWrapper>
);
