import React from 'react';

import './PageNotFound.scss';

import { MAIL_FROM_ADDRESS } from 'app/constants/emails';
import ServerRouteHelper from 'app/helpers/ServerRouteHelper';

export const PageNotFound: React.FC = () => (
  <div className="page-not-found">
    <div className="content">
      <div className="title">Page Not Found</div>
      <p>
        It seems like you don’t have permission to view this page. This could be a setting on our
        side, or how your organization or team was set up.
      </p>
      <p>
        If you think you should have access to it, click here to send us an email and we’ll get
        right on it.
        <a href={`mailto:${MAIL_FROM_ADDRESS}`}>{MAIL_FROM_ADDRESS}</a>
      </p>
      <a href={ServerRouteHelper.dashboard.home()}>Return Home</a>
    </div>
  </div>
);

export default PageNotFound;
