import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { RouteComponentProps, Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const ManageAlign = lazy(() => import('./ManageAlign'));
const FullAlignReport = lazy(() => import('./Report/FullAlignReport'));
const Exercise = lazy(() => import('./Exercise'));
const TestDriveExercise = lazy(() => import('app/pages/align/Exercise/TestDriveExercise'));
const DiscussionGuide = lazy(() => import('./DiscussionGuide'));
const TestDriveAlignReport = lazy(() => import('./Report/TestDriveAlignReport'));
const TestDriveOverview = lazy(
  () => import('app/pages/align/Exercise/TestDriveExercise/TestDriveOverview')
);
const CoachReport = lazy(() => import('./Report/CoachReport'));

const AlignRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.path}/:alignId/discussion-guide/:step?/:commitState?/:commitStep?`}
      component={DiscussionGuide}
    />
    <Route exact path={`${match.path}/exercise/:secretCode/:step?`} component={Exercise} />
    <Route path={`${match.path}/:teamId/:alignId/manage`} component={ManageAlign} />
    <Route exact path={`${match.path}/personal/:token/report`} component={FullAlignReport} />
    <Route path={`${match.path}/:token/report`} component={FullAlignReport} />
  </Switch>
);

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route path="/align" component={AlignRoutes} />
      <Route exact path="/single-player/:alignId/manage" component={ManageAlign} />
      <Route
        exact
        path="/test-drive/:testDriveType/report_summary"
        component={TestDriveAlignReport}
      />
      <Route path="/test-drive/:testDriveType/report" component={TestDriveAlignReport} />

      <Route exact path="/test-drive/:testDriveType/exercise" component={TestDriveExercise} />
      <Route exact path="/test-drive/:testDriveType/overview" component={TestDriveOverview} />
      <Route
        exact
        path="/test-drive/:demoAlignId/discussion-guide/:step?/:commitState?/:commitStep?"
        component={DiscussionGuide}
      />
      <Route path="/teams/:secretCode/:exerciseId/coach_report" component={CoachReport} />
    </Switch>
  </Suspense>
);
