import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const ReferLink = lazy(() => import('./Link'));
const ReferEmail = lazy(() => import('./Email'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route exact path="/dashboard/referrals" component={ReferLink} />
      <Route exact path="/dashboard/referrals/email" component={ReferEmail} />
    </Switch>
  </Suspense>
);
