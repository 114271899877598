import { action, computed, observable, reaction, runInAction } from 'mobx';

import { ClientDataHelper, PerspectiveHelper, ServerRouteHelper } from 'app/helpers';
import { EmailActivityModel, MessageTags, TeamModel } from 'app/models';
import MemberModel from 'app/models/MemberModel';
import { ModelItem } from 'app/models/ModelItem';
import { ModelList } from 'app/models/ModelList';
import { PairViewAccess } from 'app/models/PerspectiveModel';
import ThirdPartyService from 'app/services/ThirdPartyService';
import Store from 'app/stores/Store';

export enum MemberStoreIncludes {
  MBTI_LETTERS = 'mbti_letters',
  PERMISSION_ATTRS = 'permission_attrs',
  TEAMS = 'teams',
  HAS_PERSPECTIVE = 'hasPerspective',
  HABITS_STATISTICS = 'habits_statistics',
  ORGANIZATIONS = 'organizations',
  ORGANIZATIONS_TEAM_GROUPS = 'organizations.team_groups',
  LICENSE = 'license',
}

/**
 * A store for the current Member
 */
export class MemberStore extends Store<MemberModel> {
  @observable public coachItem = new ModelItem<MemberModel>(MemberModel);
  @observable public membersWithMBTI = new ModelList<MemberModel>(MemberModel);
  @observable public teamMembers = new ModelList<MemberModel>(MemberModel);
  @observable public currentMember = new ModelItem<MemberModel>(MemberModel);
  @observable public currentMemberReferralLink: string;
  @observable public isLoadingReferralLink: boolean;

  @observable public isLoggedIn: boolean;
  @action setIsLoggedIn = (loggedIn) => (this.isLoggedIn = loggedIn);

  @observable public loadingLoginStatus: boolean;
  @action setLoadingLoginStatus = (status) => (this.loadingLoginStatus = status);

  @observable public isLoginStatusChecked: boolean;
  @action setIsLoginStatusChecked = (status: boolean): void => {
    this.isLoginStatusChecked = status;
  };

  @observable public isLoadingGuestMember: boolean;
  @action setIsLoadingGuestMember = (status: boolean): void => {
    this.isLoadingGuestMember = status;
  };

  constructor() {
    super();
    MemberModel._store = this;

    reaction(
      () => this.currentMember?.item,
      () => this.syncMemberTrackingId()
    );
  }

  @action
  private setIsloadingReferralLink(value) {
    this.isLoadingReferralLink = value;
  }

  @computed
  get membersSortedWithMBTI(): MemberModel[] {
    return PerspectiveHelper.sortMembersByPerspectives(
      this.membersWithMBTI.items,
      this.currentMember.item.id
    );
  }

  @computed
  get members(): MemberModel[] {
    return this.teamMembers.items;
  }

  @computed
  get membersWithLetters(): MemberModel[] {
    return this.membersSortedWithMBTI.filter((member) => {
      return member.mbti_letters !== null;
    });
  }

  @computed
  get membersWithoutLetters(): MemberModel[] {
    return this.membersSortedWithMBTI.filter((member) => {
      return member.mbti_letters === null;
    });
  }

  @computed
  get isCoaching(): boolean {
    if (!this.coachItem.item) {
      return false;
    }
    return this.currentMember.item.id !== this.coachItem.item.id;
  }

  @computed
  get activeMember(): MemberModel {
    return this.isCoaching ? this.coachItem.item : this.currentMember.item;
  }

  /**
   * Gets the currentMember from ClientData
   */
  @action
  async loadCurrentMember(
    allowAPI = true,
    includes: string[] = [],
    forceAPI = false,
    allowRedirect = true
  ): Promise<void> {
    const url = allowAPI ? ServerRouteHelper.api.members.current(this.sharedLinkToken) : undefined;

    if (!url) {
      ClientDataHelper.get('member')
        .then((m) => {
          const member = MemberModel.fromJson(m);
          runInAction(() => {
            this.currentMember.setItem(member);
          });
        })
        .catch((error) => {
          ThirdPartyService.sentry.captureException(error);
        });
      return;
    }

    if ((this.currentMember.loading || !!this.currentMember.item) && !forceAPI) {
      return;
    }

    await this.currentMember.load(
      url,
      { includes: includes.join(',') },
      { forceRefresh: forceAPI, redirectIfUnauthorized: allowRedirect }
    );

    this.setIsLoggedIn(!!this.currentMember.item);
  }

  @action
  public async loadCoachedMember(memberId: number, includes: string[] = []): Promise<void> {
    await this.coachItem.load(ServerRouteHelper.api.members.show(memberId), {
      includes: includes.join(','),
    });
  }

  @action
  async loadCurrentMemberReferralLink(): Promise<void> {
    this.setIsloadingReferralLink(true);
    const response = await this.apiService.get(ServerRouteHelper.api.members.currentReferralLink());
    this.currentMemberReferralLink = response.referral_link;
    this.setIsloadingReferralLink(false);
  }

  @action
  async sendReferralToEmails(emails: string[]): Promise<void> {
    const url = ServerRouteHelper.api.members.currentSendReferralEmail();
    const config = {
      url,
      data: { emails },
      throwError: true,
    };

    await this.apiService.newPost(config);
  }

  @action
  async loadMembersWithMBTI(teamId: number, forceRefresh = false): Promise<void> {
    const config = {
      forceRefresh,
    };

    const url = ServerRouteHelper.api.teams.membersWithMBTI(teamId);
    this.membersWithMBTI.load(url, null, config);
  }

  @action
  async loadTeamMembers(teamID: number): Promise<void> {
    await this.teamMembers.load(ServerRouteHelper.api.teams.members.list(teamID));
  }

  async saveMemberOrgRole(organizationId: number, roleId: string): Promise<void> {
    const route = ServerRouteHelper.api.organizations.saveMemberOrgRole(organizationId);
    await this.apiService.put(route, { roleId });
  }

  @action
  async updateMember(member: MemberModel): Promise<void> {
    try {
      member.setUpdating(true);

      const url = ServerRouteHelper.api.members.update(member.id);
      const config = {
        url,
        data: {
          name: member.name,
          email: member.email,
          avatarColor: member.avatar.color,
          currentPassword: member.currentPassword,
          password: member.password,
          passwordConfirm: member.passwordConfirm,
          role_self_reported: member.role_self_reported,
          pair_perspective_access: member.pair_perspective_access,
          accepted_current_policy: member.accepted_current_policy,
        },
        throwError: true,
      };

      const response = await this.apiService.newPut(config);

      member.updateFromJson(response);
      member.setUpdating(false);
    } catch (e) {
      member.setUpdating(false);
      throw e;
    }
  }

  async updateMemberPairAccess(viewAccess: PairViewAccess, token?: string): Promise<void> {
    const route = ServerRouteHelper.api.members.updateMemberPairAccess();
    const data = { pair_perspective_access: viewAccess };

    if (token) {
      data['token'] = token;
    }

    await this.apiService.put(route, data);
  }

  @action
  async updateMemberRoleSelfReportedWithToken(role: string, token: string): Promise<void> {
    const config = {
      url: ServerRouteHelper.api.members.updateSelfRole(),
      data: {
        role_self_reported: role,
        token,
      },
      throwError: true,
    };

    await this.apiService.newPut(config);
  }

  async createCoachedMember(data: { name: string; email: string }): Promise<MemberModel> {
    const url = ServerRouteHelper.api.members.saveCoachedMember();
    const config = {
      url,
      data,
      throwError: true,
    };

    const response = await this.apiService.newPost(config);

    return MemberModel.fromJson(response.data);
  }

  // Loads a member pinned to a perspective token
  async loadGuestMemberWithToken(token: string): Promise<{
    team: TeamModel;
    member: MemberModel;
    has_accepted_policy: boolean;
  }> {
    this.setIsLoadingGuestMember(true);

    const url = ServerRouteHelper.api.members.guestMember(token);
    const response = await this.apiService.get(url);

    const out = {
      team: null,
      member: null,
      has_accepted_policy: false,
    };

    if (!response) {
      this.setIsLoadingGuestMember(false);
      return out;
    }

    const { team, member, has_accepted_policy } = response.data;

    if (team) {
      out.team = TeamModel.fromJson(team);
    }

    if (member) {
      out.member = MemberModel.fromJson(member);
    }

    if (has_accepted_policy) {
      out.has_accepted_policy = has_accepted_policy;
    }

    this.setIsLoadingGuestMember(false);
    return out;
  }

  @action
  async checkOnlineStatus(): Promise<void> {
    if (this.loadingLoginStatus || this.isLoginStatusChecked) {
      return;
    }

    this.setLoadingLoginStatus(true);
    const url = ServerRouteHelper.api.members.current();
    try {
      const response = await this.apiService.get(url, null, null, null, false);
      this.currentMember.setItem(MemberModel.fromJson(response.data));
      this.setIsLoggedIn(true);
    } catch (e) {
      this.setIsLoggedIn(false);
    } finally {
      this.setLoadingLoginStatus(false);
      this.setIsLoginStatusChecked(true);
    }
  }

  updateMembersWithMBTI(members: MemberModel[]): void {
    this.membersWithMBTI.appendUniqueItems(members);
  }

  fakeEmailActivities(memberIds: number[]): void {
    const created = {
      date: Date.now().toString(),
      days: 0,
    };

    memberIds.forEach((memberId) => {
      const member = MemberModel.get(memberId);

      if (!member) {
        return;
      }

      // If there is an existing activity, modify it to be latest
      if (member.lastEmailInvite) {
        member.lastEmailInvite.updateFromJson({ created });
        return;
      }

      member.updateFromJson({
        lastEmailInvite: EmailActivityModel.fromJson({
          id: `fake_${memberId}`,
          member_id: memberId,
          action: 'send',
          message_tag: MessageTags.PerspectiveInvite,
          created,
        }),
      });
    });
  }

  /**
   * When current member changes we want to update the identified member id used
   * by posthog.
   */
  syncMemberTrackingId(): void {
    const accompany = window['accompany'] || {};

    if (!accompany.authMember) {
      return;
    }

    // Check if current member changed
    if (this.currentMember?.item?.tracking_id === accompany.authMember?.trackingId) {
      return;
    }

    // Update auth member tracking member info
    accompany.authMember.id = this.currentMember.item.id;
    accompany.authMember.name = this.currentMember.item.name;
    accompany.authMember.email = this.currentMember.item.email;
    accompany.authMember.trackingId = this.currentMember.item.tracking_id;

    // If no posthog, stop here
    if (!window['posthog']) {
      return;
    }

    window['posthog'].identify(accompany.authMember.trackingId, {
      email: accompany.authMember.email,
    });
  }

  async getMemberAndTokenFromSharedLink(path: string) {
    const url = ServerRouteHelper.api.members.getSharedLinkInfo();
    const config = {
      url,
      showGenericError: true,
      params: { path: path },
    };

    const response = await this.apiService.newGet(config);

    return response?.data;
  }
}

export default MemberStore;
