import React from 'react';

import { notification } from 'antd';
import { get } from 'lodash';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import URI from 'urijs';

import { STORE_AUTH } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import { ShiftError } from 'app/models';
import { Logo } from 'app/pages/auth/Partials/Logo/Logo';
import ThirdPartyService from 'app/services/ThirdPartyService';
import { AuthStore } from 'app/stores';

import LoginContainer from './Login/LoginContainer';
import ResetPasswordForm from './ResetPasswordForm';

interface Params {
  token: string;
}

export interface ResetPasswordProps extends RouteComponentProps<Params> {
  [STORE_AUTH]?: AuthStore;
}

class ResetPassword extends React.Component<ResetPasswordProps> {
  @observable private isResettingPassword: boolean;
  @action setIsResettingPassword = (resetting: boolean) => (this.isResettingPassword = resetting);
  @observable private error: ShiftError;
  @action setError = (error: ShiftError) => (this.error = error);
  @observable private name = '';
  @action setName = (value) => (this.name = value);
  @observable private password = '';
  @action setPassword = (value) => (this.password = value);
  @observable private confirmPassword = '';
  @action setConfirmPassword = (value) => (this.confirmPassword = value);

  componentDidMount() {
    this.checkForEmail();
  }

  checkForEmail() {
    if (this.email) {
      return;
    }

    ThirdPartyService.sentry.captureMessage('Email not included as param to password reset link');
  }

  @computed
  get header() {
    if (!this.email) {
      return 'Password Reset Expired';
    }

    if (!this.props.match.url.includes('invitation/accept') && !this.newAccount) {
      return 'Reset Password';
    }
    return 'Get Started';
  }

  @computed
  get email() {
    return get(this.queryParams, 'email');
  }

  @computed
  get changeNameNeeded(): boolean {
    const name = get(this.queryParams, 'name');
    return !name || name === get(this.queryParams, 'email');
  }

  @computed
  get status() {
    return localStorage.getItem('status');
  }

  @computed
  get hasError() {
    return !!this.error;
  }

  @computed
  get queryParams() {
    return URI.parseQuery(window.location.search);
  }

  @computed
  get redirectTo() {
    return get(this.queryParams, 'redirect_to');
  }

  @computed
  get newAccount() {
    return get(this.queryParams, 'newAccount') || 0;
  }

  @computed
  get utmCampaign() {
    return get(this.queryParams, 'utm_campaign');
  }

  @computed
  get token() {
    return this.props.match.params.token;
  }

  getErrorByKey(key: string) {
    return this.error?.errorsByKey(key)?.[0];
  }

  handleSubmit = async () => {
    this.setIsResettingPassword(true);
    this.setError(null);
    const { authStore } = this.props;
    try {
      const data = {
        email: this.email,
        token: this.token,
        password: this.password,
        password_confirmation: this.confirmPassword,
        newAccount: this.newAccount,
        name: this.name,
      } as any;

      if (this.utmCampaign === 'user-invited-to-org') {
        data.entry_point = 'invited_to_org';
      }

      const response = await authStore.resetPassword(data);

      if (response.post_reset_login_redirect) {
        window.location = response.post_reset_login_redirect;
        return;
      }

      if (this.newAccount) {
        this.props.history.push(ServerRouteHelper.member.onboarding());
        return;
      }

      window.location = this.redirectTo || ServerRouteHelper.dashboard.home();
    } catch (error) {
      if (error.status === 422) {
        const message = error.errors.token ?? 'Please fill all fields and submit again';

        notification.error({
          message: 'Error',
          description: message,
          placement: 'bottomRight',
          duration: 8,
        });
      }

      this.error = error;
    } finally {
      this.setIsResettingPassword(false);
    }
  };

  @computed
  get resetForm() {
    return (
      <ResetPasswordForm
        email={this.email}
        header={this.header}
        userName={this.name}
        isResettingPassword={this.isResettingPassword}
        changeNameNeeded={this.changeNameNeeded}
        setName={this.setName}
        password={this.password}
        setPassword={this.setPassword}
        confirmPassword={this.confirmPassword}
        setConfirmPassword={this.setConfirmPassword}
        handleSubmit={this.handleSubmit}
      />
    );
  }

  @computed
  get resetInvalid() {
    return (
      <div>
        <p>
          To generate a new password reset link, please click the "Continue" button below and enter
          your email, afterwards click “Send me a password reset link.
        </p>
        <a href={ServerRouteHelper.auth.login()} className="btn btn-primary w-100">
          Continue
        </a>
      </div>
    );
  }

  render() {
    return (
      <LoginContainer>
        <Logo />

        {this.status && <div className="alert alert-success">{this.status}</div>}
        {this.email ? this.resetForm : this.resetInvalid}
      </LoginContainer>
    );
  }
}

export default inject(STORE_AUTH)(observer(ResetPassword));
