import React, { useState } from 'react';

import CarouselModalComponent from 'app/components/ui/CarouselModalComponent';

export interface WelcomeToSHIFTModalProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete?: () => void;
  completeDisabled?: boolean;
  disableInfoText?: string;
}

const SLIDES = [
  {
    title: ' ', // Empty title
    header: 'Welcome to Valence!',
    headerStyle: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    content: "Here's what you can expect as you explore our tools.",
    contentStyle: {
      margin: '1rem auto 0',
    },
    iconSrc: '/images/welcomeToSHIFTModal/1-welcome.png',
    iconStyle: {
      marginTop: '3.125rem',
      marginBottom: '2.2rem',
      width: '21.6rem',
      height: '12.8rem',
    },
  },
  {
    header: 'Start by uncovering team insights',
    content:
      'Each team member will complete a 2 minute exercise in their own time, analyzing their core values and figuring out what matters most.',
    iconSrc: '/images/welcomeToSHIFTModal/2-insights.svg',
    iconStyle: {
      marginTop: '3rem',
      marginBottom: '2.8rem',
    },
  },
  {
    header: 'Discuss your team insights',
    content:
      'Lead crucial team discussions that explore blind spots, consider others’ points of view, and identify where change is needed most - all in a safe space.',
    iconSrc: '/images/welcomeToSHIFTModal/3-discuss.svg',
    iconStyle: {
      marginTop: '2.1rem',
      marginBottom: '2.3rem',
    },
  },
  {
    header: 'Identify and track improvement goals',
    content:
      'Pinpoint the goals that will be most helpful for you and set up a quick team pulse to receive nudges and invite feedback on your progress.',
    iconSrc: '/images/welcomeToSHIFTModal/4-track.svg',
    iconStyle: {
      marginTop: '6.5rem',
      marginBottom: '3.8rem',
    },
  },
];

export const WelcomeToSHIFTModal: React.StatelessComponent<WelcomeToSHIFTModalProps> = ({
  isOpen,
  onComplete,
  onClose,
}) => (
  <CarouselModalComponent
    title="Welcome to Align at Valence"
    isOpen={isOpen}
    onClose={onClose}
    onComplete={onComplete}
    slides={SLIDES}
  />
);

export const WelcomeToSHIFTModalContainer: React.StatelessComponent = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <CarouselModalComponent
      title="Welcome to Valence"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      onComplete={() => {
        setIsOpen(false);
      }}
      slides={SLIDES}
      completeLabel="Got it!"
      dataTrackPrefix="onboarding-welcome-modal"
    />
  );
};

export default WelcomeToSHIFTModal;
