import { scaleLinear, scaleThreshold } from 'd3-scale';

import { CELEBRATE, CLASS_COLOR_MAP, DISCUSS, IMPROVE } from 'app/constants';

export default class AlignHelper {
  static algoClassify(cutoffs) {
    if (cutoffs == null) {
      cutoffs = [-6.82278, -3.715749];
    }

    // The following could be <number, string> if the classifications were string instead of any
    // I'm not sure why they are "as any" so I don't want to change it in this PR. Only touching at all to fix type error.
    return scaleThreshold<number, string>().domain(cutoffs).range([IMPROVE, DISCUSS, CELEBRATE]);
  }

  /**
   * @legacy - You should use modern `algoClass` method
   */
  static algoColor(cutoffs) {
    const classifier = this.algoClassify(cutoffs);
    return (value: number) => CLASS_COLOR_MAP[classifier(value)];
  }

  static algoRescale(cutoffs) {
    if (cutoffs == null) {
      cutoffs = [-6.82278, -3.715749];
    }

    return scaleLinear()
      .domain([-15, ...cutoffs, 4])
      .range([0, 33, 66, 100]);
  }

  static alignScore(x: number, y: number): number {
    return ((x - 50.0) * (y + 150.0)) / 1500.0;
  }
}
