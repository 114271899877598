import React, { ReactElement, ReactNode } from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { message, notification } from 'antd';
import cx from 'classnames';
import { sampleSize, uniqueId } from 'lodash';
import { action, computed, observable, when } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';
import URI from 'urijs';

import './StepOne.scss';

import CrucialConversationsOnboardingModal from 'app/components/features/CrucialConversationsOnboardingModal';
import CustomDndProvider from 'app/components/features/CustomDndProvider';
import { ExerciseStatementCardCustomDragLayer } from 'app/components/features/ExerciseStatementCard';
import ScatterPlotCard from 'app/components/features/ScatterPlotCard';
import WelcomeToMy360Modal from 'app/components/features/WelcomeToMy360Modal';
import {
  ExerciseCardListView,
  ExerciseHideMobileBanner,
  STORE_ALIGN,
  STORE_AUTH,
  STORE_BREAKPOINT,
  STORE_ENTITY_STATE,
  STORE_EXERCISE_ANSWERS,
  STORE_EXERCISE_TYPE,
  STORE_MEMBER,
} from 'app/constants';
import { LocalStorageHelper, ServerRouteHelper } from 'app/helpers';
import TagHelper from 'app/helpers/TagHelper';
import { AlignModel, MemberModel, TeamModel } from 'app/models';
import ExerciseAnswersModel from 'app/models/ExerciseAnswersModel';
import ExerciseTypeModel, {
  ExerciseStatement,
  OnboardingModalKeys,
} from 'app/models/ExerciseTypeModel';
import ExerciseUIStore from 'app/pages/align/Exercise/ExerciseUIStore';
import { StatementCardsService } from 'app/services/StatementCardsService';
import {
  AlignStore,
  AuthStore,
  BreakpointStore,
  EntityStateStore,
  ExerciseAnswersStore,
  MemberStore,
} from 'app/stores';

import CardListActions from '../../CardListActions';
import ExerciseContext from '../../ExerciseContext';
import ExerciseIntroCard from '../../ExerciseIntroCard';
import ExerciseStatementCardList from '../../ExerciseStatementCardList';
import ExerciseCustomStatementCardList from '../../ExerciseStatementCardList/ExerciseCustomStatementCardList';
import ExerciseStatementsSubmitCard from '../../ExerciseStatementsSubmitCard';
import MobileCardList from '../../MobileCardList';

const ALIGN_ANONYMOUS_MESSAGE_TIMEOUT = 5000;
const ALIGN_INFO_MESSAGE_TIMEOUT = 20000;
const BANNER_DURATION = 5;
const BANNER_POS_TOP = 218;
const BANNER_POS_TOP_MOBILE = 100;

export const STICKIES_ANIMATION_LOAD_TIME = 200;

interface Params {
  secretCode?: string;
  testDriveType?: string;
}

export interface StepOneProps extends RouteComponentProps<Params> {
  uiStore: ExerciseUIStore;
  alignStore?: AlignStore;
  breakpointStore?: BreakpointStore;
  exerciseAnswersStore?: ExerciseAnswersStore;
  testDriveExerciseType?: ExerciseTypeModel;
  memberStore?: MemberStore;
  authStore?: AuthStore;
  entityStateStore?: EntityStateStore;
  isAlignExercise?: boolean;
}

export class StepOne extends React.Component<StepOneProps> {
  static contextType = ExerciseContext;
  context!: React.ContextType<typeof ExerciseContext>;

  @observable statements: ExerciseStatement[] = [];
  @action setStatements = (statements) => (this.statements = statements);

  @observable hideMobileBanner = false;
  @action setHideMobileBanner = (hideMobileBanner) => (this.hideMobileBanner = hideMobileBanner);

  @observable showWelcomeModal = false;
  @action toggleWelcomeModal = (): void => {
    this.showWelcomeModal = !this.showWelcomeModal;
  };

  @observable showHowItWorksModal = false;
  @action toggleHowItWorksModal = (): void => {
    this.showHowItWorksModal = !this.showHowItWorksModal;
  };

  @observable isFlipped = false;
  @action toggleFlip = (): void => {
    this.isFlipped = !this.isFlipped;
  };

  @observable showSubmitModal = false;
  @action toggleSubmitModal = (): void => {
    this.showSubmitModal = !this.showSubmitModal;
  };

  stickiesAnimation: any;

  constructor(props) {
    super(props);
    this.init();
  }

  init() {
    // If context already exists, then DnD has been previously initialized
    // and user hit browser's back button. Trigger a reload so it resets the
    // state and avoid multi-backend initialization error.
    //
    // This is an edge case and the cards' placement are
    // stored on localstorage anyway so they're not lost.
    if (this.dndContext) {
      window.location.reload();
      return;
    }

    const { memberStore, exerciseAnswersStore } = this.props;

    this.setHideMobileBanner(LocalStorageHelper.get(ExerciseHideMobileBanner));
    memberStore.loadCurrentMember(true, [], false, false);

    if (this.props.uiStore.isTestDrive) {
      this.resetPlacedStatementCards();
    }

    when(
      () => !this.isLoading && !!this.exercise,
      () => {
        this.setStatements(this.exercise?.exercise_type?.item.basicStatements);
        this.loadFromStatementLocalStorage();

        // Create the exercise answers instance so we can
        // record the opened_at and started_at
        const exerciseAnswers = ExerciseAnswersModel.fromJson({
          id: uniqueId(),
          exercise_code: this.exercise.code,
          opened_at: moment().unix(),
        });
        exerciseAnswersStore.setExerciseAnswers(exerciseAnswers);

        // open up welcome modal automatically if this isn't a My360
        if (!this.exercise.is_360) {
          this.toggleWelcomeModal();
        }
      }
    );
  }

  componentWillUnmount() {
    this.props.uiStore.setRunningSubmitAnimation(false);
    clearTimeout(this.stickiesAnimation);
  }

  get dndContext() {
    return window[Symbol.for('__REACT_DND_CONTEXT_INSTANCE__')];
  }

  get uiStore(): ExerciseUIStore {
    return this.context.uiStore;
  }

  get token(): string {
    return this.props.uiStore.token;
  }

  get exercise(): AlignModel {
    return this.props.uiStore.exercise;
  }

  get team(): TeamModel {
    return this.props.uiStore.team;
  }

  get exerciseAnswers(): ExerciseAnswersModel {
    return this.props.uiStore.exerciseAnswers;
  }

  @computed
  get hasInsightStep() {
    return this.exercise?.is_post_survey_enabled;
  }

  @computed
  get isLoading() {
    return this.props.alignStore.exercise.loading;
  }

  @computed
  get placedStatements() {
    return this.statements?.filter((statement) => !!statement.placed);
  }

  get isTestDrive(): boolean {
    return this.props.uiStore.isTestDrive;
  }

  @computed
  get isSubmitDisabled(): boolean {
    return (
      (this.isTestDrive && this.exercise.is_360) ||
      this.statements.filter((statement) => !statement.placed).length > 0
    );
  }

  @computed
  get isMobileOrTablet(): boolean {
    const { isMobileDevice, isTabletDevice } = this.props.breakpointStore;
    return isMobileDevice || isTabletDevice;
  }

  @computed
  get isMobile(): boolean {
    return this.props.breakpointStore.isMobileDevice;
  }

  @computed
  get onboardingModalKey() {
    return this.exercise?.exercise_type?.item.onboarding_modal_key;
  }

  get localStorageKey(): string {
    return this.props.uiStore.localStorageKey;
  }

  @computed
  get stepTwoUrl() {
    if (this.exercise?.is_360) {
      return ServerRouteHelper.my360.exercise(this.token, 2);
    }

    return ServerRouteHelper.align.exercise(this.token, 2);
  }

  @computed
  get exerciseType(): ExerciseTypeModel {
    return this.exercise?.exercise_type.item;
  }

  @computed
  get isCC(): boolean {
    return this.exerciseType?.isCompassionateConversationExercise;
  }

  @computed
  get my360MemberName() {
    if (this.exercise?.member?.isCurrentMember) {
      return 'Yourself';
    }
    return this.exercise?.member?.name;
  }

  @computed
  get my360MemberFirstName() {
    if (this.exercise?.member?.isCurrentMember) {
      return 'Yourself';
    }
    return this.exercise?.member?.firstName;
  }

  @computed
  get welcomeModalRandomStatements() {
    return sampleSize(this.exerciseType?.align_statements, 4).map(({ text }) => text);
  }

  @computed
  get onboardingModal() {
    if (!this.exerciseType) {
      return null;
    }

    if (this.onboardingModalKey === OnboardingModalKeys.CRUCIAL_CONVERSATIONS) {
      return (
        <CrucialConversationsOnboardingModal
          isOpen={this.showWelcomeModal}
          onClose={this.toggleWelcomeModal}
          dataTrackPrefix="crucial-conversations-onboarding-modal"
          tags={TagHelper.Align.ExerciseStepOne.CrucialConversationsOnboardingModal}
        />
      );
    }
  }

  get queryParams() {
    return URI.parseQuery(window.location.search);
  }

  get isCustomOnboardingExercise() {
    return this.queryParams?.utm_campaign === 'onboarding-team-exercise';
  }

  autoPlaceStatementCards = () => {
    this.setStatements(StatementCardsService.autoPlaceStatements(this.statements));

    this.setStartedAt();
    this.updateStatementLocalStorage();
  };

  resetPlacedStatementCards = () => {
    this.setStatements(StatementCardsService.resetStatementPlacements(this.statements));

    this.updateStatementLocalStorage();
  };

  showAlignBannerMessages() {
    if (this.isMobile) {
      message.config({
        top: !this.hideMobileBanner ? BANNER_POS_TOP : BANNER_POS_TOP_MOBILE,
        prefixCls: 'align-anonymous-banner ant-message', // Hack to add custom class
      });
    }

    if (!this.allowDirectSubmit) {
      setTimeout(
        () =>
          notification.info({
            message: 'Your answers are anonymous and will not be shared with your manager',
            placement: 'bottomRight',
            duration: BANNER_DURATION,
          }),
        ALIGN_ANONYMOUS_MESSAGE_TIMEOUT
      );
    }

    setTimeout(
      () =>
        notification.info({
          duration: BANNER_DURATION,
          message: "For the best results, rate each card's relative importance at this moment.",
          placement: 'bottomRight',
          description: (
            <div className="align-step-one-rate">
              <div className="mr-3">
                <p className="more-helpful">MORE HELPFUL</p>
              </div>

              <div>
                <p>LESS HELPFUL</p>
              </div>
              <img src="/images/align/align-toast-rate-step-one.svg" />
            </div>
          ),
        }),
      ALIGN_INFO_MESSAGE_TIMEOUT
    );
  }

  updateStatementLocalStorage = () => {
    StatementCardsService.writeToLocalStorage(this.statements, this.localStorageKey);
  };

  loadFromStatementLocalStorage = () => {
    this.setStatements(
      StatementCardsService.loadFromLocalStorage(this.statements, this.localStorageKey)
    );
  };

  handleStatementCardDrop = (id, updatedStatement) => {
    const index = this.statements.findIndex((statement) => statement.id === id);
    const statement = this.statements.find((statement) => statement.id === id);

    this.setStatements([
      ...this.statements.slice(0, index),
      { ...statement, ...updatedStatement },
      ...this.statements.slice(index + 1),
    ]);

    this.setStartedAt();

    this.updateExerciseAnswers();
    this.updateStatementLocalStorage();
  };

  get allowDirectSubmit(): boolean {
    return this.props.uiStore.allowDirectSubmit;
  }

  updateExerciseAnswers = (): void => {
    const statements = this.placedStatements.map(({ id, scaledX, scaledY }) => ({
      id,
      x: scaledX,
      y: scaledY,
    }));

    this.exerciseAnswers.update({ statements });
  };

  handleSubmitStatementPlacement = async (): Promise<void> => {
    const { uiStore } = this.props;

    this.updateExerciseAnswers();

    if (this.isCustomOnboardingExercise) {
      await uiStore.saveExerciseAnswers(this.currentMember);
      uiStore.redirectTo(this.stepTwoUrl);
      return;
    }

    // Only allow direct submit if there's no insight step,
    // so event managers self-assessing the team can take post-survey
    if (this.allowDirectSubmit && !this.hasInsightStep) {
      // Members detected as being team/secondary team managers
      // have their answers submitted automatically and redirected
      await uiStore.saveExerciseAnswers(this.currentMember);

      const redirectUrl = uiStore.redirectUrl;

      // Checking for when the path has 'checkins' as the check-in page is coffescript
      // Causing an history push to not load the page
      if (redirectUrl.includes('checkins')) {
        window.location.href = redirectUrl;
        return;
      }

      uiStore.redirectTo(uiStore.redirectUrl);
      return;
    }

    // Take to next step instead of showing submit modal for align
    // types having insight steps and for 360 exercises.
    if (this.hasInsightStep || this.exercise.is360()) {
      uiStore.redirectTo(this.stepTwoUrl);
      return;
    }

    this.toggleSubmitModal();
    this.toggleFlip();
  };

  setStartedAt = () => {
    if (!this.exerciseAnswers.started_at) {
      this.exerciseAnswers.update({ started_at: moment().unix() });
    }
  };

  @computed
  get cardListView() {
    if (this.exercise?.exercise_type?.item.config?.turn_on_explanations) {
      return ExerciseCardListView.DETAILED;
    }

    return ExerciseCardListView.REGULAR;
  }

  @computed
  get title() {
    if (!this.exercise?.is_team_align) {
      return null;
    }

    if (this.exerciseType?.prompt) {
      return <span>{this.exerciseType?.prompt}</span>;
    }

    if (!this.team) {
      return <span>You have been asked to rate your team on the following behaviors.</span>;
    }

    return (
      <>
        <p className="title-header">{this.team?.name} Align</p>
      </>
    );
  }

  @computed
  get introCard(): ReactElement {
    if (this.exerciseType.external_link) {
      return (
        <ExerciseIntroCard
          exerciseType={this.exerciseType}
          teamName={this.props.uiStore.team?.name}
          onReadyClick={this.toggleFlip}
          exerciseTypeLink={this.exerciseType.external_link}
          exerciseTypeLinkName="Statement Definitions"
        />
      );
    }

    return (
      <ExerciseIntroCard
        exerciseType={this.exerciseType}
        teamName={this.props.uiStore.team?.name}
        onReadyClick={this.toggleFlip}
      />
    );
  }

  @computed
  get statementsCard(): ReactElement {
    if (this.isMobile) {
      return (
        <MobileCardList
          title={this.title}
          statements={this.statements}
          exerciseIs360={this.exercise?.is_360}
          onDragArrowDoubleClick={this.autoPlaceStatementCards}
          onSubmit={this.handleSubmitStatementPlacement}
          isCC={this.exerciseType.isCompassionateConversationExercise}
        />
      );
    }

    return (
      <ExerciseStatementCardList
        exerciseIs360={this.exercise?.is_360}
        view={this.cardListView}
        title={this.title}
        statements={this.statements}
        onReset={this.resetPlacedStatementCards}
        onSubmit={this.handleSubmitStatementPlacement}
        onDragArrowDoubleClick={this.autoPlaceStatementCards}
        onLearnMoreClick={this.toggleHowItWorksModal}
        isCC={this.exerciseType.isCompassionateConversationExercise}
        renderTestDriveView={this.isTestDrive}
        exercise={this.exercise}
      />
    );
  }

  @computed
  get customStatementsCard(): ReactElement {
    if (this.isMobile) {
      return (
        <div>
          <MobileCardList
            title={this.title}
            statements={this.statements}
            exerciseIs360={this.exercise?.is_360}
            onDragArrowDoubleClick={this.autoPlaceStatementCards}
            onSubmit={this.handleSubmitStatementPlacement}
          />
        </div>
      );
    }

    return (
      <ExerciseCustomStatementCardList
        exerciseIs360={this.exercise?.is_360}
        view={this.cardListView}
        statements={this.statements}
        onSubmit={this.handleSubmitStatementPlacement}
        onDragArrowDoubleClick={this.autoPlaceStatementCards}
        onTipsClick={this.toggleFlip}
        teamName={this.team?.name}
      />
    );
  }

  @computed
  get submitCard(): ReactElement {
    return (
      <ExerciseStatementsSubmitCard
        token={this.token}
        history={this.props.history}
        toggleSubmitModal={this.toggleSubmitModal}
        showSubmitModal={this.showSubmitModal}
        nextStepUrl={this.stepTwoUrl}
        isRunningSubmitAnimation={this.props.uiStore.isRunningSubmitAnimation}
        onSurveyMember={this.handleSavingOnboardingFeedback}
        uiStore={this.props.uiStore}
        onSubmitAnswersCallback={this.handleSubmitAnswersCallback}
        animateStickies
      />
    );
  }

  @computed
  get leftCard(): ReactElement {
    return (
      <div className={'card-container' + (this.isFlipped ? ' flipped' : '')}>
        <div className="front">{this.customStatementsCard}</div>
        <div className="back">{this.showSubmitModal ? this.submitCard : this.introCard}</div>
      </div>
    );
  }

  @computed
  get howItWorksModal(): ReactElement {
    if (this.exercise?.is_360) {
      return (
        <WelcomeToMy360Modal
          isOpen={this.showHowItWorksModal}
          onClose={this.toggleHowItWorksModal}
          firstName={this.my360MemberFirstName}
          initialStep={2}
          statements={this.welcomeModalRandomStatements}
          exercise={this.exercise}
        />
      );
    }
  }

  handleSubmitAnswersCallback = () => {
    this.props.uiStore.setRunningSubmitAnimation(true);

    const statementCount = this.statements.length;

    this.stickiesAnimation = setTimeout(() => {
      if (this.exercise.is_single_player && this.props.uiStore.submitRedirectUrl) {
        window.location.href = this.props.uiStore.submitRedirectUrl;
        return;
      }

      this.props.history.push(this.stepTwoUrl);
    }, statementCount * STICKIES_ANIMATION_LOAD_TIME);
  };

  handleSavingOnboardingFeedback = (member: Partial<MemberModel>): void => {
    this.uiStore.saveOnboardingFeedback(this.exercise.id, member.id);
  };

  @computed
  get modals(): ReactElement {
    return (
      <>
        {this.onboardingModal}
        {this.howItWorksModal}
      </>
    );
  }

  get currentMember(): MemberModel {
    return this.props.uiStore.currentMember;
  }

  @computed
  get isGuest(): boolean {
    return !this.isLoading && !this.currentMember;
  }

  get notAlign(): boolean {
    return this.exercise.is_360 || this.exerciseType.isCompassionateConversationExercise;
  }

  @computed
  get cardFooter(): ReactNode {
    if (this.isLoading || !this.isMobile) {
      return null;
    }

    return (
      <div className="row no-gutters card-footer">
        <div className="col-md-12">
          {this.notAlign && (
            <CardListActions
              exercise={this.exercise}
              render360View={this.exercise?.is_360}
              renderTestDriveView={this.isTestDrive}
              onLearnMoreClick={this.toggleHowItWorksModal}
              onReset={this.resetPlacedStatementCards}
              onSubmit={this.handleSubmitStatementPlacement}
              isSubmitDisabled={this.isSubmitDisabled}
              isRunningSubmitAnimation={this.props.uiStore.isRunningSubmitAnimation}
            />
          )}

          {!this.notAlign && !this.props.uiStore.isRunningSubmitAnimation && this.isFlipped && (
            <div className={cx('action-buttons')}>
              <button
                onClick={this.resetPlacedStatementCards}
                className="btn btn-secondary reset-btn custom-reset-btn"
              >
                <ReloadOutlined /> &nbsp; Reset
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  render(): ReactElement {
    return (
      <div
        className={cx('exercise-step-one', { 'no-title': !this.title, 'cc-exercise': this.isCC })}
      >
        {this.isLoading && <h3 className="loading">Please wait...</h3>}
        {!this.isLoading && (
          <div className="row d-flex justify-content-center exercise-cards-container">
            <CustomDndProvider>
              <div className="col-md-6 col-lg-5 col-sm-6 card-list-container">
                {this.notAlign ? this.statementsCard : this.leftCard}
              </div>
              <div
                className={cx('col-md-6 col-lg-7 col-sm-6 scatter-plot-container-outer-container', {
                  'cc-scatterplot-container': this.isCC,
                })}
              >
                <div className="scatter-plot-card-wrapper">
                  <ScatterPlotCard
                    statements={this.placedStatements}
                    exerciseType={this.exerciseType}
                    exercise={this.exercise}
                    onStatementCardDrop={this.handleStatementCardDrop}
                    startAnimation={this.props.uiStore.isRunningSubmitAnimation}
                    blockDragging={this.props.uiStore.isRunningSubmitAnimation}
                  />
                </div>
              </div>

              <ExerciseStatementCardCustomDragLayer />
            </CustomDndProvider>
          </div>
        )}

        {/* mobile also needs to be handled */}
        {this.cardFooter}
        {this.modals}
      </div>
    );
  }
}

export default inject(
  STORE_ALIGN,
  STORE_AUTH,
  STORE_BREAKPOINT,
  STORE_EXERCISE_ANSWERS,
  STORE_EXERCISE_TYPE,
  STORE_MEMBER,
  STORE_ENTITY_STATE
)(observer(StepOne));
