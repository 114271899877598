import * as React from 'react';

import './Breadcrumbs.scss';

export interface AppHeaderProps {
  breadcrumbs?: {
    link?: string;
    title: string;
  }[];
}

export const AppHeader: React.StatelessComponent<AppHeaderProps> = ({ breadcrumbs }) => (
  <div className="d-flex align-items-center">
    {breadcrumbs.map(({ link, title }, index) => (
      <div className="d-inline" key={index}>
        {index != 0 && (
          <i className="far fa-chevron-right mx-2 text-secondary" aria-hidden="true" />
        )}
        {link && <a href={link}>{title}</a>}
        {!link && <span className="breadcrumb-title">{title}</span>}
      </div>
    ))}
  </div>
);

export default AppHeader;
