import { computed, observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import PerspectiveMemberResultStore from 'app/stores/PerspectiveMemberResultStore';

import { MemberAvatarModel } from './MemberAvatarModel';

export class PerspectiveMemberResultModel extends Model {
  static _store: PerspectiveMemberResultStore;

  @observable id: number;
  @observable name: string;
  @observable email: string;
  @observable letters: string;
  @observable avatar: MemberAvatarModel;
  @observable completed_perspective: boolean;
  @observable diffLabel: string;

  @computed
  get firstName() {
    return this.name?.split(' ')[0];
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectiveMemberResultModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PerspectiveMemberResultModel;
  }

  static get(id) {
    return this._get(id) as PerspectiveMemberResultModel;
  }
}

export default PerspectiveMemberResultModel;
