import React, { useState } from 'react';

import { Button, Divider, Form, Input, Select } from 'antd';

import AdminSimpleContentItemModel from 'app/models/AdminSimpleContentItemModel';

export interface OrgContentAddItemsProps {
  items: AdminSimpleContentItemModel[];
  addHandler: (item: AdminSimpleContentItemModel) => void;
  onSelectedItemChange: () => void;
  errors: string[];
}

export const OrgContentAddItems: React.FC<OrgContentAddItemsProps> = ({
  items,
  addHandler,
  onSelectedItemChange,
  errors,
}) => {
  const [selectedItem, setSelectedItem] = useState<AdminSimpleContentItemModel>(null);
  const [argumentsError, setArgumentsError] = useState<string>(null);

  const validateArguments = (args: string): boolean => {
    try {
      JSON.parse(args);
    } catch (error) {
      return false;
    }
    return true;
  };

  // Handle option state changes
  const handleSelectChange = (value: string) => {
    onSelectedItemChange();
    const item = items.find(({ key }) => key === value);
    setSelectedItem(item);
  };

  const handleAddItem = () => {
    const itemArguments = selectedItem.arguments ?? selectedItem.sample_arguments;

    if (selectedItem.has_arguments && !validateArguments(itemArguments)) {
      setArgumentsError('Invalid arguments, must be valid JSON.');
      return;
    }

    // Call handler
    addHandler(selectedItem);

    // Unset option to disable button
    setSelectedItem(null);

    // Clear error
    setArgumentsError(null);
  };

  const handleArgumentsChange = (event) => (selectedItem.arguments = event.target.value);

  // No results view when a org has nothing new to add
  if (items.length == 0) {
    return (
      <div>
        <Divider />
        <p className="text-center">
          <strong>All items overriden, no items to add.</strong>
        </p>
        <Divider />
      </div>
    );
  }

  const fieldProps = { help: null, validateStatus: null };

  if (errors.length > 0) {
    fieldProps.help = errors.join('. ');
    fieldProps.validateStatus = 'error';
  }

  return (
    <div>
      <Divider />
      <Form layout="inline" className="float-right mb-3">
        <Form.Item label="Available Items" {...fieldProps}>
          <Select style={{ width: 250 }} onChange={handleSelectChange} value={selectedItem?.key}>
            {items.map((item) => {
              return (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        {selectedItem?.has_arguments && (
          <Form.Item help={argumentsError} validateStatus={!!argumentsError ? 'error' : undefined}>
            <Input
              name="arguments"
              defaultValue={selectedItem.arguments ?? selectedItem.sample_arguments}
              onChange={handleArgumentsChange}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button disabled={!selectedItem} onClick={handleAddItem}>
            Add Item
          </Button>
        </Form.Item>
      </Form>
      <Divider />
    </div>
  );
};

export default OrgContentAddItems;
