export const Breakpoints = {
  // WARNING: We should stop using these and
  // slowly migrate to the ones below
  mobile: '(max-width: 767px)',
  tablet: '(max-width: 1024px)',

  // To reflect exactly what we have on responsive.scss
  // using fractional widths to better work with viewports with fractional widths or  high-dpi devices
  smallScreen: '(max-width: 767.98px)',
  mediumScreen: '(min-width: 768px) and (max-width: 1199.98px)',
  largeScreen: '(min-width: 1200px)',
};
