import React from 'react';

import './CardGridView.scss';

import { ExerciseStatementCardSimple } from 'app/components/features/ExerciseStatementCard';
import { ExerciseStatementCardProps } from 'app/components/features/ExerciseStatementCard/ExerciseStatementCard';

export interface CardGridViewProps {
  statements: ExerciseStatementCardProps[];
}

const getProps = ({ id, title, explanation, placed }: ExerciseStatementCardProps) => ({
  title,
  key: id,
});

export const CardGridViewSimple: React.StatelessComponent<CardGridViewProps> = ({ statements }) => (
  <div className="card-grid">
    {statements.map((statement) => (
      <ExerciseStatementCardSimple {...getProps(statement)} />
    ))}
  </div>
);

export default CardGridViewSimple;
