import React from 'react';

import cx from 'classnames';

import './FlatCard.scss';

import { GRAY_100 } from 'app/constants';

import Card, { CardProps } from '../CardNew/Card';

interface FlatCardProps extends CardProps {
  color?: string;
}

export const FlatCard: React.FC<FlatCardProps> = ({ color, className, ...rest }) => {
  const bgColor = color || GRAY_100;

  return (
    <Card className={cx('flat-card', className)} style={{ backgroundColor: bgColor }} {...rest} />
  );
};

export default FlatCard;
