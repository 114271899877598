import React, { ReactNode } from 'react';

import { Button, Input, Select } from 'antd';
import { camelCase } from 'lodash';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import styles from '../PulseTemplates.module.scss';

import { PulseStatementType } from 'app/constants';

const types = [
  PulseStatementType.Scale,
  PulseStatementType.Binary,
  PulseStatementType.Text,
  PulseStatementType.Header,
];

export interface InputStatementFormProps {
  addStatement: (text: string, type: string) => void;
}
export class InputStatementForm extends React.Component<InputStatementFormProps> {
  @observable private selectedType: string = types[0];
  @observable setSelectedType = (selectedType: string) => (this.selectedType = selectedType);

  @observable private statementText: string;
  @action setStatementText = (text: string) => (this.statementText = text);

  handleAdd = () => {
    const { addStatement } = this.props;

    addStatement(this.statementText, this.selectedType);

    this.setSelectedType(types[0]);
    this.setStatementText('');
  };

  public render(): ReactNode {
    return (
      <div className="d-flex">
        <Input
          value={this.statementText}
          className={styles.statementName}
          placeholder={'Input Statement Text'}
          onChange={(e) => this.setStatementText(e.target.value)}
        />

        <Select
          value={this.selectedType}
          onChange={(value) => this.setSelectedType(value)}
          className="ml-4"
        >
          {types.map((type: string, i) => (
            <Select.Option key={i} value={type}>
              {camelCase(type)}
            </Select.Option>
          ))}
        </Select>

        <Button type="ghost" className="ml-4" onClick={this.handleAdd}>
          Add
        </Button>
      </div>
    );
  }
}

export default observer(InputStatementForm);
