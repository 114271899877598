import { observable } from 'mobx';

import { ModelJson } from 'app/models';
import Model from 'app/models/Model';
import { ModelList } from 'app/models/ModelList';

import allModels from './allModels';
import type { PulseResponseActivityModel } from './PulseResponseActivityModel';

export class GroupedPulseResponsesModel extends Model {
  @observable pulseActivities = new ModelList<PulseResponseActivityModel>(
    allModels.PulseResponseActivityModel
  );

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as GroupedPulseResponsesModel;
  }
}

export default GroupedPulseResponsesModel;
