import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { AlignModel } from 'app/models/AlignModel';
import Model from 'app/models/Model';
import { ModelItem } from 'app/models/ModelItem';
import { ModelList } from 'app/models/ModelList';
import Store from 'app/stores/Store';

export class SinglePlayerStore extends Store<Model> {
  @observable loading = false;
  @observable singlePlayer = new ModelItem<AlignModel>(AlignModel);
  @observable singlePlayers = new ModelList<AlignModel>(AlignModel);

  async getSinglePlayer(memberId: number, exerciseId: number) {
    const url = ServerRouteHelper.api.exercises.singlePlayer.show(memberId, exerciseId);
    await this.singlePlayer.load(url);
  }

  async getSinglePlayers(memberId: number) {
    const url = ServerRouteHelper.api.exercises.singlePlayer.list(memberId);
    await this.singlePlayers.load(url);
  }

  async createNew(memberId: number, exerciseTypeId: number) {
    const url = ServerRouteHelper.api.exercises.singlePlayer.create(memberId);

    const config = {
      url,
      data: { exercise_type_id: exerciseTypeId },
      showGenericError: true,
    };

    const response = await this.apiService.newPost(config);
    if (response?.data) {
      return AlignModel.fromJson(response.data);
    }
  }

  startSinglePlayer = async (memberId: number, exerciseTypeId: number): Promise<void> => {
    this.loading = true;
    const exercise = await this.createNew(memberId, exerciseTypeId);

    window.location.href = exercise.invite_url;
  };
}

export default SinglePlayerStore;
