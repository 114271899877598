import React from 'react';

import cx from 'classnames';

interface LogoProps {
  className?: string;
  clickable?: boolean;
  url?: string;
}

const Logo: React.FC<LogoProps> = ({ className, url }) => (
  <a id="shift-logo" href={url ? url : '/'} className={cx('logo', className)}>
    <img
      src="/images/logo.svg"
      className="acc-topbar-brand-link-img"
      alt="Valence"
      width={182}
      height={40}
    />
  </a>
);

export const LogoWhite: React.FC<LogoProps> = ({ className, url }) => (
  <a id="shift-logo-white" href={url ? url : '/'} className={cx('logo', className)}>
    <img
      src="/images/logo-white.svg"
      className="acc-topbar-brand-link-img"
      alt="Valence"
      width={160}
      height={40}
    />
  </a>
);

export const LogoOnly: React.FC<LogoProps> = ({ className, url }) => (
  <a id="shift-logo-only" href={url ? url : '/'} className={cx('logo', className)}>
    <img src="/images/logo-only.svg" className="acc-topbar-brand-link-img" alt="Valence" />
  </a>
);

export default Logo;
