import React from 'react';

import { RouteComponentProps } from 'react-router';

import './RegistrationForm/RegistrationForm.scss';

import { Container } from 'app/pages/auth/Container';

export const Register: React.FC<RouteComponentProps> = ({ history, location, match, children }) => (
  <Container>{children}</Container>
);

export default Register;
