import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import AdminStatsStore from 'app/stores/AdminStatsStore';

export class AdminStatsModel extends Model {
  public static _store: AdminStatsStore;

  @observable public id: string;
  @observable public total: number;
  @observable public count: number;
  @observable public percent: number;
  @observable public ranks: any;
  @observable public top: any;

  static fromJson(json: ModelJson): AdminStatsModel {
    return this._fromJson(json) as AdminStatsModel;
  }

  static getOrNew(id): AdminStatsModel {
    return this._getOrNew(id) as AdminStatsModel;
  }

  static get(id): AdminStatsModel {
    return this._get(id) as AdminStatsModel;
  }
}

export default AdminStatsModel;
