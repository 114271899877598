import { action } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { DiscussionPromptModel } from 'app/models';
import DiscussionPromptAnswerModel from 'app/models/DiscussionPromptAnswerModel';
import Store from 'app/stores/Store';

export class DiscussionPromptAnswerStore extends Store<DiscussionPromptAnswerModel> {
  constructor() {
    super();
    DiscussionPromptAnswerModel._store = this;
  }

  @action
  async savePromptAnswer(guideId: number, promptId: number, answer: string) {
    const prompt = DiscussionPromptModel.getOrNew(promptId);
    if (!prompt) {
      return;
    }

    const url = ServerRouteHelper.api.discussionGuides.savePromptAnswer(guideId, promptId);
    const response = await this.apiService.post(url, { answer });
    prompt.answers.appendItem(DiscussionPromptAnswerModel.fromJson(response.data));
    return prompt;
  }
}

export default DiscussionPromptAnswerStore;
