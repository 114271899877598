import React from 'react';

import {
  AnimatedAxis,
  AnimatedGlyphSeries,
  AnimatedLineSeries,
  Grid,
  XYChart,
} from '@visx/xychart';

import {
  GRAY_200,
  GRAY_600,
  SURVEY_DOT_COLOR_BLACK,
  SURVEY_DOT_COLOR_GRAY,
  SURVEY_DOT_COLOR_GREEN,
  SURVEY_DOT_COLOR_GREEN_DARK,
  SURVEY_DOT_COLOR_GREEN_LIGHT,
  SURVEY_DOT_COLOR_ORANGE_DARK,
  SURVEY_DOT_COLOR_ORANGE_LIGHT,
  SURVEY_DOT_COLOR_RED,
} from 'app/constants';

const COLORS = [
  SURVEY_DOT_COLOR_RED,
  SURVEY_DOT_COLOR_ORANGE_DARK,
  SURVEY_DOT_COLOR_ORANGE_LIGHT,
  SURVEY_DOT_COLOR_GRAY,
  SURVEY_DOT_COLOR_GREEN_LIGHT,
  SURVEY_DOT_COLOR_GREEN_DARK,
  SURVEY_DOT_COLOR_GREEN,
  SURVEY_DOT_COLOR_BLACK,
];

function TimeSeriesChart({ data, width }) {
  const accessors = {
    xAccessor: (d) => new Date(d?.x),
    yAccessor: (d) => d.y,
  };

  const glyphColor = (d): string | undefined => {
    return COLORS[d.y];
  };

  const getColorOfIndex = (i: number): string => {
    return COLORS[i];
  };

  return (
    <XYChart width={width} height={200} xScale={{ type: 'time' }} yScale={{ type: 'linear' }}>
      {/* This displays the x-axis labels */}
      <AnimatedAxis
        orientation="bottom"
        strokeDasharray="3"
        strokeWidth={0.5}
        {...accessors}
        tickLabelProps={() => ({
          fill: 'black',
          fontSize: 12,
          fontWeight: '700',
          textAnchor: 'middle',
          verticalAnchor: 'end',
        })}
      />

      {/* This displays the horizontal line oer row */}
      <Grid
        numTicks={7}
        columns={null}
        stroke={GRAY_200}
        lineStyle={{
          strokeWidth: 10,
        }}
      />

      {/* This displays the vertical line per column */}
      <Grid numTicks={7} strokeDasharray="3" rows={null} />

      {/* This displays the y-axis */}
      <AnimatedAxis
        hideAxisLine
        orientation="left"
        numTicks={7}
        tickComponent={({ formattedValue, ...tickProps }) => (
          <circle r={4} cx={0} fill={getColorOfIndex(parseInt(formattedValue))} />
        )}
      />

      {/* This displays the lines that connects the data dots */}
      <AnimatedLineSeries
        data={data}
        dataKey={'x'}
        {...accessors}
        stroke={GRAY_600}
        strokeWidth={1}
        from={0}
        to={6}
      />

      {/* This displays the actual data dots */}
      <AnimatedGlyphSeries data={data} dataKey={'x'} {...accessors} colorAccessor={glyphColor} />
    </XYChart>
  );
}

export default TimeSeriesChart;
