import { observable } from 'mobx';

import ServerRouteHelper from 'app/helpers/ServerRouteHelper';
import { ModelItem, OnboardingToolIntroductionModel } from 'app/models';
import Store from 'app/stores/Store';

export class OnboardingToolIntroductionStore extends Store<OnboardingToolIntroductionModel> {
  @observable introduction = new ModelItem<OnboardingToolIntroductionModel>(
    OnboardingToolIntroductionModel
  );

  constructor() {
    super();
    OnboardingToolIntroductionModel._store = this;
  }

  async loadToolIntroductionContent(token: string): Promise<void> {
    const url = ServerRouteHelper.api.onboarding.tool.introduction(token);

    this.introduction.load(url);
  }
}

export default OnboardingToolIntroductionStore;
