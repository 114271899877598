import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import PairedNoteStore from 'app/stores/PairedNoteStore';

export enum PairedNoteTypes {
  PairPerspective = 'pair_perspective',
}

export enum PairedNoteThreadKeys {
  DimensionNote = 'dimension_note',
}

export class PairedNoteModel extends Model {
  public static _store: PairedNoteStore;

  @observable public id: number;
  @observable public author_id: number;
  @observable public author_type: string;
  @observable public recipient_id: number;
  @observable public recipient_type: string;
  @observable public type: string;
  @observable public thread_key: string;
  @observable public note: string;
  @observable public created_at: string;
  @observable public updated_at: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PairedNoteModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PairedNoteModel;
  }

  static get(id) {
    return this._get(id) as PairedNoteModel;
  }
}

export default PairedNoteModel;
