import { maxBy } from 'lodash';
import { action, computed, observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import ExerciseAnswersStore from 'app/stores/ExerciseAnswersStore';

const BIAS = 1.44;

export interface ExerciseSimpleAnswer {
  id: number;
  x: number;
  y: number;
}

export class ExerciseAnswersModel extends Model {
  public static _store: ExerciseAnswersStore;

  @observable public exercise_code: string;
  @observable public name?: string;
  @observable public email?: string;
  @observable public statements: ExerciseSimpleAnswer[];
  @observable public opened_at?: number;
  @observable public started_at?: number;
  @observable public finished_at?: number;
  @observable public rating?: number;

  @action
  update(props?: any) {
    if (props) {
      for (const k in props) {
        this[k] = props[k];
      }
    }
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as ExerciseAnswersModel;
  }

  asJSON() {
    return {
      exercise_code: this.exercise_code,
      name: this.name,
      email: this.email,
      statements: this.statements,
      opened_at: this.opened_at,
      started_at: this.started_at,
      finished_at: this.finished_at,
      rating: this.rating,
    };
  }

  @computed
  get goingWellStatement() {
    // Get statements from upper-left quadrant,
    // raise its coordinates to two, add them together
    // then get square-root of that.
    // Finally get statement with highest square-root.
    return maxBy(
      this.statements
        .filter(({ x, y }) => x < 0 && y > 0)
        .map(({ id, x, y }) => ({ id, score: Math.sqrt(BIAS * x ** 2 + y ** 2) })),
      'score'
    );
  }

  @computed
  get notGoingWellStatement() {
    // Same as above but get statements from bottom-left quadrant.
    return maxBy(
      this.statements
        .filter(({ x, y }) => x < 0 && y < 0)
        .map(({ id, x, y }) => ({ id, score: Math.sqrt(BIAS * x ** 2 + y ** 2) })),
      'score'
    );
  }
}

export default ExerciseAnswersModel;
