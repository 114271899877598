import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { EntityBlockModel, PagingMetaModel } from 'app/models';
import { ModelList } from 'app/models/ModelList';
import Store from 'app/stores/Store';

export class EntityBlockStore extends Store<EntityBlockModel> {
  @observable entityBlocks = new ModelList<EntityBlockModel>(EntityBlockModel);
  @observable pageMeta: PagingMetaModel;

  constructor() {
    super();
    EntityBlockModel._store = this;
  }

  async getEntityBlocks(search?: string, type?: string, page?: number): Promise<void> {
    const params = { search, type, page };
    const url = ServerRouteHelper.api.admin.entityBlocks.list(params);

    this.entityBlocks.load(url, undefined, {
      onResponse: (response) => {
        this.pageMeta = new PagingMetaModel(response.meta);
      },
    });
  }

  async deleteEntityBlock(entityBlock: EntityBlockModel): Promise<void> {
    const url = ServerRouteHelper.api.admin.entityBlocks.delete(entityBlock.id);
    const config = { url };

    return this.apiService.newDelete(config);
  }

  async addEntityBlock(type: string, email: string, value: string): Promise<EntityBlockModel> {
    const url = ServerRouteHelper.api.admin.entityBlocks.list();

    const data: { type: string; email?: string; value?: string } = { type };

    if (email) {
      data.email = email;
    }

    if (value) {
      data.value = value;
    }

    const config = {
      url,
      data,
      throwError: true,
    };

    const resp = await this.apiService.newPost(config);

    return EntityBlockModel.fromJson(resp.data);
  }
}

export default EntityBlockStore;
