import React, { useMemo } from 'react';

import cx from 'classnames';

import './PulseStatementChoice.scss';

interface PulseStatementChoiceProps {
  value: number;
  selected?: boolean;
  disabled?: boolean;
  binary?: boolean;
  onClick: (value: number) => void;
}

export const PulseStatementChoice: React.FC<PulseStatementChoiceProps> = ({
  value,
  selected,
  disabled,
  binary,
  onClick,
}) => {
  const choiceClass = useMemo(() => {
    if (binary && value === 0) {
      return 'neg-3';
    } else if (binary && value === 1) {
      return 'pos-3';
    }

    if (value < 0) {
      return `neg-${Math.abs(value)}`;
    } else if (value > 0) {
      return `pos-${value}`;
    }
    return 'zero';
  }, [value, binary]);

  return (
    <div
      className={cx('pulse-statement-choice', choiceClass, { selected, binary })}
      onClick={!disabled ? () => onClick(value) : null}
    >
      <span>{value}</span>
    </div>
  );
};

export default PulseStatementChoice;
