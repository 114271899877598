import React, { ReactNode } from 'react';

import cx from 'classnames';
import { isString } from 'lodash';

import './CardTitle.scss';

interface CardTitleProps {
  icon?: ReactNode;
  title?: ReactNode;
  subTitle?: ReactNode;
  titleExtras?: ReactNode;
  centered?: boolean;
}

export const CardTitle: React.FC<CardTitleProps> = ({
  icon,
  title,
  subTitle,
  titleExtras,
  centered,
}) => {
  const formattedTitle =
    !!subTitle && isString(title) ? <h4 className="card-title-title">{title}</h4> : title;

  const formattedSubTitle =
    !!subTitle && isString(subTitle) ? (
      <h5 className="card-title-subtitle">{subTitle}</h5>
    ) : (
      subTitle
    );

  return (
    <div
      className={cx('card-title', {
        'text-center': centered,
      })}
    >
      {icon && <div className="card-title-icon">{icon}</div>}

      <div className="title-container">
        <div className="float-right">{titleExtras}</div>
        {formattedTitle}
        {formattedSubTitle}
      </div>
    </div>
  );
};

export default React.memo(CardTitle);
