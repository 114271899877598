import React, { ReactElement } from 'react';

import MetabaseDashboard from 'app/components/ui/MetabaseDashboard';
import { MetabaseDashboards } from 'app/constants';

export const UsageData = (): ReactElement => (
  <MetabaseDashboard title="Usage Data" name={MetabaseDashboards.ActivitiesOverview} admin />
);

export default UsageData;
