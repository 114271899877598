import React, { Component, ReactNode } from 'react';

import { Button, Input, notification } from 'antd';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import validator from 'validator';

import './EmailDomainList.scss';

import { STORE_ORGANIZATION } from 'app/constants';
import { OrganizationModel } from 'app/models';
import { OrganizationStore } from 'app/stores';

interface EmailDomainListProps {
  organization: OrganizationModel;
  organizationStore?: OrganizationStore;
}

export class EmailDomainList extends Component<EmailDomainListProps> {
  @observable domains: string[] = [];
  @action setDomains = (domains: string[]): void => {
    this.domains = domains;
  };

  @observable isSaving = false;
  @action toggleIsSaving = (): void => {
    this.isSaving = !this.isSaving;
  };

  constructor(props: EmailDomainListProps) {
    super(props);
    this.setDomains(props.organization.email_domains.slice());
  }

  @action
  handleChange = (oldDomain: string, newDomain: string): void => {
    const index = this.domains.findIndex((domain) => domain === oldDomain);
    this.domains[index] = newDomain;
  };

  handleDelete = (domain: string): void => {
    const newDomains = this.domains.filter((d) => d !== domain);
    this.setDomains(newDomains);
  };

  handleAdd = (): void => {
    this.setDomains([...this.domains, '']);
  };

  handleSave = async (): Promise<void> => {
    const { organization, organizationStore } = this.props;

    const validDomains = this.domains
      .map((domain) => domain.trim())
      .filter((domain) => this.isValidDomain(domain));
    this.setDomains(validDomains);

    this.toggleIsSaving();

    try {
      await organizationStore.updateEmailDomains(organization.id, validDomains);

      notification.success({
        message: 'Saved!',
        description: 'Email domains successfully saved.',
      });
    } catch (err) {
      notification.error({
        message: 'Oops!',
        description: 'Something went wrong!',
      });
    } finally {
      this.toggleIsSaving();
    }
  };

  isValidDomain = (domain: string): boolean => {
    if (domain.startsWith('*.')) {
      domain = domain.substring(2);
    }

    return validator.isFQDN(domain);
  };

  render(): ReactNode {
    return (
      <div className="org-email-domains">
        <h4>Email Domains</h4>

        <p>Wildcard email domain are supported:</p>

        <ul>
          <li>
            wildcard email domain only works for subdomains. For example, if you have org which uses
            '@foo.com', and '*.foo.com', so you will need to add two emails domains for this org:
            <ol>
              <li>
                email domain as 'foo.com' (exact match) which will match all email addresses
                matching this domain. For example 'john@foo.com'
              </li>
              <li>
                wildCarded email domain '*.foo.com', which will match all emails addresses with
                subdomains. For example 'john@eu.foo.com', and 'mark@us.foo.com'
              </li>
            </ol>
          </li>
          <li>
            Wildcard email domains matches only higher subdomain. For example, wildcard
            '*.eu.test.com' will match 'mark@foo.eu.test.com', and 'john@bar.eu.test.com'. But, it
            will not match 'john@us.foo.eu.test.com'.
          </li>
        </ul>

        {this.domains.map((domain, i) => (
          <div className="domain-item" key={i} data-testid="domain-item">
            <div className="domain-input">
              <Input
                value={domain}
                onChange={({ target }) => this.handleChange(domain, target.value)}
              />
            </div>
            <div className="domain-delete">
              <Button type="link" size="small" onClick={() => this.handleDelete(domain)}>
                Delete
              </Button>
            </div>
          </div>
        ))}

        <div className="actions">
          <Button
            data-testid="new-btn"
            size="large"
            onClick={this.handleAdd}
            disabled={this.isSaving}
          >
            New Domain
          </Button>

          <Button
            data-testid="save-btn"
            type="primary"
            size="large"
            onClick={this.handleSave}
            disabled={!this.domains.length || this.isSaving}
          >
            Save Domains
          </Button>
        </div>
      </div>
    );
  }
}

export default inject(STORE_ORGANIZATION)(observer(EmailDomainList));
