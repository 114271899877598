import { action, computed, observable } from 'mobx';
import moment from 'moment';

import { DATE_TIME_FORMAT, DEFAULT_TIME, DEFAULT_TIMEZONE } from 'app/constants';
import BaseModel from 'app/models/BaseModel';

import { ModelJson } from '.';

// This is a copy of the Recurrence class in coffee/utils/reminderModal.coffee
export class RecurrenceModel extends BaseModel {
  @observable public weeksFrequency = 1;
  @observable public days = observable([]);
  @observable public startingOn = moment(new Date(), DATE_TIME_FORMAT.ISO_LOCAL_DATE);
  @observable public isEnabled = true;
  @observable public time = moment(DEFAULT_TIME, DATE_TIME_FORMAT.ISO_LOCAL_TIME);
  @observable public timezone = DEFAULT_TIMEZONE;

  public setStartingOnFromDate(jsDate: Date) {
    this.startingOn = moment(jsDate, DATE_TIME_FORMAT.ISO_LOCAL_DATE);
  }

  public setTimeOfDayFromDate(jsTime: Date) {
    this.time = moment(jsTime, DATE_TIME_FORMAT.ISO_LOCAL_TIME);
  }

  @action
  public toggleDayOfWeek(day: string, checked: boolean) {
    if (checked === false) {
      this.days.remove(day);
    } else if (this.days.indexOf(day) === -1) {
      this.days.push(day);
    }
  }

  public isValid() {
    return this.days.length > 0;
  }

  public isValidDate(jsDate: Date) {
    const dayOfWeek = moment(jsDate).format(DATE_TIME_FORMAT.DAY_OF_WEEK);
    return this.days.includes(dayOfWeek);
  }

  public getHumanFormat() {
    const weekPart = this.weeksFrequency === 1 ? '' : `${this.weeksFrequency} weeks on `;
    const daysPart = this.days.join(', ');
    const startingDateFormatted = moment(this.startingOn).format(DATE_TIME_FORMAT.DAY_DATE_YEAR);
    const startingTimeFormatted = moment(this.time).format(DATE_TIME_FORMAT.TIME_12_HOUR);
    return `${weekPart}${daysPart}, starting on ${startingDateFormatted} at ${startingTimeFormatted} EST`;
  }

  public getSummary() {
    return this.isValid() ? 'Every ' + this.getHumanFormat() : '';
  }

  @computed
  public get toJS() {
    return {
      weeksFrequency: this.weeksFrequency,
      days: this.days.toJS(),
      isEnabled: this.isEnabled,
      startingOn: this.startingOn ? this.startingOn.format(DATE_TIME_FORMAT.ISO_LOCAL_DATE) : '',
      time: this.time ? this.time.format(DATE_TIME_FORMAT.ISO_LOCAL_TIME) : '',
      timezone: this.timezone,
    };
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as RecurrenceModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as RecurrenceModel;
  }

  static get(id) {
    return this._get(id) as RecurrenceModel;
  }

  deserialize_startingOn(startingOn) {
    const date = startingOn || new Date();
    this.startingOn = moment(date, DATE_TIME_FORMAT.ISO_LOCAL_DATE);
  }

  deserialize_time(time) {
    const date = time || DEFAULT_TIME;
    this.time = moment(date, DATE_TIME_FORMAT.ISO_LOCAL_TIME);
  }

  deserialize_timezone(timezone) {
    this.timezone = timezone || DEFAULT_TIMEZONE;
  }

  deserialize_days(days) {
    this.days.replace(days || [moment().format(DATE_TIME_FORMAT.DAY_OF_WEEK)]);
  }
}

export default RecurrenceModel;
