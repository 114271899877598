import React from 'react';

import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';

import './StressRegistration.scss';

import FakeBox from 'app/components/ui/FakeBox';
import { STORE_CONTENT_SECTION } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import { ContentSectionStore } from 'app/stores';

import RegistrationForm from '../RegistrationForm';

const SECTION_NAME = 'register.campaigns.teams_under_stress';
const ENTRY_POINT = 'register.campaigns.team_under_stress';

export interface StressRegistrationProps extends RouteComponentProps {
  contentSectionStore?: ContentSectionStore;
}

export class StressRegistration extends React.Component<StressRegistrationProps> {
  constructor(props) {
    super(props);
    this.init();
  }

  init() {
    this.props.contentSectionStore.getByName(SECTION_NAME);
  }

  @computed
  get isLoading() {
    return this.props.contentSectionStore.contentSection.loading;
  }

  @computed
  get contentSection() {
    return this.props.contentSectionStore.contentSection.item;
  }

  @computed
  get navbar() {
    return (
      <div className="stress-registration-navbar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul>
                <li>
                  <a href="https://support.valence.co/en/">Support</a>
                </li>
                <li>
                  <a href={ServerRouteHelper.auth.login()}>Login</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  @computed
  get title() {
    return this.contentSection?.content?.['title'];
  }

  @computed
  get description() {
    return this.contentSection?.content?.['description'];
  }

  @computed
  get tagline() {
    return this.contentSection?.content?.['tagline'];
  }

  render() {
    return (
      <div className="stress-registration">
        <div className="stress-registration-inner">
          {this.navbar}

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="stress-registration-header">
                  <img className="logo" src="/images/logo.svg" alt="SHIFT" />
                  {this.tagline && <h2>{this.tagline}</h2>}
                </div>
                <div className="stress-registration-body">
                  {this.isLoading && <FakeBox width="34.5rem" height="45.625rem" />}
                  {this.contentSection && (
                    <RegistrationForm
                      {...this.props}
                      title={this.title}
                      description={this.description}
                      entry_point={ENTRY_POINT}
                    />
                  )}
                </div>
                <div className="stress-registration-footer">&copy; Copyright Valence</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(STORE_CONTENT_SECTION)(observer(StressRegistration));
