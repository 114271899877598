import { observable } from 'mobx';

import { ModelJson } from 'app/models';
import Model from 'app/models/Model';
import { PulseResponseStore } from 'app/stores';

export class PulseResponseModel extends Model {
  static _store: PulseResponseStore;

  @observable id: number;
  @observable member_id?: number;
  @observable pulse_statement_id: number;
  @observable pulse_activities_id?: number;
  @observable response_text?: string;
  @observable response_int?: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PulseResponseModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PulseResponseModel;
  }

  static get(id) {
    return this._get(id) as PulseResponseModel;
  }
}

export default PulseResponseModel;
