import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import PerspectiveDimensionLensStore from 'app/stores/PerspectiveDimensionLensStore';

export class PerspectiveDimensionLensModel extends Model {
  static _store: PerspectiveDimensionLensStore;

  readonly id: string;
  @observable binnedLensScore: string;
  @observable binnedScore: number;
  @observable lens: string;
  @observable rawScore: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectiveDimensionLensModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PerspectiveDimensionLensModel;
  }

  static get(id) {
    return this._get(id) as PerspectiveDimensionLensModel;
  }
}

export default PerspectiveDimensionLensModel;
