import PersonalStatementWithAnswerModel from 'app/models/PersonalStatementWithAnswerModel';

import Store from './Store';

export class PersonalStatementWithAnswerStore extends Store<PersonalStatementWithAnswerModel> {
  constructor() {
    super();
    PersonalStatementWithAnswerModel._store = this;
  }
}

export default PersonalStatementWithAnswerStore;
