import { PerspectiveDimensionLensModel } from 'app/models';
import Store from 'app/stores/Store';

export class PerspectiveDimensionLensStore extends Store<PerspectiveDimensionLensModel> {
  constructor() {
    super();
    PerspectiveDimensionLensModel._store = this;
  }
}

export default PerspectiveDimensionLensStore;
