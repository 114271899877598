import { useCallback, useRef } from 'react';

export function useCallbackIfVisible(callback?: () => void) {
  const nodeRef = useRef(null);
  const observerRef = useRef(null);

  return useCallback(
    (node) => {
      if (!callback) {
        return;
      }

      if (!observerRef.current) {
        observerRef.current = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            callback();
          }
        });
      }

      if (nodeRef.current) {
        observerRef.current.unobserve(nodeRef.current);
      }

      if (node) {
        observerRef.current.observe(node);
      }

      nodeRef.current = node;
    },
    [callback, nodeRef, observerRef]
  );
}
