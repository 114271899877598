import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import OrgNoticeModel from 'app/models/OrgNoticeModel';
import ThirdPartyService from 'app/services/ThirdPartyService';
import Store from 'app/stores/Store';

export class OrgNoticeStore extends Store<OrgNoticeModel> {
  @observable isSaving = false;
  @action setIsSaving = (isSaving: boolean): void => {
    this.isSaving = isSaving;
  };

  constructor() {
    super();
    OrgNoticeModel._store = this;
  }

  saveAnswers = async (noticeId: number, answers: Record<string, string>): Promise<string> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.orgNotice.answers(noticeId);
      const config = {
        url,
        data: { answers },
        showGenericError: true,
      };

      const resp = await this.apiService.newPost(config);
      return resp.redirect_url;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };
}

export default OrgNoticeStore;
