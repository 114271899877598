export const ESSITY_SECRET_CODE = 'essity';
export const BOSTON_SCIENTIFIC_CODE = 'bsci';
export const SERVICENOW_SECRET_CODE = 'servicenow';
export const AGIOS_SECRET_CODE = 'agios';
export const OLX_SECRET_CODE = 'olx';
export const ILLUMINA_SECRET_CODE = 'illumina';
export const LIBERTY_MUTUAL_SECRET_CODE = 'liberty';
export const NESTLE_EU_ORG_SECRET_CODES = [
  'nestleaoa',
  'nestleeurope',
  'nestlegc', // China
  'MQN9Gi', // Latam
  'nestle',
  'Ej9R2I', // Nestle Practice Lab
];
