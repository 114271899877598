import { groupBy, reduce, snakeCase, sortBy, template } from 'lodash';
import { action, computed, observable } from 'mobx';
import moment, { Moment } from 'moment';

import { ExerciseStatusKeys } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import type { TeamModel } from 'app/models/TeamModel';
import AlignStore from 'app/stores/AlignStore';

import allModels from './allModels';
import DiscussionGuideModel from './DiscussionGuideModel';
import type { DiscussionSpaceModel } from './DiscussionSpaceModel';
import ExerciseStatementModel from './ExerciseStatementModel';
import type { ExerciseStatusProps } from './ExerciseStatusModel';
import ExerciseStatusModel from './ExerciseStatusModel';
import ExerciseTypeModel from './ExerciseTypeModel';
import MemberModel from './MemberModel';
import Model, { ModelJson } from './Model';
import { ModelItem } from './ModelItem';
import { ModelList } from './ModelList';
import OrganizationModel from './OrganizationModel';
import PulseForTeamModel from './PulseForTeamModel';

export interface AlignStatementSummaryStats {
  celebrate: number;
  discuss: number;
  improve: number;
  comparison: string;
  shiftScore: number;
}

export interface AlignProps {
  id: number;
  code?: string;
  team_id?: number;
  team?: typeof TeamModel;
  exercise_type_id?: number;
  answers?: any;
  answers_count?: number;
  cutoff_date?: Moment;
  exercise_type?: any;
  invite_url?: string;
  discussion_url?: string;
  members?: MemberModel[];
  personalReportLinks?: { [member_id: number]: string };
  reportLinks?: { [report_key: string]: string };
  created_at?: string;
  is_closed?: boolean;
  closed_at?: string;
  is_preview_available?: boolean;
  can_be_closed?: boolean;
  can_be_deleted?: boolean;
  discussion_guide?: DiscussionGuideModel;
  discussion_space?: DiscussionSpaceModel;
  isSinglePlayerMode?: boolean;
  is_360?: boolean;
  is_single_player?: boolean;
  is_team_align?: boolean;
  is_demo?: boolean;
  is_test_drive?: boolean;
  score_cutoffs?: number[];
  results_sent?: boolean;
  organization?: Partial<OrganizationModel>;
  is_report_shared?: boolean;

  currentUserHasCompleted?: boolean;
  currentUserCanCoach?: boolean;
  currentUserCanManage?: boolean;
  currentUserHideCompletePrompt?: boolean;

  secret_code?: string;
  member_id?: number;
  member?: MemberModel;
  pulse?: PulseForTeamModel;
  is_activated?: boolean;
  is_post_survey_enabled: boolean;
  anomaly_highlights_hidden: boolean;
  post_questionnaire?: string;

  statements_summary_stats?: AlignStatementSummaryStats;
}

export class AlignModel extends Model {
  static _store: AlignStore;

  protected omittedKeys = ['participants', 'nonParticipants'];

  @observable id: number;
  @observable code?: string;
  @observable organization_id?: number;
  @observable team_id: number;
  @observable exercise_type_id?: number;
  @observable exercise_type_name?: string;
  @observable answers?: any;
  @observable answers_count?: number;
  @observable cutoff_date?: Moment;

  @observable invite_url: string;
  @observable discussion_url?: string;
  @observable is_360?: boolean;
  @observable is_single_player?: boolean;
  @observable is_team_align?: boolean;
  @observable is_demo?: boolean;
  @observable is_test_drive?: boolean;
  @observable personalReportLinks?: { [member_id: number]: string };
  @observable reportLinks?: { [report_key: string]: string };
  @observable created_at?: string;
  @observable is_closed?: boolean;
  @observable closed_at?: string;
  @observable is_preview_available?: boolean;
  @observable can_be_closed?: boolean;
  @observable can_be_deleted?: boolean;
  @observable discussion_guide?: DiscussionGuideModel;
  @observable discussion_space?: DiscussionSpaceModel;
  @observable isSinglePlayerMode: boolean;
  @observable score_cutoffs?: number[];
  @observable results_sent?: boolean;
  @observable results_send_date?: string;
  @observable pulse_id?: number;
  @observable pulse?: PulseForTeamModel;
  @observable is_discussion_over: boolean;
  @observable organization?: Partial<OrganizationModel>;
  @observable discussion_space_actions: Record<string, string>[];
  @observable discussion_type?: string;
  @observable is_report_shared?: boolean;

  // fields related to current user
  @observable currentUserHasCompleted?: boolean;
  @observable currentUserCanCoach?: boolean;
  @observable currentUserCanManage?: boolean;
  @observable currentUserHideCompletePrompt?: boolean;
  @observable currentUserHasAcceptedPolicy?: boolean;

  @observable secret_code?: string;
  @observable member_id?: number;
  @observable is_activated?: boolean;
  @observable shift_insights: string;
  @observable shift_insights_secondary: string;
  @observable exercise_type = new ModelItem<ExerciseTypeModel>(ExerciseTypeModel);
  @observable members = new ModelList<MemberModel>(MemberModel);
  @observable memberStatuses = new ModelList<ExerciseStatusModel>(ExerciseStatusModel);
  @observable member?: MemberModel;
  @observable team = new ModelItem<TeamModel>(allModels.TeamModel);
  @observable exercise_member? = new ModelItem<MemberModel>(MemberModel);
  @observable is_post_survey_enabled?: boolean;

  @observable scatterplot_highlights: any[];
  @observable anomaly_highlights_hidden: boolean;
  @observable post_questionnaire?: string;

  @observable statements_summary_stats?: AlignStatementSummaryStats;

  @observable personalReportRoutes: any; // only for Coach.

  deserialize_discussion_guide(discussion_guide) {
    this.discussion_guide = new DiscussionGuideModel(discussion_guide);
  }

  deserialize_discussion_space(discussion_space) {
    this.discussion_space = allModels.DiscussionSpaceModel.fromJson(discussion_space);
  }

  deserialize_live_discussion_space(live_discussion_space) {
    this.discussion_space = allModels.DiscussionSpaceModel.fromJson(live_discussion_space);
  }

  deserialize_member(member) {
    this.member = MemberModel.fromJson(member);
  }

  deserialize_pulse(pulse) {
    this.pulse = PulseForTeamModel.fromJson(pulse);
  }

  deserialize_team_exercise_pulse(pulse) {
    this.pulse = PulseForTeamModel.fromJson(pulse);
  }

  deserialize_organization(organization) {
    this.organization = OrganizationModel.fromJson(organization);
  }

  @action appendMember(member: MemberModel, status?: ExerciseStatusModel) {
    this.memberStatuses.appendItem(status);
    this.members.appendItem(member);
  }

  @computed
  get organizationId(): number {
    return this.organization_id ?? this.organization?.id ?? 0;
  }

  @computed
  get participants() {
    return this.members.items.filter((member) => this.statusForMember(member.id)?.isCompleted);
  }

  @computed
  get nonParticipants() {
    return this.members.items.filter((member) => !this.statusForMember(member.id)?.isCompleted);
  }

  statusForMember(memberId: number): ExerciseStatusModel {
    return this.memberStatuses?.items?.find(({ member_id }) => member_id === memberId);
  }

  get participantsCount() {
    return this.participants.length;
  }

  get nonParticipantsCount() {
    return this.nonParticipants.length;
  }

  get membersCount() {
    return this.members.items.length;
  }

  get invitedMembersCount() {
    return this.memberStatuses.items.filter((status) => status.member_id !== this.member.id).length;
  }

  @computed
  get completedMembers() {
    return this.memberStatuses.items.filter(
      (member) => member.status === ExerciseStatusKeys.completed_align
    );
  }

  getDefaultEmailContent(member): string {
    const inviteTemplate = this.exercise_type.item.invite_email;

    return template(inviteTemplate)({
      baseUrl: window.location.origin,
      name: member?.name ?? '',
      linkLabel: 'Complete Align here',
      linkUrl: 'LINKTOALIGN',
    });
  }

  get completedMembersCount() {
    return this.memberStatuses.items.filter(
      (member) => member.status === 'completed_align' && this.member.id !== member.member_id
    ).length;
  }

  get manageUrl(): string {
    return this.is_360
      ? ServerRouteHelper.dashboard.myself.manageMy360(this.id)
      : ServerRouteHelper.align.manage(this.team_id, this.id);
  }

  get participantsStatus() {
    const { membersCount, participantsCount } = this;

    if (this.is_closed && this.closed_at) {
      return `Closed on ${this.closedAtFormatted}. ${participantsCount} people responded.`;
    }

    if (membersCount === 0) {
      return 'No one has been invited to this exercise';
    }

    if (this.is_closed && this.closed_at) {
      return `Closed on ${this.closedAtFormatted}. ${participantsCount} people responded.`;
    }

    if (membersCount === 0) {
      return 'No one has been invited to this exercise';
    }

    if (participantsCount === 0) {
      return `${membersCount} people have been invited to this exercise`;
    }

    return `${participantsCount} out of ${membersCount} responded so far`;
  }

  get participantStats() {
    return `${this.participantsCount}/${this.membersCount}`;
  }

  get closedAtFormatted() {
    const { closed_at } = this;

    if (!closed_at) {
      return;
    }

    return moment(closed_at).format('MMM Do YYYY');
  }

  get resultsSendDateFormatted() {
    if (!this.results_send_date) {
      return;
    }

    return moment(this.results_send_date).format('MMM D, YYYY');
  }

  get createdAtFormatted() {
    const { created_at } = this;

    return moment(created_at).format('MMM Do YYYY');
  }

  get teamDiscussionDeadlineFormatted() {
    const { closed_at } = this;

    if (!closed_at) {
      return;
    }

    return moment(closed_at).add(moment.duration(2, 'weeks')).format('MMM Do');
  }

  get personalReportUrl() {
    return this.reportLinks?.personalReport;
  }

  get cutoffDate() {
    if (moment(this.cutoff_date).isValid()) {
      return moment(this.cutoff_date);
    }
  }

  is360() {
    return this.exercise_type.item.type === '360';
  }

  getStatementsWithAnswers() {
    const rescaleScore = accompany.Utils.algoRescale(this.score_cutoffs);
    let statements = sortBy(accompany.alignUtils.associateAnswersWithStatements(this), 'score');
    statements = statements.map((stmt) => {
      const scoreRescaled = rescaleScore(stmt.score);
      return new ExerciseStatementModel({ ...stmt, scoreRescaled });
    });

    const colorToLabel = reduce(
      accompany.alignUtils.StatusLegendLabels,
      (acc, value, key) => {
        acc[value.color] = key.toLowerCase();
        return acc;
      },
      {}
    );

    return groupBy(statements, (statement) => snakeCase(colorToLabel[statement.color]));
  }

  asJSON() {
    const { team_id, exercise_type_id } = this;
    return {
      team_id,
      exercise_type_id,
    };
  }

  @action
  updateMemberStatus(status: ExerciseStatusProps) {
    const existingStatus = this.statusForMember(status.member_id);

    if (existingStatus) {
      existingStatus.updateFromJson(status);
      return;
    }

    this.memberStatuses.appendItem(ExerciseStatusModel.fromJson(status));
  }

  @action
  setDiscussionSpace(discussionSpace: DiscussionSpaceModel): void {
    this.discussion_space = discussionSpace;
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as AlignModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as AlignModel;
  }

  static get(id) {
    return this._get(id) as AlignModel;
  }
}

allModels.register({ AlignModel });

export default AlignModel;
