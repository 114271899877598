import { debounce } from 'lodash';
import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList, PagingMetaModel } from 'app/models';
import SessionModel, { SessionsAggregate } from 'app/models/SessionModel';
import Store from 'app/stores/Store';

export enum SessionSearchType {
  Member = 'member',
  IpAddress = 'ip_address',
}

enum ALLOWED_SEARCH_FILTERS {
  MemberFilter = 'member_filter',
  IpAddress = 'ip_address',
}

export class SessionStore extends Store<SessionModel> {
  @observable sessions = new ModelList<SessionModel>(SessionModel);
  @observable sessionsAggregate: SessionsAggregate[] = [];
  @observable pageMeta: PagingMetaModel;

  @observable sessionSearch = '';
  @action setSessionSearch = (search: string): void => {
    this.sessionSearch = search;
  };

  @observable isLoading = false;
  @action setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading;
  };

  @observable sessionAggregatePage = 1;
  @action setSessionAggregatePage = (page: number): void => {
    this.sessionAggregatePage = page;
  };

  @observable currentSearchType: SessionSearchType = SessionSearchType.Member;
  @action setCurrentSearchType = (searchType: SessionSearchType): void => {
    this.currentSearchType = searchType;
  };

  constructor() {
    super();
    SessionModel._store = this;
  }

  filterSessions = debounce(() => {
    this.getSessions(this.currentSearchType, this.sessionSearch, 1);
  }, 500);

  async getSessions(search_type: SessionSearchType, search?: string, page?: number): Promise<void> {
    this.setIsLoading(true);
    const params = { search_type, page };

    if (search !== undefined) {
      const searchParam = this.getSearchParam();
      params[searchParam] = search;
    }

    const url = ServerRouteHelper.api.admin.sessions.list(params);

    const response = await this.apiService.get(url);
    this.sessionsAggregate = response.data;
    this.pageMeta = response;
    this.setIsLoading(false);
  }

  async getSessionDetails(params): Promise<void> {
    params.search_type = this.currentSearchType;

    const url = ServerRouteHelper.api.admin.sessions.details(params);

    this.sessions.load(url);
  }

  async logoutSessions(params): Promise<void> {
    params.search_type = this.currentSearchType;

    const url = ServerRouteHelper.api.admin.sessions.logoutSessions(params);

    await this.apiService.post(url);
  }

  async deleteSession(session_id: string): Promise<void> {
    const url = ServerRouteHelper.api.admin.sessions.delete(session_id);

    await this.apiService.delete(url);

    this.sessions.deleteItemById(session_id);
  }

  getSearchParam(): string {
    if (this.currentSearchType === SessionSearchType.IpAddress) {
      return ALLOWED_SEARCH_FILTERS.IpAddress;
    }

    return ALLOWED_SEARCH_FILTERS.MemberFilter;
  }
}

export default SessionStore;
