import React, { ReactNode } from 'react';

import { Col, notification, Row } from 'antd';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './ReviewStep.module.scss';

import EnhancedCard from 'app/components/ui/EnhancedCard';
import { Clickable } from 'app/components/ui/EnhancedCard/CardFooter/CardFooter';
import Markdown from 'app/components/ui/Markdown';
import MemberAvatar from 'app/components/ui/MemberAvatar';
import { MemberItem, PulseStatementModel } from 'app/models';
import PulseHabitsContext from 'app/pages/dashboard/Pulse/Context/PulseHabitsContext';
import ThirdPartyService from 'app/services/ThirdPartyService';

export interface RouteParams {
  step: string;
  teamId?: string;
}

export interface ReviewStepProps extends RouteComponentProps<RouteParams> {}

export class ReviewStep extends React.Component<ReviewStepProps> {
  static contextType = PulseHabitsContext;

  private get invitedMembers(): MemberItem[] {
    return this.context.uiStore.participants.filter((member) => member.isSelected);
  }

  private get habits(): PulseStatementModel[] {
    return this.context.uiStore.validHabits;
  }

  @computed
  private get primaryButton(): Clickable {
    const { isSaving } = this.context.uiStore;

    return {
      label: this.saveButtonLabel,
      disabled: isSaving,
      onClick: this.handleLaunch,
    };
  }

  @computed
  private get isHabitStarted(): boolean {
    const { activePulse } = this.context.uiStore;

    return !!activePulse.started_at;
  }

  @computed
  private get saveButtonLabel(): string {
    const { isSaving } = this.context.uiStore;

    if (this.isHabitStarted || this.context.uiStore.isEditing) {
      return 'Done';
    }

    return isSaving ? 'Launching...' : 'Launch Habits';
  }

  @computed
  private get subHeading(): string {
    if (this.context.uiStore.isEditing) {
      return 'Make sure that your habits, colleagues, and cadence are as you want them';
    }

    return 'Make sure that your habits, colleagues, and cadence are as you want them before launching.';
  }

  @computed
  private get secondaryButton(): Clickable {
    return {
      label: 'Go back to cadence',
      onClick: this.handlePrev,
    };
  }

  private handlePrev = (): void => {
    const url = this.context.uiStore.getStepUrl('configure');

    this.props.history.push(url);
  };

  private handleLaunch = async (): Promise<void> => {
    try {
      if (!this.isHabitStarted) {
        const status = await this.context.uiStore.launchHabit();

        if (!status && !this.context.uiStore.isTestDrive) {
          return;
        }

        notification.success({ message: 'Habit launched successfully.', placement: 'bottomRight' });
      }

      // After launching redirect to list of habits.
      const url = this.context.uiStore.launchHabitUrl;
      this.props.history.push(url);
    } catch (error) {
      ThirdPartyService.sentry.captureException(error);
      notification.error({
        message: 'Something went wrong while trying to launch your habit, we are looking into it.',
        placement: 'bottomRight',
      });
    }
  };

  public render(): ReactNode {
    return (
      <EnhancedCard
        heading="Time to review everything"
        subHeading={this.subHeading}
        footerPrimaryButton={this.primaryButton}
        footerSecondaryButton={this.secondaryButton}
      >
        <Row gutter={24} className={styles.container}>
          <Col span={16}>
            <div className={styles.habitsList}>
              <h6>Your habits</h6>

              {this.habits.map((habit, i) => (
                <div className="list-item" key={i}>
                  {habit.text}
                </div>
              ))}
            </div>
          </Col>

          <Col span={8} className={styles.rightContainer}>
            <div className={styles.inviteList}>
              <h6>Invited colleagues</h6>

              <ul className="mt-1">
                {this.invitedMembers?.map((member) => (
                  <li key={member.id}>
                    <MemberAvatar {...member.avatar} /> {member.name}
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.cadence}>
              <h6>Cadence</h6>
              <Markdown source={this.context.uiStore.cadenceText} />
            </div>
          </Col>
        </Row>
      </EnhancedCard>
    );
  }
}

export default withRouter(observer(ReviewStep));
