// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZFK39pUoLSoInfEPP2MTMg\\=\\=,.ZFK39pUoLSoInfEPP2MTMg\\=\\= h1{margin-top:1rem}.ZFK39pUoLSoInfEPP2MTMg\\=\\= h1{align-items:center;display:flex;margin-bottom:1.875rem}.ZFK39pUoLSoInfEPP2MTMg\\=\\= h1 span{margin-right:1rem}", "",{"version":3,"sources":["webpack://./resources/assets/js/components/ui/PageTitle/PageTitle.module.scss"],"names":[],"mappings":"AAGE,2DAFA,eAOF,CALE,+BAEE,kBAAA,CADA,YAAA,CAEA,sBAEJ,CACI,oCACE,iBACN","sourcesContent":[".header {\n  margin-top: 1rem;\n\n  h1 {\n    display: flex;\n    align-items: center;\n    margin-bottom: 1.875rem;\n    margin-top: 1rem;\n\n    span {\n      margin-right: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "ZFK39pUoLSoInfEPP2MTMg=="
};
export default ___CSS_LOADER_EXPORT___;
