import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const PulseOptOut = lazy(() => import('./PulseOptOut'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route exact path="/pulses/:token/opt-out" component={PulseOptOut} />
    </Switch>
  </Suspense>
);
