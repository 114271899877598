import React, { Component, CSSProperties, ReactNode } from 'react';

import cx from 'classnames';

import './ExerciseStatementCard.scss';

// TItle length sizes
const EXTRA_LARGE_SIZE = 50;
const LARGE_SIZE = 35;
const MAX_WORD_LENGTH = 10;

// Font sizes matching title length
const STANDARD_FONT_SIZE = 0.75;
const EXTRA_LARGE_FONT_SIZE = 0.6;
const MED_FONT_SIZE = 0.7;

export interface ExerciseStatementCardBasicProps {
  title: string;
  placed?: boolean;
  left?: number;
  top?: number;
  mobileScale?: boolean;
  className?: string;
}

class ExerciseStatementCardBasic extends Component<ExerciseStatementCardBasicProps> {
  longestWordLength = (sentence) => {
    return sentence.split(' ').reduce((maxLengthWord, currentWord) => {
      if (currentWord.length > maxLengthWord.length) {
        return currentWord;
      } else {
        return maxLengthWord;
      }
    }, '').length;
  };

  get fontSize() {
    const { title } = this.props;

    let fontSize = STANDARD_FONT_SIZE;
    const textLength = title.length;

    if (textLength >= EXTRA_LARGE_SIZE) {
      fontSize = EXTRA_LARGE_FONT_SIZE;
    }

    if (textLength >= LARGE_SIZE || this.longestWordLength(title) > MAX_WORD_LENGTH) {
      fontSize = MED_FONT_SIZE;
    }

    return `${fontSize}rem`;
  }

  get styles(): CSSProperties {
    const { top, left } = this.props;
    return top || left ? { top, left, position: 'absolute' } : {};
  }

  render(): ReactNode {
    const { title, className, placed, mobileScale } = this.props;

    return (
      <div
        className={cx('exercise-statement-card', className, {
          placed,
          mobileScale,
        })}
        style={this.styles}
      >
        <div className="body" style={{ fontSize: this.fontSize }}>
          {title}
        </div>
      </div>
    );
  }
}

export default ExerciseStatementCardBasic;
