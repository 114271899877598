import React from 'react';

import './PulseStatementLabel.scss';

interface PulseStatementLabelProps {
  label: string;
}

export const PulseStatementLabel: React.FC<PulseStatementLabelProps> = ({ label }) => (
  <div className="pulse-statement-label">{label}</div>
);

export default PulseStatementLabel;
