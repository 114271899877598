import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import My360sStatsStore from 'app/stores/My360sStatsStore';

export interface TopStatement {
  text: string;
  execise_type: string;
  score: string;
}

export class My360sStatsModel extends Model {
  static _store: My360sStatsStore;

  @observable id: number;
  @observable topStatementsToCelebrate: TopStatement[];
  @observable topStatementsToDiagnose: TopStatement[];
  @observable hasStatements: boolean;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as My360sStatsModel;
  }
}

export default My360sStatsModel;
