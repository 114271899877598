import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import AdminOrgContentStore from 'app/stores/AdminOrgContentStore';

export class AdminOrgContentItemModel extends Model {
  public static _store: AdminOrgContentStore;

  @observable public id: number;
  @observable public key: string;
  @observable public type: string;
  @observable public content: string;
  @observable public arguments?: string;
  @observable public has_arguments: boolean;

  deserialize_content(value): void {
    if (this.type === 'text') {
      this.content = value;
      return;
    }

    this.content = JSON.stringify(value, null, 2);
  }

  static fromJson(json: ModelJson): AdminOrgContentItemModel {
    return this._fromJson(json) as AdminOrgContentItemModel;
  }

  static getOrNew(id): AdminOrgContentItemModel {
    return this._getOrNew(id) as AdminOrgContentItemModel;
  }

  static get(id): AdminOrgContentItemModel {
    return this._get(id) as AdminOrgContentItemModel;
  }
}

export default AdminOrgContentItemModel;
