import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { EntityStateStore } from 'app/stores';

export enum EntityStateAssociationType {
  CurrentMember = 'Member',
  Exercise = 'Exercise',
  Team = 'Team',
  AlignStatement = 'AlignStatement',
  DiscussionSpace = 'DiscussionSpace',
}

export class EntityStateModel extends Model {
  public static _store: EntityStateStore;

  @observable public id: number;
  @observable public type: string;
  @observable public flag?: boolean;
  @observable public meta?: any;

  @observable public entity_type: EntityStateAssociationType;
  @observable public entity_id: number;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as EntityStateModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as EntityStateModel;
  }

  static get(id) {
    return this._get(id) as EntityStateModel;
  }
}

export default EntityStateModel;
