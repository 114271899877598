import { random } from 'lodash';

import { ExerciseStatementCardProps } from 'app/components/features/ExerciseStatementCard/ExerciseStatementCard';
import { LocalStorageHelper } from 'app/helpers';

export class StatementCardsService {
  static autoPlaceStatements = (statements: ExerciseStatementCardProps[]) => {
    return statements.map((statement) => {
      statement.placed = true;
      statement.scaledX = random(-90, 90);
      statement.scaledY = random(-90, 90);

      return statement;
    });
  };

  static resetStatementPlacements = (statements: ExerciseStatementCardProps[]) => {
    return statements.map((statement) => {
      statement.placed = false;
      statement.scaledX = undefined;
      statement.scaledY = undefined;

      return statement;
    });
  };

  static writeToLocalStorage = (statements: ExerciseStatementCardProps[], key: string) => {
    const data = statements
      .map(({ id, scaledX, scaledY }) => {
        if (scaledX && scaledY) {
          return { id, scaledX, scaledY };
        }

        return null;
      })
      .filter((statement) => statement);

    LocalStorageHelper.set(key, data);
  };

  static loadFromLocalStorage = (
    statements: ExerciseStatementCardProps[],
    key: string
  ): ExerciseStatementCardProps[] => {
    const data = LocalStorageHelper.get(key);

    if (!data) {
      return statements;
    }

    data.forEach(({ id, scaledX, scaledY }) => {
      if (id && scaledX && scaledY) {
        const statement = statements.find(({ id: statementID }) => statementID === id);

        if (!statement) {
          return;
        }

        statement.scaledX = scaledX;
        statement.scaledY = scaledY;
        statement.placed = true;
      }
    });

    return statements;
  };
}

export default StatementCardsService;
