import { assign } from 'lodash';
import type { IObservableArray } from 'mobx';
import { action, computed, observable } from 'mobx';
import moment from 'moment';

import { AlignModel, MemberModel, OrganizationModel } from 'app/models';
import Model, { ModelJson } from 'app/models/Model';
import { ModelItem } from 'app/models/ModelItem';
import { ModelList } from 'app/models/ModelList';
import PulseTemplateModel from 'app/models/PulseTemplateModel';
import TeamGameModel from 'app/models/TeamGameModel';
import { TeamStore } from 'app/stores/TeamStore';

import allModels from './allModels';
import ExerciseTypeModel from './ExerciseTypeModel';

const OLD_ALIGN_MONTHS_THRESHOLD = 4;

export interface TeamProps {
  name?: string;
  organization_id?: number;
  manager?: any;
  secondary_manager?: any;
  group_ids?: number[];
  group_map?: { [division: number]: number };
}

export interface MinimalAlignResource {
  id: number;
  exercise_type_name: string;
  closed_at: string;
  created_at: string;
  is_closed: boolean;
}

export interface TeamGroupResource {
  id: number;
  name: string;
  team_group_division_id: number;
}

/**
 * TeamModel is used to represent Teams
 */
export class TeamModel extends Model {
  static _store: TeamStore;

  @observable id: number;
  @observable organization_id: number;
  @action setOrganizationID = (id: number): void => {
    this.organization_id = id;
  };

  @observable name: string;
  @observable group_ids?: number[];
  @observable code?: string;
  @observable created_at?: string;
  @observable invite_link?: string;
  @observable is_top_team?: boolean;
  @observable can_use_discussion_space?: boolean;
  @observable any_perspective_invites?: boolean;

  @observable currentUserCanManage?: boolean;
  @observable currentUserCanCoach?: boolean;
  @observable group_map: { [division: number]: number } = {};
  @observable manager_id?: number;
  @observable members_count?: number;
  @observable name_updated?: boolean;

  @observable membersWithPerspectiveCount?: number;
  @observable membersCount?: number;

  @observable pulses_count?: number;
  @observable pulses_draft_count?: number;
  @observable pulses_disabled_count?: number;
  @observable pulses_active_count?: number;
  @observable latestAlign?: MinimalAlignResource;
  @observable hasActivePulse?: boolean;
  @observable active_onboarding_link_count?: number;

  @observable team_groups?: TeamGroupResource[];

  @observable align = new ModelItem<AlignModel>(allModels.AlignModel);
  @observable aligns = new ModelList<AlignModel>(allModels.AlignModel);
  @observable all_aligns = new ModelList<AlignModel>(allModels.AlignModel);
  @observable members = new ModelList<MemberModel>(allModels.MemberModel);
  @observable newMembers = new ModelList<MemberModel>(allModels.MemberModel);
  @observable manager = new ModelItem<MemberModel>(allModels.MemberModel);
  @observable secondary_manager = new ModelItem<MemberModel>(allModels.MemberModel);
  @action setSecondaryManager = (manager) => assign(this.secondary_manager, manager);
  @observable organization = new ModelItem<OrganizationModel>(allModels.OrganizationModel);
  @observable exercises = new ModelList<AlignModel>(allModels.AlignModel);
  @observable pulse_templates = new ModelList<PulseTemplateModel>(PulseTemplateModel);
  @observable pulse_templates_disabled = new ModelList<PulseTemplateModel>(PulseTemplateModel);
  @observable exercise_types?: ExerciseTypeModel[] = [];
  @observable team_games? = new ModelList<TeamGameModel>(TeamGameModel);
  @observable can_view_team?: boolean;
  @observable is_manager_coach_disabled?: boolean;

  @observable completed_align_templates?: IObservableArray<{
    name: string;
    most_recent_exercise: {
      link: string;
      created_at: string;
    };
  }>;
  @observable completed_align_templates_count?: number;

  asJSON() {
    const { name, group_ids, organization_id } = this;

    if (Object.values(this.group_map).length > 0) {
      this.associateGroupMap();
    }

    return {
      name,
      group_ids,
      organization_id,
    };
  }

  @computed
  get allAligns() {
    return this.aligns.items.sort((a: AlignModel, b: AlignModel) => {
      return moment(b.created_at).unix() - moment(a.created_at).unix();
    });
  }

  @computed
  get recentAligns(): AlignModel[] {
    return this.aligns.items.filter(
      (align) =>
        !align.closed_at ||
        moment().diff(moment(align.closed_at), 'months') <= OLD_ALIGN_MONTHS_THRESHOLD
    );
  }

  @computed
  get membersById(): number[] {
    return this.members.items.reduce((acc, member) => {
      acc.push(member.id);
      return acc;
    }, []);
  }

  get mostRecentAlign(): AlignModel {
    return this.recentAligns?.[0];
  }

  get activeOnboardingLinkCount(): number {
    return this.active_onboarding_link_count;
  }

  associateGroupMap() {
    this.group_ids = Object.values(this.group_map);
  }

  isManager = (memberId: number): boolean => {
    const managerId = this.manager_id ? this.manager_id : this.manager?.item?.id;
    return managerId === memberId || this.secondary_manager?.item?.id === memberId;
  };

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeamModel;
  }

  static get(id) {
    return this._get(id) as TeamModel;
  }
}

allModels.register({ TeamModel });

export default TeamModel;
