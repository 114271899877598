import { ServerRouteHelper } from 'app/helpers';
import Model from 'app/models/Model';
import Store from 'app/stores/Store';

export class MailStore extends Store<Model> {
  sendEmailToMembers(teamId, emails: any[], message: string, subject: string): Promise<any> {
    const url = ServerRouteHelper.api.teams.sendEmailToSelectedMembers(teamId);

    const config = {
      url,
      data: { emails, message, subject },
      throwError: true,
    };

    return this.apiService.newPost(config);
  }
}

export default MailStore;
