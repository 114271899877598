import React, { ReactNode } from 'react';

import { Breadcrumb } from 'antd';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import numeral from 'numeral';
import { Link, RouteComponentProps, Switch } from 'react-router-dom';

import { STORE_ADMIN_ORG_CONTENT } from 'app/constants';
import Route from 'app/customRoute';
import { preventDefault, ServerRouteHelper } from 'app/helpers';
import { AdminOrgContentStore } from 'app/stores';

import OrgContentOrgItems from './OrgContentOrgItems';
import OrgContentOrgList from './OrgContentOrgList';

export interface OrgContentProps extends RouteComponentProps {
  adminOrgContentStore?: AdminOrgContentStore;
}

export class OrgContent extends React.Component<OrgContentProps> {
  get title(): string {
    return 'Org Content Overrides';
  }

  @computed
  get breadcrumb(): ReactNode {
    return (
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item key="home-welcome" href={ServerRouteHelper.admin.home()}>
          Admin
        </Breadcrumb.Item>
        <Breadcrumb.Item key="insight-stats">
          <Link to={ServerRouteHelper.admin.orgContent.list()}>{this.title}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  protected handleSubmit = (event) => {
    const form = new FormData(event.target);
    const id = form.get('id').toString();

    const url = ServerRouteHelper.admin.orgContent.edit(numeral(id).value());
    this.props.history.push(url);

    event.target.reset();
  };

  public render(): ReactNode {
    const { match } = this.props;

    return (
      <div className="col pb-4 px-4 px-md-5">
        {this.breadcrumb}

        <div className="insight-stats widget">
          <div className="widget__title d-flex justify-content-between align-items-center">
            <h1 className="d-inline-block mr-1 mb-0">{this.title}</h1>
            <div className="widget__actions">
              <form onSubmit={preventDefault(this.handleSubmit)}>
                <div className="form-group">
                  <div className="form-inline">
                    <input
                      className="form-control"
                      placeholder="Org ID"
                      name="id"
                      type="number"
                      min="1"
                    />
                    <button className="btn btn-primary ml-2" name="submit">
                      Go To Org
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="widget__body p-4 overflow-auto">
            <Switch>
              <Route exact path={`${match.path}`} component={OrgContentOrgList} />;
              <Route path={`${match.path}/:orgId`} component={OrgContentOrgItems} />;
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(STORE_ADMIN_ORG_CONTENT)(observer(OrgContent));
