import React, { ReactNode } from 'react';

import { Button, Card, Divider, Form, Input } from 'antd';
import { startsWith, uniqueId } from 'lodash';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from '../PulseTemplates.module.scss';

import { PulseStatementStiType, STORE_ADMIN_PULSE_TEMPLATES } from 'app/constants';
import { PulseStatementModel, PulseTemplateModel } from 'app/models';
import { AdminPulseTemplateStore } from 'app/stores';

import InputStatementForm from './InputStatementForm';
import StatementForm from './StatementForm';

export const TMP_PULSE_STATEMENT_ID_PREFIX = 'tmp_pulse_statement';

export interface TemplateCategoryProps {
  adminPulseTemplateStore?: AdminPulseTemplateStore;
  statements: PulseStatementModel[];
  category: PulseTemplateModel;
}

export class TemplateCategory extends React.Component<TemplateCategoryProps> {
  @observable private categoryTitle: string = this.props.category.name;
  @action setCategoryTitle = (name) => (this.categoryTitle = name);

  @observable private isAddCategoryModalOpen: boolean;
  @action toggleIsAddCategoryModalOpen = () =>
    (this.isAddCategoryModalOpen = !this.isAddCategoryModalOpen);

  @observable statements: PulseStatementModel[] = this.props.statements;
  @action setStatements = (statements) => (this.statements = statements);

  @observable isUpdated = false;
  @action setIsUpdated = (isUpdated) => (this.isUpdated = isUpdated);

  get template(): PulseTemplateModel {
    return this.props.adminPulseTemplateStore?.currentTemplate.item;
  }

  get name(): string {
    return this.template?.name;
  }

  get scope(): string {
    return this.template?.scope || 'team_pulse';
  }

  get isDefault(): boolean {
    return this.template?.is_default;
  }

  deleteStatement = (index: number) => {
    const statements = this.statements;

    statements.splice(index, 1);
    this.setStatements(statements);
  };

  updateSelectedType = (type: string, index: number) => {
    const statements = this.statements;
    const statement = statements[index];

    statement.statement_type = type;
    statements.splice(index, 1, statement);
    this.setStatements(statements);
  };

  updateStatementText = (text: string, index: number) => {
    const statements = this.statements;
    const statement = statements[index];

    statement.text = text;
    statements.splice(index, 1, statement);
    this.setIsUpdated(true);
    this.setStatements(statements);
  };

  addStatement = (text, type) => {
    const statement = PulseStatementModel.fromJson({
      id: uniqueId(TMP_PULSE_STATEMENT_ID_PREFIX),
      text: text,
      type: PulseStatementStiType.Template,
      statement_type: type,
      order: this.statements.length,
    });

    this.statements.push(statement);

    this.setIsUpdated(true);
  };

  @computed
  get formattedValidStatements(): Partial<PulseStatementModel>[] {
    return this.statements.map((statement) => {
      const data = {
        id: statement.id,
        type: statement.type,
        statement_type: statement.statement_type,
        text: statement.text,
        order: statement.order,
      };

      if (startsWith(data.id.toString(), TMP_PULSE_STATEMENT_ID_PREFIX)) {
        delete data.id;
      }

      return data;
    });
  }

  updateCategory = () => {
    const category = this.props.category;
    category.name = this.categoryTitle;

    this.props.adminPulseTemplateStore.updateCategory(category, this.formattedValidStatements);

    this.setIsUpdated(false);
  };

  @computed
  get statementForms(): ReactNode {
    if (this.statements.length === 0) {
      return 'There are no Statements under this category.';
    }

    return this.statements.map((statement, index) => (
      <div className="d-flex m-2" key={index}>
        <StatementForm
          statement={statement}
          index={index}
          updateStatementText={this.updateStatementText}
          deleteStatement={this.deleteStatement}
          updateSelectedType={this.updateSelectedType}
        />
      </div>
    ));
  }

  public render(): ReactNode {
    return (
      <Card className="category">
        <div>
          <Form.Item label="Category Name">
            <Input
              className={styles.categoryName}
              value={this.categoryTitle}
              onChange={(e) => {
                this.setCategoryTitle(e.target.value);
                this.setIsUpdated(true);
              }}
            />
          </Form.Item>

          {this.isUpdated && (
            <Button type="primary" className={styles.saveBtn} onClick={this.updateCategory}>
              Save
            </Button>
          )}
        </div>

        <Divider />

        <div className="statements">
          <p> Statements</p>
          {this.statementForms}

          <Divider />
          <InputStatementForm addStatement={this.addStatement} />
        </div>
      </Card>
    );
  }
}

export default inject(STORE_ADMIN_PULSE_TEMPLATES)(observer(TemplateCategory));
