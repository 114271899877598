export const PULSE_STATEMENT_SCALE_RANGE = [-3, -2, -1, 0, 1, 2, 3];
export const PULSE_STATEMENT_BINARY_RANGE = [0, 1];

export enum PulseStatementType {
  Text = 'text',
  Scale = 'scale',
  Binary = 'binary',
  Header = 'header',
}

export const PulseStatementOptions = [
  {
    type: 'scale',
    name: 'Range',
  },
  {
    type: 'binary',
    name: 'Binary',
  },
  {
    type: 'text',
    name: 'Open text',
  },
];

export const PulseStatementOptionsNames = {
  scale: 'Range',
  binary: 'Binary',
  text: 'Open text',
};

export enum PulseStatementStiType {
  Template = 'template',
  Statement = 'statement',
}

export const enum MembershipType {
  Solo = 'solo',
  Group = 'group',
}

export const MembershipTypeOptions = [
  { label: 'Only me', value: MembershipType.Solo },
  { label: 'Me and others', value: MembershipType.Group },
];

export enum PulseStepKey {
  Add = 'add',
  Invite = 'invite',
  Configure = 'configure',
  Review = 'review',
  TestDriveListing = 'listing', // Not a regular step in the builder but the final step in a test drive flow
}
