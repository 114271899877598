export const UNIVERSAL_CONTENT_KEY = 'u-content';

export const CONTENT_KEYS = {
  PERSONAL_ANOMALY: 'personal-anomaly',
  ALIGN_GUIDANCE: 'align-guidance',
  PERSONAL_PERSPECTIVE_BREAKDOWN: 'personal-perspective-breakdown',
  PAIR_PERSPECTIVE_LENSES_DETAILS: 'pair-perspective-lenses-details',
  TEAM_PERSPECTIVE_DISCUSSION: 'team-perspective-discussion',
  TEAM_PERSPECTIVE_MANAGER_INSIGHTS: 'team-perspective-manager-insights',
  TEAM_PERSPECTIVE_LENS_BREAKDOWN: 'team-perspective-lens-breakdown',
};
