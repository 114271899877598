import axios from 'axios';
import { action, computed, observable, reaction } from 'mobx';

import ServerRouteHelper from 'app/helpers/ServerRouteHelper';
import {
  AlignReportModel,
  DiscussionGuideModel,
  DiscussionGuideStepModel,
  DiscussionGuideTypeModel,
  MemberModel,
  PulseForTeamModel,
  TeamModel,
} from 'app/models';
import { ShiftStore } from 'app/stores/ShiftStore';
import { ShiftStoreResponse } from 'app/stores/ShiftStore';
import { ShiftStoreRequestOptionMethods } from 'app/stores/ShiftStore';

export enum DiscussionGuideStoreKeys {
  CurrentDiscussionGuide = 'CURRENT_DISCUSSION_GUIDE',
  NewGuide = 'NEW_GUIDE',
  SaveGuideType = 'SAVE_GUIDE_TYPE',
  SaveLastPage = 'SAVE_LAST_PAGE',
  LaunchGuide = 'LAUNCH_GUIDE',
}

/**
 * A store for the current DiscussionGuide
 */
export class DiscussionGuideStore extends ShiftStore {
  @observable currentDiscussionGuide: DiscussionGuideModel;
  @observable exerciseID: number;
  @observable pulse: PulseForTeamModel;
  @observable commitments: string;
  @observable state: string;
  @observable errors = null;
  @observable savingInProgress = false;
  @observable saveSuccess = false;
  @observable team: TeamModel;
  @observable teamManager: MemberModel;
  @observable teamMembers: MemberModel[];
  @observable alignReport: AlignReportModel;
  @observable isLaunching = false;
  @observable types: DiscussionGuideTypeModel[] = [];
  @observable steps: DiscussionGuideStepModel[] = [];
  @observable isLoading = true;
  @action setLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading;
  };

  habitQuestionDisposer: () => void;
  discussionGuideDisposer: () => void;

  @computed
  get hasPulse(): boolean {
    return !!this.pulse;
  }

  @action setPulse = (pulse: PulseForTeamModel) => {
    this.pulse = pulse;
  };

  /**
   * Load from Exercise ID using API call.
   */
  @action
  async load(alignId: number) {
    const storeKey = DiscussionGuideStoreKeys.CurrentDiscussionGuide;

    // Dont call the API if we have already been loaded
    if (this.isLoaded(storeKey)) {
      return;
    }

    // Set pending flag for mobx
    this.setPending(storeKey);

    // set loading
    this.setLoading(true);

    // Fetch data
    const url = ServerRouteHelper.api.discussionGuides.get(alignId);
    const response = await axios.get(url.toString());

    const data = response.data.data;
    const meta = response.data.meta;

    this.setData(data, meta);

    // Let mobx know we done loading
    this.setLoaded(storeKey);
    this.setLoading(false);

    // ensure that when one of the statement questions
    // changed/added/removed we update the checkin
    this.setupDiscussionGuideDisposer();
  }

  /**
   * Load from Exercise ID using API call.
   */
  @action
  async loadTestDrive(exerciseId: number) {
    const storeKey = DiscussionGuideStoreKeys.CurrentDiscussionGuide;

    // Dont call the API if we have already been loaded
    if (this.isLoaded(storeKey)) {
      return;
    }

    // Set pending flag for mobx
    this.setPending(storeKey);

    // set loading
    this.setLoading(true);

    // Fetch data
    const url = ServerRouteHelper.api.discussionGuides.getTestDrive(exerciseId);
    const response = await axios.get(url.toString());
    const data = response.data.data;
    const meta = response.data.meta;

    this.setData(data, meta);

    // Let mobx know we done loading
    this.setLoaded(storeKey);
    this.setLoading(false);

    // ensure that when one of the statement questions
    // changed/added/removed we update the checkin
    this.setupDiscussionGuideDisposer();
  }

  /**
   * Populate store models using supplied data.
   */
  @action
  public setData(data, config?: any) {
    // Set meta config props
    if (!!config) {
      this.setGuideConfig(config);
    }

    // Extract data from response and build dependent models
    this.exerciseID = data.exercise.id;
    this.pulse = data.pulse;

    if (!(data.pulse instanceof PulseForTeamModel)) {
      this.pulse = PulseForTeamModel.fromJson(this.pulse);
    }

    this.commitments = data.commitments;
    this.alignReport = new AlignReportModel(data.exercise);
    this.teamManager = MemberModel.fromJson(data.teamManager);
    this.teamMembers = data.teamMembers.map((member) => MemberModel.fromJson(member));
    this.team = TeamModel.fromJson(data.team);

    // Build our model
    const modelData = {
      ...data,
      pulse: this.pulse,
      alignReport: this.alignReport,
      lastPage: data.last_page,
    };

    this.currentDiscussionGuide = new DiscussionGuideModel(modelData as any);
  }

  /**
   * Set config based on raw meta data.
   */
  @action
  protected setGuideConfig(metaData) {
    this.types = Object.keys(metaData.types).map((key) => {
      return new DiscussionGuideTypeModel({
        id: key,
        ...metaData.types[key],
      });
    });

    this.steps = Object.keys(metaData.steps).map((key) => {
      return new DiscussionGuideStepModel({
        id: key,
        ...metaData.steps[key],
      });
    });
  }

  protected setupDiscussionGuideDisposer() {
    const options = { delay: 30000 };
    const dataFn = () => this.currentDiscussionGuide.toJS;
    const effectFn = () => this.saveDiscussionGuide();

    this.discussionGuideDisposer = reaction(dataFn, effectFn, options);
  }

  async saveDiscussionGuide() {
    const isLoadedGuide = this.isLoaded(DiscussionGuideStoreKeys.CurrentDiscussionGuide);

    // Don't execute on test drive or when guide is loading
    if (!isLoadedGuide || this.currentDiscussionGuide?.is_test_drive) {
      return;
    }

    // Check that another save is not in progress
    if (this.savingInProgress) {
      return;
    }

    this.savingInProgress = true;
    this.saveSuccess = false;
    this.errors = null;

    const data = this.currentDiscussionGuide.toJS;

    try {
      await axios.patch(
        ServerRouteHelper.api.discussionGuides.update(this.currentDiscussionGuide.id).toString(),
        data
      );
      this.saveSuccess = true;
    } catch (err) {
      this.errors = err.response && err.response.body && err.response.body.errors;
      throw err;
    }

    this.savingInProgress = false;
  }

  public async saveLastPage(page: string) {
    // Don't execute on test drive
    const isLoadedGuide = this.isLoaded(DiscussionGuideStoreKeys.CurrentDiscussionGuide);

    if (!isLoadedGuide || this.currentDiscussionGuide?.is_test_drive) {
      return;
    }

    await this.request({
      key: DiscussionGuideStoreKeys.SaveLastPage,
      id: 'current',
      url: ServerRouteHelper.api.discussionGuides.update(this.currentDiscussionGuide.id),
      method: ShiftStoreRequestOptionMethods.PATCH,
      params: { last_page: page },
      onSuccess: (response: ShiftStoreResponse) => {
        this.currentDiscussionGuide.lastPage = page;
      },
      errorMessage: 'Unable to update last page.',
    });
  }

  public async launch() {
    this.savingInProgress = true;

    // Don't execute on test drive
    if (this.currentDiscussionGuide.is_test_drive) {
      this.savingInProgress = false;
      return;
    }

    await this.request({
      key: DiscussionGuideStoreKeys.LaunchGuide,
      id: 'current',
      url: ServerRouteHelper.api.discussionGuides.launch(this.currentDiscussionGuide.id),
      method: ShiftStoreRequestOptionMethods.POST,
      params: {},
      onSuccess: (response: ShiftStoreResponse) => {
        const storeKey = DiscussionGuideStoreKeys.CurrentDiscussionGuide;

        // Set pending flag for mobx
        this.setPending(storeKey);

        // Update local state from server response
        const { data, meta } = response;
        this.setData(data, meta);

        // Let mobx know we done loading
        this.setLoaded(storeKey);
      },
      errorMessage: 'Unable to launch discussion guide.',
    });

    this.savingInProgress = false;
  }

  async newGuide(exerciseId: number, type: string) {
    let discussionGuide = null;
    await this.request({
      key: DiscussionGuideStoreKeys.NewGuide,
      method: ShiftStoreRequestOptionMethods.POST,
      url: ServerRouteHelper.api.discussionGuides.new(exerciseId),
      params: { guide_type: type },
      onSuccess: (response: ShiftStoreResponse) => {
        discussionGuide = new DiscussionGuideModel(response.data);
      },
      errorMessage: 'Unable to create new discussion guide. Please Try Again.',
    });
    return discussionGuide;
  }

  async saveGuideType(guideId: number, type: string) {
    // Don't execute on test drive
    if (this.currentDiscussionGuide.is_test_drive) {
      return;
    }

    let guideType = null;
    await this.request({
      key: DiscussionGuideStoreKeys.SaveGuideType,
      method: ShiftStoreRequestOptionMethods.PATCH,
      url: ServerRouteHelper.api.discussionGuides.type(guideId),
      params: { guide_type: type },
      onSuccess: (response: ShiftStoreResponse) => {
        guideType = response.guide_type?.[type].type;
      },
      errorMessage: 'Unable to save discussion guide type. Please Try Again.',
    });
    return guideType;
  }
}

export default DiscussionGuideStore;
