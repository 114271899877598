import { concat, each, groupBy, isNumber, keyBy, last, map, mean, omit, sortBy } from 'lodash';
import { action, computed, observable } from 'mobx';
import moment from 'moment';

import { InsightKeys } from 'app/constants';
import { LocalStorageHelper } from 'app/helpers';
import { ExerciseReportStore } from 'app/stores';

import AlignModel, { AlignProps } from './AlignModel';
import AnomalyHighlightModel, { AnomalyHighlightProps } from './AnomalyHighlightModel';
import BenchmarkModel, { BenchmarkProps } from './BenchmarkModel';
import { ExerciseAnswerModel, ExerciseAnswerProps } from './ExerciseAnswerModel';
import ExerciseCommitmentModel, { ExerciseCommitmentModelProps } from './ExerciseCommitmentModel';
import ExerciseStatementModel, { ExerciseStatementProps } from './ExerciseStatementModel';
import ExerciseThemeModel from './ExerciseThemeModel';
import ExerciseTypeModel, { ExerciseTypeProps } from './ExerciseTypeModel';
import MemberModel, { MemberProps } from './MemberModel';
import { PerspectiveDimensionIndex } from './MemberPerspectiveResultModel';
import { ModelList } from './ModelList';
import { OrganizationModel } from './OrganizationModel';
import PersonalStatementWithAnswerModel from './PersonalStatementWithAnswerModel';
import ShiftModel from './ShiftModel';
import TeamModel from './TeamModel';

const PERSONAL_BENCHMARK_LABEL = 'You';

export type ExerciseReportRoutes = { [key: string]: string };

export enum SortOption {
  MostCritical = 'critical',
  MostCriticalOthers = 'criticalOthers', // for my360
  HighestToLowest = 'desc',
  LowestToHighest = 'asc',
}

export enum BenchmarkGapSortOption {
  HighestToLowest = 'desc',
  LowestToHighest = 'asc',
  NoSort = 'none',
}

export enum FilterOption {
  None = 'none',
  AreasToCelebrate = 'celebrate',
  AreasToImprove = 'improve',
  AreasBetterThanYouThought = 'better',
  AreasWorseThanYouThought = 'worst',
  AreasWithFeedback = 'feedback',
}

export interface ExerciseReportPreviousExerciseProps {
  exercise: AlignProps;
  answers: ExerciseAnswerProps[];
}

// TODO: Too much interfaces, these should all be models!
// In fact there already exists for perspective but wasn't used here.

export interface ExerciseReportCommentGroup {
  question: string;
  statement: string;
  statement_id: number;
  comments: string[];
}

export interface StatementInsights {
  statement_id: number;
  title: string;
  subtitle: string;
  why: string;
  how: string;
  what: string;
  dimension: string;
}

export interface PerspectiveLens {
  binnedLensScore: string;
  binnedScore: string;
  lens: string;
  rawScore: string;
}

export interface PerspectiveDimension {
  dimension: PerspectiveDimensionIndex;
  dimensionDisplayName: string;
  lenses: PerspectiveLens[];
}

export interface DiggingIntoItContentItem {
  title: string;
  content: string;
}

export interface DiggingIntoItContent {
  id: number;
  title: string;
  items: DiggingIntoItContentItem[];
}

export interface AlignInsight {
  action_text?: string;
  align_statement_ids?: number[];
  description?: string;
  heading?: string;
  insights_text?: string;
  insight_type?: string;
  key: string;
  rank?: number;
  computed_rank?: number;
  section?: string;
  member_answer_map?: Record<number, number>;
  extreme_score_map?: Record<number, number>;
  digging_into_it?: Record<number, DiggingIntoItContent>;
}

export interface AlignInsightWithStatement extends AlignInsight {
  statement: ExerciseStatementModel;
}

export interface DataInsightsObject {
  individual: Record<number, AlignInsight[]>;
  team: GroupedAlignInsights;
}

export interface InsightContextItem {
  answer_ids: number[];
  extreme_score: number;
  statement_id: number;
}

export interface GroupedAlignInsights {
  celebrate: AlignInsight[];
  improve: AlignInsight[];
  insights: AlignInsight[];
  context: InsightContextItem[][];
  summary: AlignInsight[];
}

export interface DataInsights {
  insights: DataInsightsObject | GroupedAlignInsights | AlignInsight[];
  type: string;
}

export interface PerspectiveInsights {
  mbti: string;
  statements: StatementInsights[];
  new_insights: StatementInsights[];
}

export interface ExerciseReportProps {
  exercise: AlignProps;
  exercise_type: ExerciseTypeProps;

  organization?: OrganizationModel;
  team?: TeamModel;
  statements: ExerciseStatementProps[];
  answers: ExerciseAnswerProps[];

  report_member?: MemberProps;
  benchmarks: BenchmarkProps[];

  participants?: MemberProps[];
  participant_count?: number;
  non_participant_count?: number;
  non_participants?: MemberProps[];
  previous_exercises?: ExerciseReportPreviousExerciseProps[];
  anomaly_highlights?: AnomalyHighlightProps[];
  scatterplot_highlights?: any[];
  data_insights?: DataInsights;
  member_insights?: DataInsights;
  perspective_insights?: PerspectiveInsights;

  exercise_routes: ExerciseReportRoutes;
  can_manage_exercise: boolean;
  can_see_team: boolean;
  can_manage_team: boolean;
  can_see_Percentiles: boolean;
  is_admin: boolean;
  is_discussion_over: boolean;

  comments: { [key: string]: ExerciseReportCommentGroup };

  commitments?: ExerciseCommitmentModelProps[];
}

export class ExerciseReportModel extends ShiftModel<ExerciseReportProps> {
  @observable exercise: AlignModel;
  @observable exercise_type: ExerciseTypeModel;

  @observable commitments: ExerciseCommitmentModel[];

  @observable organization?: Partial<OrganizationModel>;
  @observable team?: TeamModel;
  @observable private _statements: ExerciseStatementModel[];
  @observable answers: ExerciseAnswerModel[];
  @observable aligns_run?: number;

  @observable reportMember?: MemberModel;
  @observable benchmarks: BenchmarkModel[];

  @observable participants?: MemberModel[];
  @observable nonParticipants?: MemberModel[];
  @observable participantCount?: number;
  @observable nonParticipantCount?: number;
  @observable previousReports?: ExerciseReportModel[];
  @observable anomalyHighlights?: AnomalyHighlightModel[];
  @observable scatterplotHighlights?: any[];
  @observable data_insights?: DataInsights;
  @observable member_insights?: DataInsights;
  @observable single_statement_insights?: DataInsights;
  @observable perspective_insights?: PerspectiveInsights;

  @observable public canManageExercise: boolean;
  @observable public canSeeTeam: boolean;
  @observable public canManageTeam: boolean;
  @observable public canSeePercentiles: boolean;
  @observable public isAdmin: boolean;
  @observable is_discussion_over: boolean;

  @observable comments: { [key: string]: ExerciseReportCommentGroup };

  @observable routes: ExerciseReportRoutes;
  @action setRoutes = (routes: ExerciseReportRoutes) => (this.routes = routes);

  @observable activeBenchmarkIndex = 0;
  @action setActiveBenchmarkIndex = (index) => (this.activeBenchmarkIndex = index);

  @observable previousExerciseId: number;
  @action setPreviousExerciseId = (id) => (this.previousExerciseId = id);

  @observable sortBy: SortOption | number;
  @action setSortBy = (sortBy) => {
    this.sortBy = sortBy;
  };

  @observable benchmarkGapSortBy: BenchmarkGapSortOption = BenchmarkGapSortOption.NoSort;
  @action setBenchmarkGapSortBy = (benchmarkGapSortBy) => {
    this.benchmarkGapSortBy = benchmarkGapSortBy;
  };

  @observable filterBy: FilterOption | number;
  @action setFilterBy = (filterBy) => {
    this.filterBy = filterBy;
  };

  @observable isLoadingStatementPercentiles: boolean;
  @action setIsLoadingStatementPercentiles = (v: boolean) =>
    (this.isLoadingStatementPercentiles = v);

  @observable hasLoadedStatementPercentiles: boolean;
  @action setHasLoadedStatementPercentiles = (v: boolean) =>
    (this.hasLoadedStatementPercentiles = v);

  @observable isTestDrive = false;
  @action setIsTestDrive = (isTestDrive) => (this.isTestDrive = isTestDrive);

  _token: string;
  _store: ExerciseReportStore;

  constructor(props: ExerciseReportProps, store?: ExerciseReportStore, token?: string) {
    // we use omit to silence TS2376
    // @ts-ignore
    super(omit(props, ['statements']));

    const {
      organization,
      exercise,
      exercise_type,
      team,
      statements,
      answers,
      report_member,
      benchmarks,
      participants,
      non_participants,
      previous_exercises,
      anomaly_highlights,
      commitments,
    } = props;

    this.organization = OrganizationModel.fromJson(organization);
    this.exercise = AlignModel.fromJson(exercise);
    this.exercise_type = ExerciseTypeModel.fromJson(exercise_type);
    this.team = TeamModel.fromJson(team);

    this.benchmarks = benchmarks?.map(
      (benchmark) => new BenchmarkModel(benchmark, this.exercise_type)
    );

    this.benchmarks.unshift(new BenchmarkModel({ name: '- No benchmark -' }));

    // set default sort depending on exercise type
    this.resetSortBy();

    this._statements = statements?.map((statement) => new ExerciseStatementModel(statement, this));
    this.answers = answers?.map((answer) => new ExerciseAnswerModel(answer, this.exercise_type));

    if (participants) {
      this.participants = participants.map((participant) => MemberModel.fromJson(participant));
    }

    if (non_participants) {
      this.nonParticipants = non_participants.map((participant) =>
        MemberModel.fromJson(participant)
      );
    }

    if (report_member) {
      this.reportMember = MemberModel.fromJson(report_member);
    }

    this.previousReports = [];
    if (previous_exercises) {
      this.previousReports = previous_exercises.map((previousExercise) => {
        const { exercise, answers, previous_exercises, ...otherProps } = props;

        return new ExerciseReportModel({
          ...previousExercise,
          previous_exercises: [],
          ...otherProps,
        });
      });
    }

    if (anomaly_highlights) {
      this.anomalyHighlights = anomaly_highlights?.map?.(
        (anomalyHighlight) => new AnomalyHighlightModel(anomalyHighlight)
      );
    }

    if (commitments) {
      this.commitments = commitments.map((commitment) =>
        ExerciseCommitmentModel.fromJson(commitment)
      );
    }

    this.canManageExercise = props.can_manage_exercise;
    this.canSeeTeam = props.can_see_team;
    this.canManageTeam = props.can_manage_team;
    this.canSeePercentiles = props.can_see_Percentiles;
    this.isAdmin = props.is_admin;

    this.participantCount = props.participant_count;
    this.nonParticipantCount = props.non_participant_count;

    this.scatterplotHighlights = props.scatterplot_highlights;

    this.setRoutes({
      personalInsights: props.exercise_routes?.personal_insights,
      personalReport: props.exercise_routes?.personal_report,
      teamInsights: props.exercise_routes?.team_insights,
      discussion: props.exercise_routes?.discussion,
      commitments: props.exercise_routes?.commitments,
    });

    this._store = store;
    this._token = token;
  }

  @action
  loadTestDriveAnswers() {
    this.setIsTestDrive(true);

    this.statements.forEach(({ id }) => {
      const cachedAnswer = LocalStorageHelper.get(`statement-exercise-${id}`);
      if (cachedAnswer) {
        const answer = new ExerciseAnswerModel({
          align_statement_id: cachedAnswer.id,
          coordinate_x: cachedAnswer.x,
          coordinate_y: cachedAnswer.y,
          member_id: this.reportMember?.id,
        });
        this.answers.push(answer);
      }
    });
  }

  @action
  setPreviousExerciseByIndex(index: number) {
    const previousReport = this.previousReports[index];

    if (previousReport) {
      this.setPreviousExerciseId(previousReport.exercise.id);
    }
  }

  @action
  setStatementPercentiles(statements: ExerciseStatementProps[]) {
    const keyedStatements = keyBy(statements, ({ id }) => id);

    this.statements.forEach((statement) => {
      const { percentiles } = keyedStatements[statement.id];
      statement.percentiles = percentiles;
    });

    this.setHasLoadedStatementPercentiles(true);
    this.setIsLoadingStatementPercentiles(false);
  }

  @action
  adminLoadStatementPercentiles() {
    if (this.isLoadingStatementPercentiles || this.hasLoadedStatementPercentiles) {
      return;
    }

    this.setIsLoadingStatementPercentiles(true);
    this._store.adminGetStatementPercentilesForReport(this._token, this);
  }

  resetActiveBenchmark() {
    this.setActiveBenchmarkIndex(0);
    this.resetBenchmarkGapSortBy();
  }

  resetBenchmarkGapSortBy() {
    this.setBenchmarkGapSortBy(BenchmarkGapSortOption.NoSort);
  }

  resetSortBy() {
    if (this.exercise?.is_360) {
      this.setSortBy(SortOption.HighestToLowest);
      return;
    }

    this.setSortBy(SortOption.MostCritical);
  }

  @computed
  get noBenchmarkSelected(): boolean {
    return this.activeBenchmarkIndex === 0;
  }

  @computed
  get activeTheme(): ExerciseThemeModel {
    return isNumber(this.sortBy) ? this.exercise_type.themesById[this.sortBy] : null;
  }

  @computed
  get isCC(): boolean {
    return this.exercise_type?.isCompassionateConversationExercise;
  }

  @computed
  get activeBenchmark(): BenchmarkModel {
    // Return benchmark if index is within range because it could
    // go past it if the selected benchmark from dropdown is previous exercise.
    const benchmark =
      this.benchmarks.length > this.activeBenchmarkIndex
        ? this.benchmarks[this.activeBenchmarkIndex]
        : null;

    return benchmark && !benchmark?.isPlaceholder ? benchmark : null;
  }

  @computed
  get activeBenchmarkLabel(): string {
    if (this.benchmarks[this.activeBenchmarkIndex]?.isPlaceholder) {
      return null;
    }

    const benchmarkOption =
      this.benchmarkOptions.length > this.activeBenchmarkIndex
        ? this.benchmarkOptions[this.activeBenchmarkIndex]
        : null;

    return benchmarkOption.label;
  }

  /**
   * Returns a map of answer arrays keyed by statement id
   */

  @computed
  get answersByStatementID(): { [statementID: number]: ExerciseAnswerModel[] } {
    return groupBy(this.answers, (answer: ExerciseAnswerModel) => answer.align_statement_id);
  }

  @computed
  get answersByID(): { [answerID: number]: ExerciseAnswerModel } {
    return keyBy(this.answers, (answer: ExerciseAnswerModel) => answer.id);
  }

  @computed
  get previousAnswersByStatementID(): {
    [statementID: number]: ExerciseAnswerModel[];
  } {
    return groupBy(
      this.previousReportAnswers,
      (answer: ExerciseAnswerModel) => answer.align_statement_id
    );
  }

  @computed
  get otherAnswers() {
    if (!this.reportMember) {
      return this.answers;
    }

    return this.answers.filter(
      (answer: ExerciseAnswerModel) => answer.member_id !== this.reportMember.id
    );
  }

  @computed
  get otherAnswersByStatementID(): {
    [statementID: number]: ExerciseAnswerModel[];
  } {
    return groupBy(this.otherAnswers, (answer: ExerciseAnswerModel) => answer.align_statement_id);
  }

  @computed
  get personalAnswers(): ExerciseAnswerModel[] {
    if (!this.reportMember) {
      return [];
    }

    return this.answers.filter(
      (answer: ExerciseAnswerModel) => answer.member_id === this.reportMember?.id
    );
  }

  @computed
  get previousReportAnswers(): ExerciseAnswerModel[] {
    return this.previousReportsById[this.previousExerciseId]?.answers;
  }

  @computed
  get personalAnswersByStatementID(): {
    [statementID: number]: ExerciseAnswerModel;
  } {
    return (
      this.personalAnswers &&
      keyBy(this.personalAnswers, (answer: ExerciseAnswerModel) => answer.align_statement_id)
    );
  }

  @computed
  get hasPersonalAnswers() {
    return (
      this.personalAnswersByStatementID && Object.keys(this.personalAnswersByStatementID).length > 0
    );
  }

  @computed
  get personalCompletedAt() {
    if (!this.hasPersonalAnswers) {
      return;
    }

    if (this.exercise.is_demo) {
      return moment().format('MMM DD, YYYY');
    }

    const finishedAt = this.personalAnswers[0].finished_at;
    return moment(finishedAt).format('MMM DD, YYYY');
  }

  /**
   * Returns sorted statements
   * We do this coz call to .sort() on observable array doesn't sort in-place unlike native array.
   * See https://mobx.js.org/refguide/array.html#observable-arrays
   */

  @computed
  get statements(): ExerciseStatementModel[] {
    return this.statementsWithSort(this.sortBy);
  }

  get perspectiveInsights() {
    return this.perspective_insights;
  }

  statementsWithSort(sortBy: number | SortOption): ExerciseStatementModel[] {
    if (!sortBy) {
      return this._statements;
    }

    // If there is an active benchmark, we need to sort by the benchmark
    if (!!this.activeBenchmark) {
      switch (this.benchmarkGapSortBy) {
        case BenchmarkGapSortOption.HighestToLowest:
          return this.statementsSortedByHighestToLowestBenchmarkScore;
        case BenchmarkGapSortOption.LowestToHighest:
          return this.statementsSortedByLowestToHighestBenchmarkScore;
      }
    }

    switch (sortBy) {
      case SortOption.MostCritical:
        return this.statementsSortedByScore;
      case SortOption.MostCriticalOthers:
        return this.statementsSortedByOtherScore;
      case SortOption.HighestToLowest:
        return this.statementsSortedByHighestToLowestScore;
      case SortOption.LowestToHighest:
        return this.statementsSortedByLowestToHighestScore;
      default:
        return this._statements.sort((a, b) => {
          const aIndex = this.activeTheme?.orderedSectionStatementIds.indexOf(a.id);
          const bIndex = this.activeTheme?.orderedSectionStatementIds.indexOf(b.id);
          return aIndex - bIndex;
        });
    }
  }

  @computed
  get statementsById(): { [id: number]: ExerciseStatementModel } {
    return keyBy(this.statements, (statement: ExerciseStatementModel) => statement.id);
  }

  @computed
  get singleStatementInsights(): Map<number, AlignInsight> {
    const map = new Map();

    each(this.single_statement_insights?.insights || [], (insight: AlignInsight) => {
      each(insight?.align_statement_ids, (id) => map.set(id, insight));
    });

    return map;
  }

  @computed
  get benchmarksWithoutYou() {
    return this.benchmarks.filter(({ name }) => name !== PERSONAL_BENCHMARK_LABEL);
  }

  @computed
  get isYouActiveBenchmark() {
    return this.activeBenchmark && this.activeBenchmark.name === PERSONAL_BENCHMARK_LABEL;
  }

  @computed
  get showYouBenchmark() {
    return !!this.benchmarks.find(({ name }) => name === PERSONAL_BENCHMARK_LABEL);
  }

  /**
   * Gets statements sorted by their score
   */

  @computed
  get statementsSortedByScore() {
    return this._statements.sort((a, b) => {
      const aScore = this.isYouActiveBenchmark ? a.personalScore : a.score;
      const bScore = this.isYouActiveBenchmark ? b.personalScore : b.score;
      return aScore - bScore;
    });
  }

  /**
   * Statement sorted by the lowest score
   */
  @computed
  get statementsSortedByHighestToLowestScore() {
    return this._statements.sort((a, b) => {
      let aScore = this.exercise?.is_360 ? a.othersScoreScaled : a.scoreScaled;
      if (this.prevScore) {
        aScore = aScore - this.prevScore;
      }

      let bScore = this.exercise?.is_360 ? b.othersScoreScaled : b.scoreScaled;
      if (this.prevScore) {
        bScore = bScore - this.prevScore;
      }

      return Math.abs(bScore) - Math.abs(aScore);
    });
  }

  /**
   * Statement sorted by the lowest to highest
   */
  @computed
  get statementsSortedByLowestToHighestScore() {
    return this._statements.sort((a, b) => {
      let aScore = this.exercise?.is_360 ? a.othersScoreScaled : a.scoreScaled;
      if (this.prevScore) {
        aScore = aScore - this.prevScore;
      }

      let bScore = this.exercise?.is_360 ? b.othersScoreScaled : b.scoreScaled;
      if (this.prevScore) {
        bScore = bScore - this.prevScore;
      }

      return Math.abs(aScore) - Math.abs(bScore);
    });
  }

  /**
   * Gets statements sorted by other score.
   */

  @computed
  get statementsSortedByOtherScore() {
    return this._statements.sort((a, b) => {
      const aScore = a.othersScore;
      const bScore = b.othersScore;
      return aScore - bScore;
    });
  }

  /**
   * Gets statements sorted by their personal score
   */

  @computed
  get statementsSortedByPersonalScore() {
    if (!this.hasPersonalAnswers) {
      return [];
    }

    return this._statements.sort((a, b) => a.personalScore - b.personalScore);
  }

  /**
   * Sort statements by benchmark score in descending order
   */
  @computed
  get statementsSortedByHighestToLowestBenchmarkScore() {
    return this._statements.sort((a, b) => {
      let aScore = a.scoreScaled;
      if (this.activeBenchmark && !this.isYouActiveBenchmark) {
        const benchmarkScore = a.benchmarkScores[this.activeBenchmarkIndex];
        aScore = aScore - ((benchmarkScore && benchmarkScore.scaledScore) || 0);
      }

      let bScore = b.scoreScaled;
      if (this.activeBenchmark && !this.isYouActiveBenchmark) {
        const benchmarkScore = b.benchmarkScores[this.activeBenchmarkIndex];
        bScore = bScore - ((benchmarkScore && benchmarkScore.scaledScore) || 0);
      }

      return Math.abs(bScore) - Math.abs(aScore);
    });
  }

  /**
   * Sort statements by benchmark score in ascending order.
   */
  @computed
  get statementsSortedByLowestToHighestBenchmarkScore() {
    return this._statements.sort((a, b) => {
      let aScore = a.scoreScaled;
      if (this.activeBenchmark && !this.isYouActiveBenchmark) {
        const benchmarkScore = a.benchmarkScores[this.activeBenchmarkIndex];
        aScore = aScore - ((benchmarkScore && benchmarkScore.scaledScore) || 0);
      }

      let bScore = b.scoreScaled;
      if (this.activeBenchmark && !this.isYouActiveBenchmark) {
        const benchmarkScore = b.benchmarkScores[this.activeBenchmarkIndex];
        bScore = bScore - ((benchmarkScore && benchmarkScore.scaledScore) || 0);
      }

      return Math.abs(aScore) - Math.abs(bScore);
    });
  }

  /**
   * Returns statements grouped by classification
   * Classes are IMPROVE, DISCUSS, CELEBRATE
   */

  @computed
  get statementsByClassification() {
    return this.statementsByClassificationWithSort(this.sortBy);
  }

  statementsByClassificationWithSort(sortBy?: SortOption | number) {
    const statements = sortBy ? this.statementsWithSort(sortBy) : this.statements;
    return groupBy(statements, (statement: ExerciseStatementModel) => {
      const classification = statement.classification;
      return classification || 'generic';
    });
  }

  /**
   * Returns statements grouped by classification
   * Classes are IMPROVE, DISCUSS, CELEBRATE
   */

  @computed
  get statementsByPersonalClassification() {
    if (!this.hasPersonalAnswers) {
      return {};
    }

    return groupBy(
      this.statements,
      (statement: ExerciseStatementModel) => statement.personalClassification
    );
  }

  /**
   * A scaler function that converts our Shift Exercise Score to a standard
   * scale between 1-100. Takes into account custom cutoffs for exercise types.
   */

  @computed
  get scaleScore() {
    return this.exercise_type.scaleScore;
  }

  /**
   * A classification function that determines the classification of a Shift Exercise
   * score depending on the score cutoffs for the exercise type
   */

  @computed
  get classifyScore() {
    return this.exercise_type.classifyScore;
  }

  @computed
  get score() {
    return mean(this.statements.map((statement) => statement.score));
  }

  @computed
  get previousReportScore() {
    return mean(
      this.previousReports &&
        this.previousReportsById[this.previousExerciseId] &&
        this.previousReportsById[this.previousExerciseId].statements.map(
          (statement) => statement.score
        )
    );
  }

  @computed
  get scoreScaled() {
    return this.scaleScore(this.score);
  }

  @computed
  get othersScore() {
    return mean(this.statements.map((statement) => statement.othersScore));
  }

  @computed
  get othersScoreScaled() {
    return this.scaleScore(this.othersScore);
  }

  @computed
  get personalScore() {
    if (!this.hasPersonalAnswers) {
      return;
    }

    return mean(this.statements.map((statement) => statement.personalScore));
  }

  @computed
  get personalScoreScaled() {
    if (!this.hasPersonalAnswers) {
      return;
    }

    return this.scaleScore(this.personalScore);
  }

  @computed
  get shiftScore() {
    const value = Math.floor(this.scoreScaled) / 10.0;

    if (value < 0) {
      return 0.0;
    }
    if (value > 10) {
      return 10.0;
    }

    return value;
  }

  @computed
  get personalShiftScore() {
    if (!this.hasPersonalAnswers) {
      return;
    }

    const value = Math.floor(this.personalScoreScaled) / 10.0;

    if (value < 0) {
      return 0.0;
    }
    if (value > 10) {
      return 10.0;
    }

    return value;
  }

  @computed
  get participantsByID() {
    return this.participants && keyBy(this.participants, 'id');
  }

  @computed
  get participantsByMemberRole(): { [member_role: string]: MemberModel[] } {
    return groupBy(this.participants ?? [], (member: MemberModel) => member.member_role);
  }

  @computed
  get benchmarkOptions() {
    let benchmarks = this.benchmarks.map((benchmark, i) => {
      return {
        label: benchmark.name,
        value: 'benchmark-' + i,
      };
    });

    const previousReports = this.previousReports.map((previousReport, i) => {
      const previousReportMenuIndex = benchmarks.length + i;

      return {
        label: moment(previousReport.exercise.created_at).format('MMMM YYYY'),
        value: `previousReportOption-${previousReportMenuIndex}-${i}`,
      };
    });

    if (!this.showYouBenchmark) {
      benchmarks = benchmarks.filter(({ label }) => label !== PERSONAL_BENCHMARK_LABEL);
    }

    return concat(benchmarks, previousReports);
  }

  @computed
  get sortOptions() {
    let options = [];
    const themeNames = (this.exercise_type.exercise_themes || []).map((theme) => {
      return { label: theme.title, value: theme.id };
    });

    if (themeNames.length !== 0) {
      options = options.concat(themeNames);
    }

    options = options.concat(this.getOrderingOptions());

    return options;
  }

  getOrderingOptions(): any[] {
    return [
      {
        label: 'Highest to lowest',
        value: SortOption.HighestToLowest,
      },
      {
        label: 'Lowest to highest',
        value: SortOption.LowestToHighest,
      },
    ];
  }

  @computed
  get filterOptions() {
    return [
      { label: 'None', value: FilterOption.None },
      { label: 'Areas to Celebrate', value: FilterOption.AreasToCelebrate },
      { label: 'Areas to Improve', value: FilterOption.AreasToImprove },
      { label: 'Areas better than you thought', value: FilterOption.AreasBetterThanYouThought },
      { label: 'Areas worse than you thought', value: FilterOption.AreasWorseThanYouThought },
      { label: 'Areas with feedback', value: FilterOption.AreasWithFeedback },
    ];
  }

  @computed
  get prevScore() {
    if (!this.previousReport) {
      return null;
    }

    return this.exercise?.is_360
      ? this.previousReport.othersScoreScaled
      : this.previousReport.scoreScaled;
  }

  @computed
  get previousReport(): ExerciseReportModel {
    return this.previousExerciseId ? this.previousReportsById[this.previousExerciseId] : null;
  }

  @computed
  get previousReportsById(): { [id: number]: ExerciseReportModel } {
    return this.previousReports ? keyBy(this.previousReports, 'exercise.id') : {};
  }

  @computed
  get benchmarksById(): { [id: number]: BenchmarkModel } {
    return keyBy(this.benchmarks, (benchmark: BenchmarkModel) => benchmark.id);
  }

  @computed
  get groupedStatements() {
    const { statementsSortedByScore } = this;

    const morePositiveStatements: ExerciseStatementModel[] = [];
    const moreNegativeStatements: ExerciseStatementModel[] = [];
    const bothNegativeStatements: ExerciseStatementModel[] = [];
    const bothPositiveStatements: ExerciseStatementModel[] = [];

    statementsSortedByScore.forEach((statement) => {
      if (statement.isMorePositive) {
        morePositiveStatements.push(statement);
      } else if (statement.isMoreNegative) {
        moreNegativeStatements.push(statement);
      } else if (statement.isBothPositive) {
        bothPositiveStatements.push(statement);
      } else if (statement.isBothNegative) {
        bothNegativeStatements.push(statement);
      }
    });
    return {
      morePositiveStatements,
      moreNegativeStatements,
      bothNegativeStatements,
      bothPositiveStatements,
    };
  }

  @computed
  get mostPositiveStatement(): ExerciseStatementModel {
    const { morePositiveStatements } = this.groupedStatements;
    return morePositiveStatements?.[morePositiveStatements.length - 1];
  }

  @computed
  get mostNegativeStatement(): ExerciseStatementModel {
    const { moreNegativeStatements } = this.groupedStatements;
    return moreNegativeStatements?.[0];
  }

  @computed
  get memberInsights(): AlignInsight[] {
    return (this.member_insights?.insights ?? []) as AlignInsight[];
  }

  @computed
  get extremeSortedMemberInsights(): AlignInsightWithStatement[] {
    // For each raw insight, attach the matching extreme statement
    return this.memberInsights.map((insight) => {
      const alignStatementsRelatedToMember = insight.align_statement_ids.filter(
        (alignStatement) => {
          return this.personalAnswers.find((alignAnswer) => {
            return (
              alignAnswer.align_statement_id === alignStatement &&
              insight.member_answer_map[alignAnswer.id] === this.reportMember.id
            );
          });
        }
      );

      // Sort statement ids by their extremeness
      // score and only pick the most extreme one
      const mostExtremeStatementId = last(
        sortBy(alignStatementsRelatedToMember, (statementId) => {
          return insight.extreme_score_map?.[statementId];
        })
      );

      const statement = this.statementsById?.[mostExtremeStatementId];

      return { ...insight, statement };
    });
  }

  @computed
  get diggingIntoItMemberInsights(): AlignInsightWithStatement[] {
    return this.extremeSortedMemberInsights.filter((insight) => !!insight.digging_into_it);
  }

  hasMemberInsight = (lookupKey: InsightKeys): boolean => {
    return !!this.getMemberInsight(lookupKey);
  };

  getMemberInsight = (lookupKey: InsightKeys): AlignInsight => {
    return this.memberInsights.find(({ key }) => key === lookupKey);
  };

  @computed
  get personalStatementsWithAnswer(): ModelList<PersonalStatementWithAnswerModel> {
    const statementsWithAnswer = map(this.personalAnswersByStatementID, (answer, statementId) => {
      const statement = this.statementsById[statementId];
      return {
        id: statement.id,
        title: statement.text,
        scaledX: answer.coordinate_x,
        scaledY: answer.coordinate_y,
        placed: true,
        answer,
        classification: statement.classification,
        labels: statement.labels,
        points: statement.pointsWithPersonalAnswerHighlighted,
      };
    });

    const deserializedStatementsWithAnswers = new ModelList<PersonalStatementWithAnswerModel>(
      PersonalStatementWithAnswerModel
    );

    deserializedStatementsWithAnswers.deserialize(statementsWithAnswer);
    return deserializedStatementsWithAnswers;
  }

  @computed
  get safeScatterplotHighlights() {
    return this.scatterplotHighlights?.filter(
      (highlight) =>
        this.statements.filter(({ id }) => highlight?.align_statement_ids?.indexOf(id) !== -1)
          .length > 0
    );
  }
}
