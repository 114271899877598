import * as React from 'react';

import './styles.scss';

import { PRIVACY_POLICY_URL } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';

export interface AcceptTermsProps {
  subsToEduContentCheck: boolean;
  agreementCheck: boolean;
  onAgreementChange: () => void;
  onSubsToEduContentChange: () => void;
}

export const AcceptTerms: React.StatelessComponent<AcceptTermsProps> = ({
  agreementCheck,
  subsToEduContentCheck,
  onAgreementChange,
  onSubsToEduContentChange,
}) => (
  <div className="form-group my-4">
    <div className="form-check d-flex flex-column">
      <input
        type="checkbox"
        checked={agreementCheck}
        required
        name="agreementCheck"
        id="agreementCheck"
        onChange={onAgreementChange}
        className="form-check-input"
      />
      <label className=" label-text" htmlFor="agreementCheck">
        I agree to the&nbsp;
        <a target="_blank" href={ServerRouteHelper.agreements.terms()}>
          Terms of Use
        </a>
        &nbsp;and&nbsp;
        <a target="_blank" href={PRIVACY_POLICY_URL}>
          Privacy Policy
        </a>
      </label>
    </div>
    <div className="form-check">
      <input
        type="checkbox"
        checked={subsToEduContentCheck}
        onChange={onSubsToEduContentChange}
        id="subsToEduContentCheck"
        name="subsToEduContentCheck"
        className="form-check-input"
      />
      <label className="label-text" htmlFor="subsToEduContentCheck">
        Send me occasional content on being a leader (no spamming, we promise!)
      </label>
    </div>
  </div>
);
