import ShiftModel from './ShiftModel';

export interface AnomalyHighlightProps {
  key: string;
  text: string[];
}

export class AnomalyHighlightModel extends ShiftModel<AnomalyHighlightProps> {
  key: string;
  text: string[];
}

export default AnomalyHighlightModel;
