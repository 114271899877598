import SubscriptionItemProviderModel from 'app/models/SubscriptionItemProviderModel';
import Store from 'app/stores/Store';

export class SubscriptionItemProviderStore extends Store<SubscriptionItemProviderModel> {
  constructor() {
    super();
    SubscriptionItemProviderModel._store = this;
  }
}

export default SubscriptionItemProviderStore;
