import * as React from 'react';

import cx from 'classnames';
import { partial } from 'lodash';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import { ExerciseTypeModel } from 'app/models';

import ExerciseTypeEdit from './ExerciseTypeEdit';

export interface ExerciseTypeProps {
  exerciseType: ExerciseTypeModel;
  onMoveUp: (ExerciseTypeModel) => void;
  onMoveDown: (ExerciseTypeModel) => void;
  onRemove: (ExerciseTypeModel) => void;
}

export class ExerciseType extends React.Component<ExerciseTypeProps> {
  @observable isEditing = false;
  @action setIsEditing = (isEditing) => (this.isEditing = isEditing);

  handleEdit = (event) => {
    event.preventDefault();
    this.setIsEditing(!this.isEditing);
  };

  handleLabelChange = (event) => {
    const { exerciseType } = this.props;
    exerciseType.label = event.target.value;
  };

  handleStyleChange = (event) => {
    const { exerciseType } = this.props;
    exerciseType.style = event.target.value;
  };

  render() {
    const { exerciseType, onMoveUp, onMoveDown, onRemove } = this.props;

    return (
      <div className="card mb-1">
        <div className="card-body p-2">
          <div className="d-flex">
            <div className="mr-2">
              <img src={exerciseType.image} style={{ width: 50 }} />
            </div>
            <div>
              <h5 className="d-inline-block mb-0 mr-2">{exerciseType.name}</h5>
              <div className={cx('badge py-1 px-2', exerciseType.style)}>{exerciseType.label}</div>
              <p className="m-0 text-muted">{exerciseType.top_line}</p>
            </div>
            <div className="ml-auto d-flex align-items-center">
              <button data-testid="edit-btn" className="btn btn-sm mr-3" onClick={this.handleEdit}>
                <i className="far fa-pen-to-square" />
              </button>
              <button
                data-testid="moveup-btn"
                className="btn btn-sm"
                onClick={partial(onMoveUp, exerciseType)}
              >
                <i className="far fa-chevron-up" />
              </button>
              <button
                data-testid="movedown-btn"
                className="btn btn-sm mr-3"
                onClick={partial(onMoveDown, exerciseType)}
              >
                <i className="far fa-chevron-down" />
              </button>
              <button
                data-testid="remove-btn"
                className="btn btn-sm"
                onClick={partial(onRemove, exerciseType)}
              >
                <i className="far fa-xmark" />
              </button>
            </div>
          </div>

          {this.isEditing && (
            <ExerciseTypeEdit
              exerciseType={exerciseType}
              onLabelChange={this.handleLabelChange}
              onStyleChange={this.handleStyleChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default observer(ExerciseType);
