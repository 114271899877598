import { action, observable } from 'mobx';

import { MemberStateStore } from 'app/stores/MemberStateStore';

import Model, { ModelJson } from './Model';

export class MemberStateModel extends Model {
  static _store: MemberStateStore;
  @observable public num_teams: number;
  @observable public num_teams_managed: number;
  @observable public num_aligns: number;
  @observable public num_aligns_managed: number;
  @observable public seen_habit_works_modal: boolean;
  @observable public seen_team_improvement_modal: boolean;

  @action
  setNumTeamsManaged(numTeams: number) {
    this.num_teams_managed = numTeams;
  }

  @action
  setNumAlignsManaged(numAligns: number) {
    this.num_aligns_managed = numAligns;
  }

  @action
  setNumTeams(numTeams: number) {
    this.num_teams = numTeams;
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as MemberStateModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as MemberStateModel;
  }

  static get(id) {
    return this._get(id) as MemberStateModel;
  }
}
