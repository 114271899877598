import * as React from 'react';

import cx from 'classnames';

import { ExerciseTypeModel } from 'app/models';

const styles = [
  '',
  'bg--pastel-blue',
  'bg--pastel-green',
  'bg--pastel-indigo',
  'bg--pastel-purple',
  'bg--pastel-orange',
];

export interface ExerciseTypeEditProps {
  exerciseType: ExerciseTypeModel;
  onLabelChange: React.ChangeEventHandler;
  onStyleChange: React.ChangeEventHandler;
}

export const ExerciseTypeEdit: React.StatelessComponent<ExerciseTypeEditProps> = ({
  exerciseType,
  onLabelChange,
  onStyleChange,
}) => (
  <div data-testid="exercise-type-edit" className="p-2">
    <div className="form-group">
      <label>
        Label
        <input
          type="text"
          name="label"
          className="form-control form-control-sm"
          style={{ width: '15rem' }}
          value={exerciseType.label}
          onChange={onLabelChange}
        />
      </label>
    </div>
    <div className="d-flex">
      <label>
        Style
        <div className="btn-group btn-group-toggle">
          {styles.map((style, i) => (
            <label
              key={`style-${i}`}
              data-testid={`style-label-${i}`}
              className={cx('btn btn-sm btn-light p-1', { active: style === exerciseType.style })}
            >
              <input
                type="radio"
                name={`style-${i}`}
                value={style}
                checked={style === exerciseType.style}
                onChange={onStyleChange}
              />
              <div className={cx('badge d-inline-block', style)}>
                <i className="fas fa-square" />
              </div>
            </label>
          ))}
        </div>
      </label>
    </div>
  </div>
);

export default ExerciseTypeEdit;
