import React from 'react';

import { observer } from 'mobx-react';

import './CommitToImproveModal.scss';

import AntModal from 'app/components/ui/AntModal';
import { ExerciseReportModel } from 'app/models';
import ExerciseCommitmentModel, {
  ExerciseCommitmentCategory,
  ExerciseCommitmentContentKey,
} from 'app/models/ExerciseCommitmentModel';

import CommitToImproveModalSelectStatementStep from './steps/CommitToImproveModalSelectStatementStep';
import {
  CommitToImproveModalWriteChallengeStep,
  CommitToImproveModalWriteChangeStep,
} from './steps/CommitToImproveModalWriteContentStep';

export enum CommitToImproveModalStep {
  SELECT_STATEMENT = 'select_statement',
  WRITE_CHALLENGE = 'write_challenge',
  WRITE_COMMITMENT = 'write_commitment',
}

interface CommitToImproveModalProps {
  isOpen: boolean;
  isSubmitting: boolean;
  report: ExerciseReportModel;
  exerciseCommitment: ExerciseCommitmentModel;
  step: CommitToImproveModalStep;
  onToggle: () => void;
  onChangeStep: (v: CommitToImproveModalStep) => void;
  onSubmit: () => void;
  checkCanGoBack: (contentKey: ExerciseCommitmentContentKey, callback: () => void) => void;
  isTestDrive?: boolean;
}

const steps = {
  [CommitToImproveModalStep.SELECT_STATEMENT]: CommitToImproveModalSelectStatementStep,
  [CommitToImproveModalStep.WRITE_CHALLENGE]: CommitToImproveModalWriteChallengeStep,
  [CommitToImproveModalStep.WRITE_COMMITMENT]: CommitToImproveModalWriteChangeStep,
};

const getTitle = (
  report: ExerciseReportModel,
  step: CommitToImproveModalStep,
  exerciseCommitment: ExerciseCommitmentModel
) => {
  let title = null;

  const CommitToImproveTitles = {
    [ExerciseCommitmentCategory.celebrate]: 'Choose a statement that you are proud of',
    [ExerciseCommitmentCategory.improve]: 'Choose a statement that you want to improve on',
    [ExerciseCommitmentCategory.summary]: 'Select a statement which was unexpected',
  };

  if (step === CommitToImproveModalStep.SELECT_STATEMENT) {
    title = CommitToImproveTitles[exerciseCommitment.category];
  }

  if (step === CommitToImproveModalStep.WRITE_CHALLENGE) {
    title =
      exerciseCommitment.category === ExerciseCommitmentCategory.improve ? (
        <div>
          What's holding you back currently on&nbsp;
          <span className="statement">
            {report.statementsById[exerciseCommitment.align_statement_id]?.text}
          </span>
        </div>
      ) : (
        <div>
          What will you do differently now on&nbsp;
          <span className="statement">
            {report.statementsById[exerciseCommitment.align_statement_id]?.text}
          </span>
        </div>
      );
  }

  if (step === CommitToImproveModalStep.WRITE_COMMITMENT) {
    title =
      exerciseCommitment.category === ExerciseCommitmentCategory.improve ? (
        <div>
          What would it be like to make a change on&nbsp;
          <span className="statement">
            {report.statementsById[exerciseCommitment.align_statement_id]?.text}
          </span>
        </div>
      ) : (
        <div>
          Why was the result on&nbsp;
          <span className="statement">
            {report.statementsById[exerciseCommitment.align_statement_id]?.text}
          </span>
          &nbsp; unexpected ?
        </div>
      );
  }

  return title;
};

const CommitToImproveModal: React.FC<CommitToImproveModalProps> = ({
  isOpen,
  isSubmitting,
  step,
  exerciseCommitment,
  report,
  onToggle,
  onChangeStep,
  onSubmit,
  checkCanGoBack,
  isTestDrive,
}) => {
  const Step = steps[step];
  const title = exerciseCommitment?.category ? getTitle(report, step, exerciseCommitment) : null;

  return (
    <AntModal className="commit-to-improve-modal" balancedHeader {...{ isOpen, onToggle, title }}>
      {Step && (
        <Step
          {...{
            exerciseCommitment,
            report,
            isSubmitting,
            onChangeStep,
            onSubmit,
            checkCanGoBack,
            isTestDrive,
          }}
        />
      )}
    </AntModal>
  );
};

export default observer(CommitToImproveModal);
