import React, { ReactNode } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { range } from 'lodash';
import { computed } from 'mobx';
import moment from 'moment';

import styles from './PulseCircleGraph.module.scss';

import {
  BLUE_GRAY_LIGHT,
  GREEN_DARK,
  GREEN_LIGHT,
  ORANGE,
  RED,
  TEAL,
  YELLOW_LIGHT,
} from 'app/constants';
import { pluralize } from 'app/helpers';
import { PulseRecentActivity } from 'app/models';

const COLORS = [RED, ORANGE, YELLOW_LIGHT, BLUE_GRAY_LIGHT, GREEN_LIGHT, TEAL, GREEN_DARK];
const NUMBER_OF_PROGRESS_CIRCLES = 6;
const COLOR_PADDING = 3;

export interface PulseCircleGraphProps {
  activities: PulseRecentActivity[];
}

// Based on PulseProgressCircles, but more modern.
export class PulseCircleGraph extends React.Component<PulseCircleGraphProps> {
  getColor = (value: number): string => {
    if (value === null || value === undefined) {
      return undefined;
    }
    return COLORS[Math.round(value) + COLOR_PADDING];
  };

  @computed
  get responses() {
    return (
      this.props.activities?.reverse().map((activity) => {
        return {
          average: activity.counts.average,
          replies: activity.counts.responses,
          date: moment(activity.created_at).format('MMM Do'),
        };
      }) || []
    );
  }

  renderCircle = (key: string, response = null): ReactNode => {
    const replies = response?.replies ?? null;
    const average = response?.average ?? null;
    const color = this.getColor(average);
    const icon = response !== null ? <CheckOutlined /> : null;
    let title = '';

    if (response !== null) {
      title = `${response.date}: ${replies} ${pluralize(replies, 'reply', 'replies')}`;
    }

    return (
      <Tooltip key={key} placement="topLeft" title={title}>
        <div key={key} className={styles.circle} style={{ backgroundColor: color }}>
          {icon}
        </div>
      </Tooltip>
    );
  };

  @computed
  get circles(): ReactNode {
    const { responses } = this;

    return responses.map((response, i) => {
      return this.renderCircle(`filled_${i}`, response);
    });
  }

  @computed
  get emptyCircles(): ReactNode {
    const emptyCirclesCount = NUMBER_OF_PROGRESS_CIRCLES - this.responses.length;

    if (emptyCirclesCount < 1) {
      return null;
    }

    return range(emptyCirclesCount).map((i) => this.renderCircle(`empty_${i}`));
  }

  render(): ReactNode {
    return (
      <div className={styles.pulseCircleGraph}>
        {this.emptyCircles}
        {this.circles}
      </div>
    );
  }
}

export default PulseCircleGraph;
