import React from 'react';

import { isFunction, omit, partialRight } from 'lodash';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';

import AntModal from 'app/components/ui/AntModal';

export enum StepNavigation {
  nextStep = 'nextStep',
  prevStep = 'prevStep',
  lastStep = 'lastStep',
  firstStepAndClose = 'firstStepAndClose',
}

export interface MultiStepModalAction {
  label: string;
  onClick: React.MouseEventHandler | StepNavigation;
}

export interface ModalStep {
  title?: string;
  content: any;
  footer?: any;
  primaryAction?: MultiStepModalAction;
  secondaryAction?: MultiStepModalAction;
}

export interface ModalProps {
  isOpen?: boolean;
  className?: string;
  steps: ModalStep[];
  initialStep?: number;
  footerClassName?: string;
  onToggle?: () => void;

  // if you want to run something before moving to another step
  allowStep?: (direction: StepNavigation, step: number) => boolean;

  // always called when moving to another step
  onStep?: (direction: StepNavigation) => void;
}

export class MultiStepModal extends React.Component<ModalProps> {
  @observable currentStep = this.props.initialStep || 0;
  @action updateCurrentStep = (step) => (this.currentStep = step);

  nextStep = () => {
    const { steps, onStep, allowStep } = this.props;
    const possibleNextStep = this.currentStep + 1;
    if (possibleNextStep >= steps.length) {
      return;
    }

    if (allowStep && !allowStep(StepNavigation.nextStep, possibleNextStep)) {
      return;
    }

    this.updateCurrentStep(possibleNextStep);
    onStep && onStep(StepNavigation.nextStep);
  };

  firstStepAndClose = () => {
    this.updateCurrentStep(this.props.initialStep || 0);
    this.props.onToggle();
  };

  lastStep = () => {
    const { steps, onStep } = this.props;

    this.updateCurrentStep(steps.length - 1);
    onStep && onStep(StepNavigation.lastStep);
  };

  prevStep = () => {
    const { onStep, allowStep } = this.props;
    const possiblePrevStep = this.currentStep - 1;
    if (possiblePrevStep < 0) {
      return;
    }

    if (allowStep && !allowStep(StepNavigation.prevStep, possiblePrevStep)) {
      return;
    }

    this.updateCurrentStep(possiblePrevStep);
    onStep && onStep(StepNavigation.prevStep);
  };

  @computed get step() {
    return this.props.steps[this.currentStep];
  }

  @computed get showBack() {
    return this.currentStep > 0;
  }

  @computed get hasActions() {
    return !!(this.step.primaryAction || this.step.secondaryAction);
  }

  @computed get primaryAction() {
    if (!this.step.primaryAction) {
      return null;
    }

    return {
      onClick: partialRight(this.handleActionClick, this.step.primaryAction),
      ...omit(this.step.primaryAction, 'onClick'),
    };
  }

  @computed get secondaryAction() {
    if (!this.step.secondaryAction) {
      return null;
    }

    return {
      onClick: partialRight(this.handleActionClick, this.step.secondaryAction),
      ...omit(this.step.secondaryAction, 'onClick'),
    };
  }

  handleActionClick = (event, action) => {
    event.preventDefault();

    if (isFunction(action.onClick)) {
      action.onClick(event);
      return;
    }

    this[action.onClick]();
  };

  render() {
    const { isOpen, className, footerClassName, onToggle } = this.props;

    return (
      <AntModal
        isOpen={isOpen}
        className={className}
        onBack={this.showBack ? this.prevStep : null}
        title={this.step.title}
        footer={this.step.footer}
        footerClassName={footerClassName}
        onToggle={onToggle}
        primaryAction={this.primaryAction}
        secondaryAction={this.secondaryAction}
      >
        {this.step.content}
      </AntModal>
    );
  }
}

export default observer(MultiStepModal);
