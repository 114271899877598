// Node modules
import { observable } from 'mobx';

// App
import Model, { ModelJson } from 'app/models/Model';
import CustomLandingPagesLinkStore from 'app/stores/CustomLandingPagesLinkStore';

/**
 * CustomLandingPagesLinksModel is used to represent Organization Pulse Links
 */
export class CustomLandingPagesLinkModel extends Model {
  static _store: CustomLandingPagesLinkStore;

  @observable public id: number;
  @observable public title: string;
  @observable public url: string;
  @observable public survey_link: string;
  @observable public results_link: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as CustomLandingPagesLinkModel;
  }
}

export default CustomLandingPagesLinkModel;
