import React, { ReactNode } from 'react';

import cx from 'classnames';

import styles from './Footer.module.scss';

import { ModalAction } from 'app/components/ui/AntModal/AntModal';

import ActionButton from '../ActionButton';

interface FooterProps {
  content?: ReactNode;
  primaryAction?: ModalAction;
  secondaryAction?: ModalAction;
  className?: string;
}

export default function Footer({
  content,
  primaryAction,
  secondaryAction,
  className,
}: FooterProps): JSX.Element {
  return (
    <div className={cx(styles.footer, className)}>
      {content && <div className={styles.content}>{content}</div>}
      {secondaryAction && (
        <ActionButton key="secondary-btn" type="link" size="large" {...secondaryAction} />
      )}
      {primaryAction && (
        <ActionButton
          key="primary-btn"
          type="primary"
          size="large"
          className={styles.primaryAction}
          {...primaryAction}
        />
      )}
    </div>
  );
}
