import React from 'react';

import { ExerciseReportModel } from 'app/models';

import SummaryPrintView from './SummaryPrintView';

export interface AlignSummaryPrintViewProps {
  report: ExerciseReportModel;
}

// TODO: rename to ExerciseSummaryPrintView
export const AlignSummaryPrintView: React.FC<AlignSummaryPrintViewProps> = ({ report }) => (
  <SummaryPrintView report={report} scatterPlotSize={350} />
);

export default AlignSummaryPrintView;
