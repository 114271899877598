import * as React from 'react';

import { inject, observer } from 'mobx-react';

import MithrilComponent from 'app/components/features/MithrilComponent';
import MemberAvatar from 'app/components/ui/MemberAvatar';
import { STORE_MEMBER } from 'app/constants';
import MemberStore from 'app/stores/MemberStore';

/**
 * The props that get passed in to the container.
 * The @inject decorator will automatically inject the stores
 */
export interface ExampleContainerProps {
  /** MobX Stores will be injected via @inject() **/
  [STORE_MEMBER]: MemberStore;
}

/**
 * The properties stored in the state of the container
 */
export interface ExampleContainerState {}

/**
 * Example Container shows how to properly load the current member
 * from the MemberStore and render the Avatar + Name for the current member
 */
class ExampleContainer extends React.Component<ExampleContainerProps, ExampleContainerState> {
  constructor(props) {
    super(props);
    this.props[STORE_MEMBER].loadCurrentMember();
  }

  isLoading() {
    return this.props[STORE_MEMBER].currentMember.loading;
  }

  /**
   * Render gets called when ever the object redraws. This can happen
   * when the state changes, or when a store updates. It's important
   * that we keep it lean. Do processing elsewhere.
   */
  render() {
    // The stores should always be treated as async. Even if we are
    // getting the variable from a hash, we should still add a loading
    // page for the component. That way we can make it an API call later
    // without breaking everything
    if (this.isLoading()) {
      return <div>Loading...</div>;
    }

    // Get the parameters from the store
    const { currentMember } = this.props[STORE_MEMBER];

    // Return the JSX object
    return (
      <div>
        <div>
          <MemberAvatar {...currentMember.item.avatar} />
          <span>This is a component that is mounted and controlled using React</span>
        </div>

        {/* Load a Mithril component (this must be registered using the toReact method) */}
        <MithrilComponent componentID="MemberAvatar" />
      </div>
    );
  }
}

export default inject(STORE_MEMBER)(observer(ExampleContainer));
