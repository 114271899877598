import { observable } from 'mobx';

import CsvUploadStore from 'app/stores/CsvUploadStore';

import Model, { ModelJson } from './Model';

export interface CsvColumn {
  id: number;
  value: string;
}

interface CsvRow {
  id: number;
  values: CsvColumn[];
}

export class CsvDataModel extends Model {
  static _store: CsvUploadStore;

  @observable headers: CsvColumn[];
  @observable rows: CsvRow[];

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as CsvDataModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as CsvDataModel;
  }

  static get(id) {
    return this._get(id) as CsvDataModel;
  }
}
