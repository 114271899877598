import React, { ReactNode } from 'react';

import { Col, Row } from 'antd';
import cx from 'classnames';

import styles from './EmptyCardComponent.module.scss';

export interface EmptyCardComponentContentCard {
  image: string;
  title: string;
  description: string;
}

interface EmptyCardComponentProps {
  className?: string;
  cardHeader?: ReactNode;
  cardFooter?: ReactNode;
  contentCards: EmptyCardComponentContentCard[];
  imgClassname?: string;
}

const EmptyCardComponent: React.FC<EmptyCardComponentProps> = ({
  className,
  contentCards,
  cardHeader,
  cardFooter,
  imgClassname,
}) => {
  const colSpan = contentCards.length >= 3 ? 8 : 10;
  const hasSingleContentCard = contentCards.length === 1;

  const body = (): ReactNode => {
    if (hasSingleContentCard) {
      const card = contentCards[0];

      return (
        <>
          <Col xs={24} sm={6} md={8} lg={8} xl={8}>
            <img src={card.image} className={styles.singleContentCardImage} />
          </Col>
          <Col xs={24} sm={18} md={16} lg={16} xl={16}>
            <div>
              <h5 className={styles.singleContentCardTitle}>{card.title}</h5>
              <p className={styles.singleContentCardDescription}>{card.description}</p>
            </div>
          </Col>
        </>
      );
    }

    return (
      <>
        {contentCards.map((card, index) => {
          return (
            <Col xs={24} sm={12} md={colSpan} lg={colSpan} xl={colSpan} key={index}>
              <div className={styles.contentCard}>
                <img src={card.image} className={imgClassname} />
                <h5 className={styles.contentCardTitle}>{card.title}</h5>
                <p className={styles.contentCardDescription}>{card.description}</p>
              </div>
            </Col>
          );
        })}
      </>
    );
  };

  return (
    <div className={cx(styles.container, className)}>
      {cardHeader}
      <Row className={styles.contentContainer} gutter={24} justify="center" wrap>
        {body()}
      </Row>
      {cardFooter}
    </div>
  );
};

export default EmptyCardComponent;
