import { computed } from 'mobx';

import BenchmarkModel from './BenchmarkModel';
import ExerciseTypeModel from './ExerciseTypeModel';
import ShiftModel from './ShiftModel';

export interface BenchmarkScoreProps {
  id: number;
  benchmark_id: number;
  align_statement_template_id: number;
  score: number;
}

export class BenchmarkScoreModel extends ShiftModel<BenchmarkScoreProps> {
  exerciseType: ExerciseTypeModel;
  benchmark: BenchmarkModel;

  id: number;
  score: number;
  benchmark_id: number;
  align_statement_template_id: number;

  constructor(
    props: BenchmarkScoreProps,
    exerciseType?: ExerciseTypeModel,
    benchmark?: BenchmarkModel
  ) {
    super(props);
    this.exerciseType = exerciseType;
    this.benchmark = benchmark;
  }

  @computed get alignScore() {
    return this.score;
  }

  @computed get scaledScore() {
    return this.exerciseType.scaleScore(this.score);
  }
}

export default BenchmarkScoreModel;
