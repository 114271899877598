import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { PairedNoteModel } from 'app/models';
import { ModelList } from 'app/models/ModelList';
import Store from 'app/stores/Store';

export class PairedNoteStore extends Store<PairedNoteModel> {
  @observable public pairedNotes = new ModelList<PairedNoteModel>(PairedNoteModel);

  constructor() {
    super();
    PairedNoteModel._store = this;
  }

  @action
  public async loadPairedNotes(
    recipientId: number,
    recipientType: string,
    types?: string[]
  ): Promise<void> {
    let query = {};

    if (types) {
      query = { ...query, 'type[]': types };
    }

    const url = ServerRouteHelper.api.pairedNote.list(recipientId, recipientType, query);

    return this.pairedNotes.load(url);
  }

  @action
  public async createPairedNote(pairedNote: PairedNoteModel): Promise<void> {
    const url = ServerRouteHelper.api.pairedNote.list(
      pairedNote.recipient_id,
      pairedNote.recipient_type
    );

    if (this.sharedLinkToken) {
      const query = { shared_link_token: this.sharedLinkToken };
      url.withParams(query);
    }

    const response = await this.apiService.post(url, pairedNote);

    pairedNote.updateFromJson(response.data);
    this.pairedNotes.appendItem(pairedNote);
  }

  @action
  public async updatePairedNote(pairedNote: PairedNoteModel): Promise<void> {
    const url = ServerRouteHelper.api.pairedNote.update(pairedNote.id);

    if (this.sharedLinkToken) {
      const query = { shared_link_token: this.sharedLinkToken };
      url.withParams(query);
    }

    await this.apiService.put(url, { note: pairedNote.note });
  }
}

export default PairedNoteStore;
