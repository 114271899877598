import React, { ReactNode } from 'react';

import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import numeral from 'numeral';

import { preventDefault, ServerRouteHelper } from 'app/helpers';
import { OrganizationModel } from 'app/models';
import TeamModel from 'app/models/TeamModel';

export interface QuickEditProps {
  team: TeamModel;
  otherTeams: TeamModel[];
  organizations: OrganizationModel[];
  isUpdating: boolean;
  isMergingToTeam: boolean;
  isPromotingToMembers: boolean;
  isDeleting: boolean;
  isSavingAdminChanges: boolean;
  onTeamRename: (name: string) => void;
  onLinkToOrg: (orgId: number | string) => void;
  onMergeToTeam: (otherTeamId: number) => void;
  onPromoteToMembers: () => void;
  onChangeManager: (managerId: number) => void;
  onTopTeam: () => void;
  onCanUseDiscussionSpace: () => void;
  onDelete: () => void;
}

export class QuickEdit extends React.Component<QuickEditProps> {
  @observable name;
  @action setName = (name) => (this.name = name);

  @observable orgId = '';
  @action setOrgId = (orgId) => (this.orgId = orgId);

  @observable managerId: number;
  @action setManagerId = (managerId) => (this.managerId = managerId);

  @observable mergeToTeamId;
  @action setMergeToTeamId = (mergeToTeamId) => (this.mergeToTeamId = mergeToTeamId);

  constructor(props) {
    super(props);

    const { team } = props;
    this.setName(team.name);
    this.setManagerId(team.manager_id);
  }

  get organization() {
    return this.props.team.organization.item;
  }

  handleDelete = (event) => {
    if (!confirm('ARE YOU SURE?! ... this is a pain to undo!')) {
      event.preventDefault();
    } else {
      this.props.onDelete();
    }
  };

  renderStats() {
    const { team } = this.props;
    const manager = team.manager.item;

    return (
      <div className="stats">
        <div className="admin-and-org">
          {manager && (
            <span className="admin">
              Admin: <a href={ServerRouteHelper.admin.memberReport(manager.id)}>{manager.name}</a>
            </span>
          )}
          {this.organization && (
            <span className="org">
              Org:
              <a
                className="ml-2"
                href={ServerRouteHelper.dashboard.organization.summary(this.organization.id)}
              >
                {this.organization.name}
              </a>
            </span>
          )}
        </div>
      </div>
    );
  }

  renderRenaming() {
    const { isUpdating, onTeamRename } = this.props;
    const handleNameChange = (event) => this.setName(event.target.value);

    return (
      <form className="form-inline text-center">
        <div className="form-group mr-3">
          <label htmlFor="team_name" className="mr-3">
            Team name
          </label>
          <input
            className="mr-3"
            type="text"
            name="team_name"
            id="team_name"
            defaultValue={this.name}
            onChange={handleNameChange}
            required
            disabled={isUpdating}
          />
        </div>
        <button
          className="btn acc-btn-caps btn-primary btn-sm"
          disabled={isUpdating}
          onClick={() => onTeamRename(this.name)}
        >
          Change name
        </button>
      </form>
    );
  }

  renderLinking() {
    const { organizations, isUpdating, onLinkToOrg } = this.props;
    const handleOrgChange = (event) => {
      let orgId = event.target.value;
      orgId = orgId ? numeral(orgId).value() : orgId;
      this.setOrgId(orgId);
    };

    return (
      <form className="form-inline text-center">
        <div className="form-group mr-3">
          <label htmlFor="org-select" className="mr-3">
            Link to
          </label>
          <select
            className="mr-3"
            name="organization_id"
            id="org-select"
            required
            disabled={isUpdating}
            defaultValue={this.orgId}
            onChange={handleOrgChange}
          >
            <option disabled value="">
              Choose organization...
            </option>
            {this.organization && <option value="0">(Unlink from org)</option>}
            {organizations.map((org, i) => (
              <option key={`org-${i}`} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
        </div>
        <button
          className="btn acc-btn-caps btn-primary btn-sm"
          disabled={isUpdating}
          onClick={() => onLinkToOrg(this.orgId)}
        >
          Link
        </button>
      </form>
    );
  }

  renderChangeManager() {
    const { team, isUpdating, onChangeManager } = this.props;
    const { members } = team;

    const handleManagerChange = (event) => {
      const managerId = parseInt(event.target.value);
      this.setManagerId(managerId);
    };

    return (
      <form className="form-inline text-center">
        <div className="form-group mr-3">
          <label htmlFor="manager-select" className="mr-3">
            Manager
          </label>
          <select
            className="mr-3"
            name="organization_id"
            id="manager-select"
            required
            disabled={isUpdating}
            defaultValue={this.managerId?.toString()}
            onChange={handleManagerChange}
          >
            <option disabled value="">
              Choose manager...
            </option>
            {members.items.map((member, i) => (
              <option key={i} value={member.id}>
                {member.name}
              </option>
            ))}
          </select>
        </div>
        <button
          className="btn acc-btn-caps btn-primary btn-sm"
          disabled={isUpdating}
          onClick={() => onChangeManager(this.managerId)}
        >
          Update Manager
        </button>
      </form>
    );
  }

  renderMergeAndPromote() {
    if (!this.organization) {
      return null;
    }

    const { otherTeams, isMergingToTeam, isPromotingToMembers, onMergeToTeam, onPromoteToMembers } =
      this.props;
    const handleMergeChange = (event) => {
      const teamId = numeral(event.target.value).value();
      this.setMergeToTeamId(teamId);
    };

    const handleSubmitMerge = (event) => {
      if (!confirm('CAREFUL: cannot undo!')) {
        event.preventDefault();
        return;
      }

      onMergeToTeam(this.mergeToTeamId);
    };

    return (
      <div>
        <form className="form-inline text-center">
          <div className="form-group mr-3">
            <label htmlFor="team_select" className="mr-3">
              Merge into
            </label>
            <select
              className="mr-3"
              name="other_team_id"
              id="team_select"
              required
              disabled={isMergingToTeam}
              defaultValue=""
              onChange={handleMergeChange}
            >
              <option disabled value="">
                Choose team...
              </option>
              {otherTeams.map((team, i) => (
                <option key={`team-${i}`} value={team.id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
          <button
            className="btn acc-btn-caps btn-primary btn-sm"
            onClick={handleSubmitMerge}
            disabled={isMergingToTeam}
          >
            {isMergingToTeam ? 'Merging...' : 'Merge'}
          </button>
        </form>

        <div className="text-center">
          <button
            className="btn acc-btn-caps btn-md btn-primary"
            onClick={preventDefault(onPromoteToMembers)}
            disabled={isPromotingToMembers}
          >
            {isPromotingToMembers
              ? 'Promoting...'
              : `Promote participants to members of ${this.organization.name}`}
          </button>
        </div>
      </div>
    );
  }

  renderTopTeam() {
    const { team, isUpdating, onTopTeam } = this.props;
    const handleTopTeam = action(() => (team.is_top_team = !team.is_top_team));

    return (
      <form className="form-inline text-center">
        <div className="form-group mr-3">
          <label htmlFor="top-team" className="mr-3">
            Is Top Team?
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            checked={team.is_top_team}
            onChange={handleTopTeam}
          />
        </div>
        <button
          className="btn acc-btn-caps btn-primary btn-sm"
          disabled={isUpdating}
          onClick={preventDefault(onTopTeam)}
        >
          Save
        </button>
      </form>
    );
  }

  @computed
  get canUseDiscussionSpace(): ReactNode {
    const { team, isSavingAdminChanges, onCanUseDiscussionSpace } = this.props;
    const handleCanUseDiscussionSpace = () =>
      team.updateFromJson({ can_use_discussion_space: !team.can_use_discussion_space });

    return (
      <form className="form-inline text-center">
        <div className="form-group mr-3">
          <label className="mr-3">Can use Discussion Space</label>
          <input
            className="form-check-input"
            type="checkbox"
            checked={team.can_use_discussion_space}
            onChange={handleCanUseDiscussionSpace}
          />
        </div>

        <button
          className="btn acc-btn-caps btn-primary btn-sm"
          disabled={isSavingAdminChanges}
          onClick={preventDefault(onCanUseDiscussionSpace)}
        >
          Save
        </button>
      </form>
    );
  }

  renderDelete() {
    const { isDeleting } = this.props;

    return (
      <div className="text-center">
        <button
          type="submit"
          className="btn acc-btn-caps btn-xs btn-danger"
          onClick={this.handleDelete}
          disabled={isDeleting}
        >
          {isDeleting ? 'Deleting...' : 'Delete team'}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="quick-edit acc-card acc-card-border">
        <h3 className="text-center">Admin Controls</h3>

        {this.renderStats()}
        {this.renderRenaming()}
        {this.renderLinking()}
        {this.renderMergeAndPromote()}
        {this.renderChangeManager()}
        {this.renderTopTeam()}
        {this.canUseDiscussionSpace}
        {this.renderDelete()}
      </div>
    );
  }
}

export default observer(QuickEdit);
