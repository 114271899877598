import React, { ReactNode } from 'react';

import { Button } from 'antd';
import Clipboard from 'clipboard';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

interface ClipboardButtonProps {
  target?: string;
  targetText?: string;
  className?: string;
  copiedText?: ReactNode;
  icon?: ReactNode;
  copyText?: ReactNode;
  link?: boolean;
  transitionDuration?: number;
  dataTrackId?: string;
  disabled?: boolean;
  overrideCopyAction?: () => void;
  onCopy?: () => void;
}

const CopyTextDefault = 'Copy';
const CopyTextCopied = 'Copied!';
const TransitionDuration = 1500;

@observer
export class ClipboardButton extends React.Component<ClipboardButtonProps> {
  delay = (cb, delay) => setTimeout(cb, delay);

  @observable copyText = CopyTextDefault;
  @action setCopyText = (text) => (this.copyText = text);

  constructor(props) {
    super(props);

    if (props.copyText) {
      this.copyText = props.copyText;
    }
  }

  bindClipboard(ref) {
    if (ref) {
      new Clipboard(ref);
    }
  }

  handleClick(e) {
    e.preventDefault();

    const { transitionDuration, overrideCopyAction, onCopy } = this.props;
    let { copyText, copiedText } = this.props;

    if (!copyText) {
      copyText = CopyTextDefault;
    }

    if (!copiedText) {
      copiedText = CopyTextCopied;
    }

    this.setCopyText(copiedText);
    this.delay(() => this.setCopyText(copyText), transitionDuration || TransitionDuration);

    onCopy?.();

    if (overrideCopyAction) {
      overrideCopyAction();
    }
  }

  render() {
    const { target, targetText, className, link, overrideCopyAction, disabled, icon } = this.props;
    const { copyText } = this;

    const props = overrideCopyAction
      ? {}
      : {
          ref: this.bindClipboard,
          'data-clipboard-action': 'copy',
          'data-clipboard-target': target,
          'data-clipboard-text': targetText,
        };

    if (link) {
      return (
        <a
          {...props}
          href="#"
          onClick={this.handleClick.bind(this)}
          data-track={this.props.dataTrackId}
          className={className}
        >
          {copyText}
        </a>
      );
    }

    return (
      <Button
        {...props}
        ghost
        type="primary"
        onClick={this.handleClick.bind(this)}
        data-track={this.props.dataTrackId}
        className={className}
        disabled={disabled}
      >
        {icon} {copyText}
      </Button>
    );
  }
}
