import React from 'react';

import cx from 'classnames';

interface ContainerProps {
  className?: string;
}

export const Container: React.FC<ContainerProps> = ({ className, children }) => (
  <div className={cx('acc-registration-page', className)}>
    <div className="acc-registration-wrapper">{children}</div>
  </div>
);
