import React, { ReactNode } from 'react';

import { Button, Input, Select } from 'antd';
import { camelCase } from 'lodash';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import styles from '../PulseTemplates.module.scss';

import { PulseStatementType } from 'app/constants';
import { PulseStatementModel } from 'app/models';

const types = [
  PulseStatementType.Scale,
  PulseStatementType.Binary,
  PulseStatementType.Text,
  PulseStatementType.Header,
];

export interface StatementFormProps {
  statement: PulseStatementModel;
  index: number;
  updateStatementText: (value: string, index: number) => void;
  updateSelectedType: (value: string, index: number) => void;
  deleteStatement?: (index: number) => void;
}

export class StatementForm extends React.Component<StatementFormProps> {
  @computed
  get isTempId() {
    return this.props.statement?.id?.toString()?.includes('temp_');
  }

  public render(): ReactNode {
    const { index, statement, updateStatementText, updateSelectedType, deleteStatement } =
      this.props;

    return (
      <div className="d-flex" key={index}>
        <Input
          value={statement.text}
          className={styles.statementName}
          onChange={(e) => updateStatementText(e.target.value, index)}
        />

        <Select
          value={statement.statement_type}
          disabled={!this.isTempId}
          onChange={(value) => updateSelectedType(value, index)}
        >
          {types.map((type, i) => (
            <Select.Option key={i} value={type}>
              {camelCase(type)}
            </Select.Option>
          ))}
        </Select>

        {this.isTempId && (
          <Button type="ghost" className="ml-4" onClick={() => deleteStatement(index)}>
            X
          </Button>
        )}
      </div>
    );
  }
}

export default observer(StatementForm);
