import { keyBy, range, startsWith, uniqueId } from 'lodash';
import moment from 'moment';

import { Cadence, PulseStatementType } from 'app/constants';
import {
  PulseForMemberModel,
  PulseForTeamModel,
  PulseReminderModel,
  PulseStatementModel,
} from 'app/models';
import { PulseReminderStore } from 'app/stores';

export class PulseService {
  activePulse: PulseForTeamModel | PulseForMemberModel;
  pulseReminderStore: PulseReminderStore;
  tmp_pulse_id_prefix: string;
  tmp_pulse_statement_id_prefix: string;

  constructor(
    activePulse: PulseForTeamModel | PulseForMemberModel,
    pulseReminderStore: PulseReminderStore,
    tmp_pulse_id_prefix: string,
    tmp_pulse_statement_id_prefix: string
  ) {
    this.activePulse = activePulse;
    this.pulseReminderStore = pulseReminderStore;
    this.tmp_pulse_id_prefix = tmp_pulse_id_prefix;
    this.tmp_pulse_statement_id_prefix = tmp_pulse_statement_id_prefix;
  }

  public get selectedHabitsById(): Record<number, PulseStatementModel> {
    return keyBy(this.habits, 'id');
  }

  public get validHabits(): PulseStatementModel[] {
    return this.habits.filter(({ text }) => !!text);
  }

  public get isPulseSaved(): boolean {
    return !startsWith(this.activePulse.id.toString(), this.tmp_pulse_id_prefix);
  }

  public get habits(): PulseStatementModel[] {
    return this.activePulse.statements.items.filter((statement) => !statement.is_default);
  }

  public get isCadenceLoading(): boolean {
    return this.pulseReminderStore.reminders.loading;
  }

  public get reminder(): PulseReminderModel {
    return this.pulseReminderStore.activeReminder;
  }

  public get cadenceText(): string {
    return this.reminder?.summary;
  }

  public get formattedValidHabits(): Partial<PulseStatementModel>[] {
    return this.validHabits.map((habit, i) => {
      const data = {
        id: habit.id,
        type: habit.type,
        statement_type: habit.statement_type,
        order: i,
        text: habit.text,
      };

      if (startsWith(habit.id.toString(), this.tmp_pulse_statement_id_prefix)) {
        delete data.id;
        delete data.type;
      }

      return data;
    });
  }

  public updateActiveDefaultReminder = (reminder: PulseReminderModel): void => {
    const date = this.getNextWeekday(moment(), reminder.day_of_week);

    const dayPart = `**${reminder.day_of_week}**`;

    // Prefix will only need recomputation when the cadence config is a custom one
    let prefix = dayPart;
    if (reminder.cadence === Cadence.Custom) {
      prefix = reminder.interval > 1 ? `${reminder.interval} weeks on ${dayPart}` : dayPart;
      reminder.cadence = Cadence.Weekly;
    }

    if (reminder.cadence === Cadence.Monthly) {
      prefix = '**Monthly**';
    }

    if (reminder.cadence === Cadence.MonthlyThirdFriday) {
      prefix = '**3rd Friday of the month**';
    }

    const startingDateFormatted = moment(date).format('MMMM Do YYYY');
    const startingTimeFormatted = moment(reminder.time_of_day, 'HH:mm:ss').format('h:mm a');
    const tz = this.pulseReminderStore.options.timezones?.filter(
      (t) => t.value === reminder.timezone
    );

    reminder.summary = `Habits will trigger every ${prefix}, starting on ${startingDateFormatted} at ${startingTimeFormatted} ${tz?.[0]?.name}`;

    reminder.start_date = date;

    this.pulseReminderStore.setActiveReminder(reminder);
  };

  public updateEmptyHabit = (statement: PulseStatementModel): void => {
    // If statement already exists, skip
    if (this.selectedHabitsById?.[statement.id]) {
      return;
    }

    const habit = this.habits.find(({ text }) => !text);

    // If no empty habit, create a new slot
    if (!habit) {
      statement.setIsNew(true);
      this.activePulse.statements.appendItem(statement);
      return;
    }

    habit.updateFromJson(statement);
  };

  public createEmptySlots = (): void => {
    if (this.habits.length > 0) {
      return;
    }

    range(3).forEach(() => this.appendNewHabit('', false));
  };

  public appendNewHabit = (text: string, useEmptySlot = true): void => {
    const habit = PulseStatementModel.fromJson({
      id: uniqueId(this.tmp_pulse_statement_id_prefix),
      text,
      statement_type: PulseStatementType.Scale,
    });

    if (useEmptySlot) {
      this.updateEmptyHabit(habit);
      return;
    }

    this.activePulse.statements.appendItem(habit);
  };

  public deleteHabit = (habit: PulseStatementModel): void => {
    this.activePulse.statements.deleteItem(habit);
  };

  public setPulse = (pulse: PulseForTeamModel | PulseForMemberModel): void => {
    this.activePulse = pulse;
  };

  private getNextWeekday = (day, weekdayName: string) => {
    const weekday = PulseReminderModel._store.options.days.findIndex((val) => val === weekdayName);
    const current = day.day(); // 2
    const days = (7 + weekday - current) % 7;

    if (days === 0) {
      return day.add(1, 'weeks').day(current);
    }

    return day.clone().add(days, 'days');
  };
}
