import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import IntelligenceActionStore from 'app/stores/IntelligenceActionStore';

export class IntelligenceActionResponseModel extends Model {
  public static _store: IntelligenceActionStore;

  @observable result: string;

  static fromJson(json: ModelJson): IntelligenceActionResponseModel {
    return this._fromJson(json) as IntelligenceActionResponseModel;
  }

  static getOrNew(id): IntelligenceActionResponseModel {
    return this._getOrNew(id) as IntelligenceActionResponseModel;
  }

  static get(id): IntelligenceActionResponseModel {
    return this._get(id) as IntelligenceActionResponseModel;
  }
}

export default IntelligenceActionResponseModel;
