import React from 'react';

import PersonalHabitsUIStore from '../../Myself/MyHabit/PersonalHabits/PersonalHabitsUIStore';
import TeamHabitsUIStore from '../../Team/TeamHabits/TeamHabitsUIStore';

const PulseHabitsContext = React.createContext<{
  uiStore: PersonalHabitsUIStore | TeamHabitsUIStore;
}>({
  uiStore: null,
});

export default PulseHabitsContext;
