import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList, TeamModel } from 'app/models';
import { TeamCardChecklistModel } from 'app/models/TeamCardChecklistModel';

import Store from './Store';

export class TeamCardChecklistStore extends Store<TeamCardChecklistModel> {
  @observable checklists = new ModelList<TeamCardChecklistModel>(TeamCardChecklistModel);

  @observable checklistProvider: string;
  @action setChecklistProvider = (provider: string): void => {
    this.checklistProvider = provider;
  };

  @observable isLoadingChecklists = false;
  @action setIsLoadingChecklists = (isLoading: boolean): void => {
    this.isLoadingChecklists = isLoading;
  };

  constructor() {
    super();
    TeamCardChecklistModel._store = this;
  }

  @action
  async loadChecklists(team?: TeamModel) {
    const url = ServerRouteHelper.api.checklist.show(team?.id);

    this.setIsLoadingChecklists(true);

    const response = await this.apiService.newGet({ url });

    if (response) {
      this.checklists.setItems(
        response.checklists.map((checklist) => TeamCardChecklistModel.fromJson(checklist))
      );

      this.setChecklistProvider(response.checklists_provider);
    }

    this.setIsLoadingChecklists(false);
  }

  get isRunningProgram(): boolean {
    return this.checklistProvider === 'programs' && this.checklists.items.length > 0;
  }

  get hasChecklist(): boolean {
    return !!this.checklistProvider;
  }

  clearChecklists(): void {
    this.setChecklistProvider(null);
    this.checklists.setItems([]);
  }
}

export default TeamCardChecklistStore;
