import * as React from 'react';

import cx from 'classnames';

import './FakeBox.scss';

export interface FakeBoxProps {
  className?: string;
  height?: string;
  width?: string;
  borderRadius?: string;
}

export const FakeBox: React.StatelessComponent<FakeBoxProps> = ({
  className,
  width,
  height,
  borderRadius,
}) => (
  <div
    className={cx('fake-box', className)}
    style={{
      width,
      height,
      borderRadius,
    }}
  />
);
