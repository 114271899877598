import React, { Component, ReactNode } from 'react';

import cx from 'classnames';
import IframeResizer from 'iframe-resizer-react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './MetabaseDashboard.module.scss';

import { STORE_METABASE } from 'app/constants';
import { MetabaseStore } from 'app/stores';

interface MetabaseDashboardParams {
  use_active_org?: boolean;
  onboarding_link_id?: number;
}

interface MetabaseDashboardProps {
  name: string;
  title?: string;
  params?: MetabaseDashboardParams;
  orgId?: number;
  admin?: boolean;
  metabaseStore?: MetabaseStore;
}

export class MetabaseDashboard extends Component<MetabaseDashboardProps> {
  constructor(props: MetabaseDashboardProps) {
    super(props);
    this.init();
  }

  init = (): void => {
    const { name, params, orgId, admin, metabaseStore } = this.props;

    if (admin) {
      metabaseStore.loadAdminDashboard(name, params);
    } else {
      metabaseStore.loadDashboard(orgId, name, params);
    }
  };

  componentDidUpdate(): void {
    const { name, params, orgId, metabaseStore } = this.props;
    if (orgId) {
      metabaseStore.loadDashboard(orgId, name, params);
    }
  }

  get isLoading(): boolean {
    return this.props.metabaseStore.dashboard.loading;
  }

  @computed
  get url(): string {
    if (this.isLoading) {
      return null;
    }

    return this.props.metabaseStore.dashboard?.item?.dashboard_url;
  }

  render(): ReactNode {
    return (
      <div className={cx('container-fluid', styles.dashboard)}>
        {this.props.title && <h3>{this.props.title}</h3>}
        {this.isLoading && <p>Loading dashboard...</p>}
        {!this.isLoading && !this.url && <p>Unable to load dashboard.</p>}
        {this.url && <IframeResizer id="graph-frame" src={this.url} />}
      </div>
    );
  }
}

export default inject(STORE_METABASE)(observer(MetabaseDashboard));
