import { action, computed, observable, ObservableMap } from 'mobx';

import { TEST_DRIVE_HEADER } from 'app/constants';
import Model from 'app/models/Model';
import { ApiService } from 'app/services/ApiService';

export abstract class Store<M extends Model> {
  @observable isTestDrive: boolean;
  @action setIsTestDrive = (isTestDrive: boolean): void => {
    this.isTestDrive = isTestDrive;
  };

  @observable sharedLinkToken: string;
  @action setSharedLinkToken = (sharedLinkToken: string): void => {
    this.sharedLinkToken = sharedLinkToken;
  };

  @computed
  get defaultHeaders(): Record<string, any> {
    const headers = {};

    if (this.isTestDrive) {
      headers[TEST_DRIVE_HEADER] = true;
    }

    return headers;
  }

  protected apiService = ApiService.getInstance();

  public entities = new ObservableMap<number | string, M>();

  public get(id: number | string): M {
    return this.entities.get(id);
  }

  public find(field: string, value: any): M {
    return Array.from(this.entities.values()).find((entity) => entity[field] === value);
  }

  @action
  public push(entity: M) {
    this.entities.set(entity.id, entity);
  }
}

export default Store;
