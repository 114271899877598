import React, { ReactNode } from 'react';

import styles from './License.module.scss';

import Markdown from 'app/components/ui/Markdown';

interface LicenseProps {
  license: string;
}

class License extends React.Component<LicenseProps> {
  render(): ReactNode {
    const markdown = this.props.license;

    if (!!markdown === false) {
      return null;
    }

    return (
      <div className={styles.license}>
        <Markdown source={markdown}></Markdown>
      </div>
    );
  }
}

export default License;
