import PerspectivePersonalReportModel from 'app/models/PerspectivePersonalReportModel';

import Store from './Store';

export class PerspectivePersonalReportStore extends Store<PerspectivePersonalReportModel> {
  constructor() {
    super();
    PerspectivePersonalReportModel._store = this;
  }
}

export default PerspectivePersonalReportStore;
