import React from 'react';

import { action, observable } from 'mobx';

import './Sandbox.scss';

import AlignFocusAreaSectionDemo from './AlignFocusAreaSectionDemo';
import AlignImportanceModalDemo from './AlignImportanceModalDemo';
import ExceptionHandling from './ExceptionHandling';
import MemberAvatarDemo from './MemberAvatarDemo';
import MobileCardListDemo from './MobileCardListDemo';
import WhatIsAlignModalDemo from './WhatIsAlignModalDemo';

export class Sandbox extends React.Component {
  @observable showSubmitLoader = false;
  @action setShowSubmitLoader = (showSubmitLoader: boolean) =>
    (this.showSubmitLoader = showSubmitLoader);

  render() {
    return (
      <div className="sandbox">
        <div className="demo-row">
          <div>
            <h1>Typography Heading 1</h1>
            <h2>Typography Heading 2</h2>
            <h3>Typography Heading 3</h3>
            <h4>Typography Heading 4</h4>
            <h5>Typography Heading 5</h5>
            <h6>Typography Heading 6</h6>
            <p>
              Typography normal text <strong>Strong</strong> text <strong>legacy bold</strong> text
            </p>
          </div>
        </div>

        <div className="demo-row">
          <MemberAvatarDemo />
        </div>

        <div className="demo-row">
          <AlignImportanceModalDemo />
        </div>

        <div className="demo-row">
          <MobileCardListDemo />
        </div>

        <div className="demo-row">
          <WhatIsAlignModalDemo />
        </div>

        <div className="demo-row">
          <AlignFocusAreaSectionDemo />
        </div>

        <div className="demo-row">
          <ExceptionHandling />
        </div>
      </div>
    );
  }
}

export default Sandbox;
