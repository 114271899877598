import React, { ReactElement } from 'react';

import cx from 'classnames';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import './ScoreBarMultiMarker.scss';

import { RED } from 'app/constants';

const MARKER_HALF_WIDTH = '0.5rem';

export enum ScoreBarMarkerShape {
  Circle = 'circle',
  Diamond = 'diamond',
}

export interface ScoreBarMarker {
  value: number;
  color?: string;
  shape?: ScoreBarMarkerShape;
}

export interface ScoreBarMultiMarkerProps {
  className?: string;
  markers: ScoreBarMarker[];
  classification: string;
  onClick?: () => void;
}

export class ScoreBarMultiMarker extends React.Component<ScoreBarMultiMarkerProps> {
  @computed
  get markers(): ScoreBarMarker[] {
    return this.props.markers.filter(({ value }) => !isNaN(value));
  }

  boundValue = (value: number, min = 0.0, max = 100.0): number => {
    if (value < min) {
      return min;
    }
    if (value > max) {
      return max;
    }

    return value;
  };

  getMarkerStyle = (marker: ScoreBarMarker): any => {
    return {
      left: `calc(${this.boundValue(marker.value)}% - ${MARKER_HALF_WIDTH})`,
      background: marker.color || RED,
    };
  };

  getMarkerClass = (marker: ScoreBarMarker): any => {
    if (marker.shape === 'diamond') {
      return 'legend-marker circle mx-2';
    }

    return marker.shape ?? 'circle';
  };

  render(): ReactElement {
    const { className, onClick, classification } = this.props;

    return (
      <div onClick={onClick} className={cx('score-bar-container', 'no-pins', className)}>
        <div className="line score-bar-multimarker-line">
          {this.markers.map((marker, i) => (
            <div
              key={i}
              className={`${this.getMarkerClass(marker)} ${classification}`}
              style={this.getMarkerStyle(marker)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default observer(ScoreBarMultiMarker);
