import React from 'react';

import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import { STORE_ORGANIZATION } from 'app/constants';
import { OrganizationModel } from 'app/models';
import { OrganizationStore } from 'app/stores';

import RegistrationForm from '../RegistrationForm';

export interface OrgRegistrationFormProps {
  referral_code?: string;
  habitShortRegistration?: boolean;
  organization?: OrganizationModel;
  organizationStore?: OrganizationStore;
}

export class OrgRegistrationForm extends React.Component<OrgRegistrationFormProps> {
  @computed
  get organization() {
    return this.props.organization ?? this.props.organizationStore.organization.item;
  }

  orgCustomLocalization = (key) => {
    if (this.organization && this.organization.custom_localization) {
      return this.organization.custom_localization[key];
    }
    return null;
  };

  @computed
  get description() {
    return this.orgCustomLocalization('register.tagline') ?? undefined;
  }

  get logo() {
    return this.organization?.logo_urls?.registration;
  }

  @computed
  get title() {
    let title = undefined;
    if (this.props.habitShortRegistration) {
      title = 'Save Your Habits';
    } else if (this.orgCustomLocalization('register.heading')) {
      title = this.orgCustomLocalization('register.heading');
    }
    return title;
  }

  render() {
    const props = {
      ...this.props,
      title: this.title,
      description: this.description,
      organization: this.organization,
      logo: this.logo,
    };

    return <RegistrationForm {...props} />;
  }
}

export default inject(STORE_ORGANIZATION)(observer(OrgRegistrationForm));
