import { observable } from 'mobx';

import { ExerciseClassification } from 'app/constants';
import PersonalStatementWithAnswerStore from 'app/stores/PersonalStatementWithAnswerStore';

import { ExerciseAnswerModel } from './ExerciseAnswerModel';
import Model, { ModelJson } from './Model';
import { ScatterPlotLabels, ScatterPlotPoint } from './ScatterPlotModel';

export class PersonalStatementWithAnswerModel extends Model {
  static _store: PersonalStatementWithAnswerStore;

  @observable id: number;
  @observable title: string;
  @observable scaledX: number;
  @observable scaledY: number;
  @observable placed: boolean;
  @observable answer: ExerciseAnswerModel;
  @observable classification: ExerciseClassification;
  @observable labels: ScatterPlotLabels;
  @observable points: ScatterPlotPoint[];

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PersonalStatementWithAnswerModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PersonalStatementWithAnswerModel;
  }

  static get(id) {
    return this._get(id) as PersonalStatementWithAnswerModel;
  }
}

export default PersonalStatementWithAnswerModel;
