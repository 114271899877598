import * as Sentry from '@sentry/react';

import { isTesting } from 'app/helpers/Utils';

export default class ThirdPartyService {
  public static fullStory = {
    identify: (...args): void => {
      ThirdPartyService.runSafely(() => window.FS?.identify(...args));
    },
  };

  public static posthog = {
    capture: (...args): void => {
      ThirdPartyService.runSafely(() => window.posthog?.capture(...args));
    },
  };

  public static sentry = {
    withScope: (scope): void => {
      ThirdPartyService.runSafely(() => Sentry.withScope(scope));
    },

    captureException: (error: any): void => {
      if (ThirdPartyService.shouldIgnoreSentryMessage(error)) {
        return;
      }

      if (error instanceof Error) {
        ThirdPartyService.runSafely(() => Sentry.captureException(error));
        return;
      }

      ThirdPartyService.sentry.withScope((scope) => {
        scope.setExtra('context', error);
        Sentry.captureMessage(error?.message ?? 'Non-Error object passed to captureException');
      });
    },

    captureMessage: (message: string): void => {
      if (ThirdPartyService.shouldIgnoreSentryMessage(message)) {
        return;
      }

      ThirdPartyService.runSafely(() => Sentry.captureMessage(message));
    },
  };

  private static runSafely = (callback): void => {
    // When testing don't call any third-party service
    if (isTesting()) {
      return;
    }

    try {
      callback();
    } catch (err) {
      console.error(err);
    }
  };

  private static shouldIgnoreSentryMessage = (error: any): boolean => {
    const message = ((typeof error === 'string' ? error : error?.message) || '').toLowerCase();

    // Ignore network errors as we don't have control over them
    if (message === 'network error') {
      return true;
    }

    // Ignore errors that are user-facing and not technical
    if (message === 'error. please try again.') {
      return true;
    }

    return false;
  };
}
