import React, { ComponentClass, ReactNode } from 'react';

import { Col, Row, Steps } from 'antd';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, Switch, withRouter } from 'react-router';

import './PersonalHabits.scss';

import EndOfPageRow from 'app/components/ui/EndOfPageRow';
import FakeBox from 'app/components/ui/FakeBox';
import { CreateHabitsIcon } from 'app/components/ui/icons';
import PageTitle from 'app/components/ui/PageTitle';
import {
  PulseStepKey,
  STORE_MEMBER,
  STORE_ORGANIZATION,
  STORE_PULSE_FOR_MEMBER,
  STORE_PULSE_REMINDER,
  STORE_PULSE_TEMPLATE_GROUP,
} from 'app/constants';
import Route from 'app/customRoute';
import { MemberModel } from 'app/models';
import PulseHabitsContext from 'app/pages/dashboard/Pulse/Context/PulseHabitsContext';
import PreviewModal from 'app/pages/dashboard/Pulse/PreviewModal';
import {
  ConfigureStep,
  CreateHabitsStep,
  InvitePeersStep,
  ReviewStep,
} from 'app/pages/dashboard/Pulse/PulseSteps';
import {
  MemberStore,
  PulseForMemberStore,
  PulseReminderStore,
  PulseTemplateGroupStore,
} from 'app/stores';

import HabitCard from '../PersonalHabitList/HabitCard';
import PersonalHabitsTestDriveUIStore from './PersonalHabitsTestDriveUIStore';

const { Step } = Steps;

export interface StepItem {
  name: string;
  title: string;
  component: ComponentClass;
}

// Map stepper items
const STEPS: StepItem[] = [
  { name: PulseStepKey.Add, title: 'Select habits', component: CreateHabitsStep },
  { name: PulseStepKey.Invite, title: 'Add colleagues', component: InvitePeersStep },
  { name: PulseStepKey.Configure, title: 'Configure cadence', component: ConfigureStep },
  { name: PulseStepKey.Review, title: 'Review habits', component: ReviewStep },
  { name: PulseStepKey.TestDriveListing, title: 'View Habit', component: HabitCard },
];

interface Params {
  step?: PulseStepKey;
}

export interface PersonalHabitsTestDriveProps extends RouteComponentProps<Params> {
  currentMember: MemberModel;
  pulseBasePath: string;
  pulseForMemberStore?: PulseForMemberStore;
  pulseTemplateGroupStore?: PulseTemplateGroupStore;
  memberStore?: MemberStore;
  pulseReminderStore?: PulseReminderStore;
}

export class PersonalHabitsTestDrive extends React.Component<PersonalHabitsTestDriveProps> {
  uiStore: PersonalHabitsTestDriveUIStore;

  constructor(props: PersonalHabitsTestDriveProps) {
    super(props);
    this.init();
  }

  protected init = (): void => {
    this.props.memberStore.currentMember.setItem(this.props.currentMember);

    this.uiStore = new PersonalHabitsTestDriveUIStore({
      memberId: this.props.currentMember.id,
      pulseId: null,
      pulseBasePath: this.props.pulseBasePath,
      pulseForMemberStore: this.props.pulseForMemberStore,
      pulseTemplateGroupStore: this.props.pulseTemplateGroupStore,
      memberStore: this.props.memberStore,
      pulseReminderStore: this.props.pulseReminderStore,
      isTestDrive: true,
    });

    this.uiStore.load();

    // Start on add step
    this.props.history.push(this.uiStore.getStepUrl('add'));
  };

  get isLoading(): boolean {
    return this.uiStore.isLoading;
  }

  protected getStepIndexByName(name: PulseStepKey): number {
    return STEPS.findIndex((item: StepItem) => item.name === name);
  }

  protected get currentStep(): number {
    const stepName = this.props.match.params.step;
    return this.getStepIndexByName(stepName);
  }

  @computed
  protected get onListingPage(): boolean {
    return this.props.match.params.step === PulseStepKey.TestDriveListing;
  }

  protected renderStep = (props: RouteComponentProps, item: StepItem): ReactNode => {
    if (item.name === PulseStepKey.TestDriveListing) {
      return (
        <item.component
          {...props}
          {...{
            habit: this.uiStore.activePulse,
            pulseForMemberStore: this.props.pulseForMemberStore,
            hideHeaderMenu: true,
            hideActions: true,
          }}
        />
      );
    }

    return <item.component {...props} />;
  };

  public render(): ReactNode {
    const { match } = this.props;

    return (
      <PulseHabitsContext.Provider value={{ uiStore: this.uiStore }}>
        <div className="personal-habits">
          <div className="container">
            {this.isLoading && <FakeBox width="100%" height="20rem" className="m-4" />}

            {!this.isLoading && (
              <div className="personal-habits-content">
                <PageTitle
                  icon={<CreateHabitsIcon />}
                  title={this.onListingPage ? 'My Habit' : 'Create your habits'}
                />

                {!this.onListingPage && (
                  <Row className="stepper">
                    <Col span={24}>
                      <Steps current={this.currentStep}>
                        {STEPS.map((item: StepItem) => (
                          <Step title={item.title} key={`step-${item.name}`} />
                        ))}
                      </Steps>
                    </Col>
                  </Row>
                )}

                <div className="step-item">
                  <Switch>
                    {STEPS.map((item: StepItem) => (
                      <Route
                        exact
                        key={`route-${item.name}`}
                        path={`${match.path}/${item.name}`}
                        render={(props) => this.renderStep(props, item)}
                      />
                    ))}
                  </Switch>
                </div>
              </div>
            )}
          </div>
          <EndOfPageRow />
        </div>

        <PreviewModal
          habits={this.uiStore.validHabits}
          isOpen={this.uiStore.isPreviewOpen}
          onClose={this.uiStore.togglePreviewModal}
        />
      </PulseHabitsContext.Provider>
    );
  }
}
export default inject(
  STORE_PULSE_FOR_MEMBER,
  STORE_PULSE_TEMPLATE_GROUP,
  STORE_MEMBER,
  STORE_PULSE_REMINDER,
  STORE_ORGANIZATION
)(withRouter(observer(PersonalHabitsTestDrive)));
