import { observable } from 'mobx';

import PlainModel from './PlainModel';

export class PagingMetaModel extends PlainModel {
  @observable public current_page: number;
  @observable public from: number;
  @observable public last_page: number;
  @observable public path: string;
  @observable public per_page: number;
  @observable public to: number;
  @observable public total: number;
  @observable public search?: string;
}

export default PagingMetaModel;
