import { assign } from 'lodash';
import { action, computed, observable } from 'mobx';

import { PERSPECTIVE_DIMENSION_COLOR, PerspectiveDimension } from 'app/constants';
import PerspectivePersonalReportStore from 'app/stores/PerspectivePersonalReportStore';

import Model, { ModelJson } from './Model';

export interface PerspectivePersonalReportLens {
  lens: string;
  rawScore: string;
  binnedScore: number;
  title: string;
  description: string;
  left_label: string;
  right_label: string;
  left_tip: string;
  right_tip: string;
}

export interface PerspectiveDimensionLabels {
  introverted_label: string;
  extroverted_label: string;
  thinking_label: string;
  feeling_label: string;
  judging_label: string;
  perceiving_label: string;
  sensing_label: string;
  intuitive_label: string;
}

export class PerspectivePersonalReportDimension {
  type: PerspectiveDimension;
  title: string;
  description: string;
  alt_name: string;
  lenses: PerspectivePersonalReportLens[];
  labels: PerspectiveDimensionLabels;

  @observable isExplored = false;
  @action setIsExplored = (isExplored: boolean): void => {
    this.isExplored = isExplored;
  };

  constructor(props) {
    assign(this, props);
  }

  @computed
  get color(): string {
    return PERSPECTIVE_DIMENSION_COLOR[this.type];
  }
}

export class PerspectivePersonalReportModel extends Model {
  static _store: PerspectivePersonalReportStore;

  @observable id: number;
  @observable first_name: string;
  @observable email: string;
  @observable dimensions: PerspectivePersonalReportDimension[];

  deserialize_dimensions(dimensions: PerspectivePersonalReportDimension[]): void {
    this.dimensions = dimensions.map(
      (dimension) => new PerspectivePersonalReportDimension(dimension)
    );
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectivePersonalReportModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PerspectivePersonalReportModel;
  }

  static get(id) {
    return this._get(id) as PerspectivePersonalReportModel;
  }
}

export default PerspectivePersonalReportModel;
