import React from 'react';

import PulseStatement from 'app/components/features/PulseStatement';
import AntModal from 'app/components/ui/AntModal';
import { PulseStatementModel } from 'app/models';

const MODAL_WIDTH = 762;

interface PreviewModalProps {
  isOpen?: boolean;
  habits: PulseStatementModel[];
  onClose: () => void;
}

export const PreviewModal: React.FC<PreviewModalProps> = ({ isOpen, habits, onClose }) => (
  <AntModal
    title="Preview of your habits survey"
    isOpen={isOpen}
    onToggle={onClose}
    width={MODAL_WIDTH}
    footer={null}
  >
    {habits.map((habit) => (
      <PulseStatement key={habit.id} statement={habit} />
    ))}
  </AntModal>
);

export default PreviewModal;
