import React, { ReactNode } from 'react';

import cx from 'classnames';

import './MultiColumnLayout.scss';

export interface MultiColumnLayoutProps {
  title?: string;
  columns: ReactNode[];
  className?: string;
}

export const MultiColumnLayout: React.FC<MultiColumnLayoutProps> = ({
  title,
  columns,
  className,
}) => (
  <div className="multi-column-layout">
    {!!title && <p className={cx(`${className}`, { 'mb-4': className })}>{title}</p>}
    <div className="multi-column-layout-columns">
      {columns.map((column, index) => (
        <div key={index} className="multi-column-layout-column">
          {column}
        </div>
      ))}
    </div>
  </div>
);

export default MultiColumnLayout;
