import { ReactNode } from 'react';

import { assign } from 'lodash';
import { action, observable } from 'mobx';

import { MemberAvatarModel } from 'app/models/MemberAvatarModel';

import EmailActivityModel from './EmailActivityModel';
import ExerciseStatusModel from './ExerciseStatusModel';
import MemberModel from './MemberModel';
import PerspectiveMemberModel from './PerspectiveMemberModel';

/**
 * A general puprose member item model that is dervived from raw data or MemberModel.
 */
export class MemberItem {
  @observable id?: number;
  @observable name: string;
  @observable email: string;
  @observable role?: string;
  @observable avatar?: MemberAvatarModel;
  @observable isNew = false;
  @observable hasError = false;
  @observable inviteLink?: string;
  @observable lastReminded: string;

  // Used in a select table or drop downs
  @observable isSelected = false;

  @observable isDisabled?: boolean;

  @observable exerciseStatus?: ExerciseStatusModel;
  @observable action?: ReactNode;

  @observable lastEmailInvite?: EmailActivityModel;

  constructor(props) {
    this.update(props);
  }

  @action
  setIsSelected(isSelected: boolean): void {
    this.isSelected = isSelected;
  }

  @action
  update(props): void {
    assign(this, props);
  }

  toggleSelected(): void {
    this.setIsSelected(!this.isSelected);
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      role: this.role,
      avatar: this.avatar,
    };
  }

  static fromMemberModel(
    member: MemberModel | PerspectiveMemberModel,
    overrides?: Partial<MemberItem>
  ): MemberItem {
    return new MemberItem(
      Object.assign(
        {
          id: member.id,
          name: member.name,
          email: member.email,
          isSelected: false,
          isDisabled: false,
        },
        overrides
      )
    );
  }
}
