import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem } from 'app/models';
import { TeamsSubscriptionStatusModel } from 'app/models/TeamsSubscriptionStatusModel';
import Store from 'app/stores/Store';

export class TeamsSubscriptionStatusStore extends Store<TeamsSubscriptionStatusModel> {
  @observable teamsSubscriptionStatus = new ModelItem<TeamsSubscriptionStatusModel>(
    TeamsSubscriptionStatusModel
  );

  constructor() {
    super();
    TeamsSubscriptionStatusModel._store = this;
  }

  async loadMemberTeamsSubscriptionStatus(orgId?: number): Promise<void> {
    const url = ServerRouteHelper.api.subscriptions.member.teamsStatus(orgId);

    await this.teamsSubscriptionStatus.load(url);
  }
}

export default TeamsSubscriptionStatusStore;
