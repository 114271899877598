import React from 'react';

import { Button } from 'antd';

import { ModalAction } from './AntModal';

const ActionButton: React.FC<ModalAction> = ({ label, ...rest }) => {
  return <Button {...rest}>{label}</Button>;
};

export default ActionButton;
