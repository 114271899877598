import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import DiscussionSpaceRaisedHandModel from 'app/models/DiscussionSpaceRaisedHandModel';
import Store from 'app/stores/Store';

export class DiscussionSpaceRaisedHandStore extends Store<DiscussionSpaceRaisedHandModel> {
  @observable token;
  @action setToken = (token: string): void => {
    this.token = token;
  };

  constructor() {
    super();
    DiscussionSpaceRaisedHandModel._store = this;
  }

  findRaisedHandsForDiscussionSpace = (
    discussionSpaceID: number
  ): DiscussionSpaceRaisedHandModel[] => {
    return Array.from(this.entities.values()).filter(
      (entry) => entry.discussion_space_id === discussionSpaceID
    );
  };

  async updateRaisedHandForDiscussionSpace(
    discussion_space_id: number,
    active: boolean,
    uuid: string,
    hand: DiscussionSpaceRaisedHandModel
  ): Promise<DiscussionSpaceRaisedHandModel> {
    let url = ServerRouteHelper.api.discussionSpace.raiseHand(discussion_space_id, hand.member.id);

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    const response = await this.apiService.post(url, { active, uuid });

    if (!response?.data) {
      return;
    }

    if (hand) {
      hand.updateFromJson(response.data);
      return hand;
    }

    return DiscussionSpaceRaisedHandModel.fromJson(response.data);
  }
}

export default DiscussionSpaceRaisedHandStore;
