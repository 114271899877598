import React from 'react';

import { computed } from 'mobx';

import './ExerciseStatementsSubmitCard.scss';

import Card from 'app/components/ui/Card';
import { MemberModel } from 'app/models';

import ExerciseUIStore from '../ExerciseUIStore';
import SubmissionPlaceholder from '../SubmissionPlaceholder';
import ExerciseSubmitContent from './ExerciseSubmitContent';

export interface ExerciseStatementsSubmitCardProps {
  uiStore?: ExerciseUIStore;
  token: string;
  history: any;
  toggleSubmitModal: () => void;
  showSubmitModal: boolean;
  nextStepUrl?: string;
  onSurveyMember?: (member: Partial<MemberModel>) => void;
  isRunningSubmitAnimation: boolean;
  animateStickies?: boolean;
  onSubmitAnswersCallback?: () => void;
}

export class ExerciseStatementsSubmitCard extends React.Component<ExerciseStatementsSubmitCardProps> {
  @computed
  get showTeamContent(): boolean {
    return this.props.uiStore.exercise.is_team_align;
  }

  @computed
  get title(): React.ReactNode {
    return (
      <div className="card-title">
        <img className="image" src="/images/align/align-exercise-scatterplot-icon.svg" />
        <div className="right-section">
          <p className="header">Thank you for your ratings</p>
          <p className="sub-header">We just need one more thing from you</p>
        </div>
      </div>
    );
  }

  @computed
  get header(): React.ReactNode {
    if (!this.showTeamContent) {
      return null;
    }

    return (
      <div>
        <p>
          To track the team’s submissions, we need to ask for your details. Don’t worry your answers
          are still anonymous.
        </p>
      </div>
    );
  }

  @computed
  get footer(): React.ReactNode {
    if (!this.showTeamContent) {
      return null;
    }

    return (
      <div>
        <p className="whats-next">What’s next:</p>
        <p className="content-body">
          When the full team has submitted their responses, you'll receive your personalized report
          with insights on the team's strengths and opportunities for improvement.
        </p>
      </div>
    );
  }

  render() {
    return (
      <Card newDesign title={this.title} className="exercise-submit-card">
        {this.props.isRunningSubmitAnimation && <SubmissionPlaceholder />}

        {!this.props.isRunningSubmitAnimation && (
          <>
            {this.header}
            <ExerciseSubmitContent {...this.props} isCustomForm />
            {this.footer}
          </>
        )}
      </Card>
    );
  }
}

export default ExerciseStatementsSubmitCard;
