import ShiftModel from './ShiftModel';

export interface ExerciseThemeSectionModelProps {
  id: number;
  exercise_theme_id: number;
  order: number;
  header: string;
  created_at: string;
  updated_at: string;

  // @todo: consolidate this two field,
  // align_statement_ids might be outdated but is currently used
  align_statements?: any[];
  align_statement_ids: number[];
}

export class ExerciseThemeSectionModel extends ShiftModel<ExerciseThemeSectionModelProps> {
  id: number;
  exercise_theme_id: number;
  order: number;
  header: string;
  align_statements: any[];
  align_statement_ids: number[];
  created_at: string;
  updated_at: string;

  color?: string;

  constructor(props: ExerciseThemeSectionModelProps, color: string) {
    super(props);

    this.color = color;

    this.align_statement_ids =
      props.align_statement_ids ?? props.align_statements?.map(({ id }) => id);
  }
}

export default ExerciseThemeSectionModel;
