import React from 'react';

import { ReloadOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cx from 'classnames';

import './CardListActions.scss';

import { SERVICENOW_SECRET_CODE } from 'app/constants';
import { AlignModel } from 'app/models';

import { SubmitButton } from './SubmitButton';
import { TestDriveButton } from './TestDriveButton';

const SERVICE_NOW_LEADERSHIP_CAPABILITY_ALIGNMENT = 277;

export interface CardListActionsProps {
  onLearnMoreClick?: () => void;
  onReset: () => void;
  onSubmit: () => void;
  isSubmitDisabled: boolean;
  renderTestDriveView?: boolean;
  render360View?: boolean;
  isRunningSubmitAnimation?: boolean;
  exercise?: AlignModel;
}

export const CardListActions: React.StatelessComponent<CardListActionsProps> = ({
  onLearnMoreClick,
  onReset,
  onSubmit,
  isSubmitDisabled,
  renderTestDriveView,
  render360View,
  isRunningSubmitAnimation,
  exercise,
}) => {
  const buttonDisabled = () => (!renderTestDriveView || render360View) && isSubmitDisabled;

  const is360TestDrive = renderTestDriveView && render360View;
  const showHowItWorks =
    is360TestDrive ||
    (!renderTestDriveView && render360View && onLearnMoreClick && !isRunningSubmitAnimation);
  const serviceNowShowLeadershipOverview =
    exercise?.organization?.code === SERVICENOW_SECRET_CODE &&
    exercise?.exercise_type_id === SERVICE_NOW_LEADERSHIP_CAPABILITY_ALIGNMENT;

  const submitButton = () => {
    if (renderTestDriveView && !render360View) {
      return <TestDriveButton onClick={onSubmit} disabled={buttonDisabled()} />;
    }

    return <SubmitButton label="Next" disabled={buttonDisabled()} onClick={onSubmit} />;
  };

  return (
    <div className="exercise-statement-card-list-actions">
      <div className="learn-more-wrapper">
        {showHowItWorks && (
          <Button
            type="link"
            data-testid="learn-more-btn"
            onClick={onLearnMoreClick}
            className="learn-more-btn"
          >
            {is360TestDrive && 'Learn more about this exercise'}
            {!is360TestDrive && 'How it works'}
            <RightOutlined />
          </Button>
        )}

        {serviceNowShowLeadershipOverview && (
          <Button
            type="link"
            target="_blank"
            href="/pdfs/SN-Enterprise-Leadership-Capabilities.pdf"
          >
            Leadership Capability Overview
            <RightOutlined />
          </Button>
        )}
      </div>

      {!isRunningSubmitAnimation && (
        <div className={cx('action-buttons', { 'mt-4': renderTestDriveView })}>
          <button onClick={onReset} className="btn btn-secondary reset-btn">
            <ReloadOutlined /> Reset
          </button>

          {submitButton()}
        </div>
      )}
    </div>
  );
};

export default CardListActions;
