import { FullStory, init } from '@fullstory/browser';

export const initializeFullStory = (userJustAccepted = false): boolean => {
  const useAcceptedPerformanceCookies =
    accompany.currentUserAcceptedPerformanceCookies || userJustAccepted;
  const fsActive = accompany.fs.active;

  if (fsActive && useAcceptedPerformanceCookies) {
    init({
      orgId: accompany.fs.org_id,
      host: 'fsrelay.valence.co',
      script: 'fsrelay.valence.co/s/fs.js',
      appHost: 'app.fullstory.com',
    });

    if (accompany.fs?.tracking_data) {
      const data = accompany.fs.tracking_data;

      FullStory('setIdentity', {
        uid: data.uid,
        properties: data,
      });
    }
  }

  return fsActive && useAcceptedPerformanceCookies;
};
