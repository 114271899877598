import React from 'react';

import { startsWith } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface ScrollToTopProps extends RouteComponentProps {
  exclude?: string[];
}

class ScrollToTop extends React.Component<ScrollToTopProps> {
  componentDidUpdate(prevProps) {
    if (this.allow(this.props.location.pathname, prevProps.location.pathname)) {
      window.scrollTo(0, 0);
    }
  }

  allow = (currentPathname, prevPathname) => {
    const isNewPath = currentPathname !== prevPathname;
    if (!this.props.exclude) {
      return isNewPath;
    }

    const isExcluded = this.props.exclude.find((path) => startsWith(currentPathname, path));
    return isNewPath && !isExcluded;
  };

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
