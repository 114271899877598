import { runIntercom } from 'app/vendors/intercom/intercom';

export const initializeIntercom = () => {
  const intercomActive = accompany.intercom.active;
  const intercomData = accompany.intercom.tracking_data;
  if (!intercomActive || !intercomData) {
    return;
  }

  window.intercomSettings = intercomData;
  runIntercom(intercomData.app_id);
};
