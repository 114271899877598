import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem, PagingMetaModel, SubscriptionOrderModel } from 'app/models';
import { ModelList } from 'app/models/ModelList';
import SubscriptionItemModel from 'app/models/SubscriptionItemModel';
import SubscriptionItemProviderModel from 'app/models/SubscriptionItemProviderModel';
import Store from 'app/stores/Store';

export class SubscriptionOrderStore extends Store<SubscriptionOrderModel> {
  @observable subscriptionOrders = new ModelList<SubscriptionOrderModel>(SubscriptionOrderModel);
  @observable pageMeta: PagingMetaModel;

  @observable subscriptionOrder = new ModelItem<SubscriptionOrderModel>(SubscriptionOrderModel);

  @observable isCreatingOrder = false;
  @action setIsCreatingOrder = (value: boolean): void => {
    this.isCreatingOrder = value;
  };

  constructor() {
    super();
    SubscriptionOrderModel._store = this;
  }

  async getSubscriptionOrders(orgId: number, page?: number): Promise<void> {
    const params = page ? { page } : undefined;
    const url = ServerRouteHelper.api.subscriptions.orders.list(orgId, params);

    this.subscriptionOrders.load(url, undefined, {
      onResponse: (response) => {
        this.pageMeta = new PagingMetaModel(response.meta);
      },
    });
  }

  async getSubscriptionOrderById(id: number): Promise<void> {
    const url = ServerRouteHelper.api.subscriptions.orders.get(id);
    this.subscriptionOrder.load(url);
  }

  async createSubscriptionOrder(
    orgId: number,
    subscriptionItem: SubscriptionItemModel,
    subscriptionItemProvider: SubscriptionItemProviderModel,
    memberIds: number[],
    newMembers: { name: string; email: string }[]
  ): Promise<any> {
    this.setIsCreatingOrder(true);

    try {
      const url = ServerRouteHelper.api.subscriptions.orders.create(orgId);

      const config = {
        url,
        data: {
          subscription_item_id: subscriptionItem.id,
          subscription_item_provider_id: subscriptionItemProvider.id,
          member_ids: memberIds,
          new_members: newMembers,
        },
        throwError: true,
      };

      return this.apiService.newPost(config);
    } finally {
      this.setIsCreatingOrder(false);
    }
  }
}

export default SubscriptionOrderStore;
