import React, { Component, ReactNode } from 'react';

import { Col, Menu, MenuProps, notification, Popconfirm, Row, Tag } from 'antd';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';

import styles from './HabitCard.module.scss';

import EnhancedCard, { Clickable } from 'app/components/ui/EnhancedCard';
import Markdown from 'app/components/ui/Markdown';
import MemberAvatar from 'app/components/ui/MemberAvatar';
import { ServerRouteHelper } from 'app/helpers';
import { PulseForMemberModel } from 'app/models';
import HabitTrackerCard from 'app/pages/dashboard/Team/TeamHabits/TeamHabitsList/HabitCard/HabitTrackerCard';
import { PulseForMemberStore } from 'app/stores';

interface HabitCardProps extends RouteComponentProps {
  habit: PulseForMemberModel;
  hideHeaderMenu?: boolean;
  hideActions?: boolean;
  pulseForMemberStore: PulseForMemberStore;
}

export class HabitCard extends Component<HabitCardProps> {
  @computed
  get habit(): PulseForMemberModel {
    return this.props.habit;
  }

  private get hasReport(): boolean {
    return this.habit.has_report_data;
  }

  @computed
  get reminderMenuItem(): ReactNode {
    if (this.habit.disabled) {
      return null;
    }

    return (
      <Popconfirm
        key={`${this.habit.id}_reminder_confirm`}
        title={<p>Are you sure you want to send a reminder</p>}
        onConfirm={this.handleSendReminder}
        okText="Yes"
        cancelText="No"
      >
        <Menu.Item key={`${this.habit.id}_reminder`} disabled={this.habit.deleting}>
          Send reminder
        </Menu.Item>
      </Popconfirm>
    );
  }

  @computed
  get headerTag(): ReactNode {
    if (!this.habit.disabled) {
      return null;
    }

    return <Tag color="gold">PAUSED</Tag>;
  }

  @computed
  get headerMenu(): MenuProps['items'] {
    if (this.props.hideHeaderMenu) {
      return null;
    }

    return [
      {
        label: this.reminderMenuItem,
        key: 'reminder-personal-habit',
      },
      {
        label: (
          <span className={styles.pauseSpan} onClick={this.togglePause}>
            {this.habit.disabled ? 'Resume' : 'Pause'}
          </span>
        ),
        key: `${this.habit.id}_toggle_pause`,
      },
      {
        label: (
          <Popconfirm
            key={`${this.habit.id}_delete_confirm`}
            title={<p>Are you sure you want to delete this Habit</p>}
            onConfirm={this.handleDelete}
            okText="Yes"
            cancelText="No"
          >
            <Menu.Item key={`${this.habit.id}_delete`} disabled={this.habit.deleting}>
              Delete
            </Menu.Item>
          </Popconfirm>
        ),
        key: `${this.habit.id}_delete`,
      },
    ];
  }

  handleSendReminder = async (): Promise<void> => {
    await this.props.pulseForMemberStore.reminder(this.habit);
    notification.success({ message: 'Reminder sent successfully.', placement: 'bottomRight' });
  };

  handleDelete = async (): Promise<void> => {
    await this.props.pulseForMemberStore.delete(this.habit);
    notification.success({ message: 'Habit deleted successfully.', placement: 'bottomRight' });
  };

  togglePause = () => {
    this.props.pulseForMemberStore.togglePause(this.habit, !this.habit.disabled);
    this.habit.disabled = !this.habit.disabled;
  };

  @computed
  get viewResultsButton(): Clickable {
    if (this.props.hideActions) {
      return null;
    }

    const url = ServerRouteHelper.pulses.report(this.habit.owner.item.id, this.habit.id);
    return {
      label: 'View results',
      disabled: !this.hasReport,
      tooltip:
        !this.hasReport && 'Your results will be available after the participants have responded.',
      onClick: () => window.open(url, '_self'),
    };
  }

  @computed
  get editHabitButton(): Clickable {
    if (this.props.hideActions) {
      return null;
    }

    const url = ServerRouteHelper.dashboard.myself.personalHabitsEdit(this.habit.id, 'add');

    return {
      label: 'Edit habit',
      onClick: () => {
        this.props.history.push(url);
      },
    };
  }

  @computed
  get invitedPeers(): ReactNode {
    return (
      <div className={styles.invitedPeers}>
        <h6>Invited colleagues</h6>
        <ul className={styles.participants}>
          {this.habit.participants.items.map((member) => (
            <li key={member.id}>
              <MemberAvatar {...member.avatar} /> {member.name}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  @computed
  get cadenceSummary(): ReactNode {
    return (
      <div className={styles.cadenceSummary}>
        <h6>Cadence</h6>
        <Markdown source={this.habit.reminder?.summary} />
      </div>
    );
  }

  render(): ReactNode {
    if (this.props.pulseForMemberStore.pulse.loading) {
      return null;
    }

    return (
      <EnhancedCard
        className={styles.habitCard}
        heading="Personal habits"
        headerMenu={this.headerMenu}
        footerPrimaryButton={this.viewResultsButton}
        footerSecondaryButton={this.editHabitButton}
        headerTag={this.headerTag}
      >
        <div className={styles.cardContentContainer}>
          <Row gutter={24}>
            <Col span={16}>
              <HabitTrackerCard habit={this.habit} />
            </Col>
            <Col span={8}>
              <Row gutter={24}>
                <Col span={24}>{this.invitedPeers}</Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>{this.cadenceSummary}</Col>
              </Row>
            </Col>
          </Row>
        </div>
      </EnhancedCard>
    );
  }
}

export default withRouter(observer(HabitCard));
