import * as React from 'react';

import { inject, observer } from 'mobx-react';

import './MemberAvatarDemo.scss';

import MemberAvatar from 'app/components/ui/MemberAvatar';
import { STORE_MEMBER } from 'app/constants';
import MemberStore from 'app/stores/MemberStore';

export interface MemberAvatarDemoProps {
  [STORE_MEMBER]?: MemberStore;
}

export class MemberAvatarDemo extends React.Component<MemberAvatarDemoProps> {
  /**
   * Called once a component is mounted
   * We'll load our stores here. This way we don't need to load every store
   * on application boot. Just when a component needs it.
   */
  constructor(props) {
    super(props);
    this.props[STORE_MEMBER].loadCurrentMember();
  }

  isLoading() {
    return this.props[STORE_MEMBER].currentMember.loading;
  }

  /**
   * Render gets called when ever the object redraws. This can happen
   * when the state changes, or when a store updates. It's important
   * that we keep it lean. Do processing elsewhere.
   */
  render() {
    // The stores should always be treated as async. Even if we are
    // getting the variable from a hash, we should still add a loading
    // page for the component. That way we can make it an API call later
    // without breaking everything
    if (this.isLoading()) {
      return <div>Loading...</div>;
    }

    // Get the parameters from the store
    const { currentMember } = this.props[STORE_MEMBER];
    const { name, avatar } = currentMember.item;

    // Return the JSX object
    return (
      <div className="member-avatar-demo">
        <div>
          <h5>This block is rendered using React.</h5>
        </div>
        <div>
          <MemberAvatar {...currentMember.item.avatar} />
          <span>{name}</span>
        </div>
        <div className="mt-2">
          <div className="form-group">
            <label htmlFor="avatar-text">Avatar Initials:</label>
            <input
              id="avatar-text"
              className="form-control"
              onChange={({ target }) => (avatar.text = target.value)}
              value={avatar.text}
              type="text"
            />
          </div>

          <div className="form-group">
            <label htmlFor="avatar-color">Avatar Color:</label>
            <input
              id="avatar-color"
              className="form-control"
              onChange={({ target }) => (avatar.color = target.value)}
              value={avatar.color}
              type="text"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default inject(STORE_MEMBER)(observer(MemberAvatarDemo));
