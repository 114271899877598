import { isEqual } from 'lodash';
import { action, computed, observable } from 'mobx';

import { URLBuilder } from 'app/helpers/URLBuilder';
import { Model } from 'app/models/Model';
import { ShiftError } from 'app/models/ShiftError';
import { ApiService } from 'app/services/ApiService';

export type CancelHandler = () => void;

export enum LOAD_METHOD {
  GET = 'get',
  POST = 'POST',
}

export abstract class ModelContainer<T extends Model, F = unknown> {
  @observable public error: ShiftError;
  @action setError = (e: ShiftError) => (this.error = e);
  @observable public loading: boolean;
  @action setLoading = (loading: boolean) => (this.loading = loading);
  @observable public loaded: boolean;
  @action setLoaded = (loaded: boolean) => (this.loaded = loaded);

  protected url: URLBuilder;
  protected params: { [key: string]: any };
  protected requestId: string;

  constructor(
    protected modelClass: typeof Model,
    protected apiService = ApiService.getInstance()
  ) {}

  @computed
  get hasError() {
    return !!this.error;
  }

  @action
  public async load(
    url: URLBuilder,
    params?: { [key: string]: any },
    config?: {
      dataKey?: string;
      forceRefresh?: boolean;
      throwError?: boolean;
      method?: LOAD_METHOD;
      redirectIfUnauthorized?: boolean;
      headers?: Record<string, any>;
      append?: boolean;
      skipCancel?: boolean;
      cancelHandler?: (handler: CancelHandler) => void;
      onResponse?: (response: any) => void;
    }
  ): Promise<any> {
    // Allow loading via custom method but default to GET if not defined
    const serviceMethod = config?.method ?? LOAD_METHOD.GET;

    // config.datakey can be null.
    const dataKey = config?.dataKey === undefined ? 'data' : config.dataKey;
    const forceRefresh = config && config.forceRefresh;
    const headers = config?.headers ?? null;

    const isSameUrl = this.url?.toString() === url.toString();
    const areSameParams = (!this.params && !params) || isEqual(this.params, params);
    const isSameRequest = isSameUrl && areSameParams;
    const append = config?.append ?? false;
    const skipCancel = config?.skipCancel ?? false;
    const throwError = config?.throwError ?? true;

    if (isSameRequest && this.loaded && !forceRefresh) {
      return;
    }

    if (this.loading && isSameRequest) {
      return;
    }

    // If this container is already loading and cancellation
    // wasn't skipped, cancel it pending request
    if (this.loading && !skipCancel) {
      const requestConfig = { url: this.url };
      this.apiService.cancelRequest(this.requestId, requestConfig);
    }

    this.setError(null);
    this.setLoaded(false);
    this.setLoading(true);
    this.url = url;
    this.params = params;
    this.requestId = this.apiService.generateRequsetId();

    config?.cancelHandler?.(() => {
      const requestConfig = { url: this.url };
      this.apiService.cancelRequest(this.requestId, requestConfig);
    });

    const redirectIfUnauthorized = config?.redirectIfUnauthorized ?? true;
    let response;

    try {
      const requestConfig = {
        url,
        headers,
        data: undefined,
        params: undefined,
        requestId: this.requestId,
        redirectIfUnauthorized,
        throwError,
      };

      if (serviceMethod === LOAD_METHOD.POST) {
        requestConfig.data = params;
        response = await this.apiService.newPost(requestConfig);
      } else {
        requestConfig.params = params;
        response = await this.apiService.newGet(requestConfig);
      }

      config?.onResponse?.(response);

      const data = dataKey ? response[dataKey] : response;
      this.onSuccess(data, append);
    } catch (e) {
      this.onError(e);
    }

    // return response to allow caller access to data
    return response;
  }

  abstract deserialize(data: any, append?: boolean);

  @action
  protected onSuccess(response, append = false) {
    this.setLoaded(true);
    this.setLoading(false);
    this.deserialize(response, append);
  }

  @action
  protected onError(error) {
    this.setLoaded(true);
    this.setLoading(false);
    this.setError(error);
  }
}
