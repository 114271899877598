import React from 'react';

import './ExerciseStatementCard.scss';

export interface ExerciseStatementCardSimpleProps {
  title: string;
  style?: { [key: string]: any };
}

export const ExerciseStatementCardSimple: React.FC<ExerciseStatementCardSimpleProps> = ({
  title,
  style,
}) => (
  <div className="exercise-statement-card simple" style={style}>
    <div className="header"></div>
    <div className="body">{title}</div>
  </div>
);

export default ExerciseStatementCardSimple;
