import { keyBy } from 'lodash';
import { computed, observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { OrganizationReportRollupStore } from 'app/stores';

import OrganizationReportRollupTeamGroupModel from './OrganizationReportRollupTeamGroupModel';
import { TeamGroupDivisionOptions } from './TeamGroupDivisionModel';

export class OrganizationReportRollupDivisionModel extends Model {
  static _store: OrganizationReportRollupStore;

  @observable id: number;
  @observable name: string;
  @observable order?: number;
  @observable team_groups: OrganizationReportRollupTeamGroupModel[];
  @observable options?: TeamGroupDivisionOptions;

  deserialize_team_groups(teamGroups) {
    this.team_groups = teamGroups.map((teamGroup) =>
      OrganizationReportRollupTeamGroupModel.fromJson(teamGroup)
    );
  }

  @computed
  get displayName(): string {
    return this.options?.label ?? this.name;
  }

  // Valid parent-only team groups
  @computed
  get validTeamGroups(): OrganizationReportRollupTeamGroupModel[] {
    return this.team_groups.filter((teamGroup) => {
      return teamGroup.exercise_types?.length > 0 || teamGroup.validTeamGroups?.length > 0;
    });
  }

  @computed
  get allTeamGroupsById(): Record<number, OrganizationReportRollupTeamGroupModel> {
    return keyBy(this.team_groups, 'id');
  }

  // Team groups without parent but have exercise types
  @computed
  get parentTeamGroups(): OrganizationReportRollupTeamGroupModel[] {
    return this.groups.filter((teamGroup) => {
      return (
        teamGroup.is_overall ||
        (teamGroup.exercise_types?.length > 0 && !teamGroup.dependent_group_id)
      );
    });
  }

  // All valid child team groups
  @computed
  get childTeamGroups(): OrganizationReportRollupTeamGroupModel[] {
    return this.parentTeamGroups.reduce((acc, parentTeamGroup) => {
      return [...acc, ...parentTeamGroup.dependent_team_groups];
    }, []);
  }

  // All valid child team groups that are directly under division (not nested)
  @computed
  get directChildTeamGroups(): OrganizationReportRollupTeamGroupModel[] {
    return this.validTeamGroups.filter((teamGroup) => {
      return !!teamGroup.dependent_group_id;
    });
  }

  get groups(): OrganizationReportRollupTeamGroupModel[] {
    return this.team_groups;
  }

  static fromJson(json: ModelJson): OrganizationReportRollupDivisionModel {
    return this._fromJson(json) as OrganizationReportRollupDivisionModel;
  }

  static getOrNew(id: number | string): OrganizationReportRollupDivisionModel {
    return this._getOrNew(id) as OrganizationReportRollupDivisionModel;
  }

  static get(id: number | string): OrganizationReportRollupDivisionModel {
    return this._get(id) as OrganizationReportRollupDivisionModel;
  }
}

export default OrganizationReportRollupDivisionModel;
