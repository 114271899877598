export abstract class PlainModel {
  public props: Record<string, any>;

  constructor(props = null) {
    if (props) {
      for (const prop in props) {
        this[prop] = props[prop];
      }
    }
  }

  public static fromJson(props: Record<string, any>): any {
    return new (this as any)(props);
  }
}

export default PlainModel;
