export const ExerciseResources = [
  {
    align_id: 1,
    statement: 'We focus on the most important things',
    resources: [
      {
        title: 'How to Maximize Your Focus and Master Your Priorities',
        url: 'https://jamesclear.com/buffett-focus',
      },
      {
        title: 'TEDTalk: Why the secret to success is setting the right goals',
        url: 'https://embed.ted.com/talks/lang/en/john_doerr_why_the_secret_to_success_is_setting_the_right_goals',
      },
    ],
  },
  {
    align_id: 2,
    statement: 'We are efficient in our meetings',
    resources: [
      {
        title: 'Want a better decision? Plan a better meeting',
        url: 'https://www.mckinsey.com/business-functions/organization/our-insights/want-a-better-decision-plan-a-better-meeting',
      },
      {
        title: 'How To Run a Meeting',
        url: 'https://hbr.org/1976/03/how-to-run-a-meeting',
      },
    ],
  },
  {
    align_id: 3,
    statement: 'We hold ourselves to high standards',
    resources: [
      {
        title: 'Important Lessons in Creativity From Ed Catmull, Pixar Founder and Disney Legend',
        url: 'https://www.inc.com/bill-carmody/important-lessons-in-creativity-from-ed-catmull-pixar-founder-and-disney-legend.html',
      },
      {
        title: 'TEDTalk: Grit, The power of passion and perseverance',
        url: 'https://embed.ted.com/talks/lang/en/angela_lee_duckworth_grit_the_power_of_passion_and_perseverance',
      },
    ],
  },
  {
    align_id: 4,
    statement: 'We have clear roles',
    resources: [
      {
        title: 'How to turn a group of strangers into a team',
        url: 'https://embed.ted.com/talks/lang/en/amy_edmondson_how_to_turn_a_group_of_strangers_into_a_team',
      },
    ],
  },
  {
    align_id: 5,
    statement: "We admit if we are wrong or don't know",
    resources: [
      {
        title: 'Iterating Quickly, Iterating Perfectly',
        url: 'https://highperformanceinstitute.mykajabi.com/blog/iterating-quickly-iterating-perfectly',
      },
      {
        title: 'Good Employees Make Mistakes. Great Leaders Allow Them To.',
        url: 'https://www.forbes.com/sites/amyanderson/2013/04/17/good-employees-make-mistakes-great-leaders-allow-them-to/?sh=b8d78f0126a4',
      },
    ],
  },
  {
    align_id: 6,
    statement: 'We genuinely care about each other',
    resources: [
      {
        title: 'Netflix Culture Manifesto',
        url: 'https://www.slideshare.net/reed2001/culture-1798664',
      },
      {
        title: 'How the words we use can get people on our side',
        url: 'https://www.businessinsider.com/satya-nadella-microsoft-nonviolent-communication-feelings-2018-11',
      },
    ],
  },
  {
    align_id: 7,
    statement: 'We deliver on our commitments',
    resources: [
      {
        title: 'The Right Way to Hold People Accountable',
        url: 'https://hbr.org/2016/01/the-right-way-to-hold-people-accountable',
      },
      {
        title: 'Promise-Based Management: The Essence of Execution',
        url: 'https://hbr.org/2007/04/promise-based-management-the-essence-of-execution',
      },
    ],
  },
  {
    align_id: 8,
    statement: "We don't let resentments build up",
    resources: [
      {
        title: 'The 4-Part Nonviolent Communication',
        url: 'https://www.nonviolentcommunication.com/aboutnvc/4partprocess.htm',
      },
      {
        title: 'Conflict Keeps Teams at the Top of Their Game',
        url: 'https://hbr.org/2012/07/conflict-keeps-teams-at-the-to',
      },
    ],
  },
  {
    align_id: 9,
    statement: 'We make decisions quickly',
    resources: [
      {
        title: "Untangling your organization's decision making",
        url: 'https://www.mckinsey.com/business-functions/organization/our-insights/untangling-your-organizations-decision-making',
      },
      {
        title: 'How to Make Good Decisions as a Team',
        url: 'https://www.inc.com/les-mckeown/how-to-make-good-decisions-as-a-team.html',
      },
    ],
  },
  {
    align_id: 10,
    statement: 'We have fun together',
    resources: [
      {
        title: 'Netflix Culture Manifesto',
        url: 'https://jobs.netflix.com/culture',
      },
      {
        title: 'Study: Being happy at work really makes you more productive',
        url: 'https://fortune.com/2015/10/29/happy-productivity-work/',
      },
    ],
  },
  {
    align_id: 11,
    statement: 'We actively surface & address problems',
    resources: [
      {
        title: 'The Five Whys',
        url: 'https://en.wikipedia.org/wiki/Five_whys',
      },
      {
        title: 'TEDTalk: Forget the pecking order at work',
        url: 'https://embed.ted.com/talks/lang/en/margaret_heffernan_forget_the_pecking_order_at_work',
      },
    ],
  },
  {
    align_id: 12,
    statement: "We don't let our egos get in the way",
    resources: [
      {
        title: 'Calming Your Brain During Conflict',
        url: 'https://hbr.org/2015/12/calming-your-brain-during-conflict',
      },
      {
        title: 'The Observing Self: A Tool Essential to Save Ourselves and Our Planet',
        url: 'https://www.huffpost.com/entry/the-observing-self-a-tool_b_6199126',
      },
    ],
  },
  {
    align_id: 13,
    statement: 'We communicate well',
    resources: [
      {
        title: 'The Hard Science of Teamwork',
        url: 'https://hbr.org/2012/03/the-new-science-of-building-gr',
      },
      {
        title: 'TEDTalk: 5 ways to listen better',
        url: 'https://embed.ted.com/talks/lang/en/julian_treasure_5_ways_to_listen_better',
      },
    ],
  },
  {
    align_id: 14,
    statement: 'We feel safe with one another',
    resources: [
      {
        title: 'Why psychological safety matters and what to do about it',
        url: 'https://rework.withgoogle.com/blog/how-to-foster-psychological-safety/',
      },
      {
        title: 'Personal Histories Exercise',
        url: 'https://www.agilecafe.org/wp-content/uploads/Personal-Histories-Exercise.pdf',
      },
    ],
  },
  {
    align_id: 15,
    statement: 'We give honest feedback to each other',
    resources: [
      {
        title: 'Immediately Improve Your Talent Development with the SBI Feedback Model',
        url: 'https://www.ccl.org/articles/leading-effectively-articles/hr-pipeline-a-quick-win-to-improve-your-talent-development-process/',
      },
      {
        title: 'A Feedback Exercise For Teams That Gets Results',
        url: 'https://www.linkedin.com/pulse/feedback-exercise-teams-gets-results-lou-bergholz/',
      },
    ],
  },
  {
    align_id: 16,
    statement: 'We let good ideas come from anywhere',
    resources: [
      {
        title: 'How to Really Listen to Your Employees',
        url: 'https://hbr.org/2015/01/how-to-really-listen-to-your-employees',
      },
      {
        title: 'A New Way to Become More Open-Minded',
        url: 'https://hbr.org/2018/11/a-new-way-to-become-more-open-minded',
      },
    ],
  },
];
