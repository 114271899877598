import React from 'react';

import cx from 'classnames';

import Logo from './Logo';

interface NavbarLogoProps {
  wrapperClassName?: string;
}

const NavbarLogo: React.StatelessComponent<NavbarLogoProps> = ({ wrapperClassName }) => (
  <Logo className={cx('navbar-brand', wrapperClassName)} />
);

export default NavbarLogo;
