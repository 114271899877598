import { computed, observable } from 'mobx';
import moment from 'moment';

import { ExerciseStatusHumanKeys, ExerciseStatusKeys } from 'app/constants';
import Model, { ModelJson } from 'app/models/Model';
import ExerciseStatusStore from 'app/stores/ExerciseStatusStore';

export interface ExerciseStatusProps {
  id: number;
  status: string;
  date: string;
  member_id: number;
}

export class ExerciseStatusModel extends Model {
  static _store: ExerciseStatusStore;

  @observable id: number | string;
  @observable status: string;
  @observable date: string;
  @observable member_id: number;

  public get prettyStatus() {
    return ExerciseStatusHumanKeys[this.status];
  }

  public get prettyDate() {
    return moment(this.date).format('MMM D, YYYY');
  }

  public toString() {
    if (!this.status) {
      return ExerciseStatusHumanKeys.invite_not_sent;
    }

    if (this.status === ExerciseStatusKeys.completed_align) {
      return this.prettyStatus;
    }

    return `${this.prettyStatus} ${this.prettyDate}`;
  }

  @computed
  public get isReminder() {
    return this.status === ExerciseStatusKeys.remind_align;
  }

  @computed
  public get isCompleted() {
    return this.status === ExerciseStatusKeys.completed_align;
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as ExerciseStatusModel;
  }
}

export default ExerciseStatusModel;
