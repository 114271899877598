import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { PerspectiveManagerInsightsStore } from 'app/stores';

interface Feedback {
  mainText: string;
  nutshell: string;
  positiveExample: string;
  negativeExample: string;
}

interface Tip {
  celebrate: string;
  challenges: string;
}

interface Structure {
  intro: string;
  assign: string;
  checkin: string;
  example: string;
}

interface Problem {
  intro: string;
  content: string;
  example: string;
  approach: string;
}
export class PerspectiveManagerInsightsModel extends Model {
  static _store: PerspectiveManagerInsightsStore;

  @observable id: string;
  @observable introduction: string;
  @observable sharingFeedbacks: Feedback;
  @observable managerTips: Tip;
  @observable memberFirstName: string;
  @observable structureWork: Structure;
  @observable problemSolve: Problem;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectiveManagerInsightsModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PerspectiveManagerInsightsModel;
  }

  static get(id) {
    return this._get(id) as PerspectiveManagerInsightsModel;
  }
}

export default PerspectiveManagerInsightsModel;
