import React, { ReactNode } from 'react';

import { CoffeeOutlined, MessageOutlined, ThunderboltFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';

import Card from 'app/components/ui/Card';
import EnhancedCard from 'app/components/ui/EnhancedCard';
import { ServerRouteHelper } from 'app/helpers';
import { ExerciseActivityActions } from 'app/stores';

import CommitToImproveExplainer from '../components/CommitToImproveExplainer';
import CommitToImproveSolidifyReflections from '../components/CommitToImproveSolidifyReflections/CommitToImproveSolidifyReflections';
import DiscoverHabitBuilder from '../components/DiscoverHabitBuilder';
import ReflectionCTACards from '../components/ReflectionCTACards';
import My360ReportUIStore from '../My360ReportUIStore';

interface CommitToImproveContainerProps extends RouteComponentProps {
  uiStore: My360ReportUIStore;
}

const COACH_BOOKING_URL = 'https://www.valence.co/coaching';

const reflectPromoCardContent = [
  {
    icon: <ThunderboltFilled className="with-coach" />,
    title: 'Reflect with a coach',
    content:
      'A coach who is familiar with 360s can help you interpret and contextualize your results.',
  },
  {
    icon: <MessageOutlined />,
    title: 'Reflect with a peer',
    content: 'A trusted peer or manager can help ask you questions and guide your reflection.',
  },
  {
    icon: <CoffeeOutlined />,
    title: 'Reflect on your own',
    content: 'A few reflection questions will help you translate your report into action steps.',
  },
];

export class CommitToImproveContainer extends React.Component<CommitToImproveContainerProps> {
  @computed
  get renderAuthenticatedView(): ReactNode {
    const { uiStore } = this.props;
    const personalReport = uiStore.report?.routes?.personalReport;

    return (
      <div className="align-center commit-to-improve">
        <CommitToImproveExplainer organization={uiStore.organization} exercise={uiStore.exercise} />
        <ReflectionCTACards
          personalReport={personalReport}
          onBookCoach={this.handleBookCoach}
          isTestDrive={this.isTestDrive}
          organization={uiStore.organization}
          exercise={uiStore.exercise}
        />
        <CommitToImproveSolidifyReflections {...{ uiStore }} />
        <DiscoverHabitBuilder
          reportMemberId={uiStore.report?.reportMember?.id}
          isTestDrive={this.isTestDrive}
        />
      </div>
    );
  }

  handleBookCoach = (): void => {
    const { uiStore } = this.props;

    if (this.isTestDrive) {
      return;
    }

    uiStore.exerciseReportStore.recordExerciseActivity(
      uiStore.token,
      uiStore.exercise.id,
      ExerciseActivityActions.My360SignedupForCoach
    );

    window.open(COACH_BOOKING_URL, '_blank', 'noopener');
  };

  handleRouteToLogin = (): void => {
    this.props.history.push(
      `${ServerRouteHelper.auth.login()}?redirect_to=${window.location.href}`
    );
  };

  @computed
  get renderGuestView(): ReactNode {
    return (
      <div>
        <div className="habit-promotion-cards">
          {reflectPromoCardContent.map((promoContent, contentIndex) => {
            return (
              <EnhancedCard
                key={contentIndex}
                heading={promoContent.title}
                headerIcon={promoContent.icon}
                className="habit-promotion-card"
              >
                <div className="card-body">
                  <p>{promoContent.content}</p>
                </div>
              </EnhancedCard>
            );
          })}
        </div>
        <Card className="habit-builder-card">
          <h5>Is this your report? Log in to complete your reflection</h5>
          <Button type="primary" onClick={this.handleRouteToLogin}>
            Access Reflection builder
          </Button>
        </Card>
      </div>
    );
  }

  get isTestDrive(): boolean {
    return this.props.uiStore.isTestDrive;
  }

  render() {
    const { uiStore } = this.props;
    const { isLoading } = uiStore;

    if (isLoading) {
      return null;
    }

    return !!uiStore.memberStore?.currentMember || this.isTestDrive
      ? this.renderAuthenticatedView
      : this.renderGuestView;
  }
}

export default withRouter(observer(CommitToImproveContainer));
