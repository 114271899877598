export const PerspectiveAnswersKey = (memberId?: number): string => {
  const suffix = memberId ? `:${memberId}` : '';
  return `perspective:answers:cache${suffix}`;
};

export const PerspectiveSurveyKey = (memberId?: number): string => {
  const suffix = memberId ? `:${memberId}` : '';
  return `perspective:survey:answers${suffix}`;
};

export const MemberAuthTokenKey = 'member_auth_token';

export const DiscussionGuideInsightsKey = (id) => `ShiftDiscussionGuideInsights:${id}`;

export const ShowDiscussionModalKey = 'show_discussion_modal';

export const ExerciseHideMobileBanner = 'exercise_hide_mobile_banner';

export const TeamsHideCCCard = 'teams_hide_cc_card';

export const AutoMagicLinkEmailKey = 'auto_magiclink_email';
export const AutoMagicLinkOriginKey = 'auto_magiclink_origin';

export const TeamGameRevealedProfileKey = 'team_game_revealed_profiles';

export const CookiePref = 'cookie_pref';
