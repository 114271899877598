import React, { useState } from 'react';

import { ParentSize } from '@visx/responsive';
import { ConnectDropTarget } from 'react-dnd';

import './ScatterPlotCard.scss';

import ExerciseStatementCard from 'app/components/features/ExerciseStatementCard';
import { AlignModel, ExerciseTypeModel } from 'app/models';

import { getLinearScales, renderLabels } from './ScatterPlotCard';

const CARD_SIZE = 88;
const CARD_OFFSET = 4;

export interface StatementCard {
  id: number | string;
  title: string;
  scaledX?: number;
  scaledY?: number;
  placed?: boolean;
}

export interface ScatterPlotCardDraggableProps {
  statements: StatementCard[];
  exerciseType: ExerciseTypeModel;
  exercise?: AlignModel;
  boundingBoxRef?: React.MutableRefObject<any>;
  dropTargetRef?: ConnectDropTarget;
  onStatementCardDrop?: (id, statement) => void;
  onSize?: (size: number) => void;
  postCardAnimationAction?: (id: string | number) => void;
  blockDragging?: boolean;
  startAnimation?: boolean;
}

const ScatterPlotCardDraggable: React.FC<ScatterPlotCardDraggableProps> = ({
  exercise,
  exerciseType,
  statements,
  boundingBoxRef,
  dropTargetRef,
  onSize,
  blockDragging,
  startAnimation,
  postCardAnimationAction,
}) => {
  const [foregroundCardId, setForegroundCardId] = useState(null);

  return (
    <ParentSize>
      {({ width: size }) => {
        const { dx, dy } = getLinearScales(size);
        onSize?.(size);

        return (
          <div
            data-testid="scatter-plot-card-basic"
            className="scatter-plot-card"
            style={{ width: size, height: size }}
          >
            <div className="scatter-plot-card-inner">
              <div className="outer" ref={boundingBoxRef}>
                <div className="scatter-plot-grid-container">
                  <div className="outer" ref={dropTargetRef}>
                    {statements.map(({ id, scaledX, scaledY, title }, i) => (
                      <ExerciseStatementCard
                        key={i}
                        id={id}
                        title={title}
                        left={dx(scaledX) - CARD_SIZE / 2 + CARD_OFFSET}
                        top={dy(scaledY) - CARD_SIZE / 2 + CARD_OFFSET}
                        onClick={setForegroundCardId}
                        foreground={id === foregroundCardId}
                        blockDragging={blockDragging}
                        startAnimation={startAnimation}
                        postCardAnimationAction={postCardAnimationAction}
                        cardIndex={i}
                        mobileScale
                      />
                    ))}
                  </div>
                </div>

                {renderLabels(size, dx, dy, exerciseType, exercise)}
              </div>
            </div>
          </div>
        );
      }}
    </ParentSize>
  );
};

export default ScatterPlotCardDraggable;
