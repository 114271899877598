import React from 'react';

import cx from 'classnames';

interface Submit360ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  hideForDesktop?: boolean;
}

export const Submit360Button: React.FC<Submit360ButtonProps> = ({
  onClick,
  disabled,
  hideForDesktop,
}) => (
  <button
    id="align-submit-button"
    onClick={onClick}
    className={cx('btn btn-primary submit-btn', { 'align-tablet': hideForDesktop })}
    disabled={disabled}
  >
    Next: Comments <i className="fas fa-chevron-right" />
  </button>
);
