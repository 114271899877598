import React, { ReactNode } from 'react';

import { Button, Tooltip } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { ButtonHTMLType } from 'antd/lib/button/button';
import cx from 'classnames';

import './CardFooter.scss';

export interface Clickable {
  label: string;
  id?: string;
  disabled?: boolean;
  isLoading?: boolean;
  htmlType?: ButtonHTMLType;
  type?: ButtonType;
  className?: string;
  tooltip?: string;
  icon?: ReactNode;
  dataTag?: string;
  dataTrack?: boolean;
  onClick?: () => void;
  ghost?: boolean;
}

interface CardFooterProps {
  title?: string;
  primaryButton?: Clickable;
  secondaryButton?: Clickable;
  actionButton?: Clickable;
  compact?: boolean;
  theme?: 'purple' | 'default';
  sticky?: boolean;
}

const CardFooter: React.FC<CardFooterProps> = ({
  title,
  primaryButton,
  secondaryButton,
  actionButton,
  theme,
  compact,
  children,
  sticky,
}) => {
  const actionBtn = actionButton && (
    <Button
      id={actionButton.id}
      type="link"
      onClick={actionButton.onClick}
      disabled={actionButton.disabled}
      htmlType={actionButton.htmlType}
      loading={actionButton.isLoading}
      className={actionButton.className}
      icon={actionButton.icon}
      data-tag={actionButton.dataTag}
      data-track={actionButton.dataTrack}
      block
    >
      {actionButton.label}
    </Button>
  );

  const hasBtns = primaryButton || secondaryButton;

  const primaryBtn = primaryButton && (
    <Button
      id={primaryButton.id}
      type="primary"
      onClick={primaryButton.onClick}
      disabled={primaryButton.disabled}
      htmlType={primaryButton.htmlType}
      loading={primaryButton.isLoading}
      className={primaryButton.className}
      icon={primaryButton.icon}
      data-tag={primaryButton.dataTag}
      data-track={primaryButton.dataTrack}
      block
    >
      {primaryButton.label}
    </Button>
  );

  const secondaryBtn = secondaryButton && (
    <Button
      id={secondaryButton.id}
      type="primary"
      className={secondaryButton.className}
      onClick={secondaryButton.onClick}
      disabled={secondaryButton.disabled}
      htmlType={secondaryButton.htmlType}
      loading={secondaryButton.isLoading}
      icon={secondaryButton.icon}
      data-tag={secondaryButton.dataTag}
      data-track={secondaryButton.dataTrack}
      ghost
      block
    >
      {secondaryButton.label}
    </Button>
  );

  return (
    <div className={cx('footer', `${theme}-theme`, { sticky: sticky })}>
      {!compact && title && <h4 className="title">{title}</h4>}
      {!title && hasBtns && actionBtn}

      <div className="btns-container">
        {(title || !hasBtns) && actionBtn}

        {secondaryButton && !secondaryButton.tooltip && secondaryBtn}
        {secondaryButton && secondaryButton.tooltip && (
          <Tooltip title={secondaryButton.tooltip}>{secondaryBtn}</Tooltip>
        )}

        {primaryButton && !primaryButton.tooltip && primaryBtn}
        {primaryButton && primaryButton.tooltip && (
          <Tooltip title={primaryButton.tooltip}>{primaryBtn}</Tooltip>
        )}
        {children}
      </div>
    </div>
  );
};

export default CardFooter;
