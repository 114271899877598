import { History } from 'history';
import { action, computed, observable, ObservableMap } from 'mobx';

import { ENTITY_STATES } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import { MemberModel, PerspectiveMemberModel, ToolSurveyAnswerModel } from 'app/models';
import {
  EntityStateStore,
  FeatureStore,
  MemberStore,
  NavigationStore,
  OrganizationStore,
  PairedNoteStore,
  PerspectiveManagerInsightsStore,
  PerspectiveStore,
  TeamStore,
} from 'app/stores';
import MemberRatingStore from 'app/stores/MemberRatingStore';

export interface PairInfo {
  memberId: number;
  teamId?: number;
  currentMemberId?: number;
}

interface PerspectiveReportUIStoreProps {
  testDriveInfo?: PairInfo;
  token?: string;
  history: History<any>;

  perspectiveStore: PerspectiveStore;
  featureStore?: FeatureStore;
  memberStore?: MemberStore;
  teamStore?: TeamStore;
  organizationStore?: OrganizationStore;
  navigationStore?: NavigationStore;
  pairedNoteStore?: PairedNoteStore;
  memberRatingStore?: MemberRatingStore;
  entityStateStore?: EntityStateStore;
  perspectiveManagerInsightsStore?: PerspectiveManagerInsightsStore;
}

export interface ActiveLens {
  key: string;
  index: number;
}

export default class PerspectiveReportUIStore {
  /**
   * Configuration
   */
  history: History<any>;
  testDriveInfo: PairInfo;
  token: string;

  @observable exploreActiveLens: ObservableMap<string, number> = new ObservableMap();
  @action setExploreActiveLens = (activeLens: ActiveLens): void => {
    this.exploreActiveLens.set(activeLens.key, activeLens.index);
  };

  @observable isShowingPerspectiveVisibilityNotification = false;
  @action setIsShowingPerspectiveVisibilityNotification = (
    isShowingPerspectiveVisibilityNotification: boolean
  ) =>
    (this.isShowingPerspectiveVisibilityNotification = isShowingPerspectiveVisibilityNotification);

  @observable redirectPage;
  @action setRedirectPage = (url) => (this.redirectPage = url);

  @observable pairInfo: PairInfo;
  @action setPairInfo = (pairInfo: PairInfo): void => {
    this.pairInfo = pairInfo;
  };

  /**
   * Stores
   */
  perspectiveStore: PerspectiveStore;
  featureStore?: FeatureStore;
  memberStore?: MemberStore;
  teamStore?: TeamStore;
  organizationStore?: OrganizationStore;
  navigationStore?: NavigationStore;
  pairedNoteStore?: PairedNoteStore;
  memberRatingStore?: MemberRatingStore;
  perspectiveManagerInsightsStore?: PerspectiveManagerInsightsStore;
  entityStateStore?: EntityStateStore;

  /**
   * Constructor
   */
  constructor(props: PerspectiveReportUIStoreProps) {
    this.testDriveInfo = props.testDriveInfo;
    this.token = props.token;

    this.history = props.history;
    this.perspectiveStore = props.perspectiveStore;
    this.featureStore = props.featureStore;
    this.memberStore = props.memberStore;
    this.teamStore = props.teamStore;
    this.organizationStore = props.organizationStore;
    this.navigationStore = props.navigationStore;
    this.pairedNoteStore = props.pairedNoteStore;
    this.memberRatingStore = props.memberRatingStore;
    this.entityStateStore = props.entityStateStore;
    this.perspectiveManagerInsightsStore = props.perspectiveManagerInsightsStore;

    this.perspectiveStore.setSharedLinkToken(this.token);
    this.memberStore?.setSharedLinkToken(this.token);
    this.teamStore?.setSharedLinkToken(this.token);
    this.memberRatingStore?.setSharedLinkToken(this.token);
    this.pairedNoteStore?.setSharedLinkToken(this.token);
    this.perspectiveManagerInsightsStore?.setSharedLinkToken(this.token);

    this.perspectiveStore?.setIsTestDrive(this.isTestDrive);
    this.memberRatingStore?.setIsTestDrive(this.isTestDrive);
    this.memberStore?.setIsTestDrive(this.isTestDrive);
    this.teamStore?.setIsTestDrive(this.isTestDrive);
    this.perspectiveManagerInsightsStore?.setIsTestDrive(this.isTestDrive);
  }

  setSeenPerspectiveVisibilityNotification(): void {
    const entityState = this.perspectiveVisibilityEntityState;
    this.setIsShowingPerspectiveVisibilityNotification(!entityState?.flag);
  }

  get isEntityStatesLoaded(): boolean {
    return this.isMemberEntityStatesLoaded;
  }

  @computed
  get isTestDrive(): boolean {
    return !!this.testDriveInfo;
  }

  @computed
  get redirectURL(): string {
    const url =
      this.redirectPage ??
      ServerRouteHelper.dashboard.pairPerspective(this.organizationStore.organization.item?.id);

    return this.perspectiveStore.sharedLinkToken
      ? `${ServerRouteHelper.auth.login()}?redirect_to=${url}`
      : url;
  }

  @computed
  get homeURL(): string {
    let url = ServerRouteHelper.dashboard.home();

    if (this.teamStore.team.item) {
      url = ServerRouteHelper.dashboard.teamPage(this.teamStore.team.item.id, 'home');
    }

    return this.perspectiveStore.sharedLinkToken
      ? `${ServerRouteHelper.auth.login()}?redirect_to=${url}`
      : url;
  }

  @computed
  get perspectiveVisibilityEntityState() {
    return this.entityStateStore.memberEntityStates.items.find(
      (item) => item.type === ENTITY_STATES.SEEN_PERSPECTIVE_VISIBILITY_TOGGLE_NOTIFICATION
    );
  }

  get teamNonParticipant(): PerspectiveMemberModel[] {
    return this.perspectiveStore.teamNonParticipants.items;
  }

  get isMemberEntityStatesLoaded(): boolean {
    return this.entityStateStore.memberEntityStates.loaded;
  }

  get inviterMember(): MemberModel {
    return this.perspectiveStore.inviter.item;
  }

  // Get current member
  get currentMember(): MemberModel {
    return this.memberStore.currentMember.item;
  }

  getLensResonateType = (type: string, leftLabel: string, rightLabel: string): string => {
    // Strip short version from labels, ie. Extraversion (E)
    leftLabel = leftLabel.replace(/\(.*\)/, '').trim();
    rightLabel = rightLabel.replace(/\(.*\)/, '').trim();
    return `${type}-lens-${leftLabel}:${rightLabel}`;
  };

  getResonateTag = (type: string): string => {
    return `perspective:personal:${type}`;
  };

  getResonateAnswer = (tag: string): ToolSurveyAnswerModel => {
    const answers = this.perspectiveStore.surveyAnswers.items ?? [];
    return answers.find((answer) => answer.tag === tag);
  };

  getLensResonateAnswer = (resonateType: string): string => {
    const tag = this.getResonateTag(resonateType);
    const answer = this.getResonateAnswer(tag);
    return answer?.answer;
  };

  saveSurveyAnswer = (type: string, answer: string): void => {
    const tag = this.getResonateTag(type);
    this.perspectiveStore.saveSurveyAnswer(tag, answer);
  };

  handleRetake = (): void => {
    this.perspectiveStore.retake(this.currentMember.id);
    this.history.push(ServerRouteHelper.perspective.questions());
  };
}
