import { keyBy } from 'lodash';
import { computed, observable } from 'mobx';

import { EXERCISE_TYPES } from 'app/constants/exerciseType';
import { AlignHelper } from 'app/helpers';
import Model, { ModelJson } from 'app/models/Model';
import { ExerciseTypeStore } from 'app/stores';

import AlignModel from './AlignModel';
import BenchmarkModel from './BenchmarkModel';
import ExerciseStatementModel from './ExerciseStatementModel';
import ExerciseThemeModel from './ExerciseThemeModel';
import FeaturedExerciseTypeModel from './FeaturedExerciseTypeModel';
import HtmlArraySegmentModel from './HtmlArraySegmentModel';

const LOOKUP_TAG_COMPASSIONATE_CONVERSATIONS = 'compassionate_conversations';

export interface ExerciseStatement {
  id: string | number;
  title: string;
  explanation?: string;
  placed?: boolean;
  left?: number;
  top?: number;
  scaledX?: number;
  scaledY?: number;
  mobileScale?: boolean;
  className?: string;
  foreground?: boolean;
  onClick?: (id: string | number) => void;
  postCardAnimationAction?: (id: string | number) => void;
  blockDragging?: boolean;
  cardIndex?: number;
}

export enum OnboardingModalKeys {
  CRUCIAL_CONVERSATIONS = 'crucial-conversations',
}

/**
 * Define the properties that are passed in to create ExerciseTypes
 */
export interface ExerciseTypeProps {
  id: number;
  created_at?: number;
  deleted_at?: number;
  updated_at?: number;

  name: string;

  config: any;
  public: number;
  type: string;
  code: string;
  organization_id: string;

  align_statements?: any[];
  score_cutoffs: number[];
  exercise_themes?: ExerciseThemeModel[];

  bottom: string;
  left: string;
  right: string;
  top: string;

  right_with_name?: string;
  left_with_name?: string;
  top_line?: string;

  graphic: string;
  image: string;
  invite_email: string;
  when_to_use?: string;
  where_from?: string;
  context?: string;
  context_html_segments?: HtmlArraySegmentModel[];
  prompt?: string;
  card_description: string;

  benchmarks?: BenchmarkModel[];

  firstStarted?: string;
  inProgressCount?: number;
  completedCount?: number;

  exercises?: AlignModel[];
  isFeatured?: boolean;

  pivot?: FeaturedExerciseTypeModel;
  onboarding_modal_key?: string;
  lookup_tag?: string;
  external_link?: string;
}

export class ExerciseTypeModel extends Model {
  static _store: ExerciseTypeStore;

  readonly id: number;
  created_at?: number;
  deleted_at?: number;
  updated_at?: number;

  @observable name: string;
  @observable description: string;

  config: any;
  public: number;
  type: string;
  code: string;
  organization_id: string;

  align_statements?: ExerciseStatementModel[];
  score_cutoffs: number[];
  exercise_themes?: ExerciseThemeModel[];

  bottom: string;
  left: string;
  right: string;
  top: string;

  right_with_name?: string;
  left_with_name?: string;
  top_line?: string;

  graphic: string;
  image: string;
  invite_email: string;
  when_to_use?: string;
  where_from?: string;
  context?: string;
  context_html_segments?: HtmlArraySegmentModel[];
  prompt?: string;
  card_description: string;

  benchmarks?: BenchmarkModel[];

  firstStarted?: string;
  inProgressCount?: number;
  completedCount?: number;
  lookup_tag?: string;

  @observable exercises?: Array<AlignModel>;
  @observable isFeatured?: boolean;

  external_link?: string;

  static defaultImage = '/images/cardGraphics/other-type@3x.png';
  @observable pivot?: FeaturedExerciseTypeModel;
  onboarding_modal_key?: string;

  benchmarkScoreForIndexAndStatementTemplateID(index, statementTemplateID) {
    const benchmark = this.benchmarks[index];

    if (!benchmark) {
      return;
    }

    return benchmark.scoresByStatementTemplateId[statementTemplateID];
  }

  @computed
  get is360(): boolean {
    return this.type === '360';
  }

  @computed
  get isCompassionateConversationExercise(): boolean {
    return this.lookup_tag === LOOKUP_TAG_COMPASSIONATE_CONVERSATIONS;
  }

  labelsWithoutMy360Names() {
    let { labels } = this;
    const { top, left, bottom, right } = this;

    // sometimes `exerciseType` doesn't contain a non-null `labels` field
    // we have to manually construct the `labels` object in such cases
    if (!labels && top && bottom && left && right) {
      labels = { top, bottom, left, right };
    }

    return labels;
  }

  labelsForExercise(exercise?: AlignModel) {
    const labels = this.labelsWithoutMy360Names();

    const { left_with_name, right_with_name } = this;

    const isAssessing360AsNonMember = exercise?.is_360 && !exercise?.member?.isCurrentMember;
    const has360CustomLabels = left_with_name && right_with_name;

    if (isAssessing360AsNonMember && has360CustomLabels) {
      const name = exercise.member.firstName;

      labels.left = `${name} ${left_with_name}`;
      labels.right = `${name} ${right_with_name}`;
    }

    return labels;
  }

  get label(): string {
    return this.pivot ? this.pivot.label : '';
  }

  set label(value: string) {
    if (this.pivot) {
      this.pivot.label = value;
    }
  }

  get style(): string {
    return this.pivot ? this.pivot.style : '';
  }

  set style(value: string) {
    if (this.pivot) {
      this.pivot.style = value;
    }
  }

  get order(): number {
    return this.pivot ? this.pivot.order : 0;
  }

  set order(value: number) {
    if (this.pivot) {
      this.pivot.order = value;
    }
  }

  @computed
  get scaleScore() {
    return AlignHelper.algoRescale(this.score_cutoffs);
  }

  @computed
  get classifyScore() {
    return AlignHelper.algoClassify(this.score_cutoffs);
  }

  @computed
  get labels() {
    const { top, right, bottom, left } = this;
    return { top, right, bottom, left };
  }

  @computed
  get themesById(): { [id: number]: ExerciseThemeModel } {
    return keyBy(this.exercise_themes, (theme: ExerciseThemeModel) => theme.id);
  }

  @computed
  get isTeamFoundations(): boolean {
    return this.name.toLowerCase().indexOf(EXERCISE_TYPES.TEAM_FOUNDATIONS) !== -1;
  }

  @computed
  get basicStatements(): ExerciseStatement[] {
    const statements = this.align_statements || [];
    return statements.map(({ id, text: title, explanation }) => ({
      id,
      title,
      explanation,
    }));
  }

  @computed
  get hasCustomSurveyQuestions(): boolean {
    return this.customSurveyQuestions?.length > 0;
  }

  @computed
  get customSurveyQuestions() {
    return this.config?.survey_questions ?? [];
  }

  deserialize_align_statements(statements) {
    this.align_statements = statements.map((statement) => new ExerciseStatementModel(statement));
  }

  deserialize_benchmarks(benchmarks) {
    this.benchmarks = benchmarks.map((benchmark) => new BenchmarkModel(benchmark));
  }

  deserialize_exercise_themes(themes) {
    this.exercise_themes = themes.map((theme) => new ExerciseThemeModel(theme));
  }

  deserialize_context_html_segments(html): HtmlArraySegmentModel[] {
    if (!html) {
      return;
    }

    this.context_html_segments = html.map((item) => HtmlArraySegmentModel.fromJson(item));
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as ExerciseTypeModel;
  }
}

export default ExerciseTypeModel;
