import React from 'react';

import cx from 'classnames';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';

import './RegisterFullScreen.scss';

import Card from 'app/components/ui/Card';
import { FakeBox } from 'app/components/ui/FakeBox';
import { LogoWhite } from 'app/components/ui/Logo';
import { STORE_ORGANIZATION } from 'app/constants';
import { OrganizationModel } from 'app/models';
import Register from 'app/pages/auth/Register/Register';
import { OrganizationStore } from 'app/stores';

import OrgRegistrationForm from '../OrgRegistrationForm';

export interface Params {
  secret_code: string;
  referral_code: string;
}

export interface RegisterFullScreenProps extends RouteComponentProps<Params> {
  destination?: string;
  habitShortRegistration?: boolean;
  orgName?: string;
  name?: string;
  email?: string;
  organizationStore?: OrganizationStore;
}

export class RegisterFullScreen extends React.Component<RegisterFullScreenProps> {
  @observable isLoading: boolean;
  @action setIsLoading = (value) => {
    this.isLoading = value;
  };

  @observable organization: OrganizationModel;
  @action setOrganization = (org: OrganizationModel) => {
    this.organization = org;
  };

  constructor(props) {
    super(props);
    this.init();
  }

  private async init() {
    this.setIsLoading(true);
    if (this.orgSecretCode) {
      const response = await this.props[STORE_ORGANIZATION].loadOrgBySecretCode(this.orgSecretCode);

      if (response?.data) {
        this.setOrganization(OrganizationModel.fromJson(response.data));
      }
    }

    this.setIsLoading(false);
  }

  orgCustomLocalization = (key) => {
    if (this.organization && this.organization.custom_localization) {
      return this.organization.custom_localization[key];
    }
    return null;
  };

  @computed
  get orgSecretCode() {
    return this.props.match.params.secret_code;
  }

  @computed
  get referralCode() {
    return this.props.match.params.referral_code;
  }

  @computed
  get hideLeftScreenUpperText() {
    return this.orgCustomLocalization('register.hide_left_screen_upper_text') ?? false;
  }

  @computed
  get leftScreenMiddleText() {
    return (
      this.orgCustomLocalization('register.left_screen_middle_text') ??
      'Valence’s team diagnostic tools quickly point you to simple, proven practices that will help your team thrive.'
    );
  }

  @computed
  get leftScreenBottomText() {
    return (
      this.orgCustomLocalization('register.left_screen_bottom_text') ??
      'Get started with a free trial or check to see if you have a pre-paid enterprise license waiting for you.'
    );
  }

  @computed
  get hideLeftScreenBottomText() {
    return this.orgCustomLocalization('register.hide_left_screen_bottom_text') ?? false;
  }

  @computed
  get customCaredRequested() {
    return this.orgCustomLocalization('register.custom-card') ?? false;
  }

  @computed
  get customCardData() {
    if (!this.customCaredRequested) {
      return null;
    }
    const customCardMeta = {
      image: this.orgCustomLocalization('register.customer-card-img'),
      headerText: this.orgCustomLocalization('register.custom-card-header-text'),
      lowerText: this.orgCustomLocalization('register.custom-card-lower-text'),
    };

    return customCardMeta;
  }

  render() {
    if (this.isLoading) {
      return (
        <div className="d-flex">
          <FakeBox className="m-5" width="55%" height="30rem" />
          <FakeBox className="m-5" width="40%" height="30rem" />
        </div>
      );
    }

    return (
      <Register {...this.props}>
        <div className="register-container">
          <div className="left-container">
            <div
              className={cx('left-container-inner', {
                'left-container-inner-custom': this.customCaredRequested,
              })}
            >
              <LogoWhite className="shift-logo" />
              <div className="text-container">
                {!this.hideLeftScreenUpperText && (
                  <h1>The fastest way to understand your team and lead it better.</h1>
                )}
                <p
                  className="mid-text"
                  dangerouslySetInnerHTML={{ __html: this.leftScreenMiddleText }}
                ></p>
                <p className="break-text"></p>

                {!this.hideLeftScreenBottomText && (
                  <p className="bottom-text">{this.leftScreenBottomText}</p>
                )}
              </div>

              {this.customCaredRequested && (
                <Card newDesign>
                  <div className="custom-card-container">
                    <img src={this.customCardData.image} />
                    <div className="custom-card-text-container">
                      <p
                        className="header-text"
                        dangerouslySetInnerHTML={{ __html: this.customCardData.headerText }}
                      ></p>

                      <p
                        className="lower-text"
                        dangerouslySetInnerHTML={{ __html: this.customCardData.lowerText }}
                      ></p>
                    </div>
                  </div>
                </Card>
              )}
            </div>
          </div>

          <div className="right-container">
            <div className="register-form-container">
              <OrgRegistrationForm
                organization={this.organization}
                referral_code={this.referralCode}
                {...this.props}
              />
            </div>
          </div>
        </div>
      </Register>
    );
  }
}

export default inject(STORE_ORGANIZATION)(observer(RegisterFullScreen));
