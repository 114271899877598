import React, { ReactNode } from 'react';

import cx from 'classnames';

import './Card.scss';

import CardNew from './CardNew';

export interface CardProps {
  id?: string;
  title?: ReactNode;
  content?: string;
  className?: string;
  extra?: ReactNode;
  bordered?: boolean;
  children?: ReactNode;
  onClick?: () => void;
  thin?: boolean;

  // New design props
  icon?: ReactNode;
  subTitle?: ReactNode;
  newDesign?: boolean;
}

export const Card: React.StatelessComponent<CardProps> = ({
  id,
  title,
  extra,
  content,
  className,
  bordered,
  children,
  onClick,
  thin,

  // New design props
  icon,
  subTitle,
  newDesign,
}) => {
  if (newDesign) {
    return (
      <CardNew {...{ id, icon, title, subTitle, extra, content, className, onClick }}>
        {children}
      </CardNew>
    );
  }

  return (
    <div
      id={id}
      data-testid="card"
      className={cx('card', className, {
        thin,
        'acc-dash-widget': !bordered,
      })}
      onClick={onClick}
    >
      {title && <h5>{title}</h5>}
      {content || children}
    </div>
  );
};

export default Card;
