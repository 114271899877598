import { action, observable, onBecomeObserved, onBecomeUnobserved, set } from 'mobx';
import { Channel } from 'pusher-js';

import WebSocketHelper from 'app/helpers/WebSocketHelper';

import Model from './Model';

export default class WebsocketStateModel<T> extends Model {
  @observable state: T;
  @action setState = (s: T) => set(this.state, s);

  channel: string;

  private _socketChannel: Channel;

  afterInitialize() {
    this.bindObservation();
  }

  deserialize_state(state) {
    this.state = state;
  }

  private bindObservation() {
    onBecomeObserved(this, 'state', this.handleObserved.bind(this));
    onBecomeUnobserved(this, 'state', this.handleUnobserved.bind(this));
  }

  private handleObserved() {
    if (this._socketChannel) {
      return;
    }

    this._socketChannel = WebSocketHelper.sharedInstance.subscribe(this.channel);
    this.bindChannels();
  }

  private handleUnobserved() {
    if (!this._socketChannel) {
      return;
    }

    this._socketChannel.unsubscribe();
    this._socketChannel = null;
  }

  _bindChannels() {
    this._socketChannel.bind('update_state', this.handleStateUpdate.bind(this));
  }

  bindChannels() {
    this._bindChannels();
  }

  bindEvent(eventName: string, callback: (arg?: any) => void) {
    return this._socketChannel.bind(eventName, callback);
  }

  protected handleStateUpdate(newState: T): void {
    this.setState(newState);
  }
}
