import React from 'react';

import { Bar as VXBar } from '@visx/shape';
import { Text } from '@visx/text';

function Bar({ width, height, x, y, fill, value }) {
  return (
    <>
      <VXBar x={x} y={y} width={width} height={height} fill={fill} />
      <Text x={x + 10} y={y - 10}>
        {value > 0 ? value : ''}
      </Text>
    </>
  );
}

export default Bar;
