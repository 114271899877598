import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList } from 'app/models';
import SubscriptionItemModel from 'app/models/SubscriptionItemModel';
import Store from 'app/stores/Store';

export class SubscriptionItemStore extends Store<SubscriptionItemModel> {
  @observable subscriptionItems = new ModelList<SubscriptionItemModel>(SubscriptionItemModel);

  constructor() {
    super();
    SubscriptionItemModel._store = this;
  }

  async getSubscriptionItems(orgId: number): Promise<void> {
    const url = ServerRouteHelper.api.subscriptions.items.list(orgId);
    this.subscriptionItems.load(url);
  }
}

export default SubscriptionItemStore;
