import React from 'react';

import Icon from '@ant-design/icons';

const CreateHabitsSVG = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.25 5.3125C4.25 6.43967 4.69777 7.52067 5.4948 8.3177C6.29183 9.11473 7.37283 9.5625 8.5 9.5625C9.62717 9.5625 10.7082 9.11473 11.5052 8.3177C12.3022 7.52067 12.75 6.43967 12.75 5.3125C12.75 4.18533 12.3022 3.10433 11.5052 2.3073C10.7082 1.51027 9.62717 1.0625 8.5 1.0625C7.37283 1.0625 6.29183 1.51027 5.4948 2.3073C4.69777 3.10433 4.25 4.18533 4.25 5.3125V5.3125Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9529 15.4244C14.137 14.0031 12.8746 12.891 11.3617 12.2608C9.84879 11.6307 8.17015 11.5178 6.58651 11.9397C5.00287 12.3615 3.60286 13.2946 2.60397 14.5938C1.60507 15.8931 1.06319 17.4859 1.0625 19.1248V22.3123H4.25L5.3125 32.9373H11.6875"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.25 26.5625H15.9375V31.875"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.113 27.6009C31.5503 29.2453 30.4607 30.658 29.0131 31.6199C27.5655 32.5817 25.841 33.039 24.107 32.9206C22.373 32.8023 20.7266 32.115 19.4231 30.9654C18.1197 29.8158 17.2321 28.2681 16.898 26.5625"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.625 22.3125H32.9375V17"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7634 21.2741C17.3261 19.6297 18.4158 18.217 19.8633 17.2551C21.3109 16.2932 23.0355 15.836 24.7694 15.9544C26.5034 16.0727 28.1498 16.76 29.4533 17.9096C30.7568 19.0592 31.6444 20.6069 31.9784 22.3125"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6562 4.78125C19.6563 5.76752 20.048 6.7134 20.7454 7.4108C21.4428 8.1082 22.3887 8.5 23.375 8.5C24.3613 8.5 25.3072 8.1082 26.0046 7.4108C26.702 6.7134 27.0937 5.76752 27.0937 4.78125C27.0937 3.79498 26.702 2.8491 26.0046 2.1517C25.3072 1.4543 24.3613 1.0625 23.375 1.0625C22.3887 1.0625 21.4428 1.4543 20.7454 2.1517C20.048 2.8491 19.6563 3.79498 19.6562 4.78125V4.78125Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.9 12.7499C28.3928 11.872 27.6835 11.1277 26.8311 10.5789C25.9786 10.03 25.0075 9.69238 23.9984 9.59403C22.9892 9.49568 21.9712 9.63944 21.0288 10.0134C20.0864 10.3873 19.2468 10.9806 18.5796 11.7441"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CreateHabitsIcon = (props) => <Icon component={CreateHabitsSVG} {...props} />;

export default CreateHabitsIcon;
