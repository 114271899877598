import { observable } from 'mobx';

import { RoleStore } from 'app/stores/RoleStore';

import Model, { ModelJson } from './Model';

export class RoleModel extends Model {
  static _store: RoleStore;

  @observable id: number;
  @observable name: string;
  @observable icon_class?: string;
  @observable public_default: number;
  @observable members_count?: number;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as RoleModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as RoleModel;
  }

  static get(id) {
    return this._get(id) as RoleModel;
  }
}
