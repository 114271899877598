import React, { Component, ReactNode } from 'react';

import { action, autorun, observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './NavMenu.module.scss';

import {
  MemberDropdown,
  RoutedMemberDropdown,
} from 'app/components/features/AppLayout/MemberDropdown';
import NavMenuList from 'app/components/features/AppLayout/NavMenuList';
import { AppLayoutUiStore } from 'app/components/features/AppLayout/stores';
import { LogoWhite } from 'app/components/ui/Logo';
import { STORE_APP_LAYOUT_UI, STORE_MENU, STORE_TEAM } from 'app/constants';
import { MenuModel, OrganizationModel, TeamModel } from 'app/models';
import { MenuStore, TeamStore } from 'app/stores';

export interface NavMenuProps {
  appLayoutUiStore?: AppLayoutUiStore;
  menuStore?: MenuStore;
  teamStore?: TeamStore;
}

/**
 * Primary vertical side menu and user profile switcher
 */
export class NavMenu extends Component<NavMenuProps> {
  // create observable for last loaded orgId and teamId
  @observable lastLoadedOrgId: number;
  @action setLastLoadedOrgId = (orgId: number): void => {
    this.lastLoadedOrgId = orgId;
  };

  @observable lastLoadedTeamId: number;
  @action setLastLoadedTeamId = (teamId: number): void => {
    this.lastLoadedTeamId = teamId;
  };

  constructor(props: NavMenuProps) {
    super(props);

    // Load menu when active model changes
    autorun(() => {
      if (this.activeOrganization || this.activeTeam) {
        this.loadMenu();
      }
    });
  }

  protected loadMenu = async (): Promise<void> => {
    if (
      this.lastLoadedOrgId === this.activeOrganization?.id &&
      this.lastLoadedTeamId === this.activeTeam?.id
    ) {
      return;
    }

    if (this.activeOrganization && this.activeTeam) {
      if (this.activeTeam.organization_id !== this.activeOrganization.id) {
        return;
      }
    }

    this.setLastLoadedOrgId(this.activeOrganization?.id);
    this.setLastLoadedTeamId(this.activeTeam?.id);

    await this.props.menuStore.loadMenu('main', this.activeOrganization?.id, this.activeTeam?.id);

    if (!this.activeOrganization?.id) {
      console.log('no active organization id found');
      return;
    }

    await this.props.teamStore.loadMemberTeams(this.activeOrganization?.id);
  };

  get activeOrganization(): OrganizationModel {
    return this.props.appLayoutUiStore.activeOrganization;
  }

  get activeTeam(): TeamModel {
    return this.props.appLayoutUiStore.activeTeam;
  }

  get mainMenu(): MenuModel {
    return this.props.appLayoutUiStore.menu;
  }

  get memberDropdown(): ReactNode {
    if (this.props.appLayoutUiStore.useRouter) {
      return <RoutedMemberDropdown />;
    }

    return <MemberDropdown />;
  }

  render(): ReactNode {
    return (
      <nav className={styles.navMenu}>
        <div className={styles.topNavContainer}>
          <LogoWhite />
          <NavMenuList menu={this.mainMenu} />
        </div>
        <div className={styles.bottomNavContainer}>{this.memberDropdown}</div>
      </nav>
    );
  }
}

export default inject(STORE_APP_LAYOUT_UI, STORE_MENU, STORE_TEAM)(observer(NavMenu));
