import { ReactNode } from 'react';

import { action, computed, observable, when } from 'mobx';
import { matchPath } from 'react-router';

import { MenuItemModel, MenuItemTypes, MenuModel, OrganizationModel, TeamModel } from 'app/models';
import NavService from 'app/services/NavService';
import { MenuStore, OrganizationStore } from 'app/stores';

export interface AppLayoutUiStoreProps {
  menuStore: MenuStore;
  organizationStore: OrganizationStore;
}

export default class AppLayoutUiStore {
  menuStore: MenuStore;
  organizationStore: OrganizationStore;

  constructor(props) {
    this.menuStore = props.menuStore;
    this.organizationStore = props.organizationStore;
  }

  @observable activeTeam: TeamModel;
  @action setActiveTeam = (team: TeamModel): void => {
    this.activeTeam = team;
  };

  @observable activeOrganization: OrganizationModel;
  @action setActiveOrganization = (organization: OrganizationModel): void => {
    this.activeOrganization = organization;
  };

  @observable path: any;
  @action setPath = (path: string): void => {
    this.path = path;
  };

  @observable isLoading = false;
  @action setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading;
  };

  /**
   * Toggle the display or hiding of the app layout sidebar,
   * where the primary menu and logo are displayed
   */
  @observable showSidebar = false;

  @action setShowSidebar = (showSidebar: boolean): void => {
    this.showSidebar = showSidebar;
  };

  @action toggleSidebar = (): void => {
    this.showSidebar = !this.showSidebar;
  };

  @observable sidebarNavMenuOpen = false;
  @action toggleSidebarNavMenu = (): void => {
    this.sidebarNavMenuOpen = !this.sidebarNavMenuOpen;
  };

  /**
   * Page UI bar title
   */
  @observable title: string = null;
  @action setTitle = (title: string): void => {
    this.title = title;
  };

  /**
   * Page UI bar first left element after the title.
   */
  @observable primaryElement: ReactNode = null;
  @action setPrimaryElement = (primaryElement: ReactNode): void => {
    this.primaryElement = primaryElement;
  };

  /**
   * Page UI bar second left element after the title.
   */
  @observable secondaryElement: ReactNode = null;
  @action setSecondaryElement = (secondaryElement: ReactNode): void => {
    this.secondaryElement = secondaryElement;
  };

  /**
   * Primary UI bar top right first CTA
   */
  @observable primaryCta: ReactNode = null;
  @action setPrimaryCta = (primaryCta: ReactNode): void => {
    this.primaryCta = primaryCta;
  };

  /**
   * Primary UI bar top right second CTA
   */
  @observable secondaryCta: ReactNode = null;
  @action setSecondaryCta = (secondaryCta: ReactNode): void => {
    this.secondaryCta = secondaryCta;
  };

  /**
   * Set if history routing is supported on the current layout.
   *
   * Use router will determine if links that can be history routed are able to.
   * If this is false all links will use normal `href` links. If this is true the menu
   * item settings will determine if history routing is used or not.
   */
  @observable useRouter = true;
  @action setUseRouter = (status: boolean): void => {
    this.useRouter = status;
  };

  @computed
  get hasActiveTeam(): boolean {
    return !!this.activeTeam;
  }

  /**
   * Reset and set all top nav components in a single callback.
   *
   * This was done to prevent `resetComponents()` from being called after top
   * nav components had been set. This happened when resetComponents() was
   * called from a reaction or autorun and triggered after the top nav was
   * already setup.
   *
   * By combining the reset and the setup of the top nav into a single call they
   * are never called separately and always called together and we never have an
   * instance where reset accidentally clears active top nav components.
   */
  public setTopNav = (callBack?: (store: AppLayoutUiStore) => void, manualTitle?: string): void => {
    // Clear all components
    this.resetComponents();

    // Build and set title
    manualTitle ? this.setTitle(manualTitle) : this.whenReadyTitleFromBreadcrumb();

    if (callBack) {
      callBack(this);
    }
  };

  /**
   * Graceful handle updating the title from the menu. This prevents components
   * form needing to setup the when.
   */
  public whenReadyTitleFromBreadcrumb = (): void => {
    when(
      () => !!this.menu && !!this.path,
      () => this.handleTitleFromBreadcrumb()
    );
  };

  public updateLayoutOrg = (organizationId: number): void => {
    if (organizationId === this.activeOrganization?.id) {
      return;
    }

    this.resetComponents();
    this.setActiveOrganization(null);
    this.setActiveTeam(null);
    this.loadActiveOrgAndTeam(organizationId);
  };

  public updateLayoutTeam = (team: TeamModel): void => {
    // Current organization should be the first priority
    if (team.organization_id !== this.activeOrganization?.id) {
      return;
    }

    // if teamId from the URL is different than the current active team, we should update the active team
    if (team.id !== this.activeTeam?.id) {
      this.loadActiveOrgAndTeam(team.organization_id, team.id);
    }
  };

  /**
   * A lot of discussion happened about this method. Please be ware, this is a dangerous place.
   * This is a top level method, that should be called once the page is mounted, or if there an org/team in the URL detected.
   *
   * If we allow this endpoint to be called from different places, it would cause conflict, as we could end up having an active organization from the BE, different
   * than the active organization in the URL.
   * @param activeOrganizationId
   * @param activeTeamId
   *
   * @returns Promise<void>
   */
  public loadActiveOrgAndTeam = async (
    activeOrganizationId?: number,
    activeTeamId?: number
  ): Promise<void> => {
    this.setIsLoading(true);
    const orgId = activeOrganizationId ?? this.activeOrganization?.id;
    const teamId = activeTeamId ?? this.activeTeam?.id;

    const response = await this.menuStore.getActiveOrgIdAndTeam(orgId, teamId);

    if (response?.active_organization) {
      this.setActiveOrganization(response.active_organization);
      this.setActiveTeam(response.active_team);
      await this.organizationStore.getOrganizationById(response.active_organization.id);
    }

    this.setIsLoading(false);
  };

  /**
   * Update the title based on breadcrumbs
   */
  public handleTitleFromBreadcrumb = (): void => {
    if (!this.menu || !this.path) {
      return;
    }

    const pathItem = this.currentMenuItem();

    if (pathItem) {
      this.setTitleFromItem(pathItem);
      return;
    }

    // If no path found, ensure we clear the title
    this.setTitle(null);
  };

  /**
   * Reset all top nav components, excluding the title.
   */
  @action
  protected resetComponents = (): void => {
    this.setPrimaryElement(null);
    this.setSecondaryElement(null);
    this.setPrimaryCta(null);
    this.setSecondaryCta(null);
  };

  /**
   * Set the main top nav title using a menu item.
   */
  protected setTitleFromItem(item: MenuItemModel): void {
    if (!item?.breadcrumb) {
      return;
    }

    if (item.breadcrumb.length > 0) {
      this.updateTitle(item.breadcrumb.join(' / '));
      return;
    }

    this.updateTitle(item.name);
  }

  @computed
  get menu(): MenuModel {
    return this.menuStore.getMenu('main');
  }

  currentMenuItem(menuItems: MenuItemModel[] = null): MenuItemModel {
    // Fallback to main app dashboard menu if none supplied
    const itemsToSearch = menuItems || this.menu.menu_items;

    return this.findMenuItem(itemsToSearch);
  }

  /**
   * Recursively look for a matching menu item based on the current path.
   *
   * We avoid using things like `Array.prototype.find()`` as they make recursion
   * difficult and you end up finding the top level parent, instead of the
   * deepest nested child.
   */
  protected findMenuItem(items: MenuItemModel[]): MenuItemModel {
    for (const item of items) {
      // Check if item matches
      if (this.menuItemPathMatch(item)) {
        return item;
      }

      let foundItem = null;

      // Check the children
      if (item.children.length > 0) {
        foundItem = this.findMenuItem(item.children);
      }

      // Only stop looping when we have a match
      if (foundItem) {
        return foundItem;
      }
    }
  }

  /**
   * Check if menu item match our path and item type.
   */
  protected menuItemPathMatch(item: MenuItemModel): boolean {
    // Only links and hidden declared items can be matched to a path and used for titles
    const types = [MenuItemTypes.Link, MenuItemTypes.Declared, MenuItemTypes.Component];

    if (!types.includes(item.type)) {
      return false;
    }

    const pathMatchOptions = { path: item.token_path, exact: true };
    return item.url === this.path || !!matchPath(this.path, pathMatchOptions);
  }

  protected updateTitle(title: string) {
    this.setTitle(title);
    NavService.setHeadTitle(title);
  }
}
