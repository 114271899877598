import React, { ReactNode } from 'react';

import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Bar, Circle, Polygon } from '@visx/shape';
import { Tooltip } from 'antd';
import cx from 'classnames';
import { kebabCase, map, uniqueId } from 'lodash';
import { computed } from 'mobx';

import './ScatterPlot.scss';

import { SCATTER_PLOT_AXIS } from 'app/constants';
import { ScatterPlotPoint } from 'app/models';

const axisThickness = 3;
const halfAxisThickness = axisThickness / 2;

const SCATTERPLOT_MARKER_DIAMOND = 'diamond';
const PERSONAL_RESULT_RADIUS = 11;
const POINT_COLOR = '#262626';

type Scaler = (x: number) => number;

export type ScatterPlotGradientStop = {
  color: string;
  offset: string;
};

export interface ScatterPlotProps {
  title?: string;
  points: ScatterPlotPoint[];
  labels?: {
    top: ReactNode;
    bottom: ReactNode;
    right: ReactNode;
    left: ReactNode;
  };
  gradientStops?: ScatterPlotGradientStop[];
  pointRadius?: number;
  pointColor?: string;
  size?: number;
  labelPadding?: number;
  footer?: ReactNode;
}

export class ScatterPlot extends React.Component<ScatterPlotProps> {
  static defaultProps = { pointRadius: 5, labelPadding: 24 };

  suffix = '';

  constructor(props) {
    super(props);
    this.suffix = uniqueId();
  }

  @computed
  get xaxisId() {
    return `xaxis-gradient-${this.suffix}`;
  }

  @computed
  get yaxisId() {
    return `yaxis-gradient-${this.suffix}`;
  }

  getLinearScales(size: number, padding = 0) {
    const dx = scaleLinear({
      domain: [-100, 100],
      range: [padding, size - padding],
    });

    const dy = scaleLinear({
      domain: [-100, 100],
      range: [size - padding, padding],
    });

    return { dx, dy };
  }

  defineGradients() {
    return (
      <>
        <LinearGradient id={this.xaxisId} x1="0%" x2="100%" y1="0%" y2="0%" />
        <LinearGradient id={this.yaxisId} x1="0%" x2="0%" y1="0%" y2="100%" />
      </>
    );
  }

  drawBorder(top: number, left: number, size: number) {
    return <rect x={left} y={top} width={size} height={size} className="scatter-plot-border" />;
  }

  drawxAxis(left: number, size: number, dy: Scaler) {
    return (
      <Bar
        x={left + (size * 2.5) / 100}
        y={dy(0) - halfAxisThickness}
        width={size}
        height={axisThickness}
        fill={SCATTER_PLOT_AXIS}
      />
    );
  }

  drawyAxis(top: number, size: number, dx: Scaler) {
    return (
      <Bar
        x={dx(0) - halfAxisThickness}
        y={top + (size * 2.5) / 100}
        width={axisThickness}
        height={size}
        fill={SCATTER_PLOT_AXIS}
      />
    );
  }

  drawPoints(dx: Scaler, dy: Scaler) {
    const { points, pointRadius } = this.props;

    return points.map((point, i) => {
      const isPersonalWithLabel = point.isPersonalAnswer && !!point.label;

      const props = {
        key: `point-${i}`,
        'data-testid': 'scatter-plot-point',
        className: cx('scatter-plot-point', point.className),
        r: isPersonalWithLabel ? PERSONAL_RESULT_RADIUS : pointRadius,
        fill: POINT_COLOR,
      };

      if (point.label) {
        props['title'] = point.label;
        props['data-toggle'] = 'tooltip';
        props['data-placement'] = 'top';
      }

      if (point.data) {
        map(point.data, (val, key) => (props[`data-${kebabCase(key)}`] = val));
      }

      const wrapTooltip = (marker) => <Tooltip title={point.label}>{marker}</Tooltip>;

      if (point.shape === SCATTERPLOT_MARKER_DIAMOND) {
        const pointMarker = (
          <Group key={`polygon-${i}`} left={dx(point.x)} top={dy(point.y)}>
            <Polygon sides={4} size={10} rotate={90} {...props} />
          </Group>
        );

        return point.label ? wrapTooltip(pointMarker) : pointMarker;
      }

      if (point.isPersonalAnswer) {
        const pointMarker = (
          <Group key={i}>
            <Circle cx={dx(point.x)} cy={dy(point.y)} {...props} />
            <text
              x={dx(point.x)}
              y={dy(point.y)}
              className={cx({ 'personal-result': isPersonalWithLabel })}
            >
              {point.label}
            </text>
          </Group>
        );

        return point.label ? wrapTooltip(pointMarker) : pointMarker;
      }

      const pointMarker = <Circle key={i} cx={dx(point.x)} cy={dy(point.y)} {...props} />;

      return point.label ? wrapTooltip(pointMarker) : pointMarker;
    });
  }

  renderLabels(size: number, dx: Scaler, dy: Scaler) {
    const { labels, labelPadding } = this.props;
    if (!labels) {
      return null;
    }

    const labelAxes = [
      { label: labels.top, x: 0, y: 110, position: 'top' },
      { label: labels.bottom, x: 0, y: -110, position: 'bottom' },
      { label: labels.left, x: -110, y: 0, position: 'left' },
      { label: labels.right, x: 108, y: 0, position: 'right' },
    ];

    const totalLabelPadding = labelPadding * 2;
    const fontSize = size + totalLabelPadding < 400 ? 10 : 16;
    return labelAxes.map(({ label, x, y, position }, i) => {
      const testId = `scatter-plot-label-${position}`;
      return (
        <div
          key={`label-${i}`}
          data-testid={testId}
          className={cx('scatter-plot-label', testId)}
          style={{ left: dx(x), top: dy(y), fontSize }}
        >
          {label}
        </div>
      );
    });
  }

  renderPlot(computedSize?: number) {
    const size = this.props.size || computedSize;
    const { dx, dy } = this.getLinearScales(size);
    const top = dy(100);
    const left = dx(-100);
    const axisSize = (size * 95) / 100;

    return (
      <div className="scatter-plot-graph">
        <svg width={size} height={size}>
          {this.defineGradients()}
          {this.drawBorder(top, left, size)}
          {this.drawxAxis(left, axisSize, dy)}
          {this.drawyAxis(top, axisSize, dx)}
          {this.drawPoints(dx, dy)}
        </svg>
        {this.renderLabels(size, dx, dy)}
      </div>
    );
  }

  render() {
    const { title, labelPadding, footer } = this.props;

    return (
      <div className="scatter-plot" data-testid="scatter-plot">
        {title && <h4 className="scatter-plot-title">{title}</h4>}
        <div className="scatter-plot-inner" style={{ padding: labelPadding }}>
          <ParentSize className="scatter-plot-container">
            {({ width: size }) => this.renderPlot(size)}
          </ParentSize>
        </div>
        {footer}
      </div>
    );
  }
}

export default ScatterPlot;
