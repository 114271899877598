import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { MenuStore } from 'app/stores';

export enum MenuItemTypes {
  Declared = 'declared',
  Heading = 'heading',
  Link = 'link',
  Group = 'group',
  Component = 'component',
}

export class MenuItemModel extends Model {
  public static _store: MenuStore;

  @observable id: string;
  @observable name: string;
  @observable url: string;
  @observable token_path?: string;
  @observable routed: boolean;
  @observable in_new_window: boolean;
  @observable appends_referrer: boolean;
  @observable children: MenuItemModel[] = [];
  @observable type?: MenuItemTypes;
  @observable breadcrumb: string[] = [];
  @observable icon?: string;
  @observable label?: string;

  deserialize_children(children): void {
    this.children = children.map((child) => MenuItemModel.fromJson(child));
  }

  static fromJson(json: ModelJson): MenuItemModel {
    return this._fromJson(json) as MenuItemModel;
  }

  static getOrNew(id): MenuItemModel {
    return this._getOrNew(id) as MenuItemModel;
  }

  static get(id): MenuItemModel {
    return this._get(id) as MenuItemModel;
  }
}

export default MenuItemModel;
