import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { MemberStateModel } from 'app/models/MemberStateModel';
import { ModelItem } from 'app/models/ModelItem';
import Store from 'app/stores/Store';

export class MemberStateStore extends Store<MemberStateModel> {
  @observable public currentMemberState = new ModelItem<MemberStateModel>(MemberStateModel);

  constructor() {
    super();
    MemberStateModel._store = this;
  }

  loadCurrentMemberState() {
    const url = ServerRouteHelper.api.members.currentMemberState();
    return this.currentMemberState.load(url, null, { dataKey: 'member_state' });
  }
}

export default MemberStateStore;
