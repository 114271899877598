export enum TeamGameStatus {
  Pending = 'pending',
  Started = 'started',
  Finished = 'finished',
}

export enum TeamGameParticipantStatus {
  Pending = 'pending',
  Completed = 'completed',
}
