import { observable } from 'mobx';

import BaseModel from 'app/models/BaseModel';

export class ToolSurveyAnswerModel extends BaseModel {
  @observable id: number;
  @observable tag: string;
  @observable answer: string;

  static fromJson(json: Record<string, any>) {
    return this._fromJson(json) as ToolSurveyAnswerModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as ToolSurveyAnswerModel;
  }

  static get(id) {
    return this._get(id) as ToolSurveyAnswerModel;
  }
}

export default ToolSurveyAnswerModel;
