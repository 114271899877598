import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { MemberConfirmationModel } from 'app/models/MemberConfirmationModel';
import Store from 'app/stores/Store';

export class MemberConfirmationStore extends Store<MemberConfirmationModel> {
  @observable isSubmittingGuestInfo = false;
  @action toggleIsSubmittingGuestInfo = (): void => {
    this.isSubmittingGuestInfo = !this.isSubmittingGuestInfo;
  };

  @observable isSubmitted = false;
  @action setIsSubmitted = (isSubmitted: boolean): void => {
    this.isSubmitted = isSubmitted;
  };

  @observable showBanner = true;
  @action setShowBanner = (showBanner: boolean): void => {
    this.showBanner = showBanner;
  };

  @observable currentMemberHasConfirmedDetails = false;
  @action setCurrentMemberHasConfirmedDetails = (status: boolean): void => {
    this.currentMemberHasConfirmedDetails = status;
  };

  handleClose = () => {
    if (!this.isSubmitted) {
      return;
    }

    this.setShowBanner(false);
  };

  @observable has_accepted_policy: boolean;

  constructor() {
    super();
    MemberConfirmationModel._store = this;
  }

  async guestConfirm(
    name: string,
    email: string,
    ownerId: number,
    sharedLinkToken: string,
    teamInfo?: { team_id: number; team_code: string },
    exerciseId?: number
  ): Promise<string> {
    const url = ServerRouteHelper.api.members.confirmations.guestConfirm(sharedLinkToken);
    const payload = Object.assign({ name, email, ownerId, teamInfo, exerciseId });

    this.toggleIsSubmittingGuestInfo();
    try {
      const response = await this.apiService.post(url, payload);
      this.setIsSubmitted(true);
      this.has_accepted_policy = !!response?.data?.has_accepted_policy;
      this.setCurrentMemberHasConfirmedDetails(true);
      return response?.data?.token;
    } finally {
      this.toggleIsSubmittingGuestInfo();
    }
  }
}

export default MemberConfirmationStore;
