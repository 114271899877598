import React, { ReactElement } from 'react';

import { notification } from 'antd';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import RichTextEditor from 'react-rte';
import Validator from 'validator';

import AntModal from 'app/components/ui/AntModal';
import { ModalAction } from 'app/components/ui/AntModal/AntModal';
import {
  MANAGER_INVITE_EMAIL_TEXT,
  REACT_RTE_EDITOR_TOOLBAR_CONFIG,
  STORE_ORGANIZATION,
} from 'app/constants';
import { MemberModel } from 'app/models';
import { OrganizationStore } from 'app/stores';

export interface InviteManagersModalProps {
  organizationStore?: OrganizationStore;
  currentMember: MemberModel;
  showModal: boolean;
  onClose: () => void;
}

enum VIEW {
  InviteManagersView,
  EditEmailView,
  SuccessView,
}

export class InviteManagersModal extends React.Component<InviteManagersModalProps> {
  @observable private view = VIEW.InviteManagersView;
  @observable private textEditorValue;
  @observable private managerEmails = [];

  @observable isCreatingTeams = false;
  @action setIsCreatingTeams = (creatingTeams: boolean): void => {
    this.isCreatingTeams = creatingTeams;
  };

  constructor(props: InviteManagersModalProps) {
    super(props);
    this.init();
  }

  init(): void {
    this.addNewManagerEmail();
    this.textEditorValue = RichTextEditor.createValueFromString(
      MANAGER_INVITE_EMAIL_TEXT(this.memberName),
      'markdown'
    );
  }

  @action
  addNewManagerEmail = (): void => {
    this.managerEmails.push('');
  };

  @action
  updateManagerEmail = (email: string, index: number): void => {
    this.managerEmails[index] = email;
  };

  @action
  loadEditEmailView = (): void => {
    if (!this.isValidForm) {
      notification.error({
        message: 'Oops!',
        description: 'Please enter valid email address to continue.',
      });
      return;
    }
    this.view = VIEW.EditEmailView;
  };

  @action
  loadInviteManagerView = (): void => {
    this.view = VIEW.InviteManagersView;
  };

  @action
  loadSuccessView = (): void => {
    this.view = VIEW.SuccessView;
  };

  @action
  handleTextEditorChange = (value) => {
    this.textEditorValue = value;
  };

  @action
  sendInviteEmail = async (): Promise<void> => {
    const teams = this.managerEmails.map((manager_email) => ({ manager_email }));
    const data = { teams, email_message: this.textEditorValue.toString('markdown') };

    try {
      this.setIsCreatingTeams(true);
      await this.props[STORE_ORGANIZATION].createTeams(this.orgId, data);
      this.loadSuccessView();
    } catch (e) {
      notification.error({
        message: 'Oops!',
        description: 'Something went wrong!',
      });
    }
    this.setIsCreatingTeams(false);
  };

  get isValidForm(): boolean {
    let valid = true;
    const emails = this.managerEmails.filter((email) => {
      if (email && !Validator.isEmail(email)) {
        valid = false;
      }
      return !!email;
    });

    return valid && emails.length > 0;
  }

  get isLoading(): boolean {
    return this.props.organizationStore.organization.loading;
  }

  get orgId(): number {
    return this.props[STORE_ORGANIZATION].organization.item.id;
  }

  get memberName(): string {
    return this.props.currentMember.name;
  }

  get title(): string {
    switch (this.view) {
      case VIEW.InviteManagersView:
      case VIEW.EditEmailView:
        return 'Invite Your Team';
      case VIEW.SuccessView:
        return 'Success!';
      default:
        return null;
    }
  }

  get renderInviteManagerView(): ReactElement {
    return (
      <div>
        <p>You can invite as many people as you want to use the tools with their teams.</p>
        {this.managerEmails.map((email, index) => {
          return this.renderInputEmailFields(email, index);
        })}
        <div className="form-group">
          <a href="#" role="button" onClick={this.addNewManagerEmail}>
            <i aria-hidden="true" className="far fa-plus mr-2" />
            Add Another Manager
          </a>
        </div>
      </div>
    );
  }

  get renderEditEmailView() {
    return (
      <RichTextEditor
        className="mb-2"
        autoFocus
        toolbarConfig={{
          display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
          INLINE_STYLE_BUTTONS: REACT_RTE_EDITOR_TOOLBAR_CONFIG.INLINE_STYLE_BUTTONS,
          BLOCK_TYPE_BUTTONS: REACT_RTE_EDITOR_TOOLBAR_CONFIG.BLOCK_TYPE_BUTTONS,
          // @ts-ignore
          HISTORY_BUTTONS: REACT_RTE_EDITOR_TOOLBAR_CONFIG.HISTORY_BUTTONS,
        }}
        value={this.textEditorValue}
        onChange={this.handleTextEditorChange}
      />
    );
  }

  get renderSuccessView(): ReactElement {
    return (
      <>
        <p>
          Your invitation has been sent. We'll send you a notice via email when the manager(s) join.
        </p>
        <img
          role="presentation"
          src="/images/superManagerDashboard/send-invites-success.png"
          className="w-100"
        />
      </>
    );
  }

  get renderBody(): ReactElement {
    switch (this.view) {
      case VIEW.InviteManagersView:
        return this.renderInviteManagerView;
      case VIEW.EditEmailView:
        return this.renderEditEmailView;
      case VIEW.SuccessView:
        return this.renderSuccessView;
    }
  }

  get primaryAction(): ModalAction {
    switch (this.view) {
      case VIEW.InviteManagersView:
        return {
          label: 'Preview Invite Email',
          onClick: this.loadEditEmailView,
        };
      case VIEW.EditEmailView:
        return {
          label: 'Send Invite Email',
          onClick: this.sendInviteEmail,
          disabled: this.isCreatingTeams,
        };
      case VIEW.SuccessView:
        return {
          label: 'Sounds Great!',
          onClick: this.handleClose,
        };

      default:
        return null;
    }
  }

  get secondaryAction(): ModalAction {
    return {
      label: 'Cancel',
      onClick: this.handleClose,
    };
  }

  @action
  handleClose = (): void => {
    this.view = VIEW.InviteManagersView;
    this.managerEmails = [''];
    this.props.onClose();
  };

  render(): ReactElement {
    if (this.isLoading) {
      return null;
    }

    return (
      <AntModal
        isOpen={this.props.showModal}
        width={500}
        title={this.title}
        onToggle={this.handleClose}
        primaryAction={this.primaryAction}
        secondaryAction={this.secondaryAction}
      >
        {this.renderBody}
      </AntModal>
    );
  }

  renderInputEmailFields = (email: string, index: number): ReactElement => {
    return (
      <div key={index} className="div form-team mb-2">
        <div className="form-group">
          <label htmlFor="team_manager_email">Manager's Email</label>
          <div>
            <input
              className="form-control"
              type="email"
              id="team_manager_email"
              required
              placeholder="Manager email"
              value={email}
              onChange={(e) => {
                this.updateManagerEmail(e.target.value, index);
              }}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default inject(STORE_ORGANIZATION)(observer(InviteManagersModal));
