import * as React from 'react';

import { ExerciseTypeModel } from 'app/models';

import ExerciseType from '../ExerciseType';

export interface FeaturedExerciseTypeListProps {
  featuredExerciseTypes: Array<ExerciseTypeModel>;
  onMoveUp: (ExerciseTypeModel) => void;
  onMoveDown: (ExerciseTypeModel) => void;
  onRemove: (ExerciseTypeModel) => void;
}

export const FeaturedExerciseTypeList: React.StatelessComponent<FeaturedExerciseTypeListProps> = ({
  featuredExerciseTypes,
  onMoveUp,
  onMoveDown,
  onRemove,
}) => (
  <div className="featured-exercise-types">
    <h4>Featured Exercise Types</h4>
    <div>
      {featuredExerciseTypes.map((exerciseType, i) => (
        <ExerciseType
          key={`exercise-type-${i}`}
          exerciseType={exerciseType}
          onMoveUp={onMoveUp}
          onMoveDown={onMoveDown}
          onRemove={onRemove}
        />
      ))}
    </div>
  </div>
);

export default FeaturedExerciseTypeList;
