import { PerspectiveDimensionModel } from 'app/models';
import Store from 'app/stores/Store';

export class PerspectiveDimensionStore extends Store<PerspectiveDimensionModel> {
  constructor() {
    super();
    PerspectiveDimensionModel._store = this;
  }
}

export default PerspectiveDimensionStore;
