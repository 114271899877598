export enum ExerciseCardListView {
  REGULAR,
  DETAILED,
}

export enum AlignCreateStepKey {
  Template = 'template',
  Invite = 'invite',
}

export const SINGLE_PLAYER_EXERCISE_CODE = 'single-player-foundations';
