import { observable } from 'mobx';

import { MemberModel, OrganizationModel } from 'app/models';
import Model, { ModelJson } from 'app/models/Model';
import { CoachStore } from 'app/stores';

export class CoachModel extends Model {
  static _store: CoachStore;

  @observable orgs: OrganizationModel[];
  @observable members: MemberModel[];

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as CoachModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as CoachModel;
  }

  static get(id) {
    return this._get(id) as CoachModel;
  }
}

export default CoachModel;
