import React, { ReactNode } from 'react';

import { observer } from 'mobx-react';

import MarkdownEditor from 'app/components/features/MarkdownEditor';
import PulseStatement from 'app/components/features/PulseStatement';
import AntModal from 'app/components/ui/AntModal';
import { ModalAction } from 'app/components/ui/Modal/Modal';
import { PulseStatementModel } from 'app/models';
import PulseHabitsContext from 'app/pages/dashboard/Pulse/Context/PulseHabitsContext';

export class InviteEmailPreview extends React.Component {
  static contextType = PulseHabitsContext;

  private get habits(): PulseStatementModel[] {
    return this.context.uiStore.habits;
  }

  private get modalAction(): ModalAction {
    return {
      label: 'Save',
      onClick: this.handleSave,
    };
  }

  private handleEmailContentChange = (content: string) => {
    this.context.uiStore.updateReminderEmailContent(content);
  };

  private handleSave = () => {
    const { saveReminder, toggleEmailModal } = this.context.uiStore;

    saveReminder();
    toggleEmailModal();
  };

  public render(): ReactNode {
    return (
      <AntModal
        title={<h6>Preview Email</h6>}
        isOpen={this.context.uiStore.isEmailModalOpen}
        onToggle={this.context.uiStore.toggleEmailModal}
        primaryAction={this.modalAction}
      >
        <MarkdownEditor
          content={this.context.uiStore.inviteEmailContent}
          onChange={this.handleEmailContentChange}
        />

        {this.habits.map((habit) => (
          <PulseStatement key={habit.id} statement={habit} />
        ))}
      </AntModal>
    );
  }
}

export default observer(InviteEmailPreview);
