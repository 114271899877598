import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const Onboarding = lazy(() => import('./Onboarding'));
const MemberPulse = lazy(() => import('./MemberPulse'));

export default (
  <Suspense fallback={<Loading />}>
    <Route path="/member/onboarding/:page?" component={Onboarding} />
    <Route exact path="/members/:memberId/pulses/:token" component={MemberPulse} />
  </Suspense>
);
