import React, { ReactNode } from 'react';

import { DragOutlined, LockFilled, TeamOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import './ExerciseIntroCard.scss';

import Card from 'app/components/ui/Card';
import { ExerciseTypeModel } from 'app/models';

export interface ExerciseIntroCardProps {
  exerciseType: ExerciseTypeModel;
  teamName?: string;
  exerciseTypeLink?: string;
  exerciseTypeLinkName?: string;
  onReadyClick: () => void;
}

export class ExerciseIntroCard extends React.Component<ExerciseIntroCardProps> {
  get title(): React.ReactNode {
    return (
      <div className="card-title">
        <div className="right-section">
          <p className="header">Help your team improve</p>
          <p className="sub-header">Rate how you sense the team is doing. </p>
        </div>
        <Button
          type="primary"
          className="action-btn"
          id="intro-card-action-btn"
          onClick={this.props.onReadyClick}
        >
          I'm ready!
        </Button>
      </div>
    );
  }

  renderPointToRemember = (icon, header, caption): ReactNode => (
    <div className="point-to-remember-container">
      {icon}
      <div className="right-container">
        <p className="sub-header">{header}</p>
        <p className="caption">{caption}</p>
      </div>
    </div>
  );

  render() {
    const statementCount = this.props.exerciseType.align_statements?.length;

    return (
      <Card newDesign title={this.title} className="exercise-intro-card">
        <div>
          <p>
            You’ve been asked to assess how your team {this.props.teamName} is doing on{' '}
            {statementCount} statements.
          </p>
        </div>
        <div>
          <p className="sub-header">Remember:</p>

          {this.renderPointToRemember(
            <LockFilled />,
            "Don't hold back",
            "Your answers are 100% anonymous, so you're free to be honest."
          )}

          {this.renderPointToRemember(
            <TeamOutlined />,
            'Focus on the team',
            'Your choices should reflect how the team is doing, rather than the wider organisation.'
          )}

          {this.renderPointToRemember(
            <DragOutlined />,
            'Keep it relative',
            'If all the cards seem important, try thinking about their relative importance right now.'
          )}

          {this.props.exerciseTypeLink && (
            <div className="exercise-type-link-wrapper">
              <Button
                type="link"
                target="_blank"
                href={this.props.exerciseTypeLink}
                data-testid="exercise-type-link"
              >
                {this.props.exerciseTypeLinkName}
              </Button>
            </div>
          )}
        </div>
      </Card>
    );
  }
}

export default ExerciseIntroCard;
