import ThirdPartyService from 'app/services/ThirdPartyService';

export default {
  set: (key: string, data: any) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(data));
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    }
  },

  get: (key: string) => {
    try {
      const value = window.localStorage.getItem(key);
      try {
        return JSON.parse(value);
      } catch (err) {
        ThirdPartyService.sentry.captureException(err);
        return value;
      }
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
      return null;
    }
  },

  remove: (key: string) => {
    try {
      window.localStorage.removeItem(key);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    }
  },
};
