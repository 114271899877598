import React, { Component, ReactNode } from 'react';

import { computed } from 'mobx';

import { preventDefault } from 'app/helpers';
import { PulseTemplateModel } from 'app/models';

interface PulseTemplateListProps {
  allTemplates: PulseTemplateModel[];
  selectedTemplates: { [id: number]: boolean };
  onSave: () => void;
  onSelected: (template: PulseTemplateModel) => void;
  isSaving: boolean;
}

export class PulseTemplateList extends Component<PulseTemplateListProps> {
  private renderTemplateCheckbox = (template: PulseTemplateModel): ReactNode => {
    const { selectedTemplates, onSelected } = this.props;

    return (
      <label>
        <input
          className="form-check-input"
          type="checkbox"
          value={template.id}
          defaultChecked={selectedTemplates[template.id]}
          onChange={() => onSelected(template)}
        />
        {template.name}
      </label>
    );
  };

  @computed
  private get saveButton(): ReactNode {
    const { onSave, isSaving } = this.props;

    return (
      <button
        type="submit"
        className="btn acc-btn-caps btn-primary"
        onClick={preventDefault(onSave)}
        disabled={isSaving}
      >
        {isSaving ? 'Saving...' : 'Save'}
      </button>
    );
  }

  public render(): ReactNode {
    const { allTemplates } = this.props;

    return (
      <div>
        <h4>Pulse Templates</h4>

        <form method="post">
          <div style={{ columns: 'auto 15rem' }}>
            {allTemplates.map((template, i) => (
              <div key={`pulse-template-${i}`} className="form-check">
                {this.renderTemplateCheckbox(template)}
              </div>
            ))}
          </div>

          <div className="mt-2 text-right">{this.saveButton}</div>
        </form>
      </div>
    );
  }
}

export default PulseTemplateList;
