import { find, keyBy } from 'lodash';
import type { IObservableArray } from 'mobx';
import { computed, observable } from 'mobx';

import { BOSTON_SCIENTIFIC_CODE, ESSITY_SECRET_CODE } from 'app/constants';
import { EntityImageTemplate } from 'app/constants/EntityImageTemplate';
import ContentSectionModel from 'app/models/ContentSectionModel';
import CustomLandingPagesLinkModel from 'app/models/CustomLandingPagesLinkModel';
import ExerciseTypeModel from 'app/models/ExerciseTypeModel';
import FeatureModel, { Features } from 'app/models/FeatureModel';
import MemberModel from 'app/models/MemberModel';
import { ModelItem } from 'app/models/ModelItem';
import { ModelList } from 'app/models/ModelList';
import { OrganizationPivotModel } from 'app/models/OrganizationPivotModel';
import { OrganizationStats } from 'app/models/OrganizationStats';
import { PaginatedModelList } from 'app/models/PaginatedModelList';
import PulseTemplateModel from 'app/models/PulseTemplateModel';
import { RoleModel } from 'app/models/RoleModel';
import TeamGroupDivisionModel from 'app/models/TeamGroupDivisionModel';
import TeamGroupModel from 'app/models/TeamGroupModel';
import TeamModel from 'app/models/TeamModel';
import { OrganizationStore } from 'app/stores/OrganizationStore';

import allModels from './allModels';
import Model, { ModelJson } from './Model';
import { PairViewAccess } from './PerspectiveModel';

export class OrganizationModel extends Model {
  static _store: OrganizationStore;

  @observable code: string;
  @observable name: string;

  @observable lookup_tag?: string;
  @observable custom_localization: { [key: string]: string };
  @observable member_role_editable: boolean;
  @observable sso_enabled: boolean;
  @observable team_group_divisions?: TeamGroupDivisionModel[];
  @observable team_groups?: TeamGroupModel[];

  @observable content_sections = new ModelList<ContentSectionModel>(ContentSectionModel);
  @observable exercise_types?: IObservableArray<ExerciseTypeModel> = observable([]);
  @observable active_exercise_types?: IObservableArray<ExerciseTypeModel> = observable([]);
  @observable featured_exercise_types?: IObservableArray<ExerciseTypeModel> = observable([]);
  @observable pair_perspective_access_default?: PairViewAccess;
  @observable perspectives_count?: number;

  @observable email_domains: string[] = [];
  @observable pulses = new ModelList<CustomLandingPagesLinkModel>(CustomLandingPagesLinkModel);
  @observable pulse_templates = new ModelList<PulseTemplateModel>(PulseTemplateModel);
  @observable pulse_templates_disabled = new ModelList<PulseTemplateModel>(PulseTemplateModel);
  @observable features = new ModelList<FeatureModel>(FeatureModel);
  @observable roles = new ModelList<RoleModel>(RoleModel);
  @observable organizationStats = new ModelItem<OrganizationStats>(OrganizationStats);
  @observable teamGroups = new ModelList<TeamGroupModel>(TeamGroupModel);
  @observable teamGroupDivisions = new ModelList<TeamGroupModel>(TeamGroupModel);
  @observable members = new PaginatedModelList<MemberModel>(MemberModel);
  @observable teams = new PaginatedModelList<TeamModel>(TeamModel);
  @observable exercises_count?: number;
  // the source is coming from navigation store (loadMainNavData)
  @observable pivot?: OrganizationPivotModel;

  @observable logo_urls?: Record<EntityImageTemplate, string>;
  @observable is_segments_enabled?: boolean;
  @observable is_subscription_enabled?: boolean;

  localize(key: string, fallback: string) {
    if (this.custom_localization && this.custom_localization[key]) {
      return this.custom_localization[key];
    }
    return fallback;
  }

  // We only interested on the domain string
  deserialize_email_domains(email_domains) {
    this.email_domains = email_domains.map(({ domain }) => domain);
  }

  deserialize_team_group_divisions(divisions) {
    this.team_group_divisions = divisions.map((division) =>
      TeamGroupDivisionModel.fromJson(division)
    );
  }

  deserialize_team_groups(groups) {
    this.team_groups = groups.map((group) => TeamGroupModel.fromJson(group));
  }

  isFeatureEnabled(name) {
    return !!find(this.features.items, { name });
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as OrganizationModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as OrganizationModel;
  }

  static get(id) {
    return this._get(id) as OrganizationModel;
  }

  @computed
  get isEssity(): boolean {
    return this.code === ESSITY_SECRET_CODE;
  }

  @computed
  get isBostonScientific(): boolean {
    return this.code === BOSTON_SCIENTIFIC_CODE;
  }

  @computed
  get teamGroupsById(): Record<number, TeamGroupModel> {
    return keyBy(this.team_groups, 'id');
  }

  @computed
  get divisionsById(): Record<number, TeamGroupDivisionModel> {
    return keyBy(this.team_group_divisions, 'id');
  }

  @computed
  get exerciseTypeById(): Record<number, Partial<ExerciseTypeModel>> {
    return keyBy(this.exercise_types, 'id');
  }

  @computed
  get isUsageDashboardDisabled(): boolean {
    return this.isFeatureEnabled(Features.OrgUsageDashboardDisabled);
  }
}

allModels.register({ OrganizationModel });

export default OrganizationModel;
