import { assign } from 'lodash';
import { action, computed, observable } from 'mobx';

import { TeamGameParticipantStatus } from 'app/constants';
import Model from 'app/models/Model';
import TeamGameParticipantStore from 'app/stores/TeamGameParticipantStore';

export class TeamGameAnswerModel {
  id: number;
  member_id: number;
  team_game_question_id: number;
  team_game_question: string;
  answer: string;

  constructor(props) {
    assign(this, props);
  }
}

export class TeamGameParticipantModel extends Model {
  static _store: TeamGameParticipantStore;

  @observable id: number;
  @observable member_id: number;
  @observable email: string;
  @observable name: string;
  @observable status: TeamGameParticipantStatus;
  @observable color?: string;
  @observable answers: TeamGameAnswerModel[];
  @observable avatar?: {
    text: string;
    color: string;
  };

  @action
  deserialize_answers = (answers) => {
    this.answers = answers.map((answer) => new TeamGameAnswerModel(answer));
  };

  @computed
  get isPending(): boolean {
    return this.status === TeamGameParticipantStatus.Pending;
  }

  @computed
  get isCompleted(): boolean {
    return this.status === TeamGameParticipantStatus.Completed;
  }
}

export default TeamGameParticipantModel;
