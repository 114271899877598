import React from 'react';

import cx from 'classnames';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';

import './PulseStatement.scss';

import { PULSE_STATEMENT_BINARY_RANGE, PULSE_STATEMENT_SCALE_RANGE } from 'app/constants';
import { PulseStatementModel } from 'app/models';

import PulseStatementChoice from './PulseStatementChoice';
import PulseStatementLabel from './PulseStatementLabel';

interface PulseStatementProps {
  statement: PulseStatementModel;
  answer?: number;
  onAnswer?: (answer: number) => void;
  isPreview?: boolean;
}

export class PulseStatement extends React.Component<PulseStatementProps> {
  @observable disabled = false;
  @action toggleDisabled = (value: boolean) => (this.disabled = value);

  get statement() {
    return this.props.statement;
  }

  @computed
  get showNotApplicationButton() {
    return this.statement.isScale || this.statement.isBinary;
  }

  handleAnswer = (answer) => this.props.onAnswer?.(answer);

  handleNoChoice = () => {
    this.props.onAnswer?.(null);
    this.toggleDisabled(true);
  };

  @computed
  get scaleChoices() {
    return PULSE_STATEMENT_SCALE_RANGE.map((choice, i) => (
      <PulseStatementChoice
        key={i}
        value={choice}
        selected={choice === this.props.answer}
        disabled={this.disabled}
        onClick={this.handleAnswer}
      />
    ));
  }

  @computed
  get binaryChoices() {
    return PULSE_STATEMENT_BINARY_RANGE.map((choice, i) => (
      <PulseStatementChoice
        key={i}
        value={choice}
        selected={choice === this.props.answer}
        disabled={this.disabled}
        binary
        onClick={this.handleAnswer}
      />
    ));
  }

  @computed
  get textField() {
    return (
      <div className="pulse-statement-text">
        <textarea
          className="pulse-statement-textarea"
          disabled={this.props.isPreview}
          onChange={(event) => this.handleAnswer(event.target.value)}
          defaultValue={this.props.isPreview ? 'Responses will go here as free text...' : ''}
        />
      </div>
    );
  }

  @computed
  get field() {
    if (this.statement.isScale || this.statement.isBinary) {
      return (
        <div className="pulse-statement-choices">
          <PulseStatementLabel label="Not Going Well" />
          {this.statement.isScale && this.scaleChoices}
          {this.statement.isBinary && this.binaryChoices}
          <PulseStatementLabel label="Going Well" />
        </div>
      );
    }

    if (this.statement.isText) {
      return this.textField;
    }

    return null;
  }

  render() {
    return (
      <div
        id={`pulse-statement-${this.props.statement.id}`}
        className={cx('pulse-statement', {
          disabled: this.disabled,
          [this.props.statement.statement_type]: true,
        })}
      >
        {!this.props.isPreview && (
          <h4 className="pulse-statement-title">{this.props.statement.text}</h4>
        )}
        {this.field}
        {this.showNotApplicationButton && !this.props.isPreview && (
          <button
            className={cx('btn not-applicable', { selected: this.disabled })}
            onClick={this.handleNoChoice}
          >
            N/A
          </button>
        )}
      </div>
    );
  }
}

export default observer(PulseStatement);
