import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { DiscussionPromptModel } from 'app/models';
import { LOAD_METHOD } from 'app/models/ModelContainer';
import { ModelList } from 'app/models/ModelList';
import Store from 'app/stores/Store';

export class DiscussionPromptStore extends Store<DiscussionPromptModel> {
  @observable token;
  @action setToken = (token: string): void => {
    this.token = token;
  };

  @observable prompts = new ModelList<DiscussionPromptModel>(DiscussionPromptModel);

  constructor() {
    super();
    DiscussionPromptModel._store = this;
  }

  @action
  async loadPrompts(guideId: number, statements: number[]) {
    let url = ServerRouteHelper.api.discussionGuides.prompts(guideId);

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    await this.prompts.load(url, { statements }, { method: LOAD_METHOD.POST });
  }

  @action
  async loadByStatementIds(discussionSpaceId: number, statementIds: number[]) {
    let url = ServerRouteHelper.api.discussionSpace.prompts(discussionSpaceId);

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    await this.prompts.load(url, { statementIds }, { method: LOAD_METHOD.POST });
  }
}

export default DiscussionPromptStore;
