import React, { ReactNode } from 'react';

import { Button } from 'antd';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';

import styles from './MemberLicenseBanner.module.scss';

import { STORE_FEATURE, STORE_SUBSCRIPTION_MEMBER } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import EventTriggerService, { EventTypes } from 'app/services/EventTriggerService';
import { FeatureStore, SubscriptionMemberStore } from 'app/stores';

export interface MemberLicenseBannerProps extends RouteComponentProps {
  organizationId: number;
  subscriptionMemberStore?: SubscriptionMemberStore;
  featureStore?: FeatureStore;
}

export class MemberLicenseBanner extends React.Component<MemberLicenseBannerProps> {
  constructor(props: MemberLicenseBannerProps) {
    super(props);
    this.init();
  }

  componentDidUpdate(prevProps: Readonly<MemberLicenseBannerProps>): void {
    if (!this.props.organizationId) {
      return;
    }

    if (prevProps.organizationId !== this.props.organizationId) {
      this.init();
    }
  }

  init() {
    this.props.subscriptionMemberStore.loadMemberSubscriptionStatus(this.activeOrgId);
    this.props.featureStore.loadOrgFeatures(this.activeOrgId);
  }

  get activeOrgId(): number {
    return this.props.organizationId;
  }

  handleReviewLicense = (): void => {
    EventTriggerService.send(EventTypes.HONEYPOT_CLICK, {
      cta_name: 'license_review_banner',
      organization_id: this.activeOrgId,
    });

    const url = ServerRouteHelper.dashboard.subscription.billing(this.activeOrgId);
    this.props.history.push(url);
  };

  get content(): ReactNode {
    if (this.props.subscriptionMemberStore.memberLicenseNeeded === false) {
      return null;
    }

    if (this.props.featureStore.isBillingHoneyPotElementsDisabled) {
      return null;
    }

    return (
      <div className={styles.bannerContainer}>
        <div className={styles.content}>
          Payment REQUIRED: Your license is not active. Please click here to activate your license
          and make payment&nbsp;
          <Button
            type="ghost"
            className={styles.reviewLicenseBtn}
            onClick={this.handleReviewLicense}
          >
            Activate License
          </Button>
        </div>
      </div>
    );
  }

  render(): ReactNode {
    return this.content;
  }
}

export default inject(
  STORE_SUBSCRIPTION_MEMBER,
  STORE_FEATURE
)(withRouter(observer(MemberLicenseBanner)));
