import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';
import RegisterFullScreen from 'app/pages/auth/Register/RegisterFullScreen';
import ResetPassword from 'app/pages/auth/ResetPassword';
import VerifyEmail from 'app/pages/auth/VerifyEmail';

import StressRegistration from './Register/StressRegistration';

const Login = lazy(() => import('app/pages/auth/Login'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/invite/:referral_code?" component={RegisterFullScreen} />
      <Route exact path="/register/teams_under_stress" component={StressRegistration} />
      <Route exact path="/register/:secret_code?" component={RegisterFullScreen} />
      <Route exact path="/verify/:token" component={VerifyEmail} />
      <Route exact path="/password/reset/:token" component={ResetPassword} />
    </Switch>
  </Suspense>
);
