import { action, computed, observable } from 'mobx';

import { PulseStatementType } from 'app/constants';
import Model, { ModelJson } from 'app/models/Model';
import { PulseStatementStore } from 'app/stores';

export class PulseStatementModel extends Model {
  static _store: PulseStatementStore;

  @observable id: number;
  @observable type: string;
  @observable statement_type: string;
  @observable text: string;
  @observable order: number;
  @observable template_id?: number;
  @observable template_name?: string;
  @observable template_lookup_tag?: string;
  @observable hidden_at?: string;
  @observable is_default?: boolean;
  @observable is_new?: boolean;

  @action setIsNew = (isNew: boolean): void => {
    this.is_new = isNew;
  };

  // FIXME: This causes circular dependency!
  // @observable responses = new ModelList<PulseResponseModel>(PulseResponseModel);

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PulseStatementModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PulseStatementModel;
  }

  static get(id) {
    return this._get(id) as PulseStatementModel;
  }

  @computed
  get isScale() {
    return this.statement_type === PulseStatementType.Scale;
  }

  @computed
  get isBinary() {
    return this.statement_type === PulseStatementType.Binary;
  }

  @computed
  get isText() {
    return this.statement_type === PulseStatementType.Text;
  }

  @computed
  get isHeader() {
    return this.statement_type === PulseStatementType.Header;
  }
}

export default PulseStatementModel;
