import React, { ReactNode } from 'react';

import { MessageOutlined, ThunderboltFilled } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import './ReflectionCTACards.scss';

import ClipboardButton from 'app/components/ui/ClipboardButton';
import EnhancedCard from 'app/components/ui/EnhancedCard';
import CardFooter from 'app/components/ui/EnhancedCard/CardFooter';
import Markdown from 'app/components/ui/Markdown';
import { STORE_ORGANIZATION_CONTENT } from 'app/constants';
import {
  AlignModel,
  My360ReportReflectCardsContent,
  OrganizationContent,
  OrganizationModel,
} from 'app/models';
import { OrganizationContentStore } from 'app/stores/OrganizationContentStore';

export interface ReflectionCTACardsProps {
  personalReport: string;
  onBookCoach: () => void;
  isTestDrive: boolean;

  organization: Partial<OrganizationModel>;
  exercise: AlignModel;
  organizationContentStore?: OrganizationContentStore;
}

const GUIDE_URL =
  'https://support.valence.co/en/articles/4324213-generating-and-reading-your-reflect-360-report';

export class ReflectionCTACards extends React.Component<ReflectionCTACardsProps> {
  private get personalReport(): string {
    return this.props.personalReport;
  }

  private get exerciseTypeId(): number {
    return this.props.exercise.exercise_type_id;
  }

  private get isTestDrive(): boolean {
    return this.props.isTestDrive;
  }

  private get organization(): Partial<OrganizationModel> {
    return this.props.organization;
  }

  @computed
  private get reflectCardContentLoaded(): boolean {
    return this.props.organizationContentStore.isLoaded(
      OrganizationContent.My360ReportReflectCardsContent,
      this.organization.id,
      this.exerciseTypeId
    );
  }

  @computed
  private get reflectCardContent(): My360ReportReflectCardsContent {
    if (!this.reflectCardContentLoaded) {
      return null;
    }

    return this.props.organizationContentStore.get(
      `${OrganizationContent.My360ReportReflectCardsContent}|${this.exerciseTypeId}`
    ).content;
  }

  private get reflectWithCoachContent(): string {
    return this.reflectCardContent.reflect_with_coach;
  }

  private get reflectWithOthersContent(): string {
    return this.reflectCardContent.reflect_with_others;
  }

  private get contentLoadingSkeleton(): ReactNode {
    return <Skeleton paragraph={{ rows: 4 }} loading={!this.reflectCardContentLoaded} active />;
  }

  public render(): ReactNode {
    return (
      <div className="reflection-cta-cards">
        <EnhancedCard
          className="reflection-cta-card"
          heading="Reflect with a coach"
          headerIcon={<ThunderboltFilled className="with-coach" />}
        >
          <div className="enhanced-card-body">
            <div className="description">
              {this.reflectCardContentLoaded && (
                <>
                  {this.contentLoadingSkeleton}
                  <Markdown className="content-quote" source={this.reflectWithCoachContent} />
                </>
              )}
            </div>
          </div>
          <CardFooter>
            <>
              {this.personalReport && (
                <ClipboardButton
                  targetText={this.personalReport}
                  copyText="Copy report link"
                  copiedText="Copied to clipboard"
                  disabled={this.isTestDrive}
                />
              )}
              <Button type="primary" disabled={this.isTestDrive} onClick={this.props.onBookCoach}>
                Book session with Valence coach
              </Button>
            </>
          </CardFooter>
        </EnhancedCard>

        <EnhancedCard
          className="reflection-cta-card"
          heading="Reflect with a peer or manager"
          headerIcon={<MessageOutlined />}
        >
          <div className="enhanced-card-body">
            <div className="description">
              {this.reflectCardContentLoaded && (
                <>
                  {this.contentLoadingSkeleton}
                  <Markdown className="content-quote" source={this.reflectWithOthersContent} />
                </>
              )}
            </div>
          </div>
          <CardFooter>
            <>
              {this.personalReport && (
                <ClipboardButton
                  targetText={this.personalReport}
                  copyText="Copy report link"
                  copiedText="Copied to clipboard"
                  disabled={this.isTestDrive}
                />
              )}
              <Button type="primary" onClick={() => window.open(GUIDE_URL, '_blank', 'noopener')}>
                View reflection guide
              </Button>
            </>
          </CardFooter>
        </EnhancedCard>
      </div>
    );
  }
}

export default inject(STORE_ORGANIZATION_CONTENT)(observer(ReflectionCTACards));
