import React from 'react';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, Input, Tooltip } from 'antd';
import cx from 'classnames';

import EnhancedCard from 'app/components/ui/EnhancedCard';
import TagHelper from 'app/helpers/TagHelper';
import DebounceValidatorService from 'app/services/DebounceValidatorService';

import { SubmitButton } from './Partials/SubmitButton/SubmitButton';

interface ResetPasswordFormProps {
  changeNameNeeded: boolean;
  isResettingPassword: boolean;
  userName: string;
  header: string;
  email: string;
  password: string;
  confirmPassword: string;
  setConfirmPassword: (confirmPassword: string) => void;
  handleSubmit: () => void;
  setName: (name: string) => void;
  setPassword: (password: string) => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  changeNameNeeded,
  isResettingPassword,
  userName,
  setName,
  header,
  email,
  confirmPassword,
  setConfirmPassword,
  password,
  setPassword,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const [hasPswdError, setHasPswdError] = React.useState(false);
  const [hasCnfPswdError, setHasCnfPswdError] = React.useState(false);

  const onFieldsChange = () => {
    const pswdError = form.getFieldError('password').length > 0;
    const cnfPswdError = form.getFieldError('password_confirmation').length > 0;

    if (pswdError !== hasPswdError) {
      setHasPswdError(pswdError);
    }

    if (cnfPswdError !== hasCnfPswdError) {
      setHasCnfPswdError(cnfPswdError);
    }
  };

  const onCheck = async () => {
    try {
      await form.validateFields();
      handleSubmit();
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  const resetBtn = (
    <SubmitButton
      id="reset-btn"
      title="Reset password"
      isLoading={isResettingPassword}
      tag={TagHelper.Auth.ResetPassword.SavePasswordButton}
      onClick={onCheck}
      disabled={
        !form.isFieldTouched('password') ||
        !form.isFieldTouched('password_confirmation') ||
        hasPswdError ||
        hasCnfPswdError
      }
    />
  );

  const passwordFieldSuffixIcon = (hasError: boolean, visible: boolean) => {
    if (hasError) {
      return null;
    }

    if (visible) {
      return (
        <Tooltip title="Hide password">
          <EyeOutlined />
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Show password">
        <EyeInvisibleOutlined />
      </Tooltip>
    );
  };

  const checkConfirmPasswordValidator = new DebounceValidatorService(
    'Please confirm your password!',
    'Passwords don’t match.',
    (value) => form.getFieldValue('password') === value
  );

  const checkPasswordValidator = new DebounceValidatorService(
    'Please input your password!',
    'The password must contain a lowercase letter, an uppercase letter, and a number.',
    (value) => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,}/.test(value)
  );

  return (
    <EnhancedCard heading={header} className="reset-pswd-form" footer={resetBtn}>
      <div className="form-body">
        <p>
          To log in to Valence, please set a new password for your email &nbsp;
          <strong className="email-text">{email}</strong>.
        </p>

        <Form size="large" form={form} onFieldsChange={onFieldsChange}>
          {changeNameNeeded && (
            <Form.Item name="name">
              <label>Full Name</label>
              <Input
                placeholder="Full name"
                autoFocus
                value={userName}
                data-testid="fullName"
                onChange={(event) => setName(event.target.value)}
              />
            </Form.Item>
          )}

          <label className={cx({ error: hasPswdError })}>Choose a new password</label>
          <Form.Item
            name="password"
            help={
              hasPswdError
                ? 'The password must contain a lowercase letter, an uppercase letter, a number and a special character.'
                : 'Minimum 10 characters, and must include: lowercase, uppercase number and a special character'
            }
            rules={[
              {
                validator: checkPasswordValidator.validate,
              },
            ]}
            validateTrigger={['onBlur', 'onChange']}
            hasFeedback={hasPswdError}
          >
            <Input.Password
              placeholder="Choose a new password"
              autoFocus
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              iconRender={(visible) => passwordFieldSuffixIcon(hasPswdError, visible)}
            />
          </Form.Item>

          <label className={cx({ error: hasCnfPswdError })}>Confirm new password</label>
          <Form.Item
            name="password_confirmation"
            dependencies={['password']}
            hasFeedback={hasCnfPswdError}
            rules={[
              {
                validator: checkConfirmPasswordValidator.validate,
              },
            ]}
            validateTrigger={['onBlur', 'onChange']}
          >
            <Input.Password
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              iconRender={(visible) => passwordFieldSuffixIcon(hasCnfPswdError, visible)}
              onPressEnter={onCheck}
            />
          </Form.Item>
        </Form>
      </div>
    </EnhancedCard>
  );
};

export default ResetPasswordForm;
