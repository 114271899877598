import { buildURL } from './URLHelpers';

export type URLParams = { [key: string]: string | number | boolean };

/**
 * Base URLBuilder Class
 * Allows us to easily build urls and keep context about them for error handling
 */
export class URLBuilder {
  baseURL: string;
  key: string;

  args: any[];

  constructor(baseURL: string, args: any[], key?: string) {
    this.baseURL = baseURL;
    this.args = args;
    this.key = key;
  }

  /**
   * Gets the URL parameters for the current builder
   * URL params are the last item in the builder arguments so long as that
   * item is an object. Otherwise it'll assume there are no params and return
   * undefined.
   */
  get params() {
    if (this.args && typeof this.args[this.args.length - 1] === 'object') {
      return this.args[this.args.length - 1] as URLParams;
    }
  }

  /**
   * Appends query params to the url, modifies the current object and returns it.
   * @param params
   */
  withParams(params: URLParams) {
    if (this.params) {
      this.args[this.args.length - 1] = {
        ...this.params,
        ...params,
      };

      return this;
    }

    this.args.push(params);
    return this;
  }

  /**
   * Where all URL builders eventually exit, this method generates the URL that
   * actually gets passed to your request library.
   *
   * Note: If the end consumer of this object expects a string, make sure you wait until
   * right before you pass it to the consumer to convert to a string. We loose context as
   * soon as we convert, so all of our internal methods should make use of `URLBuilder` if
   * possible.
   */
  toString() {
    return buildURL(this.baseURL, ...this.args);
  }
}

type BuilderFn = (...args) => URLBuilder;

/**
 * Allows us to take an existing function that returns a URLBuilder and
 * attach a key to that url builder. That key can then be used for error
 * handling
 * @param builderFn
 * @param key
 */
const wrapBuilder = (builderFn: BuilderFn, key?: string) => {
  return (...args) => {
    const b = builderFn(...args);
    b.key = key;
    return b;
  };
};

/**
 * Allows us to take an object that is full of builders, iterate through them
 * and use the `wrapBuilder` function to automatically generate their keys
 * @param object
 * @param keys
 */
export const wrapBuilders = <T>(object: T, keys = []): T => {
  if (typeof object === 'function') {
    return wrapBuilder(object as any, keys.join('.')) as any;
  }

  for (const key in object as any) {
    object[key] = wrapBuilders(object[key], keys.concat(key));
  }

  return object;
};

/**
 * Simple helper method to make the creation of builders easy.
 * Useful to import as a single character function.
 * @param baseURL
 * @param args
 */
export const makeBuilder = (baseURL: string, ...params) => {
  return new URLBuilder(baseURL, params);
};
