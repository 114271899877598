import { initializeFullStory } from 'app/helpers/initializeFullStory';
import { initializeIntercom } from 'app/helpers/initializeIntercom';
import { initializePosthog } from 'app/helpers/initializePosthog';
import { initializeWootric } from 'app/helpers/initializeWootric';

export class PerformanceScriptsService {
  public static run = () => {
    initializePosthog(true);
    initializeFullStory(true);
    initializeIntercom();
    initializeWootric(true);
  };
}
