import React from 'react';

import { notification } from 'antd';
import { findIndex, keyBy, reduce } from 'lodash';
import { action, computed, observable, ObservableMap, runInAction, when } from 'mobx';
import { inject, observer } from 'mobx-react';

import './TeamAdminControls.scss';

import PulseTemplateList from 'app/components/features/OrgAdminControls/PulseTemplateList';
import {
  STORE_ADMIN_PULSE_TEMPLATES,
  STORE_EXERCISE_TYPE,
  STORE_MEMBER,
  STORE_ORGANIZATION,
  STORE_TEAM,
} from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import { ExerciseTypeModel, OrganizationModel, PulseTemplateModel, TeamModel } from 'app/models';
import {
  AdminPulseTemplateStore,
  ExerciseTypeStore,
  MemberStore,
  MemberStoreIncludes,
  OrganizationStore,
  TeamStore,
} from 'app/stores';

import ExerciseTypesEdit from './ExerciseTypesEdit';
import QuickEdit from './QuickEdit';

export interface TeamAdminControlsProps {
  teamId: number;
  teamStore?: TeamStore;
  memberStore?: MemberStore;
  organizationStore?: OrganizationStore;
  exerciseTypeStore?: ExerciseTypeStore;
  adminPulseTemplateStore?: AdminPulseTemplateStore;
}

export class TeamAdminControls extends React.Component<TeamAdminControlsProps> {
  @observable show = false;
  @observable isMergingToTeam = false;
  @observable isPromotingToMembers = false;
  @observable isDeleting = false;
  @observable isSavingExerciseTypes = false;
  @action setShow = (show) => (this.show = show);

  @observable isSavingAdminChanges = false;
  @action setIsSavingAdminChanges = (saving) => (this.isSavingAdminChanges = saving);

  @observable selectedPulseTemplates = new ObservableMap();

  constructor(props: TeamAdminControlsProps) {
    super(props);

    when(
      () => !this.currentMember,
      () =>
        this.props.memberStore.loadCurrentMember(true, [MemberStoreIncludes.PERMISSION_ATTRS], true)
    );
  }

  init() {
    when(
      () => !this.props.exerciseTypeStore.exerciseType,
      () => this.props.exerciseTypeStore.loadExerciseTypes()
    );

    when(
      () => !this.props.adminPulseTemplateStore.allTemplates.items.length,
      () => this.props.adminPulseTemplateStore.loadAllTemplates()
    );

    when(
      () =>
        !this.props.adminPulseTemplateStore.isLoadingTemplates &&
        !this.props.teamStore.team.loading,
      () => {
        // set team pulse templates to be selected
        this.teamPulseTemplates.forEach((template) => {
          this.selectedPulseTemplates.set(template.id, true);
        });

        // Then append the default templates that aren't disabled
        this.defaultPulseTemplates
          .filter(({ id }) => !this.teamPulseTemplatesDisabled?.[id])
          .forEach(({ id }) => {
            this.selectedPulseTemplates.set(id, true);
          });
      }
    );

    when(
      () => this.currentMember && this.currentMember.canAdmin,
      () => this.props.organizationStore.loadAllOrganizations()
    );
  }

  @computed
  get team(): TeamModel {
    return this.props.teamStore.team.item;
  }

  @computed
  get otherTeams() {
    return this.teamOrg?.teams.items.filter((team) => team.id !== this.team.id) || [];
  }

  get currentMember() {
    return this.props.memberStore.currentMember.item;
  }

  get organizations() {
    return this.props.organizationStore.organizationsList.items;
  }

  get allExerciseTypes(): ExerciseTypeModel[] {
    return this.props.exerciseTypeStore.exerciseTypes.items;
  }

  @computed
  get teamOrg(): OrganizationModel {
    return this.team?.organization.item;
  }

  @computed
  get teamOrgExerciseTypes(): ExerciseTypeModel[] {
    return this.teamOrg?.exercise_types ?? [];
  }

  @computed
  get defaultPulseTemplates(): PulseTemplateModel[] {
    return this.allPulseTemplates.filter((template) => {
      return template.type === 'template' && template.is_default;
    });
  }

  get teamPulseTemplatesDisabled(): Record<number, PulseTemplateModel> {
    return keyBy(this.team.pulse_templates_disabled.items, 'id');
  }

  @computed get selectedExerciseTypes() {
    return reduce(
      this.team.exercise_types || [],
      (acc, exerciseType) => {
        acc[exerciseType.id] = true;
        return acc;
      },
      {}
    );
  }

  @computed
  get isLoading(): boolean {
    return (
      this.props.teamStore.team.loading ||
      this.props.teamStore.teams.loading ||
      this.props.memberStore.currentMember.loading ||
      this.props.organizationStore.organizationsList.loading ||
      this.props.exerciseTypeStore.isLoadingExerciseTypes
    );
  }

  @computed
  get isSavingPulseTemplates(): boolean {
    return this.props.teamStore.team.loading;
  }

  get isUpdating() {
    return false;
  }

  @computed
  get teamPulseTemplates(): PulseTemplateModel[] {
    return this.team.pulse_templates.items;
  }

  get allPulseTemplates(): PulseTemplateModel[] {
    return this.props.adminPulseTemplateStore.allTemplates.items;
  }

  handleToggle = () => {
    this.setShow(!this.show);
    this.init();
  };

  handleTeamRename = async (name) => {
    await this.props.teamStore.adminUpdateTeam(this.team.id, { name });
    window.location.reload();
  };

  handleChangeManager = async (manager_id) => {
    await this.props.teamStore.adminUpdateTeam(this.team.id, { manager_id });
    window.location.reload();
  };

  handleLinkToOrg = async (organization_id) => {
    await this.props.teamStore.adminUpdateTeam(this.team.id, { organization_id });
    window.location.reload();
  };

  @action
  handleMergeToTeam = async (otherTeamId) => {
    this.isMergingToTeam = true;
    await this.props.teamStore
      .mergeToTeam(this.team.id, otherTeamId)
      .then(() => {
        runInAction(() => (this.isMergingToTeam = false));
      })
      .catch(() => {
        runInAction(() => (this.isMergingToTeam = false));
      });
    window.location.href = ServerRouteHelper.dashboard.teamAlign(otherTeamId);
  };

  @action
  handlePromoteToMembers = async () => {
    this.isPromotingToMembers = true;
    await this.props.teamStore
      .promoteToMembers(this.team.id)
      .then(() => {
        runInAction(() => (this.isPromotingToMembers = false));
      })
      .catch(() => {
        runInAction(() => (this.isPromotingToMembers = false));
      });
    window.location.reload();
  };

  @action
  handleDelete = async () => {
    this.isDeleting = true;
    await this.props.teamStore
      .deleteTeam(this.team)
      .then(() => {
        runInAction(() => (this.isDeleting = false));
      })
      .catch(() => {
        runInAction(() => (this.isDeleting = false));
      });
    window.location.href = ServerRouteHelper.dashboard.home();
  };

  handleSelectedExerciseType = (exerciseType) => {
    const index = findIndex(this.team.exercise_types, { id: exerciseType.id });
    if (index === -1) {
      this.team.exercise_types.push(exerciseType);
    } else {
      this.team.exercise_types.splice(index, 1);
    }
  };

  @action
  handleSaveSelectedExerciseTypes = async () => {
    this.isSavingExerciseTypes = true;
    try {
      await this.props.teamStore.saveExerciseTypes(this.team.id, this.team.exercise_types);

      notification.success({
        message: 'Saved!',
        description: 'Exercise types successfully saved.',
      });
    } catch (e) {
      notification.error({
        message: 'Oops!',
        description: 'Something went wrong!',
      });
    }
    runInAction(() => (this.isSavingExerciseTypes = false));
  };

  handleTopTeam = () => {
    const { is_top_team } = this.team;
    this.props.teamStore.updateTeam(this.team.id, { is_top_team });
  };

  handleCanUseDiscussionSpace = async () => {
    const { can_use_discussion_space } = this.team;

    try {
      this.setIsSavingAdminChanges(true);
      const response = await this.props.teamStore.adminUpdateTeam(this.team.id, {
        can_use_discussion_space,
      });

      if (response !== undefined) {
        notification.success({
          message: 'Saved!',
          description: 'Team updated successfully',
        });
      }
    } finally {
      this.setIsSavingAdminChanges(false);
    }
  };

  handleSelectedPulseTemplates = (template) => {
    if (this.selectedPulseTemplates.has(template.id)) {
      this.selectedPulseTemplates.delete(template.id);
      return;
    }

    this.selectedPulseTemplates.set(template.id, true);
  };

  handleSaveSelectedPulseTemplates = async (): Promise<void> => {
    const selectedPulseTemplates = this.allPulseTemplates.filter((template) => {
      return this.selectedPulseTemplates.has(template.id);
    });

    await this.props.teamStore.savePulseTemplates(this.team.id, selectedPulseTemplates);
  };

  render() {
    return (
      <div className="team-admin-controls">
        <div className="admin-controls-toggle">
          <button className="btn acc-btn-caps btn-xs" onClick={this.handleToggle}>
            Admin controls
          </button>
        </div>

        {this.isLoading && <p>Loading controls...</p>}

        {!this.isLoading && this.show && (
          <div className="admin-controls" data-testid="admin-controls">
            <QuickEdit
              team={this.team}
              otherTeams={this.otherTeams}
              organizations={this.organizations}
              isUpdating={this.isUpdating}
              isMergingToTeam={this.isMergingToTeam}
              isPromotingToMembers={this.isPromotingToMembers}
              isDeleting={this.isDeleting}
              onTeamRename={this.handleTeamRename}
              onLinkToOrg={this.handleLinkToOrg}
              onMergeToTeam={this.handleMergeToTeam}
              onPromoteToMembers={this.handlePromoteToMembers}
              onChangeManager={this.handleChangeManager}
              onTopTeam={this.handleTopTeam}
              onDelete={this.handleDelete}
              onCanUseDiscussionSpace={this.handleCanUseDiscussionSpace}
              isSavingAdminChanges={this.isSavingAdminChanges}
            />

            <ExerciseTypesEdit
              org={this.teamOrg}
              inheritedExerciseTypes={this.teamOrgExerciseTypes}
              allExerciseTypes={this.allExerciseTypes}
              selectedExerciseTypes={this.selectedExerciseTypes}
              isSavingExerciseTypes={this.isSavingExerciseTypes}
              onSelectedExerciseType={this.handleSelectedExerciseType}
              onSaveSelectedExerciseTypes={this.handleSaveSelectedExerciseTypes}
            />

            <div className="acc-card admin-controls">
              <PulseTemplateList
                allTemplates={this.allPulseTemplates}
                selectedTemplates={this.selectedPulseTemplates.toJSON()}
                onSave={this.handleSaveSelectedPulseTemplates}
                onSelected={this.handleSelectedPulseTemplates}
                isSaving={this.isSavingPulseTemplates}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default inject(
  STORE_TEAM,
  STORE_MEMBER,
  STORE_ORGANIZATION,
  STORE_EXERCISE_TYPE,
  STORE_ADMIN_PULSE_TEMPLATES
)(observer(TeamAdminControls));
