import React, { ReactNode } from 'react';

import { Menu, Popover } from 'antd';
import cx from 'classnames';
import { action, computed, observable, reaction, when } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './MemberDropdown.module.scss';

import { AppLayoutUiStore, MenuItemUiStore } from 'app/components/features/AppLayout/stores';
import { STORE_APP_LAYOUT_UI, STORE_MEMBER, STORE_MENU, STORE_ORGANIZATION } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import { MemberModel, MenuModel, OrganizationModel } from 'app/models';
import { MemberStore, MenuStore, OrganizationStore } from 'app/stores';

const USER_PROFILE_MENU = 'user_profile';

interface MemberDropdownProps {
  handleGoToOrg?: (org: OrganizationModel) => void;

  menuStore: MenuStore;
  memberStore: MemberStore;
  appLayoutUiStore: AppLayoutUiStore;
  organizationStore: OrganizationStore;
}

class MemberDropdown extends React.Component<MemberDropdownProps> {
  @observable popoverOpened = false;
  @action setPopoverOpened = (popoverOpened: boolean) => (this.popoverOpened = popoverOpened);

  @observable lastLoadedOrgId: number;
  @action setLastLoadedOrgId = (orgId: number): void => {
    this.lastLoadedOrgId = orgId;
  };

  protected menuItemUiStore: MenuItemUiStore;

  constructor(props: MemberDropdownProps) {
    super(props);

    this.init();
  }

  init() {
    this.menuItemUiStore = new MenuItemUiStore(
      styles,
      this.props.memberStore,
      this.props.organizationStore,
      this.handleGoToOrg
    );

    // React to path changes
    reaction(
      () => this.props.appLayoutUiStore.path,
      () => this.setPopoverOpened(false)
    );

    // Ensure current member is loaded if nobody else loads it
    when(
      () => !this.member,
      () => this.props.memberStore.loadCurrentMember(true, ['organizations'])
    );

    reaction(
      () => this.displayOrg,
      (org) => {
        if (!org || org.id === this.lastLoadedOrgId) {
          return;
        }

        this.props.menuStore.loadMenu(USER_PROFILE_MENU, org.id);
        this.setLastLoadedOrgId(org.id);
      }
    );
  }

  @computed
  get isLoading(): boolean {
    const isLoadingMenu = this.props.menuStore?.isLoadingMenu || !this.menu;
    const isLoadingMember = this.props.memberStore?.currentMember?.loading || !this.member;

    return !this.displayOrg || isLoadingMember || isLoadingMenu;
  }

  @computed
  get member(): MemberModel {
    return this.props.memberStore.currentMember.item;
  }

  get displayOrg(): OrganizationModel {
    return this.props.appLayoutUiStore.activeOrganization;
  }

  @computed
  get menu(): MenuModel {
    return this.props.menuStore.getMenu(USER_PROFILE_MENU);
  }

  handleGoToOrg = (org: OrganizationModel) => {
    if (this.props.handleGoToOrg) {
      return this.props.handleGoToOrg(org);
    }

    const url = ServerRouteHelper.dashboard.organization.summary(org.id);
    document.location.href = url;
  };

  @computed
  get orgInitial(): string {
    const name = this.displayOrg?.name;

    if (name) {
      // Remove leading "The " if found
      const orgName = name.replace(/^The /, '');
      const firstLetterMatches = orgName.match(/\b(\w)/g);

      if (firstLetterMatches) {
        const initial = firstLetterMatches.join('');
        return initial.substring(0, 2);
      }

      // fallback for foreign languages / multi-byte characters
      return name.substring(0, 1) + '…';
    }

    return '';
  }

  @computed
  get orgLogo(): ReactNode {
    const url = this.displayOrg?.logo_urls?.menu;

    return url ? <img src={url} /> : <>{this.orgInitial}</>;
  }

  @computed
  get userMenu(): ReactNode {
    return (
      <Menu data-testid="member-dropdown-menu" className={styles.memberDropdownMenu}>
        {this.menu.menu_items.map((item, index) => this.menuItemUiStore.makeItem(item, index))}

        <Menu.Item key="Terms & About">
          <div className={styles.menuFooter}>
            <a
              className={styles.menuItem}
              href={ServerRouteHelper.agreements.terms()}
              target="_blank"
            >
              Terms of Use
            </a>
            <span className={styles.menuItemSeparator} />
            <a className={styles.menuItem} href="https://valence.co" target="_blank">
              About Valence
            </a>
          </div>
        </Menu.Item>
      </Menu>
    );
  }

  render(): ReactNode {
    if (this.isLoading) {
      return null;
    }

    return (
      <Popover
        overlayClassName={styles.popover}
        placement="right"
        content={this.userMenu}
        trigger="click"
        open={this.popoverOpened}
        onOpenChange={this.setPopoverOpened}
      >
        <a
          href="#"
          data-testid="member-dropdown-toggle"
          className={`${styles.orgAnchor} nav-link`}
          role="button"
        >
          <span
            className={cx(styles.orgAvatar, { [styles.hasLogo]: this.displayOrg?.logo_urls?.menu })}
          >
            {this.orgLogo}
          </span>
          <div className={styles.displayContent}>
            <p className={cx(styles.orgName, 'org-name')}>{this.displayOrg?.name}</p>
            <p className={styles.memberName}>{this.member.name}</p>
          </div>
        </a>
      </Popover>
    );
  }
}

export default inject(
  STORE_APP_LAYOUT_UI,
  STORE_ORGANIZATION,
  STORE_MENU,
  STORE_MEMBER
)(observer(MemberDropdown));
