export const DiscussionGuidePage = {
  START: 'start',
  SHARE: 'share',
  PRIORITIZE: 'prioritize',
  EXPLORE: 'explore',
  COMMIT: 'commit',
};

export const DiscussionGuideState = {
  PREVIEW: 'preview',
  STARTED: 'started',
  COMPLETED: 'completed',
};
