import { mean, meanBy } from 'lodash';
import { computed, observable } from 'mobx';

import { BLUE, CLASS_COLOR_MAP } from 'app/constants';

import AlignGuidanceModel from './AlignGuidanceModel';
import BenchmarkScoreModel from './BenchmarkScoreModel';
import type { ExerciseAnswerModel } from './ExerciseAnswerModel';
import { EXERCISE_ANSWER_LABEL_YOU } from './ExerciseAnswerModel';
import { ExerciseReportModel } from './ExerciseReportModel';
import { ScatterPlotPoint } from './ScatterPlotModel';
import ShiftModel from './ShiftModel';

export interface AlignStatementTemplate {
  id: number;
  explanation?: string;
  habit_suggestions?: string[];
}

export interface ExerciseStatementProps {
  id: number;
  align_statement_template_id: number;
  exercise_type_id: number;
  text: string;
  explanation?: string;
  guidance?: AlignGuidanceModel | string;
  score?: number;
  color?: string;
  percentiles?: { [percentile: string]: number };
  template?: AlignStatementTemplate;
}

export class ExerciseStatementModel extends ShiftModel<ExerciseStatementProps> {
  @observable id: number;
  @observable align_statement_template_id: number;
  @observable exercise_type_id: number;
  @observable text: string;
  @observable guidance?: AlignGuidanceModel;
  @observable color?: string;
  @observable template?: AlignStatementTemplate;
  @observable _explanation?: string;

  percentiles?: { [percentile: string]: number };
  report?: ExerciseReportModel;

  constructor(props: ExerciseStatementProps, report?: ExerciseReportModel) {
    const { guidance, explanation, ...rest } = props;
    super(rest);

    // guidance can be instantiated in two ways, either via align or by associating answers.
    // so here we check if its been previously instantiated and instead just return directly.
    const isGuidanceModel = guidance instanceof AlignGuidanceModel;
    const guidanceModel = isGuidanceModel
      ? guidance
      : AlignGuidanceModel.fromJson({ html: guidance });

    this.guidance = guidanceModel as AlignGuidanceModel;

    this.report = report;
    this._explanation = explanation;
  }

  asJSON() {
    const {
      id,
      align_statement_template_id,
      exercise_type_id,
      text,
      guidance,
      color,
      template,
      explanation,
    } = this;

    return {
      id,
      align_statement_template_id,
      exercise_type_id,
      text,
      guidance,
      color,
      template,
      explanation,
    };
  }

  @computed
  get explanation(): string {
    return this._explanation ?? this.template?.explanation;
  }

  @computed
  get answers(): ExerciseAnswerModel[] {
    return this.report.answersByStatementID[this.id] ?? [];
  }

  @computed
  get otherAnswers(): ExerciseAnswerModel[] {
    return this.report.otherAnswersByStatementID[this.id] ?? [];
  }

  @computed
  get previousReportAnswers(): ExerciseAnswerModel[] {
    return this.report.previousAnswersByStatementID[this.id];
  }

  @computed
  get personalAnswer(): ExerciseAnswerModel {
    return (
      this.report.personalAnswersByStatementID && this.report.personalAnswersByStatementID[this.id]
    );
  }

  @computed
  get score(): number {
    return this.answers ? mean(this.answers.map((answer) => answer.score.exerciseScore)) : 0;
  }

  @computed
  get previousScore(): number {
    if (this.previousReportAnswers?.length === 0) {
      return 0;
    }

    return mean(this.previousReportAnswers?.map((answer) => answer.score.exerciseScore));
  }

  @computed
  get mean(): number {
    if (this.otherAnswers.length === 0) {
      return 0;
    }
    return meanBy(this.otherAnswers, 'coordinate_x');
  }

  @computed
  get scoreScaled(): number {
    return this.report.scaleScore(this.score);
  }

  @computed
  get previousScoreScaled(): number {
    return this.report.scaleScore(this.previousScore);
  }

  @computed
  get personalScore(): number | undefined {
    if (!this.personalAnswer) {
      return;
    }

    return this.personalAnswer.score.exerciseScore;
  }

  @computed
  get personalScoreY(): number | undefined {
    if (!this.personalAnswer) {
      return;
    }

    return this.personalAnswer.score.y;
  }

  @computed
  get personalScoreScaled(): number | undefined {
    if (!this.personalScore) {
      return;
    }

    return this.report.scaleScore(this.personalScore);
  }

  @computed
  get othersScore(): number {
    if (this.otherAnswers.length === 0) {
      return 0;
    }
    return mean(this.otherAnswers.map((answer) => answer.score.exerciseScore));
  }

  @computed
  get othersScoreScaled(): number | undefined {
    if (!this.othersScore) {
      return;
    }

    return this.report.scaleScore(this.othersScore);
  }

  @computed
  get classification() {
    return this.report.classifyScore(this.score);
  }

  @computed
  get colorClassification() {
    return CLASS_COLOR_MAP[this.classification];
  }

  @computed
  get personalClassification() {
    if (!this.personalScore) {
      return;
    }

    return this.report.classifyScore(this.personalScore);
  }

  @computed
  get othersScoreClassification() {
    return this.report.classifyScore(this.othersScore);
  }

  @computed
  get personalColorClassification() {
    return CLASS_COLOR_MAP[this.personalClassification];
  }

  @computed
  get scaledPercentiles(): { value: number; label: string }[] {
    return Object.keys(this.percentiles || {}).map((key) => ({
      label: key,
      value: this.report.scaleScore(this.percentiles[key]),
    }));
  }

  @computed
  get benchmarkScores(): BenchmarkScoreModel[] {
    return this.report.benchmarks.reduce((acc, benchmark) => {
      acc.push(benchmark.scoresByStatementTemplateId[this.align_statement_template_id]);
      return acc;
    }, []);
  }

  @computed
  get benchmarkScoresById(): { [id: number]: BenchmarkScoreModel } {
    const benchmarks = {};

    this.report.benchmarks.forEach((benchmark) => {
      benchmarks[benchmark.id] =
        benchmark.scoresByStatementTemplateId[this.align_statement_template_id];
    });

    return benchmarks;
  }

  @computed
  get points(): ScatterPlotPoint[] {
    return this.answers.map((answer) => ({
      x: answer.coordinate_x,
      y: answer.coordinate_y,
      color: answer.score.colorClassification,
    })) as ScatterPlotPoint[];
  }

  allPointsWithPersonalResults(
    includePersonalResults: boolean,
    otherColor?: string,
    personalColor?: string
  ): ScatterPlotPoint[] {
    const points = [];

    let answers = this.answers;

    if (this.personalAnswer && includePersonalResults) {
      points.push({
        x: this.personalAnswer.coordinate_x,
        y: this.personalAnswer.coordinate_y,
        isPersonalAnswer: true,
        color: this.personalAnswer.score.colorClassification,
        label: EXERCISE_ANSWER_LABEL_YOU,
        data: {
          member_id: this.personalAnswer.member_id,
        },
      });

      answers = this.otherAnswers;
    }

    const othersPoints = answers.map((answer) => ({
      x: answer.coordinate_x,
      y: answer.coordinate_y,
      color: answer.score.colorClassification,
      label: answer.member_name,
      data: {
        member_id: answer.member_id,
      },
    }));

    return points.concat(othersPoints);
  }

  @computed
  get allPoints(): ScatterPlotPoint[] {
    return this.allPointsWithPersonalResults(true);
  }

  allPointsWithCustomColors(otherColor, personalColor): ScatterPlotPoint[] {
    return this.allPointsWithPersonalResults(true, otherColor, personalColor);
  }

  @computed
  get personalPoint(): ScatterPlotPoint[] {
    const points = [];
    if (this.personalAnswer) {
      points.push({
        x: this.personalAnswer.coordinate_x,
        y: this.personalAnswer.coordinate_y,
        color: BLUE,
        data: {
          member_id: this.personalAnswer.member_id,
        },
      });
    }
    return points;
  }

  @computed
  get allPointsForBlindspotsWidget(): ScatterPlotPoint[] {
    const points = this.otherAnswers.map((answer) => ({
      x: answer.coordinate_x,
      y: answer.coordinate_y,
      color: answer.score.colorClassification,
      isPersonalAnswer: false,
      label: answer.member_name,
      data: {
        member_id: answer.member_id,
        member_role: answer.member_role,
      },
    }));

    if (this.personalAnswer) {
      points.push({
        x: this.personalAnswer.coordinate_x,
        y: this.personalAnswer.coordinate_y,
        color: this.personalAnswer.score.colorClassification,
        label: EXERCISE_ANSWER_LABEL_YOU,
        isPersonalAnswer: true,
        data: {
          member_id: this.personalAnswer.member_id,
          member_role: this.personalAnswer.member_role,
        },
      });
    }

    return points;
  }

  @computed
  get pointsWithPersonalAnswerHighlighted(): ScatterPlotPoint[] {
    return this.answers.map((answer) => {
      const reportMemberID = this.report.reportMember && this.report.reportMember.id;

      const own = answer.member_id && answer.member_id == reportMemberID;
      const member = this.report.participantsByID && this.report.participantsByID[answer.member_id];

      const memberName = member && member.name;

      return {
        x: answer.coordinate_x,
        y: answer.coordinate_y,
        label: own ? EXERCISE_ANSWER_LABEL_YOU : memberName,
        color: answer.score.colorClassification,
        isPersonalAnswer: own,
      };
    }) as ScatterPlotPoint[];
  }

  @computed
  get comments() {
    for (const key of Object.keys(this.report.comments)) {
      if (this.report.comments[key]?.statement_id === this.id) {
        return this.report.comments[key].comments;
      }
    }
  }

  @computed
  get labels() {
    return this.report.exercise_type.labels;
  }

  @computed
  get isMorePositive() {
    return (
      this.personalAnswer &&
      this.personalAnswer.coordinate_x - this.mean > 50 &&
      this.personalAnswer.coordinate_x * this.mean < 0
    );
  }

  @computed
  get isMoreNegative() {
    return (
      this.personalAnswer &&
      this.personalAnswer.coordinate_x - this.mean < -50 &&
      this.personalAnswer.coordinate_x * this.mean < 0
    );
  }

  @computed
  get isBothPositive() {
    return this.personalAnswer && this.personalAnswer.coordinate_x > 15 && this.mean > 15;
  }

  @computed
  get isBothNegative() {
    return this.personalAnswer && this.personalAnswer.coordinate_x < -15 && this.mean < -15;
  }
}

export default ExerciseStatementModel;
