import { action, observable } from 'mobx';

import { ClientDataHelper } from 'app/helpers';
import { ScoreModel } from 'app/models/ScoreModel';

import ShiftStore from './ShiftStore';

export enum ScoreStoreKeys {
  GroupScoresFromTeamGroupStats = 'GROUP_SCORES_TEAM_GROUP_STATS',
  BenchmarkScoresFromTeamGroupStats = 'BENCHMARK_SCORES_TEAM_GROUP_STATS',
}
export class ScoreStore extends ShiftStore {
  @observable public GroupScores: {
    [GroupID: string]: {
      [ExerciseTypeID: string]: {
        [ScoreID: number]: ScoreModel;
      };
    };
  };

  @observable public BenchmarkScores: {
    [GroupID: string]: {
      [ExerciseTypeID: string]: ScoreModel[];
    };
  };

  @action
  async loadGroupScoresFromTeamGroupStats() {
    this.setPending(ScoreStoreKeys.GroupScoresFromTeamGroupStats);

    const result = await ClientDataHelper.get('teamGroupStats');

    this.GroupScores = {};

    for (const groupID in result) {
      this.GroupScores[groupID] = {};

      const group = result[groupID];

      for (const exerciseTypeID in group) {
        const groupScores = {};

        for (const scoreID in group[exerciseTypeID].group_scores) {
          const score = group[exerciseTypeID].group_scores[scoreID];
          groupScores[scoreID] = new ScoreModel(score);
        }

        this.GroupScores[groupID][exerciseTypeID] = groupScores;
      }
    }

    this.setLoaded(ScoreStoreKeys.GroupScoresFromTeamGroupStats);
  }
}

export default ScoreStore;
