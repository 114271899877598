import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import AdminOrgContentStore from 'app/stores/AdminOrgContentStore';

export class AdminOrgContentModel extends Model {
  public static _store: AdminOrgContentStore;

  @observable public id: number;
  @observable public name: string;
  @observable public overrides: string[];

  static fromJson(json: ModelJson): AdminOrgContentModel {
    return this._fromJson(json) as AdminOrgContentModel;
  }

  static getOrNew(id): AdminOrgContentModel {
    return this._getOrNew(id) as AdminOrgContentModel;
  }

  static get(id): AdminOrgContentModel {
    return this._get(id) as AdminOrgContentModel;
  }
}

export default AdminOrgContentModel;
