import React, { ReactNode } from 'react';

import { Divider, Form, Input } from 'antd';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import AntModal from 'app/components/ui/AntModal';
import { STORE_ADMIN_PULSE_TEMPLATES } from 'app/constants';
import { PulseStatementModel, PulseTemplateModel } from 'app/models';
import AdminPulseTemplateStore from 'app/stores/AdminPulseTemplateStore';

import InputStatementForm from './InputStatementForm';
import StatementForm from './StatementForm';

export interface CreateNewTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  parentTemplate: PulseTemplateModel;
  adminPulseTemplateStore?: AdminPulseTemplateStore;
}

export class CreateNewTemplateModal extends React.Component<CreateNewTemplateModalProps> {
  @observable private categoryTitle: string;
  @action setCategoryTitle = (categoryTitle) => (this.categoryTitle = categoryTitle);

  @observable statements: PulseStatementModel[] = [];
  @action setStatements = (statements) => (this.statements = statements);

  addStatement = (text: string, type: string) => {
    const statement = {
      text: text,
      statement_type: type,
      order: this.statements.length,
    };
    const statements = this.statements;

    statements.push(statement as any);
    this.setStatements(statements);
  };

  addNewCategory = () => {
    const template = this.props.parentTemplate;
    const category = {
      name: this.categoryTitle,
      scope: template.scope,
      is_default: template.is_default,
      pulse_template_parent_id: template.id,
      statements: this.statements,
    };

    this.props.adminPulseTemplateStore.createCategory(category);
    this.setCategoryTitle(null);
    this.setStatements([]);
    this.props.onClose();
  };

  deleteStatement = (index: number) => {
    const statements = this.statements;

    statements.splice(index, 1);
    this.setStatements(statements);
  };

  updateSelectedType = (type: string, index: number) => {
    const statements = this.statements;
    const statement = statements[index];

    statement.statement_type = type;
    statements.splice(index, 1, statement);
    this.setStatements(statements);
  };

  updateStatementText = (text: string, index: number) => {
    const statements = this.statements;
    const statement = statements[index];

    statement.text = text;
    statements.splice(index, 1, statement);
    this.setStatements(statements);
  };

  public render(): ReactNode {
    return (
      <AntModal
        isOpen={this.props.isOpen}
        onToggle={this.props.onClose}
        title="Add Category"
        primaryAction={{
          label: 'Create Category',
          disabled: !this.categoryTitle || this.categoryTitle?.length === 0,
          onClick: this.addNewCategory,
        }}
        width={800}
      >
        <Form.Item label="Category Title" className="mt-4">
          <Input
            value={this.categoryTitle}
            required
            onChange={(e) => this.setCategoryTitle(e.target.value)}
          />
        </Form.Item>
        <Divider />

        <div className="d-flex w-100">
          <InputStatementForm addStatement={this.addStatement} />
        </div>

        {this.statements.length > 0 && <Divider />}

        {this.statements?.map((statement: PulseStatementModel, index: number) => (
          <StatementForm
            statement={statement}
            index={index}
            key={index}
            updateStatementText={this.updateStatementText}
            deleteStatement={this.deleteStatement}
            updateSelectedType={this.updateSelectedType}
          />
        ))}
      </AntModal>
    );
  }
}

export default inject(STORE_ADMIN_PULSE_TEMPLATES)(observer(CreateNewTemplateModal));
