import React, { Component, ReactElement } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './TopPageMenu.module.scss';

import { AppLayoutUiStore } from 'app/components/features/AppLayout/stores';
import { STORE_APP_LAYOUT_UI } from 'app/constants';

export interface TopPageMenuProps {
  appLayoutUiStore?: AppLayoutUiStore;
}

/**
 * Page contextually specific top menu with page title and hamburger menu toggle.
 */
export class TopPageMenu extends Component<TopPageMenuProps> {
  get uiStore(): AppLayoutUiStore {
    return this.props.appLayoutUiStore;
  }

  @computed
  get menuToggle(): ReactElement {
    return (
      <div
        className={cx('top-menu-toggle', styles.contentItem, styles.sidebarToggle)}
        onClick={this.uiStore.toggleSidebar}
      >
        <MenuOutlined />
      </div>
    );
  }

  @computed
  get title(): ReactElement {
    if (!this.uiStore.title) {
      return null;
    }

    return <h1 className={(styles.contentItem, styles.title)}>{this.uiStore.title}</h1>;
  }

  @computed
  get primaryCta(): ReactElement {
    if (!this.uiStore.primaryCta) {
      return null;
    }

    return <div className={(styles.contentItem, styles.primaryCta)}>{this.uiStore.primaryCta}</div>;
  }

  @computed
  get secondaryCta(): ReactElement {
    if (!this.uiStore.secondaryCta) {
      return null;
    }

    return (
      <div className={(styles.contentItem, styles.secondaryCta)}>{this.uiStore.secondaryCta}</div>
    );
  }

  @computed
  get primaryElement(): ReactElement {
    if (!this.uiStore.primaryElement) {
      return null;
    }

    return (
      <div className={(styles.contentItem, styles.primaryElement)}>
        {this.uiStore.primaryElement}
      </div>
    );
  }

  @computed
  get secondaryElement(): ReactElement {
    if (!this.uiStore.secondaryElement) {
      return null;
    }

    return (
      <div className={(styles.contentItem, styles.secondaryElement)}>
        {this.uiStore.secondaryElement}
      </div>
    );
  }

  render(): ReactElement {
    return (
      <nav className={styles.topPageMenu}>
        <div className={styles.content}>
          <div className={styles.leftContent}>
            {this.menuToggle}
            {this.title}
            {this.primaryElement}
            {this.secondaryElement}
          </div>
          <div className={styles.rightContent}>
            {this.secondaryCta}
            {this.primaryCta}
          </div>
        </div>
      </nav>
    );
  }
}

export default inject(STORE_APP_LAYOUT_UI)(observer(TopPageMenu));
