export default class ShiftModel<T> {
  constructor(props?: T) {
    this.update(props);
  }

  update(props?: any) {
    this.updateRaw(props);
  }

  updateRaw(props?: any) {
    if (props) {
      for (const k in props) {
        this[k] = props[k];
      }
    }
  }

  asJSON() {
    console.warn(`asJSON() called on ${this} but it is not implemented!`);
    return {};
  }
}
