import { action } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { EntityLabelModel } from 'app/models/EntityLabelModel';
import Store from 'app/stores/Store';

export class EntityLabelStore extends Store<EntityLabelModel> {
  constructor() {
    super();
    EntityLabelModel._store = this;
  }

  @action
  public async labelMember(data): Promise<void> {
    const url = ServerRouteHelper.api.members.entityLabels();

    await this.apiService.post(url, data);
  }
}

export default EntityLabelStore;
