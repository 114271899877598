import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { EmailActivityStore } from 'app/stores';

export enum MessageTags {
  PerspectiveInvite = 'perspective_invite',
  AlignInvite = 'align_invite',
}

export interface CreatedDate {
  date: string;
  days: number;
}

export class EmailActivityModel extends Model {
  static _store: EmailActivityStore;

  action: string;
  member_id: number;
  message_tag: MessageTags;
  @observable created: CreatedDate;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as EmailActivityModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as EmailActivityModel;
  }

  static get(id) {
    return this._get(id) as EmailActivityModel;
  }
}

export default EmailActivityModel;
