import Axios, { AxiosError } from 'axios';
import { action, observable, ObservableMap } from 'mobx';
import URI from 'urijs';

import { ClientDataHelper } from 'app/helpers';
import { URLBuilder } from 'app/helpers/URLBuilder';
import ThirdPartyService from 'app/services/ThirdPartyService';

export enum ShiftStoreState {
  NotStarted = 'NOT_STARTED',
  Pending = 'PENDING',
  Loaded = 'LOADED',
  Error = 'ERROR',
}

export interface ShiftStoreError {
  message: string;
  code?: number;
  context?: Record<string, unknown>;
}

export const ShiftStoreKeyGlobal = 'SHIFT_STORE_GLOBAL';
export type ShiftStoreKey = string;

export enum ShiftStoreRequestOptionMethods {
  GET = 1,
  POST,
  PUT,
  PATCH,
  DELETE,
}

const requestFns = {
  [ShiftStoreRequestOptionMethods.GET]: Axios.get,
  [ShiftStoreRequestOptionMethods.POST]: Axios.post,
  [ShiftStoreRequestOptionMethods.PUT]: Axios.put,
  [ShiftStoreRequestOptionMethods.PATCH]: Axios.patch,
  [ShiftStoreRequestOptionMethods.DELETE]: Axios.delete,
};

export type ShiftStoreResponse = { [key: string]: any; data?: any };

const DEFAULT_ERROR_MESSAGE_NO_DATA = 'Unable to load data. Please Try Again.';
const DEFAULT_ERROR_MESSAGE_GENERAL = 'Error. Please Try Again.';

export interface ShiftStoreRequestOptions {
  key: string; // The Shift Store Key
  url: string; // The URL to request the information

  // The key to check window.accompanyData for before hitting the server. If left blank
  // or the key is not found, it will fallback on the api request
  dataKey?: string;

  // Success Handler
  onSuccess?: (data: ShiftStoreResponse) => void;

  // An optional success transformer if the we're getting the data off of the data key.
  // Useful if the data between the api and server is formatted differently
  // Throw an error to reject the dataKey response and use the api request instead
  dataKeyResponseTransformer?: (data: ShiftStoreResponse) => ShiftStoreResponse;

  // The cache id to check if we already have loaded this particular record
  id?: any;

  // The API request method (default is GET)
  method?: ShiftStoreRequestOptionMethods;

  // The params to send (for non get requests)
  params?: { [key: string]: any };

  // The error message to set if the request fails (And we can't get an error message)
  // from the server
  errorMessage?: string;

  // The error handler (if left blank, the handleServerError method will be used)
  onError?: (data: ShiftStoreResponse) => void;
}

/**
 * A store for the current Member
 * TODO: Get rid of this and update stores that uses this to use `ApiService` instead.
 */
export class ShiftStore {
  /**
   * Our base state stores. We use observable maps so that MobX
   * can watch the changes of the dynamic nested keys
   */
  @observable states = new ObservableMap();
  @observable errors = new ObservableMap();

  /**
   * Sets a store key state to pending
   * @param key - The Store Key you're setting the state of
   */
  @action
  setPending(key: ShiftStoreKey) {
    this.errors?.delete(key);
    this.clearCacheLoaded(key);
    this.setState(ShiftStoreState.Pending, key);
  }

  /**
   * Sets a store key state to loaded
   * @param key - The Store Key you're setting the state of
   */
  @action
  setLoaded(key: ShiftStoreKey, id?: any) {
    this.errors?.delete(key);
    this.setState(ShiftStoreState.Loaded, key);

    if (id) {
      this.setCacheLoaded(key, id);
    }
  }

  /**
   * Sets a store key state to an error state
   * @param key - The Store Key you're setting the state of
   */
  @action
  setError(error: ShiftStoreError, key: ShiftStoreKey) {
    this.errors?.set(key, error);
    this.setState(ShiftStoreState.Error, key);
  }

  /**
   * Sets a store key state to a ShiftStoreState
   * @param key - The Store Key you're setting the state of
   */
  @action
  setState(state: ShiftStoreState, key: ShiftStoreKey) {
    this.states.set(key, state);
  }

  /**
   * Checks if the state of a key is equal to a state. If you do not define
   * a key, it'll check all existing keys.
   * @param state - The state that you're checking for
   * @param key - The Store Key you're checking the state of (checks all if blank)
   */
  isState(state: ShiftStoreState, key: ShiftStoreKey | ShiftStoreKey[] = ShiftStoreKeyGlobal) {
    if (key == ShiftStoreKeyGlobal) {
      let ok = this.states.size > 0;

      if (!ok) {
        return ok;
      }

      this.states.forEach((keyState) => {
        if (!ok) {
          return;
        }

        ok = keyState === state;
      });

      return ok;
    }

    if (Array.isArray(key)) {
      let ok = true;

      key.every((key) => {
        return (ok = this.isState(state, key));
      });

      return ok;
    }

    return this.states.get(key) === state;
  }

  /**
   * Checks if the state of a key is loaded. If you do not define
   * a key, it'll check all existing keys.
   * @param key - The Store Key you're checking the state of (checks all if blank)
   */
  isLoaded(key?: ShiftStoreKey | ShiftStoreKey[], id?: any) {
    if (key && id) {
      return this.isCacheLoaded(key as ShiftStoreKey, id);
    }

    return this.isState(ShiftStoreState.Loaded, key);
  }

  /**
   * Checks if the state of a key is pending. If you do not define
   * a key, it'll check all existing keys.
   * @param key - The Store Key you're checking the state of (checks all if blank)
   */
  isPending(key?: ShiftStoreKey | ShiftStoreKey[]) {
    return this.isState(ShiftStoreState.Pending, key);
  }

  /**
   * Checks if the state of a key is not started. If you do not define
   * a key, it'll check all existing keys.
   * @param key - The Store Key you're checking the state of (checks all if blank)
   */
  isNotStarted(key?: ShiftStoreKey | ShiftStoreKey[]) {
    return this.isState(ShiftStoreState.NotStarted, key);
  }

  /**
   * Checks if the state of a key has an error. If you do not define
   * a key, it'll check all existing keys.
   * @param key - The Store Key you're checking the state of (checks all if blank)
   */
  hasError(key?: ShiftStoreKey | ShiftStoreKey[]) {
    return this.isState(ShiftStoreState.Error, key);
  }

  /**
   * Gets the error (if any) of the ShiftStoreKey. If you do not define
   * a key, it'll check all existing keys.
   * @param key - The Store Key you're checking the state of (checks all if blank)
   */
  getError(key: ShiftStoreKey = ShiftStoreKeyGlobal): ShiftStoreError | undefined {
    return this.errors.get(key);
  }

  handleServerError(
    e: any,
    config: { key?: ShiftStoreKey; defaultMessage?: string; followRedirectOnError?: boolean },
    fingerprintData: { method: ShiftStoreRequestOptionMethods; url: URLBuilder; errorKey?: string }
  ) {
    if (e.response) {
      const axiosError = e as AxiosError;
      const { status, data } = axiosError.response;
      const response = data as any;

      if (status === 401 && response.loginRoute && config.followRedirectOnError) {
        window.location.href = URI(response.loginRoute).query({ redirect_to: URI().resource() });
        return;
      }

      const message =
        (response ? response.message : false) ||
        config.defaultMessage ||
        'Error. Please Try Again.';

      this.setError(
        {
          message: message,
          code: status,
          context: (data ? response.context : false) || {},
        },
        config.key || ShiftStoreKeyGlobal
      );

      return;
    }

    if (this.isCancelError(e, e?.toJSON(), fingerprintData.url)) {
      return;
    }

    ThirdPartyService.sentry.withScope((scope) => {
      const { method, errorKey, url } = fingerprintData;

      const scopeKey = errorKey || url.key || url.toString();

      scope.setFingerprint([method, scopeKey, status || 'Unknown']);

      const richError = new Error(
        `Request ${method} ${errorKey || this.descriptiveKeyForURL(url)} failed with message "${
          e.message
        }"`
      );

      ThirdPartyService.sentry.captureException(richError);
    });
  }

  /**
   * This is a copy of ApiService.isCancelError(),
   * so old stores can handle cancellations and aborts gracefully.
   *
   * @param error
   * @param errorContext
   * @param url
   * @returns boolean
   */
  private isCancelError(error, errorContext, url): boolean {
    if (Axios.isCancel(error)) {
      const message = `Request to ${url} was cancelled. Suppress if one off, but fix if happening often`;
      console.warn(message);
      return true;
    }

    if (errorContext?.code === 'ECONNABORTED') {
      const message = `Request to ${url} was aborted`;
      console.warn(message);
      return true;
    }

    return false;
  }

  private descriptiveKeyForURL(url: URLBuilder) {
    return url.key ? `${url.key} (${url.toString()})` : url.toString();
  }

  @observable cacheLoadedMap = new ObservableMap();
  setCacheLoaded(key: ShiftStoreKey, id: any) {
    this.cacheLoadedMap.set(key, id);
  }

  clearCacheLoaded(key: ShiftStoreKey) {
    this.cacheLoadedMap.delete(key);
  }

  isCacheLoaded(key: ShiftStoreKey, id: any) {
    return this.cacheLoadedMap.get(key) == id;
  }

  @action update(props: Record<string, any>) {
    for (const key in props) {
      this[key] = props[key];
    }
  }

  async request(options, forceAPI = false) {
    const {
      key,
      errorKey,
      id,
      url,
      method = ShiftStoreRequestOptionMethods.GET,
      params,
      queryParams,
      errorMessage,
      onSuccess,
      onError,
      dataKey,
      dataKeyResponseTransformer,
      followRedirectOnError = true,
    } = options;

    // Check if we've already loaded
    if (!forceAPI && method === ShiftStoreRequestOptionMethods.GET && this.isLoaded(key, id)) {
      return;
    }

    // Set the loading state
    this.setPending(key);

    // Attempt to lookup via data key
    if (!forceAPI && dataKey) {
      try {
        let dataResult = await ClientDataHelper.get(dataKey);
        if (dataResult) {
          if (dataKeyResponseTransformer) {
            dataResult = dataKeyResponseTransformer(dataResult);
          }

          if (onSuccess) {
            await onSuccess(dataResult);
          }

          this.setLoaded(key, id);
          return;
        }
      } catch (e) {
        if (url) {
          await this.request(options, true);
          return;
        }

        if (onError) {
          await onError(e);
          return;
        }

        this.handleServerError(
          e,
          {
            key,
            defaultMessage: errorMessage || DEFAULT_ERROR_MESSAGE_NO_DATA,
            followRedirectOnError,
          },
          {
            method,
            url,
            errorKey,
          }
        );

        return;
      }
    }

    if (!url) {
      this.setError(
        {
          message: DEFAULT_ERROR_MESSAGE_GENERAL,
          code: 1,
          context: {},
        },
        key
      );

      return;
    }

    // If not, use the api
    try {
      const requestFn = requestFns[method];
      // Default POST & PUT calls
      let requestParams = [url.toString(), params, queryParams];

      // GET & DELETE calls
      if (
        method === ShiftStoreRequestOptionMethods.GET ||
        method === ShiftStoreRequestOptionMethods.DELETE
      ) {
        requestParams = [
          url.toString(),
          {
            ...params,
            ...queryParams,
          },
        ];
      }
      const result = await requestFn(...requestParams);

      if (onSuccess) {
        await onSuccess(result.data);
      }

      this.setLoaded(key, id);
    } catch (e) {
      if (onError) {
        await onError(e);
        return;
      }

      this.handleServerError(
        e,
        {
          key: key,
          defaultMessage: errorMessage || DEFAULT_ERROR_MESSAGE_NO_DATA,
          followRedirectOnError,
        },
        {
          method,
          url,
        }
      );
    }
  }
}

export default ShiftStore;
