import { action, computed, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { EmailActivityModel, MessageTags, Model, ModelList } from 'app/models';
import Store from 'app/stores/Store';

export class EmailActivityStore extends Store<Model> {
  @observable emailActivities = new ModelList<EmailActivityModel>(EmailActivityModel);

  constructor() {
    super();
    EmailActivityModel._store = this;
  }

  @computed
  get isLoading(): boolean {
    return this.emailActivities.loading;
  }

  @computed
  get emailActivitiesByMemberId(): { [memberId: number]: EmailActivityModel[] } {
    return this.emailActivities.items.reduce((acc, emailActivity) => {
      if (!acc?.[emailActivity.member_id]) {
        acc[emailActivity.member_id] = [];
      }
      acc[emailActivity.member_id].push(emailActivity);
      return acc;
    }, {});
  }

  @action
  async loadTeamEmailActivities(teamId: number, key: MessageTags): Promise<void> {
    const url = ServerRouteHelper.api.teams.emailActivities(teamId);
    const params = { key };

    await this.emailActivities.load(url, params);
  }

  @action
  fakeEmailActivities(memberIds: number[]): void {
    const created = {
      date: Date.now().toString(),
      days: 0,
    };

    memberIds.forEach((memberId) => {
      const emailActivity = this.emailActivitiesByMemberId?.[memberId]?.[0];

      // If there is an existing activity, modify it to be latest
      if (!!emailActivity) {
        emailActivity.updateFromJson({ created });
        return;
      }

      this.emailActivities.appendItem(
        EmailActivityModel.fromJson({
          id: `fake_${memberId}`,
          member_id: memberId,
          action: 'send',
          message_tag: MessageTags.PerspectiveInvite,
          created,
        })
      );
    });
  }
}

export default EmailActivityStore;
