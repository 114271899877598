import React from 'react';

import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import cx from 'classnames';

import './NewMemberForm.scss';

export interface NewMemberValues {
  name: string;
  email: string;
}

interface NewMemberFormProps {
  onAddMember: (values: { name: string; email: string }) => void;
  emailPlaceholder?: string;
  namePlaceholder?: string;
  emailLabel?: string;
  nameLabel?: string;
  addCta?: string;
  disabled?: boolean;
  isAdding?: boolean;
  buttonClass?: string;
  showIcons?: boolean;
  className?: string;
}

export const NewMemberForm: React.FC<NewMemberFormProps> = ({
  onAddMember,
  emailPlaceholder,
  namePlaceholder,
  emailLabel,
  nameLabel,
  addCta,
  disabled,
  buttonClass,
  showIcons,
  isAdding,
  className,
}) => {
  const [form] = Form.useForm();

  const handleForm = (values) => {
    onAddMember(values);
    form.resetFields();
  };

  const buttonText = addCta ?? 'Add Participant';

  return (
    <div className={cx('new-member-form', className)}>
      <Form form={form} layout="vertical" onFinish={handleForm}>
        <Form.Item
          name="name"
          label={nameLabel}
          rules={[
            {
              required: true,
              message: 'Full name is required',
            },
          ]}
        >
          <Input
            placeholder={namePlaceholder}
            id="new-member-form-name-input"
            disabled={disabled}
            prefix={showIcons && <UserOutlined />}
          />
        </Form.Item>

        <Form.Item
          name="email"
          label={emailLabel}
          rules={[
            {
              type: 'email',
              message: 'Must be a valid email',
            },
            {
              required: true,
              message: 'Email is required',
            },
          ]}
        >
          <Input
            placeholder={emailPlaceholder}
            id="new-member-form-email-input"
            disabled={disabled}
            prefix={showIcons && <MailOutlined />}
          />
        </Form.Item>

        <Form.Item className="button-container">
          <Button
            htmlType="submit"
            className={cx(buttonClass ?? 'btn-outline', { 'btn-default': disabled })}
            id="new-member-form-add-btn"
            disabled={disabled || isAdding}
            loading={isAdding}
          >
            {isAdding ? 'Adding' : buttonText}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewMemberForm;
