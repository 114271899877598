import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const SignUp = lazy(() => import('./SignUp'));

export default (
  <Suspense fallback={<Loading />}>
    <Route exact path="/onboarding/:token/details" component={SignUp} />
  </Suspense>
);
