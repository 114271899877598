import { action, observable } from 'mobx';

import { MembershipType } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import {
  MemberModel,
  PagingInfoModel,
  PagingMetaModel,
  PulseForMemberModel,
  PulseStatementModel,
} from 'app/models';
import { ModelItem } from 'app/models/ModelItem';
import { ModelList } from 'app/models/ModelList';
import ThirdPartyService from 'app/services/ThirdPartyService';
import Store from 'app/stores/Store';

export class PulseForMemberStore extends Store<PulseForMemberModel> {
  @observable pulse = new ModelItem<PulseForMemberModel>(PulseForMemberModel);
  @observable pulses = new ModelList<PulseForMemberModel>(PulseForMemberModel);

  @observable public searchAllParticipants = new ModelList<MemberModel>(MemberModel);
  @observable public searchParticipantsPage = new ModelList<MemberModel>(MemberModel);
  @observable public searchParticipantsPageMeta: PagingMetaModel;

  @observable isSaving = false;
  @action setIsSaving = (isSaving: boolean): void => {
    this.isSaving = isSaving;
  };

  constructor() {
    super();
    PulseForMemberModel._store = this;
  }

  loadPulseByToken = (token: string) =>
    this.pulse.load(ServerRouteHelper.api.pulses.pulseByToken(token));

  loadPulses = (memberId: number): Promise<void> => {
    return this.pulses.load(ServerRouteHelper.api.pulses.members.list(memberId));
  };

  loadPulse = (memberId: number, pulseId: number): Promise<void> => {
    return this.pulse.load(ServerRouteHelper.api.pulses.members.get(memberId, pulseId));
  };

  create = async (
    memberId: number,
    membership: MembershipType,
    statements?: Partial<PulseStatementModel>[]
  ): Promise<PulseForMemberModel> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.pulses.members.create(memberId);
      const response = await this.apiService.post(url, { membership, statements });
      return PulseForMemberModel.fromJson(response.data);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  update = async (
    pulse: PulseForMemberModel,
    statements: Partial<PulseStatementModel>[]
  ): Promise<PulseForMemberModel> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.pulses.members.update(pulse.owner.item.id, pulse.id);

      const response = await this.apiService.put(url, { membership: pulse.membership, statements });
      pulse.updateFromJson(response.data);

      return pulse;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  reminder = async (pulse: PulseForMemberModel): Promise<void> => {
    try {
      const ownerId = pulse.owner.item.id;
      const url = ServerRouteHelper.api.pulses.members.reminder(ownerId, pulse.id);

      await this.apiService.post(url);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    }
  };

  delete = async (pulse: PulseForMemberModel): Promise<void> => {
    try {
      const pulseOwner = pulse.owner.item;
      const url = ServerRouteHelper.api.pulses.members.delete(pulseOwner.id, pulse.id);

      await this.apiService.delete(url);
      this.pulses.deleteItem(pulse);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    }
  };

  launch = async (pulse: PulseForMemberModel, started = true): Promise<PulseForMemberModel> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.pulses.members.update(pulse.owner.item.id, pulse.id);

      const config = {
        url,
        data: { started: started },
        throwError: true,
        showGenericError: true,
      };

      const response = await this.apiService.newPatch(config);
      pulse.updateFromJson(response.data);

      return pulse;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  togglePause = async (pulse: PulseForMemberModel, paused): Promise<PulseForMemberModel> => {
    this.setIsSaving(true);
    try {
      const memberId = pulse.owner.item.id;
      const url = ServerRouteHelper.api.pulses.members.update(memberId, pulse.id);

      const config = {
        url,
        data: { disabled: paused },
        showGenericError: true,
      };

      const response = await this.apiService.newPatch(config);
      pulse.updateFromJson(response.data);
      return pulse;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  updateMembership = async (pulse: PulseForMemberModel): Promise<void> => {
    this.setIsSaving(true);
    try {
      const url = ServerRouteHelper.api.pulses.members.update(pulse.owner.item.id, pulse.id);
      await this.apiService.patch(url, { membership: pulse.membership });
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  updateParticipants = async (
    pulse: PulseForMemberModel,
    participants: Partial<MemberModel>[]
  ): Promise<PulseForMemberModel> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.pulses.members.participants(pulse.owner.item.id, pulse.id);

      const config = {
        url,
        data: {
          participants: participants,
        },
        throwError: true,
        showGenericError: true,
      };

      const response = await this.apiService.newPut(config);
      pulse.participants.deserialize(response.data);

      return pulse;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  @action
  public loadParticipantsPool = async (
    pulse: PulseForMemberModel,
    pageInfo?: Partial<PagingInfoModel>
  ): Promise<void> => {
    const url = ServerRouteHelper.api.pulses.participantsPool(pulse.id);

    const response = await this.searchParticipantsPage.load(url, pageInfo);

    if (!response) {
      return;
    }

    // If we at first page, clear existing aggregated members
    if (pageInfo?.page === 1) {
      this.searchAllParticipants.setItems([]);
    }

    this.searchAllParticipants.appendItems(this.searchParticipantsPage.items);
    this.searchParticipantsPageMeta = new PagingMetaModel(response.meta);
  };
}

export default PulseForMemberStore;
