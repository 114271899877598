import React, { Component, ReactNode } from 'react';

import { Button } from 'antd';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './CookiePrefFooter.module.scss';

import {
  CookiePrefActionSources,
  CookiePrefOptions,
  STORE_COOKIE_PREF,
  STORE_MEMBER,
} from 'app/constants';
import { CookiePrefStore, MemberStore } from 'app/stores';

interface CookiePrefFooterProps {
  cookiePrefStore?: CookiePrefStore;
  memberStore?: MemberStore;
}

export class CookiePrefFooter extends Component<CookiePrefFooterProps> {
  @observable show = false;
  @action setShow = (show: boolean): void => {
    this.show = show;
  };

  constructor(props: CookiePrefFooterProps) {
    super(props);
    this.init();
  }

  async init(): Promise<void> {
    const { cookiePrefStore, memberStore } = this.props;

    cookiePrefStore.loadPreference();

    if (!this.currentCookiePref) {
      const result = await memberStore.getMemberAndTokenFromSharedLink(window.location.pathname);
      cookiePrefStore.setSharedLinkToken(result?.token);

      // Reload cookie preference because of potential update after member resolution
      cookiePrefStore.loadPreference();
    }

    this.setShow(this.currentCookiePref ? false : true);
  }

  get currentCookiePref(): string {
    return this.props.cookiePrefStore.cookiePreference?.pref;
  }

  recordCookiePref = async (pref: string): Promise<void> => {
    this.setShow(false);

    const token = this.props.cookiePrefStore.sharedLinkToken;

    this.props.cookiePrefStore.savePreference({
      cookie_pref: pref,
      source: CookiePrefActionSources.BANNER,
      token,
    });
  };

  render(): ReactNode {
    if (!this.show) {
      return null;
    }

    return (
      <div className={styles.container}>
        <p className={styles.content}>
          We use performance cookies to provide a better app experience. Rejecting these cookies
          will make some features and support unavailable to you.&nbsp;
          <a href="https://www.valence.co/other/privacy#cookies" target="_blank">
            Learn more here
          </a>
          .
        </p>

        <div className={styles.buttons}>
          <Button
            id="cookie-accept"
            className={`${styles.acceptBtn} ${styles.cookieButton}`}
            onClick={() => this.recordCookiePref(CookiePrefOptions.ALL)}
          >
            Accept all cookies
          </Button>
          <Button
            id="cookie-reject"
            className={styles.cookieButton}
            type="link"
            onClick={() => this.recordCookiePref(CookiePrefOptions.ESSENTIAL)}
          >
            Reject performance cookies
          </Button>
        </div>
      </div>
    );
  }
}

export default inject(STORE_MEMBER, STORE_COOKIE_PREF)(observer(CookiePrefFooter));
