import URI from 'urijs';

import { ServerRouteHelper } from 'app/helpers';
import Model from 'app/models/Model';
import Store from 'app/stores/Store';

export enum EmailStatus {
  BLOCKED = 1,
  SAML_SSO_ENABLED = 2,
  NEW_EMAIL = 3,
  EXISTING_MEMBER = 4,
  RESET_PASSWORD = 5,
  PASSWORD_RESET_SENT = 6,
  FOREIGN_DOMAIN_CLUSTER = 8,
  PRIMARY_DOMAIN = 9,
  NO_PASSWORD = 10,
}

export enum LoginStatus {
  INVALID_CREDENTIALS = 1,
  EMAIL_NOT_VERIFIED = 2,
  LOGIN_FAILED_LOCKED_OUT = 3,
  SSO_ENABLED_FOR_EMAIL_DOMAIN = 4,
}

export class AuthStore extends Store<Model> {
  checkEmailBeforeLogin(email: string) {
    const queryParams = URI.parseQuery(window.location.search);
    const redirect_to = queryParams?.['redirect_to'];

    const url = ServerRouteHelper.api.auth.checkEmailBeforeLogin(redirect_to);
    return this.apiService.post(url, { email });
  }

  checkEmailPrimaryStatus(email: string) {
    const url = ServerRouteHelper.api.auth.checkEmailPrimaryStatus();
    return this.apiService.post(url, { email });
  }

  async login(email: string, password: string) {
    const url = ServerRouteHelper.api.auth.login();
    const config = {
      url,
      data: { email, password },
      throwError: true,
    };
    return await this.apiService.newPost(config);
  }

  logout() {
    const url = ServerRouteHelper.api.auth.logout();
    return this.apiService.post(url);
  }

  register(data) {
    const url = ServerRouteHelper.api.auth.register();
    const config = {
      url,
      data,
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  sendPasswordResetLink(email: string) {
    const url = ServerRouteHelper.api.auth.sendPasswordResetLink();

    const config = {
      url,
      data: { email },
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  verifyEmail(email: string, token: string) {
    const url = ServerRouteHelper.api.auth.verifyEmail();
    const config = {
      url,
      data: { email, token },
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  resendVerificationLink(email: string) {
    const url = ServerRouteHelper.api.auth.resendVerificationLink();

    const config = {
      url,
      data: { email },
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  resetPassword(data: {
    token: string;
    password: string;
    password_confirmation: string;
    entry_point?: string;
    name?: string;
  }) {
    const url = ServerRouteHelper.api.auth.resetPassword();
    const config = {
      url,
      data,
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  requestMagicLink = (email: string, redirectTo: string = null): Promise<void> => {
    const queryParams = URI.parseQuery(window.location.search);

    const redirect_to = redirectTo ?? queryParams?.['redirect_to'];
    const requested = queryParams?.['requested'] === 'false' ? false : true;
    const url = ServerRouteHelper.api.auth.magiclink();

    const config = {
      url,
      data: {
        email,
        redirect_to,
        requested,
      },
      throwError: true,
    };

    return this.apiService.newPost(config);
  };
}
