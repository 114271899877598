import { sortBy } from 'lodash';
import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import DiscussionSpaceAgendaModel from 'app/models/DiscussionSpaceAgendaModel';
import Store from 'app/stores/Store';

export class DiscussionSpaceAgendaStore extends Store<DiscussionSpaceAgendaModel> {
  @observable token;
  @action setToken = (token: string): void => {
    this.token = token;
  };

  constructor() {
    super();
    DiscussionSpaceAgendaModel._store = this;
  }

  findAgendasForDiscussionSpace = (discussionSpaceID: number): DiscussionSpaceAgendaModel[] => {
    const agendas = Array.from(this.entities.values()).filter(
      (entry) => entry.discussion_space_id === discussionSpaceID
    );
    return sortBy(agendas, ['order']);
  };

  updateNotesOrder = async (
    discussionSpaceId: number,
    agendaId: number,
    ordering: { type: string; id: number; order: number }[]
  ): Promise<void> => {
    let url = ServerRouteHelper.api.discussionSpace.agendas.reorder(discussionSpaceId, agendaId);

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    this.apiService.post(url, { ordering });
  };

  createAgenda = async (
    discussionSpaceId: number,
    agenda: Partial<DiscussionSpaceAgendaModel>
  ): Promise<DiscussionSpaceAgendaModel> => {
    let url = ServerRouteHelper.api.discussionSpace.agendas.create(discussionSpaceId);

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    const resp = await this.apiService.post(url, agenda);
    const rawAgenda = resp.data;
    this.createAgendaSlot(rawAgenda);
    return DiscussionSpaceAgendaModel.fromJson(rawAgenda);
  };

  updateAgenda = async (
    discussionSpaceId: number,
    agenda: DiscussionSpaceAgendaModel
  ): Promise<void> => {
    let url = ServerRouteHelper.api.discussionSpace.agendas.update(discussionSpaceId, agenda.id);

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    // Only support updating sort config atm
    const payload = { sort_by: agenda.sort_by };

    const response = await this.apiService.put(url, payload);

    if (response?.data) {
      agenda.updateFromJson(response.data);
    }
  };

  createAgendaSlot = (rawAgenda: Partial<DiscussionSpaceAgendaModel>): void => {
    // before instantiating the agenda, create an opening
    // slot first by moving indices >= this agenda's order
    Array.from(this.entities.values()).forEach((existingAgenda, i) => {
      if (existingAgenda.order < rawAgenda.order) {
        return;
      }

      const newOrder = existingAgenda.order + 1;
      existingAgenda.updateFromJson({ order: newOrder });
      this.entities[existingAgenda.id] = existingAgenda;
    });
  };
}

export default DiscussionSpaceAgendaStore;
