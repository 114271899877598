import { MemberPerspectiveResultModel } from 'app/models';

import Store from './Store';

export class MemberPerspectiveResultStore extends Store<MemberPerspectiveResultModel> {
  constructor() {
    super();
    MemberPerspectiveResultModel._store = this;
  }
}

export default MemberPerspectiveResultStore;
