import React, { Component, ReactNode } from 'react';

import { Button, Input, notification } from 'antd';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import './RegistrationCode.scss';

import { STORE_ORGANIZATION } from 'app/constants';
import { OrganizationModel } from 'app/models';
import { OrganizationStore } from 'app/stores';

interface RegistrationCodeProps {
  organization: OrganizationModel;
  organizationStore?: OrganizationStore;
}

export class RegistrationCode extends Component<RegistrationCodeProps> {
  @observable code;
  @action setCode = (code: string): string => (this.code = code);

  @observable isSaving = false;
  @action toggleIsSaving = (): void => {
    this.isSaving = !this.isSaving;
  };

  constructor(props: RegistrationCodeProps) {
    super(props);
    this.setCode(props.organization.code);
  }

  handleUpdate = async (): Promise<void> => {
    const { organization, organizationStore } = this.props;

    this.toggleIsSaving();

    try {
      await organizationStore.updateSecretCode(organization.id, this.code);

      notification.success({
        message: 'Saved!',
        description: 'Registration code successfully saved.',
      });
    } catch (err) {
      notification.error({
        message: 'Oops!',
        description: 'Something went wrong!',
      });
    } finally {
      this.toggleIsSaving();
    }
  };

  render(): ReactNode {
    return (
      <div className="org-registration-code">
        <h4>Registration Code</h4>
        <div className="code-input">
          <Input
            value={this.code}
            onChange={({ target }) => this.setCode(target.value)}
            disabled={this.isSaving}
          />
          <p>
            ⚠️ Do not change this if it's custom one that's been in use!
            <br />
            There may be code that uses this.
          </p>
        </div>
        <div className="actions">
          <Button type="primary" size="large" onClick={this.handleUpdate} disabled={this.isSaving}>
            Update
          </Button>
        </div>
      </div>
    );
  }
}

export default inject(STORE_ORGANIZATION)(observer(RegistrationCode));
