import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import SubscriptionOrderStore from 'app/stores/SubscriptionOrderStore';

import { ModelList } from './ModelList';
import SubscriptionOrderItemModel from './SubscriptionOrderItemModel';

export class SubscriptionOrderModel extends Model {
  static _store: SubscriptionOrderStore;

  readonly id: number;
  @observable slug: string;
  @observable provider: string;
  @observable provider_name: string;
  @observable licenses: number;
  @observable value: string;
  @observable status: string;
  @observable created_at: string;
  @observable order_items = new ModelList<SubscriptionOrderItemModel>(SubscriptionOrderItemModel);

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as SubscriptionOrderModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as SubscriptionOrderModel;
  }

  static get(id) {
    return this._get(id) as SubscriptionOrderModel;
  }
}

export default SubscriptionOrderModel;
