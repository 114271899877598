import { ServerRouteHelper } from 'app/helpers';
import ExerciseCommitmentModel, {
  ExerciseCommitmentCategory,
  ExerciseCommitmentModelProps,
} from 'app/models/ExerciseCommitmentModel';
import Store from 'app/stores/Store';

export class ExerciseCommitmentStore extends Store<ExerciseCommitmentModel> {
  constructor() {
    super();
    ExerciseCommitmentModel._store = this;
  }

  async createExerciseCommitment(data: ExerciseCommitmentModelProps, token: string) {
    const url = ServerRouteHelper.api.exercises.commitments.list(data.exercise_id, token);

    const response = await this.apiService.post(url, data);
    return ExerciseCommitmentModel.fromJson(response.data);
  }

  async updateExerciseCommitment(data: ExerciseCommitmentModelProps, token: string) {
    const url = ServerRouteHelper.api.exercises.commitments.show(data.exercise_id, data.id, token);

    const response = await this.apiService.put(url, data);
    return ExerciseCommitmentModel.fromJson(response.data);
  }

  commitmentsForExercise(exercise_id: number) {
    return Array.from(this.entities.values()).filter((item) => item.exercise_id === exercise_id);
  }

  commitmentsForExerciseAndCategory(exercise_id: number, category: ExerciseCommitmentCategory) {
    return Array.from(this.entities.values()).filter(
      (item) => item.exercise_id === exercise_id && item.category === category
    );
  }
}

export default ExerciseCommitmentStore;
