import React from 'react';

import { RouteComponentProps, Switch } from 'react-router-dom';

import AppHeader from 'app/components/features/AppHeader';
import Route from 'app/customRoute';
import { ServerRouteHelper } from 'app/helpers';

import EditCustomLocalizations from './EditCustomLocalizations/EditCustomLocalizations';

export default class CustomLocalizations extends React.Component<RouteComponentProps> {
  get basePath() {
    return this.props.match.path;
  }

  render() {
    return (
      <div>
        <AppHeader
          breadcrumbs={[
            {
              title: 'Admin Panel',
              link: ServerRouteHelper.referrer(),
            },
            {
              title: 'Custom Localizations',
              link: ServerRouteHelper.referrer(),
            },
          ]}
        />

        <div className="container-fluid">
          <Switch>
            <Route
              exact
              path={`${this.basePath}/:organization_id`}
              component={EditCustomLocalizations}
            />
            <Route render={() => <div>Not Found</div>} />
          </Switch>
        </div>
      </div>
    );
  }
}
