import React, { Component, ReactNode } from 'react';

import { computed } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';

import EmptyCardComponent from 'app/components/ui/EmptyCardComponent';
import { EmptyCardComponentContentCard } from 'app/components/ui/EmptyCardComponent/EmptyCardComponent';
import CardFooter from 'app/components/ui/EnhancedCard/CardFooter';
import CardHeader from 'app/components/ui/EnhancedCard/CardHeader';
import { ServerRouteHelper } from 'app/helpers';

export interface NoHabitsProps extends RouteComponentProps {
  memberId: number;
  isTestDrive?: boolean;
}

export class NoHabits extends Component<NoHabitsProps> {
  private get habitNewUrl(): string {
    return ServerRouteHelper.dashboard.myself.personalHabitsNew('add');
  }

  @computed
  get completeAssessmentBtn() {
    return {
      label: 'Create Habits',
      onClick: this.handleCreateHabit,
    };
  }

  @computed
  get readSupportGuidesBtn() {
    return {
      label: 'Read Our Habit Support Guides',
      onClick: () => window.open('https://support.valence.co/habit', '_blank', 'noopener'),
    };
  }

  handleCreateHabit = (): void => {
    if (this.props.isTestDrive) {
      return;
    }

    this.props.history.push(this.habitNewUrl);
  };

  @computed
  get cardHeader(): ReactNode {
    return <CardHeader heading="What is Habits?" button={this.completeAssessmentBtn} />;
  }

  @computed
  get cardFooter(): ReactNode {
    return <CardFooter secondaryButton={this.readSupportGuidesBtn} />;
  }

  @computed
  get contentCards(): EmptyCardComponentContentCard[] {
    return [
      {
        image: '/images/habit/habits-commit-new-habits.png',
        title: 'Commit to New Habits',
        description: 'Set personal development goals or select from our habit suggestions',
      },
      {
        image: '/images/habit/habits-be-accountable.png',
        title: 'Be Accountable',
        description: 'Make your habits public by inviting feedback on your progress',
      },
      {
        image: '/images/habit/habits-track-progress.png',
        title: 'Track Your Progress ',
        description: 'Receive reminders and check-in to track your progress ',
      },
    ];
  }

  public render(): ReactNode {
    return (
      <EmptyCardComponent
        cardHeader={this.cardHeader}
        contentCards={this.contentCards}
        cardFooter={this.cardFooter}
      />
    );
  }
}

export default withRouter(NoHabits);
