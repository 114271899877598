import React from 'react';

import cx from 'classnames';
import { chunk } from 'lodash';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';

import './SummaryPrintView.scss';

import ExerciseStatementsLegend from 'app/components/features/ExerciseStatements/ExerciseStatementsLegend';
import ScatterPlot from 'app/components/features/ScatterPlot';
import { ScoreBar } from 'app/components/features/ScoreBar/ScoreBar';
import { DEFAULT_SECTIONS, exerciseReportSections } from 'app/constants';
import { ExerciseReportModel, ExerciseStatementModel, SortOption } from 'app/models';

export interface SummaryPrintViewProps {
  className?: string;
  scatterPlotsPerPage?: number;
  scatterPlotSize?: number;
  scatterPlotLabelPadding?: number;
  report: ExerciseReportModel;
}

export class SummaryPrintView extends React.Component<SummaryPrintViewProps> {
  static defaultProps = {
    scatterPlotsPerPage: 4,
  };

  get report() {
    return this.props.report;
  }

  get team() {
    return this.report.team;
  }

  get exercise() {
    return this.report.exercise;
  }

  get exerciseType() {
    return this.report.exercise_type;
  }

  get statements() {
    return this.props.report.statementsWithSort(SortOption.MostCritical);
  }

  @computed
  get sections() {
    const { scatterPlotsPerPage } = this.props;

    const statements = this.report.statementsByClassificationWithSort();

    return DEFAULT_SECTIONS.map((section) => ({
      header: exerciseReportSections[section].title,
      color: exerciseReportSections[section].color,
      tooltip: exerciseReportSections[section].tooltip,
      statements: chunk(statements[section] ?? [], scatterPlotsPerPage),
    })).filter(({ statements }) => statements.length > 0);
  }

  @computed
  get isMy360() {
    return this.exercise?.is_360;
  }

  renderScoreBarStatement = (statement: ExerciseStatementModel) => {
    let props = { className: 'w-100', diamondValue: statement.scoreScaled };

    if (this.isMy360) {
      props = Object.assign(props, {
        circleValue: statement.personalScoreScaled,
        diamondValuecleValue: statement.othersScoreScaled,
      });
    }

    return (
      <div key={statement.id} className="statement" data-testid="scorebar-statement">
        <p>{statement.text}</p>
        <ScoreBar {...props} />
      </div>
    );
  };

  renderScatterPlotStatement = (statement: ExerciseStatementModel) => {
    const { scatterPlotSize, scatterPlotLabelPadding } = this.props;
    const points = this.isMy360 ? statement.allPoints : statement.points;
    return (
      <ScatterPlot
        key={statement.id}
        title={statement.text}
        labels={statement.labels}
        points={points}
        size={scatterPlotSize}
        labelPadding={scatterPlotLabelPadding}
      />
    );
  };

  renderSections = ({ header, color, statements }, i) => {
    return (
      <div key={`scatter-plot-${i}`} className="section" data-testid="scatter-plot-section">
        <h4 style={{ color }}>{header}</h4>
        {statements.map((subStatements, j) => (
          <div key={`sub-statements-${j}`} className="scatter-plots">
            {subStatements.map(this.renderScatterPlotStatement)}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { className } = this.props;

    return (
      <div className={cx('align-summary-print-view', className)}>
        <div className="container">
          <h1>
            {!this.isMy360 ? `${this.team.name}: ` : ''}
            {this.exerciseType.name} Report
          </h1>
          <h2>{moment(this.exercise.created_at).format('MMMM YYYY')}</h2>

          {this.isMy360 && (
            <ExerciseStatementsLegend scoreLegend="You" benchmarkLegend="Respondents" />
          )}

          <div className="statements">{this.statements.map(this.renderScoreBarStatement)}</div>
          <div className="sections">{this.sections.map(this.renderSections)}</div>
        </div>
      </div>
    );
  }
}

export default observer(SummaryPrintView);
