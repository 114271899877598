import React from 'react';

import './AlignImportanceModal.scss';

import Modal from 'app/components/ui/Modal';

export interface AlignImportanceModalProps {
  isOpen: boolean;
  onToggle: () => void;
  isMy360?: boolean;
}
const imageUrl = '/images/modals/relative_importance.png';
const my360ImageURL = '/images/modals/my360-modal-img.svg';
const MY_360_TITLE = 'Are you able to identify growth opportunities?';
const MY_360_DESCRIPTION =
  'To help identify the best learning opportunity, are you able to answer relatively - highlighting any areas that could use improvement, or picking things that might be less important compared to others?';
const ALIGN_TITLE = "The 'Relative Importance' axis";
const ALIGN_DESCRIPTION =
  ' While almost all the statements are important to many teams, you’ll get more interesting results if you try to rate their relative importance to you at this moment.';

export const AlignImportanceModal: React.StatelessComponent<AlignImportanceModalProps> = ({
  isOpen,
  onToggle,
  isMy360,
}) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const imgSrc = isMy360 ? my360ImageURL : imageUrl;
  React.useEffect(() => {
    let image = new Image();
    image.src = imgSrc;
    image.onload = () => setIsImageLoaded(true);
    return () => (image = null);
  }, [imgSrc]);
  return (
    <Modal
      isOpen={isOpen}
      title=""
      onToggle={onToggle}
      className="align-importance-modal"
      primaryAction={{
        label: isMy360 ? 'GOT IT' : 'Continue',
        onClick: onToggle,
      }}
    >
      <span className="align-importance-modal-header">{isMy360 ? MY_360_TITLE : ALIGN_TITLE}</span>
      <span className="align-importance-modal-description">
        {isMy360 ? MY_360_DESCRIPTION : ALIGN_DESCRIPTION}
      </span>
      <span className="align-importance-modal-body">
        {!isImageLoaded && <p>Loading...</p>}
        {isImageLoaded && <img width="100%" src={imgSrc} />}
      </span>
    </Modal>
  );
};

export default AlignImportanceModal;
