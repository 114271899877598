import { PulseStatementModel } from 'app/models';
import Store from 'app/stores/Store';

export class PulseStatementStore extends Store<PulseStatementModel> {
  constructor() {
    super();
    PulseStatementModel._store = this;
  }
}

export default PulseStatementStore;
