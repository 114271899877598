import React, { ReactNode } from 'react';

import cx from 'classnames';
import { action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import Card from 'app/components/ui/Card';
import FakeBox from 'app/components/ui/FakeBox';
import { STORE_ORGANIZATION } from 'app/constants';
import { preventDefault } from 'app/helpers';
import { OrganizationStore } from 'app/stores';

export interface EditCustomLocalizationsProps extends RouteComponentProps {
  [STORE_ORGANIZATION]?: OrganizationStore;
  [STORE_ORGANIZATION]?: OrganizationStore;
}

export interface CustomLocalizationRowProps {
  localKey: string;
  value: string;
  onValueChange: (key: string, value: string) => void;
  onDeleteKey: (key: string) => void;
}

const CustomLocalizationRow: React.StatelessComponent<CustomLocalizationRowProps> = ({
  localKey,
  value,
  onValueChange,
  onDeleteKey,
}) => (
  <tr>
    <td>{localKey}</td>
    <td>
      <textarea
        style={{ width: '100%' }}
        className="form-control"
        onChange={({ target: { value } }) => onValueChange(localKey, value)}
        defaultValue={value}
      />
    </td>
    <td>
      <a href="#" onClick={preventDefault(() => onDeleteKey(localKey))}>
        <i className="far fa-trash" />
      </a>
    </td>
  </tr>
);

export class EditCustomLocalizations extends React.Component<EditCustomLocalizationsProps> {
  constructor(props) {
    super(props);
    this.bind();
    this.init();
  }

  bind() {
    this.handleAddKey = this.handleAddKey.bind(this);
    this.handleDeleteKey = this.handleDeleteKey.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleUpdateOrganization = this.handleUpdateOrganization.bind(this);
  }

  init() {
    this.props[STORE_ORGANIZATION].getOrganizationById(this.props.match.params['organization_id']);
  }

  @computed
  get organization() {
    return this.props[STORE_ORGANIZATION].organization.item;
  }

  @computed
  get saving() {
    return this.props[STORE_ORGANIZATION].organization.loading;
  }

  // @todo dirty hack that should be properly fixed later
  @action
  refreshOrganizationCustomLocalization() {
    this.organization.custom_localization = JSON.parse(
      JSON.stringify(this.organization.custom_localization)
    );
  }

  handleValueChange(key: string, value: string) {
    this.organization.custom_localization[key] = value;
    this.refreshOrganizationCustomLocalization();
  }

  handleDeleteKey(key: string) {
    if (!confirm(`Are you sure you want to delete ${key}`)) {
      return;
    }

    delete this.organization.custom_localization[key];
    this.refreshOrganizationCustomLocalization();
  }

  handleAddKey() {
    const key = prompt('What would you like to call the key?', 'domain.namespace');

    if (!key) {
      return;
    }

    const value = prompt(`What would you like the initial value to be for ${key}`);
    if (!value) {
      return;
    }

    if (!this.organization.custom_localization) {
      this.organization.custom_localization = {};
    }

    this.organization.custom_localization[key] = value;
    this.refreshOrganizationCustomLocalization();
  }

  async handleUpdateOrganization() {
    await this.props[STORE_ORGANIZATION].updateOrganization(
      this.organization.id,
      this.organization
    );
  }

  get orgCustomLocalization(): ReactNode {
    if (!this.organization.custom_localization) {
      return null;
    }

    return (
      <tbody>
        {Object.keys(this.organization.custom_localization).map((key) => (
          <CustomLocalizationRow
            key={key}
            localKey={key}
            value={this.organization.custom_localization[key]}
            onValueChange={this.handleValueChange}
            onDeleteKey={this.handleDeleteKey}
          />
        ))}
      </tbody>
    );
  }

  render() {
    if (!this.organization) {
      return <FakeBox width="100%" height="30rem" />;
    }

    return (
      <div className="m-5">
        <h3>Editing Custom Localizations for {this.organization.name}</h3>
        <Card>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Key</th>
                <th scope="col">Value</th>
                <th scope="col">Action</th>
              </tr>
            </thead>

            {this.orgCustomLocalization}
          </table>

          <a
            href="#"
            className="btn btn-secondary mb-2"
            onClick={preventDefault(this.handleAddKey)}
          >
            <i className="far fa-plus mr-2 mb-2" />
            Add Key
          </a>

          <a
            href="#"
            className={cx('btn btn-primary', { 'btn-disabled': this.saving })}
            onClick={preventDefault(!this.saving && this.handleUpdateOrganization)}
          >
            {this.saving ? 'Saving...' : 'Save'}
          </a>
        </Card>
      </div>
    );
  }
}

export default inject(STORE_ORGANIZATION)(observer(EditCustomLocalizations));
