// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bcAp1qtkDF7ZPTSLjXlg5w\\=\\={margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./resources/assets/js/pages/dashboard/Pulse/PulseSteps/InvitePeersStep/InviteModal/InviteModal.module.scss"],"names":[],"mappings":"AAAA,4BACE,kBACF","sourcesContent":[".searchInput {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": "bcAp1qtkDF7ZPTSLjXlg5w=="
};
export default ___CSS_LOADER_EXPORT___;
