/*
 |------------------------------------------------------------------------------
 | Exception Handling Test
 |------------------------------------------------------------------------------
 |
 | To test manually trigger an exception and see what happens and how it is
 | handled.
 |
 */

import React from 'react';

export const ExceptionHandling: React.StatelessComponent = () => (
  <div>
    <h2>Exception Handling</h2>
    <button
      onClick={() => accompany.callFunctionThatDoesNotExist()}
      type="button"
      className="btn btn-danger m-2"
    >
      Call Function that does not exist
    </button>
    <button
      onClick={() => {
        throw 'Manually thrown exception';
      }}
      type="button"
      className="btn btn-danger m-2"
    >
      Manually Throw Exeception
    </button>
  </div>
);

export default ExceptionHandling;
