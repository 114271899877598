import SubscriptionOrderItemModel from 'app/models/SubscriptionOrderItemModel';
import Store from 'app/stores/Store';

export class SubscriptionOrderItemStore extends Store<SubscriptionOrderItemModel> {
  constructor() {
    super();
    SubscriptionOrderItemModel._store = this;
  }
}

export default SubscriptionOrderItemStore;
