import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const TeamConnect = lazy(() => import('./TeamConnect'));
const ProfileQuiz = lazy(() => import('./ProfileQuiz'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route
        exact
        path="/dashboard/teams/:teamId/team-games/connect/:teamGameId?/:step/:memberId?"
        component={TeamConnect}
      />

      <Route exact path="/team-games/connect/:token" component={ProfileQuiz} />
    </Switch>
  </Suspense>
);
