import { observable } from 'mobx';

import ServerRouteHelper from 'app/helpers/ServerRouteHelper';
import { ModelItem, OnboardingToolActivationModel } from 'app/models';
import ThirdPartyService from 'app/services/ThirdPartyService';
import Store from 'app/stores/Store';

export class OnboardingStore extends Store<OnboardingToolActivationModel> {
  @observable activationContent = new ModelItem<OnboardingToolActivationModel>(
    OnboardingToolActivationModel
  );

  constructor() {
    super();
    OnboardingToolActivationModel._store = this;
  }

  async loadToolActivationContent(token: string): Promise<void> {
    const url = ServerRouteHelper.api.onboarding.customOnboarding.activationContent(token);

    this.activationContent.load(url);
  }

  async createExerciseForOnboarding(onboardingLinkId, data: any): Promise<any> {
    const url =
      ServerRouteHelper.api.onboarding.customOnboarding.createTeamExercise(onboardingLinkId);
    const config = {
      url,
      data: data,
      throwError: true,
    };

    try {
      const response = await this.apiService.newPost(config);
      return response;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    }
  }

  async createAutoTeam(data): Promise<any> {
    const url = ServerRouteHelper.api.onboarding.createAutomaticTeam();
    const config = { url, data, throwError: true };

    try {
      await this.apiService.newPost(config);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    }
  }

  async completeOnboardingSurvey(data): Promise<any> {
    const url = ServerRouteHelper.api.onboarding.completeOnboarding();
    const config = { url, data, throwError: true };

    const response = await this.apiService.newPost(config);

    return response?.data;
  }
}

export default OnboardingStore;
