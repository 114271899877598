import { action, observable, ObservableMap } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import {
  ModelItem,
  ModelList,
  OrganizationContent,
  OrganizationContentContent,
  OrganizationContentModel,
  TeamModel,
} from 'app/models';
import { LOAD_METHOD } from 'app/models/ModelContainer';
import Store from 'app/stores/Store';

export class OrganizationContentStore extends Store<OrganizationContentModel> {
  @observable private contentItemsMap = new ObservableMap<
    string,
    ModelItem<OrganizationContentModel>
  >();
  @observable contentItems = new ModelList<OrganizationContentModel>(OrganizationContentModel);

  constructor() {
    super();
    OrganizationContentModel._store = this;
  }

  /**
   * Loads and returns the requested Organization Content (as opposed to just loading or just retrieving it).
   */
  @action
  async retrieveContentItem<T extends OrganizationContentContent = OrganizationContentContent>(
    key: OrganizationContent,
    orgId?: number,
    contentArgument?: number
  ): Promise<OrganizationContentModel<T>> {
    await this.loadContentItem(key, orgId, contentArgument);
    return this.getContentModel<T>(key, orgId);
  }

  /**
   * Note: orgId is optional since always available, ie. PerspectiveTest.
   */
  @action
  loadContentItem = async (
    key: OrganizationContent,
    orgId?: number,
    contentArgument?: number,
    params?: any
  ): Promise<void> => {
    // If already loaded, don't bother
    if (this.isLoaded(key, orgId)) {
      return;
    }

    // If this key has no tracker yet, add one
    const contentItemKeyPrefix = orgId ?? 'default';
    const contentItemKey = `${contentItemKeyPrefix}-${key}`;
    if (!this.contentItemsMap.has(contentItemKey)) {
      this.contentItemsMap.set(
        contentItemKey,
        new ModelItem<OrganizationContentModel>(OrganizationContentModel)
      );
    }

    const url = ServerRouteHelper.api.organizations.content.single(key, orgId, contentArgument);

    return this.contentItemsMap.get(contentItemKey).load(url, params);
  };

  @action
  loadContentItems(orgId: number, keys: OrganizationContent[], team?: TeamModel): Promise<void> {
    // TODO: Can also add checking if keys passed already exists and only load those missing
    const url = ServerRouteHelper.api.organizations.content.multi(orgId, team?.id);
    return this.contentItems.load(url, { keys }, { method: LOAD_METHOD.POST });
  }

  @action
  public async getContentItems(orgId: number, keys: OrganizationContent[]) {
    const url = ServerRouteHelper.api.organizations.content.multi(orgId);
    const config = {
      url: url,
      params: { keys },
      throwError: true,
      showGenericError: true,
    };

    const response = await this.apiService.newPost(config);

    return OrganizationContentModel.fromJson(response.data[0]);
  }

  public getContentModel<T extends OrganizationContentContent = OrganizationContentContent>(
    id: OrganizationContent | string,
    orgId?: number
  ): OrganizationContentModel<T> {
    return Array.from(this.entities.values()).find((entity) => {
      return entity.id === id && (!orgId || (orgId && entity.organization_id === orgId));
    });
  }

  public isLoaded = (
    id: OrganizationContent,
    orgId?: number,
    contentArgument?: number
  ): boolean => {
    const contentId = contentArgument ? `${id}|${contentArgument}` : id;
    return !!this.getContentModel(contentId, orgId);
  };

  // WARNING: I don't like this method, its passing an id
  // and yet unsure where to check it from.
  //
  // This will return true if contentItems is loading
  // even though the id passed might not be what's being loaded there.
  // In turn will make components wait for a request where
  // it might not include the data they're interested in.
  public isLoading = (id: OrganizationContent, orgId?: number): boolean => {
    const isContentLoaded = this.isLoaded(id, orgId);

    // If already loaded, then we are not loading
    if (isContentLoaded) {
      return false;
    }

    // Is any items from the map loading or contentItems is loading
    const hasLoadingItem = !!Array.from(this.contentItemsMap.values()).find((item) => item.loading);
    return hasLoadingItem || this.contentItems.loading;
  };
}

export default OrganizationContentStore;
