import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { GroupedPulseResponsesModel, PulseForTeamModel, PulseResponseModel } from 'app/models';
import { ModelItem } from 'app/models/ModelItem';
import Store from 'app/stores/Store';

export class PulseResponseStore extends Store<PulseForTeamModel> {
  @observable responses = new ModelItem<PulseResponseModel>(PulseForTeamModel);
  @observable groupedResponses = new ModelItem<GroupedPulseResponsesModel>(
    GroupedPulseResponsesModel
  );

  constructor() {
    super();
    PulseResponseModel._store = this;
  }

  saveResponses = (
    token: string,
    responses,
    exerciseId?: string,
    memberId?: string
  ): Promise<any> => {
    const payload = {
      responses,
      exerciseId,
      memberId,
    };

    const url = ServerRouteHelper.api.pulses.responsesByToken(token);

    const config = {
      url,
      data: payload,
      showGenericError: true,
    };

    return this.apiService.newPost(config);
  };

  @action
  loadResponses = async (id: number): Promise<void> => {
    const payload = await this.apiService.get(ServerRouteHelper.api.pulses.responses(id));
    this.groupedResponses.setItem(payload.data);
  };
}

export default PulseResponseStore;
