import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem, PulseReportModel } from 'app/models';
import Store from 'app/stores/Store';

export class PulseReportStore extends Store<PulseReportModel> {
  @observable personalPulseReportData = new ModelItem<PulseReportModel>(PulseReportModel);

  constructor() {
    super();
    PulseReportModel._store = this;
  }

  loadReportData = (ownerId: number, pulseId: number, type: string): Promise<void> => {
    const url =
      type === 'members'
        ? ServerRouteHelper.api.pulses.members.report(ownerId, pulseId)
        : ServerRouteHelper.api.pulses.team.report(ownerId, pulseId);

    return this.personalPulseReportData.load(url);
  };

  loadReportByToken = (pulseToken: string): Promise<void> => {
    const url = ServerRouteHelper.api.pulses.team.reportByToken(pulseToken);
    return this.personalPulseReportData.load(url);
  };
}

export default PulseReportStore;
