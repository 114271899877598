import React, { Component, ReactNode } from 'react';

import { Radio } from 'antd';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import styles from './HelpCard.module.scss';

import EnhancedCard from 'app/components/ui/EnhancedCard';
import CardFooter from 'app/components/ui/EnhancedCard/CardFooter';
import { MembershipType, MembershipTypeOptions } from 'app/constants';
import PulseHabitsContext from 'app/pages/dashboard/Pulse/Context/PulseHabitsContext';

export class HelpCard extends Component {
  static contextType = PulseHabitsContext;

  private get membership(): MembershipType {
    return this.context.uiStore.activePulse.membership;
  }

  @computed
  private get helpText(): string {
    return this.context.uiStore.helpText;
  }

  private get showModeSwitch(): boolean {
    return this.context.uiStore.hasParticipationMode;
  }

  @computed
  private get modeSwitch(): ReactNode {
    if (!this.showModeSwitch) {
      return null;
    }

    return (
      <div className={styles.modeSwitch}>
        <Radio.Group
          options={MembershipTypeOptions}
          onChange={(event) => this.context.uiStore.updateMembership(event.target.value)}
          value={this.membership}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
    );
  }

  render(): ReactNode {
    return (
      <EnhancedCard
        className={styles.helpCard}
        headerClassName={styles.helpCardHeader}
        heading={this.context.uiStore.helpCardTitle}
      >
        <div className="card-content-container">
          <div>{this.modeSwitch}</div>
          <p>{this.helpText}</p>
        </div>
        <CardFooter primaryButton={this.context.uiStore.helpCardFooterButton} />
      </EnhancedCard>
    );
  }
}

export default observer(HelpCard);
