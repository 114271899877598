import { computed, observable } from 'mobx';

import type { AlignStatementScore, DiscussionGuideModel } from 'app/models';

import allModels from './allModels';
export interface StatementNotesProps {
  statementID: number;
  order: number;
  alignStatement?: AlignStatementScore;
  guide: DiscussionGuideModel;
}

export class StatementNotesModel {
  @observable statementID: number;
  @observable order: number;
  @observable alignStatement?: AlignStatementScore;
  @observable guide: DiscussionGuideModel;

  constructor(props: StatementNotesProps) {
    this.statementID = props.statementID;
    this.guide = props.guide;
    this.order = props.order;
    this.alignStatement = props.alignStatement;
  }

  @computed get toJS() {
    return {
      order: this.order,
      statementID: this.statementID,
    };
  }
}

allModels.register({ StatementNotesModel });

export default StatementNotesModel;
