import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem } from 'app/models';
import MetabaseModel from 'app/models/MetabaseModel';
import Store from 'app/stores/Store';

export class MetabaseStore extends Store<MetabaseModel> {
  @observable public dashboard = new ModelItem<MetabaseModel>(MetabaseModel);

  constructor() {
    super();
    MetabaseModel._store = this;
  }

  public async loadDashboard(
    orgId: number,
    name: string,
    params?: Record<string, any>
  ): Promise<void> {
    params &&
      Object.keys(params).forEach((key) => {
        const value = encodeURIComponent(params[key]);
        // if value is not encoded, encode it
        if (params[key] && params[key] !== value) {
          params[key] = value;
        }
      });

    return this.dashboard.load(ServerRouteHelper.api.metabase.dashboard(orgId, name, params));
  }

  public async loadAdminDashboard(name: string, params?: Record<string, any>): Promise<void> {
    return this.dashboard.load(ServerRouteHelper.api.metabase.adminDashboard(name, params));
  }
}

export default MetabaseStore;
