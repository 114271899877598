import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { PerspectiveTeammateDiffStore } from 'app/stores';

import { MemberAvatarModel } from './MemberAvatarModel';

export class PerspectiveTeammateDiffModel extends Model {
  static _store: PerspectiveTeammateDiffStore;

  @observable id: number;
  @observable team_id: number;
  @observable name: string;
  @observable email: string;
  @observable letters: string;
  @observable avatar: MemberAvatarModel;
  @observable completed_perspective: boolean;
  @observable diffSum: number;
  @observable diffLabel: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectiveTeammateDiffModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PerspectiveTeammateDiffModel;
  }

  static get(id) {
    return this._get(id) as PerspectiveTeammateDiffModel;
  }
}

export default PerspectiveTeammateDiffModel;
