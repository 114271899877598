import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { ModelList } from 'app/models/ModelList';
import PerspectivePairScoreDiffStore from 'app/stores/PerspectivePairScoreDiffStore';

import MemberAvatarModel from './MemberAvatarModel';
import PerspectivePairScoreDiffDimensionModel from './PerspectivePairScoreDiffDimensionModel';

export class PerspectivePairScoreDiffModel extends Model {
  static _store: PerspectivePairScoreDiffStore;

  @observable id: number;
  @observable name: string;
  @observable avatar?: MemberAvatarModel;
  @observable scores = new ModelList<PerspectivePairScoreDiffDimensionModel>(
    PerspectivePairScoreDiffDimensionModel
  );

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectivePairScoreDiffModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PerspectivePairScoreDiffModel;
  }

  static get(id) {
    return this._get(id) as PerspectivePairScoreDiffModel;
  }
}

export default PerspectivePairScoreDiffModel;
