import { PerspectiveScoreDiffModel } from 'app/models';

import Store from './Store';

export class PerspectiveScoreDiffStore extends Store<PerspectiveScoreDiffModel> {
  constructor() {
    super();
    PerspectiveScoreDiffModel._store = this;
  }
}

export default PerspectiveScoreDiffStore;
