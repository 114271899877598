import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { AlignGuidanceModel } from 'app/models/AlignGuidanceModel';
import Model from 'app/models/Model';
import Store from 'app/stores/Store';

export class AlignGuidanceStore extends Store<Model> {
  @observable registry = observable.map();

  @action async loadStatementGuidance(exerciseTypeCode: string, statementID: number) {
    const cached = statementID in this.registry;

    if (cached) {
      return Promise.resolve();
    }
    const url = ServerRouteHelper.api.exerciseTypes.statement(exerciseTypeCode, statementID);
    const response = await this.apiService.get(url);
    this.registry.set(response.id, AlignGuidanceModel.fromJson({ html: response.guidance }));
  }

  getStatementGuidance(statementID: number) {
    return this.registry.get(statementID);
  }
}

export default AlignGuidanceStore;
