import React, { ReactNode } from 'react';

import { Alert, Button, Form, Input, message, Tabs } from 'antd';
import { autorun, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import numeral from 'numeral';
import { RouteComponentProps } from 'react-router-dom';

const { TabPane } = Tabs;

// import { ServerRouteHelper } from 'app/helpers';
import { STORE_ADMIN_ORG_CONTENT } from 'app/constants';
import AdminOrgContentItemModel from 'app/models/AdminOrgContentItemModel';
import AdminOrgContentModel from 'app/models/AdminOrgContentModel';
import AdminSimpleContentItemModel from 'app/models/AdminSimpleContentItemModel';
import { AdminOrgContentStore } from 'app/stores';

import OrgContentAddItems from './OrgContentAddItems';

export interface Params {
  orgId: string;
}

export interface OrgContentOrgItemsProps extends RouteComponentProps<Params> {
  adminOrgContentStore?: AdminOrgContentStore;
}

export class OrgContentOrgItems extends React.Component<OrgContentOrgItemsProps> {
  constructor(props: OrgContentOrgItemsProps) {
    super(props);

    autorun(() => this.loadOrg());
  }

  protected loadOrg(): void {
    this.props.adminOrgContentStore.getOrg(this.orgId);
    this.props.adminOrgContentStore.getOrgItems(this.orgId);
    this.props.adminOrgContentStore.getItemList();
  }

  get org(): AdminOrgContentModel {
    return this.props.adminOrgContentStore.activeOrg.item;
  }

  @computed
  get orgId(): number {
    return numeral(this.props.match.params.orgId).value();
  }

  @computed
  get contentItems(): AdminOrgContentItemModel[] {
    return this.props.adminOrgContentStore.contentItems.items;
  }

  @computed
  get availableItemsList(): AdminSimpleContentItemModel[] {
    const availableItems = this.props.adminOrgContentStore.defaultItems.items;
    const existingKeys = this.contentItems.map((item) => item.key);

    return availableItems.filter((item) => item.has_arguments || !existingKeys.includes(item.key));
  }

  /**
   * Update observable value when form input changes.
   */
  protected handleOnChange = (item: AdminOrgContentItemModel, event): void => {
    item.content = event.target.value;
  };

  /**
   * Submit editted values back to the server.
   */
  protected async handleUpdateItem(item: AdminOrgContentItemModel): Promise<void> {
    await this.props.adminOrgContentStore.updateItem(this.org, item);

    const { contentErrors } = this.props.adminOrgContentStore;

    if (contentErrors.length > 0) {
      message.error(
        <span>
          Content item <strong>"{item.key}"</strong> for <strong>"{this.org.name}"</strong> did not
          save
        </span>
      );

      return;
    }

    message.success(
      <span>
        Content item <strong>"{item.key}"</strong> for <strong>"{this.org.name}"</strong> saved
      </span>
    );
  }

  protected async handleDeleteItem(item: AdminOrgContentItemModel): Promise<void> {
    // Delete org item
    await this.props.adminOrgContentStore.deleteItem(this.org, item);

    message.success(
      <span>
        Content item <strong>"{item.key}"</strong> for <strong>"{this.org.name}"</strong> deleted
      </span>
    );
  }

  protected addNewItemHandler = async (item: AdminOrgContentItemModel): Promise<string> => {
    if (!item) {
      return;
    }

    // Add new item
    await this.props.adminOrgContentStore.addNewDefault(this.org, item);
  };

  protected handleItemSelectChange = () => {
    this.props.adminOrgContentStore.setAddErrors([]);
  };

  protected formEditTab(item: AdminOrgContentItemModel): ReactNode {
    const { contentErrors } = this.props.adminOrgContentStore;
    const fieldProps = { help: null, validateStatus: null };

    if (contentErrors.length > 0) {
      fieldProps.help = contentErrors.join('. ');
      fieldProps.validateStatus = 'error';
    }

    return (
      <TabPane tab={item.key} key={item.key}>
        <Form.Item label="Type">{item.type}</Form.Item>
        <Form.Item {...fieldProps}>
          <Input.TextArea
            value={item.content}
            rows={10}
            onChange={(e) => this.handleOnChange(item, e)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" ghost onClick={() => this.handleUpdateItem(item)} className="mr-2">
            Update
          </Button>

          <Button
            type="primary"
            danger
            ghost
            onClick={() => this.handleDeleteItem(item)}
            className="float-right"
          >
            Delete & Reset To Deafults
          </Button>
        </Form.Item>
      </TabPane>
    );
  }

  @computed
  get warningText(): ReactNode {
    return (
      <Alert
        className="mb-4"
        type="warning"
        showIcon
        message="Make sure all org item edits are fully tested. If possible, it's recommended to first test
        any edits on a QA server before saving on production."
      />
    );
  }

  @computed
  get hasItems(): boolean {
    return this.contentItems.length > 0;
  }

  @computed
  get addItems(): ReactNode {
    return (
      <OrgContentAddItems
        items={this.availableItemsList}
        addHandler={this.addNewItemHandler}
        onSelectedItemChange={this.handleItemSelectChange}
        errors={this.props.adminOrgContentStore.addErrors}
      />
    );
  }

  public render(): ReactNode {
    if (!this.org) {
      return 'Loading...';
    }

    if (this.org.id === null) {
      return (
        <p>
          No org found for ID <em>{this.orgId}</em>.
        </p>
      );
    }

    return (
      <div>
        {this.warningText}
        <h3>{this.org.name}</h3>
        {this.addItems}
        {!this.hasItems && <p>No overrides</p>}
        {this.hasItems && (
          <Tabs tabPosition="left">{this.contentItems.map((item) => this.formEditTab(item))}</Tabs>
        )}
      </div>
    );
  }
}

export default inject(STORE_ADMIN_ORG_CONTENT)(observer(OrgContentOrgItems));
