import React from 'react';

import { DownOutlined } from '@ant-design/icons';
import cx from 'classnames';

import { ServerRouteHelper } from 'app/helpers';
import { FullAlignReportUIStore } from 'app/pages/align/Report/FullAlignReport/FullAlignReportUIStore';
import My360ReportUIStore, { ReportTab } from 'app/pages/my360/Report/My360ReportUIStore';

export enum ScrollDirection {
  Up = 'scroll-up',
  Down = 'scroll-down',
}

interface TabbedReportNavigationItems {
  key: string;
  id: string;
  label: string;
  icon: string;
  theme: string;
  hash: string;
  order: number;
}

export interface TabbedReportNavigationProps {
  navItems: TabbedReportNavigationItems[];
  currentActiveSubRoute: string;
  uiStore: My360ReportUIStore | FullAlignReportUIStore;
  onNavItemClick: (key: string, hash?: string) => void;
}

export class TabbedReportNavigation extends React.Component<TabbedReportNavigationProps> {
  makeReportUrl = (path: string) => {
    path = path === ReportTab.DIGEST ? '' : path;

    return ServerRouteHelper.my360.report(this.props.uiStore.token, path);
  };

  render(): React.ReactNode {
    return (
      <>
        {this.props.navItems.map((navItem, i) => {
          const isActive = this.props.currentActiveSubRoute === navItem.key;

          return (
            <div
              className={cx('nav-item', `${navItem.theme}`, {
                active: isActive,
              })}
              onClick={() => {
                this.props.onNavItemClick(navItem.key, navItem.hash);
              }}
              key={`nav-item-${i}`}
              id={navItem.id}
            >
              <div className={cx('icon-wrapper', `${navItem.theme}`)}>{navItem.icon}</div>
              {navItem.label}
              <div
                className={cx('arrow-down-wrapper', `${navItem.theme}`, {
                  active: isActive,
                })}
              >
                <DownOutlined />
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default TabbedReportNavigation;
