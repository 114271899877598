import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import EngagementAnalyticsStore from 'app/stores/EngagementAnalyticsStore';

export interface EngagementAnalyticsCounter {
  label: string;
  type: string;
  value: number;
}

export interface EngagementAnalyticsTimeSeries {
  label: string;
  type: string;
  value: { month: string; value: number }[];
}

export class EngagementAnalyticsModel extends Model {
  static _store: EngagementAnalyticsStore;

  @observable licenses: EngagementAnalyticsCounter[];
  @observable counters: EngagementAnalyticsCounter[];
  @observable time_series: EngagementAnalyticsTimeSeries[];

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as EngagementAnalyticsModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as EngagementAnalyticsModel;
  }

  static get(id) {
    return this._get(id) as EngagementAnalyticsModel;
  }
}

export default EngagementAnalyticsModel;
