// Node modules
import { observable } from 'mobx';
import moment, { Moment } from 'moment';

// App
import { DATE_TIME_FORMAT } from 'app/constants';
import Model, { ModelJson } from 'app/models/Model';
import ProgramStore from 'app/stores/ProgramStore';

import TeamModel from './TeamModel';

export enum ProgramType {
  PERSPECTIVE = 'perspective',
  ALIGN = 'team_exercise',
}

// Map ProgramType to the name of the program
export const ProgramTypeName = {
  [ProgramType.PERSPECTIVE]: 'Perspective',
  [ProgramType.ALIGN]: 'Align',
};

export enum ProgramInitiative {
  PILOT = 'pilot',
  PROGRAM = 'program',
}

export interface ProgramLink {
  title: string;
  url: string;
}

export enum ProgramStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
}

/**
 * ProgramModel is used to represent OnboardingLinks with initiative = Program.
 */
export class ProgramModel extends Model {
  static _store: ProgramStore;

  @observable public id: number;
  @observable public name: string;
  @observable public team: TeamModel;
  @observable public shared_link: string;
  @observable public webinar_date: Moment;
  @observable public type: ProgramType;
  @observable public title: string;
  @observable public description: string;
  @observable public cta_text: string;
  @observable public initiative: ProgramInitiative;
  @observable public status: ProgramStatus;
  @observable public created_at: string;
  @observable public team_id?: number;
  @observable public links: ProgramLink[];
  @observable public exercise_type_id: number;

  deserialize_webinar_date(webinar_date) {
    this.webinar_date = moment(webinar_date, DATE_TIME_FORMAT.ISO_LOCAL_DATE_TIME);
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as ProgramModel;
  }
}

export default ProgramModel;
