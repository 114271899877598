import { observable, ObservableMap } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import ContentSectionStore from 'app/stores/ContentSectionStore';

export class ContentSectionModel extends Model {
  public static _store: ContentSectionStore;

  @observable public id: number;
  @observable public section_name: string;
  @observable public type: string;
  @observable public content: ObservableMap;
  @observable public organization_id: number;
  @observable public enabled: boolean;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as ContentSectionModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as ContentSectionModel;
  }

  static get(id) {
    return this._get(id) as ContentSectionModel;
  }
}

export default ContentSectionModel;
