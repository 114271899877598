import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { RoleStore } from 'app/stores/RoleStore';

export enum OrgRole {
  Regular = 'regular',
  OrgTeamManager = 'org-team-manager',
  OrgHrbp = 'org-hrbp',
  Admin = 'administrator',
  Coach = 'coach',
}

export const OrgRoleLabels = {
  [OrgRole.Regular]: 'Regular',
  [OrgRole.OrgTeamManager]: 'Organization Team Manager',
  [OrgRole.OrgHrbp]: 'HRBP',
  [OrgRole.Admin]: 'Administrator',
  [OrgRole.Coach]: ' Coach',
};

export const ShortenedOrgRoleLabels = {
  [OrgRole.Regular]: 'Member',
  [OrgRole.OrgHrbp]: 'HRBP',
  [OrgRole.OrgTeamManager]: 'Manager',
  [OrgRole.Admin]: 'Admin',
  [OrgRole.Coach]: ' Coach',
};

export class OrganizationRoleModel extends Model {
  static _store: RoleStore;
  @observable id: number;
  @observable name: string;
  @observable icon_class?: string;
  @observable public_default?: number;
  @observable members_count?: number;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as OrganizationRoleModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as OrganizationRoleModel;
  }

  static get(id) {
    return this._get(id) as OrganizationRoleModel;
  }
}

export default OrganizationRoleModel;
