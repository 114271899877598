import React, { Fragment, ReactElement } from 'react';

import { Tooltip } from 'antd';
import cx from 'classnames';

import './ScoreBar.scss';

import { RED } from 'app/constants';

const MARKER_HALF_WIDTH = '0.5rem';

export interface ScoreBarProps {
  onClick?: () => void;
  className?: string;
  circleLabel?: string;
  circleValue?: number;
  circleColor?: string;
  circleTooltip?: string;
  circleClassName?: string;
  diamondLabel?: string;
  diamondValue?: number;
  pins?: { value: number; label: string }[];
  otherBenchmarks?: { value: number; color?: string; marker: ReactElement; tooltip?: string }[];
  showArrow?: boolean;
}

// TODO: clean this up!
export class ScoreBar extends React.Component<ScoreBarProps> {
  boundValue = (value: number, min = 0.0, max = 100.0) => {
    if (value < min) {
      return min;
    }
    if (value > max) {
      return max;
    }

    return value;
  };

  drawProgressLine = (diamondValue, circleValue) => {
    let score1, score2, arrowDirection;
    const LineLeftMargin = 2.5;
    const LineRightMargin = 1.5;
    if (circleValue < diamondValue) {
      score1 = circleValue;
      score2 = diamondValue;
      arrowDirection = 'left';
    } else {
      score1 = diamondValue;
      score2 = circleValue;
      arrowDirection = 'right';
    }
    const positionLeft = this.boundValue(score1) + LineLeftMargin;
    const positionRight = this.boundValue(score2) - LineRightMargin;
    const lineWidth =
      this.boundValue(score2) - this.boundValue(score1) - (LineLeftMargin + LineRightMargin);

    return (
      <div
        className="progress-line"
        style={{
          left: `${positionLeft}%`,
          right: `${positionRight}%`,
          width: `${lineWidth > 0 ? lineWidth : 0}%`,
        }}
      >
        <div className={`arrow ${arrowDirection}`}></div>
      </div>
    );
  };

  render() {
    const {
      className,
      circleValue,
      circleLabel,
      circleColor,
      circleTooltip,
      circleClassName,
      diamondLabel,
      diamondValue,
      showArrow,
      onClick,
      pins,
      otherBenchmarks,
    } = this.props;

    return (
      <div
        onClick={onClick}
        className={cx(
          'score-bar-container',
          { 'no-label-up': !(circleLabel && diamondValue) },
          { 'no-pins': !pins },
          className
        )}
        data-testid="score-bar-container"
      >
        <div className="line">
          {!!circleValue && (
            <Tooltip title={circleTooltip}>
              <div
                className={cx('circle', circleClassName)}
                style={{
                  left: `calc(${this.boundValue(circleValue)}% - ${MARKER_HALF_WIDTH})`,
                  background: circleColor || RED,
                }}
              >
                <span className="label-up">{circleLabel}</span>
              </div>
            </Tooltip>
          )}

          {!!diamondValue && (
            <div
              className={'legend-marker circle mx-2'}
              style={{
                left: `calc(${this.boundValue(diamondValue)}% - ${MARKER_HALF_WIDTH})`,
                border: `1px solid ${circleColor}`,
                background: 'white',
              }}
            >
              <span className="label-up">{diamondLabel}</span>
            </div>
          )}

          {!!diamondValue && showArrow && this.drawProgressLine(diamondValue, circleValue)}

          {otherBenchmarks &&
            otherBenchmarks.map(
              (otherBenchmark, i) =>
                !isNaN(otherBenchmark.value) && (
                  <Fragment key={i}>
                    <Tooltip title={otherBenchmark.tooltip}>
                      {React.cloneElement(otherBenchmark.marker, {
                        style: {
                          left: `calc(${this.boundValue(
                            otherBenchmark.value
                          )}% - ${MARKER_HALF_WIDTH})`,
                        },
                      })}
                    </Tooltip>
                  </Fragment>
                )
            )}

          {pins &&
            pins.map((pin, index) => (
              <div
                className="vertical-line"
                style={{ left: `${this.boundValue(pin.value)}%` }}
                key={index}
              >
                <span className="label-down">{pin.label}</span>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default ScoreBar;
