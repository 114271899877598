import TeamGroupDivisionModel from 'app/models/TeamGroupDivisionModel';

import Store from './Store';

export class TeamGroupDivisionStore extends Store<TeamGroupDivisionModel> {
  constructor() {
    super();
    TeamGroupDivisionModel._store = this;
  }
}

export default TeamGroupDivisionStore;
