import React, { ReactNode } from 'react';

import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import styles from './InviteModal.module.scss';

import NewMemberForm from 'app/components/features/NewMemberForm';
import AntModal from 'app/components/ui/AntModal';
import { ModalAction } from 'app/components/ui/Modal/Modal';
import SelectableTable from 'app/components/ui/SelectableTable';
import { Row } from 'app/components/ui/SelectableTable/SelectableTable';
import PulseHabitsContext from 'app/pages/dashboard/Pulse/Context/PulseHabitsContext';

export class InviteModal extends React.Component {
  static contextType = PulseHabitsContext;

  private get headers(): string[] {
    return ['Name', 'Email'];
  }

  @computed
  private get rows(): Row[] {
    return this.context.uiStore.membersToInvite.map((member) => {
      const columns: string[] = [member.name, member.email];

      return {
        id: `invite-member-row-${member.id}`,
        isSelected: member.isSelected,
        columns,
        member,
      };
    });
  }

  private get modalAction(): ModalAction {
    return {
      label: 'Update colleagues',
      onClick: this.context.uiStore.toggleInviteModal,
    };
  }

  private get isLoadingMembers(): boolean {
    return this.context.uiStore.isLoadingMembers;
  }

  private get modalTitle(): string {
    return this.context.uiStore.inviteModalTitle ?? 'Personal habits: Add colleagues';
  }

  private get modalHeading(): string {
    return this.context.uiStore.inviteModalHeading;
  }

  @computed
  get searchInput(): ReactNode {
    return (
      <Input
        allowClear
        className={styles.searchInput}
        placeholder="Filter by name"
        defaultValue={this.context.uiStore.participantSearchQuery}
        suffix={this.isLoadingMembers ? <LoadingOutlined /> : <SearchOutlined />}
        onChange={(event) => this.handleFilterMembers(event.target.value)}
      />
    );
  }

  private handleFilterMembers = debounce((searchText) => {
    this.context.uiStore.searchParticipants(searchText);
  }, 1000);

  private handleLoadMore = debounce(() => {
    this.context.uiStore.loadMoreParticipants();
  }, 1000);

  public render(): ReactNode {
    return (
      <AntModal
        title={<h6>{this.modalTitle}</h6>}
        isOpen={this.context.uiStore.isInviteModalOpen}
        onToggle={this.context.uiStore.toggleInviteModal}
        primaryAction={this.modalAction}
      >
        <h4>{this.modalHeading}</h4>

        <p>Add missing members, send invites.</p>

        <NewMemberForm
          emailPlaceholder="Email (Required)"
          namePlaceholder="User full name..."
          addCta="Add"
          buttonClass="btn-primary"
          onAddMember={this.context.uiStore.handleAddNewMember}
          showIcons
        />

        {this.searchInput}

        <SelectableTable
          id="invite-members-select-table"
          headers={this.headers}
          rows={this.rows}
          onSelectAll={this.context.uiStore.handleSelectAll}
          onRowSelect={this.context.uiStore.handleRowSelect}
          onLoadMore={this.handleLoadMore}
        />
      </AntModal>
    );
  }
}

export default observer(InviteModal);
