export enum MemberRoleReported {
  Manager = 'manager',
  Member = 'member',
  Facilitator = 'facilitator',
}

export enum MemberOnboardingRole {
  ManagerMultiple = 'manager_multiple',
  LeadSingleTeam = 'lead_single_team',
  FacilitatorOrCoach = 'facilitator_or_coach',
  IndividualContributor = 'individual_contributor',
}

export const OnboardingRoleToReportedRole = {
  [MemberOnboardingRole.ManagerMultiple]: MemberRoleReported.Manager,
  [MemberOnboardingRole.LeadSingleTeam]: MemberRoleReported.Manager,
  [MemberOnboardingRole.FacilitatorOrCoach]: MemberRoleReported.Facilitator,
  [MemberOnboardingRole.IndividualContributor]: MemberRoleReported.Member,
};
