import React, { ReactNode, useState } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import cx from 'classnames';

import './ExerciseStatementCardList.scss';

import { ExerciseStatementCardProps } from 'app/components/features/ExerciseStatementCard/ExerciseStatementCard';
import { ExerciseCardListView } from 'app/constants';
import { AlignModel } from 'app/models';

import AllStatementsModal from '../AllStatementsModal';
import CardCarouselView from '../CardCarouselView';
import CardGridView from '../CardGridView';
import CardListActions from '../CardListActions';

export interface ExerciseStatementCardListProps {
  view?: ExerciseCardListView;
  title?: React.ReactElement;
  statements: ExerciseStatementCardProps[];
  onReset: () => void;
  onSubmit: () => void;
  onDragArrowDoubleClick: () => void;
  onLearnMoreClick: () => void;
  renderTestDriveView?: boolean;
  exercise: AlignModel;
  exerciseIs360?: boolean;
  isCC?: boolean;
}

export const ExerciseStatementCardList: React.FC<ExerciseStatementCardListProps> = ({
  view = ExerciseCardListView.REGULAR,
  title = '',
  statements,
  onReset,
  onSubmit,
  onDragArrowDoubleClick,
  onLearnMoreClick,
  renderTestDriveView,
  exerciseIs360,
  isCC,
  exercise,
}) => {
  const [arrowClickCount, setArrowClickCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const heading = exerciseIs360
    ? 'Drag the cards onto the grid'
    : 'Drag the cards onto the grid to rate your team';

  const withSubHeading = exerciseIs360 && !exercise.member?.isCurrentMember;

  const subHeading = withSubHeading ? `You're rating ${exercise.member.name}.` : '';

  const handleArrowClick = () => {
    if (arrowClickCount === 0) {
      setArrowClickCount(arrowClickCount + 1);
      return;
    }
    setArrowClickCount(0);
    onDragArrowDoubleClick();
  };

  const rightArrow = (): ReactNode => {
    return (
      <>
        {(!renderTestDriveView || !exerciseIs360) && (
          <span className="icon-container" id="double-click-auto-drag" onClick={handleArrowClick}>
            <ArrowRightOutlined data-testid="drag-right-arrow" />
          </span>
        )}
      </>
    );
  };

  const cardTitle = (): ReactNode => {
    return (
      <div className="card-title">
        {!isCC && (
          <img
            className="image"
            src="/images/align/align-exercise-scatterplot-icon.svg"
            id="double-click-auto-drag"
            onClick={handleArrowClick}
          />
        )}
        <div className="right-section">
          <p className="header">
            {heading} {rightArrow()}
          </p>
          {subHeading && <p className="sub-header">{subHeading}</p>}
        </div>
      </div>
    );
  };

  const renderCards = (): ReactNode => {
    switch (view) {
      case ExerciseCardListView.REGULAR:
        return <CardGridView statements={statements} />;
      case ExerciseCardListView.DETAILED:
        return (
          <CardCarouselView
            statements={statements}
            onSeeAllStatements={toggleModal}
            onSubmit={onSubmit}
            renderTestDriveView={renderTestDriveView}
            render360View={exerciseIs360}
          />
        );
    }
  };

  const noStatementsPlaced = statements.filter((statement) => !statement.placed).length > 0;
  const isSubmitDisabled = noStatementsPlaced || (exerciseIs360 && renderTestDriveView);

  const renderCardActions = (): ReactNode => {
    return (
      <CardListActions
        onLearnMoreClick={onLearnMoreClick}
        onReset={onReset}
        onSubmit={onSubmit}
        isSubmitDisabled={isSubmitDisabled}
        renderTestDriveView={renderTestDriveView}
        render360View={exerciseIs360}
        exercise={exercise}
      />
    );
  };

  return (
    <div className="exercise-statement-card-list">
      <div className={cx('outer-title', { 'cc-title': isCC })}>{title}</div>
      <div className={cx('main', { 'cc-card': isCC })}>
        {cardTitle()}
        {renderCards()}
        {renderCardActions()}
        <AllStatementsModal statements={statements} isOpen={showModal} onToggle={toggleModal} />
      </div>
    </div>
  );
};

export default ExerciseStatementCardList;
