import React, { ReactNode } from 'react';

import { Steps } from 'antd';

import './BulletedLayout.scss';

const { Step } = Steps;

export interface BulletedLayoutProps {
  title: string;
  items: (string | ReactNode)[];
  ordered?: boolean;
}

export const BulletedLayout: React.FC<BulletedLayoutProps> = ({ title, items, ordered }) => {
  return (
    <div className="bulleted-layout">
      <h3 className="bulleted-layout-title">{title}</h3>
      <Steps
        className="bulleted-layout-items"
        direction="vertical"
        progressDot={!ordered}
        size={ordered ? 'small' : null}
      >
        {items.map((item, i) => (
          <Step key={i} title={item} status="process" />
        ))}
      </Steps>
    </div>
  );
};

export default BulletedLayout;
