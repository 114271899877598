import React from 'react';

import MemberAvatar from 'app/components/ui/MemberAvatar';
import { MemberModel, ShortenedOrgRoleLabels } from 'app/models';

interface MenuLinkProps {
  styles: any;
  activeMember: MemberModel;
}

const UserProfile: React.FC<MenuLinkProps> = ({ activeMember, styles }) => {
  return (
    <div className={styles.memberMenuItemContainer}>
      <MemberAvatar {...activeMember.avatar} />
      <div className={styles.content}>
        <label className={styles.name}>{activeMember.name}</label>
        <span>
          <label className={styles.label}>{ShortenedOrgRoleLabels[activeMember.role]}</label>
          &middot;
          <label className={styles.label}>My Profile</label>
        </span>
      </div>
    </div>
  );
};

export default UserProfile;
