import { action, computed, observable } from 'mobx';

import { CookiePrefOptions } from 'app/constants';
import type { OrganizationModel, TeamModel } from 'app/models';
import Model, { ModelJson } from 'app/models/Model';
import { ModelList } from 'app/models/ModelList';
import { MemberStore } from 'app/stores/MemberStore';

import allModels from './allModels';
import EmailActivityModel from './EmailActivityModel';
import MemberAvatarModel from './MemberAvatarModel';
import { PairViewAccess } from './PerspectiveModel';

export interface ContractLicense {
  id: number;
  created_at: string;
  contract: {
    id: number;
    organization_id: number;
    start_date: string;
    end_date: string;
  };
}

export interface Subscription {
  buyer: string;
  start_date: string;
  end_date: string;
}

/**
 * Define the properties that are passed in to create members
 */
export interface MemberProps {
  id: number;
  name: string;
  avatar: MemberAvatarModel;
  teams?: ModelList<TeamModel>;
  code?: string;
  email?: string;
  role?: string;
  is_admin?: boolean;
  is_manager?: boolean;
  last_login_method?: string;
  organizations?: ModelList<OrganizationModel>;
  coachedMembers?: ModelList<MemberModel>;
  created_at?: string;
  canManageAlign?: boolean;
  canCoachAlign?: boolean;
  canSeeOrgReportsWithMemberRole?: boolean;
  canSeeGlobalPulses?: boolean;
  canManageWithMemberRole?: boolean;
  canManageInternal?: boolean;
  canManageOrg?: boolean;
  canAdmin?: boolean;
  currentMemberCanCoach?: boolean;
  pair_perspective_access?: PairViewAccess;
  membershipLevel?: string;
  orgRole?: string;
  isDeleted?: boolean;
  hasPerspective?: boolean;
  hasSinglePlayerAlign?: boolean;
  isCurrentMember?: boolean;
  activeLicense?: ContractLicense;
  perspective_url?: string;
  has_paired?: boolean;
  cookie_pref?: CookiePrefOptions;
  show_member_reactivation_marquee_card?: boolean;
  managed_team_count?: number;
  anonymized_by_id?: number;
  is_session_login?: boolean;
  member_role?: string;
  subscription?: Subscription;
}

/**
 * MemberModel is used to represent Members
 */
export class MemberModel extends Model {
  static _store: MemberStore;

  readonly id: number;
  @observable name: string;
  @observable email: string;
  @observable avatar: MemberAvatarModel;
  @observable role: string;
  @observable code: string;
  @observable isParticipant?: boolean;
  @observable pair_perspective_access?: PairViewAccess;
  @observable is_admin?: boolean;
  @observable is_manager?: boolean;
  @observable created_at?: string;
  @observable tracking_id?: string;
  @observable hasEnabledPerspectiveCalendar?: boolean;

  // For changing password
  @observable currentPassword: string;
  @observable password: string;
  @observable passwordConfirm: string;

  @observable canManageAlign?: boolean;
  @observable canCoachAlign?: boolean;
  @observable canSeeOrgReportsWithMemberRole?: boolean;
  @observable canSeeGlobalPulses?: boolean;
  @observable canManageWithMemberRole?: boolean;
  @observable canManageInternal?: boolean;
  @observable canManageOrg?: boolean;
  @observable canAdmin?: boolean;
  @observable currentMemberCanCoach?: boolean;
  @observable membershipLevel?: string;
  @observable orgRole?: string;
  @observable member_role?: string;

  @observable role_self_reported: string;
  @observable is_actual_role: string;
  @observable hasPerspective?: boolean;
  @observable hasDonePairPerspective?: boolean;
  @observable hasSinglePlayerAlign?: boolean;
  @observable mbti_letters?: string;
  @observable mbti_archetype?: string;
  @observable perspective_url?: string;
  @observable lastEmailInvite?: EmailActivityModel;
  @observable activeLicense?: ContractLicense;
  @observable last_login_method?: string;
  @observable align_perspective_profile?: string;

  @observable teams = new ModelList<TeamModel>(allModels.TeamModel);
  @observable organizations = new ModelList<OrganizationModel>(allModels.OrganizationModel);
  @observable coachedMembers = new ModelList<MemberModel>(MemberModel);

  @observable isCurrentMember: boolean;
  @observable habitsCount?: number;
  @observable habitProgress?: any;
  @observable habitAvgScore?: number;
  @observable accepted_current_policy?: boolean;
  @observable cookie_pref?: CookiePrefOptions;
  @observable show_member_reactivation_marquee_card?: boolean;
  @observable is_session_login?: boolean;

  @observable org_code?: string;
  @observable anonymizeMember = false;
  @action setAnonymizeMember = (anonymizeMember) => (this.anonymizeMember = anonymizeMember);

  @observable public status?: string; // on courses, this holds pivot status
  @observable has_paired?: boolean = false;

  @observable managed_team_count?: number = 0;

  @observable anonymized_by_id?: number;
  @observable subscription?: Subscription;

  @observable active_subscriptions?: Subscription[];

  @action
  setAvatar = (avatar) => (this.avatar = avatar);

  @action
  setLastEmailInvite = (lastEmailInvite) => (this.lastEmailInvite = lastEmailInvite);

  @computed
  get personalOrganization(): OrganizationModel {
    return this.organizations.items[0];
  }

  @computed
  get hasOrgWidePairAccess(): boolean {
    return this.pair_perspective_access === PairViewAccess.Organization;
  }

  deserialize_avatar(avatar: any) {
    this.setAvatar(MemberAvatarModel.fromJson(avatar));
  }

  deserialize_last_email_invite(lastEmailInvite: EmailActivityModel): void {
    this.setLastEmailInvite(EmailActivityModel.fromJson(lastEmailInvite));
  }

  get firstName() {
    return this.name?.split(' ')[0];
  }

  get isAdmin() {
    return this.role === 'administrator';
  }

  get isCoach() {
    return this.role === 'coach';
  }

  get lastNames() {
    return this.name?.split(' ').slice(1).join(' ');
  }

  asJSON() {
    const { name, email, avatar, role_self_reported } = this;
    return {
      name,
      email,
      avatar,
      role_self_reported,
    };
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as MemberModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as MemberModel;
  }

  static get(id) {
    return this._get(id) as MemberModel;
  }
}

allModels.register({ MemberModel });

export default MemberModel;
