import React, { ReactNode } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import cx from 'classnames';
import { computed } from 'mobx';

import { makeSafeForCSS } from 'app/helpers/Utils';
import { MemberModel, MenuItemModel, MenuItemTypes, OrganizationModel } from 'app/models';
import { MemberStore, OrganizationStore } from 'app/stores';

import { UserActiveOrgComponent, UserProfile } from '../MenuItemComponents';
import MenuLink from '../MenuLink';

/**
 * A Menu Item rendering service UI Store.
 *
 * Only components require active member and handleGoToOrg.
 */
export class MenuItemUiStore {
  protected styles: any;
  protected memberStore: MemberStore;
  protected organizationStore: OrganizationStore;
  protected handleGoToOrg?: (org: OrganizationModel) => void;

  constructor(
    styles: any,
    memberStore?: MemberStore,
    organizationStore?: OrganizationStore,
    handleGoToOrg?: (org: OrganizationModel) => void
  ) {
    this.styles = styles;
    this.memberStore = memberStore;
    this.organizationStore = organizationStore;
    this.handleGoToOrg = handleGoToOrg;
  }

  @computed
  get activeMember(): MemberModel {
    return this.memberStore.currentMember.item;
  }

  @computed
  get activeOrg(): OrganizationModel {
    return this.organizationStore?.organization.item;
  }

  canRender(item: MenuItemModel): boolean {
    return item.type !== MenuItemTypes.Declared;
  }

  makeMenuItems(items: MenuItemModel[]): ReactNode[] {
    return items
      .filter((item: MenuItemModel) => this.canRender(item))
      .map((item: MenuItemModel, index: number) => {
        return this.makeMenuItem(item, index);
      });
  }

  makeMenuItem(item: MenuItemModel, index: number): ReactNode {
    const key = item.id;

    if (item.type == MenuItemTypes.Heading) {
      return this.makeMenuHeading(item, key);
    }

    if (item.type == MenuItemTypes.Group) {
      return this.makeMenuGroup(item, key);
    }

    if (item.type == MenuItemTypes.Link) {
      return this.makeMenuLink(item, key);
    }

    if (item.type == MenuItemTypes.Component) {
      return this.makeComponent(item, key, index);
    }

    // Default to link if no type is match
    console.warn('Menu item built with missing type');
    return this.makeMenuLink(item, key);
  }

  makeMenuHeading(item: MenuItemModel, key: string): ReactNode {
    const itemKey = `menu-heading-${key}`;
    const children = this.makeMenuItems(item.children);
    const cssClass = makeSafeForCSS(itemKey);

    return (
      <Menu.ItemGroup key={itemKey} className={cx(cssClass, this.styles.menuHeadingGroup)}>
        <Menu.Item key={key} className={this.styles.menuHeading}>
          <h3>{item.name}</h3>
        </Menu.Item>
        {children}
      </Menu.ItemGroup>
    );
  }

  makeMenuGroup(item: MenuItemModel, key: string): ReactNode {
    const itemKey = `menu-group-${key}`;
    const children = this.makeMenuItems(item.children);
    const cssClass = makeSafeForCSS(itemKey);

    const label = (
      <a className={cx('menu-item-link', this.styles.groupLink, this.styles.menuItemLink)}>
        {item.name}
        <RightOutlined />
      </a>
    );

    return (
      <Menu.SubMenu
        key={itemKey}
        title={label}
        className={cx(cssClass, this.styles.menuGroup)}
        icon={null}
      >
        {children}
      </Menu.SubMenu>
    );
  }

  makeMenuLink(item: MenuItemModel, key: string): ReactNode {
    const itemKey = `menu-link-${key}`;
    const attributes = item.in_new_window ? { target: '_blank' } : {};
    const cssClass = makeSafeForCSS(itemKey);

    return (
      <Menu.Item key={itemKey} className={cx(cssClass, this.styles.menuItem)}>
        <MenuLink
          menuItem={item}
          className={cx('menu-item-link', this.styles.menuLink, this.styles.menuItemLink)}
          {...attributes}
        >
          {item.name}
        </MenuLink>
      </Menu.Item>
    );
  }

  makeUserActiveOrgComponent(_item: MenuItemModel, key: string): ReactNode {
    const itemKey = `component-${key}`;
    const cssClass = makeSafeForCSS(itemKey);

    return (
      <Menu.ItemGroup
        key={itemKey}
        title="Organization"
        className={cx(cssClass, this.styles.orgDropdown)}
      >
        <UserActiveOrgComponent
          styles={this.styles}
          activeOrg={this.activeOrg}
          activeMember={this.activeMember}
          handleGoToOrg={this.handleGoToOrg}
        />
      </Menu.ItemGroup>
    );
  }

  makeUserProfileComponent(item: MenuItemModel, key: string): ReactNode {
    const itemKey = `component-${key}`;
    const styles = this.styles;
    const cssClass = makeSafeForCSS(itemKey);

    return (
      <Menu.Item key={itemKey} className={cssClass}>
        <MenuLink menuItem={item} className={(styles.userMenu, styles.profileMenuItem)}>
          <UserProfile activeMember={this.activeMember} styles={styles} />
        </MenuLink>
      </Menu.Item>
    );
  }

  makeMenuDivider(item: MenuItemModel, key: string, index: number): ReactNode {
    return <Menu.Divider key={`${index}-${key}`} />;
  }

  makeComponent(item: MenuItemModel, key: string, index: number): ReactNode {
    if (!this.activeMember || !this.handleGoToOrg) {
      console.warn('Attempting to render a component without a active member or go to org handler');
    }

    const components = {
      user_active_org: (item: MenuItemModel, key: string): ReactNode => {
        return this.makeUserActiveOrgComponent(item, key);
      },
      user_profile: (item: MenuItemModel, key: string): ReactNode => {
        return this.makeUserProfileComponent(item, key);
      },
      menu_divider: (item: MenuItemModel, key: string): ReactNode => {
        return this.makeMenuDivider(item, key, index);
      },
    };

    const componentCallbak = components[item.name];
    return componentCallbak(item, key, index);
  }

  makeItem(menuItem: MenuItemModel, index: number): ReactNode {
    if (!this.canRender(menuItem)) {
      return null;
    }

    return this.makeMenuItem(menuItem, index);
  }
}

export default MenuItemUiStore;
