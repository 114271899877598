import { action, computed } from 'mobx';

import { MembershipType } from 'app/constants';
import { MemberModel } from 'app/models';

import fakeDefaultReminder from '../../../Pulse/mocks/default_reminder.json';
import fakeTemplates from '../../../Pulse/mocks/personal_template_groups.json';
import PersonalHabitsUIStore, { PersonalHabitsUIStoreProps } from './PersonalHabitsUIStore';

export interface PersonalHabitsTestDriveUIStoreProps extends PersonalHabitsUIStoreProps {
  pulseBasePath: string;
}

export default class PersonalHabitsTestDriveUIStore extends PersonalHabitsUIStore {
  pulseBasePath: string;

  constructor(props: PersonalHabitsTestDriveUIStoreProps) {
    super(props);

    this.pulseBasePath = props.pulseBasePath;
  }

  @computed
  protected get hasMoreParticipants(): boolean {
    return false;
  }

  public get isLoadingMembers(): boolean {
    return false;
  }

  protected get testPeer(): MemberModel {
    return MemberModel.fromJson({
      id: 100,
      name: 'Whitney Reader',
      email: 'whitney@acme.org',
      avatar: {
        text: 'WR',
        color: 'gold',
      },
    });
  }

  @action
  protected searchMembers = async (): Promise<void> => {
    const selected = !!this.loadedParticipantsById?.[this.testPeer.id];
    this.allMembersCache = [...this.allMembersCache, this.makeMemberItem(this.testPeer, selected)];
  };

  @action
  protected populateMembersToInvite = (): void => {
    this.membersToInvite = [this.allMembersCacheById[this.testPeer.id]];
  };

  public load = async (): Promise<any> => {
    this.loadFakeTemplates();
    this.loadFakeDefaultReminder();
    this.setActivePulseReminders();

    return this.searchParticipants();
  };

  @action
  public savePulse = async (): Promise<any> => {
    this.activePulse.membership = MembershipType.Solo;
    this.activePulse.owner.setItem(this.currentMember);
    this.activePulse.statements.setItems(
      this.activePulse.statements.items.filter((statement) => statement.text !== '')
    );
  };

  public updateMembership = (membership: MembershipType): void => {
    const participants =
      membership === MembershipType.Group ? this.selectedMembersCache : [this.pulseOwner];
    this.setParticipants(participants);

    this.activePulse.updateFromJson({ membership });
  };

  @action
  public updateParticipants = async (): Promise<any> => {
    const pulseParticipants = this.participants.map((participant) =>
      MemberModel.fromJson(participant.toJSON())
    );

    this.activePulse.participants.setItems(pulseParticipants);
  };

  public saveReminder = async (): Promise<any> => {
    const reminder = this.pulseReminderStore.activeReminder;
    this.activePulse.reminders.setItems([reminder]);
  };

  public launchHabit = async (): Promise<any> => {
    // Override to not save in DB
  };

  public getStepUrl(step: string): string {
    return `${this.pulseBasePath}/${step}`;
  }

  public get launchHabitUrl(): string {
    return this.getStepUrl('listing');
  }

  public get inviteModalHeading(): string {
    return 'Test Organization members';
  }

  protected loadFakeTemplates = (): void => {
    this.pulseTemplateGroupStore.templateGroups.deserialize(fakeTemplates.data);
  };

  protected loadFakeDefaultReminder = (): void => {
    this.pulseReminderStore.reminders.deserialize(fakeDefaultReminder.data.reminders);
    this.pulseReminderStore.setOptions(fakeDefaultReminder.data.options as any);
  };

  protected setActivePulseReminders = (): void => {
    this.activePulse.reminders.deserialize(fakeDefaultReminder.data.reminders);
  };
}
