import React, { Component, ReactElement, ReactNode } from 'react';

import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Radio, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import cx from 'classnames';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import styles from './HabitItem.module.scss';

import PulseStatement from 'app/components/features/PulseStatement';
import { PulseStatementOptions, PulseStatementOptionsNames } from 'app/constants';
import { PulseStatementModel } from 'app/models';

interface HabitItemProps {
  habit: PulseStatementModel;
  onDelete: (habit: PulseStatementModel) => void;
  isEditing?: boolean;
}

export class HabitItem extends Component<HabitItemProps> {
  @observable currentStatementType = PulseStatementOptionsNames[this.props.habit.statement_type];
  @action setCurrentStatementType = (statementType: string) =>
    (this.currentStatementType = statementType);

  handleChange = (text: string) => this.props.habit.updateFromJson({ text });

  handleRadioChange = (event) => {
    const value = event.target.value;
    this.setCurrentStatementType(PulseStatementOptionsNames[value]);
    this.props.habit.statement_type = value;
  };

  get habitTypeMenu(): ReactElement {
    return (
      <Menu>
        <Menu.Item key={0}>Change Type</Menu.Item>
        <Menu.Divider />
        <Radio.Group onChange={this.handleRadioChange} value={this.props.habit.statement_type}>
          <Space direction="vertical">
            {PulseStatementOptions.map((option) => (
              <Menu.Item key={option.type}>
                <Radio value={option.type}>{option.name}</Radio>
              </Menu.Item>
            ))}
          </Space>
        </Radio.Group>
      </Menu>
    );
  }

  get disableTypeDropdown(): boolean {
    return this.props.isEditing && !this.props.habit.is_new;
  }

  render(): ReactNode {
    return (
      <div className={styles.habitItem}>
        <div className={styles.habitHeader}>
          <div className={styles.habitOptions}>
            <h6>Habit type:</h6>
            <Dropdown
              overlay={this.habitTypeMenu}
              trigger={['click']}
              disabled={this.disableTypeDropdown}
              className={cx({ [styles.disabledDropDown]: this.disableTypeDropdown })}
            >
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                {this.currentStatementType} <DownOutlined />
              </a>
            </Dropdown>
          </div>
          <Button
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={() => this.props.onDelete(this.props.habit)}
          />
        </div>
        <TextArea
          rows={2}
          value={this.props.habit.text}
          onChange={(event) => this.handleChange(event.target.value)}
        />

        <PulseStatement statement={this.props.habit} isPreview />
      </div>
    );
  }
}

export default observer(HabitItem);
