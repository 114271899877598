import { range } from 'lodash';
import { action, computed, observable } from 'mobx';

import Model from 'app/models/Model';
import { ModelList } from 'app/models/ModelList';

export const PAGES_RANGE = 20;
export const PAGES_OFFSET = 5;

export interface LinksProps {
  first?: string;
  last?: string;
  prev?: string;
  next?: string;
}

export interface MetaProps {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
  search?: string;
}

export class PaginatedModelList<T extends Model, F = unknown> extends ModelList<T, F> {
  private isLoadingNext: boolean;

  @observable private _links?: LinksProps;
  @observable private _meta?: MetaProps;

  constructor(protected modelClass: typeof Model) {
    super(modelClass);
  }

  @computed
  get totalPages() {
    return this._meta?.total || 1;
  }

  @computed
  get lastPage() {
    return this._meta?.last_page || 1;
  }

  @computed
  get currentPage() {
    return this._meta?.current_page || 1;
  }

  @computed
  get firstPageUrl() {
    return this._links?.first || '';
  }

  @computed
  get lastPageUrl() {
    return this._links?.last || '';
  }

  @computed
  get isFirstPage() {
    return this.currentPage === 1;
  }

  @computed
  get isLastPage() {
    return this.lastPage === this.currentPage;
  }

  @computed
  get visiblePages() {
    const { path, current_page, last_page } = this._meta;
    if (last_page === 1) {
      return [];
    }

    let startPage = 1;
    let endPage = last_page > PAGES_RANGE ? PAGES_RANGE : last_page;

    const withinPagesFromMaxPage = current_page > PAGES_RANGE - PAGES_OFFSET;
    const beyondMaxPage = current_page > PAGES_RANGE;
    if (withinPagesFromMaxPage || beyondMaxPage) {
      const newEndPage = current_page + PAGES_OFFSET;
      endPage = newEndPage > last_page ? last_page : newEndPage;
      startPage = endPage - PAGES_RANGE;
    }

    const pages = range(startPage, endPage + 1).map((page) => ({
      page,
      url: `${path}?page=${page}`,
      isCurrent: page === current_page,
      isEllipsis: false,
    }));

    if (startPage > 1) {
      pages.unshift({
        page: null,
        url: null,
        isCurrent: false,
        isEllipsis: true,
      });
    }

    if (endPage < last_page) {
      pages.push({
        page: null,
        url: null,
        isCurrent: false,
        isEllipsis: true,
      });
    }

    return pages;
  }

  @computed
  get nextPage() {
    return this.currentPage + 1;
  }

  @computed
  get prevPage() {
    return this.currentPage - 1;
  }

  public async loadNext() {
    if (this.isLastPage) {
      return;
    }
    this.isLoadingNext = true;
    await super.load(this.url, { ...this.params, page: this.nextPage }, { dataKey: null });
    this.isLoadingNext = true;
  }

  public load(url, params?: { [key: string]: any; page?: number }) {
    return super.load(url, params, { dataKey: null });
  }

  /**
   * PaginatedModelList has to customize deserialize, not the default "T" items deserialize, because
   * this class needs *pagination metadata* in addition to the items data. It acutally won't work if
   * you initialize with items only but missing the pagination stuff (e.g. will deference null `meta`)
   */
  @action
  public deserialize(paginationAndItemData: any) {
    this._meta = paginationAndItemData.meta;
    this._links = paginationAndItemData.links;
    const items = Array.isArray(paginationAndItemData.data)
      ? paginationAndItemData.data.map((d) => this.modelClass._fromJson(d))
      : null;
    if (this.isLoadingNext) {
      this.appendItems(items);
    } else {
      this.setItems(items);
    }
  }
}
