import React from 'react';

import './Loading.scss';

interface LoadingProps {
  isLoading?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ isLoading }) => {
  if (isLoading === false) {
    return null;
  }

  return (
    <div className="loading-page">
      <div className="loading-container">
        <div className="spin">
          <img className="spin-logo" src="/images/valence-loading.webp" alt="loading..." />
        </div>
      </div>
    </div>
  );
};

export default Loading;
