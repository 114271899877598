import { isString, keyBy } from 'lodash';
import { computed } from 'mobx';

import { BenchmarkScoreModel, BenchmarkScoreProps } from './BenchmarkScoreModel';
import ExerciseTypeModel from './ExerciseTypeModel';
import PlainModel from './PlainModel';

/**
 * Define the properties that are passed in to create Benchmarks
 */
export interface BenchmarkProps {
  id?: number;
  name: string;
  scores?: BenchmarkScoreProps[];
  cutoffs?: { [percentage: number]: number } | string;
  is_overall?: boolean;
  display_order?: number;
}

/**
 * BenchmarkModel is used to represent Benchmarks
 */
export class BenchmarkModel extends PlainModel {
  id?: number;
  name: string;
  scores?: BenchmarkScoreProps[];
  cutoffs?: { [id: number]: number };
  exerciseType?: ExerciseTypeModel;
  is_overall?: boolean;
  display_order?: number;

  /**
   * Constructor method for Benchmarks
   * @param data The parameters of the Benchmark from the server
   */
  constructor(props: BenchmarkProps, exerciseType?: ExerciseTypeModel) {
    const { scores, cutoffs, ...rest } = props;
    super({ ...rest, exerciseType } as any);

    if (cutoffs) {
      this.cutoffs = isString(cutoffs) ? JSON.parse(cutoffs as string) : cutoffs;
    }

    if (scores) {
      this.scores = scores.map((score) => new BenchmarkScoreModel(score, this.exerciseType, this));
    }
  }

  @computed
  get scoresByStatementTemplateId(): { [id: number]: BenchmarkScoreModel } {
    return keyBy(
      this.scores,
      (score: BenchmarkScoreModel) => score.align_statement_template_id
    ) as Record<number, BenchmarkScoreModel>;
  }

  @computed
  get isPlaceholder(): boolean {
    return this.name.toLowerCase().includes('no benchmark');
  }
}

export default BenchmarkModel;
