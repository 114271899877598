import React from 'react';

import CarouselModalComponent from 'app/components/ui/CarouselModalComponent';

export interface HowHabitWorksModalProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete?: () => void;
  completeDisabled?: boolean;
  disableInfoText?: string;
}

const SLIDES = [
  {
    header: 'Welcome to Habits',
    content:
      'Habits is a lightweight way to identify and track the improvement goals that will most help your team',
    iconSrc: '/images/habit/welcome-to-habits.svg',
    iconStyle: {
      marginTop: '1.5rem',
      marginBottom: '2.5rem',
    },
  },
  {
    header: 'Identify your goals',
    content:
      'First, following your Align exercise, we’ll help you identiy the goals that will be most helpful for you (and you can always pick your own)',
    iconSrc: '/images/habit/identify-your-goals.svg',
    iconStyle: {
      marginTop: '3.5rem',
      marginBottom: '2.25rem',
    },
  },
  {
    header: 'Keep a pulse on your team',
    content:
      "Second, you'll be able to set up a quick team pulse to get reminders and feedback on how things are going.",
    iconSrc: '/images/habit/keep-a-pulse.svg',
    iconTopMargin: '1.75rem',
    iconStyle: {
      marginTop: '1.75rem',
      marginBottom: '1.25rem',
    },
  },
  {
    header: 'Track improvement over time',
    content: "You'll be able to track progress over time and watch your team improve.",
    iconSrc: '/images/habit/track-improvement.svg',
    iconStyle: {
      marginTop: '1.5rem',
      marginBottom: '2.5rem',
    },
  },
];

export const HowHabitWorksModal: React.StatelessComponent<HowHabitWorksModalProps> = ({
  isOpen,
  onComplete,
  onClose,
}) => (
  <CarouselModalComponent
    title="How does Habit work?"
    isOpen={isOpen}
    onClose={onClose}
    onComplete={onComplete}
    slides={SLIDES}
  />
);

export default HowHabitWorksModal;
