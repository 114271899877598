import { remove, sortBy } from 'lodash';

import { MemberModel } from 'app/models';

export default class PerspectiveHelper {
  static sortMembersByPerspectives(members: MemberModel[], memberId: number) {
    const currentMember = remove(members, function (member) {
      return member.id === memberId;
    });

    const sortedWithPerspectives = sortBy(members, [
      function (member) {
        return member.mbti_letters;
      },
    ]);
    return currentMember.concat(sortedWithPerspectives);
  }
}
