import { omit } from 'lodash';
import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import DiscussionSpaceNoteModel from 'app/models/DiscussionSpaceNoteModel';
import Store from 'app/stores/Store';

export class DiscussionSpaceNoteStore extends Store<DiscussionSpaceNoteModel> {
  @observable token;
  @action setToken = (token: string): void => {
    this.token = token;
  };

  constructor() {
    super();
    DiscussionSpaceNoteModel._store = this;
  }

  async createNote(
    discussionSpaceId: number,
    agendaId: number,
    note: DiscussionSpaceNoteModel
  ): Promise<void> {
    let url = ServerRouteHelper.api.discussionSpace.agendaNotes(discussionSpaceId, agendaId);

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    const note_ids = note.notes?.map((note) => note.id);
    const payload = { ...omit(note, 'notes'), note_ids };

    const config = {
      url,
      data: payload,
      throwError: true,
    };
    const response = await this.apiService.newPost(config);

    if (response?.data) {
      note.updateFromJson(response.data);
    }
  }

  updateNote = async (
    discussionSpaceId: number,
    agendaId: number,
    note: DiscussionSpaceNoteModel
  ): Promise<void> => {
    let url = ServerRouteHelper.api.discussionSpace.notes.note(
      discussionSpaceId,
      agendaId,
      note.id
    );

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    const note_ids = note.notes?.map((note) => note.id);
    const payload = { ...omit(note, 'notes'), note_ids };
    const response = await this.apiService.put(url, payload);
    note.updateFromJson(response.data);
  };

  deleteNote = (
    discussionSpaceId: number,
    agendaId: number,
    note: DiscussionSpaceNoteModel
  ): Promise<void> => {
    let url = ServerRouteHelper.api.discussionSpace.notes.note(
      discussionSpaceId,
      agendaId,
      note.id
    );

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    return this.apiService.delete(url);
  };
}

export default DiscussionSpaceNoteStore;
