import React, { ReactNode } from 'react';

import { RouteComponentProps, withRouter } from 'react-router';

import { ServerRouteHelper } from 'app/helpers';
import { OrganizationModel } from 'app/models';

import MemberDropdown from './/MemberDropdown';

interface RoutedMemberDropdownProps extends RouteComponentProps {}

class RoutedMemberDropdown extends React.Component<RoutedMemberDropdownProps> {
  handleGoToOrg = (org: OrganizationModel) => {
    const url = ServerRouteHelper.dashboard.organization.summary(org.id);
    this.props.history.push(url);
  };

  render(): ReactNode {
    return <MemberDropdown handleGoToOrg={this.handleGoToOrg} />;
  }
}

export default withRouter(RoutedMemberDropdown);
