import React, { ReactNode } from 'react';

import { Divider, Form, Input, Select, Switch } from 'antd';
import cx from 'classnames';
import { action, computed, observable, when } from 'mobx';
import { inject, observer } from 'mobx-react';
import numeral from 'numeral';
import { RouteComponentProps } from 'react-router-dom';

import styles from '../PulseTemplates.module.scss';

import { STORE_ADMIN_PULSE_TEMPLATES } from 'app/constants';
import { PulseTemplateModel } from 'app/models';
import { AdminPulseTemplateStore } from 'app/stores';

import CreateNewTemplateModal from './CreateNewTemplateModal';
import TemplateCategory from './TemplateCategory';

export interface Params {
  templateId: string;
}

export interface EditPulseTemplateProps extends RouteComponentProps<Params> {
  adminPulseTemplateStore: AdminPulseTemplateStore;
}

export class EditPulseTemplate extends React.Component<EditPulseTemplateProps> {
  @observable private templateName: string;
  @action setTemplateName = (name) => (this.templateName = name);

  @observable private templateScope: string;
  @action setTemplateScope = (scope) => (this.templateScope = scope);

  @observable private templateIsDefault: boolean;
  @action setIsDefault = (isDefault) => (this.templateIsDefault = isDefault);

  @observable private isAddCategoryModalOpen: boolean;
  @action toggleIsAddCategoryModalOpen = () =>
    (this.isAddCategoryModalOpen = !this.isAddCategoryModalOpen);

  constructor(props: EditPulseTemplateProps) {
    super(props);
    this.init();
  }

  private async init() {
    const { adminPulseTemplateStore } = this.props;

    await adminPulseTemplateStore.loadTemplate(this.templateId);

    when(
      () => !!this.template,
      () => {
        this.setTemplateName(this.name);
        this.setTemplateScope(this.scope);
        this.setIsDefault(this.isDefault);
      }
    );
  }

  @computed
  get templateId(): number {
    return numeral(this.props.match.params.templateId).value();
  }

  get template(): PulseTemplateModel {
    return this.props.adminPulseTemplateStore?.currentTemplate.item;
  }

  get name(): string {
    return this.template?.name;
  }

  get scope(): string {
    return this.template?.scope || 'team_pulse';
  }

  get isDefault(): boolean {
    return this.template?.is_default;
  }

  get categories(): PulseTemplateModel[] {
    return this.props.adminPulseTemplateStore.categories.items;
  }

  @computed
  get templateCategories(): ReactNode {
    if (this.categories?.length === 0) {
      return 'There are no Categories under this template.';
    }

    return (
      <div>
        {this.categories?.map((category, i) => (
          <TemplateCategory
            key={i}
            category={category}
            statements={category.statements.items || (category.statements as any)}
          />
        ))}
      </div>
    );
  }

  @computed
  get templateCategoryHeader(): ReactNode {
    return (
      <div className={styles.subSectionHeader}>
        <h4>Template Categories</h4>
        <button className="btn btn-outline mb-4" onClick={this.toggleIsAddCategoryModalOpen}>
          Add Category
        </button>
      </div>
    );
  }

  public render(): ReactNode {
    return (
      <div className={cx(styles.templateForm, 'container')}>
        <h4>Edit Pulse Template</h4>

        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal">
          <Form.Item label="Template Title">
            <Input
              value={this.templateName}
              onChange={(e) => this.setTemplateName(e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Type">
            <Select value={this.templateScope} onChange={(value) => this.setTemplateScope(value)}>
              <Select.Option value="member_pulse">Personal</Select.Option>
              <Select.Option value="team_pulse">Team</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Is Default ? ">
            <Switch
              checked={this.templateIsDefault}
              onChange={(checked) => this.setIsDefault(checked)}
            />
          </Form.Item>
        </Form>
        <Divider />

        {this.templateCategoryHeader}
        {this.templateCategories}

        <CreateNewTemplateModal
          isOpen={this.isAddCategoryModalOpen}
          onClose={this.toggleIsAddCategoryModalOpen}
          parentTemplate={this.template}
        />
      </div>
    );
  }
}

export default inject(STORE_ADMIN_PULSE_TEMPLATES)(observer(EditPulseTemplate));
