import ThirdPartyService from 'app/services/ThirdPartyService';

export default {
  get: (key: string): string => {
    try {
      const cookies = document.cookie.split(';');

      for (const cookiePart of cookies) {
        const [name, value] = cookiePart.trim().split('=');

        if (name === key) {
          return decodeURIComponent(value);
        }
      }
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    }

    return null;
  },
};
