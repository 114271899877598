import React from 'react';

import { get } from 'lodash';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import URI from 'urijs';

import { FakeBox } from 'app/components/ui/FakeBox';
import { STORE_AUTH } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import { ShiftError } from 'app/models';
import LoginContainer from 'app/pages/auth/Login/LoginContainer';
import { Logo } from 'app/pages/auth/Partials/Logo/Logo';
import { AuthStore } from 'app/stores';

interface Params {
  token: string;
}

interface VerifyEmailProps extends RouteComponentProps<Params> {
  [STORE_AUTH]?: AuthStore;
}

class VerifyEmail extends React.Component<VerifyEmailProps> {
  @observable error: ShiftError;
  @action setError = (error) => (this.error = error);
  @observable isVerifying: boolean;
  @action setIsVerifying = (verified: boolean) => (this.isVerifying = verified);

  constructor(props) {
    super(props);
    this.verifyEmail();
  }

  @computed
  get email() {
    return get(this.queryParams, 'email');
  }

  @computed
  get queryParams() {
    return URI.parseQuery(window.location.search);
  }

  @computed
  get token() {
    return this.props.match.params.token;
  }

  verifyEmail = async () => {
    const { authStore } = this.props;
    try {
      this.setIsVerifying(true);
      await authStore.verifyEmail(this.email, this.token);
    } catch (error) {
      this.setError(error);
    } finally {
      this.setIsVerifying(false);
    }
  };

  @computed
  get hasError() {
    return !!this.error;
  }

  @computed
  get description() {
    if (this.hasError) {
      return 'The e-mail verification token is either invalid or has expired.';
    }
    return (
      <>
        Thank you! Your e-mail address has been successfully verified.
        <br /> <Link to={ServerRouteHelper.auth.login()}>Continue to the application &gt;&gt;</Link>
      </>
    );
  }

  @computed
  get header() {
    if (this.hasError) {
      return 'Invalid Token';
    }
    return 'E-mail Address Verified!';
  }

  render() {
    if (this.isVerifying) {
      return <FakeBox />;
    }
    return (
      <LoginContainer>
        <Logo />
        <h2 className="text-center font-weight-bold mb-3">{this.header}</h2>
        <p className="text-center">{this.description}</p>
      </LoginContainer>
    );
  }
}

export default inject(STORE_AUTH)(observer(VerifyEmail));
