import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { AlignModel, ExerciseStatementModel } from 'app/models';
import ExerciseAnswersModel from 'app/models/ExerciseAnswersModel';
import type {
  PostSurveyMember,
  PostSurveyOption,
  PostSurveyQuestion,
} from 'app/models/PostSurveyModel';

import Store from './Store';

export class ExerciseAnswersStore extends Store<ExerciseAnswersModel> {
  @observable exerciseAnswers: ExerciseAnswersModel;
  @action setExerciseAnswers = (exerciseAnswers) => (this.exerciseAnswers = exerciseAnswers);

  @observable public postSurveyQuestions: PostSurveyQuestion[];
  @action private setPostSurveyQuestions = (questions: PostSurveyQuestion[]) =>
    (this.postSurveyQuestions = questions);

  @observable postSurveyAnswers: Map<string, string> = new Map();
  @action setPostSurveyAnswer = (position, answer) => this.postSurveyAnswers.set(position, answer);

  @observable postSurveyRelationship: PostSurveyOption = null;
  @action setPostSurveyRelationship = (postSurveyRelationship) =>
    (this.postSurveyRelationship = postSurveyRelationship);

  @observable postSurveyRelationshipOther: string = null;
  @action setPostSurveyRelationshipOther = (postSurveyRelationshipOther) =>
    (this.postSurveyRelationshipOther = postSurveyRelationshipOther);

  @observable postSurveyAnonymous = false;
  @action setPostSurveyAnonymous = (postSurveyAnonymous) =>
    (this.postSurveyAnonymous = postSurveyAnonymous);

  @observable statementFeedbacks: Map<string, string> = new Map();
  @action setStatementFeedbacks = (statementId, feedback) =>
    this.statementFeedbacks.set(statementId, feedback);

  @observable isSavingAnswers = false;
  @action setIsSavingAnswers = (isSavingAnswers) => (this.isSavingAnswers = isSavingAnswers);

  @observable isLoadingPostSurveyQuestions = false;
  @action setIsLoadingPostSurveyQuestions = (isLoadingPostSurveyQuestions) =>
    (this.isLoadingPostSurveyQuestions = isLoadingPostSurveyQuestions);

  @observable isSavingPostSurveyAnswers = false;
  @action setIsSavingPostSurveyAnswers = (isSavingPostSurveyAnswers) =>
    (this.isSavingPostSurveyAnswers = isSavingPostSurveyAnswers);

  @observable isSavingStatementComments = false;
  @action setIsSavingStatementComments = (isSavingStatementComments) =>
    (this.isSavingStatementComments = isSavingStatementComments);

  @observable exerciseLowestStatements: Partial<ExerciseStatementModel[]>;
  @action setExerciseLowestStatements = (exerciseLowestStatements) =>
    (this.exerciseLowestStatements = exerciseLowestStatements);

  constructor() {
    super();
    ExerciseAnswersModel._store = this;
  }

  public async saveAnswers(exerciseId: number) {
    this.setIsSavingAnswers(true);
    const url = ServerRouteHelper.api.exercises.saveAnswers(exerciseId);
    const config = {
      url,
      data: this.exerciseAnswers.asJSON(),
      throwError: true,
    };

    const result = await this.apiService.newPost(config);

    if (!result?.member) {
      this.setIsSavingAnswers(false);
      return;
    }

    if (result?.lowest_statements) {
      this.setExerciseLowestStatements(result.lowest_statements);
    }

    this.setIsSavingAnswers(false);
    return result;
  }

  public async loadPostSurveyQuestions(exerciseId: number, params?: Record<string, unknown>) {
    this.setIsLoadingPostSurveyQuestions(true);
    const url = ServerRouteHelper.api.exercises.postSurveyQuestions(exerciseId);
    const response = await this.apiService.get(url, params);
    if (response?.data) {
      this.setPostSurveyQuestions(response.data);
    }

    this.setIsLoadingPostSurveyQuestions(false);
  }

  public async savePostSurveyAnswers(exerciseId: number, data) {
    this.setIsSavingPostSurveyAnswers(true);
    const url = ServerRouteHelper.api.exercises.savePostSurveyAnswers(exerciseId);
    const config = {
      url,
      data,
      throwError: true,
    };

    const response = await this.apiService.newPost(config);

    this.setIsSavingPostSurveyAnswers(false);
    return response?.redirectUrl;
  }

  public getSurveyAnswers(exercise: AlignModel, surveyMember?: PostSurveyMember) {
    const data = {
      member: surveyMember?.id,
      keep_anonymous: this.postSurveyAnonymous,
      question: {
        relationship: {
          type: this.postSurveyRelationship,
          other: this.postSurveyRelationshipOther,
        },
      },
    };

    this.postSurveyAnswers.forEach((v, k) => {
      // don't save if empty string or only whitespaces
      if (!v.replace(/ /g, '')) {
        return;
      }

      data.question[k] = v;
    });

    if (exercise.exercise_type?.item.hasCustomSurveyQuestions) {
      return data;
    }

    data['topStatementId'] = this.exerciseAnswers.goingWellStatement?.id;
    data['bottomStatementId'] = this.exerciseAnswers.notGoingWellStatement?.id;

    return data;
  }

  public async saveStatementComments(exerciseId: number, memberId: number): Promise<void> {
    this.setIsSavingStatementComments(true);
    const url = ServerRouteHelper.api.my360s.comments(exerciseId, memberId);
    await this.apiService.post(url, { comments: this.statementFeedbacks });
    this.statementFeedbacks.clear();
    this.setIsSavingStatementComments(false);
  }
}

export default ExerciseAnswersStore;
