import { action, computed, observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import PerspectiveMemberStore from 'app/stores/PerspectiveMemberStore';

import EmailActivityModel from './EmailActivityModel';
import { MemberAvatarModel } from './MemberAvatarModel';
import PerspectiveModel, { PairViewAccess } from './PerspectiveModel';

export interface PerspectiveMemberTeam {
  id: number;
  organization_id: number;
  name: string;
  // invite_link: string;
  name_updated: boolean;
  manager_id: number;
}

export class PerspectiveMemberModel extends Model {
  static _store: PerspectiveMemberStore;

  @observable id: number;
  @observable name: string;
  @observable first_name: string;
  @observable last_name: string;
  @observable email: string;
  @observable letters: string;
  @observable isActive = true;
  @observable avatar?: MemberAvatarModel;
  @observable perspective?: PerspectiveModel;
  @observable is_colleague?: boolean;
  @observable is_current_member?: boolean;
  @observable teams?: PerspectiveMemberTeam[];
  @observable last_email_invite?: EmailActivityModel;
  @observable pair_perspective_access?: PairViewAccess;

  @computed
  get firstName() {
    return this.name.split(' ')[0];
  }

  @action
  setActive(isActive: boolean) {
    this.isActive = isActive;
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectiveMemberModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PerspectiveMemberModel;
  }

  static get(id) {
    return this._get(id) as PerspectiveMemberModel;
  }
}

export default PerspectiveMemberModel;
