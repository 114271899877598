import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import PerspectiveScoreDiffStore from 'app/stores/PerspectiveScoreDiffStore';

import MemberAvatarModel from './MemberAvatarModel';

export class PerspectiveScoreDiffModel extends Model {
  static _store: PerspectiveScoreDiffStore;

  @observable id: number;
  @observable name: string;
  @observable diff: { string: number };
  @observable diffLabel: string;
  @observable avatar: MemberAvatarModel;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectiveScoreDiffModel;
  }

  static getOrNew(id): PerspectiveScoreDiffModel {
    return this._getOrNew(id) as PerspectiveScoreDiffModel;
  }

  static get(id): PerspectiveScoreDiffModel {
    return this._get(id) as PerspectiveScoreDiffModel;
  }
}

export default PerspectiveScoreDiffModel;
