import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';

import { ClientDataHelper, ServerRouteHelper } from 'app/helpers';
import { ModelList, PagingInfoModel, PagingMetaModel } from 'app/models';
import AlignModel from 'app/models/AlignModel';
import { ModelItem } from 'app/models/ModelItem';
import TeamModel from 'app/models/TeamModel';
import Store from 'app/stores/Store';

const DEFAULT_EXERCISES_PAGE_NUMBER = 1;
const DEFAULT_EXERCISES_PAGE_SIZE = 3;

export interface AlignCloseOptions {
  sendResultsToParticipants?: boolean;
  personalMessage?: string;
  source?: string;
}

export enum AlignStoreIncludes {
  DISCUSSION_GUIDE = 'discussion_guide',
  DISCUSSION_SPACE = 'discussion_space',
  DISCUSSION_SPACE_ACTIONS = 'discussion_space_actions',
  SCATTERPLOT_HIGHLIGHTS = 'scatterplot_highlights',
  EXERCISE_TYPE_STATEMENTS = 'exercise_type_statements',
  TEAM_EXERCISE_PULSE = 'team_exercise_pulse',
  PULSE = 'pulse',
  CLOSED_AT = 'closed_at',
  CURRENT_USER_HIDE_COMPLETE_PROMPT = 'current_user_hide_complete_prompt',
  CUTOFF_DATE = 'cutoff_date',
  STATEMENTS_SUMMARY_STATS = 'statements_summary_stats',
}

export class AlignStore extends Store<AlignModel> {
  @observable public currentAlign: AlignModel;
  @observable public loadingCurrentAlign: boolean;

  @observable reOpeningAlign: boolean;
  @action toggleReopeningAlign = (): void => {
    this.reOpeningAlign = !this.reOpeningAlign;
  };

  @observable public exercise = new ModelItem<AlignModel>(AlignModel);

  @observable sharedLinkToken: string;
  @action setSharedLinkToken = (sharedLinkToken) => (this.sharedLinkToken = sharedLinkToken);

  @observable exercisesPageMeta: PagingMetaModel;

  @observable teamPartialAligns = new ModelList<AlignModel>(AlignModel);

  @observable exercisesPagingInfo = new PagingInfoModel({
    page: DEFAULT_EXERCISES_PAGE_NUMBER,
    page_size: DEFAULT_EXERCISES_PAGE_SIZE,
  });

  @action resetExercisePagingInfo = (): void => {
    this.exercisesPagingInfo = new PagingInfoModel({
      page: DEFAULT_EXERCISES_PAGE_NUMBER,
      page_size: DEFAULT_EXERCISES_PAGE_SIZE,
    });
  };

  @observable isLoadingTeamAligns: boolean;
  @action setIsLoadingTeamAligns = (isLoadingTeamAligns: boolean): void => {
    this.isLoadingTeamAligns = isLoadingTeamAligns;
  };

  constructor() {
    super();
    AlignModel._store = this;
  }

  async loadTeamAlign(
    teamId: number,
    alignId: number,
    includes: AlignStoreIncludes[] = [],
    forceRefresh = false
  ): Promise<void> {
    const team = TeamModel.getOrNew(teamId);
    if (!team) {
      return;
    }

    const query = {};

    if (includes.length > 0) {
      query['includes'] = includes.join(',');
    }

    await team.align.load(ServerRouteHelper.api.exercises.show(teamId, alignId), query, {
      dataKey: 'exercise',
      forceRefresh,
    });
  }

  @action
  async getCurrentAlign() {
    this.loadingCurrentAlign = false;
    const result = await ClientDataHelper.get('align');
    this.currentAlign = AlignModel.fromJson(result);
    this.loadingCurrentAlign = true;
  }

  async getAlign(teamId: number, alignId: number) {
    const url = ServerRouteHelper.api.exercises.show(teamId, alignId);
    const config = {
      url,
      throwError: true,
    };

    const response = await this.apiService.newGet(config);
    return AlignModel.fromJson(response?.exercise);
  }

  async getTeamAligns(teamId) {
    const team = TeamModel.getOrNew(teamId);
    if (!team) {
      return;
    }

    this.setIsLoadingTeamAligns(true);

    const params = {
      page: this.exercisesPagingInfo.page,
      page_size: this.exercisesPagingInfo.page_size,
      includes: [
        AlignStoreIncludes.CLOSED_AT,
        AlignStoreIncludes.CURRENT_USER_HIDE_COMPLETE_PROMPT,
        AlignStoreIncludes.CUTOFF_DATE,
        AlignStoreIncludes.DISCUSSION_GUIDE,
        AlignStoreIncludes.STATEMENTS_SUMMARY_STATS,
      ].join(','),
    };

    const url = ServerRouteHelper.api.teams.exercises.list(teamId);

    const response = await this.teamPartialAligns.load(url, params);

    if (response?.meta) {
      this.exercisesPageMeta = new PagingMetaModel(response.meta);

      this.exercisesPagingInfo.page === 1
        ? team.aligns.setItems(this.teamPartialAligns.items)
        : team.aligns.appendItems(this.teamPartialAligns.items);

      this.exercisesPagingInfo.incrementPage();
    }

    this.setIsLoadingTeamAligns(false);
  }

  async getAllTeamAligns(teamId, includes = []) {
    const team = TeamModel.getOrNew(teamId);
    if (!team) {
      return;
    }

    const url = ServerRouteHelper.api.teams.exercises.minimalList(teamId);
    await team.all_aligns.load(url, {
      includes: includes.join(','),
    });
  }

  sendReminders(teamId, exerciseId, emails, message, tag = '') {
    const url = ServerRouteHelper.api.teams.exercises.sendReminders(teamId, exerciseId);
    const config = {
      url,
      data: {
        emails,
        message,
        tag,
      },
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  async sendInvites(teamId, exerciseId, emails, message) {
    const url = ServerRouteHelper.api.teams.exercises.sendInvites(teamId, exerciseId);
    const config = {
      url,
      data: {
        emails,
        message,
      },
      showGenericError: true,
    };

    return this.apiService.newPost(config);
  }

  // FIXME: Unify this with `closeAlign` below,
  // there should only be one way to close an exercise.
  // In fact I think there's a bug on one of the callers
  // of each of these that might be posting to the wrong endpoint.
  async closeExercise(data: Record<string, unknown>, exerciseId: number) {
    const result = await this.apiService.post(
      ServerRouteHelper.api.exercises.close(exerciseId),
      data
    );

    const exercise = AlignModel.getOrNew(exerciseId);
    exercise.updateFromJson(result.data);
    return exercise;
  }

  @action
  async dismissPrompt(teamId: number, exerciseId: number, prompt: string) {
    const align = AlignModel.getOrNew(exerciseId);
    const url = ServerRouteHelper.api.teams.exercises.dismissPrompt(teamId, exerciseId);
    await this.apiService.post(url, { prompt });
    runInAction(() => {
      align.currentUserHideCompletePrompt = true;
    });
  }

  @action
  async undoDismissPrompt(teamId: number, exerciseId: number, prompt: string) {
    const align = AlignModel.getOrNew(exerciseId);
    const url = ServerRouteHelper.api.teams.exercises.undoDismissPrompt(teamId, exerciseId);
    await this.apiService.post(url, { prompt });
    runInAction(() => {
      align.currentUserHideCompletePrompt = false;
    });
  }

  @action
  async setCutoffDate(alignId, cutoffDate) {
    const cutoff_date = moment(cutoffDate).format('Y-MM-DD');
    const response = await this.apiService.patch(ServerRouteHelper.api.exercises.update(alignId), {
      cutoff_date,
    });

    runInAction(() => {
      const align = AlignModel.getOrNew(alignId);
      const cutoffDate = moment(response['cutoff_date']);
      if (cutoffDate.isValid()) {
        align.cutoff_date = cutoffDate;
      }
    });
  }

  @action
  async closeAlign(alignId: number, options: AlignCloseOptions = {}): Promise<string> {
    const url = ServerRouteHelper.api.exercises.close(alignId);
    const { sendResultsToParticipants, personalMessage, source } = options;

    const data = {
      source,
      send_results_to_participants: sendResultsToParticipants,
      ...(personalMessage && {
        personal_message: personalMessage,
      }),
    };

    const result = await this.apiService.post(url, data);

    const align = AlignModel.getOrNew(alignId);
    align.is_closed = true;

    return result?.data.reportUrl;
  }

  @action
  async sendResults(alignId) {
    const url = ServerRouteHelper.api.exercises.sendResults(alignId);
    const params = { token: this.sharedLinkToken };

    await this.apiService.post(url, params);
    const align = AlignModel.getOrNew(alignId);
    align.results_sent = true;
  }

  @action
  async deleteExercise(exerciseId) {
    const exercise = AlignModel.getOrNew(exerciseId);
    if (!exercise) {
      return;
    }

    exercise.deleting = true;
    try {
      await this.apiService.delete(ServerRouteHelper.api.exercises.delete(exerciseId));
      exercise.deleted = true;
    } finally {
      exercise.deleting = false;
    }
  }

  @action
  public async deleteMemberAnswers(alignId: number, memberId: number) {
    const align = AlignModel.getOrNew(alignId);
    try {
      const url = ServerRouteHelper.api.admin.exercises.deleteMemberResults(alignId, memberId);
      const config = {
        url,
        throwError: true,
      };

      await this.apiService.newDelete(config);

      runInAction(() => {
        align.members.setItems(align.members.items.filter((member) => member.id !== memberId));
      });
    } catch (error) {
      throw error;
    }
  }

  @action
  public async saveInsights(alignId: number, content: any, submitKey?: string) {
    const params = {};
    params[submitKey || 'shift_insights'] = content;

    const url = ServerRouteHelper.api.admin.exercises.shiftInsights(alignId);
    const config = {
      url,
      data: params,
      throwError: true,
    };

    await this.apiService.newPost(config);
  }

  async createNewAlign(teamId: number, alignTypeId: number, source?: string): Promise<AlignModel> {
    const url = ServerRouteHelper.api.exercises.create(teamId);
    const config = {
      url,
      data: {
        source,
        align: { exercise_type_id: alignTypeId },
      },
      showGenericError: true,
    };

    const response = await this.apiService.newPost(config);
    if (response?.align) {
      return AlignModel.fromJson(response.align);
    }
  }

  @action
  async importAlignAnswers(
    destinationExerciseId: number,
    source_exercise_id: number,
    member_ids: number[]
  ) {
    const url = ServerRouteHelper.api.admin.exercises.importAlignAnswers(destinationExerciseId);
    const config = {
      url,
      data: {
        source_exercise_id,
        member_ids,
      },
      throwError: true,
    };

    await this.apiService.newPost(config);
  }

  @computed
  get shouldLoadMoreAligns(): boolean {
    return (
      this.exercisesPageMeta &&
      this.exercisesPageMeta?.current_page < this.exercisesPageMeta?.last_page
    );
  }

  async reOpenAlign(exerciseId: number): Promise<void> {
    this.toggleReopeningAlign();

    await this.apiService.get(ServerRouteHelper.api.admin.exercises.reOpenExercise(exerciseId));

    const align = AlignModel.get(exerciseId);
    align.updateFromJson({ is_closed: false, closed_at: null, closed_by: null });

    this.toggleReopeningAlign();
  }

  async loadExerciseByToken(
    token: string,
    includes: string[] = [],
    forceRefresh = false
  ): Promise<void> {
    const url = ServerRouteHelper.api.exercises.showByIdOrToken(token);
    await this.exercise.load(url, { includes: includes.join(',') }, { forceRefresh });
  }

  async loadExerciseById(id: number) {
    const url = ServerRouteHelper.api.exercises.showByIdOrToken(id);
    await this.exercise.load(url);
  }

  async loadExerciseForCoach(id: number) {
    const url = ServerRouteHelper.api.exercises.showForCoach(id);
    await this.exercise.load(url);
  }
}

export default AlignStore;
