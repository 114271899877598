import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { Model } from 'app/models';
import Store from 'app/stores/Store';

export class SubscriptionStore extends Store<Model> {
  @observable activeOrgId: number;
  @action setActiveOrgId = (activeOrgId: number): void => {
    this.activeOrgId = activeOrgId;
  };

  @observable enabled = false;
  @action setEnabled = (enabled: boolean): void => {
    this.enabled = enabled;
  };

  @observable isTestMode = false;
  @action setTestMode = (isTestMode: boolean): void => {
    this.isTestMode = isTestMode;
  };

  @observable isLoadingStatus = false;
  @action setIsLoadingStatus = (isLoadingStatus: boolean): void => {
    this.isLoadingStatus = isLoadingStatus;
  };

  async loadStatus(orgId: number, forceRefresh = false): Promise<void> {
    if (this.activeOrgId === orgId || forceRefresh) {
      return;
    }

    this.setIsLoadingStatus(true);

    const url = ServerRouteHelper.api.subscriptions.organization.status(orgId);
    const resp = await this.apiService.newGet({ url });

    this.setActiveOrgId(orgId);
    this.setEnabled(!!resp.data.enabled);
    this.setTestMode(!!resp.data.test_mode);

    this.setIsLoadingStatus(false);
  }
}

export default SubscriptionStore;
