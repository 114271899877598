import React from 'react';

import { Spinner } from 'app/components/ui/Spinner/Spinner';

interface SubmitButtonProps {
  id?: string;
  title: string;
  isLoading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  track?: string;
  tag: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  id,
  title,
  isLoading,
  type,
  track,
  tag,
  disabled,
  onClick,
}) => {
  return (
    <button
      id={id}
      type={type ?? 'submit'}
      disabled={isLoading || disabled}
      className="btn btn-primary btn-block"
      data-track={track ?? 'login-btn'}
      data-tag={tag}
      onClick={onClick}
    >
      {title}&nbsp;
      <Spinner isLoading={isLoading} />
    </button>
  );
};
