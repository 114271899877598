import { ReactNode } from 'react';

import { remove, uniqueId } from 'lodash';
import { action, observable, ObservableMap } from 'mobx';

export interface ModalConfigItem {
  key: string;
  render: (props: any) => ReactNode;
}

/**
 * Modal UI Store allows for any components to register and control a modals
 * display and props without needing to manage the mounting of the modal a page.
 *
 * This allows for a simple CTA button component to expose a modal anywhere it
 * is rendered without needing to update the page it is imported and rendered
 * on.
 *
 * Additionally we avoid the awkward DOM renderings times where we render and
 * mount a modal inside a component.
 * EG a modal renders and mounted inside a CTA.
 *
 * Currently this is only used in registering the modals on TeamHome component
 * and they are rendered in AppLayout.
 * @see AppLayout
 * @see `TeamHome.tsx`
 *
 * A major goal of this UI store is to remove complex state and wiring from base
 * pages just that is sometimes duplicated and only for the rendering of a modal.
 */

export default class ModalsUiStore {
  @observable modalOpenStates = new ObservableMap();
  @observable modals: ModalConfigItem[] = [];

  @action registerModal = (modalRenderer) => {
    const modal: ModalConfigItem = {
      key: uniqueId(),
      render: modalRenderer,
    };

    this.modals.push(modal);
    this.modalOpenStates.set(modal.key, false);

    return modal.key;
  };

  @action unregisterModal = (key: string) => {
    remove(this.modals, { key: key });
  };

  @action openModal = (key: string) => {
    this.modalOpenStates.set(key, true);
  };

  @action closeModal = (key: string) => {
    this.modalOpenStates.set(key, false);
  };

  @action setOpenState = (key: string, state: boolean) => {
    this.modalOpenStates.set(key, state);
  };

  makeModal = (modal: ModalConfigItem) => {
    return modal.render({
      key: modal.key,
      isOpen: this.modalOpenStates.get(modal.key),
    });
  };
}
