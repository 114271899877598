// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Uf855buGd5xYftWV4Pi5Mg\\=\\={display:flex;flex-direction:column;padding:1rem 0;width:100%}.Uf855buGd5xYftWV4Pi5Mg\\=\\= h4{color:#000}.Uf855buGd5xYftWV4Pi5Mg\\=\\= textarea{margin-bottom:1.25rem}.Uf855buGd5xYftWV4Pi5Mg\\=\\= button{align-items:center;align-self:flex-end;display:flex}", "",{"version":3,"sources":["webpack://./resources/assets/js/pages/dashboard/Pulse/PulseSteps/CreateHabitsStep/SelectHabitsCard/AddCustomHabit/AddCustomHabit.module.scss"],"names":[],"mappings":"AAEA,4BAGE,YAAA,CACA,qBAAA,CAHA,cAAA,CACA,UAkFF,CA9EE,+BACE,UAgFJ,CA7EE,qCACE,qBA+EJ,CA5EE,mCAEE,kBAAA,CACA,mBAAA,CAFA,YAgFJ","sourcesContent":["@import 'resources/assets/sass/bootstrap_variables';\n\n.addCustomHabit {\n  padding: 1rem 0;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n\n  h4 {\n    color: $black;\n  }\n\n  textarea {\n    margin-bottom: 1.25rem;\n  }\n\n  button {\n    display: flex;\n    align-items: center;\n    align-self: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addCustomHabit": "Uf855buGd5xYftWV4Pi5Mg=="
};
export default ___CSS_LOADER_EXPORT___;
