import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import PerspectiveStore from 'app/stores/PerspectiveStore';

export enum PairViewAccess {
  Team = 'team',
  Organization = 'organization',
}

export class PerspectiveModel extends Model {
  static _store: PerspectiveStore;

  @observable id: number;
  @observable member_id: number;
  @observable created_at: string;
  @observable archetype: string;
  @observable archetype_description?: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectiveModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PerspectiveModel;
  }

  static get(id) {
    return this._get(id) as PerspectiveModel;
  }
}

export default PerspectiveModel;
