import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import MemberAvatarStore from 'app/stores/MemberAvatarStore';

export interface MemberAvatarProps {
  id: number | string;
  text: string;
  color: string;
}

export class MemberAvatarModel extends Model {
  static _store: MemberAvatarStore;

  @observable id: number;
  @observable text: string;
  @observable color: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as MemberAvatarModel;
  }
}

export default MemberAvatarModel;
