import React from 'react';

import TextArea from 'antd/lib/input/TextArea';
import { action } from 'mobx';
import { observer } from 'mobx-react';

import { RUSS_360_COACHING_URL } from 'app/constants';
import ExerciseCommitmentModel, {
  ExerciseCommitmentContentKey,
} from 'app/models/ExerciseCommitmentModel';

import { CommitToImproveModalStep } from '../CommitToImproveModal';

interface CommitToImproveModalSelectStatementStepProps
  extends CommitToImproveModalWriteContentBaseProps {
  contentKey: ExerciseCommitmentContentKey;
  submitButtonText: string;
  submitButtonTextSubmitting?: string;
  onBack: () => void;
}

interface CommitToImproveModalWriteContentBaseProps {
  exerciseCommitment: ExerciseCommitmentModel;
  isSubmitting: boolean;
  onChangeStep: (v: CommitToImproveModalStep) => void;
  onSubmit: () => void;
  checkCanGoBack: (contentKey: string, callback: () => void) => void;
  isTestDrive?: boolean;
}

const handleUpdateCommitmentContent = (
  exerciseCommitment: ExerciseCommitmentModel,
  contentKey: ExerciseCommitmentContentKey
) =>
  action((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    exerciseCommitment.commitments[contentKey] = event.target.value;
  });

const CommitToImproveModalWriteContentStep: React.FC<CommitToImproveModalSelectStatementStepProps> =
  observer(
    ({
      exerciseCommitment,
      isSubmitting,
      contentKey,
      submitButtonText,
      submitButtonTextSubmitting,
      onSubmit,
      onBack,
      isTestDrive,
    }) => (
      <div>
        <div className="rows">
          <TextArea
            onChange={handleUpdateCommitmentContent(exerciseCommitment, contentKey)}
            defaultValue={exerciseCommitment.commitments[contentKey]}
            placeholder="Type answer here..."
          />
        </div>

        <div className="you-stuck">
          <p>
            <span>Are you stuck?</span>&nbsp;
            <a href={!isTestDrive ? RUSS_360_COACHING_URL : '#'}>
              Set up a coaching call and we'll walk you through this.
            </a>
          </p>
        </div>

        <div className="actions">
          <button className="btn btn-link" onClick={onBack}>
            Back
          </button>
          <button
            className="btn btn-primary"
            disabled={!exerciseCommitment.commitments[contentKey] || isSubmitting || isTestDrive}
            onClick={onSubmit}
          >
            {(isSubmitting && submitButtonTextSubmitting) || submitButtonText}
          </button>
        </div>
      </div>
    )
  );

export const CommitToImproveModalWriteChallengeStep: React.FC<
  CommitToImproveModalWriteContentBaseProps
> = (props) => (
  <CommitToImproveModalWriteContentStep
    {...props}
    contentKey={ExerciseCommitmentContentKey.challenge}
    submitButtonText="Continue"
    onBack={() =>
      props.checkCanGoBack(ExerciseCommitmentContentKey.challenge, () => {
        props.onChangeStep(CommitToImproveModalStep.SELECT_STATEMENT);
        props.exerciseCommitment.commitments.challenge = '';
        props.exerciseCommitment.commitments.change = '';
      })
    }
    onSubmit={() => props.onChangeStep(CommitToImproveModalStep.WRITE_COMMITMENT)}
  />
);

export const CommitToImproveModalWriteChangeStep: React.FC<
  CommitToImproveModalWriteContentBaseProps
> = (props) => (
  <CommitToImproveModalWriteContentStep
    {...props}
    contentKey={ExerciseCommitmentContentKey.change}
    submitButtonText="Submit"
    submitButtonTextSubmitting="Submitting"
    onBack={() => props.onChangeStep(CommitToImproveModalStep.WRITE_CHALLENGE)}
    onSubmit={props.onSubmit}
  />
);
