import { observable } from 'mobx';

import { SubscriptionMemberStore } from 'app/stores/SubscriptionMemberStore';

import Model, { ModelJson } from './Model';

export class SubscriptionMemberModel extends Model {
  static _store: SubscriptionMemberStore;

  readonly id: number | string;
  @observable name: string;
  @observable email: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as SubscriptionMemberModel;
  }

  static getOrNew(id: number) {
    return this._getOrNew(id) as SubscriptionMemberModel;
  }

  static get(id: number) {
    return this._get(id) as SubscriptionMemberModel;
  }
}

export default SubscriptionMemberModel;
