import { computed, observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { OnboardingToolIntroductionStore } from 'app/stores';

export enum ToolType {
  Perspective = 'perspective',
}
export class OnboardingToolIntroductionModel extends Model {
  static _store: OnboardingToolIntroductionStore;

  @observable id: number;
  @observable description: string;
  @observable type: ToolType;
  @observable title: string;
  @observable cta_text: string;
  @observable secondary_cta_text: string;
  @observable cta_url: string;

  @computed
  get imageUrl(): string {
    return `/images/onboarding/introduction/${this.type}.png`;
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as OnboardingToolIntroductionModel;
  }
  static getOrNew(id) {
    return this._getOrNew(id) as OnboardingToolIntroductionModel;
  }
  static get(id) {
    return this._get(id) as OnboardingToolIntroductionModel;
  }
}
