import React from 'react';

import {
  ArrowLeftOutlined,
  PrinterOutlined,
  SettingOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { computed } from 'mobx';
import { Link } from 'react-router-dom';

import './ReportTopBarContainer.scss';

import AppHeader from 'app/components/features/AppHeader';
import ClipboardButton from 'app/components/ui/ClipboardButton';
import Logo from 'app/components/ui/Logo';
import { EXERCISE_REPORT_PRINT_MODES } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';

import My360ReportUIStore from '../../My360ReportUIStore';

const { SubMenu } = Menu;
interface ReportTopBarContainerProps {
  uiStore: My360ReportUIStore;
  scrollDirection: string;
  onPrint: () => void;
}

export class ReportTopBarContainer extends React.Component<ReportTopBarContainerProps> {
  @computed
  get copyLinkMenuItem() {
    const { report } = this.props.uiStore;

    return (
      <ClipboardButton
        link
        targetText={report?.routes?.personalReport}
        className="copy-link-btn"
        copyText="Copy link to share report"
        copiedText="Copied link to share report"
      />
    );
  }

  setPrintModeAndPrint = (mode) => {
    const { uiStore, onPrint } = this.props;

    uiStore.setPrintMode(mode);
    onPrint();
  };

  @computed
  get dropdownMenu() {
    const items = [
      {
        label: this.copyLinkMenuItem,
        key: 'copy-link',
        icon: <ShareAltOutlined />,
      },
      {
        label: (
          <SubMenu
            icon={<PrinterOutlined />}
            key="admin-menu"
            title="Print report"
            className="admin-sub-menu"
          >
            <Menu.Item
              icon={<PrinterOutlined />}
              onClick={() => this.setPrintModeAndPrint(EXERCISE_REPORT_PRINT_MODES.SUMMARY)}
            >
              Summary View
            </Menu.Item>
            <Menu.Item
              icon={<PrinterOutlined />}
              onClick={() => this.setPrintModeAndPrint(EXERCISE_REPORT_PRINT_MODES.BY_CATEGORY)}
            >
              Scatterplots By Category
            </Menu.Item>
            <Menu.Item
              icon={<PrinterOutlined />}
              onClick={() => this.setPrintModeAndPrint(EXERCISE_REPORT_PRINT_MODES.PER_PAGE)}
            >
              Scatterplots Per Page
            </Menu.Item>
          </SubMenu>
        ),
        key: 'print-report',
      },
    ];

    return {
      items,
    };
  }

  render() {
    const { uiStore, scrollDirection } = this.props;
    const { report } = uiStore;

    return (
      <div className={cx('reflect-360-header', scrollDirection)}>
        <AppHeader
          hideLogo
          forceLayout
          leftComponent={
            <Link to={ServerRouteHelper.dashboard.home()} className="back-button">
              <ArrowLeftOutlined className="left-icon" />
              Return to dashboard
            </Link>
          }
          rightComponent={
            <Dropdown menu={this.dropdownMenu} trigger={['click']}>
              <Button className="share-btn">
                <SettingOutlined /> Share & more
              </Button>
            </Dropdown>
          }
          centerComponent={
            <div className="logo-container">
              <Logo />
              <div className="pulse-info">
                <h2>360 Report</h2>
                <div className="date-info">{report?.exercise?.closedAtFormatted}</div>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default ReportTopBarContainer;
