import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import MbtiDataStore from 'app/stores/MbtiDataStore';

export class MbtiDataModel extends Model {
  static _store: MbtiDataStore;

  @observable id: string;
  @observable title: string;
  @observable intro: string;
  @observable population: string;
  @observable descriptions: string[];
  @observable strengths: string[];
  @observable challenges: string[];

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as MbtiDataModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as MbtiDataModel;
  }

  static get(id) {
    return this._get(id) as MbtiDataModel;
  }
}

export default MbtiDataModel;
