import React, { Component, ReactNode } from 'react';

import { Divider } from 'antd';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';

import styles from './SelectHabitsCard.module.scss';

import EnhancedCard from 'app/components/ui/EnhancedCard';
import { Clickable } from 'app/components/ui/EnhancedCard/CardFooter/CardFooter';
import { PulseStatementModel } from 'app/models';
import PulseHabitsContext from 'app/pages/dashboard/Pulse/Context/PulseHabitsContext';

import AddCustomHabit from './AddCustomHabit';
import HabitItem from './HabitItem';

interface SelectHabitsCardProps extends RouteComponentProps {
  habits: PulseStatementModel[];
  onCustomHabit: (text: string) => void;
  onDeleteHabit: (habit: PulseStatementModel) => void;
}

export class SelectHabitsCard extends Component<SelectHabitsCardProps> {
  static contextType = PulseHabitsContext;

  private get habits(): PulseStatementModel[] {
    return this.props.habits;
  }

  private get validHabits(): PulseStatementModel[] {
    return this.context.uiStore.validHabits;
  }

  @computed
  private get primaryButton(): Clickable {
    const { isSaving } = this.context.uiStore;

    return {
      label: isSaving ? 'Saving...' : 'Next: add colleagues',
      disabled: this.validHabits.length === 0 || isSaving,
      onClick: this.handleNext,
    };
  }

  @computed
  private get secondaryButton(): Clickable {
    return {
      label: 'Preview survey',
      disabled: this.validHabits.length === 0,
      onClick: this.context.uiStore.togglePreviewModal,
    };
  }

  private handleCustomHabit = (text: string): void => {
    this.props.onCustomHabit(text);
  };

  private handleNext = async (): Promise<void> => {
    await this.context.uiStore.savePulse();

    const url = this.context.uiStore.getStepUrl('invite');
    this.props.history.push(url);
  };

  public render(): ReactNode {
    const { onDeleteHabit } = this.props;

    return (
      <EnhancedCard
        className="select-habits-card"
        heading="Your selected Habits"
        footerPrimaryButton={this.primaryButton}
        footerSecondaryButton={this.secondaryButton}
      >
        <div className={styles.templatedHabitSection}>
          {this.habits.length === 0 && (
            <div className={styles.noHabits}>
              <p>
                You didn’t add any habits yet. Select habits from the left or add custom ones below.
              </p>
            </div>
          )}

          {this.habits.length > 0 && (
            <div className={styles.habitItems}>
              {this.habits.map((habit) => (
                <HabitItem
                  key={habit.id}
                  habit={habit}
                  onDelete={onDeleteHabit}
                  isEditing={this.context.uiStore.isEditing}
                />
              ))}
            </div>
          )}
        </div>
        <Divider className={styles.sectionDivider} />
        <div className={styles.customHabitSection}>
          <AddCustomHabit onCustomHabit={this.handleCustomHabit} />
        </div>
      </EnhancedCard>
    );
  }
}

export default withRouter(observer(SelectHabitsCard));
