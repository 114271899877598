import React, { ReactNode } from 'react';

import cx from 'classnames';
import { Modal as BSModal, ModalBody } from 'reactstrap';

import './Modal.scss';

import Footer from './Footer';
import Header from './Header';

export interface ModalAction {
  label: string;
  onClick: React.MouseEventHandler;
  [key: string]: any;
  id?: string;
  className?: string;
}

export interface ModalProps {
  isOpen?: boolean;
  size?: string;
  title?: ReactNode | string;
  footer?: any;
  primaryAction?: ModalAction;
  secondaryAction?: ModalAction;
  className?: string;
  bodyClassName?: string;
  footerClassName?: string;
  dataTrackPrefix?: string;
  closeButtonTag?: string;
  scrollable?: boolean;
  loading?: boolean;
  headerClassName?: string;
  onBack?: () => void;
  onToggle?: () => void;
}

export const Modal: React.StatelessComponent<ModalProps> = ({
  isOpen,
  size,
  title,
  children,
  footer,
  primaryAction,
  secondaryAction,
  className,
  bodyClassName,
  footerClassName,
  dataTrackPrefix,
  closeButtonTag,
  scrollable,
  loading,
  headerClassName,
  onBack,
  onToggle,
  ...rest
}) => {
  const hasActions = !!(primaryAction || secondaryAction);

  return (
    <BSModal
      isOpen={isOpen}
      toggle={onToggle}
      size={size}
      className={cx('custom-modal', className)}
      scrollable={scrollable}
      {...rest}
    >
      {!!title && (
        <Header
          loading={loading}
          title={title}
          onBack={onBack}
          onToggle={onToggle}
          dataTrackPrefix={dataTrackPrefix}
          closeButtonTag={closeButtonTag}
          className={headerClassName}
        />
      )}
      <ModalBody className={bodyClassName}>{children}</ModalBody>
      {(hasActions || footer) && (
        <Footer
          footerClassName={footerClassName}
          content={footer}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
        />
      )}
    </BSModal>
  );
};

Modal.defaultProps = {
  size: 'lg',
};

export default Modal;
