import React, { useState } from 'react';

import { ArrowDownOutlined } from '@ant-design/icons';
import cx from 'classnames';

import './MobileCardList.scss';

import { ExerciseStatementCardProps } from 'app/components/features/ExerciseStatementCard/ExerciseStatementCard';

import AllStatementsModal from '../AllStatementsModal';
import CardCarouselView from '../CardCarouselView';

export interface MobileCardListProps {
  title?: React.ReactElement;
  statements: ExerciseStatementCardProps[];
  onDragArrowDoubleClick: () => void;
  onSubmit?: () => void;
  renderTestDriveView?: boolean;
  exerciseIs360?: boolean;
  isCC?: boolean;
}

export const MobileCardList: React.StatelessComponent<MobileCardListProps> = ({
  title = '',
  statements,
  onDragArrowDoubleClick,
  onSubmit,
  renderTestDriveView,
  exerciseIs360,
  isCC,
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const listText = exerciseIs360
    ? 'Drag the cards onto the grid'
    : 'Drag the cards onto the grid to rate your team';

  return (
    <div className="exercise-statement-card-list-mobile">
      {title && (
        <div className={cx('outer-title', { 'cc-title': isCC })}>
          <div>{title}</div>
        </div>
      )}

      <div className="main">
        <div className="inner-title">
          {listText}
          <span
            className="icon-container"
            id="double-click-auto-drag"
            onDoubleClick={onDragArrowDoubleClick}
          >
            <ArrowDownOutlined data-testid="drag-right-arrow" />
          </span>
        </div>

        <CardCarouselView
          statements={statements}
          onSeeAllStatements={toggleModal}
          onSubmit={onSubmit}
          renderTestDriveView={renderTestDriveView}
          render360View={exerciseIs360}
        />
      </div>

      <AllStatementsModal statements={statements} isOpen={showModal} onToggle={toggleModal} />
    </div>
  );
};

export default MobileCardList;
