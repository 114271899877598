import { observable } from 'mobx';

import BaseModel from 'app/models/BaseModel';
import { ModelList } from 'app/models/ModelList';

import MemberModel from './MemberModel';

export class TeammatesWithPerspectiveModel extends BaseModel {
  @observable members = new ModelList<MemberModel>(MemberModel);
  @observable otherMembersCount: number;

  static fromJson(json: Record<string, any>) {
    return this._fromJson(json) as TeammatesWithPerspectiveModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeammatesWithPerspectiveModel;
  }

  static get(id) {
    return this._get(id) as TeammatesWithPerspectiveModel;
  }
}
