import { ModalsUiStore } from 'app/components/features/AppLayout/stores';
import {
  STORE_ADMIN_ORG_CONTENT,
  STORE_ADMIN_PULSE_TEMPLATES,
  STORE_ADMIN_STATS,
  STORE_ALIGN,
  STORE_ALIGN_GUIDANCE,
  STORE_ANALYTICS_FILTERS,
  STORE_AUTH,
  STORE_BREAKPOINT,
  STORE_COACH,
  STORE_CONTENT_SECTION,
  STORE_CONTRACT,
  STORE_COOKIE_PREF,
  STORE_CSV_UPLOAD,
  STORE_CUSTOM_LANDING_PAGES_LINK,
  STORE_DISCUSSION_GUIDE,
  STORE_DISCUSSION_PROMPT,
  STORE_DISCUSSION_PROMPT_ANSWER,
  STORE_DISCUSSION_SPACE,
  STORE_DISCUSSION_SPACE_AGENDA,
  STORE_DISCUSSION_SPACE_NOTE,
  STORE_DISCUSSION_SPACE_RAISED_HAND,
  STORE_DISCUSSION_SPACE_VOTE,
  STORE_DISCUSSION_SPACE_WEBSOCKET_STATE,
  STORE_EMAIL_ACTIVITY,
  STORE_ENGAGEMENT_ANALYTICS,
  STORE_ENTITY_BLOCK,
  STORE_ENTITY_LABEL,
  STORE_ENTITY_STATE,
  STORE_EXERCISE_ANSWERS,
  STORE_EXERCISE_COMMITMENT,
  STORE_EXERCISE_REPORT,
  STORE_EXERCISE_STATUS,
  STORE_EXERCISE_TYPE,
  STORE_FEATURE,
  STORE_INTELLIGENCE_ACTION,
  STORE_MAIL,
  STORE_MBTI_DATA,
  STORE_MEMBER,
  STORE_MEMBER_AVATAR,
  STORE_MEMBER_CONFIRMATION,
  STORE_MEMBER_PERSPECTIVE_RESULT,
  STORE_MEMBER_RATING,
  STORE_MEMBER_STATE,
  STORE_MENU,
  STORE_METABASE,
  STORE_MODAL_UI,
  STORE_MY360,
  STORE_MY360S_STATS,
  STORE_NAVIGATION,
  STORE_ONBOARDING,
  STORE_ONBOARDING_TOOL_INTRODUCTION,
  STORE_ORG_NOTICE,
  STORE_ORGANIZATION,
  STORE_ORGANIZATION_CONTENT,
  STORE_ORGANIZATION_REPORT_ROLLUP,
  STORE_ORGANIZATION_STATS,
  STORE_PAIRED_NOTE,
  STORE_PERSONAL_PULSE_REPORT,
  STORE_PERSONAL_STATEMENT_WITH_ANSWER,
  STORE_PERSPECTIVE,
  STORE_PERSPECTIVE_DIMENSION,
  STORE_PERSPECTIVE_DIMENSION_LENS,
  STORE_PERSPECTIVE_MANAGER_INSIGHTS,
  STORE_PERSPECTIVE_MEMBER,
  STORE_PERSPECTIVE_MEMBER_RESULT,
  STORE_PERSPECTIVE_PAIR_SCORE_DIFF,
  STORE_PERSPECTIVE_PERSONAL_REPORT,
  STORE_PERSPECTIVE_QUESTION,
  STORE_PERSPECTIVE_SCORE_DIFF,
  STORE_PERSPECTIVE_TEAMMATE_DIFF,
  STORE_PROGRAM,
  STORE_PULSE_ACTIVITY,
  STORE_PULSE_FOR_MEMBER,
  STORE_PULSE_FOR_TEAM,
  STORE_PULSE_REMINDER,
  STORE_PULSE_RESPONSE,
  STORE_PULSE_STATEMENT,
  STORE_PULSE_TEMPLATE_GROUP,
  STORE_ROLE,
  STORE_SCORE,
  STORE_SESSION,
  STORE_SINGLE_PLAYER,
  STORE_SUBSCRIPTION,
  STORE_SUBSCRIPTION_ITEM,
  STORE_SUBSCRIPTION_ITEM_PROVIDER,
  STORE_SUBSCRIPTION_MEMBER,
  STORE_SUBSCRIPTION_ORDER,
  STORE_SUBSCRIPTION_ORDER_ITEM,
  STORE_TEAM,
  STORE_TEAM_CARD_CHECKLIST,
  STORE_TEAM_GAME,
  STORE_TEAM_GAME_PARTICIPANT,
  STORE_TEAM_GROUP,
  STORE_TEAM_GROUP_DIVISION,
  STORE_TEAM_PERSPECTIVE_RESULT,
  STORE_TEAM_REPORT_GROUP,
  STORE_TEAM_SUBSCRIPTION_STATUS,
} from 'app/constants';

import AdminOrgContentStore from './AdminOrgContentStore';
import AdminPulseTemplateStore from './AdminPulseTemplateStore';
import AdminStatsStore from './AdminStatsStore';
import AlignGuidanceStore from './AlignGuidanceStore';
import AlignStore from './AlignStore';
import AnalyticsFiltersStore from './AnalyticsFiltersStore';
import { AuthStore } from './AuthStore';
import BreakpointStore from './BreakpointStore';
import { CoachStore } from './CoachStore';
import ContentSectionStore from './ContentSectionStore';
import ContractStore from './ContractStore';
import CookiePrefStore from './CookiePrefStore';
import CsvUploadStore from './CsvUploadStore';
import CustomLandingPagesLinkStore from './CustomLandingPagesLinkStore';
import DiscussionGuideStore from './DiscussionGuideStore';
import DiscussionPromptAnswerStore from './DiscussionPromptAnswerStore';
import DiscussionPromptStore from './DiscussionPromptStore';
import DiscussionSpaceAgendaStore from './DiscussionSpaceAgendaStore';
import DiscussionSpaceNoteStore from './DiscussionSpaceNoteStore';
import DiscussionSpaceRaisedHandStore from './DiscussionSpaceRaisedHandStore';
import DiscussionSpaceStore from './DiscussionSpaceStore';
import DiscussionSpaceVoteStore from './DiscussionSpaceVoteStore';
import DiscussionSpaceWebsocketStateStore from './DiscussionSpaceWebsocketStateStore';
import EmailActivityStore from './EmailActivityStore';
import EngagementAnalyticsStore from './EngagementAnalyticsStore';
import EntityBlockStore from './EntityBlockStore';
import EntityLabelStore from './EntityLabelStore';
import EntityStateStore from './EntityStateStore';
import ExerciseAnswersStore from './ExerciseAnswersStore';
import ExerciseCommitmentStore from './ExerciseCommitmentStore';
import ExerciseReportStore from './ExerciseReportStore';
import ExerciseStatusStore from './ExerciseStatusStore';
import ExerciseTypeStore from './ExerciseTypeStore';
import FeatureStore from './FeatureStore';
import IntelligenceActionStore from './IntelligenceActionStore';
import MailStore from './MailStore';
import MbtiDataStore from './MbtiDataStore';
import MemberAvatarStore from './MemberAvatarStore';
import MemberConfirmationStore from './MemberConfirmationStore';
import MemberPerspectiveResultStore from './MemberPerspectiveResultStore';
import MemberRatingStore from './MemberRatingStore';
import MemberStateStore from './MemberStateStore';
import MemberStore from './MemberStore';
import MenuStore from './MenuStore';
import MetabaseStore from './MetabaseStore';
import My360sStatsStore from './My360sStatsStore';
import My360Store from './My360Store';
import NavigationStore from './NavigationStore';
import OnboardingStore from './OnboardingStore';
import OnboardingToolIntroductionStore from './OnboardingToolIntroductionStore';
import OrganizationContentStore from './OrganizationContentStore';
import OrganizationReportRollupStore from './OrganizationReportRollupStore';
import OrganizationStatsStore from './OrganizationStatsStore';
import { OrganizationStore } from './OrganizationStore';
import OrgNoticeStore from './OrgNoticeStore';
import PairedNoteStore from './PairedNoteStore';
import PersonalStatementWithAnswerStore from './PersonalStatementWithAnswerStore';
import PerspectiveDimensionLensStore from './PerspectiveDimensionLensStore';
import PerspectiveDimensionStore from './PerspectiveDimensionStore';
import PerspectiveManagerInsightsStore from './PerspectiveManagerInsightsStore';
import PerspectiveMemberResultStore from './PerspectiveMemberResultStore';
import PerspectiveMemberStore from './PerspectiveMemberStore';
import PerspectivePairScoreDiffStore from './PerspectivePairScoreDiffStore';
import PerspectivePersonalReportStore from './PerspectivePersonalReportStore';
import PerspectiveQuestionStore from './PerspectiveQuestionStore';
import PerspectiveScoreDiffStore from './PerspectiveScoreDiffStore';
import PerspectiveStore from './PerspectiveStore';
import PerspectiveTeammateDiffStore from './PerspectiveTeammateDiffStore';
import ProgramStore from './ProgramStore';
import PulseActivityStore from './PulseActivityStore';
import PulseForMemberStore from './PulseForMemberStore';
import PulseForTeamStore from './PulseForTeamStore';
import PulseReminderStore from './PulseReminderStore';
import PulseReportStore from './PulseReportStore';
import PulseResponseStore from './PulseResponseStore';
import PulseStatementStore from './PulseStatementStore';
import PulseTemplateGroupStore from './PulseTemplateGroupStore';
import RoleStore from './RoleStore';
import ScoreStore from './ScoreStore';
import SessionStore from './SessionStore';
import SinglePlayerStore from './SinglePlayerStore';
import SubscriptionItemProviderStore from './SubscriptionItemProviderStore';
import SubscriptionItemStore from './SubscriptionItemStore';
import SubscriptionMemberStore from './SubscriptionMemberStore';
import SubscriptionOrderItemStore from './SubscriptionOrderItemStore';
import SubscriptionOrderStore from './SubscriptionOrderStore';
import SubscriptionStore from './SubscriptionStore';
import TeamCardChecklistStore from './TeamCardChecklistStore';
import TeamGameParticipantStore from './TeamGameParticipantStore';
import TeamGameStore from './TeamGameStore';
import TeamGroupDivisionStore from './TeamGroupDivisionStore';
import TeamGroupReportStore from './TeamGroupReportStore';
import TeamGroupStore from './TeamGroupStore';
import TeamPerspectiveResultStore from './TeamPerspectiveResultStore';
import TeamsSubscriptionStatusStore from './TeamsSubscriptionStatusStore';
import TeamStore from './TeamStore';

// Don't use this function outside tests.
export function createStoresInstance() {
  return {
    [STORE_AUTH]: new AuthStore(),
    [STORE_MEMBER]: new MemberStore(),
    [STORE_MEMBER_STATE]: new MemberStateStore(),
    [STORE_ORGANIZATION]: new OrganizationStore(),
    [STORE_PERSPECTIVE]: new PerspectiveStore(),
    [STORE_TEAM]: new TeamStore(),
    [STORE_MY360]: new My360Store(),
    [STORE_TEAM_REPORT_GROUP]: new TeamGroupReportStore(),
    [STORE_EXERCISE_TYPE]: new ExerciseTypeStore(),
    [STORE_SCORE]: new ScoreStore(),
    [STORE_DISCUSSION_GUIDE]: new DiscussionGuideStore(),
    [STORE_ALIGN_GUIDANCE]: new AlignGuidanceStore(),
    [STORE_CONTENT_SECTION]: new ContentSectionStore(),
    [STORE_MAIL]: new MailStore(),
    [STORE_ALIGN]: new AlignStore(),
    [STORE_FEATURE]: new FeatureStore(),
    [STORE_EXERCISE_REPORT]: new ExerciseReportStore(),
    [STORE_SINGLE_PLAYER]: new SinglePlayerStore(),
    [STORE_ROLE]: new RoleStore(),
    [STORE_ENTITY_STATE]: new EntityStateStore(),
    [STORE_ENTITY_LABEL]: new EntityLabelStore(),
    [STORE_EXERCISE_ANSWERS]: new ExerciseAnswersStore(),
    [STORE_BREAKPOINT]: new BreakpointStore(),
    [STORE_NAVIGATION]: new NavigationStore(),
    [STORE_PULSE_FOR_TEAM]: new PulseForTeamStore(),
    [STORE_PULSE_RESPONSE]: new PulseResponseStore(),
    [STORE_PULSE_STATEMENT]: new PulseStatementStore(),
    [STORE_PULSE_ACTIVITY]: new PulseActivityStore(),
    [STORE_PULSE_REMINDER]: new PulseReminderStore(),
    [STORE_PULSE_TEMPLATE_GROUP]: new PulseTemplateGroupStore(),
    [STORE_DISCUSSION_PROMPT]: new DiscussionPromptStore(),
    [STORE_DISCUSSION_PROMPT_ANSWER]: new DiscussionPromptAnswerStore(),
    [STORE_EXERCISE_COMMITMENT]: new ExerciseCommitmentStore(),
    [STORE_DISCUSSION_SPACE]: new DiscussionSpaceStore(),
    [STORE_DISCUSSION_SPACE_AGENDA]: new DiscussionSpaceAgendaStore(),
    [STORE_DISCUSSION_SPACE_NOTE]: new DiscussionSpaceNoteStore(),
    [STORE_DISCUSSION_SPACE_RAISED_HAND]: new DiscussionSpaceRaisedHandStore(),
    [STORE_DISCUSSION_SPACE_WEBSOCKET_STATE]: new DiscussionSpaceWebsocketStateStore(),
    [STORE_ORGANIZATION_REPORT_ROLLUP]: new OrganizationReportRollupStore(),
    [STORE_DISCUSSION_SPACE_VOTE]: new DiscussionSpaceVoteStore(),
    [STORE_ORGANIZATION_CONTENT]: new OrganizationContentStore(),
    [STORE_PAIRED_NOTE]: new PairedNoteStore(),
    [STORE_MEMBER_RATING]: new MemberRatingStore(),
    [STORE_ADMIN_STATS]: new AdminStatsStore(),
    [STORE_ADMIN_ORG_CONTENT]: new AdminOrgContentStore(),
    [STORE_EMAIL_ACTIVITY]: new EmailActivityStore(),
    [STORE_PERSPECTIVE_MANAGER_INSIGHTS]: new PerspectiveManagerInsightsStore(),
    [STORE_PERSONAL_PULSE_REPORT]: new PulseReportStore(),
    [STORE_COACH]: new CoachStore(),
    [STORE_PULSE_FOR_MEMBER]: new PulseForMemberStore(),
    [STORE_ADMIN_PULSE_TEMPLATES]: new AdminPulseTemplateStore(),
    [STORE_MEMBER_CONFIRMATION]: new MemberConfirmationStore(),
    [STORE_TEAM_GAME]: new TeamGameStore(),
    [STORE_TEAM_GAME_PARTICIPANT]: new TeamGameParticipantStore(),
    [STORE_CSV_UPLOAD]: new CsvUploadStore(),
    [STORE_PERSPECTIVE_DIMENSION]: new PerspectiveDimensionStore(),
    [STORE_PERSPECTIVE_DIMENSION_LENS]: new PerspectiveDimensionLensStore(),
    [STORE_EXERCISE_STATUS]: new ExerciseStatusStore(),
    [STORE_CUSTOM_LANDING_PAGES_LINK]: new CustomLandingPagesLinkStore(),
    [STORE_PROGRAM]: new ProgramStore(),
    [STORE_MY360S_STATS]: new My360sStatsStore(),
    [STORE_MEMBER_AVATAR]: new MemberAvatarStore(),
    [STORE_MBTI_DATA]: new MbtiDataStore(),
    [STORE_ORGANIZATION_STATS]: new OrganizationStatsStore(),
    [STORE_PERSPECTIVE_MEMBER]: new PerspectiveMemberStore(),
    [STORE_COOKIE_PREF]: new CookiePrefStore(),
    [STORE_PERSPECTIVE_MEMBER_RESULT]: new PerspectiveMemberResultStore(),
    [STORE_METABASE]: new MetabaseStore(),
    [STORE_PERSPECTIVE_QUESTION]: new PerspectiveQuestionStore(),
    [STORE_TEAM_GROUP]: new TeamGroupStore(),
    [STORE_TEAM_GROUP_DIVISION]: new TeamGroupDivisionStore(),
    [STORE_MENU]: new MenuStore(),
    [STORE_PERSPECTIVE_PAIR_SCORE_DIFF]: new PerspectivePairScoreDiffStore(),
    [STORE_PERSONAL_STATEMENT_WITH_ANSWER]: new PersonalStatementWithAnswerStore(),
    [STORE_PERSPECTIVE_PERSONAL_REPORT]: new PerspectivePersonalReportStore(),
    [STORE_PERSPECTIVE_TEAMMATE_DIFF]: new PerspectiveTeammateDiffStore(),
    [STORE_PERSPECTIVE_SCORE_DIFF]: new PerspectiveScoreDiffStore(),
    [STORE_TEAM_CARD_CHECKLIST]: new TeamCardChecklistStore(),
    [STORE_ONBOARDING]: new OnboardingStore(),
    [STORE_ONBOARDING_TOOL_INTRODUCTION]: new OnboardingToolIntroductionStore(),
    [STORE_CONTRACT]: new ContractStore(),
    [STORE_ANALYTICS_FILTERS]: new AnalyticsFiltersStore(),
    [STORE_MEMBER_PERSPECTIVE_RESULT]: new MemberPerspectiveResultStore(),
    [STORE_TEAM_PERSPECTIVE_RESULT]: new TeamPerspectiveResultStore(),
    [STORE_MODAL_UI]: new ModalsUiStore(),
    [STORE_ORG_NOTICE]: new OrgNoticeStore(),
    [STORE_SUBSCRIPTION_ORDER]: new SubscriptionOrderStore(),
    [STORE_TEAM_SUBSCRIPTION_STATUS]: new TeamsSubscriptionStatusStore(),
    [STORE_SUBSCRIPTION_ORDER_ITEM]: new SubscriptionOrderItemStore(),
    [STORE_SUBSCRIPTION_ITEM]: new SubscriptionItemStore(),
    [STORE_SUBSCRIPTION_ITEM_PROVIDER]: new SubscriptionItemProviderStore(),
    [STORE_SUBSCRIPTION_MEMBER]: new SubscriptionMemberStore(),
    [STORE_SUBSCRIPTION]: new SubscriptionStore(),
    [STORE_ENTITY_BLOCK]: new EntityBlockStore(),
    [STORE_SESSION]: new SessionStore(),
    [STORE_ENGAGEMENT_ANALYTICS]: new EngagementAnalyticsStore(),
    [STORE_INTELLIGENCE_ACTION]: new IntelligenceActionStore(),
  };
}

let stores = null;
export function createStores() {
  if (!stores) {
    stores = createStoresInstance();
  }
  return stores;
}
