import React, { ReactNode } from 'react';

import { Button } from 'antd';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import { STORE_ADMIN_ORG_CONTENT } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import AdminOrgContentModel from 'app/models/AdminOrgContentModel';
import { AdminOrgContentStore } from 'app/stores';

export interface OrgContentOrgListProps extends RouteComponentProps {
  adminOrgContentStore?: AdminOrgContentStore;
}

export class OrgContentOrgList extends React.Component<OrgContentOrgListProps> {
  constructor(props: OrgContentOrgListProps) {
    super(props);

    this.init();
  }

  public get orgs(): AdminOrgContentModel[] {
    return this.props.adminOrgContentStore.orgs.items;
  }

  protected async init(): Promise<void> {
    const { adminOrgContentStore } = this.props;

    await adminOrgContentStore.getOrgsWithOverrides();
  }

  protected handleEditOrgContent(item: AdminOrgContentModel): void {
    this.props.adminOrgContentStore.setActiveOrg(item);
    const url = ServerRouteHelper.admin.orgContent.edit(item.id);
    this.props.history.push(url);
  }

  protected formatOverrides(list: string[]): ReactNode {
    return (
      <ul>
        {list.map((item, i) => (
          <li key={`override-${i}`}>{item}</li>
        ))}
      </ul>
    );
  }

  protected formatRow(item: AdminOrgContentModel): ReactNode {
    return (
      <tr key={item.id}>
        <td key="id">{item.id}</td>
        <td key="name">{item.name}</td>
        <td key="overrides">{this.formatOverrides(item.overrides)}</td>
        <td key="edit">
          <Button type="primary" ghost onClick={() => this.handleEditOrgContent(item)}>
            View/Edit
          </Button>
        </td>
      </tr>
    );
  }

  render(): ReactNode {
    if (this.orgs.length === 0) {
      return <p>No organization specific overrides found</p>;
    }

    return (
      <table className="modern-dash-table">
        <thead>
          <tr>
            <th key="h1">#</th>
            <th key="h2">Name</th>
            <th key="h3">Active Overrides</th>
            <th key="h4"></th>
          </tr>
        </thead>
        <tbody>{this.orgs.map((item) => this.formatRow(item))}</tbody>
      </table>
    );
  }
}

export default inject(STORE_ADMIN_ORG_CONTENT)(observer(OrgContentOrgList));
