import React, { lazy, Suspense } from 'react';

import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const DiscussionSpace = lazy(() => import('./DiscussionSpace'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route exact path="/discussion-spaces/:token" component={DiscussionSpace} />
    </Switch>
  </Suspense>
);
