import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const Exercise = lazy(() => import('../align/Exercise'));
const Report = lazy(() => import('./Report'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route exact path="/single-player/exercise/:secretCode/:step?" component={Exercise} />
      <Route exact path="/single-player/:token/report" component={Report} />
    </Switch>
  </Suspense>
);
