import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { DiscussionSpaceVoteModel, DiscussionSpaceVoteTypes } from 'app/models';
import Store from 'app/stores/Store';

export class DiscussionSpaceVoteStore extends Store<DiscussionSpaceVoteModel> {
  @observable token;
  @action setToken = (token: string): void => {
    this.token = token;
  };

  @observable votes: DiscussionSpaceVoteModel[] = [];
  @action setVotes = (votes: DiscussionSpaceVoteModel[]): void => {
    this.votes = votes;
  };

  constructor() {
    super();
    DiscussionSpaceVoteModel._store = this;
  }

  async upVote(
    discussionSpaceId: number,
    entityType: DiscussionSpaceVoteTypes,
    entityId: number
  ): Promise<void> {
    let url = ServerRouteHelper.api.discussionSpace.votes.vote(
      discussionSpaceId,
      entityType,
      entityId
    );

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    const config = { url };
    const response = await this.apiService.newPost(config);

    if (!response) {
      return;
    }

    const vote = DiscussionSpaceVoteModel.fromJson(response.data);

    this.addVote(vote);
  }

  async downVote(
    discussionSpaceId: number,
    entityType: DiscussionSpaceVoteTypes,
    entityId: number,
    memberId: number
  ): Promise<void> {
    let url = ServerRouteHelper.api.discussionSpace.votes.vote(
      discussionSpaceId,
      entityType,
      entityId
    );

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    this.apiService.delete(url);

    const vote = this.votes.find(({ discussion_space_id, entity_type, entity_id, member_id }) => {
      return (
        discussion_space_id === discussionSpaceId &&
        entity_type === entityType &&
        entity_id === entityId &&
        member_id === memberId
      );
    });
    this.deleteVote(vote);
  }

  async listVotes(discussionSpaceId: number): Promise<void> {
    let url = ServerRouteHelper.api.discussionSpace.votes.list(discussionSpaceId);

    if (this.token) {
      url = url.withParams({ token: this.token });
    }

    const config = {
      url,
      showGenericError: true,
    };

    const response = await this.apiService.newGet(config);

    if (response?.data) {
      this.setVotes(response.data.map((vote) => DiscussionSpaceVoteModel.fromJson(vote)));
    }
  }

  @action
  addVote = (vote: DiscussionSpaceVoteModel): void => {
    if (this.votes.find(({ id }) => id === vote.id)) {
      return;
    }

    this.votes.push(vote);
  };

  @action
  deleteVote = (vote: DiscussionSpaceVoteModel): void => {
    const index = this.votes.findIndex(({ id }) => id === vote.id);
    if (index !== -1) {
      this.votes.splice(index, 1);
    }
  };
}

export default DiscussionSpaceVoteStore;
