import { isNumber, keyBy, pick, uniqBy, uniqueId } from 'lodash';
import { action, computed, observable, reaction, when } from 'mobx';

import { NewMemberValues } from 'app/components/features/NewMemberForm';
import { Clickable } from 'app/components/ui/EnhancedCard';
import { MembershipType, ReminderType } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import {
  MemberItem,
  MemberModel,
  OrganizationModel,
  PagingInfoModel,
  PulseForMemberModel,
  PulseReminderModel,
  PulseStatementModel,
  PulseTemplateModel,
} from 'app/models';
import { PulseService } from 'app/services/PulseService';
import {
  MemberStore,
  OrganizationStore,
  PulseForMemberStore,
  PulseReminderStore,
  PulseTemplateGroupStore,
  ReminderOptions,
} from 'app/stores';

const TMP_PULSE_ID_PREFIX = 'tmp_pulse';
const TMP_PULSE_STATEMENT_ID_PREFIX = 'tmp_pulse_statement';
const TMP_MEMBER_ID_PREFIX = 'tmp_member';

const DEFAULT_PARTICIPANTS_PAGE_NUMBER = 1;

export const CADENCE_TYPES = [
  { label: 'Weekly on Friday', value: ReminderType.Weekly },
  { label: '3rd Friday of the Month', value: ReminderType.MonthlyThirdFriday },
  { label: 'Custom', value: ReminderType.Custom },
];

export interface PersonalHabitsUIStoreProps {
  memberId: number;
  pulseId: number;
  pulseForMemberStore: PulseForMemberStore;
  pulseTemplateGroupStore: PulseTemplateGroupStore;
  pulseReminderStore: PulseReminderStore;
  memberStore: MemberStore;
  isEditing?: boolean;
  isTestDrive?: boolean;
  organization?: OrganizationModel;
  organizationStore?: OrganizationStore;
}

export default class PersonalHabitsUIStore {
  /**
   * Observables
   */

  @observable activePulse: PulseForMemberModel;
  @observable templates: PulseTemplateModel[];

  @observable allMembersCache: MemberItem[];
  @observable membersToInvite: MemberItem[];
  @observable selectedMembersCache: MemberItem[];

  @observable participants: MemberItem[];
  @action setParticipants = (participants: MemberItem[]): void => {
    this.participants = participants;
  };

  @observable isInviteModalOpen = false;
  @action public toggleInviteModal = (): void => {
    this.isInviteModalOpen = !this.isInviteModalOpen;
  };

  @observable isPreviewOpen = false;
  @action togglePreviewModal = (): void => {
    this.isPreviewOpen = !this.isPreviewOpen;
  };

  @observable isEmailModalOpen = false;
  @action public toggleEmailModal = (): void => {
    this.isEmailModalOpen = !this.isEmailModalOpen;
  };

  @observable cadenceType: ReminderType = CADENCE_TYPES[0].value;
  @action setCadenceType = (value: ReminderType): void => {
    this.cadenceType = value;
  };

  @observable participantsPageInfo = new PagingInfoModel({
    page: DEFAULT_PARTICIPANTS_PAGE_NUMBER,
  });

  @observable pulseService: PulseService;

  /**
   * Config
   */

  organization: OrganizationModel;
  memberId: number;
  pulseId: number;
  isEditing: boolean;
  isTestDrive?: boolean;
  testDriveMember?: MemberModel;

  /**
   * Stores
   */

  pulseForMemberStore: PulseForMemberStore;
  pulseTemplateGroupStore: PulseTemplateGroupStore;
  pulseReminderStore: PulseReminderStore;
  memberStore: MemberStore;
  organizationStore: OrganizationStore;

  constructor(props: PersonalHabitsUIStoreProps) {
    this.init(props);
  }

  @action
  protected init = async (props: PersonalHabitsUIStoreProps) => {
    this.organization = props.organization;
    this.memberId = props.memberId;
    this.isEditing = props.isEditing;
    this.isTestDrive = props.isTestDrive;
    this.pulseId = props.pulseId;
    this.isTestDrive = props.isTestDrive;

    this.pulseForMemberStore = props.pulseForMemberStore;
    this.pulseTemplateGroupStore = props.pulseTemplateGroupStore;
    this.memberStore = props.memberStore;
    this.pulseReminderStore = props.pulseReminderStore;
    this.organizationStore = props.organizationStore;

    this.templates = [];
    this.participants = [];
    this.membersToInvite = [];
    this.allMembersCache = [];
    this.selectedMembersCache = [];

    this.loadPulse();
    this.pulseReminderStore.reminders.setItems([]);

    when(
      () => !!this.activePulse && isNumber(this.activePulse.id),
      () => {
        const defaultReminder = this.pulseReminderStore.reminders.items[0];

        this.searchParticipants();
        this.pulseReminderStore.setActiveReminder(
          this.activePulse.reminders.items[0] ?? defaultReminder
        );
      }
    );

    // Set current member as first participant
    when(
      () => this.pulseOwner && this.selectedMembersCache.length === 0,
      () => {
        this.selectedMembersCache.unshift(this.pulseOwner);
        this.setParticipants(this.selectedMembersCache);
      }
    );

    // Set active reminder
    when(
      () => !!this.pulseReminderStore.reminders.items.length,
      () => {
        const defaultReminder = this.pulseReminderStore.reminders.items[0];

        if (!this.reminder) {
          this.pulseReminderStore.setActiveReminder(defaultReminder);
        }
      }
    );

    when(
      () => !!this.activePulse && !!this.activePulse.statements.items,
      () => this.createPulseService()
    );

    reaction(
      () => !!this.membersToInvite.length,
      () => this.setParticipants(this.selectedMembersCache)
    );
  };

  @action
  private createPulseService = () => {
    this.pulseService = new PulseService(
      this.activePulse,
      this.pulseReminderStore,
      TMP_PULSE_ID_PREFIX,
      TMP_PULSE_STATEMENT_ID_PREFIX
    );
  };

  @action
  private loadPulse = async (): Promise<any> => {
    if (this.isEditing) {
      this.loadPulseForEdit();
      return;
    }

    // Create an empty pulse
    this.activePulse = PulseForMemberModel.fromJson({ id: uniqueId(TMP_PULSE_ID_PREFIX) });
  };

  @action
  public updateReminderEmailContent = (content: string): void => {
    const reminder = this.reminder;
    reminder.email_content = content;

    this.pulseService.updateActiveDefaultReminder(reminder);
  };

  @action
  protected populateMembersToInvite = (): void => {
    const members = this.pulseForMemberStore.searchAllParticipants.items
      .filter(({ id }) => id !== this.memberId)
      .map((member) => this.allMembersCacheById[member.id]);

    this.membersToInvite = uniqBy(members, 'id');
  };

  @action
  protected searchMembers = async (): Promise<void> => {
    await this.pulseForMemberStore.loadParticipantsPool(
      this.activePulse,
      this.participantsPageInfo.toJSON()
    );

    // Cache all loaded members
    const newMembers = this.pulseForMemberStore.searchParticipantsPage.items
      .filter(({ id }) => !this.allMembersCacheById?.[id])
      .map((member) => this.makeMemberItem(member, !!this.loadedParticipantsById?.[member.id]));

    this.allMembersCache = [...this.allMembersCache, ...newMembers];
  };

  @action
  private loadPulseForEdit = async (): Promise<any> => {
    await this.pulseForMemberStore.loadPulse(this.memberId, this.pulseId);
    this.activePulse = this.pulseForMemberStore.pulse.item;

    this.pulseService.setPulse(this.activePulse);

    when(
      () => !!this.activePulse.participants.items.length,
      () => this.loadParticipants()
    );
  };

  @action
  private createPulse = async (): Promise<any> => {
    // Create pulse with default solo membership
    const pulse = (this.activePulse = await this.pulseForMemberStore.create(
      this.memberId,
      MembershipType.Solo,
      this.formattedValidHabits
    ));

    this.pulseService.activePulse = pulse;

    this.pulseForMemberStore.pulses.appendItem(pulse);
  };

  @action
  public addToSelectedMembers = (member: MemberItem): void => {
    const index = this.selectedMembersCache.findIndex(({ id }) => id === member.id);

    // If selected but not yet on list, add it
    if (member.isSelected && index === -1) {
      this.selectedMembersCache.push(member);
    }

    // If not selected but already on list, remove it
    if (!member.isSelected && index !== -1) {
      this.selectedMembersCache.splice(index, 1);
    }
  };

  protected makeMemberItem(member: MemberModel, isSelected = false): MemberItem {
    return new MemberItem({
      ...pick(member, ['id', 'name', 'email', 'avatar']),
      status: '',
      isSelected,
    });
  }

  @computed
  protected get allMembersCacheById(): Record<number, MemberItem> {
    return keyBy(this.allMembersCache, 'id');
  }

  @computed
  protected get loadedParticipantsById(): Record<number, MemberModel> {
    const participants = this.isEditing ? this.activePulse?.participants.items : [];
    return keyBy(participants, 'id');
  }

  @computed
  protected get formattedValidHabits(): Partial<PulseStatementModel>[] {
    return this.pulseService.formattedValidHabits;
  }

  @action
  public deleteHabit = (habit: PulseStatementModel): void => {
    this.pulseService.deleteHabit(habit);
  };

  @action
  public updateActiveDefaultReminder = (reminder: PulseReminderModel): void => {
    this.pulseService.updateActiveDefaultReminder(reminder);
  };

  @action
  public resetSelectedMemberCache = (): void => {
    this.selectedMembersCache = [];
  };

  private loadParticipants = () => {
    // In Edit mode
    // Pre-select participants for participant modal & selectedMemberCache
    const participants = this.activePulse.participants.items.map((participant) => {
      const memberItem = this.makeMemberItem(participant, true);
      this.addToSelectedMembers(memberItem);
      return memberItem;
    });

    // Set participants
    this.setParticipants(participants);
  };

  private updatePulse = async (): Promise<any> => {
    await this.pulseForMemberStore.update(this.activePulse, this.formattedValidHabits);
  };

  private createReminder = async (): Promise<any> => {
    const response = await this.pulseReminderStore.create(
      this.memberId,
      this.activePulse.id,
      this.reminder
    );
    this.activePulse.reminders.appendItem(response);
  };

  private updateReminder = async (): Promise<any> => {
    await this.pulseReminderStore.update(this.memberId, this.activePulse, this.reminder);
  };

  public getStepUrl(step: string): string {
    if (this.isEditing) {
      return ServerRouteHelper.dashboard.myself.personalHabitsEdit(this.activePulse.id, step);
    }

    return ServerRouteHelper.dashboard.myself.personalHabitsNew(step);
  }

  @computed
  private get isReminderSaved(): boolean {
    return !!this.activePulse.reminder?.id;
  }

  @computed
  protected get hasMoreParticipants(): boolean {
    return (
      this.participantsPageInfo.page < this.pulseForMemberStore.searchParticipantsPageMeta.last_page
    );
  }

  /**
   * Public
   */

  @computed
  public get helpText(): string {
    if (this.isSolo) {
      return 'In this option, you will assess your progress on your Habits regularly yourself. In the next step, you will be able to set up the cadence for your personal checkin.';
    }

    return 'Selecting colleagues or your manager as accountability partners will help you to track progress on your Habits through the assessment of others.';
  }

  @computed
  public get helpCardFooterButton(): Clickable {
    if (this.isSolo) {
      return null;
    }

    return {
      label: this.hasSelectedPeers ? 'Edit colleagues' : 'Add colleagues',
      type: this.hasSelectedPeers ? 'default' : undefined,
      className: this.hasSelectedPeers ? 'btn btn-outline' : undefined,
      onClick: this.toggleInviteModal,
    };
  }

  @computed
  public get validHabits(): PulseStatementModel[] {
    return this.pulseService.validHabits;
  }

  @computed
  public get isLoading(): boolean {
    return (
      this.pulseTemplateGroupStore.templateGroups.loading ||
      this.pulseForMemberStore.pulse.loading ||
      !this.pulseService
    );
  }

  @computed
  get isSolo(): boolean {
    return this.activePulse?.membership === MembershipType.Solo;
  }

  @computed
  get hasSelectedPeers(): boolean {
    if (this.isSolo) {
      return this.participants.length === 1;
    }

    return this.participants.length > 1;
  }

  @computed
  public get selectedHabitsById(): Record<number, PulseStatementModel> {
    return this.pulseService.selectedHabitsById;
  }

  @computed
  get pulseOwner(): MemberItem {
    return this.activePulse?.owner?.item
      ? this.makeMemberItem(this.activePulse.owner.item, true)
      : null;
  }

  @computed
  public get isPulseSaved(): boolean {
    return this.pulseService.isPulseSaved;
  }

  @computed
  public get isSaving(): boolean {
    return this.pulseForMemberStore.isSaving || this.pulseReminderStore.isSaving;
  }

  public get isCadenceLoading(): boolean {
    return this.pulseService.isCadenceLoading;
  }

  get options(): ReminderOptions {
    return this.pulseReminderStore.options;
  }

  public get cadenceText(): string {
    return this.pulseService.cadenceText;
  }

  public get hasParticipationMode(): boolean {
    return true;
  }

  public get helpCardTitle(): string {
    return 'Select colleagues who will rate your ongoing habits';
  }

  public get isLoadingMembers(): boolean {
    return this.pulseForMemberStore.searchParticipantsPage.loading;
  }

  public get launchHabitUrl(): string {
    return ServerRouteHelper.dashboard.myself.myHabits();
  }

  public get inviteModalHeading(): string {
    return 'Colleagues from all your organizations';
  }

  public get participantSearchQuery(): string {
    return this.participantsPageInfo.search;
  }

  public get currentMember(): MemberModel {
    return this.memberStore.currentMember.item;
  }

  public get habits(): PulseStatementModel[] {
    return this.pulseService.habits;
  }

  get inviteEmailContent(): string {
    return this.reminder?.email_content;
  }

  public get reminder(): PulseReminderModel {
    return this.pulseService.reminder;
  }

  public createEmptySlots = (): void => {
    this.pulseService.createEmptySlots();
  };

  public appendNewHabit = (text: string, useEmptySlot = true): void => {
    this.pulseService.appendNewHabit(text, useEmptySlot);
  };

  public updateEmptyHabit = (statement: PulseStatementModel): void => {
    this.pulseService.updateEmptyHabit(statement);
  };

  public configureSubHeading = (): string => {
    return 'Set reminders for yourself to be very frequent so the topic is in front of mind';
  };

  public load = async (): Promise<any> => {
    return Promise.all([
      this.memberStore.loadCurrentMember(),
      this.pulseTemplateGroupStore.loadTemplateGroups(this.memberId),
      this.pulseReminderStore.loadDefaultReminders('member_pulse', true),
    ]);
  };

  public savePulse = async (): Promise<any> => {
    if (this.isPulseSaved) {
      return this.updatePulse();
    }

    return this.createPulse();
  };

  public launchHabit = async (): Promise<any> => {
    const habit = await this.pulseForMemberStore.launch(this.activePulse);

    return habit;
  };

  public updateParticipants = async (): Promise<any> => {
    const participants = this.participants.map(({ name, email }) => ({ name, email }));
    return this.pulseForMemberStore.updateParticipants(this.activePulse, participants);
  };

  public handleSelectAll = (checked: boolean): void => {
    this.membersToInvite.forEach((member) => {
      member.setIsSelected(checked);
      this.addToSelectedMembers(member);
    });
  };

  public handleRowSelect = (row: { member: MemberItem }): void => {
    const { member } = row;
    member.toggleSelected();
    this.addToSelectedMembers(member);
  };

  public handleAddNewMember = (value: NewMemberValues): void => {
    const tmpId = `${TMP_MEMBER_ID_PREFIX}_${this.membersToInvite.length}`;

    const emailAlreadyExists =
      this.membersToInvite.filter((member) => member.email === value.email).length > 0;

    if (emailAlreadyExists || this.pulseOwner.email === value.email) {
      return;
    }

    const newMember = new MemberItem({
      ...value,
      id: tmpId,
      avatar: {
        text: value.name.substring(0, 2),
        color: '#00FF00',
      },
      isSelected: true,
      isNew: true,
    });

    this.membersToInvite.unshift(newMember);
    this.selectedMembersCache.splice(1, 0, newMember);
  };

  public saveReminder = async (): Promise<any> => {
    if (this.isReminderSaved) {
      return this.updateReminder();
    }

    return this.createReminder();
  };

  public searchParticipants = async (searchText?: string): Promise<void> => {
    this.participantsPageInfo = new PagingInfoModel({
      search: searchText,
      page: 1, // new search resets the page
    });

    await this.searchMembers();
    this.populateMembersToInvite();
  };

  public loadMoreParticipants = async (): Promise<void> => {
    if (!this.hasMoreParticipants) {
      return;
    }

    // Set to next page
    this.participantsPageInfo.incrementPage();

    await this.searchMembers();
    this.populateMembersToInvite();
  };

  public updateMembership = (membership: MembershipType): void => {
    const participants =
      membership === MembershipType.Group ? this.selectedMembersCache : [this.pulseOwner];
    this.setParticipants(participants);

    this.activePulse.updateFromJson({ membership });
    this.pulseForMemberStore.updateMembership(this.activePulse);
  };
}
