import React from 'react';

import { Button } from 'antd';

import './SampleReflection.scss';

const SampleReflection: React.FC = () => {
  return (
    <div className="sample-reflection">
      <div className="reflection-section">
        <p className="title-text">Were your results what you had expected?</p>
        <Button className="btn-outline">Slightly better than expected</Button>
      </div>
      <div className="reflection-section">
        <p className="title-text">How do you feel?</p>
        <div className="d-flex">
          <Button className="btn-outline">Relieved</Button>
          <Button className="btn-outline">Excited</Button>
          <Button className="btn-outline">Curious</Button>
        </div>
      </div>

      <div className="reflection-section">
        <p className="title-text">What made you most proud?</p>
        <div className="sub-section-title">
          <p className="title-text">Is composed under stress</p>
        </div>
      </div>

      <div className="reflection-section">
        <p className="title-text">What was an unexpected strength or weakness?</p>
        <div className="sub-section-title">
          <p className="title-text">Minimizes organizational politics</p>
          <div className="inner-section">
            <p className="section-content">
              <p className="content-title-text">Why was this unexpected?</p>
              <p>
                My manager thinks I’m not doing this well, but me, my peers and my reports all think
                I’m doing this quite well. I didn’t think this would be an issue so it’s interesting
                that one person sees it that way.
              </p>
            </p>
            <p className="section-content">
              <p className="content-title-text">What will you do differently now?</p>
              <p>
                I need to follow up with my manager to learn why they think this is an issue, but
                it’s still good news that everyone else thinks this is going fine.
              </p>
            </p>
          </div>
        </div>
      </div>
      <div className="reflection-section">
        <p className="title-text">What is your top priority to focus on improving going forward?</p>
        <div className="sub-section-title">
          <p className="title-text">Sets clear goals and roles</p>
          <div className="inner-section">
            <p className="section-content">
              <p className="content-title-text">What would it be like to make this change? </p>
              <p>
                I need to help my team get more clarity on their goals. I think they have good daily
                tasks and big picture goals, but we’re missing the strategic drivers of our
                objectives and the monthly to quarterly time horizon goals.
              </p>
            </p>
            <p className="section-content">
              <p className="content-title-text">What’s holding me back?</p>
              <p>
                I need to put more time and effort into this, right now it’s a lower priority for
                me. I also need to get more feedback and input from others. I’d like to collectively
                set the goals with my team or reports first in 1-1s with them. Then have a debate
                session about them with the whole team, and finally present them out to the
                leadership team. Having these iterations should help everyone have clarity on the
                goal and the quality should be better.
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleReflection;
