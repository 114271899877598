import React, { ReactNode } from 'react';

import { Button, Pagination, Table } from 'antd';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link, RouteComponentProps } from 'react-router-dom';

import styles from './PulseTemplates.module.scss';

import { STORE_ADMIN_PULSE_TEMPLATES } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import { PagingMetaModel, PulseTemplateModel } from 'app/models';
import AdminPulseTemplateStore from 'app/stores/AdminPulseTemplateStore';

export interface PulseTemplatesProps extends RouteComponentProps {
  adminPulseTemplateStore: AdminPulseTemplateStore;
}

export class PulseTemplates extends React.Component<PulseTemplatesProps> {
  constructor(props: PulseTemplatesProps) {
    super(props);

    this.init();
  }

  private async init() {
    const { adminPulseTemplateStore } = this.props;
    await adminPulseTemplateStore.loadTemplates();
  }

  get templates(): PulseTemplateModel[] {
    return this.props.adminPulseTemplateStore.templates.items;
  }

  get pageMeta(): PagingMetaModel {
    return this.props.adminPulseTemplateStore.pageMeta;
  }

  @computed
  get columns() {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        filterSearch: true,
        onFilter: (value, record) => record.name.includes(value),
        render: (text, record) => (
          <Link to={ServerRouteHelper.admin.pulseTemplates.edit(record.id)}>{text}</Link>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'scope',
        key: 'scope',
        render: (text) => <span>{text === 'member_pulse' ? 'Personal' : 'Team'}</span>,
      },
      {
        title: 'Is Default?',
        dataIndex: 'is_default',
        key: 'is_default',
        render: (text) => <span>{text === 0 ? 'False' : 'True'}</span>,
      },
    ];
  }

  handleCreate = () => {
    this.props.history.push(ServerRouteHelper.admin.pulseTemplates.create());
  };

  handlePageChange = (pageNumber: number): void => {
    this.props.adminPulseTemplateStore.loadTemplates(pageNumber);
  };

  public render(): ReactNode {
    return (
      <div className="container mt-4">
        <h4>
          Pulse Templates
          <Button
            onClick={this.handleCreate}
            type="primary"
            className={styles.createNewTemplateBtn}
          >
            Create New Template
          </Button>
        </h4>

        <Table columns={this.columns} dataSource={this.templates} pagination={false} />

        <div className="float-right m-4">
          {this.pageMeta && (
            <Pagination
              current={this.pageMeta.current_page}
              total={this.pageMeta.total}
              defaultPageSize={this.pageMeta.per_page}
              showSizeChanger={false}
              showQuickJumper={false}
              hideOnSinglePage
              onChange={this.handlePageChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default inject(STORE_ADMIN_PULSE_TEMPLATES)(observer(PulseTemplates));
