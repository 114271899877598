import React from 'react';

import cx from 'classnames';

import './AllStatementsModal.scss';

import { ExerciseStatementCardProps } from 'app/components/features/ExerciseStatementCard/ExerciseStatementCard';
import Modal from 'app/components/ui/Modal';

import CardGridViewSimple from '../CardGridView/CardGridViewSimple';

export interface AllStatementsModalProps {
  statements: ExerciseStatementCardProps[];
  isOpen: boolean;
  onToggle: () => void;
  title?: string;
  className?: string;
}

export const AllStatementsModal: React.StatelessComponent<AllStatementsModalProps> = ({
  statements,
  isOpen,
  onToggle,
  title = null,
  className = '',
}) => (
  <Modal
    className={cx('all-statements-modal', className)}
    isOpen={isOpen}
    title={title ?? 'All Statements'}
    onToggle={onToggle}
  >
    <CardGridViewSimple statements={statements} />
  </Modal>
);

export default AllStatementsModal;
