import { computed, observable } from 'mobx';

import { DiscussionSpaceStore } from 'app/stores/DiscussionSpaceStore';

import allModels from './allModels';
import DiscussionSpaceAgendaModel from './DiscussionSpaceAgendaModel';
import DiscussionSpaceRaisedHandModel from './DiscussionSpaceRaisedHandModel';
import {
  DiscussionSpaceAlignPlusPerspectiveTipModel,
  DiscussionSpaceAlignTipModel,
  DiscussionSpacePerspectiveTipModel,
  DiscussionSpaceTriggerInfo,
} from './DiscussionSpaceTipModel';
import DiscussionSpaceWebsocketStateModel from './DiscussionSpaceWebsocketStateModel';
import Model, { ModelJson } from './Model';

export enum DiscussionSpaceType {
  Basic = 'basic',
  MultiSteps = 'multi_steps',
  MultiStepsDemo = 'multi_steps_demo',
}

export enum DiscussionSpaceStatus {
  Live = 'live',
  Demo = 'demo',
}

type Tips = {
  align: Record<number, DiscussionSpaceAlignTipModel>;
  perspective: Record<string, Record<string, DiscussionSpacePerspectiveTipModel>>;
  align_perspective: Record<number, DiscussionSpaceAlignPlusPerspectiveTipModel>;
};

export type TipFeedback = {
  id: number;
  key: string;
  member_id: number;
  helpful: boolean;
};

export interface DiscussionSpaceMeta {
  recipients: number[];
}

export class DiscussionSpaceModel extends Model {
  public static _store: DiscussionSpaceStore;

  @observable public id: number;
  @observable public exercise_id: number;
  @observable public title: string;
  @observable public websocketState: DiscussionSpaceWebsocketStateModel;
  @observable public can_manage: boolean;
  @observable public is_participant: boolean;
  @observable public member_id: number;
  @observable public current_member_id: number;
  @observable public report_token: string;
  @observable public tips: Tips;
  @observable public tipFeedbacks: Record<string, TipFeedback>;
  @observable public type: DiscussionSpaceType;
  @observable public meta: DiscussionSpaceMeta;

  deserialize_websocket_state(websocketState) {
    this.websocketState = DiscussionSpaceWebsocketStateModel.fromJson(websocketState);
  }

  deserialize_raised_hands(raisedHands: any[]) {
    raisedHands.forEach((raisedHand) => DiscussionSpaceRaisedHandModel.fromJson(raisedHand));
  }

  deserialize_agendas(agendas: any[]) {
    agendas.forEach((agenda) => DiscussionSpaceAgendaModel.fromJson(agenda));
  }

  deserialize_tip_feedbacks(feedbacks: any[]) {
    this.tipFeedbacks = feedbacks.reduce((acc, feedback) => {
      acc[feedback.key] = { ...feedback, helpful: !!feedback.helpful };
      return acc;
    }, {});
  }

  deserialize_tips(tips) {
    this.tips = {
      align: DiscussionSpaceAlignTipModel.parse(tips.align),
      perspective: DiscussionSpacePerspectiveTipModel.parse(tips.perspective),
      align_perspective: DiscussionSpaceAlignPlusPerspectiveTipModel.parse(tips.align_perspective),
    };
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as DiscussionSpaceModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as DiscussionSpaceModel;
  }

  static get(id) {
    return this._get(id) as DiscussionSpaceModel;
  }

  @computed
  get agendas(): DiscussionSpaceAgendaModel[] {
    return DiscussionSpaceAgendaModel._store.findAgendasForDiscussionSpace(this.id);
  }

  @computed
  get allHands() {
    return DiscussionSpaceRaisedHandModel._store.findRaisedHandsForDiscussionSpace(this.id);
  }

  @computed
  get raisedHands() {
    return this.allHands.filter(({ active }) => active);
  }

  @computed
  get hasStarted() {
    return !!this.websocketState.state.active_agenda_id;
  }

  @computed
  get hasSelectedStatements() {
    return (this.websocketState.state.selected_align_statement_ids ?? []).length > 0;
  }

  @computed
  get showCelebrationCard(): boolean {
    return this.websocketState.state.show_celebration_card ?? true;
  }

  @computed
  get isBasic(): boolean {
    return this.type === DiscussionSpaceType.Basic;
  }

  @computed
  get isMultiSteps(): boolean {
    return this.type === DiscussionSpaceType.MultiSteps;
  }

  @computed
  get isLive(): boolean {
    return this.type.indexOf('_demo') === -1;
  }

  showTip(trigger: DiscussionSpaceTriggerInfo) {
    // NOTE: This feature got removed
    // see https://app.shortcut.com/chooseshift/story/5460/remove-discussion-tips-on-ds-and-show-see-results-button-to-facilitator
  }

  getStatementTipTriggerState(statementId: number) {
    // Check if statement is red or bimodal
    const tipInfo = this.tips?.align?.[statementId];
    if (tipInfo?.color === 'red' || tipInfo?.isBimodal) {
      return 'red_or_bimodal';
    }
    return null;
  }
}

allModels.register({ DiscussionSpaceModel });

export default DiscussionSpaceModel;
