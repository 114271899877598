import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { DiscussionPromptAnswerStore } from 'app/stores';

export class DiscussionPromptAnswerModel extends Model {
  public static _store: DiscussionPromptAnswerStore;

  @observable public id: number;
  @observable public prompt_id: number;
  @observable public discussion_guide_id: number;
  @observable public answer: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as DiscussionPromptAnswerModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as DiscussionPromptAnswerModel;
  }

  static get(id) {
    return this._get(id) as DiscussionPromptAnswerModel;
  }
}

export default DiscussionPromptAnswerModel;
