import { keyBy } from 'lodash';
import { computed, observable } from 'mobx';

import { ModelItem } from 'app/models/ModelItem';
import { ModelList } from 'app/models/ModelList';

import allModels from './allModels';
import Model, { ModelJson } from './Model';
import { PulseResponseModel } from './PulseResponseModel';
import { PulseStatementModel } from './PulseStatementModel';

export class PulseResponseActivityModel extends Model {
  @observable statement = new ModelItem<PulseStatementModel>(PulseStatementModel);
  @observable responses = new ModelList<PulseResponseModel>(PulseResponseModel);

  @computed
  get byResponseId() {
    return keyBy(this.responses, 'id');
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PulseResponseActivityModel;
  }
}

allModels.register({ PulseResponseActivityModel });

export default PulseResponseActivityModel;
