import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const My360Report = lazy(() => import('./Report/My360Report'));
const Exercise = lazy(() => import('../align/Exercise'));
const TestDrive = lazy(() => import('./TestDrive'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route
        exact
        path="/my360/:token/:page(report|next_steps|respondents)/:subnav?"
        component={My360Report}
      />

      <Route exact path="/my360/exercise/:secretCode/:step?" component={Exercise} />
      <Route path="/reflect360/test-drive/:token" component={TestDrive} />
    </Switch>
  </Suspense>
);
