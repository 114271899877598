/**
 * Any required initializers should go here
 */

import 'babel-polyfill';
import './polyfills';
import { FullStory } from '@fullstory/browser';
import { fullStoryIntegration } from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import _ from 'lodash';
import posthog from 'posthog-js';

import { initializeFullStory } from './helpers/initializeFullStory';
import { initializePosthog } from './helpers/initializePosthog';
import { initializeWootric } from './helpers/initializeWootric';

// Defined in /views/layouts/head.blade.php
axios.defaults.headers.common['X-CSRF-TOKEN'] = window['accompanyCSRF_token'];
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Ensure accompany
const accompany = window['accompany'] || {};

//
// Configure Lodash templates
//
_.templateSettings.interpolate = /\{\{(.+?)\}\}/g;

const isPosthogActive = initializePosthog();
const isFullStoryActive = initializeFullStory();
initializeWootric();

//
// Define Sentry default placeholder instance,
// when in debug mode without a production configs
// This gets replaced below in when in production
//
window['Sentry'] = {
  captureException: (e: any) => {
    // Log stack trace for better debugging
    if (window['accompany'].isProduction === false && e.stack) {
      console.log('Stack Trace', e.stack.toString());
    }

    // Log Exception as object
    console.error('Exception', e);
  },

  captureMessage: (s: string) => console.error('Message', s),

  withScope: (callback) => {
    callback({
      setFingerprint: (fingerprint) => console.log('Fingerprinting error with', fingerprint),
    });
  },
};

//
// Configure Sentry
//
if (accompany.sentryDSN) {
  const sentryIntegrations: any[] = [
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ];

  // Integrate Posthog with Sentry
  if (isPosthogActive && window['posthog']) {
    const posthogIntegration = new posthog.SentryIntegration(
      posthog,
      accompany.sentryOrganization,
      accompany.sentryProjectId
    );

    posthogIntegration.setupOnce(Sentry.addEventProcessor, () => {});
  }

  // Integrate Full Story with Sentry
  if (isFullStoryActive) {
    sentryIntegrations.push(fullStoryIntegration(window['_fs_org'], { client: FullStory }));
  }

  // Setup Sentry config
  Sentry.init({
    ignoreErrors: ['Non-Error promise rejection captured'],
    dsn: accompany.sentryDSN,
    release: accompany.release,
    normalizeDepth: 5,
    integrations: sentryIntegrations,

    // Sentry replay config
    replaysSessionSampleRate: 0.1, // Capture only 10% of sessions
    replaysOnErrorSampleRate: 1.0, // Capture all errors
  });

  // Integrate auth member
  if (!!accompany.authMember) {
    Sentry.setUser(accompany.authMember);
  }

  if (accompany?.ip_address) {
    Sentry.getCurrentScope().setTag('ip_address', accompany.ip_address);
  }

  window['Sentry'] = Sentry;
}
