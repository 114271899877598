import { computed, observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { OnboardingStore } from 'app/stores';

export class OnboardingToolActivationModel extends Model {
  static _store: OnboardingStore;

  @observable id: number;
  @observable name: string;
  @observable type: string;
  @observable title: string;
  @observable description: string;
  @observable cta_text: string;
  @observable cta_url: string;

  @computed
  get imageUrl(): string {
    return `/images/onboarding/activations/${this.type}.svg`;
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as OnboardingToolActivationModel;
  }
  static getOrNew(id) {
    return this._getOrNew(id) as OnboardingToolActivationModel;
  }
  static get(id) {
    return this._get(id) as OnboardingToolActivationModel;
  }
}
