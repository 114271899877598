import { observable } from 'mobx';

import { SessionStore } from 'app/stores/SessionStore';

import Model, { ModelJson } from './Model';

export interface UserAgent {
  browser: string;
  platform: string;
  device: string;
  is_robot: boolean;
  version: string;
}

export interface SessionsAggregate {
  user?: string;
  user_id?: number;
  ip_address?: string;
  session_count: number;
  first_active: string;
  last_active: string;
}

export class SessionModel extends Model {
  static _store: SessionStore;

  readonly id: string;
  @observable user_id: number;
  @observable user_email: string;
  @observable ip_address: string;
  @observable user_agent: UserAgent;
  @observable last_activity: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as SessionModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as SessionModel;
  }

  static get(id) {
    return this._get(id) as SessionModel;
  }
}

export default SessionModel;
