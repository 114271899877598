import React, { ReactNode } from 'react';

import cx from 'classnames';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import URI from 'urijs';
import Validator from 'validator';

import './RegistrationForm.scss';

import { AcceptTerms } from 'app/components/features/AcceptTerms/AcceptTerms';
import CardFooter from 'app/components/ui/EnhancedCard/CardFooter';
import { Clickable } from 'app/components/ui/EnhancedCard/CardFooter/CardFooter';
import CardHeader from 'app/components/ui/EnhancedCard/CardHeader';
import EnhancedCard from 'app/components/ui/EnhancedCard/EnhancedCard';
import TextField from 'app/components/ui/TextField';
import { STORE_AUTH } from 'app/constants';
import { buildURL, preventDefault, ServerRouteHelper } from 'app/helpers';
import { OrganizationModel } from 'app/models';
import ThirdPartyService from 'app/services/ThirdPartyService';
import { AuthStore, EmailStatus } from 'app/stores';

import License from './License';

export interface RegistrationFormProps {
  referral_code?: string;
  entry_point?: string;
  destination?: string;
  title?: string;
  description?: string;
  name?: string;
  email?: string;
  organization?: OrganizationModel;
  authStore?: AuthStore;
  logo?: string;
  license?: any;
}

export class RegistrationForm extends React.Component<RegistrationFormProps> {
  static defaultProps = {
    title: 'Get started free today',
  };

  @observable name: string;
  @action setName = (name) => (this.name = name);

  @observable email: string;
  @action setEmail = (email) => (this.email = email);

  @observable agreementCheck = false;
  @action toggleAgreement = () => (this.agreementCheck = !this.agreementCheck);

  @observable subsToEduContentCheck = false;
  @action toggleEduContent = () => (this.subsToEduContentCheck = !this.subsToEduContentCheck);

  @observable password: string;
  @action setPassword = (password) => (this.password = password);

  @observable orgName: string;
  @action setOrgName = (orgName) => (this.orgName = orgName);

  @observable occupation: string;
  @action setOccupation = (occupation) => (this.occupation = occupation);

  @observable isSubmitting = false;
  @action setIsSubmitting = (isSubmitting) => (this.isSubmitting = isSubmitting);

  @observable validationErrors = [];

  constructor(props) {
    super(props);

    this.name = props.name || this.queryParams?.name;
    this.email = this.props.email || this.queryParams?.email || localStorage.getItem('email');
  }

  @computed
  get queryParams() {
    return URI.parseQuery(window.location.search);
  }

  @action
  resetError(key) {
    if (this.validationErrors) {
      this.validationErrors[key] = null;
    }
  }

  get organization() {
    return this.props.organization;
  }

  get license(): string {
    return this.orgCustomLocalization('register.license') ?? '';
  }

  handleSubmit = async () => {
    this.setIsSubmitting(true);

    const data = {
      name: this.name,
      email: this.email,
      password: this.password,
      occupation: this.occupation,
      destination: this.props.destination,
      orgName: this.orgName,
    };

    if (this.organization) {
      data['orgID'] = this.organization.id;
      data['orgCode'] = this.organization.code;
      data['company'] = this.organization.name;
    }

    if (this.agreementCheck) {
      data['agreementCheck'] = this.agreementCheck;
    }

    if (this.subsToEduContentCheck) {
      data['subsToEduContentCheck'] = this.subsToEduContentCheck;
    }

    if (this.props.referral_code) {
      data['referral_code'] = this.props.referral_code;
    }

    if (this.props.entry_point) {
      data['entry_point'] = this.props.entry_point;
    }

    const query = URI.parseQuery(window.location.search);
    const redirect_to = query.redirect_to && encodeURIComponent(query.redirect_to);

    if (redirect_to?.startsWith('http')) {
      this.logToSentryRedirectFullPath(redirect_to);
    }

    try {
      const response = await this.props.authStore.register(data);

      if (!response) {
        return;
      }

      // Check if registration API response is redirecting us to a different server
      if (response.email_status != EmailStatus.NEW_EMAIL && response.redirect_to) {
        window.location.href = response.redirect_to;
        return;
      }

      if (redirect_to) {
        const url = buildURL(response.redirectPath, { redirect_to });

        if (url.startsWith('http')) {
          this.logToSentryRedirectFullPath(url);
        }

        window.location.href = url;
        return;
      }

      window.location.href = response.redirectPath;
    } catch (error) {
      if (error.status === 422) {
        this.validationErrors = error.errors;
      }
    } finally {
      this.setIsSubmitting(false);
    }
  };

  logToSentryRedirectFullPath = (url: string): void => {
    ThirdPartyService.sentry.captureMessage(`Detected redirect to full path, url: ${url}`);
  };

  validationError = (key) => this.validationErrors?.[key]?.[0];

  validateEmail = () => {
    // Validator expects a not null value to act on
    const email = this.email ? this.email : '';
    return !Validator.isEmail(email) ? 'Please enter a valid email address.' : '';
  };

  @computed
  get acceptTerms() {
    return (
      <AcceptTerms
        agreementCheck={this.agreementCheck}
        subsToEduContentCheck={this.subsToEduContentCheck}
        onAgreementChange={this.toggleAgreement}
        onSubsToEduContentChange={this.toggleEduContent}
      />
    );
  }

  orgCustomLocalization(key) {
    if (this.organization && this.organization.custom_localization) {
      return this.organization.custom_localization[key];
    }
    return null;
  }

  get header(): ReactNode {
    const { title, logo } = this.props;
    const image = logo && <img src={logo} />;

    return <CardHeader heading={title} image={image} />;
  }

  @computed
  get emailError() {
    return this.validationError('email');
  }

  @computed
  get showIfNonDomainValidationErrorOccurs(): boolean {
    const emailError = this.validationError('email');
    const domainValidationError = 'Only valid organization email domains are allowed.';

    if (!emailError || emailError.includes(domainValidationError)) {
      return false;
    }

    return true;
  }

  handleLoginOrGetNewPassword = () => {
    const query = URI.parseQuery(window.location.search);
    const url = buildURL(ServerRouteHelper.auth.login(), {
      ...query,
      email: encodeURIComponent(this.email),
    });

    window.location.href = url;
  };

  handleClickLogin = (): void => {
    window.location.href = ServerRouteHelper.auth.login();
  };

  get signUp(): Clickable {
    return {
      label: 'Sign Up',
      disabled: this.isSubmitting,
      htmlType: 'submit',
      isLoading: this.isSubmitting,
    };
  }

  get logIn(): Clickable {
    return {
      label: 'Already have an account? Log in',
      onClick: this.handleClickLogin,
    };
  }

  @computed
  get body() {
    return (
      <>
        <div className="form-body">
          <TextField
            className="text-field"
            inputId="registerFormName"
            required
            name="name"
            placeHolder="Full name*"
            value={this.name}
            error={this.validationError('name')}
            onFocus={() => this.resetError('name')}
            onValueChange={this.setName}
          />
          <TextField
            name="email"
            className="text-field"
            inputId="registerFormEmail"
            required
            placeHolder="Work e-mail*"
            value={this.email}
            error={this.emailError}
            onFocus={() => this.resetError('email')}
            validators={[this.validateEmail]}
            onValueChange={this.setEmail}
          />
          {this.showIfNonDomainValidationErrorOccurs && (
            <button
              type="submit"
              className="btn btn-block btn-primary form-control--tall mt-3"
              onClick={this.handleLoginOrGetNewPassword}
            >
              Log in or get a new password
            </button>
          )}
          <TextField
            className="text-field"
            inputId="password"
            placeHolder="Password*"
            name="password"
            type="password"
            required
            value={this.password}
            error={this.validationError('password')}
            onFocus={() => this.resetError('password')}
            onValueChange={this.setPassword}
          />
          <span className="hint">
            Minimum 10 characters and must include 1 uppercase, 1 lowercase, 1 number and a special
            character.
          </span>
          {!this.organization && (
            <TextField
              className="text-field"
              inputId="organization"
              required={false}
              placeHolder="Organization name"
              value={this.orgName}
              onValueChange={this.setOrgName}
            />
          )}
          <TextField
            className="text-field special-occupation-field"
            inputId="occupation"
            required={false}
            label="Your occupation"
            placeHolder="Users with screen readers, please skip this"
            value={this.occupation}
            onValueChange={this.setOccupation}
          />
          {this.acceptTerms}
          <License license={this.license} />
        </div>
        <CardFooter
          primaryButton={!this.emailError && this.signUp}
          secondaryButton={!!this.emailError && this.signUp}
          actionButton={this.logIn}
        />
      </>
    );
  }

  render() {
    return (
      <div
        id="registration-form"
        className={cx('registration-form', { submitting: this.isSubmitting })}
      >
        <EnhancedCard>
          <form onSubmit={preventDefault(this.handleSubmit)}>
            {this.header}
            {this.body}
          </form>
        </EnhancedCard>
      </div>
    );
  }
}

export default inject(STORE_AUTH)(observer(RegistrationForm));
