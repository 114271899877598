import React, { ReactNode } from 'react';

import { Col, Row } from 'antd';

import styles from './PageTitle.module.scss';

export interface PageTitleProps {
  icon?: ReactNode;
  actions?: ReactNode;
  title: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ icon, title, actions }) => {
  return (
    <Row className={styles.header}>
      <Col span={24}>
        <h1>
          {icon}
          {title}
        </h1>

        {actions}
      </Col>
    </Row>
  );
};

export default PageTitle;
