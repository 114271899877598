import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import AdminOrgContentStore from 'app/stores/AdminOrgContentStore';

export class AdminSimpleContentItemModel extends Model {
  public static _store: AdminOrgContentStore;

  @observable public id: number;
  @observable public key: string;
  @observable public type: string;
  @observable public arguments?: string;
  @observable public has_arguments: boolean;
  @observable public sample_arguments?: string;

  static fromJson(json: ModelJson): AdminSimpleContentItemModel {
    return this._fromJson(json) as AdminSimpleContentItemModel;
  }

  static getOrNew(id): AdminSimpleContentItemModel {
    return this._getOrNew(id) as AdminSimpleContentItemModel;
  }

  static get(id): AdminSimpleContentItemModel {
    return this._get(id) as AdminSimpleContentItemModel;
  }
}

export default AdminSimpleContentItemModel;
