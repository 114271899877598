import React, { useState } from 'react';

import { Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import styles from './AddCustomHabit.module.scss';

interface AddCustomHabitProps {
  onCustomHabit: (text: string) => void;
}

export const AddCustomHabit: React.FC<AddCustomHabitProps> = ({ onCustomHabit }) => {
  const [text, setText] = useState<string>('');

  const handleAdd = () => {
    onCustomHabit(text);
    setText('');
  };

  return (
    <div className={styles.addCustomHabit}>
      <h4>Add custom Habit</h4>
      <TextArea
        rows={2}
        placeholder="E.g. I set aside time on a week/monthly/quarterly basis to give each other feedback"
        value={text}
        onChange={(event) => setText(event.target.value)}
      />
      <Button className="btn btn-outline" disabled={!text} onClick={handleAdd}>
        Add custom Habit
      </Button>
    </div>
  );
};

export default AddCustomHabit;
