import React, { ReactNode } from 'react';

import { ThunderboltFilled } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './CommitToImproveExplainer.module.scss';

import EnhancedCard from 'app/components/ui/EnhancedCard';
import Markdown from 'app/components/ui/Markdown';
import { STORE_ORGANIZATION_CONTENT } from 'app/constants';
import {
  AlignModel,
  My360ReportReflectCardsContent,
  OrganizationContent,
  OrganizationModel,
} from 'app/models';
import { OrganizationContentStore } from 'app/stores';

export interface CommitToImproveExplainerProps {
  organization: Partial<OrganizationModel>;
  exercise: AlignModel;
  organizationContentStore?: OrganizationContentStore;
}

export class CommitToImproveExplainer extends React.Component<CommitToImproveExplainerProps> {
  private get organization(): Partial<OrganizationModel> {
    return this.props.organization;
  }

  private get exerciseTypeId(): number {
    return this.props.exercise.exercise_type_id;
  }

  @computed
  private get reflectCardContentLoaded(): boolean {
    return this.props.organizationContentStore.isLoaded(
      OrganizationContent.My360ReportReflectCardsContent,
      this.organization.id,
      this.exerciseTypeId
    );
  }

  @computed
  private get reflectCardContent(): My360ReportReflectCardsContent {
    if (!this.reflectCardContentLoaded) {
      return null;
    }

    return this.props.organizationContentStore.get(
      `${OrganizationContent.My360ReportReflectCardsContent}|${this.exerciseTypeId}`
    ).content;
  }

  private get contentLoadingSkeleton(): ReactNode {
    return <Skeleton paragraph={{ rows: 4 }} loading={!this.reflectCardContentLoaded} active />;
  }

  private get reflectExplainerContent(): string {
    return this.reflectCardContent.explainer_card;
  }

  render(): ReactNode {
    return (
      <div className={styles.commitToImprove}>
        <EnhancedCard
          headerIcon={<ThunderboltFilled />}
          heading="Make sense of your report's key points"
        >
          <div className={styles.caption}>
            <h5>
              {this.reflectCardContentLoaded && (
                <>
                  {this.contentLoadingSkeleton}
                  <Markdown className="content-quote" source={this.reflectExplainerContent} />
                </>
              )}
            </h5>
          </div>
        </EnhancedCard>
      </div>
    );
  }
}

export default inject(STORE_ORGANIZATION_CONTENT)(observer(CommitToImproveExplainer));
