import { autoTag } from 'app/helpers/TagUtils';

/**
 * This page is the default page users land on when they access the app
 * as an unauthenticated user. On this page they enter their email, from there
 * we'll direct them to SSO, the Login Page, or the Registration page.
 */

export default {
  GetStartedButton: autoTag,
};
