// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Jn4P-GaAfJTrjCFBY8ClyA\\=\\={padding-bottom:1rem;padding-top:1rem}.Jn4P-GaAfJTrjCFBY8ClyA\\=\\= iframe{border:0;height:60rem;width:100%}", "",{"version":3,"sources":["webpack://./resources/assets/js/components/ui/MetabaseDashboard/MetabaseDashboard.module.scss"],"names":[],"mappings":"AAAA,4BAEE,mBAAA,CADA,gBAEF,CACE,mCACE,QAAA,CAEA,YAAA,CADA,UAEJ","sourcesContent":[".dashboard {\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n\n  iframe {\n    border: 0;\n    width: 100%;\n    height: 60rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard": "Jn4P-GaAfJTrjCFBY8ClyA=="
};
export default ___CSS_LOADER_EXPORT___;
