// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ghKvbxM7OgGBrzg3cHYFag\\=\\= .card-content-container{height:12.75rem}.ghKvbxM7OgGBrzg3cHYFag\\=\\= .uYa\\+YDBCQxyMxPWAa6u0dQ\\=\\={margin-bottom:1rem}.hpuEYJJPZWivBf5uzFD7lA\\=\\= p{color:#8c8c8c}", "",{"version":3,"sources":["webpack://./resources/assets/js/pages/dashboard/Pulse/PulseSteps/InvitePeersStep/HelpCard/HelpCard.module.scss"],"names":[],"mappings":"AAGE,oDACE,eA+EJ,CA5EE,yDACE,kBA8EJ,CAzEE,8BACE,aA4EJ","sourcesContent":["@import 'resources/assets/sass/bootstrap_variables';\n\n.helpCard {\n  :global .card-content-container {\n    height: 12.75rem;\n  }\n\n  .modeSwitch {\n    margin-bottom: 1rem;\n  }\n}\n\n.helpCardHeader {\n  p {\n    color: $gray-700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpCard": "ghKvbxM7OgGBrzg3cHYFag==",
	"modeSwitch": "uYa+YDBCQxyMxPWAa6u0dQ==",
	"helpCardHeader": "hpuEYJJPZWivBf5uzFD7lA=="
};
export default ___CSS_LOADER_EXPORT___;
