import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import TeamsSubscriptionStatusStore from 'app/stores/TeamsSubscriptionStatusStore';

export class TeamsSubscriptionStatusModel extends Model {
  public static _store: TeamsSubscriptionStatusStore;

  readonly id: number;
  @observable teams_with_subscription_count: number;
  @observable teams_with_missing_subscription_count: number;
  @observable members_with_subscription_count: number;
  @observable members_without_subscription_count: number;
  @observable team_members_count: number;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamsSubscriptionStatusModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeamsSubscriptionStatusModel;
  }

  static get(id) {
    return this._get(id) as TeamsSubscriptionStatusModel;
  }
}

export default TeamsSubscriptionStatusModel;
