import React, { lazy, Suspense } from 'react';

import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import FakeBox from 'app/components/ui/FakeBox';
import Loading from 'app/components/ui/Loading';
import { STORE_CONTENT_SECTION } from 'app/constants';
import { ContentSectionModel } from 'app/models';
import { ContentSectionStore } from 'app/stores/ContentSectionStore';

const EmailSectionEditor = lazy(() => import('app/components/shims/EmailSectionEditor'));

export interface EmailEditorContainerProps {
  [STORE_CONTENT_SECTION]: ContentSectionStore;
}

// TODO: Test this!
class EmailEditorContainer extends React.Component<EmailEditorContainerProps> {
  @observable isSaving: boolean;
  @action setIsSaving = (status) => (this.isSaving = status);
  constructor(props) {
    super(props);
    this.contentSectionStore.loadContentSections();
  }

  @action
  async handleSave(section) {
    this.setIsSaving(true);
    await this.contentSectionStore.updateContentSection(section);
    this.setIsSaving(false);
  }

  @computed
  get contentSectionStore() {
    return this.props[STORE_CONTENT_SECTION];
  }

  @computed
  get isLoading() {
    return this.contentSectionStore.isLoadingContentSection;
  }

  render() {
    if (this.isLoading) {
      return <FakeBox className="flex-fill" height="200px" />;
    }

    return (
      <Suspense fallback={<Loading />}>
        <div className="d-flex flex-fill w-100">
          {this.contentSectionStore.contentSections.items.map((section: ContentSectionModel, i) => (
            <EmailSectionEditor
              key={i}
              contentSection={section}
              saving={this.isSaving}
              onSave={() => this.handleSave(section)}
            />
          ))}
        </div>
      </Suspense>
    );
  }
}

export default inject(STORE_CONTENT_SECTION)(observer(EmailEditorContainer));
