import { startsWith } from 'lodash';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import numeral from 'numeral';

import Model, { ModelJson } from 'app/models/Model';
import { DiscussionSpaceNoteStore } from 'app/stores';

import MemberModel from './MemberModel';

export enum DiscussionSpaceNoteType {
  Note = 'note',
  Group = 'group',
}

export class DiscussionSpaceNoteModel extends Model {
  public static _store: DiscussionSpaceNoteStore;

  @observable public id: number;
  @observable public agenda_id: number;
  @observable public content: string;
  @observable public category_key: string;
  @observable public member?: MemberModel;
  @observable public created_at: moment.Moment;
  @observable public type: string;
  @observable public order: number;
  @observable public notes?: DiscussionSpaceNoteModel[];
  @observable public group_id?: number;

  public uuid: string;

  deserialize_notes(notes: any[]) {
    this.notes = notes.map((note) => {
      return DiscussionSpaceNoteModel.fromJson(note);
    });
  }

  deserialize_member(member: any) {
    if (member instanceof MemberModel) {
      this.member = member;
      return;
    }

    this.member = MemberModel.fromJson(member);
  }

  deserialize_created_at(created_at: any) {
    this.created_at = moment(created_at);
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as DiscussionSpaceNoteModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as DiscussionSpaceNoteModel;
  }

  static get(id) {
    return this._get(id) as DiscussionSpaceNoteModel;
  }

  @computed
  get isNote() {
    return this.type === DiscussionSpaceNoteType.Note;
  }

  get plainContent(): string {
    return this.content.replace('+', '');
  }

  @computed
  get isGroup() {
    return this.type === DiscussionSpaceNoteType.Group;
  }

  @computed
  get isUsedGroup() {
    return this.isGroup && this.notes.length > 0;
  }

  @computed
  get hasGroup() {
    return !!this.group_id;
  }

  @computed
  get statementId() {
    const matches = this.category_key?.match(/statement_(\d+)/);
    return numeral(matches?.[1]).value();
  }

  @computed
  get promptId() {
    const matches = this.category_key?.match(/statement_(\d+)_prompt_(\d+)_reflect/);
    return numeral(matches?.[2]).value();
  }

  @computed
  get isPositive() {
    return startsWith(this.content, '+');
  }

  @computed
  get isNegative() {
    return startsWith(this.content, '-');
  }

  @action
  sortNotes() {
    this.notes = this.notes.sort((a, b) => {
      return a.order - b.order;
    });
  }

  @action
  deleteNote(note: DiscussionSpaceNoteModel) {
    if (!this.isGroup) {
      return;
    }

    const index = this.notes.findIndex(({ id }) => id === note.id);
    if (index !== -1) {
      this.notes.splice(index, 1);
    }
  }
}

export default DiscussionSpaceNoteModel;
