import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import OrgNoticeStore from 'app/stores/OrgNoticeStore';

interface OrgNoticeQuestionChoice {
  key: string;
  label: string;
}

export interface OrgNoticeQuestion {
  key: string;
  label: string;
  choices: OrgNoticeQuestionChoice[];
}

export class OrgNoticeModel extends Model {
  public static _store: OrgNoticeStore;

  @observable public id: number;
  @observable public title: string;
  @observable public content: string;
  @observable public questions: OrgNoticeQuestion[];

  static fromJson(json: ModelJson): OrgNoticeModel {
    return this._fromJson(json) as OrgNoticeModel;
  }

  static getOrNew(id): OrgNoticeModel {
    return this._getOrNew(id) as OrgNoticeModel;
  }

  static get(id): OrgNoticeModel {
    return this._get(id) as OrgNoticeModel;
  }
}

export default OrgNoticeModel;
