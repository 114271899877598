import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { MemberConfirmationStore } from 'app/stores/MemberConfirmationStore';

export class MemberConfirmationModel extends Model {
  static _store: MemberConfirmationStore;

  @observable id: number;
  @observable member_id: number;
  @observable version: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as MemberConfirmationModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as MemberConfirmationModel;
  }

  static get(id) {
    return this._get(id) as MemberConfirmationModel;
  }
}

export default MemberConfirmationModel;
