import { action, observable } from 'mobx';

import { CookiePrefOptions } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import CookieStorageHelper from 'app/helpers/CookieStorageHelper';
import { CookiePrefModel } from 'app/models/CookiePrefModel';
import { PerformanceScriptsService } from 'app/services/PerformanceScriptsService';
import Store from 'app/stores/Store';

export class CookiePrefStore extends Store<CookiePrefModel> {
  @observable public cookiePreference: CookiePrefModel;
  @action setCookiePreference = (cookiePref: CookiePrefModel): void => {
    this.cookiePreference = cookiePref;
  };

  constructor() {
    super();
    CookiePrefModel._store = this;
  }

  public loadPreference() {
    const pref = CookieStorageHelper.get('cookie_pref');
    this.setCookiePreference(CookiePrefModel.fromJson({ id: null, pref }));
  }

  public async savePreference(data): Promise<void> {
    const url = ServerRouteHelper.api.cookies.managePreferences();

    await this.apiService.post(url, data);

    // run performance scripts only once the user changes his preference
    if (data.cookie_pref === CookiePrefOptions.ALL) {
      PerformanceScriptsService.run();
    }

    this.loadPreference();
  }
}

export default CookiePrefStore;
