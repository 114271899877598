import React, { Component } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import { ScoreBar } from 'app/components/features/ScoreBar/ScoreBar';
import { RUSS_360_COACHING_URL } from 'app/constants';
import { ExerciseReportModel, ExerciseStatementModel } from 'app/models';
import ExerciseCommitmentModel, {
  ExerciseCommitmentCategory,
} from 'app/models/ExerciseCommitmentModel';

import { CommitToImproveModalStep } from '../CommitToImproveModal';

interface CommitToImproveModalSelectStatementStepProps {
  isSubmitting: boolean;
  exerciseCommitment: ExerciseCommitmentModel;
  report: ExerciseReportModel;
  onChangeStep: (v: CommitToImproveModalStep) => void;
  onSubmit: () => void;
  isTestDrive?: boolean;
}

class CommitToImproveModalSelectStatementStep extends Component<CommitToImproveModalSelectStatementStepProps> {
  @computed
  get sortedStatementsForCategory() {
    const { exerciseCommitment, report } = this.props;

    const direction = exerciseCommitment.category === ExerciseCommitmentCategory.improve ? 1 : -1;

    return report.statements.sort((a, b) => {
      return (a.score - b.score) * direction;
    });
  }

  @computed
  get submitButtonTextForCategory() {
    const { isSubmitting, exerciseCommitment } = this.props;

    if (exerciseCommitment.category === ExerciseCommitmentCategory.celebrate) {
      return isSubmitting ? 'Submitting' : 'Submit';
    }

    return 'Continue';
  }

  @computed
  get rows() {
    const { exerciseCommitment } = this.props;

    return (
      <div className="rows">
        {this.sortedStatementsForCategory.map((statement) => {
          const isSelected = exerciseCommitment.align_statement_id === statement.id;
          return (
            <div
              key={statement.id}
              onClick={() => this.handleClickStatement(statement)}
              className={cx('statement-row', {
                selected: isSelected,
              })}
            >
              <div className="statement-text">{statement.text}</div>
              <ScoreBar diamondValue={statement.scoreScaled} />
              <div className="check-wrapper">{isSelected && <CheckOutlined />}</div>
            </div>
          );
        })}
      </div>
    );
  }

  handleClickStatement = (statement: ExerciseStatementModel) => {
    const { exerciseCommitment } = this.props;
    exerciseCommitment.setAlignStatementID(statement.id);
  };

  handleClickSubmit = () => {
    const { exerciseCommitment, onSubmit, onChangeStep } = this.props;

    if (exerciseCommitment.category === ExerciseCommitmentCategory.celebrate) {
      onSubmit();
      return;
    }

    onChangeStep(CommitToImproveModalStep.WRITE_CHALLENGE);
  };

  get isTestDrive(): boolean {
    return this.props.isTestDrive;
  }

  @computed
  get coachingUrl(): string {
    return !this.isTestDrive ? RUSS_360_COACHING_URL : '#';
  }

  @computed
  get disableSubmit(): boolean {
    const { isSubmitting, exerciseCommitment } = this.props;
    return !exerciseCommitment.align_statement_id || isSubmitting || this.isTestDrive;
  }

  render() {
    return (
      <div>
        {this.rows}

        <div className="actions">
          <div className="you-stuck">
            <p>
              <span>Are you stuck?</span>&nbsp;
              <a href={this.coachingUrl}>Set up a coaching call and we'll walk you through this.</a>
            </p>
          </div>
          <button
            className="btn btn-primary"
            disabled={this.disableSubmit}
            onClick={this.handleClickSubmit}
          >
            {this.submitButtonTextForCategory}
          </button>
        </div>
      </div>
    );
  }
}

export default observer(CommitToImproveModalSelectStatementStep);
