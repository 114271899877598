import { action, observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import PerspectiveQuestionStore from 'app/stores/PerspectiveQuestionStore';
export class PerspectiveQuestionModel extends Model {
  static _store: PerspectiveQuestionStore;

  @observable id: number;
  @observable text: string;
  @observable lens: string;
  @observable display_order: number;
  @observable dimension: string;
  @observable direction: number;

  // client usage only, not stored
  @observable scaleAnswer?: number = null;
  @action setScaleAnswer = (answer) => (this.scaleAnswer = answer);

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectiveQuestionModel;
  }
}

export default PerspectiveQuestionModel;
