import React from 'react';

import { Switch } from 'react-router-dom';

import Route from 'app/customRoute';

import PulseReport from './PulseReport';

export default (
  <Switch>
    <Route exact path="/:ownerType/:ownerId/pulses/:pulseId/report" component={PulseReport} />
  </Switch>
);
