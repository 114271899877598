import React, { ReactNode } from 'react';

import { notification } from 'antd';
import { find, findIndex, keyBy, reduce } from 'lodash';
import { computed, observable, ObservableMap, when } from 'mobx';
import { inject, observer } from 'mobx-react';

import './OrgAdminControls.scss';

import {
  STORE_ADMIN_PULSE_TEMPLATES,
  STORE_EXERCISE_TYPE,
  STORE_ORGANIZATION,
} from 'app/constants';
import { ExerciseTypeModel, OrganizationModel, PulseTemplateModel } from 'app/models';
import FeaturedExerciseTypeModel from 'app/models/FeaturedExerciseTypeModel';
import ThirdPartyService from 'app/services/ThirdPartyService';
import { ExerciseTypeStore, OrganizationStore } from 'app/stores';
import AdminPulseTemplateStore from 'app/stores/AdminPulseTemplateStore';

import AllExerciseTypeList from './AllExerciseTypeList';
import EmailDomainList from './EmailDomainList';
import ExerciseTypeSelect from './ExerciseTypeSelect';
import FeaturedExerciseTypeList from './FeaturedExerciseTypeList';
import PulseTemplateList from './PulseTemplateList';
import RegistrationCode from './RegistrationCode';

export interface AdminControlsProps {
  show?: boolean;
  organization?: OrganizationModel;

  organizationStore?: OrganizationStore;
  exerciseTypeStore?: ExerciseTypeStore;
  adminPulseTemplateStore?: AdminPulseTemplateStore;
}

export class OrgAdminControls extends React.Component<AdminControlsProps> {
  @observable selectedPulseTemplates = new ObservableMap();

  constructor(props) {
    super(props);
    this.init();
  }

  async init() {
    const { exerciseTypeStore, organizationStore, adminPulseTemplateStore } = this.props;

    exerciseTypeStore.loadExerciseTypes();
    organizationStore.loadOrganization();
    adminPulseTemplateStore.loadAllTemplates();

    when(
      () => !!this.organization && !adminPulseTemplateStore.isLoadingTemplates,
      () => {
        // Set orgs templates as selected by default
        this.orgPulseTemplates.forEach((template) => {
          this.selectedPulseTemplates.set(template.id, true);
        });

        // Then append the default templates that aren't disabled
        this.defaultPulseTemplates
          .filter(({ id }) => !this.orgPulseTemplatesDisabled?.[id])
          .forEach(({ id }) => {
            this.selectedPulseTemplates.set(id, true);
          });
      }
    );
  }

  @computed
  get organization() {
    return this.props.organization;
  }

  @computed
  get isOrgLoading() {
    return this.props.organizationStore.organization.loading;
  }

  get allExerciseTypes(): ExerciseTypeModel[] {
    const { exerciseTypeStore } = this.props;
    if (exerciseTypeStore.exerciseType.loading) {
      return [];
    }

    return exerciseTypeStore.exerciseTypes.items;
  }

  get availableExerciseTypes() {
    return this.allExerciseTypes.filter((exerciseType) => {
      return !find(this.featuredExerciseTypes, { id: exerciseType.id });
    });
  }

  get featuredExerciseTypes(): ExerciseTypeModel[] {
    if (this.isOrgLoading) {
      return [];
    }
    return this.organization.featured_exercise_types || [];
  }

  get allPulseTemplates(): PulseTemplateModel[] {
    return this.props.adminPulseTemplateStore.allTemplates.items;
  }

  get orgPulseTemplates(): PulseTemplateModel[] {
    return this.organization.pulse_templates.items;
  }

  get orgPulseTemplatesDisabled(): Record<number, PulseTemplateModel> {
    return keyBy(this.organization.pulse_templates_disabled.items, 'id');
  }

  @computed get selectedExerciseTypes() {
    if (this.isOrgLoading) {
      return {};
    }
    return reduce(
      this.organization.active_exercise_types || [],
      (acc, exerciseType) => {
        acc[exerciseType.id] = true;
        return acc;
      },
      {}
    );
  }

  @computed
  get defaultPulseTemplates(): PulseTemplateModel[] {
    return this.allPulseTemplates.filter((template) => {
      return template.type === 'template' && template.is_default;
    });
  }

  swap(indexA, indexB) {
    const { organization } = this;
    const tmp = organization.featured_exercise_types[indexA];
    organization.featured_exercise_types[indexA] = organization.featured_exercise_types[indexB];
    organization.featured_exercise_types[indexB] = tmp;
  }

  handleMoveUp = (exerciseType) => {
    const index = findIndex(this.featuredExerciseTypes, { id: exerciseType.id });
    if (index === -1 || index === 0) {
      return;
    }

    const prevIndex = index - 1;
    const prevExerciseType = this.featuredExerciseTypes[prevIndex];
    const order = exerciseType.order;
    exerciseType.order = prevExerciseType.order;
    prevExerciseType.order = order;
    this.swap(index, prevIndex);
  };

  handleMoveDown = (exerciseType) => {
    const lastIndex = this.featuredExerciseTypes.length - 1;
    const index = findIndex(this.featuredExerciseTypes, { id: exerciseType.id });
    if (index === -1 || index === lastIndex) {
      return;
    }

    const nextIndex = index + 1;
    const nextExerciseType = this.featuredExerciseTypes[nextIndex];
    const order = exerciseType.order;
    exerciseType.order = nextExerciseType.order;
    nextExerciseType.order = order;
    this.swap(index, nextIndex);
  };

  handleRemove = (exerciseType) => {
    const { organization } = this;
    const index = findIndex(organization.featured_exercise_types, { id: exerciseType.id });
    if (index !== -1) {
      organization.featured_exercise_types.splice(index, 1);
      organization.featured_exercise_types.forEach((exerciseType, i) => (exerciseType.order = i));
    }
  };

  handleAdd = (exerciseTypeId) => {
    const { organization } = this;
    const exerciseType = find(this.allExerciseTypes, { id: exerciseTypeId });
    if (exerciseType) {
      exerciseType.pivot = new FeaturedExerciseTypeModel({
        label: '',
        style: '',
        order: this.featuredExerciseTypes.length,
      });
      organization.featured_exercise_types.push(exerciseType);
    }
  };

  handleSaveFeatured = async () => {
    const { organization } = this;
    await this.props.organizationStore.saveFeaturedExerciseTypes(
      organization.id,
      this.featuredExerciseTypes
    );

    if (this.hasErrorFeatured) {
      notification.error({
        message: 'Oops!',
        description: 'Something went wrong!',
      });
      return;
    }

    notification.success({
      message: 'Saved!',
      description: 'Featured exercise types successfully saved.',
    });
  };

  get isSavingFeatured() {
    return this.isOrgLoading;
  }

  get hasErrorFeatured() {
    return this.props.organizationStore.organization.hasError;
  }

  handleSelectedExerciseType = (exerciseType) => {
    const { organization } = this;
    const index = findIndex(organization.active_exercise_types, { id: exerciseType.id });
    if (index === -1) {
      organization.active_exercise_types.push(exerciseType);
    } else {
      organization.active_exercise_types.splice(index, 1);
    }
  };

  handleSaveSelectedExerciseTypes = async () => {
    const { organization } = this;

    await this.props.organizationStore.saveExerciseTypes(
      organization.id,
      organization.active_exercise_types
    );

    if (this.hasErrorExerciseTypes) {
      notification.error({
        message: 'Oops!',
        description: 'Something went wrong!',
      });
      return;
    }

    notification.success({
      message: 'Saved!',
      description: 'Exercise types successfully saved.',
    });
  };

  get isSavingExerciseTypes() {
    return this.isOrgLoading;
  }

  get hasErrorExerciseTypes() {
    return this.props.organizationStore.organization.hasError;
  }

  handleSelectedPulseTemplate = (template) => {
    if (this.selectedPulseTemplates.has(template.id)) {
      this.selectedPulseTemplates.delete(template.id);
      return;
    }

    this.selectedPulseTemplates.set(template.id, true);
  };

  handleSaveSelectedPulseTemplates = async (): Promise<void> => {
    const selectedPulseTemplates = this.allPulseTemplates.filter((template) => {
      return this.selectedPulseTemplates.has(template.id);
    });

    await this.props.organizationStore.savePulseTemplates(
      this.organization.id,
      selectedPulseTemplates
    );
  };

  get orgLogo(): ReactNode {
    const url = this.organization?.logo_urls?.thumbnail;

    if (!url) {
      return null;
    }

    return <img className="logo-thumbnail" src={url} />;
  }

  get isSavingPulseTemplates() {
    return this.isOrgLoading;
  }

  handleLogoChange = async () => {
    try {
      const { organization } = this;
      const formData = new FormData($('#org-logo-form')[0] as HTMLFormElement);
      await this.props.organizationStore.updateLogo(organization, formData);

      notification.success({
        message: 'Uploaded!',
        description: 'Organization Logo uploaded!',
      });
    } catch (err) {
      notification.error({
        message: 'Oops!',
        description: 'Something went wrong!',
      });

      ThirdPartyService.sentry.captureException(err);
    }
  };

  render(): ReactNode {
    if (!this.organization || !this.props.show) {
      return null;
    }

    return (
      <div className="admin-controls">
        <h3>Admin Controls</h3>
        <FeaturedExerciseTypeList
          featuredExerciseTypes={this.featuredExerciseTypes}
          onMoveUp={this.handleMoveUp}
          onMoveDown={this.handleMoveDown}
          onRemove={this.handleRemove}
        />
        <hr />
        <ExerciseTypeSelect
          availableExerciseTypes={this.availableExerciseTypes}
          onAdd={this.handleAdd}
          onSave={this.handleSaveFeatured}
          isSaving={this.isSavingFeatured}
        />
        <hr />
        <RegistrationCode organization={this.organization} />
        <hr />
        <form method="post" id="org-logo-form">
          <p>Current logo:</p>
          {this.orgLogo}
          <p>Upload logo:</p>
          <input type="file" name="logo" onChange={this.handleLogoChange} />
        </form>
        <hr />
        <EmailDomainList organization={this.organization} />
        <hr />
        <AllExerciseTypeList
          allExerciseTypes={this.allExerciseTypes}
          selectedExerciseTypes={this.selectedExerciseTypes}
          onSave={this.handleSaveSelectedExerciseTypes}
          onSelected={this.handleSelectedExerciseType}
          isSaving={this.isSavingExerciseTypes}
        />
        <hr />
        <PulseTemplateList
          allTemplates={this.allPulseTemplates}
          selectedTemplates={this.selectedPulseTemplates.toJSON()}
          onSave={this.handleSaveSelectedPulseTemplates}
          onSelected={this.handleSelectedPulseTemplate}
          isSaving={this.isSavingPulseTemplates}
        />
      </div>
    );
  }
}

export default inject(
  STORE_ORGANIZATION,
  STORE_EXERCISE_TYPE,
  STORE_ADMIN_PULSE_TEMPLATES
)(observer(OrgAdminControls));
