import { AxiosError } from 'axios';

interface Response {
  message?: string;
  errors?: any;
  data?: any;
}

export class ShiftError {
  public constructor(
    readonly message: string = 'Error. Please Try Again.',
    readonly status: ErrorCode = ErrorCode.UNIDENTIFIED,
    readonly errors = {},
    readonly data = {}
  ) {}

  static from(axiosError: AxiosError): ShiftError {
    if (!axiosError.response) {
      return new ShiftError();
    }

    const { status, data } = axiosError.response;
    const response = data as Response;

    return new ShiftError(response?.message, status, response?.errors ?? {}, response?.data);
  }

  errorsByKey(key: string) {
    return this.errors?.[key];
  }

  hasErrorByKey(key: string) {
    return !!this.errorsByKey(key);
  }
}

export enum ErrorCode {
  UNIDENTIFIED,
  UNAUTHORIZED = 401,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  AUTH_TIMEOUT = 419, // aka CSRF_INVALID
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,
  FORBIDDEN = 403,
}
