import TeamGroupModel from 'app/models/TeamGroupModel';

import Store from './Store';

export class TeamGroupStore extends Store<TeamGroupModel> {
  constructor() {
    super();
    TeamGroupModel._store = this;
  }
}

export default TeamGroupStore;
