import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';

export enum CtaActionType {
  Modal = 'modal',
  Link = 'link',
}

export class TeamCardChecklistItemCtaModel extends Model {
  readonly id: string;

  @observable label: string;
  @observable action: string;
  @observable type: CtaActionType;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamCardChecklistItemCtaModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeamCardChecklistItemCtaModel;
  }

  static get(id) {
    return this._get(id) as TeamCardChecklistItemCtaModel;
  }
}

export default TeamCardChecklistItemCtaModel;
