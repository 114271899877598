import { computed } from 'mobx';
import numeral from 'numeral';

export type DiscussionSpaceTriggerInfo = {
  name: string;
  params?: Record<string, any>;
};

export type DiscussionSpaceTipInfo = {
  title: string;
  reasoning: string;
  tip: string;
};

export interface DiscussionSpaceTip {
  key: string; // use to identify tip uniqueness
  title: string;
  reasoning: string;
  content: string;
  params?: Record<string, any>;
}

export class DiscussionSpaceAlignTipModel {
  statementId: number;
  color: string;
  isBimodal: boolean;
  tip: Record<string, DiscussionSpaceTipInfo>;

  constructor({ statementId, color, isBimodal, tip }) {
    this.statementId = numeral(statementId).value();
    this.color = color;
    this.isBimodal = isBimodal;
    this.tip = tip;
  }

  get key() {
    return this.statementId.toString();
  }

  @computed
  get title() {
    return Object.values(this.tip)?.[0]?.title;
  }

  @computed
  get reasoning() {
    return Object.values(this.tip)?.[0]?.reasoning;
  }

  @computed
  get content() {
    return Object.values(this.tip)?.[0]?.tip;
  }

  static parse(data): Record<number, DiscussionSpaceAlignTipModel> {
    if (!data) {
      return {};
    }

    return Object.keys(data).reduce((acc, statementId) => {
      acc[statementId] = new DiscussionSpaceAlignTipModel({
        statementId,
        ...data[statementId],
      });
      return acc;
    }, {});
  }
}

export class DiscussionSpacePerspectiveTipModel {
  letter: string;
  key: string;
  tip: DiscussionSpaceTipInfo;

  params?: Record<string, any>;

  constructor({ letter, key, tip }) {
    this.letter = letter;
    this.key = key;
    this.tip = tip;
  }

  setParams(params) {
    this.params = params;
  }

  get title() {
    return this.tip.title;
  }

  get reasoning() {
    return this.tip.reasoning;
  }

  get content() {
    return this.tip.tip;
  }

  static parse(data): Record<string, Record<string, DiscussionSpacePerspectiveTipModel>> {
    if (!data) {
      return {};
    }

    return Object.keys(data).reduce((acc, letter) => {
      acc[letter] = Object.keys(data[letter]).reduce((subAcc, key) => {
        subAcc[key] = new DiscussionSpacePerspectiveTipModel({
          letter,
          key,
          tip: data[letter][key],
        });
        return subAcc;
      }, {});
      return acc;
    }, {});
  }
}

export class DiscussionSpaceAlignPlusPerspectiveTipModel {
  statementId: number;
  letter: string;
  tip: DiscussionSpaceTipInfo;

  constructor({ statementId, letter, tip }) {
    this.statementId = numeral(statementId).value();
    this.letter = letter;
    this.tip = tip;
  }

  get key() {
    return `${this.statementId}:${this.letter}`;
  }

  get title() {
    return this.tip.title;
  }

  get reasoning() {
    return this.tip.reasoning;
  }

  get content() {
    return this.tip.tip;
  }

  static parse(data): Record<number, DiscussionSpaceAlignPlusPerspectiveTipModel> {
    if (!data) {
      return {};
    }

    return Object.keys(data).reduce((acc, statementId) => {
      acc[statementId] = Object.keys(data[statementId]).reduce((subAcc, letter) => {
        subAcc[letter] = new DiscussionSpaceAlignPlusPerspectiveTipModel({
          statementId,
          letter,
          tip: data[statementId][letter],
        });
        return subAcc;
      }, {});
      return acc;
    }, {});
  }
}
