import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem } from 'app/models';
import ScoreModel from 'app/models/ScoreModel';
import TeamGroupModel from 'app/models/TeamGroupModel';
import TeamGroupReportModel from 'app/models/TeamGroupReportModel';
import Store from 'app/stores/Store';

export class TeamGroupReportStore extends Store<TeamGroupReportModel> {
  @observable teamGroupReport = new ModelItem<TeamGroupReportModel>(TeamGroupReportModel);

  @observable teamGroupReports: {
    [teamGroupID: number]: {
      [exerciseTypeID: number]: any;
    };
  } = {};

  @observable isLoadingByGroupId = new Map();

  constructor() {
    super();
    TeamGroupReportModel._store = this;
  }

  @action
  async loadTeamGroupReportForExerciseID(teamGroup: TeamGroupModel, exerciseTypeID: number) {
    this.isLoadingByGroupId.set(teamGroup.id, true);

    const urlBuilder = ServerRouteHelper.api.teamGroupReport(teamGroup.id, exerciseTypeID);
    const itemId = `${teamGroup.id}-${exerciseTypeID}`;
    await this.teamGroupReport.load(urlBuilder, undefined, { itemId });

    // Check if request was canceled
    if (this.teamGroupReport.item === undefined) {
      this.isLoadingByGroupId.set(teamGroup.id, false);
      return;
    }

    this.teamGroupReports[teamGroup.id] = this.teamGroupReports[teamGroup.id] || {};

    const { exercise_type, group_scores, benchmark_scores } = this.teamGroupReport.item;

    const groupScoresMap = {};
    const groupScoreIDs = Object.keys(group_scores);
    for (const index in groupScoreIDs) {
      const id = groupScoreIDs[index];
      groupScoresMap[id] = group_scores[id];
    }

    this.teamGroupReports[teamGroup.id][exerciseTypeID] = {
      exercise_type: exercise_type,
      group_scores: groupScoresMap,
      benchmark_scores: benchmark_scores.map((data) => new ScoreModel(data)),
    };

    this.isLoadingByGroupId.set(teamGroup.id, false);
  }
}

export default TeamGroupReportStore;
