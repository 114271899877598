import React, { useCallback } from 'react';

import { Checkbox } from 'antd';

import { PRIVACY_POLICY_URL } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';

interface PolicyAcceptanceProps {
  onAcceptPolicy: (checkStatus: boolean) => void;
}

export const PolicyAcceptance: React.FC<PolicyAcceptanceProps> = ({ onAcceptPolicy }) => {
  const handleAcceptPolicy = useCallback(
    (event) => {
      onAcceptPolicy(event.target.checked);
    },
    [onAcceptPolicy]
  );

  return (
    <div id="terms-and-conditions-tc">
      <Checkbox
        onChange={handleAcceptPolicy}
        id="agree-to-terms-check"
        data-testid="agree-to-terms-check"
      >
        I agree to the&nbsp;
        <a target="_blank" href={ServerRouteHelper.agreements.terms()}>
          Terms of use
        </a>
        &nbsp;and&nbsp;
        <a target="_blank" href={PRIVACY_POLICY_URL}>
          Privacy Policy
        </a>
      </Checkbox>
    </div>
  );
};

export default PolicyAcceptance;
