// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Swqew4\\+hsZAbNeDE4jnSWQ\\=\\={margin-top:1rem}.Swqew4\\+hsZAbNeDE4jnSWQ\\=\\= .u7i\\+VucgK2YPc6dFFDPU3g\\=\\={width:20rem}.Swqew4\\+hsZAbNeDE4jnSWQ\\=\\= .Lgn9yBWs6ddL-lChXpydDw\\=\\={margin-right:1rem;width:40rem}.Swqew4\\+hsZAbNeDE4jnSWQ\\=\\= .qBHmRxuN7NoKDk3qW018lw\\=\\={display:flex;justify-content:space-between}.Swqew4\\+hsZAbNeDE4jnSWQ\\=\\= .ggyulOq\\+1nq3ltk56RNhWg\\=\\={position:absolute;right:1rem;top:1rem}.CEIEjswZFtoTkHWEB\\+ivww\\=\\={float:right;margin-bottom:2rem}", "",{"version":3,"sources":["webpack://./resources/assets/js/pages/admin/PulseTemplates/PulseTemplates.module.scss"],"names":[],"mappings":"AAEA,6BACE,eAgFF,CA9EE,0DACE,WAgFJ,CA7EE,yDAEE,iBAAA,CADA,WAgFJ,CA5EE,yDACE,YAAA,CACA,6BA8EJ,CA3EE,0DACE,iBAAA,CAEA,UAAA,CADA,QA8EJ,CAzEA,6BACE,WAAA,CACA,kBA4EF","sourcesContent":["@import 'resources/assets/sass/bootstrap_variables';\n\n.templateForm {\n  margin-top: 1rem;\n\n  .categoryName {\n    width: 20rem;\n  }\n\n  .statementName {\n    width: 40rem;\n    margin-right: 1rem;\n  }\n\n  .subSectionHeader {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  .saveBtn {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n  }\n}\n\n.createNewTemplateBtn {\n  float: right;\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"templateForm": "Swqew4+hsZAbNeDE4jnSWQ==",
	"categoryName": "u7i+VucgK2YPc6dFFDPU3g==",
	"statementName": "Lgn9yBWs6ddL-lChXpydDw==",
	"subSectionHeader": "qBHmRxuN7NoKDk3qW018lw==",
	"saveBtn": "ggyulOq+1nq3ltk56RNhWg==",
	"createNewTemplateBtn": "CEIEjswZFtoTkHWEB+ivww=="
};
export default ___CSS_LOADER_EXPORT___;
