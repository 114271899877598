import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import SubscriptionItemProviderStore from 'app/stores/SubscriptionItemProviderStore';

export class SubscriptionItemProviderModel extends Model {
  static _store: SubscriptionItemProviderStore;

  readonly id: number;
  @observable name: string;
  @observable description: string;
  @observable type: string; // stripe, etc.

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as SubscriptionItemProviderModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as SubscriptionItemProviderModel;
  }

  static get(id) {
    return this._get(id) as SubscriptionItemProviderModel;
  }
}

export default SubscriptionItemProviderModel;
