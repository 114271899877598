import React from 'react';

import { MemberAvatarProps as MemberAvatarModelProps } from 'app/models/MemberAvatarModel';

export interface MemberAvatarProps extends MemberAvatarModelProps {
  size?: string;
  fontSize?: string;
}

/**
 * MemberAvatar will draw an avatar based on a Member object
 */
export const MemberAvatar: React.FC<MemberAvatarProps> = ({ text, color, size, fontSize }) => (
  <div
    className="avatar"
    style={{
      backgroundColor: color,
      width: size,
      height: size,
      fontSize: fontSize,
    }}
  >
    <span translate="no">{text}</span>
  </div>
);

MemberAvatar.defaultProps = {
  size: '2rem',
  fontSize: '0.85rem',
};

export default MemberAvatar;
