const allModels = {
  TeamModel: null,
  AlignModel: null,
  PulseForTeamModel: null,
  DiscussionSpaceModel: null,
  PulseResponseActivityModel: null,
  PulseActivityModel: null,
  MemberModel: null,
  StatementNotesModel: null,
  OrganizationModel: null,

  register(map: any): void {
    Object.assign(this, map);
  },
};

export default allModels;
