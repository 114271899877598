import { PerspectiveMemberResultModel } from 'app/models';

import Store from './Store';

export class PerspectiveMemberResultStore extends Store<PerspectiveMemberResultModel> {
  constructor() {
    super();
    PerspectiveMemberResultModel._store = this;
  }
}

export default PerspectiveMemberResultStore;
