import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const ManagerPlaybook = lazy(() => import('./ManagerPlaybook'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route
        exact
        path="/dashboard/teams/:teamId/perspective/manager-playbook/:memberId?"
        component={ManagerPlaybook}
      />
    </Switch>
  </Suspense>
);
