import React from 'react';

import { ParentSize } from '@visx/responsive';
import cx from 'classnames';
import { ConnectDropTarget } from 'react-dnd';

import './ScatterPlotCard.scss';

import { ExerciseStatementCardBasic } from 'app/components/features/ExerciseStatementCard';
import { AlignModel, ExerciseTypeModel } from 'app/models';

import { getLinearScales, renderLabels } from './ScatterPlotCard';

const CARD_SIZE = 88;
const CARD_OFFSET = 4;

export interface StatementCard {
  id: number | string;
  title: string;
  scaledX?: number;
  scaledY?: number;
  placed?: boolean;
  isHighlighted?: boolean;
}

export interface ScatterPlotCardBasicProps {
  statements: StatementCard[];
  highlightedStatements?: StatementCard[];
  exerciseType: ExerciseTypeModel;
  exercise?: AlignModel;
  boundingBoxRef?: React.MutableRefObject<any>;
  dropTargetRef?: ConnectDropTarget;
  onStatementCardDrop?: (id, statement) => void;
  onSize?: (size: number) => void;
}

const ScatterPlotCardBasic: React.FC<ScatterPlotCardBasicProps> = ({
  exercise,
  exerciseType,
  statements,
  boundingBoxRef,
  dropTargetRef,
  onSize,
}) => (
  <ParentSize>
    {({ width: size }) => {
      const { dx, dy } = getLinearScales(size);
      onSize?.(size);

      const hasAtleastOneHighlightedStatement = (): boolean => {
        return statements.filter((statement) => statement.isHighlighted === true).length > 0;
      };

      return (
        <div
          data-testid="scatter-plot-card-basic"
          className="scatter-plot-card"
          style={{ width: size, height: size }}
        >
          <div className="scatter-plot-card-inner">
            <div className="outer" ref={boundingBoxRef}>
              <div className="scatter-plot-grid-container">
                <div className="outer" ref={dropTargetRef}>
                  {statements.map(({ id, scaledX, scaledY, title, isHighlighted }) => (
                    <ExerciseStatementCardBasic
                      key={id}
                      title={title}
                      className={cx({
                        faded: hasAtleastOneHighlightedStatement() && !isHighlighted,
                      })}
                      left={dx(scaledX) - CARD_SIZE / 2 + CARD_OFFSET}
                      top={dy(scaledY) - CARD_SIZE / 2 + CARD_OFFSET}
                      mobileScale
                      placed
                    />
                  ))}
                </div>
              </div>

              {renderLabels(size, dx, dy, exerciseType, exercise)}
            </div>
          </div>
        </div>
      );
    }}
  </ParentSize>
);

export default ScatterPlotCardBasic;
