import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { Model } from 'app/models';
import CoachModel from 'app/models/CoachModel';

import Store from './Store';

export class CoachStore extends Store<Model> {
  constructor() {
    super();
    CoachModel._store = this;
  }

  @observable coachData: CoachModel;
  @action setCoachData = (coachData) => (this.coachData = coachData);

  @observable isLoadingCoachData: boolean;
  @action setIsLoadingCoachData = (status) => (this.isLoadingCoachData = status);

  async loadCoachData(): Promise<void> {
    const url = ServerRouteHelper.api.members.loadCoachData();
    this.setIsLoadingCoachData(true);
    const response = await this.apiService.get(url);
    this.setIsLoadingCoachData(false);

    this.setCoachData(CoachModel.fromJson(response));
  }
}
