import My360sStatsModel from 'app/models/My360sStatsModel';

import Store from './Store';

export class My360sStatsStore extends Store<My360sStatsModel> {
  constructor() {
    super();
    My360sStatsModel._store = this;
  }
}

export default My360sStatsStore;
