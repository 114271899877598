import { observable } from 'mobx';

import ShiftModel from './ShiftModel';

export interface FeaturedExerciseTypeModelProps {
  id?: number;
  label?: string;
  style?: string;
  order?: number;
}

export class FeaturedExerciseTypeModel extends ShiftModel<FeaturedExerciseTypeModelProps> {
  readonly id?: number;
  @observable label?: string;
  @observable style?: string;
  @observable order?: number;
}

export default FeaturedExerciseTypeModel;
