import { observable } from 'mobx';

import ExerciseTypeModel from 'app/models/ExerciseTypeModel';
import { Model, ModelJson } from 'app/models/Model';
import OrganizationStatsStore from 'app/stores/OrganizationStatsStore';

export interface OrganizationStatsProps {
  name: string;
  teamsCount: number;
  teamsCountPrev: number;
  membersCount: number;
  exercisesCount: number;
  membersCountPrev: number;
  membersWithCompletedAlignCount: number;
  teamsWithExercises: number;
  teamsWithExercisesPrev: number;
  membersWithCompletedExercises: number;
  membersWithCompletedExercisesPrev: number;
  membersWithPerspective: number;
  membersWithPerspectivePrev: number;
  topStatementsToCelebrate: { text: string; exercise_type: string }[];
  topStatementsToDiagnose: { text: string; exercise_type: string }[];
  recentTeams: { name: string; id: number }[];
  teamExerciseTypes: ExerciseTypeModel[];
}

export class OrganizationStats extends Model {
  static _store: OrganizationStatsStore;

  @observable id: number;
  @observable name: string;
  @observable teamsCount: number;
  @observable teamsCountPrev: number;
  @observable membersCount: number;
  @observable membersCountPrev: number;
  @observable membersWithCompletedAlignCount: number;
  @observable teamsWithExercises: number;
  @observable teamsWithExercisesPrev: number;
  @observable membersWithCompletedExercises: number;
  @observable membersWithCompletedExercisesPrev: number;
  @observable membersWithPerspective: number;
  @observable membersWithPerspectivePrev: number;
  @observable topStatementsToCelebrate: { text: string; exercise_type: string }[];
  @observable topStatementsToDiagnose: { text: string; exercise_type: string }[];
  @observable recentTeams: { name: string; id: number }[];
  @observable exercisesCount: number;
  @observable teamExerciseTypes: ExerciseTypeModel[];

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as OrganizationStats;
  }
}
