import { action, observable } from 'mobx';

import { ClientDataHelper, ServerRouteHelper } from 'app/helpers';
import ContentSectionModel from 'app/models/ContentSectionModel';
import { ModelItem } from 'app/models/ModelItem';
import { ModelList } from 'app/models/ModelList';
import Store from 'app/stores/Store';

export class ContentSectionStore extends Store<ContentSectionModel> {
  @observable contentSections = new ModelList<ContentSectionModel>(ContentSectionModel);
  @observable contentSection = new ModelItem<ContentSectionModel>(ContentSectionModel);

  @observable isLoadingContentSection: boolean;
  @action private setIsLoadingContentSection = (status) => (this.isLoadingContentSection = status);

  constructor() {
    super();
    ContentSectionModel._store = this;
  }

  @action async loadContentSections() {
    this.setIsLoadingContentSection(true);
    const data = await ClientDataHelper.get('sectionData');
    const orgId = await ClientDataHelper.get('orgId');

    const sections = Object.keys(data).map((sectionName) => {
      const section = ContentSectionModel.fromJson(data[sectionName]);
      section.updateFromJson({ organization_id: orgId });
      return section;
    });

    this.contentSections.setItems(sections);
    this.setIsLoadingContentSection(false);
  }

  updateContentSection(section: ContentSectionModel) {
    const url = ServerRouteHelper.api.admin.contentSections.updateContentSection(
      section.section_name
    );
    const { id, section_name, type, content, organization_id, enabled } = section;
    const data = { id, section_name, type, organization_id, enabled, content: content };
    return this.apiService.post(url, data);
  }

  getByName(name: string) {
    const url = ServerRouteHelper.api.contentSections.show(name);
    return this.contentSection.load(url);
  }
}

export default ContentSectionStore;
