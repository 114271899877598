import React, { CSSProperties, ReactNode } from 'react';

import { Breadcrumb } from 'antd';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { STORE_ADMIN_STATS } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import { ModelList } from 'app/models';
import AdminStatsModel from 'app/models/AdminStatsModel';
import { AdminStatsStore } from 'app/stores';

export interface InsightStatsProps extends RouteComponentProps {
  adminStatsStore?: AdminStatsStore;
}

const TOP_COLORS = {
  celebrate: 'Green',
  insights: 'Yellow',
  improve: 'Red',
};

export class InsightStats extends React.Component<InsightStatsProps> {
  constructor(props: InsightStatsProps) {
    super(props);

    this.init();
  }

  private async init() {
    const { adminStatsStore } = this.props;
    await adminStatsStore.getInsigthStats();
  }

  @computed
  get stats(): ModelList<AdminStatsModel> {
    return this.props.adminStatsStore?.stats;
  }

  get title(): string {
    return 'Insight Stats TF Family';
  }

  @computed
  get breadcrumb(): ReactNode {
    return (
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item key="home-welcome" href={ServerRouteHelper.admin.home()}>
          Admin
        </Breadcrumb.Item>
        <Breadcrumb.Item key="insight-stats">
          <Link to={ServerRouteHelper.admin.insightStats()}>{this.title}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  formatCatLabel(category: string): string {
    const color = TOP_COLORS[category];
    return `${category} (${color})`;
  }

  formatCatGroup(category: []): string {
    return category?.slice(0, 3).join(' / ');
  }

  formatRow(item: AdminStatsModel): ReactNode {
    return (
      <tr key={item.id}>
        <td key="id">{item.id}</td>
        <td key="count">{item.count}</td>
        <td key="percent">{item.percent}</td>
        <td key="type">{this.formatCatLabel(item.top.type)}</td>
        <td key="top_counts">{this.formatCatGroup(item.top.counts)}</td>
        <td key="top_percents">{this.formatCatGroup(item.top.percents)}</td>
        <td key="rank_counts">{this.formatCatGroup(item.ranks.counts)}</td>
        <td key="rank_percents">{this.formatCatGroup(item.ranks.percents)}</td>
      </tr>
    );
  }

  @computed
  get formatStats(): ReactNode {
    if (!this.stats || this.stats.items.length === 0) {
      return (
        <p>
          No stats data found: please run <code>shift:admin-stats:data-insights</code> command.
        </p>
      );
    }

    const headingStyles = {
      borderLeft: '1px solid #CCC',
      textAlign: 'center',
    } as CSSProperties;

    return (
      <table className="modern-dash-table">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th style={headingStyles} colSpan={2}>
              By Score (1st / 2nd / 3rd)
            </th>
            <th style={headingStyles} colSpan={2}>
              By Rank Position (1st / 2nd / 3rd)
            </th>
          </tr>
          <tr>
            <th key="h1">Name</th>
            <th key="h2">Count</th>
            <th key="h3">Percentage</th>
            <th key="h4">Type</th>
            <th key="h5">Counts</th>
            <th key="h6">Percentage</th>
            <th key="h7">Counts</th>
            <th key="h8">Percentage</th>
          </tr>
        </thead>
        <tbody>{this.stats.items.map((item) => this.formatRow(item))}</tbody>
      </table>
    );
  }

  public render(): ReactNode {
    return (
      <div className="col pb-4 px-4 px-md-5">
        {this.breadcrumb}

        <div className="insight-stats widget">
          <div className="widget__title d-flex justify-content-between align-items-center">
            <h1 className="d-inline-block mr-1 mb-0">{this.title}</h1>
          </div>

          <div className="widget__body p-4 overflow-auto">
            <p>
              Data Insights stats for the last <strong>90 days</strong>.
            </p>
            <p>
              Counts the number of times an anomaly is detected for an exercise and where it places
              in categories <em>(Celebrate, Insight, Improve)</em>, based on rank and score.
            </p>
            <h5>Definitions</h5>
            <ul>
              <li>
                <strong>Count:</strong> Total number of times an anomaly was detected in a closed
                exercise.
              </li>
              <li>
                <strong>Percentage:</strong> The count is reflected as a percentage of the total.
              </li>
              <li>
                <strong>Type:</strong> Each insight is based on anomaly detection, and each anomaly
                is of a specific type whereby the insight will be part of the Red, Yellow or Green
                advice sections.
              </li>
              <li>
                <strong>By Score:</strong> Insights are sorted by the highest scored matching
                statement, and the top 3 positions recorded in the stats. Green insights are ordered
                from highest to lowest, others from lowest to highest.
              </li>
              <li>
                <strong>By Rank:</strong> Insights are sorted by a static rank field that signifies
                the importance of the insight, from highest to lowest, regardless of how the
                exercise was answered.
              </li>
            </ul>
            <h5>Considerations</h5>
            <ul>
              <li>
                Not all anomalies have had the rank field applied and could affect the stats
                reported.
              </li>
              <li>Older insight caches will not include ranked insights or newer anomalies.</li>
              <li>
                Align statement scores can be calculated by using <code>sum()</code> on an axis or
                using <code>mean()</code>. The backend and these stats currently use the sum method.
              </li>
            </ul>
            {this.formatStats}
          </div>
        </div>
      </div>
    );
  }
}

export default inject(STORE_ADMIN_STATS)(observer(InsightStats));
