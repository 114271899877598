import React, { Component, ReactNode } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import styles from './QuickAddHabitsCard.module.scss';

import EnhancedCard from 'app/components/ui/EnhancedCard';
import { stopPropagation } from 'app/helpers';
import { PulseStatementModel, PulseTemplateGroupModel, PulseTemplateModel } from 'app/models';
import PulseHabitsContext from 'app/pages/dashboard/Pulse/Context/PulseHabitsContext';

const { Panel } = Collapse;

export class QuickAddHabitsCard extends Component {
  static contextType = PulseHabitsContext;

  @computed
  get templateGroups(): ReactNode {
    const templateGroups = this.context.uiStore.pulseTemplateGroupStore.templateGroups.items;

    return (
      <Collapse
        accordion
        className={styles.templatesAccordion}
        bordered={false}
        defaultActiveKey={0}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <RightOutlined className={styles.templatesExpandIcon} rotate={isActive ? 90 : 0} />
        )}
      >
        {templateGroups.map((templateGroup, i) => (
          <Panel key={i} className={styles.templatePanel} header={templateGroup.name}>
            {this.renderTemplateGroup(templateGroup)}
          </Panel>
        ))}
      </Collapse>
    );
  }

  renderTemplateGroup = (templateGroup: PulseTemplateGroupModel): ReactNode => {
    return (
      <Collapse
        accordion
        className={styles.categoriesAccordion}
        bordered={false}
        defaultActiveKey={0}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <RightOutlined className={styles.categoriesExpandIcon} rotate={isActive ? 90 : 0} />
        )}
      >
        {templateGroup.templates.items.map((template, i) => (
          <Panel
            key={i}
            className={styles.categoryPanel}
            header={
              <>
                {template.name}&nbsp;
                <Button
                  size="small"
                  type="link"
                  className={styles.selectAll}
                  onClick={stopPropagation(() => this.handleSelectAll(template))}
                >
                  Select all
                </Button>
              </>
            }
          >
            {this.renderTemplate(template)}
          </Panel>
        ))}
      </Collapse>
    );
  };

  renderTemplate = (template: PulseTemplateModel): ReactNode => {
    const { selectedHabitsById } = this.context.uiStore;

    return (
      <ul>
        {template.statements.items.map((statement) => (
          <li
            key={statement.id}
            className={!!selectedHabitsById?.[statement.id] ? styles.questionActive : undefined}
            onClick={() => this.handleAddHabit(statement)}
          >
            {statement.text}
          </li>
        ))}
      </ul>
    );
  };

  handleSelectAll = (template: PulseTemplateModel): void => {
    template.statements.items.map((statement) => {
      this.context.uiStore.updateEmptyHabit(statement);
    });
  };

  handleAddHabit = (statement: PulseStatementModel): void => {
    this.context.uiStore.updateEmptyHabit(statement);
  };

  render(): ReactNode {
    return (
      <EnhancedCard
        headerClassName={styles.quickCardHeader}
        heading="Quick-Add Habits from our templates"
        subHeading="Select from our suggested habits below, make edits to them, or write your own custom habits on the right panel"
      >
        <div className="card-content-container">{this.templateGroups}</div>
      </EnhancedCard>
    );
  }
}

export default observer(QuickAddHabitsCard);
