import posthog from 'posthog-js';

//
// Setup posthog
//
export const initializePosthog = (userJustAccepted = false): boolean => {
  const useAcceptedPerformanceCookies =
    accompany.currentUserAcceptedPerformanceCookies || userJustAccepted;
  const isPosthogActive = !accompany.isAdmin && accompany.posthog.active;

  if (!isPosthogActive || !useAcceptedPerformanceCookies) {
    return false;
  }

  posthog.init(accompany.posthog.key, { api_host: accompany.posthog.host });
  if (accompany?.authMember?.trackingId) {
    posthog.identify(accompany.authMember.trackingId, {
      email: accompany.authMember.email,
    });
  }

  // make posthog available to the rest of the app
  window['posthog'] = posthog;

  return true;
};
