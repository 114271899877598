import { ModelJson } from 'app/models/Model';
import { DiscussionSpaceWebsocketStateStore } from 'app/stores';

import allModels from './allModels';
import DiscussionSpaceAgendaModel from './DiscussionSpaceAgendaModel';
import DiscussionSpaceNoteModel from './DiscussionSpaceNoteModel';
import DiscussionSpaceRaisedHandModel from './DiscussionSpaceRaisedHandModel';
import DiscussionSpaceVoteModel from './DiscussionSpaceVoteModel';
import MemberModel from './MemberModel';
import WebsocketStateModel from './WebsocketStateModel';

export enum DiscussionSpaceWebsocketEvent {
  NoteCreated = 'ds_note_created',
  NoteUpdated = 'ds_note_updated',
  NoteDeleted = 'ds_note_deleted',

  AgendaCreated = 'ds_agenda_created',
  AgendaUpdated = 'ds_agenda_updated',
  NotesReordered = 'ds_notes_reordered',

  TipsTriggered = 'ds_tips_triggered',
  HandRaised = 'ds_hand_raised',

  UpVoted = 'ds_up_voted',
  DownVoted = 'ds_down_voted',
}

export interface DiscussionSpaceWebsocketState {
  active_agenda_id: number;
  active_agenda_started_at: number;
  selected_align_statement_ids: number[];
  active_agenda_statement_id: number | null;
  explore_statement_step: Record<string, number>;
  show_celebration_card: boolean;

  // Sort config structure, note that
  // only explore supports object value.
  // {
  //   agenda_id: 'asc',
  //   agenda_id: {
  //     statement_id: ['asc', 'desc']
  //   }
  //   ...
  // }
  agendas_sort_config: Record<number, string | Record<number, string[]>>;
}

export class DiscussionSpaceWebsocketStateModel extends WebsocketStateModel<DiscussionSpaceWebsocketState> {
  static _store: DiscussionSpaceWebsocketStateStore;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as DiscussionSpaceWebsocketStateModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as DiscussionSpaceWebsocketStateModel;
  }

  static get(id) {
    return this._get(id) as DiscussionSpaceWebsocketStateModel;
  }

  bindChannels() {
    super.bindChannels();

    // Discussion Space
    this.bindEvent(DiscussionSpaceWebsocketEvent.TipsTriggered, this.handleTipsTriggered);
    this.bindEvent(DiscussionSpaceWebsocketEvent.HandRaised, this.handleHandRaised);

    // Notes
    this.bindEvent(DiscussionSpaceWebsocketEvent.NoteCreated, this.handleNoteCreated);
    this.bindEvent(DiscussionSpaceWebsocketEvent.NoteUpdated, this.handleNoteUpdated);
    this.bindEvent(DiscussionSpaceWebsocketEvent.NoteDeleted, this.handleNoteDeleted);

    // Agenda
    this.bindEvent(DiscussionSpaceWebsocketEvent.AgendaCreated, this.handleAgendaCreated);
    this.bindEvent(DiscussionSpaceWebsocketEvent.AgendaUpdated, this.handleAgendaUpdated);
    this.bindEvent(DiscussionSpaceWebsocketEvent.NotesReordered, this.handleNotesReordered);

    // Voting
    this.bindEvent(DiscussionSpaceWebsocketEvent.UpVoted, this.handleUpVoted);
    this.bindEvent(DiscussionSpaceWebsocketEvent.DownVoted, this.handleDownVoted);
  }

  protected handleStateUpdate(newState: DiscussionSpaceWebsocketState): void {
    if (DiscussionSpaceWebsocketStateModel._store.shouldSkipState(newState, this.state)) {
      return;
    }

    this.setState(newState);
  }

  handleHandRaised = (raisedHand) => {
    if (DiscussionSpaceRaisedHandModel.getStore().find('uuid', raisedHand.uuid)) {
      return;
    }

    DiscussionSpaceRaisedHandModel.fromJson(raisedHand);
  };

  handleTipsTriggered = ({ authorId, discussionSpaceId, triggers }) => {
    const currentMember = MemberModel._store.currentMember;

    // Skip trigger author because it was already triggered locally
    if (!currentMember.item || currentMember.item.id === authorId) {
      return;
    }

    const discussionSpace = allModels.DiscussionSpaceModel.get(discussionSpaceId);
    triggers.forEach((trigger) => discussionSpace?.showTip(trigger));
  };

  handleNoteCreated = (note) => {
    // Skip if already exists
    if (DiscussionSpaceNoteModel.getStore().find('uuid', note.uuid)) {
      return;
    }

    // Reify note and push to its agenda
    const newNote = DiscussionSpaceNoteModel.fromJson(note);
    DiscussionSpaceAgendaModel.appendNote(newNote);
  };

  handleNoteUpdated = (note) => {
    let existingNote = DiscussionSpaceNoteModel.get(note.id);
    if (existingNote) {
      existingNote.updateFromJson(note);
    } else {
      existingNote = DiscussionSpaceNoteModel.fromJson(note);
    }

    DiscussionSpaceAgendaModel.updateNote(existingNote);
  };

  handleNoteDeleted = (note) => {
    const agenda = DiscussionSpaceAgendaModel.getOrNew(note.agenda_id);
    agenda.deleteNote(note);
  };

  handleAgendaCreated = (agenda) => {
    // Skip if already exists
    if (DiscussionSpaceAgendaModel.getStore().find('uuid', agenda.uuid)) {
      return;
    }

    DiscussionSpaceAgendaModel._store.createAgendaSlot(agenda);
    DiscussionSpaceAgendaModel.fromJson(agenda);
  };

  handleAgendaUpdated = (agenda) => {
    const existingAgenda = DiscussionSpaceAgendaModel.get(agenda.id);
    existingAgenda.updateFromJson(agenda);
  };

  handleUpVoted = (vote) => {
    DiscussionSpaceVoteModel.fromJson(vote);
    DiscussionSpaceVoteModel._store.addVote(vote);
  };

  handleDownVoted = (vote) => {
    DiscussionSpaceVoteModel.fromJson(vote);
    DiscussionSpaceVoteModel._store.deleteVote(vote);
  };

  handleNotesReordered = ({ id, notes }) => {
    const agenda = DiscussionSpaceAgendaModel.get(id);
    agenda.reorderNotes(notes);
  };
}

export default DiscussionSpaceWebsocketStateModel;
