import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { ModelList } from 'app/models/ModelList';
import PerspectiveDimensionStore from 'app/stores/PerspectiveDimensionStore';

import type { PerspectiveDimensionIndex } from './MemberPerspectiveResultModel';
import PerspectiveDimensionLensModel from './PerspectiveDimensionLensModel';

export interface PerspectiveDimensionTip {
  id?: number;
  title: string;
  preview: string;
  fulltext: string;
  ratingKey: string;
  formattedTitle?: string;
}

export interface PerspectivePairDifferentTraitPoint {
  points: string[];
  solutionIdeas: string[];
}

export class PerspectiveDimensionModel extends Model {
  static _store: PerspectiveDimensionStore;

  readonly id: string;
  @observable binnedScore: number;
  @observable descriptionHtml: string;
  @observable dimension: PerspectiveDimensionIndex;
  @observable dimensionDisplayName: string;
  @observable lenses = new ModelList<PerspectiveDimensionLensModel>(PerspectiveDimensionLensModel);
  @observable otherLenses = new ModelList<PerspectiveDimensionLensModel>(
    PerspectiveDimensionLensModel
  );
  @observable rawScore: string;
  @observable memberScores?: Array<{ memberId: number; binnedScore: number }>;
  @observable spreadType?: string;
  @observable differences?: Array<{ brief: string; rest: string }>;
  @observable risks?: string;
  @observable tips?: PerspectiveDimensionTip[];

  @observable leftLabel: string;
  @observable rightLabel: string;
  @observable leftToolTip: string;
  @observable rightToolTip: string;
  @observable title: string;
  @observable subtitle: string;
  @observable ratingKey: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectiveDimensionModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PerspectiveDimensionModel;
  }

  static get(id) {
    return this._get(id) as PerspectiveDimensionModel;
  }
}

export default PerspectiveDimensionModel;
