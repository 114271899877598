import React from 'react';

import './ScatterPlotsByCategory.scss';

import { ExerciseReportModel } from 'app/models';

import SummaryPrintView from '../SummaryPrintView';

export interface ScatterPlotsByCategoryProps {
  report: ExerciseReportModel;
}

export const ScatterPlotsByCategory: React.FC<ScatterPlotsByCategoryProps> = ({ report }) => (
  <SummaryPrintView
    report={report}
    scatterPlotsPerPage={9}
    scatterPlotSize={250}
    className="scatter-plots-by-category"
  />
);

export default ScatterPlotsByCategory;
