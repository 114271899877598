export const apostrophize = (str: string, apostrophe = '’', s = 's') => {
  if (str[str.length - 1] === 's') {
    return str + apostrophe;
  }
  return str + apostrophe + s;
};

export const pluralize = (test: number | boolean, singular: string, plural: string = undefined) => {
  // cannot do "!test" because we want to pluralize if the count is equal to 0 as well
  if (test === false || test === 1) {
    return singular;
  }

  if (plural) {
    return plural;
  }

  return `${singular}s`;
};

// If we start using this helper often, we should swap it for a library
// not only will a library allow us to go above ten, but it'll also be
// another piece of code that isn't our job to maintain
const digits = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
];

export const numberToWord = (input: number) => {
  if (input > digits.length) {
    return input;
  }

  return digits[input];
};

export const numberToAlphabet = (input: number) => {
  return String.fromCharCode(65 + input);
};

export const getWordArticle = (word: string): string => {
  const letter = word.toUpperCase().charAt(0);
  return letter == 'A' || letter == 'E' || letter == 'I' || letter == 'O' || letter == 'U'
    ? 'an'
    : 'a';
};
