import { action, observable } from 'mobx';

import PlainModel from './PlainModel';

export class PagingInfoModel extends PlainModel {
  @observable public search?: string;
  @observable public page?: number;
  @observable public page_size?: number;

  @action
  incrementPage = (): void => {
    this.page += 1;
  };

  @action
  resetPage = (): void => {
    this.page = 1;
  };

  toJSON(): Record<string, any> {
    return ['search', 'page', 'page_size'].reduce((acc, field) => {
      if (!!this[field]) {
        acc[field] = this[field];
      }
      return acc;
    }, {});
  }
}

export default PagingInfoModel;
