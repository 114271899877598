import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem, PagingMetaModel, PulseStatementModel, PulseTemplateModel } from 'app/models';
import { ModelList } from 'app/models/ModelList';
import ThirdPartyService from 'app/services/ThirdPartyService';
import Store from 'app/stores/Store';

export class AdminPulseTemplateStore extends Store<PulseTemplateModel> {
  @observable allTemplates = new ModelList<PulseTemplateModel>(PulseTemplateModel);
  @observable templates = new ModelList<PulseTemplateModel>(PulseTemplateModel);
  @observable currentTemplate = new ModelItem<PulseTemplateModel>(PulseTemplateModel);
  @observable categories = new ModelList<PulseTemplateModel>(PulseTemplateModel);

  @observable pageMeta: PagingMetaModel;

  @observable isSaving = false;
  @action setIsSaving = (isSaving: boolean): void => {
    this.isSaving = isSaving;
  };

  @observable isLoadingTemplates = false;
  @action setIsLoadingTemplates = (isLoadingTemplates: boolean): void => {
    this.isLoadingTemplates = isLoadingTemplates;
  };

  constructor() {
    super();
    PulseTemplateModel._store = this;
  }

  loadTemplates = async (pageNumber: number = null): Promise<any> => {
    this.setIsLoadingTemplates(true);
    const url = ServerRouteHelper.api.admin.pulses.templates.list();

    const params = {};
    if (pageNumber) {
      params['page'] = pageNumber;
    }

    const response = await this.apiService.get(url, params);

    this.templates.setItems(response.data);
    this.pageMeta = new PagingMetaModel(response.meta);

    this.setIsLoadingTemplates(false);
  };

  loadAllTemplates = async (): Promise<any> => {
    this.setIsLoadingTemplates(true);
    const url = ServerRouteHelper.api.admin.pulses.templates.all();

    const config = { url };

    const response = await this.apiService.newGet(config);

    if (response !== undefined) {
      this.allTemplates.setItems(response.data);
    }

    this.setIsLoadingTemplates(false);
  };

  loadTemplate = async (id: number): Promise<any> => {
    const url = ServerRouteHelper.api.admin.pulses.templates.get(id);
    const response = await this.apiService.get(url);

    this.currentTemplate.setItem(PulseTemplateModel.fromJson(response.data));

    const rawCategories = response.data.categories ?? [];
    if (!rawCategories.length) {
      return;
    }

    const categories = rawCategories.map((rawCategory) => PulseTemplateModel.fromJson(rawCategory));
    this.categories.setItems(categories);
  };

  createTemplate = async (): Promise<any> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.admin.pulses.templates.create();
      const response = await this.apiService.post(url, this.currentTemplate.item);

      return this.currentTemplate.setItem(response?.data);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  createCategory = async (category: any): Promise<any> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.admin.pulses.templates.createCategory();
      const response = await this.apiService.post(url, category);

      return this.categories.appendItem(PulseTemplateModel.fromJson(response.data));
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  updateCategory = async (
    category: PulseTemplateModel,
    statements: Partial<PulseStatementModel>[]
  ): Promise<any> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.admin.pulses.templates.updateCategory(category.id);
      const data = { ...category, statements };

      const response = await this.apiService.put(url, data);
      category.updateFromJson(response.data);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };
}

export default AdminPulseTemplateStore;
