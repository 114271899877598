import Pusher, { Channel } from 'pusher-js';

interface WebSocketHelperMocker {
  onSubscribe: (string) => void;
  onUnsubscribe: (string) => void;
  onBind: (string) => void;
}

/**
 * A this wrapper around the Pusher Library to make
 * swapping it out with a different provider easy in the future.
 */
export default class WebSocketHelper {
  static _instance: WebSocketHelper;

  static mockSharedInstance(mock: WebSocketHelperMocker) {
    this._instance = new WebSocketHelper(mock);
  }

  static get sharedInstance() {
    if (!this._instance) {
      this._instance = new WebSocketHelper();
    }

    return this._instance;
  }

  pusher: Pusher;
  _mock: WebSocketHelperMocker;

  constructor(mock?: WebSocketHelperMocker) {
    if (mock) {
      this._mock = mock;
      return this;
    }

    if (!accompany.pusherConfig) {
      console.warn(
        'WebSocket Helper attempted to initialize, but accompany.pusherConfig is not set'
      );
      return null;
    }

    this.pusher = new Pusher(accompany.pusherConfig.appKey, {
      cluster: accompany.pusherConfig.cluster,
    });
  }

  subscribe(channel: string) {
    if (this._mock) {
      this._mock.onSubscribe(channel);

      return {
        bind: this._mock.onBind,
        unsubscribe: () => this._mock.onUnsubscribe(channel),
      } as any as Channel;
    }

    if (accompany.pusherConfig.channelPrefix) {
      channel = [accompany.pusherConfig.channelPrefix, channel].join('.');
    }

    return this.pusher.subscribe(channel);
  }
}
