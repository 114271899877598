import React, { ReactElement } from 'react';

import { CoffeeOutlined, DotChartOutlined, MessageFilled } from '@ant-design/icons';
import { Affix, Drawer } from 'antd';
import cx from 'classnames';
import { sortBy } from 'lodash';
import { action, computed, observable, when } from 'mobx';
import { inject, observer } from 'mobx-react';
import { generatePath, RouteComponentProps, Switch } from 'react-router';

import './My360Report.scss';
import 'app/components/features/ReportNavigation/TabbedReportNavigation.scss';

import AlignSummaryPrintView, {
  ScatterPlotsByCategory,
  ScatterPlotsPerPage,
} from 'app/components/features/AlignSummaryPrintView';
import {
  ScrollDirection,
  TabbedReportNavigation,
} from 'app/components/features/ReportNavigation/TabbedReportNavigation';
import Loading from 'app/components/ui/Loading';
import {
  EXERCISE_REPORT_PRINT_MODES,
  STORE_ENTITY_STATE,
  STORE_EXERCISE_REPORT,
  STORE_FEATURE,
  STORE_MEMBER,
  STORE_MY360,
  STORE_ORGANIZATION_CONTENT,
} from 'app/constants';
import Route from 'app/customRoute';
import { ServerRouteHelper } from 'app/helpers';
import { OrganizationContent } from 'app/models';
import NoHabits from 'app/pages/dashboard/Myself/MyHabit/NoHabits';
import PersonalHabitsTestDrive from 'app/pages/dashboard/Myself/MyHabit/PersonalHabits/PersonalHabitsTestDrive';
import ShrinkingHeaderService from 'app/services/ShrinkingHeaderService';
import {
  EntityStateStore,
  ExerciseReportStore,
  FeatureStore,
  MemberStore,
  My360Store,
  OrganizationContentStore,
} from 'app/stores';

import SampleReflection from './components/CommitToImproveSolidifyReflections/SampleReflection';
import CommitToImproveContainer from './containers/CommitToImproveContainer';
import FullAnalysisContainer from './containers/FullAnalysisContainer';
import ReportTopBarContainer from './containers/ReportTopBarContainer';
import My360ReportUIStore, { ReportTab } from './My360ReportUIStore';

export interface Params {
  subnav: string;
  token: string;
  page: string;
}

export interface My360ReportProps extends RouteComponentProps<Params> {
  isTestDrive?: boolean;
  testDriveType?: string;
  exerciseReportStore: ExerciseReportStore;
  entityStateStore: EntityStateStore;
  my360Store: My360Store;
  memberStore: MemberStore;
  organizationContentStore?: OrganizationContentStore;
  featureStore: FeatureStore;
}

export class My360Report extends React.Component<My360ReportProps> {
  private shrinkingHeaderService: ShrinkingHeaderService;

  @observable scrollDirection: ScrollDirection;
  @action setScrollDirection = (scrollDirection: ScrollDirection): void => {
    this.scrollDirection = scrollDirection;
  };

  @observable lastScroll: number;
  @action setLastScroll = (lastScroll: number): number => (this.lastScroll = lastScroll);

  uiStore: My360ReportUIStore;

  constructor(props) {
    super(props);

    this.setLastScroll(0);
    this.shrinkingHeaderService = new ShrinkingHeaderService({
      setScrollDirection: this.setScrollDirection,
      setLastScroll: this.setLastScroll,
      lastScroll: this.lastScroll,
    });

    this.uiStore = new My360ReportUIStore({
      token: this.props.match.params.token,
      history: this.props.history,

      printMode: EXERCISE_REPORT_PRINT_MODES.SUMMARY,
      isSigningUpForCoaching: false,

      exerciseReportStore: this.props.exerciseReportStore,
      entityStateStore: this.props.entityStateStore,
      my360Store: this.props.my360Store,
      memberStore: this.props.memberStore,
      isTestDrive: this.props.isTestDrive,
      organizationContentStore: this.props.organizationContentStore,
      featureStore: this.props.featureStore,
    });

    this.uiStore.load();

    when(
      () => !!this.uiStore.exercise && !this.props.isTestDrive,
      () =>
        this.uiStore.exerciseReportStore.listenForExerciseActivityViews(
          this.props.match.params.token,
          this.uiStore.exercise.id,
          this.props.history,
          this.uiStore.mapSuffixToViewAction
        )
    );

    when(
      () => !this.uiStore.isLoading && !!this.uiStore.organization && !!this.uiStore.exercise,
      () => this.loadOrgContent()
    );
  }

  loadOrgContent = async (): Promise<void> => {
    await this.props.organizationContentStore.loadContentItem(
      OrganizationContent.My360ReportReflectCardsContent,
      this.uiStore.organization.id,
      this.uiStore.exercise.exercise_type_id
    );
  };

  componentDidMount(): void {
    window.addEventListener('scroll', this.shrinkingHeaderService.handleScrollForHeader);
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.shrinkingHeaderService.handleScrollForHeader);
  }

  UNSAFE_componentWillReceiveProps(props: My360ReportProps) {
    if (props.match.params.page !== this.props.match.params.page) {
      setTimeout(() => window.scrollTo({ top: 0 }), 500);
    }
  }

  @computed
  get printViews() {
    if (!this.uiStore.report) {
      return null;
    }

    switch (this.uiStore.printMode) {
      case EXERCISE_REPORT_PRINT_MODES.SUMMARY:
        return <AlignSummaryPrintView report={this.uiStore.report} />;
      case EXERCISE_REPORT_PRINT_MODES.BY_CATEGORY:
        return <ScatterPlotsByCategory report={this.uiStore.report} />;
      case EXERCISE_REPORT_PRINT_MODES.PER_PAGE:
        return <ScatterPlotsPerPage report={this.uiStore.report} />;
    }
  }

  get spinLoader() {
    return <Loading />;
  }

  handlePrint = () => {
    //
    // We need window.print to wait for the page to re-render before printing,
    // naturally the way of doing this would be to do setTimeout(window.print)
    // so that print gets called on the next tick... however...
    // wrapping window.print in setTimeout causes some kind of obscure chromium
    // issue that will cause the browser to freeze up the same thing happens if you
    // try to do a delay through an async Promise timer.
    //
    // React Issue: https://github.com/facebook/react/issues/16734
    // Chromium Issue: https://bugs.chromium.org/p/chromium/issues/detail?id=956832
    //
    // Neither seem very motivated to jump on this.
    //
    // The work around for now is to force the page to re-render through forceUpdate
    // and then call the print method on the callback of forceUpdate (which runs once
    // react finishes rendering)
    //

    this.forceUpdate(() => window.print());
  };

  makeReportUrl = (path: string) => {
    path = path === ReportTab.DIGEST ? '' : path;

    if (this.props.isTestDrive) {
      return ServerRouteHelper.my360.testDrive(this.uiStore.token, 'report', path);
    }

    return ServerRouteHelper.my360.report(this.uiStore.token, path);
  };

  @computed
  get routes() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}/analysis`}>
          <FullAnalysisContainer uiStore={this.uiStore} />
        </Route>
        <Route exact path={`${match.path}/reflection`}>
          <CommitToImproveContainer uiStore={this.uiStore} />
        </Route>
        <Route exact path={`${match.path}/commitments/:step?`}>
          <div className="mt-4">
            {this.props.isTestDrive && (
              <PersonalHabitsTestDrive
                currentMember={this.uiStore.report.reportMember}
                pulseBasePath={generatePath(match.path, {
                  token: this.uiStore.token,
                  tab: 'commitments',
                })}
              />
            )}
            {!this.props.isTestDrive && <NoHabits memberId={this.uiStore.currentMember?.id} />}
          </div>
        </Route>
        <Route path={`${match.path}/`}>
          <FullAnalysisContainer uiStore={this.uiStore} />
        </Route>
      </Switch>
    );
  }

  @computed
  get navItems(): any[] {
    const items = [
      {
        key: ReportTab.DIGEST,
        id: 'digest-results-menu-item',
        label: 'Digest \n results',
        icon: <DotChartOutlined />,
        theme: 'gray',
        hash: null,
        order: 2,
      },
      {
        key: ReportTab.REFLECTION,
        id: 'reflect-results-menu-item',
        label: 'Reflect \n on results',
        icon: <CoffeeOutlined />,
        theme: 'blue',
        hash: null,
        order: 3,
      },
      {
        key: ReportTab.COMMITMENTS,
        id: 'commit-results-menu-item',
        label: 'Commit \n to Habits',
        icon: <MessageFilled />,
        theme: 'blue',
        hash: null,
        order: 4,
      },
    ];

    return sortBy(items, 'order');
  }

  @computed
  get currentActiveSubRoute(): string {
    const match = this.props.location.pathname;

    if (match.endsWith('analysis')) {
      return ReportTab.DIGEST;
    }

    if (match.endsWith('reflection')) {
      return ReportTab.REFLECTION;
    }

    if (match.includes('commitments')) {
      return ReportTab.COMMITMENTS;
    }

    return ReportTab.DIGEST;
  }

  handleNavItemClick = (key: string, hash?: string): void => {
    const suffix = hash ? `#${hash}` : '';
    const url = this.makeReportUrl(key) + suffix;
    this.props.history.push(url);
  };

  render(): ReactElement {
    const isScrollingUp = this.scrollDirection === ScrollDirection.Up;

    return (
      <div>
        <div className={cx('my360-report', 'd-print-none')}>
          {!this.props.isTestDrive && (
            <ReportTopBarContainer
              scrollDirection={this.scrollDirection}
              uiStore={this.uiStore}
              onPrint={this.handlePrint}
            />
          )}

          <div className="my360-report-container">
            <div className="my360-report-content">
              <Affix offsetTop={isScrollingUp ? 70 : 0} className="ant-tabs ant-tabs-top">
                <div className="tabs-container" id="tabs-container">
                  <div className="menu">
                    <TabbedReportNavigation
                      currentActiveSubRoute={this.currentActiveSubRoute}
                      navItems={this.navItems}
                      uiStore={this.uiStore}
                      onNavItemClick={this.handleNavItemClick}
                    />
                  </div>
                </div>
              </Affix>
              <div className="container my360-report-view">
                <div className="my360-report-view-inner">
                  {!this.uiStore.isLoading ? this.routes : this.spinLoader}
                </div>
              </div>
            </div>
          </div>

          <Drawer
            title="Sample Reflection"
            placement="right"
            zIndex={1090}
            width="50%"
            maskClosable
            closable
            onClose={this.uiStore.toggleSampleReflectionsDrawer}
            open={this.uiStore.isSampleReflectionsDrawerOpen}
          >
            <SampleReflection />
          </Drawer>
        </div>

        <div>{this.printViews}</div>
      </div>
    );
  }
}

export default inject(
  STORE_EXERCISE_REPORT,
  STORE_ENTITY_STATE,
  STORE_MY360,
  STORE_MEMBER,
  STORE_ORGANIZATION_CONTENT,
  STORE_FEATURE
)(observer(My360Report));
