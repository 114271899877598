import { action, observable } from 'mobx';

import ExerciseCommitmentStore from 'app/stores/ExerciseCommitmentStore';

import { ModelJson } from '.';
import Model from './Model';

export enum ExerciseCommitmentCategory {
  improve = 'improve',
  celebrate = 'celebrate',
  summary = 'summary',
}

export enum ExerciseCommitmentContentKey {
  challenge = 'challenge',
  change = 'change',
}

export interface ExerciseCommitmentModelProps {
  id: number;

  exercise_id: number;
  align_statement_id: number;

  commitments: {
    challenge?: string;
    change?: string;
  };

  category: ExerciseCommitmentCategory;
}

export default class ExerciseCommitmentModel extends Model {
  static _store: ExerciseCommitmentStore;

  exercise_id: number;
  @observable align_statement_id: number;

  @observable commitments = observable<{
    challenge: string;
    change: string;
  }>({
    challenge: '',
    change: '',
  });

  category: ExerciseCommitmentCategory;

  @action setAlignStatementID = (v: number) => (this.align_statement_id = v);

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as ExerciseCommitmentModel;
  }
}
