import { debounce } from 'lodash';

export default class DebounceValidatorService {
  public missingMessage;
  public invalidMessage;
  public validatorFn;

  // To do: add typing for validatorFn (e.g., return false when invalid)
  constructor(missingMessage: string, invalidMessage: string, validatorFn) {
    this.missingMessage = missingMessage;
    this.invalidMessage = invalidMessage;
    this.validatorFn = validatorFn;
  }

  private getValue = debounce(async (value, resolve, reject) => {
    if (!this.validatorFn(value)) {
      return reject(new Error(this.invalidMessage));
    }
    return resolve(value);
  }, 500);

  public validate = async (_, value) => {
    if (!value) {
      return Promise.reject(new Error(this.missingMessage));
    }

    return new Promise((resolve, reject) => this.getValue(value, resolve, reject));
  };
}
