import React, { useRef } from 'react';

import { scaleLinear } from '@visx/scale';
import cx from 'classnames';
import { DragObjectWithType, useDrop, XYCoord } from 'react-dnd';

import './ScatterPlotCard.scss';

import { AlignModel, ExerciseTypeModel } from 'app/models';

import ScatterPlotCardDraggable from './ScatterPlotCardDraggable';

export interface StatementCard {
  type?: string;
  id: number | string;
  title: string;
  scaledX?: number;
  scaledY?: number;
  placed?: boolean;
}

export interface ScatterPlotCardProps {
  statements: StatementCard[];
  exerciseType: ExerciseTypeModel;
  exercise?: AlignModel;
  onStatementCardDrop?: (id, statement) => void;
  postCardAnimationAction?: (id: string | number) => void;
  blockDragging?: boolean;
  startAnimation?: boolean;
}

type Scaler = (x: number) => number;

export const renderLabels = (
  size: number,
  dx: Scaler,
  dy: Scaler,
  exerciseType: ExerciseTypeModel,
  exercise: AlignModel
) => {
  const labels = exerciseType.labelsForExercise(exercise);

  if (!labels || !size) {
    return null;
  }

  const labelAxes = [
    { label: labels.top, x: 0, y: 100, position: 'top' },
    { label: labels.bottom, x: 0, y: -100, position: 'bottom' },
    { label: labels.left, x: -100, y: 0, position: 'left' },
    { label: labels.right, x: 100, y: 0, position: 'right' },
  ];

  const fontSize = size < 400 ? 14 : 16;

  return labelAxes.map(({ label, x, y, position }, i) => {
    const testId = `scatter-plot-card-label-${position}`;
    const isCC = exerciseType.isCompassionateConversationExercise;
    return (
      <div
        key={`label-${i}`}
        data-testid={testId}
        className={cx('scatter-plot-card-label', testId, { 'cc-scatterplot': isCC })}
        style={{ left: dx(x), top: dy(y), fontSize }}
      >
        {label}
      </div>
    );
  });
};

export const getLinearScales = (size: number, padding = 0) => {
  const dx = scaleLinear({
    domain: [-100, 100],
    range: [padding, size - padding],
  });

  const dy = scaleLinear({
    domain: [-100, 100],
    range: [size - padding, padding],
  });

  return { dx, dy };
};

const getScoreScalers = (size: number, padding = 0) => {
  const dx = scaleLinear({
    range: [-100, 100],
    domain: [padding, size - padding],
  });

  const dy = scaleLinear({
    range: [-100, 100],
    domain: [size - padding, padding],
  });

  return { dx, dy };
};

const CARD_SIZE = 88;
let scatterplotSize = 0;

const ScatterPlotCard: React.FC<ScatterPlotCardProps> = ({
  exerciseType,
  exercise,
  onStatementCardDrop,
  statements,
  blockDragging,
  startAnimation,
  postCardAnimationAction,
}) => {
  const boundingBoxRef = useRef(null);

  const moveBox = (id: any, top: number, left: number, title: string, onStatementCardDrop) => {
    const { dx, dy } = getScoreScalers(scatterplotSize);
    let scaledX = dx(left + CARD_SIZE / 2);
    let scaledY = dy(top + CARD_SIZE / 2);
    scaledX = scaledX < -100 ? -100 : scaledX;
    scaledX = scaledX > 100 ? 100 : scaledX;
    scaledY = scaledY < -100 ? -100 : scaledY;
    scaledY = scaledY > 100 ? 100 : scaledY;

    const updatedStatement = {
      id,
      title,
      placed: true,
      scaledX,
      scaledY,
    };

    onStatementCardDrop(id, updatedStatement);
  };

  const [{ canDrop, isOverCurrent }, dropTargetRef] = useDrop({
    accept: 'box',
    drop(item: StatementCard & DragObjectWithType, monitor) {
      const { top: dropzoneTop, left: dropzoneLeft } =
        boundingBoxRef.current.getBoundingClientRect();
      const sourceClientOffset = monitor.getSourceClientOffset() as XYCoord;

      const top = sourceClientOffset.y - dropzoneTop + 20;
      const left = sourceClientOffset.x - dropzoneLeft + 20;

      if (isOverCurrent || canDrop) {
        moveBox(item.id, top, left, item.title, onStatementCardDrop);
      }

      return undefined;
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  return (
    <ScatterPlotCardDraggable
      {...{
        statements,
        exercise,
        exerciseType,
        boundingBoxRef,
        dropTargetRef,
        blockDragging,
        startAnimation,
        postCardAnimationAction,
      }}
      onSize={(size) => (scatterplotSize = size)}
    />
  );
};

export default ScatterPlotCard;
