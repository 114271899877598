import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { OrganizationReportRollupStore } from 'app/stores';

import ExerciseTypeModel from './ExerciseTypeModel';
import { ModelItem } from './ModelItem';

interface BenchmarkScore {
  align_statement_template_id: number;
  score: number;
  x: number;
  y: number;
}

export class OrganizationReportRollupModel extends Model {
  static _store: OrganizationReportRollupStore;

  @observable id: string;
  @observable benchmark_scores?: Record<number, BenchmarkScore>;
  @observable group_scores?: Record<number, BenchmarkScore>;
  @observable teams_count?: number;
  @observable members_count?: number;
  @observable exercise_type = new ModelItem<ExerciseTypeModel>(ExerciseTypeModel);

  static fromJson(json: ModelJson): OrganizationReportRollupModel {
    return this._fromJson(json) as OrganizationReportRollupModel;
  }

  static getOrNew(id: number | string): OrganizationReportRollupModel {
    return this._getOrNew(id) as OrganizationReportRollupModel;
  }

  static get(id: number | string): OrganizationReportRollupModel {
    return this._get(id) as OrganizationReportRollupModel;
  }
}

export default OrganizationReportRollupModel;
