export enum PerspectiveDimension {
  Energy = 'energy',
  Processing = 'processing',
  Decisions = 'decisions',
  Structure = 'structure',
}

export enum PerspectiveSpreadType {
  Balanced = 'balanced',
  Tilted = 'tilted',
  Blindspot = 'blindspot',
}

export const PERSPECTIVE_DIMENSION_SHORTCUT = {
  E: PerspectiveDimension.Energy,
  I: PerspectiveDimension.Energy,
  J: PerspectiveDimension.Structure,
  P: PerspectiveDimension.Structure,
  F: PerspectiveDimension.Decisions,
  T: PerspectiveDimension.Decisions,
  N: PerspectiveDimension.Processing,
  S: PerspectiveDimension.Processing,
};

export const PERSPECTIVE_RESONATE_YES = 'yes';
export const PERSPECTIVE_RESONATE_NO = 'no';

export const PERSPECTIVE_DIMENSION_COLOR = {
  [PerspectiveDimension.Energy]: 'indigo', // indigo-600
  [PerspectiveDimension.Processing]: 'amber', // amber-600
  [PerspectiveDimension.Decisions]: 'cyan', // cyan-600
  [PerspectiveDimension.Structure]: 'green', // green-600
};

export enum PerspectiveLens {
  // energy
  InitiatingReceiving = 'initiating-receiving',
  ExpressiveContained = 'expressive-contained',
  GreagariousIntimate = 'gregarious-intimate',
  ActiveReflective = 'active-reflective',
  EnthusiasticQuiet = 'enthusiastic-quiet',

  // processing
  ConcreteAbstract = 'concrete-abstract',
  RealisticImaginative = 'realistic-imaginative',
  PracticalConceptual = 'practical-conceptual',
  ExperientialTheoretical = 'experiential-theoretical',
  TraditionalOriginal = 'traditional-original',

  // decisions
  LogicalEmpathetic = 'logical-empathetic',
  ReasonableCompassionate = 'reasonable-compassionate',
  QuestioningAccomodating = 'questioning-accommodating',
  CriticalAccepting = 'critical-accepting',
  ToughTender = 'tough-tender',

  // structure
  SystematicCasual = 'systematic-casual',
  PlanfulOpenEnded = 'planful-openended',
  EarlyStartingPressurePrompted = 'earlystarting-pressureprompted',
  ScheduledSpontaneous = 'scheduled-spontaneous',
  MethodicalEmergent = 'methodical-emergent',
}

export const PERSPECTIVE_PAIR_LENS_ANCHOR = {
  // Energy
  [PerspectiveLens.InitiatingReceiving]: 'Proactivity in communication',
  [PerspectiveLens.ExpressiveContained]: 'Expressiveness',
  [PerspectiveLens.GreagariousIntimate]: 'Social setting preference',
  [PerspectiveLens.ActiveReflective]: 'Approach to learning',
  [PerspectiveLens.EnthusiasticQuiet]: 'Projection of energy',

  // Processing
  [PerspectiveLens.ConcreteAbstract]: 'Handling ambiguity',
  [PerspectiveLens.RealisticImaginative]: 'Assessing feasibility',
  [PerspectiveLens.PracticalConceptual]: 'Practicality',
  [PerspectiveLens.ExperientialTheoretical]: 'Experimenting',
  [PerspectiveLens.TraditionalOriginal]: 'Considering precedent',

  // decisions
  [PerspectiveLens.LogicalEmpathetic]: 'Objectiveness',
  [PerspectiveLens.ReasonableCompassionate]: 'Valuing abstract factors',
  [PerspectiveLens.QuestioningAccomodating]: 'Seeking alignment',
  [PerspectiveLens.CriticalAccepting]: 'Handling adversarial situations',
  [PerspectiveLens.ToughTender]: 'Enforcing compliance',

  // structure
  [PerspectiveLens.SystematicCasual]: 'Formality in planning',
  [PerspectiveLens.PlanfulOpenEnded]: 'Organizing time',
  [PerspectiveLens.EarlyStartingPressurePrompted]: 'Managing tasks',
  [PerspectiveLens.ScheduledSpontaneous]: 'Planning routines',
  [PerspectiveLens.MethodicalEmergent]: 'Systematic planning',
};

export const LENS_OVERLAP_POSTFIX = {
  [PerspectiveDimension.Energy]: {
    [PerspectiveLens.InitiatingReceiving]: 'in how proactively you communicate',
    [PerspectiveLens.ExpressiveContained]: 'in how much you outwardly express',
    [PerspectiveLens.GreagariousIntimate]: 'in your social setting preference',
    [PerspectiveLens.ActiveReflective]: 'in how you learn',
    [PerspectiveLens.EnthusiasticQuiet]: 'in how much energy you project',
  },
  [PerspectiveDimension.Processing]: {
    [PerspectiveLens.ConcreteAbstract]: 'in how you consider ambiguity',
    [PerspectiveLens.RealisticImaginative]: 'in how you view feasibility',
    [PerspectiveLens.PracticalConceptual]: 'in how you value practicality',
    [PerspectiveLens.ExperientialTheoretical]: 'in your willingness to experiment',
    [PerspectiveLens.TraditionalOriginal]: 'in how you consider precedents',
  },
  [PerspectiveDimension.Decisions]: {
    [PerspectiveLens.LogicalEmpathetic]: 'in your sense of objectivity',
    [PerspectiveLens.ReasonableCompassionate]: "in how you value 'other factors'",
    [PerspectiveLens.QuestioningAccomodating]: 'in your approach to seeking alignment',
    [PerspectiveLens.CriticalAccepting]: 'in how you deal with adversarial situations',
    [PerspectiveLens.ToughTender]: 'in how you enforce compliance',
  },
  [PerspectiveDimension.Structure]: {
    [PerspectiveLens.SystematicCasual]: 'in how formally you plan',
    [PerspectiveLens.PlanfulOpenEnded]: 'in how proactively you organize time',
    [PerspectiveLens.EarlyStartingPressurePrompted]: 'in how you accomplish tasks',
    [PerspectiveLens.ScheduledSpontaneous]: 'in how you plan routines',
    [PerspectiveLens.MethodicalEmergent]: 'in how systematic your planning is',
  },
};

export enum PerspectiveLensSimilarity {
  Neutral = 'neutral',
  SimilarPositive = 'similar_positive',
  SimilarNegative = 'similar_negative',
  Different = 'different',
}

export const PERSPECTIVE_PLAIN_LINK = 'plain_link';
