import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const TeamPulse = lazy(() => import('./TeamPulse'));
const TeamPulseTestDrive = lazy(() => import('./TeamPulseTestDrive'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route exact path="/teams/:teamId/pulses/:token" component={TeamPulse} />
      <Route path="/teams/pulses/test-drive/:token" component={TeamPulseTestDrive} />
    </Switch>
  </Suspense>
);
