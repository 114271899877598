import { observable } from 'mobx';

import { ModelList } from 'app/models/ModelList';

import {
  PerspectiveDimensionIndex,
  PerspectiveDimensionTip,
  PerspectivePairDifferentTraitPoint,
} from '.';
import PerspectiveDimensionLensModel from './PerspectiveDimensionLensModel';
import PerspectiveDimensionModel from './PerspectiveDimensionModel';

export interface DimensionDiffLens {
  id: string;
  title: string;
  biasTitle: string;
  pairBiasTitle: string;
  dimension: string;
  leftLabel: string;
  leftTooltip: string;
  rightLabel: string;
  rightTooltip: string;
  difference: number;
  scores: number[];
  similarityLevel: string;
  differenceText: string;
  header?: string;
  differentPoints?: PerspectivePairDifferentTraitPoint;
  similarPoints?: PerspectivePairDifferentTraitPoint;
}

export interface TipOverview {
  tip: string;
  action?: string;
}

export interface DimensionDiff {
  id: PerspectiveDimensionIndex;
  leftLabel: string;
  leftTooltip: string;
  rightLabel: string;
  rightTooltip: string;
  scores: number[];
  difference: number;
  differenceLevel: string;
  differenceText: string;
  differenceTextFull: string;
  description: string | React.ReactNode;
  lenses: DimensionDiffLens[];
  dimension?: string;
  title?: string;
  dimensionTitle?: string;
  tipOverview?: TipOverview;
  pairTips?: PerspectiveDimensionTip[];
  differentPoints?: Record<string, PerspectivePairDifferentTraitPoint>;
  similarPoints?: Record<string, PerspectivePairDifferentTraitPoint>;
  discussionPrompts?: any;
}

export class PerspectivePairScoreDiffDimensionModel extends PerspectiveDimensionModel {
  @observable otherBinnedScore: number;
  @observable otherLenses = new ModelList<PerspectiveDimensionLensModel>(
    PerspectiveDimensionLensModel
  );
  @observable otherRawScore: string;
  @observable otherDescriptionHtml: string;
  @observable pairDifferenceLevel: string;
  @observable pairTips: PerspectiveDimensionTip[];
  @observable tipOverview: TipOverview;
  @observable differentPoints: Record<string, PerspectivePairDifferentTraitPoint>;
  @observable similarPoints: Record<string, PerspectivePairDifferentTraitPoint>;
  @observable discussionPrompts: string[];
  @observable difference: number;
  @observable differenceText: string;
  @observable differenceTextFull: string;
}

export default PerspectivePairScoreDiffDimensionModel;
