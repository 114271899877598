import React from 'react';

import { action, computed, observable, when } from 'mobx';
import { inject, observer } from 'mobx-react';

import { HowHabitWorksModal } from 'app/components/features/HowHabitWorksModal/HowHabitWorksModal';
import { ENTITY_STATES, STORE_ENTITY_STATE } from 'app/constants';
import { EntityStateStore } from 'app/stores';

export interface HowHabitWorksModalContainerProps {
  entityStateStore: EntityStateStore;
}

export class HowHabitWorksModalContainer extends React.Component<HowHabitWorksModalContainerProps> {
  @observable private showModal = false;
  @action private setShowModal = (showModal) => (this.showModal = showModal);

  constructor(props) {
    super(props);
    this.init();
  }

  private async init() {
    const { entityStateStore } = this.props;

    await entityStateStore.loadCurrentMemberOwnedEntityStates({
      type: ENTITY_STATES.SEEN_HABIT_WORKS_MODAL,
    });

    when(
      () => entityStateStore.memberEntityStates.loaded,
      () => {
        if (!this.seenHowHabitWorksModal) {
          entityStateStore.upsertCurrentMemberOwnedEntityState({
            type: ENTITY_STATES.SEEN_HABIT_WORKS_MODAL,
            flag: true,
          });
          this.setShowModal(true);
        }
      }
    );
  }

  @computed
  private get seenHowHabitWorksModalEntityState() {
    return this.props.entityStateStore.memberEntityStates.items.find(
      (item) => item.type === ENTITY_STATES.SEEN_HABIT_WORKS_MODAL
    );
  }

  @computed
  private get seenHowHabitWorksModal() {
    return !!this.seenHowHabitWorksModalEntityState?.flag;
  }

  public render() {
    return <HowHabitWorksModal isOpen={this.showModal} onClose={() => this.setShowModal(false)} />;
  }
}

export default inject(STORE_ENTITY_STATE)(observer(HowHabitWorksModalContainer));
