import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { RouteComponentProps, Switch } from 'react-router-dom';

import Route from 'app/customRoute';

const PrintDocument = lazy(() => import('./print/PrintDocument'));

const PrintRoutes: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route
          path={`${match.path}/:printDocument/:memberId/:response`}
          component={PrintDocument}
        />
      </Switch>
    </Suspense>
  );
};

export default <Route path="/print" component={PrintRoutes} />;
