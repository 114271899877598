export class NavService {
  static setHeadTitle(title) {
    const siteName =
      document.querySelector("meta[name='appName']")?.getAttribute('content') ?? 'Valence';

    document.title = title ? `${title} | ${siteName}` : siteName;
  }
}

export default NavService;
