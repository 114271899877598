import { random, times } from 'lodash';
import moment, { Moment } from 'moment';

export const preventDefault = (fn: (e: any) => void) => {
  return (e: any) => {
    e?.preventDefault?.();
    fn?.(e);
  };
};

export const getRandomString = (length = 20) => {
  return times(length, () => random(35).toString(36)).join('');
};

export const stopPropagation = (fn: (e: any) => void) => {
  return (e: any) => {
    e?.stopPropagation?.();
    fn?.(e);
  };
};

/**
 * Checks if the input value has changed (including dates).
 * Takes into account null & undefined values if any.
 *
 * @param newVal new value
 * @param oldVal old value
 * @param granularity optional comparison granularity when the input values are moment date strings
 * @returns true if newVal and oldVal don't match, false otherwise
 */
export const hasChanged = (
  newVal: string | number | Moment,
  oldVal: string | number | Moment,
  granularity?: moment.unitOfTime.StartOf
) => {
  const newDate = moment(newVal);
  const oldDate = moment(oldVal);

  if (!newDate.isValid() || !oldDate.isValid()) {
    // check plain text
    return (newVal ?? '') !== (oldVal ?? '');
  }

  // check moment date
  return !newDate.isSame(oldDate, granularity);
};

/**
 * Strip HTML from a string
 */
export const stripHtml = (html: string): string => {
  // Create a new div element
  const temporalDivEl = document.createElement('div');

  // Set HTML content using provider
  temporalDivEl.innerHTML = html;

  // Get the text property of the element (browser support)
  return temporalDivEl.textContent || temporalDivEl.innerText || '';
};

// Detect if jest test is running,
// see https://jestjs.io/docs/environment-variables
export const isTesting = (): boolean => process.env.NODE_ENV === 'test';

/**
 * Clean up a string to make it safe for use in CSS
 */
export const makeSafeForCSS = (name: string) => {
  return encodeURIComponent(name)
    .toLowerCase()
    .replace(/\.|%[0-9a-z]{2}/gi, '');
};
