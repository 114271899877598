import React from 'react';

import { Row } from 'antd';

import './EndOfPageRow.scss';

export const EndOfPageRow: React.FC = () => {
  return <Row className="end-of-page-row"></Row>;
};

export default EndOfPageRow;
