import { action, observable } from 'mobx';

import { Cadence, Weekday } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import { ModelList, PulseForMemberModel, PulseForTeamModel, PulseReminderModel } from 'app/models';
import ThirdPartyService from 'app/services/ThirdPartyService';
import Store from 'app/stores/Store';

export interface ReminderTimezone {
  name: string;
  value: string;
}

export interface ReminderOptions {
  days: Weekday[];
  cadence: Record<Cadence, string>;
  timezones: ReminderTimezone[];
}

export class PulseReminderStore extends Store<PulseReminderModel> {
  @observable reminders = new ModelList<PulseReminderModel>(PulseReminderModel);

  @observable options: ReminderOptions;
  @action setOptions = (options: ReminderOptions): void => {
    this.options = options;
  };

  @observable activeReminder: PulseReminderModel;
  @action setActiveReminder = (activeReminder: PulseReminderModel): void => {
    this.activeReminder = activeReminder;
  };

  @observable isLoadingDefaultReminder = false;
  @action setIsLoadingDefaultReminder = (isLoadingDefaultReminder: boolean): void => {
    this.isLoadingDefaultReminder = isLoadingDefaultReminder;
  };

  @observable isSaving = false;
  @action setIsSaving = (isSaving: boolean): void => {
    this.isSaving = isSaving;
  };

  constructor() {
    super();
    PulseReminderModel._store = this;
  }

  loadDefaultReminders = async (pulseType, forceApi = false): Promise<any> => {
    if (this.reminders.items.length < 1 && !forceApi) {
      return;
    }

    this.setIsLoadingDefaultReminder(true);
    const url = ServerRouteHelper.api.pulses.reminder.default();
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await this.apiService.get(url, { clientTimeZone, pulseType });

    if (response !== undefined) {
      this.reminders.setItems(response.data?.reminders);
      this.setOptions(response.data?.options);
    }

    this.setIsLoadingDefaultReminder(false);
  };

  create = async (
    memberId: number,
    pulseId: number,
    reminder: Partial<PulseReminderModel>
  ): Promise<PulseReminderModel> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.pulses.reminder.createMemberPulse(memberId, pulseId);
      const response = await this.apiService.post(url, reminder);

      return PulseReminderModel.fromJson(response.data);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  createTeamPulseReminder = async (
    teamId: number,
    pulseId: number,
    reminder: Partial<PulseReminderModel>
  ): Promise<PulseReminderModel> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.pulses.reminder.createTeamPulse(teamId, pulseId);
      const response = await this.apiService.post(url, reminder);

      return PulseReminderModel.fromJson(response.data);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  update = async (
    memberId: number,
    activePulse: PulseForMemberModel,
    reminder: PulseReminderModel
  ): Promise<PulseReminderModel> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.pulses.reminder.update(
        memberId,
        activePulse.id,
        activePulse.reminder.id
      );
      const response = await this.apiService.patch(url, reminder);
      activePulse.reminder.updateFromJson(response.data);

      return reminder;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  updateTeam = async (
    teamId: number,
    activePulse: PulseForTeamModel,
    reminder: PulseReminderModel
  ): Promise<PulseReminderModel> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.pulses.reminder.updateTeam(
        teamId,
        activePulse.id,
        activePulse.reminder.id
      );
      const response = await this.apiService.patch(url, reminder);

      if (response?.data) {
        activePulse.reminder.updateFromJson(response.data);
      }

      return reminder;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };
}

export default PulseReminderStore;
