import { CustomLandingPagesLinkModel } from 'app/models';

import Store from './Store';

export class CustomLandingPagesLinkStore extends Store<CustomLandingPagesLinkModel> {
  constructor() {
    super();
    CustomLandingPagesLinkModel._store = this;
  }
}

export default CustomLandingPagesLinkStore;
